import { Component, Input, type OnDestroy, type OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import {
  type User,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGoogleTagmanagerService,
  ResizeService, type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'
import {
  type CustomerPortalConfig,
  type AdditionalService
} from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NgxGlideComponent } from 'ngx-glide'
import { NGXLogger } from 'ngx-logger'
import { type Observable } from 'rxjs'
import { DataService } from '../../../../services/data.service'
import { GalleryService } from '../../../../services/gallery.service'
import { LeasingService, type LoanDTO } from '../../../../services/leasing.service'
import { SharedService } from '../../../../services/shared.service'
import { VehicleNotificationsService } from '../../../../services/vehicle-notifications.service'
import {
  type TrackAndTraceVehicleDTOExtended,
  type VehicleDTOExtended,
  type VehiclesResponse,
  VehiclesService
} from '../../../../services/vehicles.service'
import { ConfirmationPopupComponent } from '../../../basic/confirmation-popup/confirmation-popup.component'
import { AddNewVehicleComponent } from '../../../vehicle-list/add-new-vehicle/add-new-vehicle.component'
import { RedirectConsentsPopupComponent } from './redirect-consents-popup/redirect-consents-popup.component'
import { LocalStorageService } from '../../../../services/local-storage.service'

@Component({
  selector: 'customer-portal-revolution-vehicle-list',
  templateUrl: './revolution-vehicle-list.component.html'
})
export class RevolutionVehicleListComponent implements OnInit, OnDestroy {
  @Input() mode = 'summary'

  notifications
  vehicleNotifications
  licensePlateMask
  service: AdditionalService
  channels

  @ViewChild('RevolutionVehicleListComponent_ngxGlide')
    ngxGlide!: NgxGlideComponent

  perView
  startAt
  focusAt
  showArrows = true
  showBullets = true
  bound = false
  fitGlider = false

  sub = {
    onUserLoaded: null,
    onVehicleNotificationsLoaded: null,
    onResize: null,
    onUserVehiclesLoaded: null,
    onResizePx: null,
    onUserTrackingLoaded: null,
    onVehicleDetailsLoaded: null,
    onVehicleProfilePictureChanged: null,
    onUserConsentsLoaded: null,
    router: null
  }

  vehicles: VehicleDTOExtended[] = []
  public vin: string

  vehiclesTNT: TrackAndTraceVehicleDTOExtended[] = []
  public commissionNumber: string
  hasTracking

  user: User
  loading = true
  countryCode

  public screenSize: SCREEN_SIZE
  public screenSizePx: number

  breakpoints
  constructor (
    public leasingService: LeasingService,
    private readonly dataService: DataService,
    private readonly vehicleNotificationsService: VehicleNotificationsService,
    public sharedService: SharedService,
    public resizeService: ResizeService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly vehiclesService: VehiclesService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly logger: NGXLogger,
    private readonly userService: UserService,
    private readonly galleryService: GalleryService,
    private readonly localStorageService: LocalStorageService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.screenSizePx = this.resizeService.screenSizePx
    this.hasTracking = this.remoteConfigService.get('hasTracking')
    this.countryCode = this.remoteConfigService.get('country.code')
  }

  redirectToConsents (): boolean {
    return !this.channels && this.countryCode === 'de'
  }

  setVin (): void {
    const vin = this.route.snapshot.paramMap.get('vin')
    this.setCommissionNumberVin(vin)
  }

  setCommissionNumberVin (vin: string): void {
    if (this.sharedService.stringExists(vin)) {
      if (vin.startsWith('commissionNumber-')) {
        this.commissionNumber = vin.replace('commissionNumber-', '')
        this.vin = undefined
      } else {
        this.vin = vin
        this.commissionNumber = undefined
      }
    }
  }

  swipe (e: TouchEvent): void {
    if (this.mode !== 'summary') {
      const index = this.ngxGlide.getIndex()
      if (index >= 0) {
        const vehicles = this.vehicles
        const vehiclesTNT = this.vehiclesTNT
        const vehiclesMerged: TrackAndTraceVehicleDTOExtended[] =
          vehicles.concat(vehiclesTNT)
        const vehicle = vehiclesMerged[index]

        if (vehicle.commissionNumber != null) {
          this.goToVehicleTNT(vehicle, this.tab)
        } else {
          this.goToVehicle(vehicle, this.tab)
        }
      }
    }
  }

  get tab (): string {
    try {
      const vehicleNav = this.localStorageService.getVehicleNavigation()
      return this.sharedService.stringExists(vehicleNav)
        ? vehicleNav
        : 'general'
    } catch (error) {
      return 'general'
    }
  }

  goToVehicle (v: VehicleDTOExtended, tab: string): void {
    this.sharedService.goToVehicle(v, tab)
  }

  goToVehicleTNT (v: TrackAndTraceVehicleDTOExtended, tab: string): void {
    this.sharedService.goToVehicleTNT(v, tab)
  }

  sendContentModulesVehicleTeaserData (
    contentModuleLabel: string,
    text: string
  ): void {
    const label =
      this.sharedService.translateLink(contentModuleLabel) + '|' + text ?? ''
    this.cptGtmService.sendContentModulesData(
      'vehicle teaser',
      null,
      label,
      'Tab Switch',
      label
    )
  }

  preventEventPropagation (ev): void {
    this.sharedService.preventEventPropagation(ev)
  }

  ngOnInit (): void {
    this.setVin() // get vin from url
    this.sub.router = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setVin()
      }
    })

    this.sub.onUserLoaded = this.dataService.onUserLoaded.subscribe(value => {
      this.user = value
    })

    this.sub.onVehicleNotificationsLoaded =
      this.dataService.onVehicleNotificationsLoaded.subscribe(notifications => {
        this.notifications = notifications
        this.setNotifications()
      })

    this.sub.onUserVehiclesLoaded =
      this.dataService.onUserVehiclesLoaded.subscribe(vehicles => {
        this.vehicles = vehicles
        this.loading = false
        if (vehicles?.[0] != null) {
          this.setNotifications()
          this.setBreakpoints()
          this.recreate()
        }
      })

    this.sub.onVehicleProfilePictureChanged =
      this.galleryService.onVehicleProfilePictureChanged.subscribe(
        profilePicture => {
          this.vehicles?.forEach(vehicle => {
            if (profilePicture != null && profilePicture.vin === vehicle.vin) {
              const imgObj = this.vehiclesService.setVehicleImage({
                imageUrl: profilePicture.publicUrl,
                brand: vehicle.brand
              })
              vehicle.brandPicture = imgObj.imgUrl
              vehicle.pictureType = imgObj.imageType
            }
          })
        }
      )

    if (this.hasTracking) {
      this.sub.onUserTrackingLoaded =
        this.dataService.onUserTrackingLoaded.subscribe(vehicles => {
          this.vehiclesTNT = vehicles.filter(el => {
            return !(el.isHistory ?? false)
          })
          if (this.vehiclesTNT?.[0] != null) {
            this.setBreakpoints()
            this.recreate()
          }
        })
    }

    this.sub.onResizePx = this.resizeService.onResizePx.subscribe(size => {
      this.screenSizePx = size
      this.setBreakpoints()
      this.recreate()
    })

    this.sub.onUserConsentsLoaded =
      this.dataService.onUserConsentsLoaded.subscribe(consents => {
        if (this.countryCode === 'de') {
          this.channels = this.sharedService.hasConsentsForEFDS(consents)
        } else {
          this.channels = true
        }
      })

    const services =
      this.remoteConfigService.getArrayValues<AdditionalService[]>(
        'additionalServices'
      )
    services.forEach(service => {
      if (
        service.categoryKey ===
        'customerPortal.customer-portal.additional-services.wish.category'
      ) {
        this.service = service
      }
    })

    this.licensePlateMask = this.sharedService.setLicensePlateMask()
    this.setBreakpoints()
    this.recreate()
  }

  setNotifications (): void {
    if (this.notifications != null && this.vehicles?.[0] != null) {
      this.vehicleNotifications = {}
      this.vehicles.forEach(vehicle => {
        this.vehicleNotifications[vehicle.vin] =
          this.vehicleNotificationsService.setVehicleNotifications(
            this.notifications,
            vehicle.vin
          )
      })
    }
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  openAutomarkt (): void {
    const autoMarktLinkUrl: string = this.remoteConfigService.get(
      'links.automarkt.url'
    )
    const autoMarktLinkTarget: string = this.remoteConfigService.get(
      'links.automarkt.target'
    )
    if (autoMarktLinkTarget != null || autoMarktLinkUrl != null) {
      window.open(autoMarktLinkUrl, autoMarktLinkTarget)
    }
  }

  switchVehicle (v: VehicleDTOExtended): void {
    this.dataService.vehicleVinChanged(v.vin)
  }

  leasingWarn (leasingInfo: LoanDTO): boolean {
    if (leasingInfo == null) {
      return false
    } else {
      const leasingCalc = this.leasingService.calculateDays(leasingInfo)
      if (leasingCalc == null) {
        return false
      } else {
        return (
          leasingCalc.tillExpires <=
          this.remoteConfigService.get('multilease.leasingExpirationDays.warn')
        )
      }
    }
  }

  setBreakpoints (): void {
    this.calculateStartAt()
    const vehicleLength =
      (this.vehicles?.length != null ? this.vehicles.length : 0) +
      (this.vehiclesTNT?.length != null ? this.vehiclesTNT.length : 0)
    if (vehicleLength >= 0 && vehicleLength < 4) {
      switch (vehicleLength) {
        case 0:
        case 1:
          this.showBullets = false
          this.showArrows = false
          this.bound = !(this.mode === 'summary' && this.screenSizePx > 1024)
          this.fitGlider = !(
            this.mode === 'summary' && this.screenSizePx > 1024
          )

          this.breakpoints = {
            1024: { perView: 1, focusAt: 'center' },
            1280: { perView: 1, focusAt: 'center' },
            3840: { perView: 1, focusAt: 'center' }
          }
          break
        default:
          if (this.screenSizePx < 1024) {
            // small screen
            this.defaultBreakpoints()
            this.showBullets = true
            this.showArrows = true
            this.bound = false
            this.fitGlider = false
          } else if (this.screenSizePx < 1280) {
            // medium screen
            this.defaultBreakpoints()
            this.showBullets = vehicleLength > 2
            this.showArrows = vehicleLength > 2
            this.bound = !(vehicleLength > 2)
            this.fitGlider = !(vehicleLength > 2)
          } else {
            // large screen
            this.defaultBreakpoints()
            this.showBullets = false
            this.showArrows = false
            this.bound = true
            this.fitGlider = true
          }
          break
      }
      return
    }

    this.defaultBreakpoints()
    this.showBullets = true
    this.showArrows = true
    this.bound = false
    this.fitGlider = false
  }

  defaultBreakpoints (): void {
    this.breakpoints = {
      1024: { perView: 1, focusAt: 'center' },
      1280: { perView: 2, focusAt: 0 },
      3840: { perView: 3, focusAt: 0 }
    }
  }

  recreate (): void {
    setTimeout(() => {
      if (this.ngxGlide != null) {
        try {
          this.ngxGlide.recreate()
        } catch (error) {
          // no need to log
        }
      }
    })
  }

  calculateStartAt (): void {
    if (this.vin != null && this.vehicles?.[0] != null) {
      this.startAt = this.vehicles.findIndex(vehicle => {
        return vehicle.vin === this.vin
      })
    }

    if (this.commissionNumber != null && this.vehiclesTNT?.[0] != null) {
      this.startAt = this.vehiclesTNT.findIndex(vehicle => {
        return vehicle.commissionNumber === this.commissionNumber
      })
      if (this.startAt != null) {
        this.startAt =
          Number(this.startAt != null ? this.startAt : 0) +
          (this.vehicles?.length != null ? this.vehicles.length : 0)
      }
    }
  }

  afterRan (): void {
    const index = this.ngxGlide.getIndex()
    if (index >= 0) {
      const vehicle = this.vehicles[index]
      if (vehicle.vin !== this.vin) {
        this.goToVehicle(vehicle, this.tab)
      }
    }
  }

  load (): Observable<VehiclesResponse> {
    return this.vehiclesService.getVehicles(true, false)
  }

  addNewVehicle (): void {
    const dialogRef = this.dialog.open(AddNewVehicleComponent, {
      data: this.user,
      panelClass: 'mat-dialog-cpt'
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== false) {
        if (result.error != null) {
          this.loading = false
          const data = {
            title: 'shared.error',
            text: 'customerPortal.customer-portal.add-new-vehicle.failure.text'
          }
          if (
            result.error?.message?.includes('has owner') === true ||
            result.error?.errorMessage?.includes('has owner') === true ||
            result.message?.includes('has owner') === true
          ) {
            data.text =
              'customerPortal.customer-portal.add-new-vehicle.failure.text.has-owner'
          }
          this.dialog.open(ConfirmationPopupComponent, {
            data,
            panelClass: 'mat-dialog-cpt'
          })
        } else {
          this.loading = true
          this.dialog.open(ConfirmationPopupComponent, {
            data: {
              title: 'shared.success',
              text: 'customerPortal.customer-portal.add-new-vehicle.success.text'
            },
            panelClass: 'mat-dialog-cpt'
          })
          this.load().subscribe((data: VehiclesResponse) => {
            this.vehicles = data.userVehicles
            this.dataService.userVehiclesLoaded(data.userVehicles)
            void this.dataService.loadVehicleData(result)
            // if we are in vehicles view change url
            if (this.mode !== 'summary') {
              this.goToVehicle(result, this.tab)
            }
          })
        }
      } else {
        this.loading = false
      }
    })
  }

  sendContentModulesCTAData (contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }

  sendContentModulesSliderData (
    side: string,
    contentModuleLabel: string,
    galleryName: string
  ): void {
    this.cptGtmService.sendContentModulesData(
      'Gallery',
      side,
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Gallery|(${galleryName})`,
      side
    )
  }

  openRedirectConsentsPopup (): void {
    this.dialog.open(RedirectConsentsPopupComponent, {
      data: { user: this.user },
      panelClass: 'mat-dialog-cpt'
    })
  }

  hasVehicles (): boolean {
    return this.vehicles?.length !== 0 || this.vehiclesTNT?.length !== 0
  }
}
