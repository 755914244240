import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs'
import { DealerDTO } from '../interfaces/shared'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { NGXLogger } from 'ngx-logger'
export interface Marker {
  position: { lat: number; lng: number }
  options: { icon?: string; draggable: boolean }
  label?: string
  draggable?: boolean
  dealer?: DealerDTO
}
@Injectable({
  providedIn: 'root'
})
export class CptGoogleMapsService {
  apiLoaded: Observable<boolean>
  _maps = new BehaviorSubject<boolean>(false)

  onMapsLoaded = this._maps.asObservable()
  private readonly api

  constructor(
    private readonly logger: NGXLogger,
    private readonly httpClient: HttpClient,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.api = this.remoteConfigService.get('googleMaps.apiKey')
    this.apiLoaded = httpClient
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${this.api}&libraries=places`,
        'callback'
      )
      .pipe(
        map(() => true),
        catchError(err => {
          logger.error(err)
          return of(false)
        })
      )
  }

  onMapsChange(map: boolean): void {
    this._maps.next(map)
  }
}
