import { Component, Input } from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS } from '@angular/material/legacy-core'
import { CptDateAdapter } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { TranslateService } from '@ngx-translate/core'
import {
  APP_DATE_FORMATS_1,
  SharedService
} from '../../../../services/shared.service'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'

@Component({
  selector: 'customer-portal-app-appointments-popup-fourth-form',
  templateUrl: './appointments-popup-fourth-form.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: CptDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS_1
    }
  ]
})
export class AppointmentsPopupFourthFormComponent {
  @Input() appointmentsPopupService

  @Input()
  dialogRef

  dateLocale: string

  countryCode: string

  constructor(
    public sharedService: SharedService,
    public translateService: TranslateService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.countryCode = this.remoteConfigService.get('country.code')
    this.dateLocale = `${this.sharedService.currentLanguage()}-${this.countryCode.toUpperCase()}`
  }
}
