import { Pipe, PipeTransform } from '@angular/core'
import { VehicleType } from '../services/personalization.service'

@Pipe({
  name: 'vehicleType'
})
export class VehicleTypePipe implements PipeTransform {
  transform(
    value: VehicleType[],
    country: string,
    ...args: unknown[]
  ): VehicleType[] {
    return value.filter(type => type.country.includes(country))
  }
}
