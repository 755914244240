<div *ngIf="brandLinks && brandLinks[0]">
  <div
    *ngFor="let link of brandLinks"
    class="my-2.5 mx-0 p-2.5 bg-gray-100 text-mat-ef-revolution-primary-500 rounded-md w-full"
  >
    <a
      [href]="link.link"
      target="_blank"
      class="flex text-mat-ef-revolution-primary-500 justify-between items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          sharedService.template('transifexTranslation');
          context: {
            key: link.translation
          }
        "
      ></ng-container>
      <mat-icon svgIcon="chevron_right"></mat-icon>
    </a>
  </div>
</div>
