import { Injectable } from '@angular/core'
import { NGXLogger } from 'ngx-logger'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class CptGeolocationService {
  public onGeolocationChanged

  constructor(private readonly logger: NGXLogger) {
    this.onGeolocationChanged = new Observable(observer => {
      let watchId: number

      // Simple geolocation API check provides values to publish
      if ('geolocation' in navigator) {
        watchId = navigator.geolocation.watchPosition(
          (position: GeolocationPosition) => {
            observer.next(position)
          },
          (error: GeolocationPositionError) => {
            this.logger.info(error)
            observer.next(undefined)
          }
        )
      } else {
        this.logger.info('Geolocation not available')
        observer.next(undefined)
      }

      // When the consumer unsubscribes, clean up data ready for next subscription.
      return {
        unsubscribe() {
          navigator.geolocation.clearWatch(watchId)
        }
      }
    })
  }
}
