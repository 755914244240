<ng-container *ngIf="country === 'ch'">
  <nav id="navbar-container" class="bg-white -[999]">
    <div class="flex items-center h-[60px] lg:h-[80px]">
      <div class="w-full">
        <div class="py-1 px-2.5">
          <div
            class="relative flex items-center justify-between lg:mx-auto lg:max-w-1440"
          >
            <customer-portal-app-navbar-back
              *ngIf="screenSize === 'mobile'"
            ></customer-portal-app-navbar-back>

            <div
              class="flex items-center logo cursor-pointer"
              (click)="sendGTMNavigationData('Übersicht', '')"
              [routerLink]="['/']"
            >
              <mat-icon
                svgIcon="ef-logo-icon"
                class="w-10 h-10 md:w-[52px] md:h-[52px] text-mat-ef-primary-500"
              ></mat-icon>
              <div class="text-sm pl-3 lg:text-base flex gap-1">
                <span class="font-efSans text-mat-ef-primary-500 font-bold">
                  Emil Frey
                </span>
                <span class="font-efSans text-mat-ef-primary-500">
                  <!-- prettier-ignore -->
                  {{ country | translate:{ _key: 'customerPortal.customer-portal.country.' + country , _tags: 'customer-portal, notranslate'} }}
                </span>
              </div>
            </div>

            <div class="hidden grow md:flex justify-center">
              <ul
                class="-ml-[50px] flex text-sm lg:text-base gap-10 font-bold font-efSans-primary"
              >
                <li
                  class=""
                  *ngFor="let l of navbarLinks"
                  (click)="sendGTMNavigationData(l.translation, 'Textlink')"
                >
                  <a
                    [href]="getNavbarLink(l)"
                    target="_blank"
                    [attr.target]="getNavbarLink(l)"
                  >
                    <ng-container
                      *ngTemplateOutlet="
                        sharedService.template('transifexTranslation');
                        context: {
                          key: l?.translation
                        }
                      "
                    ></ng-container>
                  </a>
                </li>
              </ul>
            </div>

            <div class="h-full">
              <customer-portal-app-navbar-right
                (show)="setShow($event)"
              ></customer-portal-app-navbar-right>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <customer-portal-app-navbar-expand
    [show]="show"
  ></customer-portal-app-navbar-expand>
</ng-container>

<ng-container *ngIf="country === 'de'">
  <div>
    <customer-portal-app-header-v2></customer-portal-app-header-v2>
  </div>
</ng-container>
