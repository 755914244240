<div class="w-full flex items-center gap-4 h-[52px] min-h-[52px]">
  <mat-icon
    *ngIf="country === 'ch'"
    [matMenuTriggerFor]="languageChangeMenuComponent?.menu"
    #languageMenuTrigger="matMenuTrigger"
    svgIcon="language"
    class="items-center cursor-pointer h-full"
    yPosition="below"
    data-cy="language-change-trigger"
  >
  </mat-icon>
  <mat-icon
    svgIcon="person"
    [matMenuTriggerFor]="userMenuComponentMenu?.menu"
    class="cursor-pointer h-full"
    *ngIf="!loggedIn"
    yPosition="below"
  >
  </mat-icon>
  <div
    data-cy="user-menu-trigger"
    class="h-full flex justify-center items-center"
    [matMenuTriggerFor]="userMenuComponentMenu?.menu"
  >
    <div
      class="user-initials"
      *ngIf="loggedIn"
      yPosition="below"
      data-cy="loggedin-badge"
    >
      <span class="m-auto">{{ user | userinitials | async }}</span>
      <customer-portal-app-unread-mail
        class="absolute ml-[10px] -mt-[10px]"
      ></customer-portal-app-unread-mail>
    </div>
  </div>

  <ng-container *ngIf="country === 'ch' && !isMobilePwa">
    <mat-icon
      svgIcon="menu"
      class="inline-flex items-center rounded-lg md:hidden cursor-pointer h-full"
      [matMenuTriggerFor]="mobileMenuComponentMenu?.menu"
      yPosition="below"
    >
    </mat-icon>
  </ng-container>
  <ng-container
    *ngIf="country === 'de' && header?.navigationMain && !isMobilePwa"
  >
    <div
      class="h-full flex items-center"
      [matMenuTriggerFor]="menu2"
      (menuOpened)="showMenu2 = true"
      (menuClosed)="showMenu2 = false"
      yPosition="below"
    >
      <mat-icon
        [svgIcon]="!showMenu2 ? 'menu' : 'close'"
        class="menu2-icon items-center rounded-lg cursor-pointer"
      >
      </mat-icon>
    </div>
  </ng-container>

  <mat-menu class="w-screen max-w-none md:max-w-[288px] py-8" #menu2="matMenu">
    <div
      class="p-0 text-mat-ef-revolution-primary-500"
      *ngIf="header?.navigationMain"
    >
      <div
        *ngFor="let item of header.navigationMain; index as i; last as last"
        [ngClass]="{ 'pb-3': !last }"
      >
        <div
          *ngIf="item.children && item.children.length > 0"
          class="font-bold text-base cursor-pointer px-8 flex items-center justify-between gap-1"
          (click)="
            showMenu(i, $event);
            sendGTMNavigationDataBurger('Burger Menu', 'White Box Link')
          "
        >
          <span>{{ item.title }}</span>
          <mat-icon
            class="h-5 w-5"
            [svgIcon]="openedSubMenu === i ? 'expand_less' : 'expand_more'"
          >
          </mat-icon>
        </div>
        <div
          *ngIf="item.children && item.children.length < 1"
          class="font-bold text-base cursor-pointer px-8"
        >
          <a
            [href]="baseUrl + item.link"
            (click)="
              sendGTMNavigationDataBurger('Burger Menu', 'White Box Link')
            "
            target="_blank"
          >
            <span>{{ item.title }}</span></a
          >
        </div>

        <div *ngIf="item.children && i === openedSubMenu" class="ml-6 pb-3">
          <div
            *ngFor="let child of item.children"
            class="ml-[10px] text-sm cursor-pointer py-1 px-8"
          >
            <a
              *ngIf="child.link !== '/'"
              [href]="
                !child.link.startsWith('http')
                  ? baseUrl + child.link
                  : child.link
              "
              (click)="
                sendGTMNavigationDataBurger('Burger Menu', 'White Box Link')
              "
              target="_blank"
            >
              <span>{{ child.title }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </mat-menu>
</div>

<customer-portal-language-change-menu
  (saved)="languageMenuTrigger.closeMenu(); load()"
></customer-portal-language-change-menu>
<customer-portal-user-menu></customer-portal-user-menu>
<customer-portal-mobile-menu></customer-portal-mobile-menu>
