<mat-expansion-panel
  *ngIf="!loading"
  [hideToggle]="servicePackageIsDisabled"
  [disabled]="servicePackageIsDisabled"
  #servicePackagePanel
  (afterExpand)="
    sendContentModulesData(
      true,
      'customerPortal.customer-portal.vehicle.servicepackage'
    )
  "
  (afterCollapse)="
    sendContentModulesData(
      false,
      'customerPortal.customer-portal.vehicle.servicepackage'
    )
  "
>
  <mat-expansion-panel-header
    class="p-0 md:px-6"
    [collapsedHeight]="'auto'"
    [expandedHeight]="'auto'"
  >
    <mat-panel-title>
      <div class="mt-1 mr-1">
        <mat-icon
          class="text-mat-ef-revolution-primary-500"
          [ngClass]="servicePackagePanel.expanded ? 'blue-icon' : ''"
          svgIcon="build"
        ></mat-icon>
      </div>

      <div class="vehicle-expansion-panel-text" *ngIf="servicePackage">
        <div
          class="w-full pt-1 overflow-hidden whitespace-nowrap text-ellipsis"
        >
          <div class="flex text-sm sm:text-base font-bold">
            <span
              ><T
                str="Service package"
                key="customerPortal.customer-portal.vehicle.servicepackage"
                tags="customer-portal, 3.1"
              ></T
            ></span>
            <span
              *ngIf="showWarning"
              class="leasing-title-warning"
              [ngClass]="styleLeasingWarningIcon(servicePackages)"
            >
              <mat-icon svgIcon="error"></mat-icon>
            </span>
          </div>
        </div>

        <mat-progress-bar
          *ngIf="!servicePackage?.expired && servicePackage?.purchased"
          [ngClass]="styleServicePackageProgressBar(servicePackage)"
          class="h-0.5"
          mode="determinate"
          value="{{ percentageTillExpires.toString() }}"
          dir="rtl"
        >
        </mat-progress-bar>

        <div
          class="text-sm font-efSans-primary py-1 px-0"
          *ngIf="servicePackage?.expired && servicePackage?.purchased"
        >
          <T
            str="The service package expired on {endDate}."
            key="customerPortal.customer-portal.vehicle.servicepackage.expired"
            tags="customer-portal, 3.1"
            [vars]="{ endDate: servicePackage?.endDate | date: dateFormat }"
          ></T>
        </div>
        <div
          class="text-sm font-efSans-primary py-1 px-0"
          *ngIf="!servicePackage?.expired && servicePackage?.purchased"
        >
          <T
            str="The service package expires in {days} days."
            key="customerPortal.customer-portal.vehicle.servicepackage.expiration"
            tags="customer-portal, 3.1"
            [vars]="{ days: tillExpires }"
          ></T>
        </div>
      </div>
      <div class="vehicle-expansion-panel-text" *ngIf="!servicePackage">
        <div
          class="w-full pt-1 overflow-hidden whitespace-nowrap text-ellipsis"
        >
          <div class="flex text-sm sm:text-base font-bold">
            <span
              ><T
                str="Service package"
                key="customerPortal.customer-portal.vehicle.servicepackage"
                tags="customer-portal, 3.1"
              ></T
            ></span>
          </div>
        </div>

        <div class="text-sm font-efSanspy-1 px-0">
          <T
            str="No service pack"
            key="customerPortal.customer-portal.vehicle.servicepackage.none"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <mat-divider class="leasing-divider -mx-6 md:m-0"></mat-divider>

  <div class="-mx-6 md:m-0 text-mat-ef-revolution-primary-500 font-normal">
    <!-- brand warranty link -->
    <div [ngClass]="{ 'ml-0 md:ml-11': hasLinks }">
      <customer-portal-app-brand-links
        [type]="'servicePackage'"
        [brand]="vehicle?.brand"
        (hasLinks)="hasLinksChanged($event)"
      >
      </customer-portal-app-brand-links>
    </div>

    <!-- Service packages -->
    <div
      class="ml-0 md:ml-11 mt-2.5 gap-1 flex flex-col"
      *ngIf="servicePackages?.length > 0"
    >
      <ng-container *ngFor="let sp of purchasedServicePackages; last as last">
        <div class="mt-1">
          <span class="text-sm font-efSans-primary">{{ sp?.description }}</span>
        </div>

        <mat-progress-bar
          *ngIf="!sp.expired && sp !== servicePackage"
          [ngClass]="styleLeasingProgressBar(sp)"
          class="h-0.5"
          mode="determinate"
          value="{{ tillExpiration(sp).percentage.toString() }}"
          dir="rtl"
        >
        </mat-progress-bar>

        <div class="text-sm font-efSans-primary py-1 px-0" *ngIf="!sp.expired">
          <T
            str="Until"
            key="customerPortal.customer-portal.until"
            tags="customer-portal, 3.1"
          ></T>
          {{ sp.endDate | date: dateFormat }}
          <T
            str="The service package expires in {days} days."
            key="customerPortal.customer-portal.vehicle.servicepackage.expiration"
            tags="customer-portal, 3.1"
            [vars]="{ days: tillExpiration(sp).days }"
          ></T>
        </div>
        <ng-container *ngIf="sp.expired">
          <div
            class="text-sm font-efSans-primary py-1 px-0"
            *ngIf="sp.expireReason === 'DATE'"
          >
            <T
              str="The warranty expired on {endDate}."
              key="customerPortal.customer-portal.vehicle.extendedwarranty.expired.time"
              tags="customer-portal, 3.1"
              [vars]="{ endDate: sp.endDate | date: dateFormat }"
            ></T>
          </div>
          <div
            class="text-sm font-efSans-primary py-1 px-0"
            *ngIf="sp.expireReason === 'MILEAGE'"
          >
            <T
              str="The warranty expired on {maxMileage} km."
              key="customerPortal.customer-portal.vehicle.extendedwarranty.expired.mileage"
              tags="customer-portal, 3.1"
              [vars]="{ maxMileage: sp.maxMileage }"
            ></T>
          </div>
        </ng-container>

        <mat-divider *ngIf="!last" class="my-2"></mat-divider>
      </ng-container>

      <ng-container *ngIf="unpurchasedServicePackages?.length > 0">
        <div
          class="my-2.5 mx-0 p-2.5 bg-gray-100 rounded-md gap-1 flex flex-col"
          *ngFor="let o of unpurchasedServicePackages; last as last"
        >
          <div class="flex flex-row justify-between items-center">
            <div class="flex flex-col md:flex-row gap-2.5 items-baseline">
              <span class="text-sm text-efSans font-semibold">
                <T
                  str="Optional"
                  key="customerPortal.customer-portal.vehicle.warranty.optional"
                  tags="customer-portal, 3.1"
                ></T>
              </span>
              <span class="text-sm text-efSans">{{ o?.description }}</span>
            </div>
            <div
              class="min-w-fit text-sm font-efSans text-mat-ef-revolution-secondary-500 cursor-pointer bg-white px-4 py-1 rounded-2xl font-semibold inline-block overflow-hidden whitespace-nowrap"
              (click)="
                openWarrantyPackageContactPopup(
                  'offerRequest',
                  'servicePackage',
                  o
                )
              "
            >
              <T
                str="Inquire now"
                key="customerPortal.customer-portal.vehicle.warranty.requestNow"
                tags="customer-portal, 3.1"
              ></T>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</mat-expansion-panel>
