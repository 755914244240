import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { BehaviorSubject, Observable } from 'rxjs'
import { GenericObject, SharedService } from './shared.service'

@Injectable({
  providedIn: 'root'
})
export class TestService {
  private readonly apiUrl
  public loading = {
    sendingPushNotification: new BehaviorSubject<boolean>(false)
  }

  private readonly _pushNotificationResponse = new BehaviorSubject<string>(null)
  onPushNotificationResponse = this._pushNotificationResponse.asObservable()

  constructor(
    private readonly http: HttpClient,
    public sharedService: SharedService,
    private readonly logger: NGXLogger,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  sendPushNotification(
    pushNotification: GenericObject
  ): Observable<GenericObject> {
    return this.http.put<GenericObject>(
      this.apiUrl + '/_test/push-notification',
      pushNotification
    )
  }

  sendPushNotificationClicked(pushNotification): void {
    this.loading.sendingPushNotification.next(true)
    this.sendPushNotification(pushNotification).subscribe({
      next: data => {
        this.loading.sendingPushNotification.next(false)
        const dataStr = this.sharedService.previewJSON(data, {
          key: 'font-semibold'
        })
        this._pushNotificationResponse.next(dataStr)
      },
      error: error => {
        this.logger.log(error)
        this.loading.sendingPushNotification.next(false)
        const errStr = this.sharedService.previewJSON(error?.error ?? error, {
          key: 'font-semibold'
        })
        this._pushNotificationResponse.next(errStr)
      }
    })
  }
}
