<div id="social" class="p-6 lg:p-0 flex items-center gap-6 text-white">
  <a
    [href]="links.facebook"
    (click)="sendGTMNavigationData('Facebook')"
    target="_blank"
  >
    <mat-icon svgIcon="facebook"></mat-icon>
  </a>
  <a
    [href]="links.insta"
    (click)="sendGTMNavigationData('Instagram')"
    target="_blank"
  >
    <mat-icon svgIcon="instagram"></mat-icon>
  </a>
  <a
    [href]="links.youtube"
    (click)="sendGTMNavigationData('Youtube')"
    target="_blank"
  >
    <mat-icon svgIcon="youtube"></mat-icon>
  </a>
  <a
    [href]="links.linkedin"
    (click)="sendGTMNavigationData('Linkedin')"
    target="_blank"
  >
    <mat-icon svgIcon="linkedin"></mat-icon>
  </a>
</div>
