import { Component, EventEmitter, Input, Output } from '@angular/core'

export type cptMenuType = 'editDeleteMenu' | 'fileMenu'

export type cptMenuAction = 'download' | 'edit' | 'delete'

@Component({
  selector: 'customer-portal-app-cpt-menu',
  templateUrl: './cpt-menu.component.html'
})
export class CptMenuComponent {
  @Input() menuType: cptMenuType
  @Output() actionClicked = new EventEmitter()

  onActionClicked(action: string): void {
    this.actionClicked.emit(action)
  }
}
