<div class="flex gap-5 justify-end">

  <ng-container *ngIf="(showSalesInvoiceDownloadButton && filterOnlyDocumentSales(document))
  || (showServiceInvoiceDownloadButton && filterService(document)) && showPDFButtons(document)">
    <button mat-button mat-raised-button color="secondary" class="revolution min-h-[36px] h-[36px]"
      [disabled]="downloadStarted" (click)="
      document?.contractType === 'invoice'
        ? getInvoicePDF(document, 'open', $event)
        : downloadOpenSalesFile(document, 'open', $event)
    ">
      <mat-icon svgIcon="visibility_on" class="mx-0"></mat-icon>
    </button>
    <button mat-button mat-raised-button color="secondary" class="revolution min-h-[36px] h-[36px]"
      [disabled]="downloadStarted" (click)="
      document?.contractType === 'invoice'
        ? getInvoicePDF(document, 'download', $event)
        : downloadOpenSalesFile(document, 'download', $event)
    ">
      <!-- prettier-ignore -->
      <mat-icon class="mx-0"
        matTooltip="{{ 'Download' | translate:{ _key: 'customerPortal.customer-portal.vehicle.documents-menu.download', _tags: 'customer-portal, 3.1'}  }}"
        [matTooltipPosition]="'above'" svgIcon="download"></mat-icon>
    </button>
  </ng-container>

  <button mat-button mat-raised-button color="warn" class="revolution min-h-[36px] h-[36px]"
    [disabled]="downloadStarted" (click)="onDeleteDocumentClicked(document)"
    *ngIf="document.type === 'LEASING_CONTRACT'">
    <mat-icon svgIcon="delete" class="mx-0"></mat-icon>
  </button>
</div>