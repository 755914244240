<div
  data-cy="edit-birthday-form"
  [hidden]="!hidden"
  class="cpt-modal relative dialog-content-p-0 mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="Change birthdate"
      key="customerPortal.customer-portal.details.change.birthdate"
      tags="customer-portal, 3.1"
    ></T>
  </h2>
  <mat-dialog-content class="mat-typography">
    <form class="birthday-form" [formGroup]="birthdateForm" class="grid">
      <mat-form-field class="birthday w-full" appearance="legacy">
        <!-- prettier-ignore -->
        <input
        data-cy="edit-birthday-form-input"
          matInput
          (click)="picker.open()"
          readonly
          [(ngModel)]="defaultDate"
          [matDatepicker]="picker"
          [max]="maxDate"
          placeholder="{{'Birthdate' | translate:{ _key: 'shared.date-of-birth', _tags: 'customer-portal, 3.1'} }}"
          formControlName="birthdate"
        />
        <mat-datepicker-toggle
          class="calendar-size"
          matPrefix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker
          [calendarHeaderComponent]="customHeader"
          #picker
          (opened)="addScrolling(picker)"
        >
        </mat-datepicker>
        <span (click)="picker.open()" matSuffix>
          <mat-icon class="primary" svgIcon="create"></mat-icon>
        </span>

        <mat-error *ngIf="birthdateForm.controls.birthdate.invalid">
          {{
            transifexService.getErrorTranslation(
              birthdateForm.controls.birthdate,
              'customerPortal.customer-portal.details.error.'
            )
          }}
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-divider class="mt-0"></mat-divider>
  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>

    <button
      data-cy="edit-birthday-form-submit"
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      (click)="updateBirthdate()"
      cdkFocusInitial
      [disabled]="disabled || !birthdateForm.valid"
    >
      <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
      {{ 'save' | sharedTranslate }}
    </button>
  </mat-dialog-actions>
</div>

<div
  [hidden]="hidden"
  class="cpt-modal relative dialog-content-p-0 mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    {{ 'success' | sharedTranslate }}
  </h2>
  <mat-dialog-content class="mat-typography">
    <form class="form" [formGroup]="birthdateForm">
      <div class="password-form">
        <T
          str="Birthdate successfully changed"
          key="customerPortal.customer-portal.details.change.birthdate-changed"
          tags="customer-portal, 3.1"
        ></T>
      </div>
    </form>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions>
    <button class="dialog-close-button" (click)="dialogRef.close(true)">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <button
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      (click)="dialogRef.close(true)"
      cdkFocusInitial
    >
      <T
        str="Close"
        key="customerPortal.customer-portal.general.actions.close"
        tags="customer-portal, 3.1"
      ></T>
    </button>
  </mat-dialog-actions>
</div>
