import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { LocationStrategy, PathLocationStrategy } from '@angular/common'
import * as Sentry from '@sentry/angular-ivy'
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule
} from '@angular/common/http'
import {
  APP_INITIALIZER,
  type ApplicationRef,
  type DoBootstrap,
  NgModule
} from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MAT_LEGACY_DATE_LOCALE as MAT_DATE_LOCALE } from '@angular/material/legacy-core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

// libraries
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core'
import { StarRatingModule } from 'angular-star-rating'
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer'
import { NgxGlideModule } from 'ngx-glide'
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import {
  KeycloakAngularModule,
  type KeycloakOptions,
  KeycloakService
} from '@emilfreydigital/keycloak-angular'

// ef libraries
import {
  B2CModule,
  type Environment as B2cEnvironment
} from '@inside-hub-app/customer-portal-b2c-client'
import {
  PpMissingTranslationHandler,
  PpModule,
  type PpModuleConfig,
  PpTranslationLoader,
  type Translation,
  TranslationService
} from '@inside-hub-app/ef-ng-pp-client'
import { EfRemoteConfigModule } from '@inside-hub-app/ef-remote-config'

// views
import { AppRoutingModule } from './app-routing.module'

// components
import {
  CptJsonpInterceptor,
  CustomerPortalSharedModule,
  IconModule
} from '@inside-hub-app/customer-portal-shared'
import {
  type CustomerPortalConfig,
  CustomerPortalCustomerPortalConfigModule
} from '@inside-hub-app/customer-portal-config'
import { AppComponent } from './app.component'

import { type Observable } from 'rxjs'
import { ConfirmationPopupComponent } from './components/basic/confirmation-popup/confirmation-popup.component'
import { ConfirmationQuestionPopupComponent } from './components/basic/confirmation-question-popup/confirmation-question-popup.component'
import { CustomCalendarHeaderComponent } from './components/basic/custom-calendar-header/custom-calendar-header.component'
import { FooterV2Component } from './components/basic/footer-v2/footer-v2.component'
import { HeaderV2Component } from './components/basic/header-v2/header-v2.component'
import { SelectOptionsPopupComponent } from './components/basic/select-options-popup/select-options-popup.component'
import { MultilanguagePopupComponent } from './components/basic/multilanguage-popup/multilanguage-popup.component'
import { CompanyNumberLandlineComponent } from './components/user-details/company-number-landline/company-number-landline.component'
import { PasswordChangePopupComponent } from './components/user-details/password-change-popup/password-change-popup.component'
import { PersonalNumberLandlineComponent } from './components/user-details/personal-number-landline/personal-number-landline.component'
import { TelephoneChangePopupComponent } from './components/user-details/telephone-change-popup/telephone-change-popup.component'
import { CallRequestPopupComponent } from './components/vehicle-details/call-request-popup/call-request-popup.component'
import { ChangeSalesmanPopupComponent } from './components/vehicle-details/change-salesman-popup/change-salesman-popup.component'
import { DealerDetailsPopupComponent } from './components/vehicle-details/dealer-details-popup/dealer-details-popup.component'
import { FrequentDriverPopupComponent } from './components/vehicle-details/frequent-driver-popup/frequent-driver-popup.component'
import { RegistrationPlateChangePopupComponent } from './components/vehicle-details/registration-plate-change-popup/registration-plate-change-popup.component'
import { WarrantyContactPopupComponent } from './components/vehicle-details/warranty-contact-popup/warranty-contact-popup.component'
import { AddNewVehicleComponent } from './components/vehicle-list/add-new-vehicle/add-new-vehicle.component'

// Pipes
import { EditFirstRegistrationComponent } from './components/vehicle-details/edit-first-registration/edit-first-registration.component'
import { EditMileageComponent } from './components/vehicle-details/edit-mileage/edit-mileage.component'
import { LiveChatDialogComponent } from './components/vehicle-details/live-chat-dialog/live-chat-dialog.component'
import { RemoveVehiclePopupComponent } from './components/vehicle-details/remove-vehicle-popup/remove-vehicle-popup.component'
import { DealerNamePipe } from './pipes/dealername-pipe'
import { ReplacePipe } from './pipes/replace-pipe'
import { ArrayContainsPipe } from './pipes/array-contains-pipe'
import { DigitalManualsPipe } from './pipes/digital-manuals-pipe'
import { UserNamePipe } from './pipes/username-pipe'
import { SafePipe } from './pipes/safe-pipe'

import { LiveChatWidgetModule } from '@livechat/widget-angular'
import { GoogleTagManagerModule } from 'angular-google-tag-manager'
import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask'
import { CptMenuComponent } from './components/basic/cpt-menu/cpt-menu.component'
import { SizeDetectorComponent } from './components/basic/size-detector/size-detector.component'
import { ConsentSnackbarComponent } from './components/basic/consent-snackbar/consent-snackbar.component'
import { DealerInfoComponent } from './components/dealer/dealer-info/dealer-info.component'
import { DocumentPreviewComponent } from './components/documents/document-preview/document-preview.component'
import { DocumentIconComponent } from './components/documents/document-icon/document-icon.component'
import { DocumentButtonsComponent } from './components/documents/document-buttons/document-buttons.component'
import { DocumentHeaderComponent } from './components/documents/document-header/document-header.component'
import { OfferContractInfoComponent } from './components/documents/offer-contract-info/offer-contract-info.component'
import { RevolutionUpcomingAppointmentsComponent } from './components/revolution/appointments/revolution-upcoming-appointments/revolution-upcoming-appointments.component'
import { DeleteVehicleComponent } from './components/revolution/content/delete-vehicle/delete-vehicle.component'
import { TrackerStepperComponent } from './components/revolution/content/tracker-stepper/tracker-stepper.component'
import { HeroComponent } from './components/revolution/content/hero/hero.component'
import { AppointmentsComponent } from './components/revolution/content/notifications-panel/appointments/appointments.component'
import { NotificationsPanelComponent } from './components/revolution/content/notifications-panel/notifications-panel.component'
import { NotificationsComponent } from './components/revolution/content/notifications-panel/notifications/notifications.component'
import { RedirectConsentsPopupComponent } from './components/revolution/content/revolution-vehicle-list/redirect-consents-popup/redirect-consents-popup.component'
import { RevolutionVehicleListComponent } from './components/revolution/content/revolution-vehicle-list/revolution-vehicle-list.component'
import { SupportCardComponent } from './components/revolution/content/support-card/support-card.component'
import { UserNoticeCardComponent } from './components/revolution/content/user-notice-card/user-notice-card.component'
import { VehicleCardComponent } from './components/revolution/content/vehicle-card/vehicle-card.component'
import { RevolutionAddressChangePopupComponent } from './components/revolution/general-user-data/address-change-popup/address-change-popup.component'
import { RevolutionBirthdayPopupComponent } from './components/revolution/general-user-data/birthday-popup/birthday-popup.component'
import { RevolutionDeleteAccountPopupComponent } from './components/revolution/general-user-data/delete-account-popup/delete-account-popup.component'
import { RevolutionEmailChangePopupComponent } from './components/revolution/general-user-data/email-change-popup/email-change-popup.component'
import { RevolutionGeneralUserDataComponent } from './components/revolution/general-user-data/general-user-data.component'
import { MyAccountHeadComponent } from './components/revolution/general-user-data/my-account-head/my-account-head.component'
import { RevolutionMyVehicleNavigationComponent } from './components/revolution/general-user-data/my-account-navigation/my-account-navigation.component'
import { RevolutionNameChangePopupComponent } from './components/revolution/general-user-data/name-change-popup/name-change-popup.component'
import { PrivacyComponent } from './components/revolution/general-user-data/privacy/privacy.component'
import { RevolutionUploadDrivingLicensePopupComponent } from './components/revolution/general-user-data/upload-driving-license-popup/upload-driving-license-popup.component'
import { RevolutionUserImgDetailsPopupComponent } from './components/revolution/general-user-data/user-img-details-popup/user-img-details.component'
import { RevolutionUserPersonalisationComponent } from './components/revolution/general-user-data/user-personalisation/user-personalisation.component'
import { RevolutionMarketplaceRoutesViewComponent } from './components/revolution/marketplace-routes/marketplace-routes.component'
import { RevolutionMarketplaceCompareComponent } from './components/revolution/marketplace/marketplace-compare/marketplace-compare.component'
import { RevolutionMarketplaceFavoritesComponent } from './components/revolution/marketplace/marketplace-favorites/marketplace-favorites.component'
import { RevolutionMarketplaceNavigationComponent } from './components/revolution/marketplace/marketplace-navigation/marketplace-navigation.component'
import { RevolutionMarketplaceSearchagentComponent } from './components/revolution/marketplace/marketplace-searchagent/marketplace-searchagent.component'
import { RevolutionMarketplaceValuationsComponent } from './components/revolution/marketplace/marketplace-valuations/marketplace-valuations.component'
import { MyAccountViewComponent } from './components/revolution/my-account-view/my-account-view.component'
import { RevolutionMyDocumentsComponent } from './components/revolution/my-documents/my-documents.component'
import { LanguageChangeMenuComponent } from './components/revolution/navigation/language-change-menu/language-change-menu.component'
import { MobileMenuComponent } from './components/revolution/navigation/mobile-menu/mobile-menu.component'
import { NavbarExpandComponent } from './components/revolution/navigation/navbar-expand/navbar-expand.component'
import { NavbarRightComponent } from './components/revolution/navigation/navbar-right/navbar-right.component'
import { NavbarSecondComponent } from './components/revolution/navigation/navbar-second/navbar-second.component'
import { NavbarComponent } from './components/revolution/navigation/navbar/navbar.component'
import { UserMenuComponent } from './components/revolution/navigation/user-menu/user-menu.component'
import { BottomNavbarComponent } from './components/revolution/navigation/bottom-navbar/bottom-navbar.component'
import { RevolutionArticlesComponent } from './components/revolution/revolution-articles/revolution-articles.component'
import { DealerHoursComponent } from './components/revolution/revolution-vehicle-dealers/dealer-hours/dealer-hours.component'
import { RevolutionChangeDealerSellerPopupComponent } from './components/revolution/revolution-vehicle-dealers/revolution-change-dealer-seller-popup/revolution-change-dealer-seller-popup.component'
import { RevolutionDealerInfoComponent } from './components/revolution/revolution-vehicle-dealers/revolution-dealer-info/revolution-dealer-info.component'
import { RevolutionDealershipContactMoleculeComponent } from './components/revolution/revolution-vehicle-dealers/revolution-dealership-contact-molecule/revolution-dealership-contact-molecule.component'
import { RevolutionDealershipSummaryMoleculeComponent } from './components/revolution/revolution-vehicle-dealers/revolution-dealership-summary-molecule/revolution-dealership-summary-molecule.component'
import { RevolutionSearchDealerSellerPopupComponent } from './components/revolution/revolution-vehicle-dealers/revolution-search-dealer-seller-popup/revolution-search-dealer-seller-popup.component'
import { RevolutionVehicleDealersComponent } from './components/revolution/revolution-vehicle-dealers/revolution-vehicle-dealers.component'
import { RevolutionServicesComponent } from './components/revolution/services/services.component'
import { VehicleDetailsViewComponent } from './components/revolution/vehicle-details-view/vehicle-details-view.component'
import { DoesNotHaveComponent } from './components/revolution/vehicle-details/does-not-have/does-not-have.component'
import { VehicleBasicDetailsComponent } from './components/revolution/vehicle-details/vehicle-basic-details/vehicle-basic-details.component'
import { VehicleEnergyComponent } from './components/revolution/vehicle-details/vehicle-energy/vehicle-energy.component'
import { VehicleEquipmentComponent } from './components/revolution/vehicle-details/vehicle-equipment/vehicle-equipment.component'
import { VehicleExtendedDetailsComponent } from './components/revolution/vehicle-details/vehicle-extended-details/vehicle-extended-details.component'
import { RevolutionVehicleNavigationComponent } from './components/revolution/vehicle-details/vehicle-navigation/vehicle-navigation.component'
import { RevolutionVehicleDocumentDeleteConfirmationPopupComponent } from './components/revolution/vehicle-documents/vehicle-document-delete-confirmation-popup/vehicle-document-delete-confirmation-popup.component'
import { RevolutionVehicleDocumentEditPopupComponent } from './components/revolution/vehicle-documents/vehicle-document-edit-popup/vehicle-document-edit-popup.component'
import { RevolutionVehicleDocumentUploadPopupComponent } from './components/revolution/vehicle-documents/vehicle-document-upload-popup/vehicle-document-upload-popup.component'
import { RevolutionVehicleDocumentsComponent } from './components/revolution/vehicle-documents/vehicle-documents.component'
import { RevolutionLeasingContactPopupComponent } from './components/revolution/vehicle-general/leasing-contact-popup/leasing-contact-popup.component'
import { RevolutionServiceMoleculeComponent } from './components/revolution/vehicle-general/service-molecule/service-molecule.component'
import { SkeletonLoaderComponent } from './components/revolution/vehicle-general/skeleton-loader/skeleton-loader.component'
import { TradeMoleculeComponent } from './components/revolution/vehicle-general/trade-molecule/trade-molecule.component'
import { RevolutionTyreMoleculeComponent } from './components/revolution/vehicle-general/tyre-molecule/tyre-molecule.component'
import { VehiclLastActionServiceComponent } from './components/revolution/vehicle-general/vehicle-last-action-service/vehicle-last-action-service.component'
import { VehicleLeasingAndWarrantyComponent } from './components/revolution/vehicle-general/vehicle-leasing-and-warranty/vehicle-leasing-and-warranty.component'
import { VehicleServicePackageAndInsuranceComponent } from './components/revolution/vehicle-general/vehicle-service-package-and-insurance/vehicle-service-package-and-insurance.component'
import { RevolutionWarrantyContactPopupComponent } from './components/revolution/vehicle-general/warranty-contact-popup/warranty-contact-popup.component'
import { RevolutionWarrantyMoleculeComponent } from './components/revolution/vehicle-general/warranty-molecule/warranty-molecule.component'
import { RevolutionImageSliderComponent } from './components/revolution/vehicle-images/image-slider-popup/image-slider.component'
import { VehicleImagesComponent } from './components/revolution/vehicle-images/vehicle-images.component'
import { VehicleTrackTraceComponent } from './components/revolution/vehicle-track-trace/vehicle-track-trace.component'
import { UserNotificationsComponent } from './components/user-details/user-notifications/user-notifications.component'
import { BrandLinksComponent } from './components/vehicle-details/brand-links/brand-links.component'
import { EditLeasingExpiryComponent } from './components/vehicle-details/edit-leasing-expiry/edit-leasing-expiry.component'
import { EditNicknameComponent } from './components/vehicle-details/edit-nickname/edit-nickname.component'
import { VehicleWarningIndicatorComponent } from './components/vehicle-details/vehicle-warning-indicator/vehicle-warning-indicator.component'
import { EfPaginatorDirective } from './directives/ef-paginator.directive'
import { UserInitialsPipe } from './pipes/user-initials'
import { VpTranslatePipe } from './pipes/vp-translate-pipe'
import { RevolutionDetailsViewComponent } from './views/revolution/details-view/details-view.component'
import { RevolutionMarketplaceViewComponent } from './views/revolution/marketplace-view/marketplace-view.component'
import { RevolutionMyDocumentsViewComponent } from './views/revolution/my-documents-view/my-documents-view.component'
import { RevolutionAppointmentsViewComponent } from './views/revolution/revolution-appointments-view/revolution-appointments-view.component'
import { RevolutionServicesViewComponent } from './views/revolution/services-view/services-view.component'
import { SummaryComponent } from './views/revolution/summary/summary.component'
import { VehiclesNotViewComponent } from './views/revolution/vehicles-not-view/vehicles-not-view.component'
import { VehiclesViewComponent } from './views/revolution/vehicles-view/vehicles-view.component'
import { VehicleDocumentsDisclaimerComponent } from './components/revolution/vehicle-documents/vehicle-documents-disclaimer/vehicle-documents-disclaimer.component'
import { AppointmentsPopupComponent } from './components/revolution/appointments-popup/appointments-popup.component'
import { AppointmentsPopupFirstFormComponent } from './components/revolution/appointments-popup/appointments-popup-first-form/appointments-popup-first-form.component'
import { AppointmentsPopupSecondFormComponent } from './components/revolution/appointments-popup/appointments-popup-second-form/appointments-popup-second-form.component'
import { AppointmentsPopupThirdFormComponent } from './components/revolution/appointments-popup/appointments-popup-third-form/appointments-popup-third-form.component'
import { AppointmentsPopupFourthFormComponent } from './components/revolution/appointments-popup/appointments-popup-fourth-form/appointments-popup-fourth-form.component'
import { DatePickerFormatDirective } from './directives/date-picker-format.directive'
import { DisableControlDirective } from './directives/disable-control.directive'
import { RevolutionMyMailboxViewComponent } from './views/revolution/my-mailbox-view/my-mailbox-view.component'
import { RevolutionMyMailboxComponent } from './components/revolution/my-mailbox/my-mailbox.component'
import { UnreadMailComponent } from './components/revolution/content/unread-mail/unread-mail.component'
import { VehicleTypePipe } from './pipes/vehicle-type.pipe'
import { UnreadDocumentComponent } from './components/revolution/content/unread-document/unread-document.component'
import { VehicleProfilePictureComponent } from './components/revolution/content/vehicle-profile-picture/vehicle-profile-picture.component'
import { FAQCardComponent } from './components/revolution/content/faq-card/faq-card.component'
import { FAQViewComponent } from './views/revolution/faq-view/faq-view.component'
import { TestViewComponent } from './views/revolution/test-view/test-view.component'
import { FAQComponent } from './components/revolution/faq/faq.component'
import { TestComponent } from './components/revolution/test/test.component'
import { VehicleOnlineShopLinkComponent } from './components/revolution/vehicle-general/vehicle-online-shop-link/vehicle-online-shop-link.component'
import { NearestFuelStationComponent } from './components/revolution/vehicle-general/nearest-fuel-station/nearest-fuel-station.component'
import { VehicleRemindersComponent } from './components/revolution/vehicle-general/vehicle-reminders/vehicle-reminders.component'
import { VehicleRemindersFormComponent } from './components/revolution/vehicle-general/vehicle-reminders/vehicle-reminders-form/vehicle-reminders-form.component'
import { FuelPriceComparisonComponent } from './components/revolution/vehicle-general/fuel-price-comparison/fuel-price-comparison.component'
import { LoginViewComponent } from './views/login/login.component'
import { GoogleMapsModule } from '@angular/google-maps'
import { TxNativeModule } from '@emilfreydigital/transifex-angular'
import { ServiceWorkerModule } from '@angular/service-worker'
import { PwaDetectorComponent } from './components/basic/pwa-detector/pwa-detector.component'
import { PwaPromoBannerComponent } from './components/basic/pwa-promo-banner/pwa-promo-banner.component'
import { IosAddHomescreenBannerComponent } from './components/basic/ios-add-homescreen-banner/ios-add-homescreen-banner.component'
import { gdprComponent } from './components/revolution/general-user-data/gdpr/gdpr.component'
import { FooterContainerComponent } from './components/basic/footer-container/footer-container.component'
import { AboutViewComponent } from './views/revolution/about-view/about-view.component'
import { MarketFilterComponent } from './components/revolution/marketplace/market-filter/market-filter.component'
import { AcceptDeclineOfferPopupComponent } from './components/revolution/my-documents/accept-decline-offer-popup/accept-decline-offer-popup.component'
import { AppointmentsPopupChooseStepComponent } from './components/revolution/appointments-popup/appointments-popup-choose-step/appointments-popup-choose-step.component'

import { Router } from '@angular/router'
SelectOptionsPopupComponent
const keycloakService = new KeycloakService()
const remoteConfig = (window as any).ef.remoteConfig
  .configuration as CustomerPortalConfig

const ppEnvironments: PpModuleConfig = {
  storagePrefix: remoteConfig['ppEnvironments.cpt.storagePrefix'],
  translationPrefix: remoteConfig['ppEnvironments.cpt.translationPrefix'],
  environment: {
    domain: remoteConfig['ppEnvironments.cpt.environment.domain'],
    baseUrl: remoteConfig['ppEnvironments.cpt.environment.baseUrl'],
    authorizationExcludedUrls: [
      {
        pattern:
          remoteConfig[
            'ppEnvironments.cpt.environment.authorizationExcludedUrls.0.pattern'
          ]
      },
      {
        pattern:
          remoteConfig[
            'ppEnvironments.cpt.environment.authorizationExcludedUrls.1.pattern'
          ]
      },
      {
        pattern:
          remoteConfig[
            'ppEnvironments.cpt.environment.authorizationExcludedUrls.2.pattern'
          ]
      }
    ]
  }
}

const keycloakConfig: KeycloakOptions = {
  config: {
    clientId: remoteConfig['keycloak.config.clientId'],
    realm: remoteConfig['keycloak.config.realm'],
    url: remoteConfig['keycloak.config.url']
  },
  bearerExcludedUrls: remoteConfig['keycloak.bearerExcludedUrls'],
  enableBearerInterceptor: remoteConfig['keycloak.enableBearerInterceptor'],
  initOptions: {
    checkLoginIframe: remoteConfig['keycloak.initOptions.checkLoginIframe'],
    checkLoginIframeInterval:
      remoteConfig['keycloak.initOptions.checkLoginIframeInterval'],
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri:
      window.location.origin +
      remoteConfig['keycloak.initOptions.silentCheckSsoRedirectUri'],
    pkceMethod: 'S256'
  },
  loadUserProfileAtStartUp: remoteConfig['keycloak.loadUserProfileAtStartUp']
}

@NgModule({
  declarations: [
    AppComponent,
    DealerDetailsPopupComponent,
    RegistrationPlateChangePopupComponent,
    PasswordChangePopupComponent,
    TelephoneChangePopupComponent,
    AppointmentsPopupComponent,
    AppointmentsPopupFirstFormComponent,
    AppointmentsPopupSecondFormComponent,
    AppointmentsPopupThirdFormComponent,
    AppointmentsPopupFourthFormComponent,
    LiveChatDialogComponent,
    MultilanguagePopupComponent,
    CompanyNumberLandlineComponent,
    PersonalNumberLandlineComponent,
    CustomCalendarHeaderComponent,
    ConfirmationPopupComponent,
    ConfirmationQuestionPopupComponent,
    ChangeSalesmanPopupComponent,
    FrequentDriverPopupComponent,
    FooterV2Component,
    HeaderV2Component,
    WarrantyContactPopupComponent,
    ReplacePipe,
    UserNamePipe,
    DealerNamePipe,
    SafePipe,
    VpTranslatePipe,
    AddNewVehicleComponent,
    CallRequestPopupComponent,
    EditMileageComponent,
    EditFirstRegistrationComponent,
    RemoveVehiclePopupComponent,
    VehicleWarningIndicatorComponent,
    EditNicknameComponent,
    UserNotificationsComponent,
    EditLeasingExpiryComponent,
    CptMenuComponent,
    SizeDetectorComponent,
    DocumentPreviewComponent,
    EfPaginatorDirective,
    OfferContractInfoComponent,
    BrandLinksComponent,
    SummaryComponent,
    RevolutionArticlesComponent,
    RevolutionVehicleNavigationComponent,
    VehicleBasicDetailsComponent,
    VehicleExtendedDetailsComponent,
    VehicleEquipmentComponent,
    VehicleDetailsViewComponent,
    VehicleEnergyComponent,
    RevolutionVehicleDealersComponent,
    RevolutionDealershipSummaryMoleculeComponent,
    NavbarRightComponent,
    HeroComponent,
    NavbarSecondComponent,
    NotificationsPanelComponent,
    RevolutionVehicleListComponent,
    VehicleLeasingAndWarrantyComponent,
    RevolutionWarrantyMoleculeComponent,
    RevolutionWarrantyContactPopupComponent,
    RevolutionLeasingContactPopupComponent,
    RevolutionServiceMoleculeComponent,
    VehicleServicePackageAndInsuranceComponent,
    SkeletonLoaderComponent,
    RevolutionTyreMoleculeComponent,
    TradeMoleculeComponent,
    VehiclesViewComponent,
    DeleteVehicleComponent,
    VehiclLastActionServiceComponent,
    AppointmentsComponent,
    NotificationsComponent,
    RevolutionDealershipContactMoleculeComponent,
    RevolutionDealerInfoComponent,
    RevolutionVehicleDocumentUploadPopupComponent,
    RevolutionVehicleDocumentsComponent,
    RevolutionVehicleDocumentEditPopupComponent,
    RevolutionVehicleDocumentDeleteConfirmationPopupComponent,
    RevolutionImageSliderComponent,
    RevolutionChangeDealerSellerPopupComponent,
    RevolutionGeneralUserDataComponent,
    RevolutionDetailsViewComponent,
    RevolutionUserImgDetailsPopupComponent,
    RevolutionAddressChangePopupComponent,
    RevolutionBirthdayPopupComponent,
    RevolutionDeleteAccountPopupComponent,
    RevolutionEmailChangePopupComponent,
    RevolutionNameChangePopupComponent,
    RevolutionNameChangePopupComponent,
    RevolutionUploadDrivingLicensePopupComponent,
    DealerHoursComponent,
    RevolutionSearchDealerSellerPopupComponent,
    RevolutionMyDocumentsViewComponent,
    RevolutionServicesViewComponent,
    RevolutionMyDocumentsComponent,
    RevolutionServicesComponent,
    RevolutionAppointmentsViewComponent,
    RevolutionUpcomingAppointmentsComponent,
    RevolutionServicesViewComponent,
    RevolutionMyVehicleNavigationComponent,
    MyAccountViewComponent,
    MyAccountHeadComponent,
    PrivacyComponent,
    RevolutionUserPersonalisationComponent,
    NavbarExpandComponent,
    VehicleCardComponent,
    UserInitialsPipe,
    SupportCardComponent,
    VehiclesNotViewComponent,
    DoesNotHaveComponent,
    RevolutionMarketplaceViewComponent,
    RevolutionMarketplaceNavigationComponent,
    RevolutionMarketplaceFavoritesComponent,
    RevolutionMarketplaceCompareComponent,
    RevolutionMarketplaceSearchagentComponent,
    RevolutionMarketplaceValuationsComponent,
    RevolutionMarketplaceRoutesViewComponent,
    UserNoticeCardComponent,
    VehicleTrackTraceComponent,
    DealerInfoComponent,
    VehicleImagesComponent,
    RedirectConsentsPopupComponent,
    UserMenuComponent,
    MobileMenuComponent,
    LanguageChangeMenuComponent,
    NavbarComponent,
    VehicleDocumentsDisclaimerComponent,
    DatePickerFormatDirective,
    RevolutionMyMailboxViewComponent,
    RevolutionMyMailboxComponent,
    UnreadMailComponent,
    VehicleTypePipe,
    UnreadDocumentComponent,
    VehicleProfilePictureComponent,
    FAQCardComponent,
    FAQViewComponent,
    FAQComponent,
    VehicleOnlineShopLinkComponent,
    NearestFuelStationComponent,
    ArrayContainsPipe,
    DigitalManualsPipe,
    LoginViewComponent,
    VehicleRemindersComponent,
    VehicleRemindersFormComponent,
    DisableControlDirective,
    TestViewComponent,
    TestComponent,
    FuelPriceComparisonComponent,
    PwaDetectorComponent,
    PwaPromoBannerComponent,
    IosAddHomescreenBannerComponent,
    TrackerStepperComponent,
    BottomNavbarComponent,
    gdprComponent,
    FooterContainerComponent,
    AboutViewComponent,
    MarketFilterComponent,
    AppointmentsPopupChooseStepComponent,
    ConsentSnackbarComponent,
    AcceptDeclineOfferPopupComponent,
    AppointmentsPopupChooseStepComponent,
    SelectOptionsPopupComponent,
    DocumentIconComponent,
    DocumentButtonsComponent,
    DocumentHeaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    KeycloakAngularModule,
    NgxSkeletonLoaderModule,
    NgxExtendedPdfViewerModule,
    NgxGlideModule,
    GoogleMapsModule,
    ScrollingModule,
    LoggerModule.forRoot({
      enableSourceMaps: !remoteConfig.production,
      level: remoteConfig.production ? NgxLoggerLevel.OFF : NgxLoggerLevel.DEBUG
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [TranslationService, HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: PpMissingTranslationHandler
      }
    }),
    B2CModule.forRoot({
      environment: remoteConfig['b2c.environment'] as B2cEnvironment,
      apiKey: remoteConfig['b2c.apiKey']
    }),
    PpModule.forRoot(ppEnvironments),
    TxNativeModule.forRoot(),
    GoogleTagManagerModule.forRoot({
      id: remoteConfig['googleTagManager.gtmId']
    }),
    LiveChatWidgetModule,
    NgxMaskDirective,
    NgxMaskPipe,
    StarRatingModule.forRoot(),
    IconModule.forRoot({
      iconsPath: './assets/icons',
      useKcResourcePath: false
    }),
    CustomerPortalSharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: remoteConfig.pwaEnabled,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    EfRemoteConfigModule,
    CustomerPortalCustomerPortalConfigModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: KeycloakService, useValue: keycloakService },
    { provide: MAT_DATE_LOCALE, useValue: 'de-CH' },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    { provide: Window, useValue: window },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    // google maps wont work without this - ERROR: jsonp does not support options or headers
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CptJsonpInterceptor,
      multi: true
    },
    provideNgxMask()
  ]
})
export class AppModule implements DoBootstrap {
  ngDoBootstrap (app: ApplicationRef): void {
    keycloakService
      .init(keycloakConfig)
      .then(authenticated => {
        app.bootstrap(AppComponent)
      })
      .catch(error =>
      // eslint-disable-next-line no-console
      { console.error('[ngDoBootstrap] init Keycloak failed', error) }
      )
  }
}

export function getTranslations (http: HttpClient): Observable<Translation[]> {
  return http.get(
    '../assets/i18n/translations-' +
      remoteConfig['country.code'].toLowerCase() +
      '.json'
  ) as Observable<Translation[]>
}

export function HttpLoaderFactory (
  translationService: TranslationService,
  http: HttpClient
): PpTranslationLoader {
  if (window != null) {
    const params = new URL(window.location.href).searchParams
    if (params != null) {
      const showTranslationKeys = params.get('showTranslationKeys')
      if (showTranslationKeys === 'true') {
        return new PpTranslationLoader(
          [{ type: '', prefix: '' }],
          '',
          translationService
        )
      }
    }
  }

  return new PpTranslationLoader(
    [
      {
        type: 'customerPortal',
        prefix: 'customer-portal.'
      },
      {
        type: 'customerPortal',
        prefix: 'kc.'
      },
      {
        type: 'vp-modules',
        prefix: 'vp-modules.'
      }
    ],
    '',
    translationService,
    ['it', 'fr', 'de', 'en']
  )
}
