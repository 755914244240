<ng-container *ngIf="filterOnlyDocumentSales(document)">
  <mat-icon class="text-mat-ef-revolution-primary-500" svgIcon="directions_car"></mat-icon>
</ng-container>

<ng-container *ngIf="filterService(document)">
  <mat-icon class="text-mat-ef-revolution-primary-500" *ngIf="document?.code?.startsWith('R')"
    svgIcon="build"></mat-icon>
  <mat-icon *ngIf="document?.code?.startsWith('O')" svgIcon="offer"></mat-icon>
  <mat-icon class="text-mat-ef-revolution-primary-500" *ngIf="
    document?.code?.startsWith('L') ||
    document?.code?.startsWith('D') ||
    document?.code?.startsWith('I') ||
    document?.code?.startsWith('T') ||
    document?.code?.startsWith('N') ||
    document?.code?.startsWith('W')
  " svgIcon="insert_drive_file"></mat-icon>
  <mat-icon class="text-mat-ef-revolution-primary-500" *ngIf="
    document?.code?.startsWith('C') ||
    document?.code?.startsWith('M') ||
    document?.code?.startsWith('N')
  " svgIcon="directions_car"></mat-icon>

  <mat-icon class="text-mat-ef-revolution-primary-500" *ngIf="
    document?.code?.startsWith('V') ||
    document?.code?.startsWith('F')
  " svgIcon="car_ins">
  </mat-icon>
  <mat-icon class="text-mat-ef-revolution-primary-500" *ngIf="document?.code?.startsWith('G')"
    svgIcon="car_insurance"></mat-icon>
</ng-container>

<ng-container *ngIf="filterOnlyDocument(document)">
  <mat-icon class="text-mat-ef-revolution-primary-500" *ngIf="
    document?.format === 'jpg' ||
    document?.format === 'jpeg' ||
    document?.format === 'png'
  " svgIcon="broken_image">
  </mat-icon>
  <mat-icon class="text-mat-ef-revolution-primary-500" *ngIf="document?.format === 'pdf'" svgIcon="pdf_document">
  </mat-icon>
</ng-container>