/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

export interface KcLocaleData {
  current: string
  supported: Array<{ label: string; url: string }>
}
export interface KcData {
  localeData: KcLocaleData
  view: string
}

@Injectable({
  providedIn: 'root'
})
export class CptSharedDataService {
  private readonly data: KcData

  constructor() {
    if (window['ef']?.['kc']?.['data']) {
      this.data = window['ef']['kc']['data']
    }
  }

  public resourcesPath = new BehaviorSubject<string>('')

  public setResourcesPath(path: string): void {
    this.resourcesPath.next(path)
  }

  public getResourcesPath(): string {
    return this.resourcesPath.value
  }

  public getData() {
    return this.data
  }

  public getView(): string {
    return this.data ? this.data.view : null
  }

  public getLocaleData(): KcLocaleData {
    return this.data?.localeData
  }

  getLink(linkObj, primaryLang, defaultLanguage) {
    if (
      this.checkIfExists(primaryLang) &&
      this.checkIfExists(linkObj[primaryLang.toLowerCase()])
    ) {
      return linkObj[primaryLang.toLowerCase()]
    }
    return linkObj[defaultLanguage.toLowerCase()] || ''
  }

  checkIfExists(text: string) {
    if (
      text &&
      text !== null &&
      text !== undefined &&
      text !== '' &&
      text !== ' '
    ) {
      return true
    }
    return false
  }
}
