<div class="CTP-gdpr a-link block">
  <div class="privacy-title-text">
    {{ 'data-protection' | sharedTranslate }}
  </div>

  <ng-container *ngIf="loading">
    <div class="w-full p-5">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
        [count]="20"
      ></ngx-skeleton-loader>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading">
    <div
      class="font-efSans text-base text-justify text-mat-ef-revolution-primary-500"
    >
      <p>
        {{ consentsResponse?.legalText }}
      </p>
    </div>
    <p
      *ngIf="!consentsResponse?.group && !consentsResponse?.dealers"
      class="font-efSans text-base text-justify text-mat-ef-revolution-primary-500"
    >
      *
      <T
        str="No consents provided."
        key="customerPortal.customer-portal.communication.noconsents"
        tags="customer-portal, 3.1"
      ></T>
    </p>
    <mat-list
      *ngIf="consentsResponse?.group || consentsResponse?.dealers"
      class="gdpr-list"
    >
      <mat-list-item class="hidden md:block">
        <div
          [ngStyle]="getStyles()"
          class="grid w-full items-center uppercase text-mat-ef-revolution-grey-two-500"
        >
          <h3 class="col-span-3"></h3>
          <h3
            matListItemTitle
            *ngFor="let channel of headingChannels"
            class="font-light font-efSans text-mat-ef-revolution-grey-two-500 flex justify-end"
          >
            <ng-container
              *ngTemplateOutlet="communication; context: { channel: channel }"
            ></ng-container>
          </h3>
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-divider class="block md:hidden relative w-full"></mat-divider>

      <ng-container *ngIf="consentsResponse?.group">
        <ng-container
          *ngTemplateOutlet="
            consent;
            context: { consent: consentsResponse?.group, type: 'group' }
          "
        ></ng-container>
        <mat-divider></mat-divider>
      </ng-container>

      <ng-container *ngIf="consentsResponse?.dealers?.length > 0">
        <ng-container
          *ngFor="let d of consentsResponse?.dealers | orderBy: 'name'"
        >
          <ng-container
            *ngTemplateOutlet="consent; context: { consent: d, type: 'dealer' }"
          ></ng-container>
          <mat-divider></mat-divider>
        </ng-container>
      </ng-container>

      <div *ngIf="consentsResponse?.brands?.length > 0">
        <ng-container
          *ngFor="let b of consentsResponse?.brands | orderBy: 'name'"
        >
          <ng-container
            *ngTemplateOutlet="consent; context: { consent: b, type: 'brand' }"
          ></ng-container>
        </ng-container>
        <mat-divider class="mt-4"></mat-divider>
      </div>
    </mat-list>

    <div
      class="flex flex-row-reverse max-w-[1000px] mt-2.5"
      *ngIf="
        (consentsResponse?.group || consentsResponse?.dealers) && !editDisabled
      "
    >
      <button
        mat-button
        mat-flat-button
        color="secondary"
        id="saveButton"
        class="revolution"
        (click)="updateConsents()"
        [disabled]="disabled || updates.length === 0"
      >
        <mat-spinner [diameter]="16" *ngIf="disabled"></mat-spinner
        ><span> {{ 'save' | sharedTranslate }}</span>
      </button>
    </div>
  </ng-container>
</div>

<ng-template
  #checkbox
  let-consent="consent"
  let-type="type"
  let-channel="channel"
>
  <mat-checkbox
    class="h-6 w-6 md:float-right"
    *ngIf="!checkIfDisabled(consent, channel.name)"
    [disabled]="!checkIfExist(consent, channel.name)"
    (click)="errorMessage(channel.name)"
    color="primary"
    [checked]="getConsentValue(consent, channel.name)"
    (change)="updateConsentValue(type, consent.id, channel.name, $event)"
  ></mat-checkbox>
</ng-template>

<ng-template #communication let-channel="channel">
  {{
    {
      str: channel.name,
      prefix: 'customerPortal.customer-portal.communication.'
    } | transifexTranslate
  }}
</ng-template>

<ng-template #logoName let-consent="consent" let-type="type">
  <div class="flex w-full">
    <div class="max-w-[50px] min-w-[50px] mr-4 pt-2">
      <img
        class="w-auto h-auto max-w-full max-h-full object-contain rounded m-auto"
        src="{{ consent?.logo ? consent.logo : 'assets/ef-logo.svg' }}"
        alt=""
        onerror="this.onerror=null; this.src='assets/ef-logo.svg'"
      />
    </div>
    <div class="flex-1 whitespace-normal">
      <div class="font-semibold font-efSans-primary">
        {{ consent.name }}
      </div>
      <div class="font-efSans-primary text-sm">
        <ng-container *ngIf="type === 'brand' && consent.consents?.name">{{
          consent.consents.name
        }}</ng-container>
        {{ consent.street }}{{ consent.zip ? ', ' + consent.zip : '' }}
        {{ consent.city }}
      </div>
      <div
        class="font-efSans-primary text-xs pt-1"
        *ngIf="consent.consents?.legalText"
        [innerHTML]="consent.consents?.legalText | safeHtml"
      >
        <!-- <mat-checkbox
          class="h-6 w-6 md:float-right"
          color="primary"
          [checked]="getConsentValue(consent, 'signed')"
          (change)="updateConsentValue(type, consent.id, 'signed', $event)"
        ></mat-checkbox> -->
        <!-- {{ consent.consents.legalText }} -->
      </div>
    </div>
  </div>
</ng-template>

<ng-template #consent let-consent="consent" let-type="type">
  <mat-list-item [ngClass]="{ 'noYPadding pt-4': type === 'brand' }">
    <div
      matListItemTitle
      [ngStyle]="getStyles()"
      class="hidden md:grid w-full items-center"
    >
      <div class="flex flex-col col-span-3">
        <ng-container
          *ngTemplateOutlet="
            logoName;
            context: { consent: consent, type: type }
          "
        ></ng-container>
      </div>
      <span
        class="grid-list-items list-none"
        *ngFor="let channel of headingChannels"
      >
        <ng-container
          *ngTemplateOutlet="
            checkbox;
            context: { consent: consent, type: type, channel: channel }
          "
        ></ng-container>
      </span>
    </div>

    <div matListItemLine class="grid md:hidden">
      <div class="flex flex-col">
        <ng-container
          *ngTemplateOutlet="
            logoName;
            context: { consent: consent, type: type }
          "
        ></ng-container>
      </div>
      <div class="flex flex-col gap-4 my-4">
        <span
          class="grid-list-items flex justify-between items-center"
          [ngClass]="{
            hidden: checkIfDisabled(consent, channel.name),
            'opacity-50': !checkIfExist(consent, channel.name)
          }"
          *ngFor="let channel of headingChannels"
        >
          <h6 matListItemLine>
            <ng-container *ngIf="!checkIfDisabled(consent, channel.name)">
              <ng-container
                *ngTemplateOutlet="communication; context: { channel: channel }"
              ></ng-container>
            </ng-container>
          </h6>
          <ng-container
            *ngTemplateOutlet="
              checkbox;
              context: { consent: consent, type: type, channel: channel }
            "
          ></ng-container>
        </span>
      </div>
    </div>
  </mat-list-item>
</ng-template>
