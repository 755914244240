import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { NGXLogger } from 'ngx-logger'
import { DocumentsService } from '../../../../services/documents.service'
import { SharedService } from '../../../../services/shared.service'
import { ConfirmationPopupComponent } from '../../../basic/confirmation-popup/confirmation-popup.component'
import { ConfirmationQuestionPopupComponent } from '../../../basic/confirmation-question-popup/confirmation-question-popup.component'

import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'

@Component({
  selector: 'customer-portal-app-revolution-upload-driving-license-popup',
  templateUrl: './upload-driving-license-popup.component.html'
})
export class RevolutionUploadDrivingLicensePopupComponent implements OnInit {
  disabled = true
  propperSize = true
  imgType = ''
  originalFile
  preview = null
  savingInProgress = false
  imgUrl = null as string
  propperFileNameLength = true

  showDeleteButton = false
  maxSize = 10
  constructor(
    private readonly documentsService: DocumentsService,
    @Inject(MAT_DIALOG_DATA) public documents,
    public dialogRef: MatDialogRef<RevolutionUploadDrivingLicensePopupComponent>,
    private readonly dialog: MatDialog,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly logger: NGXLogger,
    public sharedService: SharedService,
    private readonly transifexTranslationsService: TranslationService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {}

  ngOnInit(): void {
    if (this.documents[this.imgType] != null) {
      this.imgUrl =
        this.documents[this.imgType] === 'front' ||
        this.documents[this.imgType] === 'back'
          ? ''
          : this.documents[this.imgType]

      if (this.sharedService.stringExists(this.imgUrl)) {
        this.disabled = false
        this.showDeleteButton = true
      }
    }
  }

  checkFilenameLength(name: string): boolean {
    return name.length < 40
  }

  loadImage(ev): void {
    const inputValue = (ev.target as HTMLInputElement).files[0]
    const reader: FileReader = new FileReader()
    const file: File = inputValue

    if (file !== undefined) {
      if (
        file.size < this.remoteConfigService.get('regEx.imageFile.maxSize10Mb')
      ) {
        reader.onload = e => {
          this.preview = e.target.result // for immediate display
          this.disabled = false
        }

        this.propperFileNameLength = this.checkFilenameLength(file.name)
        reader.readAsDataURL(file)
        this.originalFile = inputValue
        this.propperSize = true
      } else {
        this.propperSize = false
      }
      this.sendFileUpload('Step 1')
    }
  }

  deleteImage(): void {
    this.dialogRef.close()

    const dialogRef = this.dialog.open(ConfirmationQuestionPopupComponent, {
      data: {
        title: 'shared.clear',
        text: 'customerPortal.customer-portal.details.update-avatar-popup.delete-licence-confirmation',
        cancel: 'shared.cancel',
        save: 'shared.clear',
        saveButtonColor: 'warn',
        preventCloseBeforeComplete: true
      },
      panelClass: 'mat-dialog-cpt'
    })
    const sub = dialogRef.componentInstance.confirm.subscribe(() => {
      this.documentsService.deleteDriversLicence(this.imgType).subscribe(
        () => {
          dialogRef.close()
          this.documents[this.imgType] = ''
          this.dialogRef.close()

          this.dialog.open(ConfirmationPopupComponent, {
            data: {
              title: 'shared.successful',
              text: 'customerPortal.customer-portal.details.update-avatar-popup.delete-licence.success'
            },
            panelClass: 'mat-dialog-cpt'
          })
        },
        () => {
          dialogRef.close()
          this.dialog.open(ConfirmationPopupComponent, {
            data: {
              title: 'shared.error',
              text: 'customerPortal.customer-portal.details.update-avatar-popup.delete-licence.failure'
            },
            panelClass: 'mat-dialog-cpt'
          })
        }
      )
    })
    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe()
    })
  }

  sendFileUpload(step: string): void {
    this.cptGtmService.sendFileUpload('img', step)
  }

  save(): void {
    this.savingInProgress = true
    this.documentsService
      .postDriversLicence(this.imgType, this.originalFile)
      .subscribe(
        response => {
          this.documents[this.imgType] =
            String(response[this.imgType].publicUrl) +
            '&ts=' +
            String(new Date().getTime())
          this.dialogRef.close()
          this.dialog.open(ConfirmationPopupComponent, {
            data: {
              title: 'shared.successful',
              text: 'customerPortal.customer-portal.details.update-avatar-popup.replace-licence.success'
            },
            panelClass: 'mat-dialog-cpt'
          })

          this.sendFileUpload('Step 2')
        },
        error => {
          this.dialogRef.close(true)
          this.dialog.open(ConfirmationPopupComponent, {
            data: {
              title: 'shared.successful',
              text: 'customerPortal.customer-portal.details.update-avatar-popup.replace-licence.failure'
            },
            panelClass: 'mat-dialog-cpt'
          })
          this.logger.debug(error)
        }
      )
  }

  getTranslation(): string {
    return this.transifexTranslationsService.translate(
      'Insert image here or search image',
      {
        _key: 'customerPortal.customer-portal.post-picture-here-or-after',
        _tags: 'customer-portal, 3.1'
      }
    )
  }
}
