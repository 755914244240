import { Component, OnDestroy, ViewEncapsulation } from '@angular/core'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { TranslateService } from '@ngx-translate/core'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../services/data.service'
import {
  ActionPartial,
  FooterCardPartial,
  HeaderFooterTestingService,
  LegalNoticePartial,
  LogoPartial,
  NewsletterPartial,
  SocialMediaPartial,
  StructureFooterPartial
} from '../../../services/header-footer-service.service'
import { SharedService } from '../../../services/shared.service'

@Component({
  selector: 'customer-portal-app-footer-v2',
  templateUrl: './footer-v2.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FooterV2Component implements OnDestroy {
  public footer: StructureFooterPartial
  public logo: LogoPartial
  pages
  cols
  public newsletter: NewsletterPartial
  public socialMedia: SocialMediaPartial[]
  public legals: ActionPartial[]
  baseUrl: string
  public legalNotice: LegalNoticePartial
  public image: Record<string, unknown>
  public card: FooterCardPartial
  countryName

  onHeaderFooterDataLoadedSub
  isMobilePwa
  efWebsiteUrl
  constructor(
    private readonly headerFooterTestingService: HeaderFooterTestingService,
    private readonly translateService: TranslateService,
    private readonly logger: NGXLogger,
    private readonly dataService: DataService,
    private readonly sharedService: SharedService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.countryName = this.remoteConfigService.get('country.name')
    this.efWebsiteUrl = this.remoteConfigService.get('efWebsiteUrl')

    this.onHeaderFooterDataLoadedSub =
      this.dataService.onHeaderFooterDataLoaded.subscribe(value => {
        if (value != null) {
          this.footer = value.structure.footer
          this.logo = value.structure.footer.content.header.logo[0]
          this.pages = value.structure.footer.content.pages
          this.cols = this.splitArrayIntoChunksOfLen(this.pages, 6)
          this.newsletter = value.structure.footer.content.newsletter
          this.socialMedia = value.structure.footer.content.socialmedia
          this.legals = value.structure.footer.content.legal
          this.legalNotice = value.structure.footer.content.legalNotice
          this.image =
            value.structure.footer.content.card.content.imageMedium[0][0]
          this.card = value.structure.footer.content.card
        }
      })

    this.baseUrl =
      this.efWebsiteUrl +
      (this.remoteConfigService.get('country.code') === 'ch'
        ? '/' + this.translateService.currentLang
        : '')

    this.isMobilePwa = this.sharedService.isMobilePwa()
  }

  ngOnDestroy(): void {
    try {
      this.onHeaderFooterDataLoadedSub.unsubscribe()
    } catch (error) {
      // no need to show log
    }
  }

  splitArrayIntoChunksOfLen(arr, len: number): unknown {
    const chunks = []
    let i = 0
    const n = arr.length
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)))
    }
    return chunks
  }

  sendGTMNavigationData(label?: string): void {
    this.cptGtmService.sendNavigationLinkData(null, 'Footer Navigation', label)
  }
}
