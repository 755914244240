import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { UserService } from '@inside-hub-app/customer-portal-b2c-client'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { SharedService } from '../../../../services/shared.service'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'

interface DeleteReasonOption {
  translatedQuestion: string
  question: string
  id: number
}

@Component({
  selector: 'customer-portal-app-revolution-delete-account-popup',
  templateUrl: './delete-account-popup.component.html'
})
export class RevolutionDeleteAccountPopupComponent
  implements OnInit, OnDestroy
{
  error = false
  currentOption: DeleteReasonOption

  options: DeleteReasonOption[] = [
    {
      translatedQuestion: '',
      question:
        'customerPortal.customer-portal.details.delete-account.confirmation.not-using',
      id: 1
    },
    {
      translatedQuestion: '',
      question:
        'customerPortal.customer-portal.details.delete-account.confirmation.not-customer',
      id: 2
    },
    {
      translatedQuestion: '',
      question:
        'customerPortal.customer-portal.details.delete-account.confirmation.no-value',
      id: 3
    },
    {
      translatedQuestion: '',
      question:
        'customerPortal.customer-portal.details.delete-account.confirmation.other-reasons',
      id: 4
    }
  ]

  sub = {
    onTransifexLanguageChange: null
  }

  deleteAccountForm = new FormGroup({
    reasonId: new FormControl<number | null>(null),
    note: new FormControl<string | null>(null)
  })

  public loading: boolean

  constructor(
    private readonly userService: UserService,
    private readonly keycloak: KeycloakService,
    private readonly translateService: TranslateService,
    private readonly transifexTranslationsService: TranslationService,
    private readonly transifexService: TransifexService,
    public sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.deleteAccountForm.valueChanges.subscribe(() => {
      this.currentOption = this.options.find(
        option => option.id === this.deleteAccountForm.controls.reasonId.value
      )
    })

    // get translations for all options
    this.translateService
      .get(this.options.map(o => o.question))
      .subscribe(translations => {
        this.options.forEach(option => {
          option.translatedQuestion = translations[option.question]
        })
      })
  }

  deleteAccount(): void {
    this.loading = true
    this.sub.onTransifexLanguageChange =
      this.transifexService.onTransifexLanguageChange.subscribe(() => {
        const translation = this.transifexTranslationsService.translate(
          this.currentOption.question,
          {
            _key: this.currentOption.question,
            _tags: 'customer-portal, 3.1, notranslate'
          }
        )
        let reason: string = translation
        reason +=
          this.deleteAccountForm.get('note').value != null
            ? '\n\n' + String(this.deleteAccountForm.get('note').value)
            : ''
        this.userService.deleteAccount({ reason }).subscribe(
          () => {
            void this.keycloak.login()
          },
          () => {
            this.loading = false
            this.error = true
          }
        )
      })
  }

  ngOnDestroy(): void {
    this.sharedService.unsubscribe(this.sub)
  }
}
