import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGoogleTagmanagerService,
  GTMEventData
} from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import {
  LeasingCalculations,
  LeasingService,
  LoanDTO
} from '../../../../services/leasing.service'
import { RecallDTO } from '../../../../services/recalls.service'
import { SharedService } from '../../../../services/shared.service'
import { BasicDocumentDTOExtended } from '../../../../services/vehicle-documents.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'
import { AppointmentsPopupComponent } from '../../../revolution/appointments-popup/appointments-popup.component'
import { RevolutionLeasingContactPopupComponent } from '../leasing-contact-popup/leasing-contact-popup.component'
import { WarrantyService } from '../../../../services/warranty.service'
import { differenceInMonths } from 'date-fns'

@Component({
  selector: 'customer-portal-app-revolution-vehicle-leasing-and-warranty',
  templateUrl: './vehicle-leasing-and-warranty.component.html'
})
export class VehicleLeasingAndWarrantyComponent
  implements OnInit, OnDestroy, OnChanges
{
  loadingDocuments: boolean
  currentDay: Date
  public leasingCalc: LeasingCalculations
  public leasingExpirationDaysCritical
  public leasingExpirationDaysWarn
  public currency: string
  public currencyLocale: string
  hasLeasingDocuments = false
  public hasWarranty
  public enableEfitData
  user: User
  saving: boolean
  loadingWarranty: boolean
  featuresWarranty
  hasLeasingExtensionPopup
  dateFormat: string

  leasingWarrantySPLoaded = false
  maintenanceLoaded = false

  componentSub = {
    UserLoaded: null,
    recalls: null,
    documents: null,
    serviceHistory: null,
    warranty: null,
    ServiceHistoryLoaded: null,
    DocumentsLoaded: null,
    AppintmentLinkLoaded: null,
    RecallsLoaded: null,
    servicePackage: null
  }

  constructor(
    private readonly leasingService: LeasingService,
    public dialog: MatDialog,
    private readonly logger: NGXLogger,
    private readonly dataService: DataService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly sharedService: SharedService,
    private readonly warrantyService: WarrantyService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.dateFormat = this.remoteConfigService.get('dateFormat.long')
    this.leasingExpirationDaysCritical = this.remoteConfigService.get(
      'multilease.leasingExpirationDays.critical'
    )
    this.leasingExpirationDaysWarn = this.remoteConfigService.get(
      'multilease.leasingExpirationDays.warn'
    )
    this.currency = this.remoteConfigService.get('currency')
    this.currencyLocale = this.remoteConfigService.get('currencyLocale')
    this.hasWarranty = this.remoteConfigService.get('warranty.available')
    this.enableEfitData = this.remoteConfigService.get('enableEfitData')
    this.featuresWarranty = this.remoteConfigService.get('features.warranty')
    this.hasLeasingExtensionPopup = this.remoteConfigService.get(
      'hasLeasingExtensionPopup'
    )
  }

  @Input() vehicle: VehicleDTOExtended

  @Input()
  public vin: string

  appointments = [{ name: '' }, { name: '' }]
  error = null
  leasingResponse: LoanDTO = null
  page = 1
  size = 1
  months: number
  loadingServiceHistory: boolean
  numberOfWarnings = 0
  exists = true
  recallResponse: RecallDTO

  leasingIsDisabled = true

  @ViewChild('serviceHistories') public serviceHistoriesPanel
  @ViewChild('leasing') public leasingPanel
  @ViewChild('recalls') public recallsPanel

  ngOnInit(): void {
    if (this.vehicle == null) {
      this.exists = false
    }

    this.componentSub.UserLoaded = this.dataService.onUserLoaded.subscribe(
      user => {
        this.user = user
      }
    )

    this.componentSub.documents = this.dataService.loading.documents.subscribe(
      loading => {
        this.loadingDocuments = loading
        this.checkAllLoaded()
      }
    )

    this.componentSub.warranty = this.dataService.loading.warranty.subscribe(
      loading => {
        this.loadingWarranty = loading
        this.checkAllLoaded()
      }
    )

    this.componentSub.DocumentsLoaded =
      this.dataService.onVehicleDocumentsLoaded.subscribe(
        documents => {
          if (
            documents != null &&
            this.checkIfHasLeasingDocuments(documents) != null
          ) {
            this.hasLeasingDocuments = true
          }
        },
        err => {
          this.logger.debug(err)
        }
      )
  }

  setLeasing(): void {
    const data = this.vehicle?.loan
    this.leasingCalc = null
    this.leasingResponse = data != null ? data[0] ?? data : null
    if (this.leasingResponse != null) {
      this.leasingIsDisabled = false
    } else {
      this.leasingIsDisabled = true
    }
    if (
      this.leasingResponse?.startDate != null &&
      this.leasingResponse?.endDate != null
    ) {
      this.leasingCalc = this.leasingService.calculateDays(this.leasingResponse)
      if (this.leasingCalc.percentageLeft < 5) {
        this.leasingCalc.percentageLeft = 5
      }
    }
  }

  sendContentModulesData(expanded: boolean, contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Accordion',
      expanded ? 'Open' : 'Close',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Accordion|${this.sharedService.translateLink(contentModuleLabel) ?? ''}`,
      expanded ? 'Open' : 'Close'
    )
  }

  ngOnDestroy(): void {
    this.componentSubUnsubscribe()
  }

  componentSubUnsubscribe(): void {
    for (const sub in this.componentSub) {
      try {
        this.componentSub[sub].unsubscribe()
      } catch (error) {
        // no need to show log
      }
    }
  }

  ngOnChanges(): void {
    // close the panels if they were expaned
    this.closePanels()
    this.setLeasing()

    // on next car set number of tire warnings to 0
    this.numberOfWarnings = 0
    this.loadingDocuments = false
  }

  closePanels(): void {
    this.leasingIsDisabled = true
    try {
      this.leasingPanel.expanded = false
    } catch (error) {
      // no need to show log
    }
  }

  styleLeasingProgressBar(w): string {
    return this.warrantyService.styleProgressBar(w, 'servicePackage')
  }

  iconType(style): string {
    if (style === 'ok-green') {
      return 'check_circle'
    }
    if (style === 'warn-orange') {
      return 'error'
    }
    return 'error'
  }

  newAppointment(
    vehicle: VehicleDTOExtended,
    step?: number,
    serviceAction?: boolean
  ): void {
    this.dialog.open(AppointmentsPopupComponent, {
      data: { vehicle, step, serviceAction },
      panelClass: [
        'mat-dialog-cpt',
        'scrollable',
        'mat-dialog-appointments',
        'new-appointment'
      ]
    })
  }

  calculateLeasingMonths(from: Date, to: Date): number {
    return Math.round(differenceInMonths(to, from))
  }

  onLeasingDocumentButtonClicked(): void {
    /** GTM 2.7.6 */
    const gtmData: GTMEventData = {
      event: 'gaEvent2',
      event_name: 'service',
      service: {
        button_action: 'Leasing',
        service_type: 'Documents',
        event_action: 'Documents',
        event_category: 'Service',
        event_label: 'Leasing'
      }
    }
    this.cptGtmService.send(gtmData)
  }

  openLeasingContactPopup(mode: 'MultileaseContact' | 'Contact'): void {
    this.dialog.open(RevolutionLeasingContactPopupComponent, {
      data: {
        vehicle: this.vehicle,
        leasingResponse: this.leasingResponse,
        mode
      },
      panelClass: 'mat-dialog-cpt'
    })
  }

  checkIfHasLeasingDocuments(
    documents: BasicDocumentDTOExtended[]
  ): BasicDocumentDTOExtended {
    return documents.find(({ type }) => type === 'LEASING_CONTRACT')
  }

  recallClass(hasRecall: boolean): string {
    if (hasRecall) {
      return 'statusOpen'
    } else return 'statusClose'
  }

  checkAllLoaded(): void {
    this.maintenanceLoaded = !this.loadingServiceHistory

    this.leasingWarrantySPLoaded = !this.loadingDocuments

    if (this.hasWarranty && this.featuresWarranty && this.enableEfitData) {
      this.leasingWarrantySPLoaded =
        this.leasingWarrantySPLoaded && !this.loadingWarranty
    }

    this.cdRef.detectChanges()
  }

  getVehicleMileage(v: VehicleDTOExtended): number {
    const m =
      v.manualMileage != null
        ? v.manualMileage
        : v.currentMileage != null
        ? v.currentMileage
        : null
    return m
  }

  hasRecallDescription(): boolean {
    if (
      this.recallResponse?.descriptions != null &&
      this.recallResponse?.descriptions.length > 0
    ) {
      return true
    }
    return false
  }

  expansionPanelToggled(expanded: boolean, panelName: string): void {
    const gtmData: GTMEventData = {
      event: 'gaEvent2',
      event_name: 'accordian',
      accordian: {
        accordian_label: expanded ? 'Open ' + panelName : 'Close ' + panelName,
        accordian_type: panelName,
        event_action: panelName,
        event_category: 'Accordian',
        event_label: expanded ? 'Open ' + panelName : 'Close ' + panelName
      }
    }
    this.cptGtmService.send(gtmData)
  }
}
