<div class="flex flex-col align-middle">
  <div id="ef-spinner" [style]="getStyle()" class="flex justify-center">
    <mat-spinner [diameter]="diameter" class="ef-spinner"> </mat-spinner>
  </div>
  <div *ngIf="message && transifexReady">
    <h2 class="text-mat-ef-revolution-primary-500 text-4xl p-10 text-center">{{message | transifexTranslate}}</h2>
  </div>
</div>
<!-- This is a dummy element, invisible to the user.  [style.display]="'none'"-->
<div #colorContainer [style.display]="'none'" [ngClass]="ngClassColor">:)</div>
