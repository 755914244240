import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  CptGoogleTagmanagerService,
  GTMEventData,
  MatomoIntegrationService
} from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { DashEcoService } from '../../../../services/dash-eco.service'
import { DataService } from '../../../../services/data.service'
import { LanguageService } from '../../../../services/language.service'
import { LocalStorageService } from '../../../../services/local-storage.service'
import {
  InterestDTO,
  PersonalizationService,
  PersonalizeDTO,
  VehicleType
} from '../../../../services/personalization.service'
import { SharedService } from '../../../../services/shared.service'
import { BrandDTO } from '../../../../services/vehicles.service'

@Component({
  selector: 'customer-portal-app-revolution-user-personalisation',
  templateUrl: './user-personalisation.component.html'
})
export class RevolutionUserPersonalisationComponent
implements OnInit, OnChanges, OnDestroy {
  year = new Date().getFullYear()
  primaryLang
  onLanguageChangeSub
  // newsletter
  constructor (
    private readonly personalizationService: PersonalizationService,
    private readonly logger: NGXLogger,
    public dialog: MatDialog,
    private readonly dataService: DataService,
    private readonly languageService: LanguageService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public readonly sharedService: SharedService,
    public dashEcoService: DashEcoService,
    private readonly localStorageService: LocalStorageService,
    private readonly matomoIntegrationService: MatomoIntegrationService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.onLanguageChangeSub = dataService.onLanguageChange.subscribe(
      language => {
        if (this.languageService.checkIfExists(language)) {
          this.primaryLang = language.toLowerCase()
          // this.setHrefLinks()
        }
      }
    )
    this.country = this.remoteConfigService.get('country.code')
    this.showDashEcoNews = this.remoteConfigService.get('showDashEcoNews')
  }

  brands = []
  selectedBrands: BrandDTO[]
  selectedConsents: InterestDTO[]
  error = null
  getError = null
  consents: InterestDTO[]
  bodyTypes: string[]
  energyEfficiencies: string[]
  equipmentLevels: string[]
  fuelTypes: string[]
  powertrains: string[]
  transmissions: string[]
  vehicleUsages: string[]
  vehicleTypes: VehicleType[] = [
    { icon: 'SUV', type: 'suv', country: 'de, ch' },
    { icon: 'Cabrio', type: 'convertible', country: 'de, ch' },
    { icon: 'Coupe', type: 'coupe', country: 'de, ch' },
    { icon: 'Kleinwagen', type: 'compact', country: 'de, ch' },
    { icon: 'Kombi', type: 'station_wagon', country: 'de, ch' },
    { icon: 'Van2', type: 'van', country: 'de, ch' },
    { icon: 'Limousine', type: 'sedan', country: 'de, ch' },
    { icon: 'Pickup', type: 'pickup', country: 'de, ch' },
    { icon: 'RV', type: 'camp', country: 'de' }
  ]

  selectedBodyTypes: string[] = []
  selectedEnergyEfficiencies: string[] = []
  selectedEquipmentLevels = ''
  selectedFuelTypes: string[] = []
  selectedPowertrains: string[] = []
  selectedTransmissions: string[] = []
  selectedVehicleUsages: string[] = []
  personalizationValues: PersonalizeDTO
  personalizationValuesBackup: PersonalizeDTO
  loading = false
  country: string
  showDashEcoNews

  saveIsDisabled = true

  ngOnChanges (): void {
    this.loading = false
  }

  ngOnInit (): void {
    this.brands = []
    this.selectedBrands = []
    this.selectedConsents = []
    this.selectedTransmissions = []
    this.selectedBodyTypes = []
    this.selectedFuelTypes = []
    this.selectedPowertrains = []
    this.selectedVehicleUsages = []
    this.selectedEnergyEfficiencies = []
    this.personalizationService.getPersonalizationEnums().subscribe(
      value => {
        this.loading = false
        this.consents = value.interests
        this.brands = value.brands
        this.bodyTypes = value.bodyTypes
        this.energyEfficiencies = value.energyEfficiencies
        this.equipmentLevels = value.equipmentLevels
        this.fuelTypes = value.fuelTypes
        this.powertrains = value.powertrains
        this.transmissions = value.transmissions
        this.vehicleUsages = value.vehicleUsages
        // set family first in array
        this.vehicleUsages.sort(function (a, b) {
          if (a === 'family') {
            return -1
          }
          if (b === 'family') {
            return 1
          }
          return 0
        })
      },
      e => {
        this.getError = e
        this.logger.error(e)
      }
    )

    this.personalizationService.getPersonalizationValues().subscribe(value => {
      this.personalizationValues = value
      this.personalizationValuesBackup = JSON.parse(JSON.stringify(value))
      this.selectedConsents =
        value.interests?.length != null ? value.interests : []
      this.selectedBodyTypes =
        value.bodyTypes?.length != null ? value.bodyTypes : []
      this.selectedEquipmentLevels =
        value.equipmentLevel != null ? value.equipmentLevel : ''
      this.selectedEnergyEfficiencies =
        value.energyEfficiencies?.length != null ? value.energyEfficiencies : []
      this.selectedFuelTypes =
        value.fuelTypes?.length != null ? value.fuelTypes : []
      this.selectedTransmissions =
        value.transmissions?.length != null ? value.transmissions : []
      this.selectedPowertrains =
        value.powertrains?.length != null ? value.powertrains : []
      this.selectedVehicleUsages =
        value.vehicleUsages?.length != null ? value.vehicleUsages : []

      if (value.brands != null) {
        this.selectedBrands = value.brands
      } else {
        this.selectedBrands = []
      }
    })

    // this.setHrefLinks()
  }

  ngOnDestroy (): void {
    try {
      this.onLanguageChangeSub.unsubscribe()
    } catch (error) {
      // no need to show log
    }
  }

  selectBrand (selectedBrand): void {
    if (this.selectedBrands?.find(s => s.name === selectedBrand.name) != null) {
      this.selectedBrands = this.selectedBrands.filter(
        item => item.name !== selectedBrand.name
      )
    } else if (this.selectedBrands != null) {
      this.selectedBrands.push(selectedBrand)
    }

    this.checkEqualData()
  }

  checkConsent (selectedConsent): boolean {
    if (this.selectedConsents != null) {
      return !(
        this.selectedConsents.find(s => s.code === selectedConsent.code) == null
      )
    }
  }

  checkBrand (selectedBrand): boolean {
    if (this.selectedBrands != null) {
      return !(
        this.selectedBrands.find(s => s.name === selectedBrand.name) == null
      )
    }
  }

  selectConsent (selectedConsent): void {
    if (
      this.selectedConsents?.find(s => s.code === selectedConsent.code) != null
    ) {
      this.selectedConsents = this.selectedConsents.filter(
        item => item.code !== selectedConsent.code
      )
    } else if (this.selectedConsents != null) {
      this.selectedConsents.push(selectedConsent)
    }

    this.checkEqualData()
  }

  selectTransmission (transmission): void {
    if (this.selectedTransmissions?.find(s => s === transmission) != null) {
      this.selectedTransmissions = this.selectedTransmissions.filter(
        item => item !== transmission
      )
    } else if (this.selectedTransmissions != null) {
      this.selectedTransmissions.push(transmission)
    }

    this.checkEqualData()
  }

  checkTransmission (transmission): boolean {
    if (this.selectedTransmissions != null) {
      return !(this.selectedTransmissions.find(s => s === transmission) == null)
    }
  }

  selectVehicleUsage (vehicleUsage): void {
    if (this.selectedVehicleUsages?.find(s => s === vehicleUsage) != null) {
      this.selectedVehicleUsages = this.selectedVehicleUsages.filter(
        item => item !== vehicleUsage
      )
    } else if (this.selectedVehicleUsages != null) {
      this.selectedVehicleUsages.push(vehicleUsage)
    }
    this.checkEqualData()
  }

  checkVehicleUsage (vehicleUsage): boolean {
    if (this.selectedVehicleUsages != null) {
      return !(this.selectedVehicleUsages.find(s => s === vehicleUsage) == null)
    }
  }

  selectEquipmentLevels (equipmentLevels): void {
    this.selectedEquipmentLevels = equipmentLevels

    this.checkEqualData()
  }

  checkEquipmentLevels (equipmentLevels): boolean {
    return this.selectedEquipmentLevels === equipmentLevels
  }

  selectEnergyEfficiency (energyEfficiency): void {
    if (
      this.selectedEnergyEfficiencies?.find(s => s === energyEfficiency) != null
    ) {
      this.selectedEnergyEfficiencies = this.selectedEnergyEfficiencies.filter(
        item => item !== energyEfficiency
      )
    } else if (this.selectedEnergyEfficiencies != null) {
      this.selectedEnergyEfficiencies.push(energyEfficiency)
    }

    this.checkEqualData()
  }

  checkEnergyEfficiency (energyEfficiency): boolean {
    if (this.selectedEnergyEfficiencies != null) {
      return !(
        this.selectedEnergyEfficiencies.find(s => s === energyEfficiency) ==
        null
      )
    }
  }

  selectFuelType (fuelType): void {
    if (
      this.selectedFuelTypes != null &&
      this.selectedFuelTypes.length > 0 &&
      this.selectedFuelTypes.find(s => s === fuelType) != null
    ) {
      this.selectedFuelTypes = this.selectedFuelTypes.filter(
        item => item !== fuelType
      )
    } else if (this.selectedFuelTypes != null) {
      this.selectedFuelTypes.push(fuelType)
    }

    this.checkEqualData()
  }

  checkFuelType (fuelType): boolean {
    if (this.selectedFuelTypes != null) {
      return !(this.selectedFuelTypes.find(s => s === fuelType) == null)
    }
  }

  selectBodyType (bodyType): void {
    if (this.selectedBodyTypes?.find(s => s === bodyType) != null) {
      this.selectedBodyTypes = this.selectedBodyTypes.filter(
        item => item !== bodyType
      )
    } else if (this.selectedBodyTypes != null) {
      this.selectedBodyTypes.push(bodyType)
    }

    this.checkEqualData()
  }

  checkBodyType (bodyType): boolean {
    if (this.selectedBodyTypes != null) {
      return !(this.selectedBodyTypes.find(s => s === bodyType) == null)
    }
  }

  selectPowertrains (powertrain): void {
    if (this.selectedPowertrains?.find(s => s === powertrain) != null) {
      this.selectedPowertrains = this.selectedPowertrains.filter(
        item => item !== powertrain
      )
    } else if (this.selectedPowertrains != null) {
      this.selectedPowertrains.push(powertrain)
    }

    this.checkEqualData()
  }

  checkPowertrains (powertrain): boolean {
    if (this.selectedPowertrains != null) {
      return !(this.selectedPowertrains.find(s => s === powertrain) == null)
    }
  }

  sendGtmData (): void {
    const gtmData: GTMEventData = {
      event: 'gaEvent',
      event_name: 'personalization',
      personalization: {
        event_action: 'Personalization settings change',
        event_category: 'Personalization',
        event_label: 'Personalization',
        personalization_body_variant: this.selectedBodyTypes,
        personalization_car_usage: this.selectedVehicleUsages,
        personalization_drive_type: this.selectedPowertrains,
        personalization_equipment_level: this.selectedEquipmentLevels,
        personalization_favorite_makes: this.selectedBrands,
        personalization_fuel_type: this.selectedFuelTypes,
        personalization_transmission_type: this.selectedTransmissions,
        personalization_efficiency: this.selectedEnergyEfficiencies
      }
    }
    this.cptGtmService.send(gtmData)
  }

  savePersonalization (): void {
    this.matomoIntegrationService.trackEvent(
      'My account',
      'Personalization settings change'
    )

    this.sendGtmData()
    this.loading = true
    this.personalizationValues.interests = this.selectedConsents
    this.personalizationValues.brands = this.selectedBrands
    this.personalizationValues.powertrains = this.selectedPowertrains
    this.personalizationValues.vehicleUsages = this.selectedVehicleUsages
    this.personalizationValues.transmissions = this.selectedTransmissions
    this.personalizationValues.fuelTypes = this.selectedFuelTypes
    this.personalizationValues.equipmentLevel = this.selectedEquipmentLevels
    this.personalizationValues.energyEfficiencies =
      this.selectedEnergyEfficiencies
    this.personalizationValues.bodyTypes = this.selectedBodyTypes
    this.personalizationService
      .putPersonalization(this.personalizationValues)
      .subscribe(
        value => {
          this.personalizationValuesBackup = JSON.parse(JSON.stringify(value))
          this.saveIsDisabled = true
          this.loading = false
          this.selectedConsents = value.interests
          this.selectedBrands = value.brands
          this.selectedPowertrains = value.powertrains
          this.selectedBodyTypes = value.bodyTypes
          this.selectedEnergyEfficiencies = value.energyEfficiencies
          this.selectedEquipmentLevels = value.equipmentLevel
          this.selectedFuelTypes = value.fuelTypes
          this.selectedTransmissions = value.transmissions
          this.selectedVehicleUsages = value.vehicleUsages
          this.sharedService.openConfirmationPopup(
            'shared.success',
            'customerPortal.customer-portal.details.change.personalisation-changed'
          )
          this.dataService.personalizationLoaded(value)
        },
        error1 => {
          this.sharedService.openConfirmationPopup(
            'shared.error',
            'shared.general-error-message'
          )
          this.loading = false
          this.logger.error(error1)
          this.error = error1
          window.scroll(0, 0)
        }
      )
  }

  checkEqualData (): boolean {
    if (this.personalizationValuesBackup == null) {
      return true
    }

    if (
      !this.sharedService.isEqual(
        this.selectedConsents != null ? this.selectedConsents : [],
        this.personalizationValuesBackup.interests != null
          ? this.personalizationValuesBackup.interests
          : []
      )
    ) {
      this.printDiff('interests')
      this.saveIsDisabled = false
      return
    }

    if (
      !this.sharedService.isEqual(
        this.selectedBrands != null ? this.selectedBrands : [],
        this.personalizationValuesBackup.brands != null
          ? this.personalizationValuesBackup.brands
          : []
      )
    ) {
      this.printDiff('brands')
      this.saveIsDisabled = false
      return
    }

    if (
      !this.sharedService.isEqual(
        this.selectedPowertrains != null ? this.selectedPowertrains : [],
        this.personalizationValuesBackup.powertrains != null
          ? this.personalizationValuesBackup.powertrains
          : []
      )
    ) {
      this.printDiff('powertrains')
      this.saveIsDisabled = false
      return
    }

    if (
      !this.sharedService.isEqual(
        this.selectedVehicleUsages != null ? this.selectedVehicleUsages : [],
        this.personalizationValuesBackup.vehicleUsages != null
          ? this.personalizationValuesBackup.vehicleUsages
          : []
      )
    ) {
      this.printDiff('vehicleUsages')
      this.saveIsDisabled = false
      return
    }

    if (
      !this.sharedService.isEqual(
        this.selectedTransmissions != null ? this.selectedTransmissions : [],
        this.personalizationValuesBackup.transmissions != null
          ? this.personalizationValuesBackup.transmissions
          : []
      )
    ) {
      this.printDiff('transmissions')
      this.saveIsDisabled = false
      return
    }

    if (
      !this.sharedService.isEqual(
        this.selectedFuelTypes != null ? this.selectedFuelTypes : [],
        this.personalizationValuesBackup.fuelTypes != null
          ? this.personalizationValuesBackup.fuelTypes
          : []
      )
    ) {
      this.printDiff('fuelTypes')
      this.saveIsDisabled = false
      return
    }

    if (
      (this.selectedEquipmentLevels != null
        ? this.selectedEquipmentLevels
        : '') !==
      (this.personalizationValuesBackup.equipmentLevel != null
        ? this.personalizationValuesBackup.equipmentLevel
        : '')
    ) {
      this.printDiff('equipmentLevel')
      this.saveIsDisabled = false
      return
    }

    if (
      !this.sharedService.isEqual(
        this.selectedEnergyEfficiencies != null
          ? this.selectedEnergyEfficiencies
          : [],
        this.personalizationValuesBackup.energyEfficiencies != null
          ? this.personalizationValuesBackup.energyEfficiencies
          : []
      )
    ) {
      this.printDiff('energyEfficiencies')
      this.saveIsDisabled = false
      return
    }

    if (
      !this.sharedService.isEqual(
        this.selectedBodyTypes != null ? this.selectedBodyTypes : [],
        this.personalizationValuesBackup.bodyTypes != null
          ? this.personalizationValuesBackup.bodyTypes
          : []
      )
    ) {
      this.printDiff('bodyTypes')
      this.saveIsDisabled = false
      return
    }

    this.printDiff('equal')
    this.saveIsDisabled = true
  }

  printDiff (diff): void {
    this.logger.debug(diff)
  }

  efficiencyClassArrowColor (efficiencyClass: string): string {
    let cssClass = ''
    if (efficiencyClass === 'a+') {
      efficiencyClass = 'a-plus'
    }
    cssClass = this.country + ' ' + efficiencyClass.toLowerCase()
    return cssClass
  }

  // setHrefLinks(): void {
  //   if (this.primaryLang == null) {
  //     this.primaryLang = this.getLang()
  //   }

  //   const news = {
  //     link: this.remoteConfigService.get('links.newsletter.link'),
  //     link_de: this.remoteConfigService.get('links.newsletter.link_de'),
  //     link_it: this.remoteConfigService.get('links.newsletter.link_it'),
  //     link_fr: this.remoteConfigService.get('links.newsletter.link_fr'),
  //     eventLabel: this.remoteConfigService.get('links.newsletter.eventLabel')
  //   }
  //   this.newsletter = Object.assign(
  //     {},
  //     news
  //   )
  //   this.newsletter.link = this.getLink(this.newsletter)
  // }

  getLang (): string {
    try {
      const lang = this.localStorageService.getLang()
      return this.sharedService.stringExists(lang) ? lang : 'de'
    } catch (error) {
      return 'de'
    }
  }

  getLink (linkObj): string {
    return this.languageService.getLink(linkObj, this.primaryLang)
  }
}
