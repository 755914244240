import { Injectable } from '@angular/core'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { KeycloakTokenParsed } from '@emilfreydigital/keycloak-js'

export interface TokenParsed extends KeycloakTokenParsed {
  cp_id: string
}

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor(private readonly keycloak: KeycloakService) {}

  public getToken(): TokenParsed {
    return this.keycloak.getKeycloakInstance().tokenParsed as TokenParsed
  }
}
