import { Component, Input, OnInit } from '@angular/core'
import * as moment from 'moment'
import {
  CarmatoVehicleDataDTO,
  CarmatoValuationDTO,
  CarmatoService
} from '../../../../services/carmato.service'
// import { MARKETPLACE_VALUATIONS } from '../../../../mock/marketplaceValuations'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { SharedService } from '../../../../services/shared.service'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
export interface VehicleValuation {
  vehicle: string
  vehicleData: CarmatoVehicleDataDTO
  valuations: CarmatoValuationDTO[]
}

export interface Validity {
  days: number
  hours: number
  minutes: number
}

@Component({
  selector: 'customer-portal-app-revolution-marketplace-valuations',
  templateUrl: './marketplace-valuations.component.html'
})
export class RevolutionMarketplaceValuationsComponent implements OnInit {
  @Input() valuations: CarmatoValuationDTO[] = []
  vehiclesValuations: VehicleValuation[] = []
  displayedColumns: string[] = ['offerFrom', 'bid', 'created']
  currency: string
  currencyLocale: string
  marketPlaceUrl
  loading = true
  constructor(
    private readonly carmatoService: CarmatoService,
    private readonly transifexTranslationsService: TranslationService,
    private readonly sharedService: SharedService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.currency = this.remoteConfigService.get('currency')
    this.currencyLocale = this.remoteConfigService.get('currencyLocale')
    this.marketPlaceUrl = this.remoteConfigService.get('marketPlace.url')
  }

  sendContentModulesData(expanded: boolean, contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Accordion',
      expanded ? 'Open' : 'Close',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Accordion|${this.sharedService.translateLink(contentModuleLabel) ?? ''}`,
      expanded ? 'Open' : 'Close'
    )
  }

  calculateValidity(hours: number): Validity {
    const duration = moment.duration(hours, 'hours')
    const validity: Validity = {
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes()
    }
    return validity
  }

  // Used to color alternate rows
  oddEven(index: number): string {
    const result = index % 2 === 0 ? 'even' : 'odd'
    return result
  }

  lessThanOneMonthOld(v: CarmatoValuationDTO): boolean {
    let isLessThanOneMOnthOld = false
    const momentBidCreated = moment(v.dateCreated)
    const now = moment(new Date())
    const bidAgeHours = moment.duration(now.diff(momentBidCreated)).asHours()
    const oneMonthHours = 30 * 24
    if (bidAgeHours < oneMonthHours) {
      isLessThanOneMOnthOld = true
    }
    return isLessThanOneMOnthOld
  }

  ngOnInit(): void {
    this.carmatoService.getValuations().subscribe(
      valuations => {
        this.loading = false
        this.valuations = valuations
        // this.valuations = MARKETPLACE_VALUATIONS // mock

        if (this.valuations?.[0] != null) {
          this.valuations.forEach(v => {
            const haveVehicleValuation = this.vehiclesValuations.find(
              ({ vehicle }) => vehicle === v.vehicle
            )
            if (this.lessThanOneMonthOld(v)) {
              // Only consider valuations in last 30 month
              if (haveVehicleValuation != null) {
                // add in existing obj
                haveVehicleValuation.valuations.push(v)
              } else {
                // add new obj
                this.vehiclesValuations.push({
                  vehicle: v.vehicle,
                  vehicleData: v.vehicleData,
                  valuations: [v]
                })
              }
            }
          })
        }
      },
      () => {
        this.loading = false
      }
    )
  }
}
