<div
  class="my-10 w-full flex align-middle items-center justify-center gap-1 text-mat-ef-revolution-primary-500"
>
  <div>
    <mat-icon svgIcon="warning" class="mr-2"></mat-icon>
  </div>
  <span>
    <ng-container
      *ngTemplateOutlet="
        sharedService.template('transifexTranslation');
        context: {
          str: 'Disclaimer',
          key: translateKey
        }
      "
    ></ng-container>
  </span>
</div>
