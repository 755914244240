
  <div class="w-full flex-1 flex-col md:flex-row flex gap-2 md:gap-4 m-0 justify-between">
    <div
      class="flex-1 ml-1 text-mat-ef-revolution-primary-500"
    >
      <div *ngIf="filterService(document)"
        class="font-efSans text-sm md:text-base font-bold break-words"
      >
        {{ getTranslation(document) }}
      </div>
      <div
        class="font-efSans text-sm md:text-base font-bold break-words"
      >
        {{ document?.brandName }} {{ document?.modelName }}
      </div>
      <div class="font-efSans text-sm md:text-base">
        {{
          sharedService.getDocumentDate(document)
            | date: dateFormat
        }}
      </div>
      <p *ngIf="document?.dealer" class="font-efSans text-sm md:text-base">
        {{
          document?.dealer.name
        ? document?.dealer.name +
        (', ' + document?.dealer.subname) || ''
        : ''
        }}
      </p>
      <div
        *ngIf="!document?.active"
        class="font-efSans text-sm md:text-base"
      >
        <T
          str="Inactive"
          key="customerPortal.customer-portal.status.inactive"
          tags="customer-portal, 3.1"
        ></T>
      </div>
    </div>
  
    <div
      class="flex flex-col md:basis-1/3 text-mat-ef-revolution-primary-500"
    >
      <div
        class="ml-1 font-efSans text-sm md:text-base font-bold break-words"
        *ngIf="document?.type"
      >
      <!-- https://emilfreygroup.atlassian.net/browse/CPEFG-3843  -->
        {{
          {
            str:
              document?.type === 'SERVICE'
                ? 'invoice'
                : document?.type?.toLowerCase(),
            prefix: 'customerPortal.customer-portal.'
          } | transifexTranslate
        }}
      </div>
  
      <div
        class="font-efSans text-sm md:text-base font-bold break-words"
        *ngIf="
          !document?.type &&
          !document?.invNumber &&
          !document?.number
        "
      >
        -
      </div>
  
      <p
        *ngIf="document?.invNumber"
        class="font-efSans text-sm md:text-base ml-1"
      >
        {{ document?.invNumber }}
      </p>
      <p
        *ngIf="document?.number"
        class="font-efSans text-sm md:text-base ml-1"
      >
        {{ document?.number }}
      </p>
    </div>
  </div>













