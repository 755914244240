<mat-card
  class="flex-1 flex flex-col gap-3 items-center justify-between rounded-md bg-mat-ef-revolution-grey-500"
  [ngClass]="{ 'lg:flex-row': !alwaysSmall }"
>
  <div class="w-full">
    <div
      class="w-full flex font-efSans text-xl font-black text-mat-ef-revolution-primary-500 leading-normal mb-1"
    >
      <T
        str="frequently asked Questions"
        key="customerPortal.customer-portal.faq-card.title"
        tags="customer-portal, 3.1"
      ></T>
    </div>

    <div class="text-sm text-efSans text-mat-ef-revolution-primary-500">
      <T
        str="Do you have difficulties with the registration and your profile? Here you will be helped."
        key="customerPortal.customer-portal.faq-card.text"
        tags="customer-portal, 3.1"
      ></T>
    </div>
  </div>

  <div
    class="btn-rounded-btn"
    [ngClass]="{ 'lg:w-fit': !alwaysSmall }"
    (click)="
      goToFAQ();
      sendContentModulesCTAData(
        'customerPortal.customer-portal.faq-card.button'
      )
    "
  >
    <T
      str="To the questions"
      key="customerPortal.customer-portal.faq-card.button"
      tags="customer-portal, 3.1"
    ></T>
  </div>
</mat-card>
