import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { SharedService } from '../../../../services/shared.service'
import {
  TiresAppointmentDTO,
  TiresStockDTO,
  TyreDTO
} from '../../../../services/tires.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'
import { CustomCalendarHeaderComponent } from '../../../basic/custom-calendar-header/custom-calendar-header.component'
import { AppointmentsPopupComponent } from '../../appointments-popup/appointments-popup.component'

import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { AppointmentsPopupChooseStepComponent } from '../../appointments-popup/appointments-popup-choose-step/appointments-popup-choose-step.component'

export type TireSeasonEnum =
  cpt.emilfreydigital.mdm.vp.client.model.TireWarehouseResponseDto.TireSeasonEnum

@Component({
  selector: 'customer-portal-app-revolution-tyre-molecule',
  templateUrl: './tyre-molecule.component.html'
})
export class RevolutionTyreMoleculeComponent implements OnInit, OnChanges {
  percentageTillExpires: number
  tillExpires: number
  customHeader = CustomCalendarHeaderComponent
  user: User
  showWarning = false
  link: TiresAppointmentDTO
  set: TiresStockDTO
  VR: TyreDTO
  VL: TyreDTO
  HR: TyreDTO
  HL: TyreDTO
  SP: TyreDTO
  numberOfWarnings = 0
  maintenanceLoaded = false
  loadingTires: boolean
  loadingTireAppointments: boolean
  loadingRecalls: boolean

  componentSub = {
    recalls: null,
    tires: null,
    tireAppointments: null,
    TiresLoaded: null,
    AppintmentLinkLoaded: null
  }

  hasLinks = false
  hasServiceManager = false
  constructor (
    private readonly dialog: MatDialog,
    private readonly dataService: DataService,
    private readonly logger: NGXLogger,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly cdRef: ChangeDetectorRef,
    public readonly sharedService: SharedService,
    private readonly transifexTranslationsService: TranslationService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasServiceManager = this.remoteConfigService.get(
      'hasServiceManager'
    )
  }

  @Input()
  public vehicle: VehicleDTOExtended

  sendContentModulesData (expanded: boolean, contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Accordion',
      expanded ? 'Open' : 'Close',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Accordion|${this.sharedService.translateLink(contentModuleLabel) ?? ''}`,
      expanded ? 'Open' : 'Close'
    )
  }

  sendContentModulesCTAData (contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }

  sendGTMData (): void {
    const data = {
      delivery: 'Delivery',
      reifenManager: true
    }
    this.cptGtmService.sendServiceAppointmentCheckoutData(data)
  }

  getColor (tire: TyreDTO): string {
    if (tire?.profileState === 'OK') {
      const c = '#00b373'
      return c
    }
    if (tire?.profileState === 'WARN') {
      const c = '#d8ca00'
      return c
    }
    if (tire?.profileState === 'ERR') {
      const c = '#e64545'
      return c
    }
  }

  decideNextStep (): void {
    const dialogRef = this.dialog.open(AppointmentsPopupChooseStepComponent, {
      panelClass: ['mat-dialog-cpt', 'choose-step'],
      data: {
        vehicle: this.vehicle
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'CPT') {
        // open appointment popup
        this.newAppointment(this.vehicle)
      }
      if (result === 'serviceManager') {
        this.openServiceManager()
      }
    })
  }

  openServiceManager (): void {
    window.open(this.link != null ? this.link.url : '', '_blank')
    this.sendGTMData()
  }

  checkAllLoaded (): void {
    this.maintenanceLoaded = !this.loadingTires
    if (this.remoteConfigService.get('features.recalls')) {
      this.maintenanceLoaded = this.maintenanceLoaded && !this.loadingRecalls
    }
    this.cdRef.detectChanges()
  }

  setLoading (): void {
    this.loadingTires = true
    this.loadingTireAppointments = true
    this.loadingRecalls = true
  }

  newAppointment (
    vehicle: VehicleDTOExtended,
    step?: number,
    serviceAction?: boolean
  ): void {
    const dialogRef = this.dialog.open(AppointmentsPopupComponent, {
      data: { vehicle, step, serviceAction },
      panelClass: [
        'mat-dialog-cpt',
        'ef-scrollbar',
        'mat-dialog-appointments',
        'new-appointment'
      ]
    })

    dialogRef.afterClosed().subscribe(result => {
      this.logger.debug(result)
    })
  }

  ngOnInit (): void {
    this.setLoading()

    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
    })

    this.componentSub.tires = this.dataService.loading.tires.subscribe(
      loading => {
        this.loadingTires = loading
        this.checkAllLoaded()
      }
    )
    this.componentSub.tireAppointments =
      this.dataService.loading.tireAppointments.subscribe(loading => {
        this.loadingTireAppointments = loading
        this.checkAllLoaded()
      })
    this.componentSub.recalls = this.dataService.loading.recalls.subscribe(
      loading => {
        this.loadingRecalls = loading
        this.checkAllLoaded()
      }
    )

    this.componentSub.AppintmentLinkLoaded =
      this.dataService.onVehicleTiresAppintmentLinkLoaded.subscribe(
        data => {
          this.link = data
        },
        err => {
          this.logger.debug(err)
        }
      )

    this.componentSub.TiresLoaded =
      this.dataService.onVehicleTiresLoaded.subscribe(
        data => {
          this.set = this.sharedService.deepCopy(data)

          if (data != null) {
            this.loadTires(this.set?.tires)
            this.calculateWarnings(this.set?.tires)
          }
        },
        err => {
          this.logger.debug(err)
        }
      )
  }

  loadTires (tires: TyreDTO[]): void {
    this.VR = tires.find(k => k.position === 'FR')
    this.VL = tires.find(k => k.position === 'FL')
    this.HR = tires.find(k => k.position === 'RR')
    this.HL = tires.find(k => k.position === 'RL')
    this.SP = tires.find(k => k.position === 'SP')
  }

  calculateWarnings (tires: TyreDTO[]): void {
    this.numberOfWarnings = 0
    for (const tire of tires) {
      if (tire?.profileState === 'ERR') {
        this.numberOfWarnings = this.numberOfWarnings + 1
      }
    }
  }

  ngOnChanges (): void {
    // on next car set number of tire warnings to 0
    this.numberOfWarnings = 0
    // empty set array if not empty
    if (this.set?.tires != null) {
      this.set?.tires.splice(0, this.set?.tires.length)
    }
    // clear link response if car changed
    if (this.link != null) {
      this.link = null
    }
  }

  getTypeTranslation (type: TireSeasonEnum): string {
    if (this.sharedService.stringExists(type)) {
      return this.transifexTranslationsService.translate(type, {
        _key: 'customerPortal.customer-portal.' + type.toLowerCase(),
        _tags: 'customer-portal, notranslate'
      })
    }
    return ''
  }
}
