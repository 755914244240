<div
  data-cy="remove-vehicle-popup"
  [hidden]="hidden"
  class="cpt-modal RemoveVehiclePopupComponent-revolution-dialog text-mat-ef-revolution-primary-500 relative dialog-content-p-0 mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="Change of ownership"
      key="customerPortal.customer-portal.vehicle.remove"
      tags="customer-portal, 3.1"
    ></T>
  </h2>

  <mat-dialog-content>
    <form class="remove-vehicle-form">
      <div
        class="font-efSans text-xl font-bold mb-4 text-mat-ef-revolution-secondary-500"
      >
        <T
          str="If you would like to remove this vehicle from your key ring, please state the reason:"
          key="customerPortal.customer-portal.vehicle.remove.description.long"
          tags="customer-portal, 3.1"
        ></T>
      </div>

      <mat-radio-group
        name="selectedRemovalReason"
        class="option-group flex flex-col"
        [(ngModel)]="selectedRemovalReason"
      >
        <mat-radio-button
          data-cy="remove-vehicle-reason"
          *ngFor="let r of removalRequestReasons"
          class="mat-option"
          [value]="r"
        >
          <ng-container
            *ngTemplateOutlet="
              sharedService.template('transifexTranslation');
              context: {
                str: r | lowercase,
                prefix: 'customerPortal.customer-portal.vehicle.remove.reason.'
              }
            "
          ></ng-container
        ></mat-radio-button>
      </mat-radio-group>
    </form>
  </mat-dialog-content>

  <mat-divider></mat-divider>

  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>

    <button
      data-cy="remove-vehicle-submit"
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      (click)="
        data?.vehicle?.createdByDataSource === 'customer_portal'
          ? confirmDeleteVehicle()
          : saveChanges()
      "
      cdkFocusInitial
      [disabled]="saving || !selectedRemovalReason"
    >
      <mat-spinner *ngIf="saving" diameter="20"></mat-spinner>
      <T
        str="Update"
        key="customerPortal.customer-portal.general.actions.update"
        tags="customer-portal, 3.1"
      ></T>
    </button>
  </mat-dialog-actions>
</div>
