<div
  data-cy="edit-profile-picture-form"
  class="cpt-modal relative dialog-content-p-0 mat-dialog-actions-pb-20 ef-scrollbar"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="Edit profile picture"
      key="customerPortal.customer-portal.details.update-avatar-popup.title"
      tags="customer-portal, 3.1"
    ></T>
  </h2>

  <mat-divider class="mat-divider-horizontal-spacing"></mat-divider>

  <mat-dialog-content class="mat-typography">
    <div class="flex">
      <!-- *ngIf="!disabled" -->
      <div
        class="image-preview relative w-full h-60 cover curor-pointer bg-mat-ef-revolution-grey-200"
      >
        <div
          data-cy="edit-profile-picture-delete-button"
          class="absolute top-2 right-2 bg-mat-ef-revolution-red-500 shadow-xl hover:shadow-2xl h-10 w-10 rounded-full cursor-pointer z-50 flex justify-center items-center"
          *ngIf="user.profilePictureUrl"
          (click)="deleteImage(); sharedService.preventEventPropagation($event)"
        >
          <mat-icon svgIcon="delete" class="text-white"></mat-icon>
        </div>
        <img
          *ngIf="!disabled"
          class="cover"
          [src]="preview ? preview : user?.profilePictureUrl"
          alt="profile picture"
        />
        <input
          data-cy="edit-profile-picture-input"
          class="opacity-0 absolute top-0 h-full w-full z-30 cursor-pointer"
          type="file"
          accept=".jpg, .png"
          (change)="loadImage($event)"
        />
        <div class="flex justify-center">
          <div
            class="absolute text-center inset-y-1/2 flex flex-col justify-center"
            *ngIf="disabled"
          >
            <div>
              <mat-icon
                svgIcon="add_photo_alternate"
                class="text-mat-ef-revolution-primary-500"
              ></mat-icon>
            </div>
            <!-- prettier-ignore -->
            <p
              class="font-bold font-efSans-primary"
              [innerHtml]="getTranslation()"
            ></p>
            <p
              class="font-efSans text-mat-ef-revolution-grey-two-200 text-base font-normal grid"
            >
              <span class="grid">
                <span>
                  <T
                    str="Supported formats: JPG, BMP, PNG (max. 2MB)"
                    key="customerPortal.customer-portal.supported-formats"
                    tags="customer-portal, 3.1"
                  ></T>
                </span>
                <span>
                  <T
                    str="Recommended resolution 1024 x 1024 px"
                    key="customerPortal.customer-portal.details.update-avatar-popup.file-resolution"
                    tags="customer-portal, 3.1"
                  ></T>
                </span>
              </span>
              <mat-error *ngIf="!propperSize">
                <T
                  str="Maximum file size exceeded."
                  key="customerPortal.customer-portal.details.update-avatar-popup.incorrect-size"
                  tags="customer-portal, 3.1"
                ></T>
              </mat-error>
              <mat-error *ngIf="!propperFileNameLength">
                <T
                  str="Maximum filename length excceeded"
                  key="customerPortal.customer-portal.vehicle.documents-upload.error.maxlength"
                  tags="customer-portal, 3.1"
                ></T>
              </mat-error>
            </p>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-divider></mat-divider>

  <mat-dialog-actions class="justify-between gap-4">
    <div
      class="avatar-details float-left text-mat-ef-revolution-grey-two-500 text-sm"
    >
      <p class="upload-note">
        <T
          str="JPG, BMP, PNG (max. 2MB)"
          key="customerPortal.customer-portal.details.update-avatar-popup.file-requirements"
          tags="customer-portal, 3.1"
        ></T>
        <br />

        <T
          str="Recommended resolution 1024 x 1024 px"
          key="customerPortal.customer-portal.details.update-avatar-popup.file-resolution"
          tags="customer-portal, 3.1"
        ></T>
        <br />
        <mat-error *ngIf="!propperSize" class="mat-form-field p-0">
          <T
            str="Maximum file size exceeded."
            key="customerPortal.customer-portal.details.update-avatar-popup.incorrect-size"
            tags="customer-portal, 3.1"
          ></T>
        </mat-error>
        <mat-error
          *ngIf="!propperFileNameLength"
          class="mat-form-field p-0"
          data-cy="edit-profile-picture-error-maxFileName"
        >
          <T
            str="Maximum filename length excceeded"
            key="customerPortal.customer-portal.vehicle.documents-upload.error.maxlength"
            tags="customer-portal, 3.1"
          ></T>
        </mat-error>
      </p>
    </div>

    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <button
      data-cy="edit-profile-picture-submit"
      (click)="save()"
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      cdkFocusInitial
      [disabled]="
        disabled || savingInProgress || !fileUploaded || !propperFileNameLength
      "
    >
      <mat-spinner [diameter]="16" *ngIf="savingInProgress"></mat-spinner>
      {{ 'save' | sharedTranslate }}
    </button>
  </mat-dialog-actions>
</div>
