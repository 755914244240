import { Pipe, PipeTransform, OnDestroy } from '@angular/core'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { BehaviorSubject } from 'rxjs'
import { SharedService } from '../services/shared.service'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'

@Pipe({ name: 'username' })
export class UserNamePipe implements PipeTransform, OnDestroy {
  sub = {
    onTransifexLanguageChange: null
  }

  constructor(
    private readonly transifexTranslationsService: TranslationService,
    private readonly transifexService: TransifexService,
    private readonly sharedService: SharedService
  ) {}

  transform(user: User, showTitle?: boolean): BehaviorSubject<string> {
    const formattedUserName = new BehaviorSubject('')

    if (user != null) {
      this.sub.onTransifexLanguageChange =
        this.transifexService.onTransifexLanguageChange.subscribe(() => {
          let userName = ''

          let salutation
          if (user?.salute?.toLowerCase() !== 'unspecified') {
            salutation = this.transifexService.getSharedTranslation(
              String('salutation-' + user?.salute?.toLowerCase())
            )
          }

          let title
          if (showTitle === true) {
            title = this.transifexService.getSharedTranslation(
              String('title-' + user?.title?.toLowerCase())
            )
          }

          userName += this.sharedService.stringExists(salutation)
            ? salutation + ' '
            : ''
          userName += this.sharedService.stringExists(title) ? title + ' ' : ''
          userName += this.sharedService.stringExists(user.firstName)
            ? String(user.firstName) + ' '
            : ''
          userName += this.sharedService.stringExists(user.lastName)
            ? String(user.lastName) + ' '
            : ''
          userName += this.sharedService.stringExists(user.companyName)
            ? String(user.companyName) + ' '
            : ''

          formattedUserName.next(userName)
        })

      return formattedUserName
    }
  }

  ngOnDestroy(): void {
    this.sharedService.unsubscribe(this.sub)
  }
}
