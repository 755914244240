<customer-portal-app-navbar></customer-portal-app-navbar>
<customer-portal-app-navbar-second></customer-portal-app-navbar-second>

<div class="container py-14 px-4 pb-8">
  <h1 class="head-title">
    <T
      str="About"
      key="customerPortal.customer-portal.about"
      tags="customer-portal, 3.9"
    ></T>
  </h1>
</div>

<div class="aboutView">
  <customer-portal-footer-container
    [aboutView]="true"
  ></customer-portal-footer-container>
</div>
