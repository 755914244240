import { Injectable } from '@angular/core'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { NGXLogger } from 'ngx-logger'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { IosMessagingService } from './iosMessaging.service'
import { type User } from '@inside-hub-app/customer-portal-b2c-client'
import { PwaService } from './pwa.service'

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor (
    private readonly logger: NGXLogger,
    private readonly keycloak: KeycloakService,
    private readonly iosMessagingService: IosMessagingService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>,
    private readonly pwaService: PwaService
  ) {}

  logout (user: User): void {
    this.iosMessagingService.logout(user.contactId)

    const iosNative = this.pwaService.checkIosNative()
    const isPwa = this.pwaService.checkPwa()

    let logoutRedirectUrl = this.remoteConfigService.get('logoutRedirectUrl')
    if (isPwa) {
      logoutRedirectUrl = this.remoteConfigService.get('logoutRedirectUrlPwa')
    }
    if (iosNative) {
      logoutRedirectUrl = this.remoteConfigService.get('home.url')
    }
    this.keycloak.logout(logoutRedirectUrl).catch(error => {
      this.logger.error(error)
    })
  }
}
