import { type ElementRef, Injectable, type TemplateRef } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import {
  type User,
  type UserConsentsResponse,
  type UserTelephone
} from '@inside-hub-app/customer-portal-b2c-client'
import {
  type CustomerPortalConfig,
  type Navigation,
  type RevolutionLink
} from '@inside-hub-app/customer-portal-config'
import {
  type CptDateFormats,
  CptGoogleTagmanagerService,
  PwaService,
  TemplateStoreService,
  type VehicleDTOExtended,
  SharedService as CPTSharedService,
  type PhoneMask as CPTPhoneMask
} from '@inside-hub-app/customer-portal-shared'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { TranslateService } from '@ngx-translate/core'
import { type hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { NGXLogger } from 'ngx-logger'
import { ConfirmationPopupComponent } from '../components/basic/confirmation-popup/confirmation-popup.component'
import { type UpcomingAppointmentDTOExtended } from './appointments.service'
import { type DayTimeDTO, type DealerDTO } from './dealers.service'
import { type TrackAndTraceVehicleDTOExtended } from './vehicles.service'
import { format as formatDate } from 'date-fns'

import {
  type AbstractControl,
  type FormGroup,
  type ValidationErrors
} from '@angular/forms'
import {
  type CountryCallingCode,
  type CountryCode,
  parsePhoneNumber
} from 'libphonenumber-js/max'
import { type CarmatoVehicleDataDTO } from './carmato.service'
import { type BasicDocumentDTOExtended } from './vehicle-documents.service'
export type ErrorResponseDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.error.dto.ErrorResponseDTO<any>

export type PhoneMask = CPTPhoneMask
export interface Links {
  translation?: string
  link: string
  eventLabel?: string
  link_de?: string
  link_it?: string
  link_fr?: string
}

export interface UserTelephoneGeneral {
  mobile: UserTelephone
  mobileBusiness: UserTelephone
  landlineBusiness: UserTelephone
  landlinePersonal: UserTelephone
}
export type Tab =
  | 'vehicleGeneral'
  | 'vehicleDealer'
  | 'vehicleDetails'
  | 'vehicleServiceHistory'
  | 'vehicleDocuments'
  | 'userCommunication'
  | 'userPersonalization'
  | 'userNotifications'
  | 'dataprotection'
  | 'userDetails'
  | 'manageFleet'
  | 'fleetVehicles'
  | 'fleetPeople'
  | 'fleetPartners'
  | 'companyAccount'
  | 'companyGeneral'
  | 'marketplace'
  | 'myAccountUserPersonalization'
  | 'myAccountDataProtection'
  | 'myAccountPersonalData'
  | 'marketplaceFavorites'
  | 'marketplaceSearchagent'
  | 'marketplaceGeneral'
  | 'myAccountUserNotifications'
  | 'vehicleTrackingHistory'
  | 'vehicleGallery'

export type GenericObject = Record<string, string | boolean | number>

export type NavigationType =
  | 'navigationVehicle'
  | 'navigationMarketplace'
  | 'navigationAccount'

export const APP_DATE_FORMATS_1: CptDateFormats = {
  parse: {
    dateInput: 'MM/YYYY'
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    default: 'DD.MM.YYYY'
  }
}

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  ppEnvironmentDomain

  gliderEvents = {
    mousedown: null,
    id: null
  }

  preventTabSwipe = false
  preventClick = false

  public theme

  public chatBotState
  constructor (
    private readonly snackBar: MatSnackBar,
    private readonly transifexTranslationsService: TranslationService,
    private readonly logger: NGXLogger,
    public dialog: MatDialog,
    private readonly templateStoreService: TemplateStoreService,
    private readonly pwaService: PwaService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>,
    private readonly translation: TranslateService,
    private readonly router: Router,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly cptSharedService: CPTSharedService
  ) {
    this.ppEnvironmentDomain = this.remoteConfigService.get(
      'ppEnvironments.cpt.environment.domain'
    )
    this.theme = this.remoteConfigService.get('theme')
  }

  public unsubscribe (componentSub: object): void {
    for (const sub in componentSub) {
      try {
        componentSub[sub].unsubscribe()
      } catch (error) {
        // no need to show log
      }
    }
  }

  getDashEco () {
    return {
      de: {
        locale: this.remoteConfigService.get('dashEco.de.locale'),
        baseUrl: this.remoteConfigService.get('dashEco.de.baseUrl'),
        detailsUrl: this.remoteConfigService.get('dashEco.de.detailsUrl')
      },
      fr: {
        locale: this.remoteConfigService.get('dashEco.fr.locale'),
        baseUrl: this.remoteConfigService.get('dashEco.fr.baseUrl'),
        detailsUrl: this.remoteConfigService.get('dashEco.fr.detailsUrl')
      },
      it: {
        locale: this.remoteConfigService.get('dashEco.it.locale'),
        baseUrl: this.remoteConfigService.get('dashEco.it.baseUrl'),
        detailsUrl: this.remoteConfigService.get('dashEco.it.detailsUrl')
      }
    }
  }

  public fileNameFromUrl (url: string): string {
    return url.substring(url.lastIndexOf('/') + 1)
  }

  public preventEventPropagation (ev: Event): boolean {
    return this.cptSharedService.preventEventPropagation(ev)
  }

  public setPhoneMask (prefix, currentNumber?: string): string {
    return this.cptSharedService.setPhoneMask(prefix, currentNumber)
  }

  getTelephoneMask (
    phone: string,
    prefixLength: number,
    comparePrefix
  ): PhoneMask {
    return this.cptSharedService.getTelephoneMask(phone, prefixLength, comparePrefix)
  }

  findUserPhone (user: User): UserTelephoneGeneral {
    if (user?.telephone != null) {
      return {
        mobile:
          user.telephone.find(
            t =>
              t.type.toLowerCase() === 'mobile' &&
              t.usage.toLowerCase() === 'private' &&
              t.primary
          ) ?? null,
        mobileBusiness:
          user.telephone.find(
            t =>
              t.type.toLowerCase() === 'mobile' &&
              t.usage.toLowerCase() === 'business' &&
              t.primary
          ) ?? null,
        landlineBusiness:
          user.telephone.find(
            t =>
              t.type.toLowerCase() === 'landline' &&
              t.usage.toLowerCase() === 'business' &&
              t.primary
          ) ?? null,
        landlinePersonal:
          user.telephone.find(
            t =>
              t.type.toLowerCase() === 'landline' &&
              t.usage.toLowerCase() === 'private' &&
              t.primary
          ) ?? null
      }
    }
  }

  setFocus (el: ElementRef): void {
    this.cptSharedService.setFocus(el)
  }

  public setLicensePlateMask (): string {
    /*
    0 digits (like 0 to 9 numbers)
    9 digits (like 0 to 9 numbers), but optional
    A letters (uppercase or lowercase) and digits
    S only letters (uppercase or lowercase)
    U only letters uppercase
    L only letters lowercase
    */
    let licensePlateMask
    if (this.remoteConfigService.get('hasLicensePlateMask')) {
      switch (
        this.remoteConfigService.get('country.code').toString().toLowerCase()
      ) {
        case 'ch':
          // swiss license plates are always: XX YYYYY.. two letters, space and then numbers
          licensePlateMask = 'UU A*'
          break
      }
    }
    return licensePlateMask
  }

  public showLicensePlateMask (licensePlate): boolean {
    const licensePlateMask = this.setLicensePlateMask()
    // check if mask and license plate exists
    if (
      this.stringExists(licensePlateMask) &&
      this.stringExists(licensePlate)
    ) {
      let show = false

      // set up regex
      const licensePlateNormalRegex: string = this.remoteConfigService.get(
        'regEx.licencePlateRegEx.types.normal.regex'
      )
      const licensePlateOfficialRegex: string = this.remoteConfigService.get(
        'regEx.licencePlateRegEx.types.official.regex'
      )
      const deLicencePlateRegex = new RegExp(
        licensePlateNormalRegex + '|' + licensePlateOfficialRegex,
        'i'
      )
      const chLicencePlateRegex = new RegExp(licensePlateOfficialRegex, 'i')

      const country = this.remoteConfigService
        .get('country.code')
        .toString()
        .toLowerCase()
      if (country === 'de') {
        show = deLicencePlateRegex.test(licensePlate)
      } else {
        // ch license regex doesnt check first 2 letters
        const licence = licensePlate.slice(2).trim()
        show = chLicencePlateRegex.test(licence)
      }
      return show === true
    } else {
      return false
    }
  }

  getErrorTranslateKey (errors, translateKeyPrefix: string): string {
    let translateKey = ''
    if (errors != null) {
      const keys = Object.getOwnPropertyNames(errors)
      if (keys?.[0] != null) {
        translateKey = translateKeyPrefix + String(keys[0])
      }
    }
    return translateKey
  }

  public isEqual (value, other): boolean {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this

    // Get the value type
    const type = Object.prototype.toString.call(value)

    // If the two objects are not the same type, return false
    if (type !== Object.prototype.toString.call(other)) return false

    // If items are not an object or array, return false
    if (!['[object Array]', '[object Object]'].includes(type)) return false

    // Compare the length of the length of the two items
    const valueLen =
      type === '[object Array]' ? value.length : Object.keys(value).length
    const otherLen =
      type === '[object Array]' ? other.length : Object.keys(other).length
    if (valueLen !== otherLen) return false

    // Compare two items - doesnt work if i set return type
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const compare = function (item1, item2) {
      // Get the object type
      const itemType = Object.prototype.toString.call(item1)

      // If an object or array, compare recursively
      if (['[object Array]', '[object Object]'].includes(itemType)) {
        if (!self.isEqual(item1, item2)) {
          return false
        }
      } else {
        // Otherwise, do a simple comparison
        // If the two items are not the same type, return false
        if (itemType !== Object.prototype.toString.call(item2)) return false

        // Else if it's a function, convert to a string and compare
        // Otherwise, just compare
        if (itemType === '[object Function]') {
          if (item1.toString() !== item2.toString()) return false
        } else {
          if (item1 !== item2) return false
        }
      }
    }

    // Compare properties
    if (type === '[object Array]') {
      for (let i = 0; i < valueLen; i++) {
        if (compare(value[i], other[i]) === false) return false
      }
    } else {
      for (const key in value) {
        // eslint-disable-next-line no-prototype-builtins, @typescript-eslint/strict-boolean-expressions
        if (value.hasOwnProperty(key)) {
          if (compare(value[key], other[key]) === false) return false
        }
      }
    }

    // If nothing failed, return true
    return true
  }

  public stringExists (string: string): boolean {
    return this.cptSharedService.stringExists(string)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  public deepCopy (data) {
    try {
      return JSON.parse(JSON.stringify(data))
    } catch (error) {}
  }

  /**
   *
   * @param translationKey
   * @param text
   * @param duration seconds, 0 - wont close automatically
   */
  showSnackbar (
    translationKey: string | string[],
    text: string,
    duration?: number
  ): void {
    // this is only set to true during cypress test, will not prevent snackbar
    const hideSnackbar = localStorage.getItem('hideSnackbar')
    if (hideSnackbar === 'true') {
      return
    }
    this.cptSharedService.showSnackbar(translationKey, text, duration)
  }

  closeSnackbar (): void {
    this.cptSharedService.closeSnackbar()
  }

  getSnackbarRef () {
    return this.cptSharedService.snackBarRef
  }

  removeSecondsInString (text: string): string {
    if (!this.stringExists(text)) {
      return
    }
    // works with this format
    // 07:15:00 - 12:00:00, 13:15:00 - 18:00:00
    // result: 07:15 - 12:00,  13:15 - 18:00
    // eslint-disable-next-line no-useless-escape
    const search1 = /\:\d{2}$|\:\d{2}\s+/
    const replaceWith1 = ' '

    // eslint-disable-next-line no-useless-escape
    const search2 = /\:\d{2}\,/
    const replaceWith2 = ', '

    const res = text.split(search1).join(replaceWith1)
    return res.split(search2).join(replaceWith2).trim()
  }

  openPreviewTab (url): void {
    const previewWindow = window.open('', '_blank')
    if (previewWindow != null) {
      previewWindow.location.href = url
    } else {
      this.showSnackbar('customerPortal.customer-portal.allow-popups', null)
    }
  }

  setHtmlBlankTab (data: Window, text: string): void {
    data.document.body.innerHTML =
      '<!DOCTYPE html><html><head><meta name="viewport" content="width=device-width, initial-scale=1"><style>.wrapper {display: flex; flex-direction: column;justify-content: center; align-items: center;height: 80vh;} .text {font-family: Canela Black; font-size: 36px; color: #000E3D;}.loader {border: 6px solid #f3f3f3;border-radius: 50%;border-top: 6px solid #1D63DE;width: 60px;height: 60px;-webkit-animation: spin 2s linear infinite; animation: spin 2s linear infinite; } @-webkit-keyframes spin {0% { -webkit-transform: rotate(0deg); }100% { -webkit-transform: rotate(360deg); }}@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}</style></head><body><div class="wrapper"> <h2 class="text">' +
      text +
      '</h2> <div class="loader"></div></div></body></html>'
    data.focus()
  }

  setDataBlankTab (data: Window, url: string): void {
    if (url != null) {
      data.location.href = url
    } else {
      this.setHtmlBlankTab(
        data,
        'customerPortal.customer-portal.not-exist-data'
      )
      setTimeout(() => {
        data.close()
      }, 2000)
    }
  }

  openPreviewPopup (preview, format): void {
    this.cptSharedService.openPreviewPopup(preview, format)
  }

  downloadFile (url: string, name: string): void {
    this.cptSharedService.downloadFile(url, name)
  }

  // if we need to prevent click from executing while we are scrolling
  handleGliderEvents (ev, id): void {
    switch (ev.type) {
      case 'mousedown':
        this.preventClick = false
        this.gliderEvents = {
          mousedown: Date.now(),
          id
        }
        break
      case 'mouseup':
        if (
          this.gliderEvents.mousedown == null ||
          this.gliderEvents.id !== id ||
          Date.now() - this.gliderEvents.mousedown > 200
        ) {
          this.preventClick = true
          this.gliderEvents = {
            mousedown: null,
            id: null
          }
        }
        break
      case 'touchstart':
        // for 300 ms prevent tab swiping
        // swiping on glider will not change tabs on mobile devices
        this.preventTabSwipe = true
        setTimeout(() => {
          this.preventTabSwipe = false
        }, 300)
        break
    }
  }

  closedDaysOfWeek (hours: DayTimeDTO[]): string[] {
    const closedDaysOfWeek = [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
      'SUNDAY'
    ]
    hours.forEach(hour => {
      const index = closedDaysOfWeek.indexOf(hour.openDay)
      if (index > -1) {
        closedDaysOfWeek.splice(index, 1)
      }
    })
    return closedDaysOfWeek
  }

  datepickerDateFilter (d: Date, closedDaysOfWeek): boolean {
    const DaysNumber = {
      1: 'MONDAY',
      2: 'TUESDAY',
      3: 'WEDNESDAY',
      4: 'THURSDAY',
      5: 'FRIDAY',
      6: 'SATURDAY',
      0: 'SUNDAY'
    }
    if (d != null) {
      const day = d.getDay()
      return !(closedDaysOfWeek.indexOf(DaysNumber[day]) > -1)
    }
    return true
  }

  isComissionNumber (vin: string): boolean {
    if (this.stringExists(vin)) {
      if (vin.startsWith('commissionNumber-')) {
        return true
      }
    }
    return false
  }

  hasConsentsForEFDS (userConsents: UserConsentsResponse): boolean {
    return (
      userConsents?.group?.consents?.channels?.find(
        channel => channel?.allow
      ) !== undefined
    )
  }

  openConfirmationPopup (
    title: string,
    text: string,
    skipTranslate?: boolean
  ): void {
    this.dialog.open(ConfirmationPopupComponent, {
      data: {
        title,
        text,
        skipTranslate,
        useInnerHtml: true
      },
      panelClass: 'mat-dialog-cpt'
    })
  }

  /**
   * @param block start, center, end, nearest
   */
  scrollIntoView (el: ElementRef, block?: string): void {
    if (el != null) {
      try {
        el.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: block != null ? block : 'start'
        })
      } catch (error) {}
    }
  }

  scrollToTop (): void {
    try {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    } catch (error) {}
  }

  getDealerInfo (dealer: DealerDTO): string {
    return (
      String(dealer.name) +
      ', ' +
      String(dealer.subname) +
      ' ' +
      String(dealer.efitId)
    )
  }

  getDealerSubname (dealer: DealerDTO): string {
    if (this.stringExists(dealer?.subname)) {
      return dealer.subname
    }
    return dealer?.name
  }

  formatDealerName (dealer: DealerDTO): string {
    let name = ''
    if (dealer != null) {
      name = dealer.name + ', ' + dealer.city + ', ' + dealer.street
    }
    return name
  }

  sortDealers (value: DealerDTO[]): DealerDTO[] {
    const sorted = value.sort((a, b) => {
      const name = (a.name ?? '').localeCompare(b.name)
      const subname = (a.subname ?? '').localeCompare(b.subname)
      const city = (a.city ?? '').localeCompare(b.city)
      const street = (a.street ?? '').localeCompare(b.street)
      return name !== 0
        ? name
        : subname !== 0
          ? subname
          : city !== 0
            ? city
            : street
    })
    return sorted
  }

  filterDealers (value: string, array: DealerDTO[]): DealerDTO[] {
    if (typeof value === 'string' && this.stringExists(value)) {
      const filterValue = value?.toLowerCase()?.trim()
      const filteredArray = array.filter((dealer: DealerDTO) => {
        const dealerName = this.formatDealerName(dealer)
        return (
          dealerName.toLowerCase().includes(filterValue) ||
          (dealer.name ?? '').toLowerCase().includes(filterValue) ||
          (dealer.subname ?? '').toLowerCase().includes(filterValue) ||
          (dealer.city ?? '').toLowerCase().includes(filterValue) ||
          (dealer.street ?? '').toLowerCase().includes(filterValue)
        )
      })
      return this.sortDealers(filteredArray)
    } else {
      return array
    }
  }

  sortArray (array: any[], sortOrder?: 'desc' | 'asc'): any[] {
    if (sortOrder === 'asc' || !this.stringExists(sortOrder)) {
      return array.sort((a, b) => (a > b ? 1 : -1))
    } else {
      return array.sort((a, b) => (a < b ? 1 : -1))
    }
  }

  switchBoolean (value: boolean): boolean {
    if (value != null) {
      return !value
    }
    // if the boolean didnt exist set it to true
    return true
  }

  setUpProfilePicture (pictureUrl: string): string {
    if (pictureUrl != null) {
      let url = ''
      if (pictureUrl.includes('https')) {
        url = pictureUrl
      } else {
        url = 'https://' + this.ppEnvironmentDomain + pictureUrl
      }
      return url
    }
  }

  dateExists (date: Date | string): boolean {
    return this.cptSharedService.dateExists(date)
  }

  /**
   *
   * @param date
   * @param format default:'DD.MM.YYYY'
   * @returns
   */
  formatDate (date: Date | string, format?: string): string {
    if (this.dateExists(date)) {
      try {
        let dateObj
        if (typeof date === 'string') {
          dateObj = new Date(date)
        } else {
          dateObj = date
        }

        return formatDate(dateObj, 'dd.MM.yyyy.').toString()
      } catch (error) {
        this.logger.error(error)
      }
    }
  }

  capitalize (s: string): string {
    return (s != null && s[0].toUpperCase() + s.slice(1)) ?? ''
  }

  translateLink (link: string): string {
    return this.transifexTranslationsService.translate(link, {
      _key: link,
      _tags: 'notranslate'
    })
  }

  public template (key: string): TemplateRef<Record<string, unknown>> {
    return this.templateStoreService.get(key)
  }

  previewJSON (
    json,
    classOptions?: {
      number?: string
      key?: string
      string?: string
      boolean?: string
      null?: string
    }
  ): string {
    try {
      json = JSON.stringify(json, undefined, 4)
      json = json
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
      return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        function (match: string) {
          let cls = classOptions?.number ?? ''
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = classOptions?.key ?? ''
            } else {
              cls = classOptions?.string ?? ''
            }
          } else if (/true|false/.test(match)) {
            cls = classOptions?.boolean ?? ''
          } else if (match.includes('null')) {
            cls = classOptions?.null ?? ''
          }
          return '<span class="' + cls + '">' + match + '</span>'
        }
      )
    } catch (error) {}
  }

  showRemindersForVehicle (vehicle: VehicleDTOExtended): boolean {
    if (this.remoteConfigService.get('remindersConfig.hasReminders')) {
      if (
        this.remoteConfigService.get(
          'remindersConfig.showOnlyForManualVehicles'
        ) &&
        vehicle.createdByDataSource !== 'customer_portal'
      ) {
        return false
      }
      return true
    }
    return false
  }

  goToVehicle (v: VehicleDTOExtended, tab: string): void {
    this.sendContentModulesVehicleTeaserData(
      'shared.my-vehicles',
      `${v.brand ?? ''}  ${v.model ?? ''}`
    )
    void this.router.navigate(['/vehicles', v.vin, tab])
  }

  goToVehicleTNT (v: TrackAndTraceVehicleDTOExtended, tab: string): void {
    // instead of vin set commissionNumber with prefix so we can differentiate it from vin
    this.sendContentModulesVehicleTeaserData(
      'shared.my-vehicles',
      `${v.brand ?? ''}  ${v.model ?? ''}`
    )
    void this.router.navigate([
      '/vehicles',
      'commissionNumber-' + v.commissionNumber,
      tab
    ])
  }

  carmatoVehicleLink (v: CarmatoVehicleDataDTO): string {
    let url = ''
    const marketPlaceUrl = this.remoteConfigService.get('marketPlace.url')
    const adNumber = encodeURIComponent(v.uid)
    const brand = encodeURIComponent(v.manufacturerName)
    const model = encodeURIComponent(v.baseModelName)
    url = marketPlaceUrl + brand + '/' + model + '/' + adNumber
    return url
  }

  sendContentModulesVehicleTeaserData (
    contentModuleLabel: string,
    text: string
  ): void {
    const label = this.translateLink(contentModuleLabel) + '|' + text ?? ''
    this.cptGtmService.sendContentModulesData(
      'vehicle teaser',
      null,
      label,
      'Tab Switch',
      label
    )
  }

  tabSwipe (
    e: TouchEvent,
    when: string,
    swipeData: {
      swipeCoord
      swipeTime
      currentTab: number
      tabsCount: number
    }
  ): void {
    if (this.preventTabSwipe) {
      return
    }
    const coord: [number, number] = [
      e.changedTouches[0].clientX,
      e.changedTouches[0].clientY
    ]
    const time = new Date().getTime()
    if (when === 'start') {
      swipeData.swipeCoord = coord
      swipeData.swipeTime = time
    } else if (when === 'end') {
      const direction = [
        coord[0] - swipeData.swipeCoord[0],
        coord[1] - swipeData.swipeCoord[1]
      ]
      const duration = time - swipeData.swipeTime
      if (
        duration < 1000 && //
        Math.abs(direction[0]) > 30 && // Long enough
        Math.abs(direction[0]) > Math.abs(direction[1] * 3)
      ) {
        // Horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous'
        const isFirst = swipeData.currentTab === 0
        const isLast = swipeData.currentTab === swipeData.tabsCount - 1
        if (swipe === 'next') {
          swipeData.currentTab = isLast ? 0 : swipeData.currentTab + 1
        } else if (swipe === 'previous') {
          swipeData.currentTab = isFirst
            ? swipeData.tabsCount - 1
            : swipeData.currentTab - 1
        }
      }
    }
  }

  getKeyByValue (object, value): any {
    return Object.keys(object).find(key => object[key] === value)
  }

  isMobilePwa (): boolean {
    return this.cptSharedService.isMobilePwa()
  }

  objExistsInArray (arr, obj, property): boolean {
    const index = arr?.findIndex(object => object[property] === obj[property])
    if (index === -1) {
      return false
    }
    return true
  }

  objectHasKeys (object: any): boolean {
    if (object == null) {
      return false
    }
    try {
      if (Object.keys(object).length > 0) {
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  }

  getRoute (n: Navigation, replacement: string): string {
    if (n.pathParams == null) {
      return n.path
    } else {
      const pathParams: string = n.pathParams
      const updatedPath = n.path.replace(`{${pathParams}}`, replacement)
      return updatedPath
    }
  }

  currentLanguage (): string {
    return this.cptSharedService.currentLanguage()
  }

  getLink (link: RevolutionLink): string {
    return this.cptSharedService.getLink(link)
  }

  checkAppointment (
    appointment: UpcomingAppointmentDTOExtended,
    ignoreTime?: boolean
  ): boolean {
    const today = new Date()
    if (ignoreTime === true) {
      today.setHours(23, 59, 59)
    }
    return (
      appointment?.status != null &&
      new Date(appointment?.startDate) <= today &&
      !(appointment?.requested === true)
    )
  }

  monthDiff (d1, d2): number {
    let months: number
    months = (d2.getFullYear() - d1.getFullYear()) * 12
    if (months === 0) {
      months = d2.getMonth() - d1.getMonth()
    } else {
      months -= d1.getMonth()
      months += Number(d2.getMonth())
    }
    return months <= 0 ? 0 : months
  }

  landLineValidator (
    control: AbstractControl,
    changeForm: FormGroup,
    mobilePrefixes: Record<string, CountryCallingCode>
  ): ValidationErrors {
    if (control == null || changeForm == null || control.value == null) {
      return {}
    }

    if (changeForm.value.mobilePrefix == null) {
      return { invalidNumber: true }
    }

    if (control.value.length < 2) {
      return { invalidNumber: true }
    }

    if (isNaN(Number(control.value))) {
      return { invalidNumber: true }
    }

    const num: string =
      String(changeForm.value.mobilePrefix) + String(control.value)

    const prefix: string = changeForm.value.mobilePrefix
    const prefixEntry = Object.entries(mobilePrefixes).find(entry => {
      return entry[1] === prefix.substr(1)
    })
    const prefixCountry = prefixEntry[0] as CountryCode

    const parsed = parsePhoneNumber(num, prefixCountry)

    if (!parsed.isPossible()) {
      return { invalidNumber: true }
    }

    if (!parsed.isValid()) {
      return { invalidNumber: true }
    }

    const numberType = parsed.getType()
    if (numberType !== 'FIXED_LINE') {
      return { notLandline: true }
    }

    return {}
  }

  isNumber (evt: KeyboardEvent): boolean {
    return this.cptSharedService.isNumber(evt)
  }

  orderArrayByDate (array: any, property: string, order?: 'desc' | 'asc'): void {
    this.cptSharedService.orderArrayByDate(array, property, order)
  }

  getDocumentDate (doc: BasicDocumentDTOExtended): Date {
    if (this.dateExists(doc?.date)) {
      return doc?.date
    }

    if (this.dateExists(doc?.invoiceDate)) {
      return doc?.invoiceDate
    }

    if (this.dateExists(doc?.dateModified)) {
      return doc?.dateModified
    }
  }

  showServicePackageSection (): boolean {
    const servicePackage = this.remoteConfigService.get(
      'features.servicePackage'
    )
    const hasServicePackage = this.remoteConfigService.get(
      'servicePackage.available'
    )
    const enableEfitData = this.remoteConfigService.get('enableEfitData')

    return servicePackage && hasServicePackage && enableEfitData
  }

  hasCommonElements (array1, array2): boolean {
    const set1 = new Set(
      array1.map(element =>
        typeof element === 'string' ? element.toLowerCase() : element
      )
    )
    const set2 = new Set(
      array2.map(element =>
        typeof element === 'string' ? element.toLowerCase() : element
      )
    )

    for (const element of set1) {
      if (set2.has(element)) {
        return true
      }
    }

    return false
  }

  sortObjectsByBooleanField (a, b, field: string): 0 | 1 | -1 {
    const aIsTrue = a[field] === true
    const bIsTrue = b[field] === true

    if (aIsTrue && !bIsTrue) {
      return -1
    }

    if (!aIsTrue && bIsTrue) {
      return 1
    }

    return 0
  }
}
