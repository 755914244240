import { Injectable } from '@angular/core'
import { type Observable, Subject } from 'rxjs'

export enum SCREEN_SIZE {
  desktop = 'desktop',
  tablet = 'tablet',
  mobile = 'mobile'
}

@Injectable({
  providedIn: 'root'
})
export class ResizeService {
  public resizeSubject: Subject<SCREEN_SIZE>
  public resizeSubjectPx: Subject<number>
  public screenSize: SCREEN_SIZE = null
  public screenSizePx: number = null

  constructor () {
    this.resizeSubject = new Subject()
    this.resizeSubjectPx = new Subject()
  }

  get onResize (): Observable<SCREEN_SIZE> {
    return this.resizeSubject.asObservable()
  }

  get onResizePx (): Observable<number> {
    return this.resizeSubjectPx.asObservable()
  }

  calculateSize (width: number): void {
    const size: SCREEN_SIZE =
      width < 768
        ? SCREEN_SIZE.mobile
        : width >= 768 && width < 1124
          ? SCREEN_SIZE.tablet
          : SCREEN_SIZE.desktop
    this.resize(size, width)
  }

  resize (size: SCREEN_SIZE, width: number): void {
    this.screenSize = size
    this.screenSizePx = width
    this.resizeSubject.next(size)
    this.resizeSubjectPx.next(width)
  }
}
