import { Injectable, isDevMode } from '@angular/core'
import * as _ from 'lodash'
import { NGXLogger } from 'ngx-logger'
import { HubConfiguration, HubConfigurationKey, HubConfigurationValue } from './hub-configuration.model'
import { HubMobileConfigurationKey } from './hub-mobile-configuration.model'

/**
 * The HubConfigurationService expects a `window.configuration` object to be set in time when it's initialized.
 * The values can be overriden at any time using `window.ef.configuration.overrides`
 */
@Injectable({
  providedIn: 'root'
})
export class HubConfigurationService {
  private readonly _configuration: HubConfiguration

  /**
   * @deprecated use `get('key')` instead, check the link
   * @link https://emilfreygroup.atlassian.net/wiki/spaces/INHUB/pages/3183181870/Hub+Configuration
   */
  get configuration (): HubConfiguration {
    return this._configuration
  }

  constructor (private readonly logger: NGXLogger) {
    this._configuration = (window as any).configuration

    // apply overrides
    const overrides = (window as any).ef?.configuration?.overrides
    this._configuration = { ...this._configuration, ...overrides }
  }

  /**
   * Returns a value in the configuration specified by the given key.
   * @param key The key in the configuration.
   * @returns The value specified by the given key, or undefined if the key doesn't exist.
   */
  public get<Type extends HubConfigurationValue | undefined> (key: HubConfigurationKey | HubMobileConfigurationKey): Type {
    if (this._configuration === undefined) {
      return undefined
    }

    // if its an array, return a copy of it
    return _.isArray(this._configuration[key]) ? [...this._configuration[key]] as Type : this._configuration[key] as Type
  }

  getRequired<Type extends HubConfigurationValue | undefined> (key: HubConfigurationKey | HubMobileConfigurationKey): Type {
    if (this._configuration === undefined) {
      throw new Error('Configuration is not initialized')
    }

    const value = this._configuration[key]

    if (value === null || value === undefined) {
      throw new Error(`Unable to resolve required value with key: '${key}'`)
    }

    return (_.isArray(value) ? [...value] : value) as Type
  }

  /**
   * To define an array in the configuration, the indexes are contained in the keys.
   *
   * ```
   * "hub.array.0.title": "hello"
   * "hub.array.0.name": "bob"
   * "hub.array.1.title": "hi"
   * "hub.array.1.name": "alice"
   * ```
   *
   * When given a prefix (in the example this is "hub.array"), this function returns the length of this array.
   *
   * This can be used to iterate over such an array.
   *
   * ```
   * for (let menuIndex = 0; menuIndex < this.getArrayLength('hub.array'); menuIndex++)
   * {
   *   let title = this.get(`hub.array.${menuIndex}.title`)
   *   let name = this.get(`hub.array.${menuIndex}.name`)
   * }
   * ```
   *
   * @param prefix prefix for the array without the ending dot
   * @returns the length of the array defined by a prefix and indexes
   */
  public getArrayLength (prefix: string): number {
    const regex = new RegExp(`^${prefix}.(?<i>[0-9]+)(?:\.|$)`)
    const matches = _.chain(this._configuration)
      .keys()
      .map(key => parseInt(key.match(regex)?.pop(), 10))
      .filter(index => _.isFinite(index))
      .value()

    if (matches.length > 0) {
      return _.max(matches) + 1
    }

    return 0
  }

  /**
   * Call this for every lazy-loaded module. The NGXLogger's `LoggerConfig` gets created with each injector.
   * @param loggerInstance the logger instance you want to set
   */
  setLoggerLevel (loggerInstance: NGXLogger): void {
    // set log level for dev and for production
    const loggerConfig = loggerInstance.getConfigSnapshot()
    if (isDevMode()) {
      loggerConfig.level = this.get('hub.log.debug-level') ?? 0
      loggerConfig.enableSourceMaps = true
    } else {
      loggerConfig.level = this.get('hub.log.production-level') ?? 4
      loggerConfig.enableSourceMaps = false
    }
    loggerInstance.updateConfig(loggerConfig)
  }
}
