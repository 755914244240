import { Injectable } from '@angular/core'
import { GoogleTagManagerService } from 'angular-google-tag-manager'
import { NGXLogger } from 'ngx-logger'
import moment from 'moment'
import { EcondaService } from './econda.service'
import { BrandDTO, DealerDTO, VehicleDTOExtended } from '../interfaces/shared'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'

export interface GTMPageViewData {
  country: string
  language: string
  pageType: string
  loggedIn: string
  userid: string
  subSystem: string
  internal: string
  event: string
}

export interface GTMGenericEventData {
  event_category: string
  event_action: string
  event_label: string
}

export interface GTMNavigationMenu extends GTMGenericEventData {
  menu_position: string
  navigation_title: string
  navigation_linktype?: string
}

export interface GTMContentModules extends GTMGenericEventData {
  content_module: string
  content_module_action?: string
  content_module_label: string
}
export interface GTMFileUploadForm extends GTMGenericEventData {
  file_type: string
}
export interface GTMOutboundLink extends GTMGenericEventData {
  outbound_link_type: string
  dealer: string
}
export interface GTMUserCarSettingsForm extends GTMGenericEventData {
  car_brand: string
  model_text: string

  kilometer_reading?: number
  color?: string
  transmission_type_text?: string
  fuel_type_text?: string

  engine_performance?: number
  commissioning?: number
  co2emission?: number
}

export interface GTMCLickData extends GTMGenericEventData {
  button_text: string
  destination_url: string
}

export interface GTMCarListingData extends GTMGenericEventData {
  cars: string
}

export interface GTMCarAddData extends GTMGenericEventData {
  product_name: string
  product_id: string
}

export interface GTMAppointmentsData extends GTMGenericEventData {
  appointments: string
}

export interface GTMSupportData extends GTMGenericEventData {
  button_action: string
}

export interface GTMAccordionData extends GTMGenericEventData {
  accordian_type: string
  accordian_label: string
}

export interface GTMServiceData extends GTMGenericEventData {
  service_type: string
  button_action: string
}

export interface GTMCardetailsChangeData extends GTMGenericEventData {
  product_name: string
  product_id: string
  kilometer_reading: string
  colour: string
  transmission_type: string
  engine_performance: string
  fuel_type: string
  commissioning: string
  co2emission: string
}

export interface GTMCarDocumentsData extends GTMCardetailsChangeData {
  dealer_info: string
}

export interface GTMPersonalisationData extends GTMGenericEventData {
  personalization_favorite_makes: BrandDTO[]
  personalization_body_variant: string[]
  personalization_transmission_type: string[]
  personalization_car_usage: string[]
  personalization_fuel_type: string[]
  personalization_drive_type: string[]
  personalization_equipment_level: string
  personalization_efficiency: string[]
}

export interface GTMDealerOutboundData extends GTMGenericEventData {
  dealer_action: string
  dealer_info: string
}

export interface GTMFormSubmitButtonData extends GTMGenericEventData {
  form_name: string
  button_action: string
}

export interface GTMFormErrorData extends GTMGenericEventData {
  form_name: string
  error_type: string
}

export interface GTMGenericFormData extends GTMGenericEventData {
  form_step: number | string
}

export interface GTMServiceAppointmentCheckoutData extends GTMGenericFormData {
  delivery?: string
  dealer?: string
  service_type?: string
  appointment_date?: string
  car_brand?: string
  model_text?: string
  car_replacement_vehicle?: 'Yes' | 'No'
}

export interface GTMAddCarData extends GTMGenericFormData {
  car_brand?: string
  model_text?: string
}

export interface GTMOwnershipChangeData extends GTMGenericFormData {
  car_brand?: string
  model_text?: string
  ownership_change_reason?: string
}

export interface GTMContactFormData extends GTMGenericFormData {
  form_name?: string
  dealer_info?: string
}

export interface GTMContentModuleData extends GTMGenericEventData {
  content_module: string
  content_module_action: string
  content_module_label: string
}

export interface GTMDownloadData extends GTMGenericEventData {
  download_type: string
  car_brand: string
  model_text: string
}

export interface GTMEventData {
  event: string
  event_name:
    | 'servicehistory_billing'
    | 'servicehistory_accordians'
    | 'personalization'
    | 'dealer_outbound_links'
    | 'teaser_tile_services'
    | 'vehicledocuments_download'
    | 'vehicledocuments_burger'
    | 'vehicledocuments_add_added'
    | 'vehicledocuments_add'
    | 'cardetails_change_commit'
    | 'cardetails_changeclick'
    | 'service'
    | 'teaser_tile'
    | 'my_cars_listing'
    | 'main_teaser'
    | 'navigation'
    | 'navigation_menu'
    | 'my_cars_hinzufuegen'
    | 'my_cars_added'
    | 'my_appointments_listing'
    | 'my_appointments_add'
    | 'my_appointments_drivinglicence_uploaded'
    | 'support_contactus'
    | 'support'
    | 'accordian'
    | 'mydocuments_download'
    | 'form_submit_button'
    | 'form_error'
    | 'customer_portal_loginpage'
    | 'service_appointment_checkout'
    | 'add_car_form'
    | 'ownership_change_form'
    | 'contact_form_checkout'
    | 'content_modules'
    | 'downloads'
    | 'outbound_links'
    | 'updated_car_settings_form'
    | 'file_upload_form'
  navigation_menu?: GTMNavigationMenu
  content_modules?: GTMContentModules
  outbound_links?: GTMOutboundLink
  updated_car_settings_form?: GTMUserCarSettingsForm
  file_upload_form?: GTMFileUploadForm
  navigation?: GTMNavigationMenu
  main_teaser?: GTMCLickData
  teaser_tile?: GTMCLickData
  my_cars_listing?: GTMCarListingData
  my_cars_hinzufuegen?: GTMGenericEventData
  my_cars_added?: GTMCarAddData
  my_appointments_listing?: GTMAppointmentsData
  my_appointments_add?: GTMGenericEventData
  my_appointments_drivinglicence_uploaded?: GTMGenericEventData
  support_contactus?: GTMGenericEventData
  support?: GTMSupportData
  accordian?: GTMAccordionData
  service?: GTMServiceData
  cardetails_changeclick?: GTMCardetailsChangeData
  cardetails_change_commit?: GTMCardetailsChangeData
  vehicledocuments_add?: GTMCarDocumentsData
  vehicledocuments_add_added?: GTMCarDocumentsData
  vehicledocuments_burger?: GTMCarDocumentsData
  vehicledocuments_download?: GTMCarDocumentsData
  teaser_tile_services?: GTMCLickData
  personalization?: GTMPersonalisationData
  dealer_outbound_links?: GTMDealerOutboundData
  servicehistory_accordians?: GTMCarDocumentsData
  servicehistory_billing?: GTMCardetailsChangeData
  form_submit_button?: GTMFormSubmitButtonData
  form_error?: GTMFormErrorData
  customer_portal_loginpage?: GTMGenericEventData
  service_appointment_checkout?: GTMServiceAppointmentCheckoutData
  add_car_form?: GTMAddCarData
  ownership_change_form?: GTMOwnershipChangeData
  contact_form_checkout?: GTMContactFormData
  downloads?: GTMDownloadData
}

@Injectable({
  providedIn: 'root'
})
export class CptGoogleTagmanagerService {
  gaEvent = 'gaEvent'
  hasGoogleTagManager: boolean
  hasEconda: boolean

  constructor(
    private readonly gtm: GoogleTagManagerService,
    private readonly econdaService: EcondaService,
    private readonly logger: NGXLogger,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasGoogleTagManager = this.remoteConfigService.get(
      'webTracking.googleTagManager'
    )
    this.hasEconda = this.remoteConfigService.get('webTracking.econda')
  }

  send(data: GTMPageViewData | GTMEventData | any): void {
    if (this.hasGoogleTagManager) {
      this.gtm.pushTag(data).then(
        res => {
          this.logger.debug(res)
        },
        err => {
          this.logger.error(err)
        }
      )
    }
  }

  sendNavigationLinkData(
    link: string,
    menuPosition: string,
    label?: string,
    linkType?: string
  ): void {
    const data: GTMEventData = {
      event: this.gaEvent,
      event_name: 'navigation_menu',
      navigation_menu: {
        menu_position: menuPosition,
        navigation_title: link != null ? link : label,
        navigation_linktype: linkType ?? '',
        event_action: menuPosition,
        event_category: 'Navigation',
        event_label: link != null ? link : label
      }
    }
    this.send(data)
  }

  sendContentModulesData(
    contentModule: string,
    contentModuleAction: string,
    contentModuleLabel: string,
    eventAction: string,
    eventLabel: string
  ): void {
    const data: GTMEventData = {
      event: this.gaEvent,
      event_name: 'content_modules',
      content_modules: {
        content_module: contentModule,
        content_module_label: contentModuleLabel,
        event_action: eventAction,
        event_category: 'Content Modules',
        event_label: eventLabel
      }
    }
    if (contentModuleAction != null) {
      data.content_modules.content_module_action = contentModuleAction
    }
    this.send(data)
  }

  sendOutboundData(outboundLinkType: string, dealer: DealerDTO): void {
    const data: GTMEventData = {
      event: this.gaEvent,
      event_name: 'outbound_links',
      outbound_links: {
        outbound_link_type: outboundLinkType,
        dealer: this.getDealerInfo(dealer),

        event_category: 'Outbound Links',
        event_action: outboundLinkType,
        event_label: this.getDealerInfo(dealer)
      }
    }
    this.send(data)
  }

  sendFileUpload(fileType: string, step: string): void {
    const data: GTMEventData = {
      event: this.gaEvent,
      event_name: 'file_upload_form',
      file_upload_form: {
        file_type: fileType,

        event_category: 'File Upload Form',
        event_action: step,
        event_label: fileType
      }
    }
    this.send(data)
  }

  sendUserCarSettingsForm(
    vehicle: VehicleDTOExtended,
    step: string,
    field: string
  ): void {
    const data: GTMEventData = {
      event: this.gaEvent,
      event_name: 'updated_car_settings_form',
      updated_car_settings_form: {
        car_brand: vehicle?.brand,
        model_text: vehicle?.model,
        event_category: 'Updated Car Settings Form',
        event_action: step,
        event_label: field
      }
    }
    if (step === 'Step 2') {
      data.updated_car_settings_form.kilometer_reading =
        vehicle?.manualMileage ?? vehicle?.currentMileage
      data.updated_car_settings_form.color =
        vehicle?.details?.exteriorColorDisplayName ??
        vehicle?.details?.exteriorColor
      data.updated_car_settings_form.transmission_type_text =
        vehicle?.details?.transmissionType
      data.updated_car_settings_form.fuel_type_text = vehicle?.details?.fuelType
      data.updated_car_settings_form.engine_performance =
        vehicle?.details?.enginePower
      data.updated_car_settings_form.commissioning =
        vehicle?.details?.co2Emission
      data.updated_car_settings_form.co2emission = vehicle?.details?.co2Emission
    }
    this.send(data)
  }

  sendMainTeaserData(buttonText: string, destinationUrl: string): void {
    const data: GTMEventData = {
      event: this.gaEvent,
      event_name: 'main_teaser',
      main_teaser: {
        button_text: buttonText,
        destination_url: destinationUrl,
        event_category: 'Main Teaser',
        event_action: buttonText,
        event_label: destinationUrl
      }
    }
    this.send(data)
  }

  sendFormSuccessData(formName: string, buttonText: string): void {
    const data: GTMEventData = {
      event: this.gaEvent,
      event_name: 'form_submit_button',
      form_submit_button: {
        form_name: formName,
        button_action: buttonText,
        event_category: 'Form submit button',
        event_action: formName,
        event_label: buttonText
      }
    }
    this.send(data)
  }

  sendFormErrorData(formName: string, errorText: string): void {
    const data: GTMEventData = {
      event: this.gaEvent,
      event_name: 'main_teaser',
      form_error: {
        form_name: formName,
        error_type: errorText,
        event_category: 'Form submit button',
        event_action: formName,
        event_label: errorText
      }
    }
    this.send(data)
  }

  sendLoginGtmData(success: boolean): void {
    const text = success ? 'Success' : 'Failed'
    const data: GTMEventData = {
      event: this.gaEvent,
      event_name: 'customer_portal_loginpage',
      customer_portal_loginpage: {
        event_category: 'Customer Portal Login',
        event_action: 'Login',
        event_label: text
      }
    }
    this.send(data)
  }

  sendLoginSuccessData(): void {
    const data: GTMEventData = {
      event: this.gaEvent,
      event_name: 'customer_portal_loginpage',
      customer_portal_loginpage: {
        event_category: 'Customer Portal Login',
        event_action: 'Login',
        event_label: 'Success'
      }
    }
    this.send(data)
  }

  sendResetPasswordGtmData(): void {
    const data: GTMEventData = {
      event: this.gaEvent,
      event_name: 'customer_portal_loginpage',
      customer_portal_loginpage: {
        event_category: 'Customer Portal Login',
        event_action: 'Login',
        event_label: 'Reset password submitted'
      }
    }
    this.send(data)
  }

  sendLiveChatOpenedGtmData(): void {
    const data: GTMEventData = {
      event: this.gaEvent,
      event_name: 'content_modules',
      content_modules: {
        content_module: 'Mini CTA',
        content_module_action: 'Click',
        content_module_label: 'LiveChat',
        event_action: 'Mini CTA',
        event_category: 'Content Modules',
        event_label: 'LiveChat'
      }
    }
    this.send(data)
  }

  sendDownloadsGtmData(
    downloadtype: string,
    carbrand: string,
    modeltext: string,
    documentname: string
  ): void {
    const data: GTMEventData = {
      event: this.gaEvent,
      event_name: 'downloads',
      downloads: {
        download_type: downloadtype,
        car_brand: carbrand,
        model_text: modeltext,
        event_action: documentname,
        event_label: carbrand + ' - ' + modeltext,
        event_category: 'Downloads'
      }
    }
    this.send(data)
  }

  sendVehiclesLoaded(vehicles: VehicleDTOExtended[]): void {
    /** GTM - 2.4.1 */
    const gtmData: GTMEventData = {
      event: this.gaEvent,
      event_name: 'my_cars_listing',
      my_cars_listing: {
        cars: vehicles?.length != null ? vehicles?.length?.toString() : '0',
        event_category: 'My Cars',
        event_action: 'Listing',
        event_label:
          vehicles?.length != null ? vehicles?.length?.toString() : '0'
      }
    }
    this.send(gtmData)
  }

  sendServiceAppointmentCheckoutData(GTMData): void {
    const data: GTMEventData = {
      event: this.gaEvent,
      event_name: 'service_appointment_checkout',
      service_appointment_checkout: {
        form_step: GTMData.step,
        event_category: 'Service Appointment Checkout',
        event_action:
          GTMData.step != null ? 'Step ' + String(GTMData.step) : '',
        event_label: ''
      }
    }
    if (GTMData.delivery != null) {
      data.service_appointment_checkout.delivery = GTMData.delivery
    }
    if (GTMData.selectedServices != null) {
      data.service_appointment_checkout.service_type =
        GTMData.selectedServices.join(' | ')
    }
    if (GTMData.dealer != null) {
      data.service_appointment_checkout.dealer = this.getDealerInfo(
        GTMData.dealer
      )
    }
    if (GTMData.date instanceof Date && !isNaN(GTMData.date.valueOf())) {
      data.service_appointment_checkout.appointment_date = moment(GTMData.date)
        .format('YYYY-MM-DD')
        .toString()
    }
    if (GTMData.reifenManager === true) {
      data.service_appointment_checkout.event_action =
        GTMData.step != null
          ? data.service_appointment_checkout.event_action +
            ' - Forwarding to Wheel Manager'
          : 'Forwarding to Wheel Manager'
    }
    if (GTMData.brand != null) {
      data.service_appointment_checkout.car_brand = GTMData.brand
    }
    if (GTMData.model != null) {
      data.service_appointment_checkout.model_text = GTMData.model
    }
    if (
      GTMData.step !== 1 &&
      GTMData.step !== 2 &&
      GTMData.step !== 3 &&
      GTMData.replacement != null
    ) {
      data.service_appointment_checkout.car_replacement_vehicle =
        GTMData.replacement === true ? 'Yes' : 'No'
    }

    this.send(data)
  }

  sendAddCarData(step: number, vehicle?: VehicleDTOExtended): void {
    const gtmData: GTMEventData = {
      event: this.gaEvent,
      event_name: 'add_car_form',
      add_car_form: {
        form_step: 'Step ' + String(step),
        car_brand: vehicle?.brand != null ? vehicle.brand : '',
        model_text: vehicle?.model != null ? vehicle.model : '',
        event_category: 'Add Car Form',
        event_action: 'Step ' + String(step),
        event_label: ''
      }
    }
    this.send(gtmData)
  }

  sendOwnershipChangeData(
    step: number,
    vehicle?: VehicleDTOExtended,
    reason?: string
  ): void {
    const gtmData: GTMEventData = {
      event: this.gaEvent,
      event_name: 'ownership_change_form',
      ownership_change_form: {
        form_step: 'Step ' + String(step),
        event_category: 'Ownership Change Form',
        event_action: 'Step ' + String(step),
        event_label: ''
      }
    }
    if (vehicle?.brand != null) {
      gtmData.ownership_change_form.car_brand = vehicle.brand
    }
    if (vehicle?.model != null) {
      gtmData.ownership_change_form.model_text = vehicle.model
    }
    if (reason != null) {
      let translatedReason
      switch (reason) {
        case 'EXCHANGE':
          translatedReason = 'part exchange'
          break
        case 'NOT_OWNER':
          translatedReason = 'no longer the owner'
          break
        default:
          translatedReason = 'private sale'
          break
      }
      gtmData.ownership_change_form.ownership_change_reason = translatedReason
      gtmData.ownership_change_form.event_label = translatedReason
    }
    this.send(gtmData)
  }

  sendContactUsData(step: number, formName?: string, dealer?: DealerDTO): void {
    const gtmData: GTMEventData = {
      event: this.gaEvent,
      event_name: 'contact_form_checkout',
      contact_form_checkout: {
        form_step: step,
        event_category: 'Contact Form Checkout',
        event_action: 'Step ' + String(step),
        event_label: ''
      }
    }
    if (formName != null) {
      gtmData.contact_form_checkout.form_name = formName
    }
    if (dealer != null) {
      gtmData.contact_form_checkout.dealer_info = this.getDealerInfo(dealer)
    }

    this.send(gtmData)
  }

  getDealerInfo(dealer: DealerDTO): string {
    return (
      String(dealer.efitId) +
      ', ' +
      String(dealer.name) +
      ', ' +
      String(dealer.subname)
    )
  }
}
