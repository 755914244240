<div
  data-cy="first-registration-form"
  class="cpt-modal EditFirstRegistrationComponent-revolution-dialog relative dialog-content-p-0 mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="Edit first registration"
      key="customerPortal.customer-portal.edit-firstRegistration.title"
      tags="customer-portal, 3.1"
    ></T>
  </h2>

  <mat-dialog-content class="firstRegistration-dialog">
    <form [formGroup]="registrationForm" class="mt-2.5">
      <div class="my-2.5 flex justify-between w-full">
        <mat-form-field class="date" class="w-full">
          <!-- prettier-ignore -->
          <input
          data-cy="first-registration-form-input"
            matInput
            (click)="picker.open()"
            readonly
            [max]="now"
            [matDatepicker]="picker"
            placeholder="{{ 'First registration' | translate:{ _key: 'customerPortal.customer-portal.edit-firstRegistration.label', _tags: 'customer-portal, 3.1'} }}"
            formControlName="firstRegistration"
          />
          <mat-datepicker-toggle
            class="calendar-size"
            matPrefix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker
            [calendarHeaderComponent]="customHeader"
            #picker
            startView="multi-year"
            (yearSelected)="chosenYearHandler($event)"
            (monthSelected)="chosenMonthHandler($event, picker)"
            (opened)="addScrolling(picker)"
          ></mat-datepicker>
          <span (click)="picker.open()" matSuffix>
            <mat-icon class="primary" svgIcon="create"></mat-icon>
          </span>
          <mat-error
            *ngIf="registrationForm.controls.firstRegistration.invalid"
          >
            {{
              transifexService.getErrorTranslation(
                registrationForm.controls.firstRegistration,
                'customerPortal.customer-portal.add-new-vehicle.error.'
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <mat-divider class="mt-0"></mat-divider>

  <mat-dialog-actions>
    <button class="dialog-close-button" mat-dialog-close>
      <mat-icon svgIcon="close"></mat-icon>
    </button>

    <button
      data-cy="first-registration-form-submit"
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      (click)="update()"
      cdkFocusInitial
      [disabled]="loading || !registrationForm.valid"
    >
      <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>

      <T
        str="Update"
        key="customerPortal.customer-portal.general.actions.update"
        tags="customer-portal, 3.1"
      ></T>
    </button>
  </mat-dialog-actions>
</div>
