<div
  class="cpt-modal app-confirmation-question-popup ConfirmationQuestionPopupComponent-revolution-dialog relative dialog-content-p-0 mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    {{ translatedTitle }}
  </h2>

  <mat-dialog-content class="mat-typography" *ngIf="!data?.useInnerHtml">
    <div class="flex gap-2 items-center">
      <mat-spinner [diameter]="50" *ngIf="showTextSpinner"></mat-spinner>
      <div class="flex-1">
        {{ translatedText }}
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-content
    class="mat-typography"
    *ngIf="data?.useInnerHtml"
    [innerHTML]="translatedText"
  >
  </mat-dialog-content>

  <mat-divider></mat-divider>
  <mat-dialog-actions>
    <button class="dialog-close-button" (click)="onButtonClicked(false)">
      <mat-icon svgIcon="close"></mat-icon>
    </button>

    <button
      *ngIf="data.cancel"
      class="back revolution btn-dialog"
      mat-button
      mat-stroked-button
      color="secondary"
      mat-dialog-close
      (click)="onButtonClicked(false)"
    >
      {{ translatedCancel }}
    </button>

    <button
      *ngIf="data.save"
      data-cy="confirmation-question-submit"
      mat-button
      mat-flat-button
      [color]="saveButtonColor"
      class="revolution btn-dialog"
      cdkFocusInitial
      (click)="onButtonClicked(true)"
      [disabled]="saving"
    >
      <mat-spinner [diameter]="16" *ngIf="saving"></mat-spinner>
      {{ translatedSave }}
    </button>
  </mat-dialog-actions>
</div>
