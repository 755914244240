import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { InstantErrorStateMatcherService } from '../../../services/instant-error-state-matcher.service'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from '../../../services/shared.service'
import {
  VehicleDTOExtended,
  VehiclesService
} from '../../../services/vehicles.service'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'

@Component({
  selector: 'customer-portal-app-edit-nickname',
  templateUrl: './edit-nickname.component.html'
})
export class EditNicknameComponent implements OnInit {
  nicknameForm = new FormGroup({
    nickname: new FormControl<string | null>(null, [
      Validators.maxLength(30),
      Validators.required
    ])
  })

  saving = false
  private readonly vehicleUpdated: VehicleDTOExtended
  matcher = new InstantErrorStateMatcherService()

  constructor(
    private readonly dialogRef: MatDialogRef<EditNicknameComponent>,
    @Inject(MAT_DIALOG_DATA) public vehicle: VehicleDTOExtended,
    private readonly vehiclesService: VehiclesService,
    private readonly logger: NGXLogger,
    public sharedService: SharedService,
    public transifexService: TransifexService
  ) {
    this.vehicleUpdated = Object.assign({}, vehicle)
  }

  ngOnInit(): void {
    this.nicknameForm.get('nickname').setValue(this.vehicle.nickname)
  }

  updateNickname(): void {
    this.saving = true
    const params = {
      vin: this.vehicleUpdated.vin,
      nickname: this.nicknameForm.get('nickname').value
    }
    this.vehiclesService.addNickname(params).subscribe(
      value => {
        this.saving = false
        if (value?.nickname != null) {
          this.dialogRef.close({ success: true, value: value.nickname })
        }
      },
      error => {
        this.saving = false
        this.logger.debug(error)
        this.dialogRef.close({ success: false })
      }
    )
  }
}
