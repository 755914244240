import { Component, Input, OnInit } from '@angular/core'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import * as moment from 'moment'
import { SharedService } from '../../../services/shared.service'

@Component({
  selector: 'customer-portal-app-vehicle-warning-indicator',
  templateUrl: './vehicle-warning-indicator.component.html'
})
export class VehicleWarningIndicatorComponent implements OnInit {
  @Input() notification
  public type: string
  public calc
  public expirationDaysCritical

  currencyLocale: string
  maxMileage

  constructor(
    public sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.currencyLocale = this.remoteConfigService.get('currencyLocale')
  }

  ngOnInit(): void {
    if (this.notification != null) {
      switch (this.notification.scope) {
        case 'LOAN':
          this.expirationDaysCritical = this.remoteConfigService.get(
            'multilease.leasingExpirationDays.critical'
          )
          this.type = 'leasing'
          break
        case 'SERVICE_PACKAGE':
          this.expirationDaysCritical = this.remoteConfigService.get(
            'servicePackage.expirationDays.critical'
          )
          this.type = 'servicepackage'
          break
        case 'WARRANTY':
          this.expirationDaysCritical = this.remoteConfigService.get(
            'warranty.expirationDays.critical'
          )
          this.type = 'warrantystandard'
          break
        case 'EXTENDED_WARRANTY':
          this.expirationDaysCritical = this.remoteConfigService.get(
            'warranty.expirationDays.critical'
          )

          this.type = 'warrantyextended'
          break
        case 'TIRES_WARRANTY':
          this.expirationDaysCritical = this.remoteConfigService.get(
            'warranty.expirationDays.critical'
          )
          this.type = 'warrantytire'
          break
        case 'TIRES_HEALTH':
          this.type = 'warrantytire'
          break
        case 'RECALL':
          this.type = 'recall'
          break
      }

      if (this.notification?.data?.[0] != null) {
        if (this.notification.data[0].key === 'endDate') {
          this.calculation(this.notification.data[0].value)
        }

        if (this.notification.data[0].key === 'mileage') {
          this.maxMileage = this.notification.data[0].value
        }
      }
    }
  }

  styleBlock(): string {
    let style =
      'w-[14px] h-[14px] min-w-[14px] min-h-[14px] bg-mat-ef-green-secondary-500'
    if (this.notification.type === 'WARN') {
      style =
        'w-[14px] h-[14px] min-w-[14px] min-h-[14px] bg-mat-ef-yellow-secondary-500'
    }
    if (this.notification.type === 'ERR') {
      style =
        'w-[14px] h-[14px] min-w-[14px] min-h-[14px] bg-mat-ef-red-secondary-500'
    }
    return style
  }

  calculation(date): void {
    const lastDay = new Date(date)
    const currentDay = new Date()

    const wTermDate = moment(date)
    const now = moment()
    this.calc = {}
    this.calc.tillExpires = Math.round(wTermDate.diff(now, 'days', true))
    this.calc.years = lastDay.getFullYear() - currentDay.getFullYear()
    this.calc.months = this.sharedService.monthDiff(currentDay, lastDay)
  }
}
