<div class="container py-14 px-4 md:px-28">
  <div class="head-title">
    {{ 'my-vehicles' | sharedTranslate }}
  </div>

  <div class="flex flex-col gap-2 mt-14">
    <button
    data-cy="add-new-vehicle-button"
    (click)="
      addNewVehicle();
      sendContentModulesCTAData(
        'customerPortal.customer-portal.add-new-vehicle.title'
      )
    "
    mat-stroked-button
    class="text-mat-ef-revolution-secondary-500 border-2 border-mat-ef-revolution-secondary-500 text-base h-12 w-full md:w-fit"
  >
    <mat-icon class="mr-2" svgIcon="add"></mat-icon>
    <T
      str="Add vehicle"
      key="customerPortal.customer-portal.add-new-vehicle.title"
      tags="customer-portal, 3.1"
    ></T>
  </button>
    <div class="have-no-card mt-0">
      <div class="text-mat-ef-revolution-supergrey-900">
        <mat-icon
          class="min-w-[56px] min-h-[56px]"
          svgIcon="car_off"
        ></mat-icon>
      </div>
      <div class="text-base font-efSans-primary">
        <T
          str="You have no vehicles"
          key="customerPortal.customer-portal.vehicle.no-vehicles-added"
          tags="customer-portal, 3.1"
        ></T>
      </div>
    </div>
  </div>
</div>
