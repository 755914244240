import { Component, Input, type OnDestroy, type OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute } from '@angular/router'
import {
  CptGoogleTagmanagerService,
  type GTMEventData,
  MatomoIntegrationService,
  ResizeService, type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'
import { NgxGlideComponent } from 'ngx-glide'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from '../../../services/shared.service'
import { DataService } from '../../../services/data.service'
import { DocumentsService } from '../../../services/documents.service'
import {
  type VehicleGalleryDTO,
  GalleryService
} from '../../../services/gallery.service'
import { LocalStorageService } from '../../../services/local-storage.service'
import { type DocumentType } from '../../../services/vehicle-documents.service'
import { VehicleDTOExtended } from '../../../services/vehicles.service'
import { type cptMenuAction } from '../../basic/cpt-menu/cpt-menu.component'
import { RevolutionVehicleDocumentDeleteConfirmationPopupComponent } from '../vehicle-documents/vehicle-document-delete-confirmation-popup/vehicle-document-delete-confirmation-popup.component'
import { RevolutionVehicleDocumentEditPopupComponent } from '../vehicle-documents/vehicle-document-edit-popup/vehicle-document-edit-popup.component'
import { RevolutionVehicleDocumentUploadPopupComponent } from '../vehicle-documents/vehicle-document-upload-popup/vehicle-document-upload-popup.component'
import { RevolutionImageSliderComponent } from './image-slider-popup/image-slider.component'

@Component({
  selector: 'customer-portal-app-vehicle-images',
  templateUrl: './vehicle-images.component.html'
})
export class VehicleImagesComponent implements OnInit, OnDestroy {
  vehicleImages: VehicleGalleryDTO[] = []
  allGallery: VehicleGalleryDTO[] = []
  selectedDocumentType: string = null
  imageTypes: DocumentType[]
  @Input() vehicle: VehicleDTOExtended
  vehicleDetailed: VehicleDTOExtended
  loadingGalleries: boolean
  userVehicles: VehicleDTOExtended[]
  loading: boolean
  public screenSize: SCREEN_SIZE
  @ViewChild('ngxGlide') ngxGlide!: NgxGlideComponent

  documentSelectFormGroup = new FormGroup({
    documentType: new FormControl<DocumentType | null>(null)
  })

  sub = {
    onGalleriesLoaded: null,
    onGallerysSalesLoaded: null,
    onResize: null,
    onVehicleProfilePictureChanged: null
  }

  constructor (
    private readonly dialog: MatDialog,
    private readonly galleryService: GalleryService,
    private readonly documentsService: DocumentsService,
    private readonly route: ActivatedRoute,
    private readonly dataService: DataService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public sharedService: SharedService,
    private readonly logger: NGXLogger,
    public resizeService: ResizeService,
    private readonly matomoIntegrationService: MatomoIntegrationService,
    private readonly localStorage: LocalStorageService
  ) {
    this.selectedDocumentType = null

    this.dataService.loading.galleries.subscribe(loading => {
      this.loadingGalleries = loading
    })

    this.setPerView()
  }

  sendContentModulesMiniCTAData (contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Mini CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'Mini CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }

  sendContentModulesSliderData (
    side: string,
    contentModuleLabel: string,
    galleryName: string
  ): void {
    this.cptGtmService.sendContentModulesData(
      'Gallery',
      side,
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Gallery|(${galleryName})`,
      side
    )
  }

  setPerView (size?): void {
    const previous = this.screenSize
    this.screenSize = size != null ? size : this.resizeService.screenSize

    if (previous !== this.screenSize) {
      this.updateItems()
    }
  }

  updateItems (): void {
    setTimeout(() => {
      if (this.ngxGlide != null) {
        try {
          this.ngxGlide.recreate()
        } catch (error) {
          // no need to log
        }
      }
    })
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  ngOnInit (): void {
    this.getImageTypes()
    this.getUserVehicles()

    this.sub.onGalleriesLoaded = this.dataService.onGalleriesLoaded.subscribe(
      images => {
        this.allGallery = this.vehicleImages = images
        this.updateItems()
      }
    )

    this.sub.onVehicleProfilePictureChanged =
      this.galleryService.onVehicleProfilePictureChanged.subscribe(
        profilePicture => {
          // refresh gallery
          this.getDocuments()
        }
      )

    if (this.selectedDocumentType == null) {
      this.documentSelectFormGroup
        .get('documentType')
        .setValue(this.imageTypes[0])
    } else {
      const docType = this.imageTypes.find(
        ({ value }) => value === this.selectedDocumentType
      )
      this.documentSelectFormGroup.get('documentType').setValue(docType)
    }

    this.documentSelectFormGroup
      .get('documentType')
      .valueChanges.subscribe((value: DocumentType) => {
        this.onDocumentTypeChange(value)
      })
  }

  getImageTypes (): void {
    this.galleryService.getImageTypes().subscribe(types => {
      this.imageTypes = types
    })
  }

  getUserVehicles (): void {
    this.dataService.onUserVehiclesLoaded.subscribe(
      (allVehicles: VehicleDTOExtended[]) => {
        this.loading = false
        if (allVehicles?.length > 0) {
          this.userVehicles = allVehicles
        }
      }
    )
  }

  getDocuments (): void {
    this.allGallery = []
    this.vehicleImages = []
    if (this.vehicle != null) {
      this.loadingGalleries = true

      this.galleryService
        .getGalleries(this.vehicle?.vin)
        .subscribe(response => {
          this.allGallery = this.vehicleImages = response
          this.dataService.galleriesLoaded(response)
          this.galleryService.galleriesLoaded(response)
          void this.localStorage.setGalleries(this.vehicle?.vin, response)
          this.loadingGalleries = false
        })
    }
  }

  onDocumentTypeChange (type: DocumentType): void {
    this.selectedDocumentType = type.value
    this.vehicleImages = this.allGallery?.filter(gallery => {
      let type = gallery?.type
      if (gallery?.type === 'VEHICLE_PICTURE') {
        type = 'VEHICLE_IMAGE'
      }
      return this.selectedDocumentType != null
        ? type === this.selectedDocumentType
        : gallery
    })
    this.updateItems()
  }

  isImage (d: VehicleGalleryDTO): boolean {
    // Image has publicUrl for preview
    const publicUrl = d.publicUrl != null
    return publicUrl
  }

  sendEventData (
    eventType: 'upload' | 'edit' | 'download' | 'delete' | 'burger',
    doc?: VehicleGalleryDTO
  ): void {
    const gtmDataValues = this.getGtmData()

    if (eventType === 'upload') {
      const gtmData: GTMEventData = {
        event: 'gaEvent2',
        event_name: 'vehicledocuments_add',
        vehicledocuments_add: {
          ...gtmDataValues,
          ...{
            event_action: '+ Add',
            event_category: 'Vehicle documents',
            event_label: 'Opened'
          }
        }
      }
      this.cptGtmService.send(gtmData)
    }
    if (eventType === 'edit') {
      const gtmData: GTMEventData = {
        event: 'gaEvent2',
        event_name: 'vehicledocuments_burger',
        vehicledocuments_burger: {
          ...gtmDataValues,
          ...{
            event_action: 'Edit',
            event_category: 'Vehicle documents',
            event_label: doc.type + ' ' + eventType
          }
        }
      }
      this.cptGtmService.send(gtmData)
    }
    if (eventType === 'delete') {
      const gtmData: GTMEventData = {
        event: 'gaEvent2',
        event_name: 'vehicledocuments_burger',
        vehicledocuments_burger: {
          ...gtmDataValues,
          ...{
            event_action: 'Delete',
            event_category: 'Vehicle documents',
            event_label: doc.type + ' ' + eventType
          }
        }
      }
      this.cptGtmService.send(gtmData)
    }
    if (eventType === 'download') {
      const gtmData: GTMEventData = {
        event: 'gaEvent2',
        event_name: 'vehicledocuments_download',
        vehicledocuments_download: {
          ...gtmDataValues,
          ...{
            event_action: 'Document download',
            event_category: 'Vehicle documents',
            event_label: doc.type + ' ' + eventType
          }
        }
      }
      this.cptGtmService.send(gtmData)
    }
    if (eventType === 'burger') {
      const gtmData: GTMEventData = {
        event: 'gaEvent2',
        event_name: 'vehicledocuments_burger',
        vehicledocuments_burger: {
          ...gtmDataValues,
          ...{
            event_action: 'Burger',
            event_category: 'Vehicle documents',
            event_label: doc.type + ' ' + eventType
          }
        }
      }
      this.cptGtmService.send(gtmData)
    }

    if (eventType !== 'burger') {
      // matomo
      this.matomoIntegrationService.trackEvent(
        'Gallery',
        eventType + ' click',
        this.matomoIntegrationService.formatDocumentData(doc)
      )
    }
  }

  getGtmData (): {
    co2emission: string
    colour: string
    commissioning: string
    engine_performance: string
    fuel_type: string
    kilometer_reading: string
    product_id: string
    product_name: string
    transmission_type: string
    dealer_info: string
  } {
    return {
      co2emission:
        this.vehicleDetailed?.details?.co2Emission != null
          ? this.vehicleDetailed?.details.co2Emission.toString()
          : null,
      colour:
        this.vehicleDetailed?.details?.exteriorColor != null
          ? this.vehicleDetailed?.details.exteriorColor
          : null,
      commissioning: this.vehicleDetailed?.firstRegistration,
      engine_performance:
        this.vehicleDetailed?.details?.enginePower != null
          ? this.vehicleDetailed?.details.enginePower.toString()
          : null,
      fuel_type:
        this.vehicleDetailed?.details?.fuelType != null
          ? this.vehicleDetailed?.details.fuelType.toString()
          : null,
      kilometer_reading:
        this.vehicleDetailed?.currentMileage != null
          ? this.vehicleDetailed?.currentMileage.toString()
          : null,
      product_id: this.vehicleDetailed?.model,
      product_name: this.vehicleDetailed?.brand,
      transmission_type:
        this.vehicleDetailed?.details?.transmissionType != null
          ? this.vehicleDetailed?.details.transmissionType.toString()
          : null,
      dealer_info:
        this.vehicle.dealers.salesDealer != null
          ? this.vehicle.dealers.salesDealer.name +
            ' ' +
            String(this.vehicle.dealers.salesDealer.efitId)
          : null
    }
  }

  onUploadImageClicked (): void {
    this.sendEventData('upload')

    const dialogRef = this.dialog.open(
      RevolutionVehicleDocumentUploadPopupComponent,
      {
        data: {
          vehicle: this.vehicle,
          type: 'image'
        },
        panelClass: 'mat-dialog-cpt'
      }
    )

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== false) {
        this.getDocuments()
      }
    })
  }

  onDeleteDocumentClicked (doc: VehicleGalleryDTO): void {
    // prevent delete if its profile picture
    if (doc.isProfilePicture === true) {
      this.sharedService.openConfirmationPopup(
        'shared.error',
        'customerPortal.customer-portal.vehicle-profile-picture.cannot-delete'
      )
      return
    }

    const dialogRef = this.dialog.open(
      RevolutionVehicleDocumentDeleteConfirmationPopupComponent,
      {
        data: {
          document: doc,
          vehicle: this.vehicle
        },
        panelClass: 'mat-dialog-cpt'
      }
    )
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== false) {
        this.getDocuments()
      }
    })
  }

  onEditDocumentClicked (doc: VehicleGalleryDTO): void {
    const dialogRef = this.dialog.open(
      RevolutionVehicleDocumentEditPopupComponent,
      {
        data: {
          document: doc,
          vehicle: this.vehicle
        },
        panelClass: 'mat-dialog-cpt'
      }
    )
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== false) {
        this.getDocuments()
      }
    })
  }

  downloadFile (file: VehicleGalleryDTO, preview?: boolean): void {
    this.sendEventData('download', file)

    this.sharedService.showSnackbar(
      'customerPortal.customer-portal.please-wait-loading-data',
      null,
      0
    )
    this.documentsService.getDocument(file.id).subscribe(
      response => {
        this.sharedService.closeSnackbar()
        const blob: Blob = new Blob([response], {
          type: response.type
        })
        const url = URL.createObjectURL(blob)
        if (preview === true) {
          this.sharedService.openPreviewPopup(url, file.format)
        } else {
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.href = url
          a.download = file.name
          a.target = '_blank'
          a.click()
        }
      },
      () => {
        this.sharedService.showSnackbar(
          'customerPortal.customer-portal.loading-data.error',
          null
        )
      }
    )
  }

  hamburgerClicked (
    doc: VehicleGalleryDTO,
    action: 'upload' | 'edit' | 'download' | 'delete' | 'burger'
  ): void {
    this.sendEventData(action, doc)
  }

  openImage (imageArray: VehicleGalleryDTO[], index): void {
    const dialogRef = this.dialog.open(RevolutionImageSliderComponent, {
      data: { imageArray, index, vehicle: this.vehicle },
      maxWidth: '',
      panelClass: 'mat-dialog-cpt',
      width: '80vw',
      height: '70vh',
      autoFocus: false
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== false) {
        this.getDocuments()
      }
      this.logger.debug(result)
    })
  }

  actionClicked (action: cptMenuAction, file): void {
    switch (action) {
      case 'download':
        this.downloadFile(file)
        break
      case 'edit':
        this.onEditDocumentClicked(file)
        this.sendEventData('edit', file)
        break
      case 'delete':
        this.onDeleteDocumentClicked(file)
        this.sendEventData('delete', file)
        break
    }
  }
}
