<div
  class="text-lg md:text-xl font-bold mb-5 mt-14 text-mat-ef-revolution-primary-500"
>
  <T
    str="Options"
    key="customerPortal.customer-portal.vehicle.options"
    tags="customer-portal, 3.1"
  ></T>
</div>
<mat-divider
  class="relative w-full text-mat-ef-revolution-supergrey-500"
></mat-divider>

<mat-expansion-panel
  (afterExpand)="
    sendContentModulesData(
      true,
      'customerPortal.customer-portal.vehicle.tradein'
    )
  "
  (afterCollapse)="
    sendContentModulesData(
      false,
      'customerPortal.customer-portal.vehicle.tradein'
    )
  "
  #options
>
  <!-- Trade in vehicle -->
  <mat-expansion-panel-header
    class="trade-in p-0 md:px-6"
    *ngIf="hasTradeInVehicle"
    [collapsedHeight]="'auto'"
    [expandedHeight]="'auto'"
  >
    <mat-panel-title>
      <div class="mt-1 mr-1">
        <mat-icon
          class="text-mat-ef-revolution-primary-500"
          [ngClass]="options.expanded ? 'blue-icon' : ''"
          svgIcon="monetization_on"
        ></mat-icon>
      </div>

      <div class="vehicle-expansion-panel-text">
        <div class="w-full overflow-hidden whitespace-nowrap text-ellipsis">
          <div class="flex text-sm sm:text-base font-bold">
            <span>
              <T
                str="Residual value"
                key="customerPortal.customer-portal.vehicle.tradein"
                tags="customer-portal, 3.1"
              ></T
            ></span>
          </div>
        </div>
        <div class="text-sm text-efSans">
          <div>
            <T
              str="Please enter the current mileage to calculate the current market value:"
              key="customerPortal.customer-portal.vehicle.tradein.description"
              tags="customer-portal, 3.1"
            ></T>
          </div>
        </div>
      </div>
    </mat-panel-title>
    <!-- <mat-panel-description class="trade-in-mat-panel-description">

        </mat-panel-description> -->
  </mat-expansion-panel-header>

  <mat-divider
    class="-mx-6 md:m-0 relative text-mat-ef-revolution-supergrey-500"
  ></mat-divider>
  <div class="-mx-6 md:m-0">
    <div class="ml-0 md:ml-10 mt-2.5">
      <form
        class="mt-5 grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-5"
        [formGroup]="tradeInForm"
      >
        <mat-form-field appearance="legacy">
          <mat-label>
            <T
              str="Current Mileage"
              key="customerPortal.customer-portal.vehicle.currentMileage"
              tags="customer-portal, 3.1"
            ></T
          ></mat-label>
          <mat-icon matPrefix svgIcon="mileage"></mat-icon>
          <input
            type="number"
            pattern="[0-9]+"
            formControlName="mileage"
            matInput
            [errorStateMatcher]="matcher"
          />

          <mat-error *ngIf="tradeInForm.controls.mileage.invalid">
            {{
              transifexService.getErrorTranslation(
                tradeInForm.controls.mileage,
                'customerPortal.customer-portal.name-salutation.error.',
                {
                  errKey: {
                    pattern: 'only-numbers-allowed',
                    maxlength: 'maximum-length-exceeded',
                    maxLength: 'maximum-length-exceeded'
                  }
                }
              )
            }}
          </mat-error>
        </mat-form-field>
        <button
          [disabled]="
            !tradeInForm.valid ||
            !tradeInForm.controls.mileage.value ||
            savingTradeIn
          "
          mat-button
          mat-stroked-button
          class="revolution"
          color="secondary"
          (click)="submitTradeIn()"
        >
          <mat-spinner
            class="inline-block mr-2.5"
            [diameter]="16"
            *ngIf="savingTradeIn"
          ></mat-spinner>
          <T
            str="Calculate"
            key="customerPortal.customer-portal.vehicle.tradein.request"
            tags="customer-portal, 3.1"
          ></T>
        </button>
      </form>
    </div>
  </div>
</mat-expansion-panel>

<mat-divider
  class="relative w-full text-mat-ef-revolution-supergrey-500"
></mat-divider>
