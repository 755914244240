import { Component, Inject } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from '../../../../services/shared.service'
import { VehicleDocumentsService } from '../../../../services/vehicle-documents.service'
import { GalleryService } from '../../../../services/gallery.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'

interface DialogData {
  document
  vehicle?: VehicleDTOExtended
}

@Component({
  selector:
    'customer-portal-app-revolution-vehicle-document-delete-confirmation-popup',
  templateUrl: './vehicle-document-delete-confirmation-popup.component.html'
})
export class RevolutionVehicleDocumentDeleteConfirmationPopupComponent {
  disabled = false
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly vehicleDocumentsService: VehicleDocumentsService,
    private readonly dialogRef: MatDialogRef<RevolutionVehicleDocumentDeleteConfirmationPopupComponent>,
    private readonly dialog: MatDialog,
    private readonly logger: NGXLogger,
    private readonly sharedService: SharedService,
    private readonly galleryService: GalleryService
  ) {}

  onDeleteConfirmationSubmit(): void {
    // prevent delete if its profile picture
    if (this.data.document.isProfilePicture === true) {
      this.sharedService.openConfirmationPopup(
        'shared.error',
        'customerPortal.customer-portal.vehicle-profile-picture.cannot-delete'
      )
      this.dialogRef.close()
      return
    }

    this.disabled = true
    this.vehicleDocumentsService
      .deleteVehicleDocument(this.data.document.id.toString())
      .subscribe(
        picture => {
          this.dialogRef.close(true)
          this.disabled = false
          this.sharedService.openConfirmationPopup(
            'shared.successful',
            'customerPortal.customer-portal.vehicle.documents-menu.delete-success'
          )
        },
        error => {
          this.logger.log(error)
          this.disabled = false
          this.sharedService.openConfirmationPopup(
            'shared.successful',
            'shared.general-error-message'
          )
        }
      )
  }
}
