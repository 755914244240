<div
  class="text-lg md:text-xl font-bold text-mat-ef-revolution-primary-500"
  *ngIf="!loading"
>
  <T
    str="More details"
    key="customerPortal.customer-portal.vehicle.details.extended"
    tags="customer-portal, 3.1"
  ></T>
</div>
<div *ngIf="loading" class="heading-skeleton">
  <ngx-skeleton-loader
    appearance="line"
    animation="progress"
  ></ngx-skeleton-loader>
  <br />
</div>

<div class="vehicle-extended-details pl-0">
  <div class="vehicle-details">
    <ng-container
      *ngIf="
        !vehicleDetailed?.details?.exteriorColorDisplayName &&
        !vehicleDetailed?.details?.fuelType &&
        !vehicleDetailed?.details?.transmissionType &&
        !vehicleDetailed?.details?.enginePower &&
        !vehicleDetailed?.details?.driveType &&
        !loading
      "
    >
      <div
        class="mt-4 md:mt-14 w-full h-fit px-4 py-10 md:py-0 md:h-80 bg-mat-ef-revolution-grey-200 rounded-xl flex flex-col justify-center items-center"
      >
        <div class="text-mat-ef-revolution-supergrey-900">
          <mat-icon svgIcon="no_sim"></mat-icon>
        </div>
        <div class="text-base font-efSans-primary">
          <T
            str="You currently have no extended details"
            key="customerPortal.customer-portal.no-extended-details"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </div>
    </ng-container>

    <div *ngIf="loading" class="panel-icon">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>

    <div
      *ngIf="
        (vehicleDetailed?.details?.exteriorColorDisplayName ||
          vehicleDetailed?.details?.exteriorColor) &&
        !loading
      "
      class="p-2.5 flex items-center justify-between gap-2.5"
    >
      <div class="icon flex gap-2.5 items-center">
        <mat-icon class="vehicle-extended-icon" svgIcon="palette"> </mat-icon>
        <span
          class="text-sm md:text-base text-efSans text-mat-ef-revolution-grey-two-500"
        >
          <T
            str="Color"
            key="customerPortal.customer-portal.color"
            tags="customer-portal, 3.1"
          ></T
        ></span>
      </div>
      <div
        class="text-mat-ef-revolution-primary-500 text-sm md:text-base text-efSans flex gap-2 items-center"
      >
        {{
          vehicleDetailed?.details?.exteriorColorDisplayName ??
            vehicleDetailed?.details?.exteriorColor
        }}
      </div>
    </div>

    <div *ngIf="loading" class="panel-icon">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>

    <div
      class="p-2.5 flex items-center justify-between gap-2.5"
      *ngIf="
        (vehicleDetailed?.details?.fuelType ||
          vehicleDetailed?.details?.fuelTypeNormalized) &&
        !loading
      "
    >
      <div class="icon flex gap-2.5 items-center">
        <mat-icon class="vehicle-extended-icon" svgIcon="fuel"> </mat-icon>
        <span
          class="text-sm md:text-base text-efSans text-mat-ef-revolution-grey-two-500"
        >
          <T
            str="Fuel"
            key="customerPortal.customer-portal.fuel"
            tags="customer-portal, 3.1"
          ></T
        ></span>
      </div>
      <div
        class="text-mat-ef-revolution-primary-500 text-sm md:text-base text-efSans"
      >
        {{
          vehicleDetailed?.details?.fuelTypeNormalized ??
            vehicleDetailed?.details?.fuelType
        }}
      </div>
    </div>

    <div *ngIf="loading" class="panel-icon">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>

    <div
      class="p-2.5 flex items-center justify-between gap-2.5"
      *ngIf="
        (vehicleDetailed?.details?.transmissionTypeNormalized ||
          vehicleDetailed?.details?.transmissionType) &&
        !loading
      "
    >
      <div class="icon flex gap-2.5 items-center">
        <mat-icon
          class="vehicle-extended-icon"
          svgIcon="transmission"
        ></mat-icon>
        <span
          class="text-sm md:text-base text-efSans text-mat-ef-revolution-grey-two-500"
        >
          <T
            str="Transmission"
            key="customerPortal.customer-portal.transmission"
            tags="customer-portal, 3.1"
          ></T
        ></span>
      </div>
      <div
        class="text-mat-ef-revolution-primary-500 text-sm md:text-base text-efSans"
      >
        {{
          vehicleDetailed?.details?.transmissionTypeNormalized ??
            vehicleDetailed?.details?.transmissionType
        }}
      </div>
    </div>

    <div *ngIf="loading" class="panel-icon">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>

    <div
      *ngIf="!loading && vehicleDetailed?.details?.enginePower"
      class="p-2.5 flex items-center justify-between gap-2.5"
    >
      <div class="icon flex gap-2.5 items-center">
        <mat-icon class="vehicle-extended-icon" svgIcon="power"> </mat-icon>
        <span
          class="text-sm md:text-base text-efSans text-mat-ef-revolution-grey-two-500"
        >
          <T
            str="Performance"
            key="customerPortal.customer-portal.performance"
            tags="customer-portal, 3.1"
          ></T
        ></span>
      </div>
      <div
        class="text-mat-ef-revolution-primary-500 text-sm md:text-base text-efSans"
      >
        {{ vehicleDetailed?.details?.enginePower }}&nbsp;PS
      </div>
    </div>

    <div *ngIf="loading" class="panel-icon">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>

    <div *ngIf="loading" class="panel-icon">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>

    <div
      class="p-2.5 flex items-center justify-between gap-2.5"
      *ngIf="
        (vehicleDetailed?.details?.driveTypeNormalized ||
          vehicleDetailed?.details?.driveType) &&
        !loading
      "
    >
      <div class="icon flex gap-2.5 items-center">
        <mat-icon class="vehicle-extended-icon" svgIcon="wheel_drive">
        </mat-icon>
        <span
          class="text-sm md:text-base text-efSans text-mat-ef-revolution-grey-two-500"
        >
          <T
            str="Motorization"
            key="customerPortal.customer-portal.drive"
            tags="customer-portal, 3.1"
          ></T
        ></span>
      </div>
      <div
        class="text-mat-ef-revolution-primary-500 text-sm md:text-base text-efSans"
      >
        {{
          vehicleDetailed?.details?.driveTypeNormalized ??
            vehicleDetailed?.details?.driveType
        }}
      </div>
    </div>
  </div>

  <ng-container *ngIf="digitalManuals | digitalManuals: vehicleDetailed?.brand">
    <a
      [href]="(digitalManuals | digitalManuals: vehicleDetailed.brand).link"
      target="_blank"
      mat-button
      mat-raised-button
      color="secondary"
      class="revolution w-full mt-10"
      (click)="
        sendContentModulesCTAData(
          'customerPortal.customer-portal.operation-manual'
        )
      "
    >
      <T
        str="Operation manual"
        key="customerPortal.customer-portal.operation-manual"
        tags="customer-portal, 3.1.0"
      ></T>
      <mat-icon svgIcon="arrow_right"></mat-icon>
    </a>
  </ng-container>
</div>
