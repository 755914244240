import { Component, ViewChild } from '@angular/core'
import { MatMenu } from '@angular/material/menu'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import {
  CustomerPortalConfig,
  Link
} from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { SharedService } from '../../../../services/shared.service'

@Component({
  selector: 'customer-portal-mobile-menu',
  templateUrl: './mobile-menu.component.html'
})
export class MobileMenuComponent {
  constructor(
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.mobileMenuLinks =
    this.remoteConfigService.getArrayValues<Link[]>('mobileMenu.links')
  }

  @ViewChild(MatMenu, { static: true }) menu: MatMenu

  mobileMenuLinks

  sendGTMNavigationData(label: string, linkType?: string): void {
    this.cptGtmService.sendNavigationLinkData(
      null,
      'Main Navigation',
      label,
      linkType
    )
  }
}
