import { Directive, Inject, Input, Optional } from '@angular/core'
import { NgControl } from '@angular/forms'
import { DateAdapter } from '@angular/material/core'
import { MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS } from '@angular/material/legacy-core'
import { CptDateAdapter } from '@inside-hub-app/customer-portal-shared'
import { CustomDateFormat, DateDisplay, DateParse } from './custom-date-format'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[datePickerFormat]',
  providers: [
    {
      provide: DateAdapter,
      useClass: CptDateAdapter
    },
    { provide: MAT_DATE_FORMATS, useClass: CustomDateFormat }
  ]
})
export class DatePickerFormatDirective {
  @Input() public configDateParse: DateParse
  @Input() public configDateDisplay: DateDisplay

  @Input()
  set datePickerFormat(format: string) {
    if (this.configDateParse != null) {
      this.matDateFormat.updateDateFormat(
        this.configDateParse,
        this.configDateDisplay
      )
    } else {
      this.matDateFormat.updateDateFormat(
        { dateInput: format },
        { default: format }
      )
    }

    const value = this.ngControl.value
    this.ngControl.valueAccessor?.writeValue(value)
  }

  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: CustomDateFormat,
    @Optional() private readonly ngControl: NgControl
  ) {}
}
