import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from '../../../../services/shared.service'
import { DataService } from '../../../../services/data.service'
import { GalleryService } from '../../../../services/gallery.service'
import { InstantErrorStateMatcherService } from '../../../../services/instant-error-state-matcher.service'
import {
  DocumentType,
  VehicleDocumentsService
} from '../../../../services/vehicle-documents.service'
import {
  CptGoogleTagmanagerService,
  EcondaService
} from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'

import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
interface DialogData {
  vehicle: VehicleDTOExtended
  type: string
}

@Component({
  selector: 'customer-portal-app-revolution-vehicle-document-upload-popup',
  templateUrl: './vehicle-document-upload-popup.component.html'
})
export class RevolutionVehicleDocumentUploadPopupComponent implements OnInit {
  matcher = new InstantErrorStateMatcherService()
  vehicle: VehicleDTOExtended
  propperSize = true
  fileUploaded = false
  preview
  documentTypes: DocumentType[] = []
  uploadableDocumentTypes: DocumentType[]
  vehicleDocument
  selectedFile: File
  selectedFileType = 'image'
  saving = false
  allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/png']
  isAllowedImageType = false
  maxFileNameLength = 40

  documentUploadFormGroup = new FormGroup({
    documentType: new FormControl<DocumentType | null>(
      null,
      Validators.required
    ),
    name: new FormControl<string | null>(null, {
      validators: [
        Validators.required,
        Validators.maxLength(this.maxFileNameLength)
      ],
      updateOn: 'change'
    })
  })

  originalFile: File
  acceptedTypes = 'image'
  vehicleDetailed: VehicleDTOExtended

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly vehicleDocumentsService: VehicleDocumentsService,
    private readonly galleryService: GalleryService,
    private readonly dialogRef: MatDialogRef<RevolutionVehicleDocumentUploadPopupComponent>,
    private readonly dialog: MatDialog,
    private readonly logger: NGXLogger,
    private readonly dataService: DataService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public readonly sharedService: SharedService,
    private readonly econdaService: EcondaService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    pdfDefaultOptions.assetsFolder = 'assets/pdfjs'
    this.vehicleDocument = {
      name: '',
      type: null
    }

    this.documentUploadFormGroup
      .get('documentType')
      .valueChanges.subscribe(value => {
        this.vehicleDocument.type = value.value
        this.acceptedTypes = value.acceptedTypes
        this.selectedFile = null
        this.fileUploaded = false
        this.preview = null
      })
  }

  sendFileUpload (step: string): void {
    this.cptGtmService.sendFileUpload(this.selectedFileType, step)
    this.econdaService.send({
      content: 'Documents',
      Target: ['Upload document', this.selectedFileType, 1, 'd']
    })
  }

  checkAllowedFileType (filename: string): void {
    const split = filename.split('.')
    this.selectedFileType = 'image'
    const extension = split[split.length - 1]
    if (extension.toLowerCase() === 'pdf') {
      this.selectedFileType = extension.toLowerCase()
    }
    const accepted = this.acceptedTypes.split(',')
    this.isAllowedImageType = accepted.includes(this.selectedFileType)
  }

  onFileSelected (event: Event): void {
    this.fileUploaded = true
    const target = event.target as HTMLInputElement
    const file: File = target.files[0]
    const reader: FileReader = new FileReader()
    this.selectedFile = file
    this.checkAllowedFileType(this.selectedFile.name)
    if (
      this.selectedFile.size <
      this.remoteConfigService.get('regEx.imageFile.maxSize10Mb')
    ) {
      reader.onload = e => {
        this.preview = e.target.result // for immediate display
        // this.disabled = false
      }
      reader.readAsDataURL(this.selectedFile)
      this.originalFile = this.selectedFile
      this.vehicleDocument.name = this.removeFileExtension(
        this.selectedFile.name
      )
      this.documentUploadFormGroup.controls.name.setValue(
        this.vehicleDocument.name
      )
      this.documentUploadFormGroup.controls.name.markAsTouched()
      this.propperSize = true
      this.documentUploadFormGroup.updateValueAndValidity()
      this.sendFileUpload('Step 1')
    } else {
      this.propperSize = false
    }
  }

  onNameChanged (): void {
    this.documentUploadFormGroup.get('name').updateValueAndValidity()
  }

  removeFileExtension (filename: string): string {
    return filename.replace(/\.[^/.]+$/, '')
  }

  onDeleteFile (): void {
    this.fileUploaded = false
    this.selectedFile = null
    this.vehicleDocument.name = ''
    this.documentUploadFormGroup.get('name').setValue(this.vehicleDocument.name)
    this.preview = null
  }

  onUploadDocumentFormSubmit (): void {
    this.saving = true
    const vin = this.vehicle.vin
    const type = this.vehicleDocument.type
    const name = this.removeFileExtension(
      this.documentUploadFormGroup.get('name').value
    )
    const mime = this.selectedFile.type
    if (this.data?.type === 'document') {
      this.vehicleDocumentsService
        .saveDocument(vin, type, name, null, '', mime, this.selectedFile)
        .subscribe(
          response => {
            this.saving = false
            this.dialogRef.close(true)
            this.sharedService.openConfirmationPopup(
              'shared.successful',
              'customerPortal.customer-portal.vehicle.documents-upload.success.message'
            )
            // Refresh documents
            this.sendFileUpload('Step 2')
          },
          error => {
            this.saving = false
            this.logger.log(error)
            this.sharedService.openConfirmationPopup(
              'shared.error',
              'customerPortal.customer-portal.vehicle.documents-upload.failure.message'
            )
          }
        )
    } else {
      this.galleryService
        .saveGallery(vin, type, name, null, '', mime, this.selectedFile)
        .subscribe(
          response => {
            this.saving = false
            this.dialogRef.close(true)
            this.sharedService.openConfirmationPopup(
              'shared.successful',
              'customerPortal.customer-portal.vehicle.documents-upload.success.message'
            )
            // Refresh documents
            this.sendFileUpload('Step 2')
          },
          error => {
            this.saving = false
            this.logger.log(error)
            this.sharedService.openConfirmationPopup(
              'shared.error',
              'customerPortal.customer-portal.vehicle.documents-upload.failure.message'
            )
          }
        )
    }
  }

  ngOnInit (): void {
    this.vehicle = this.data.vehicle

    if (this.data?.type === 'document') {
      this.vehicleDocumentsService
        .getDocumentTypes()
        .subscribe(documentTypes => {
          this.documentTypes = documentTypes
          this.uploadableDocumentTypes = this.documentTypes.filter(
            type => !type.readOnly
          )
        })
    } else {
      this.galleryService.getImageTypes().subscribe(documentTypes => {
        this.documentTypes = documentTypes
        this.uploadableDocumentTypes = this.documentTypes.filter(
          type => !type.readOnly
        )
      })
    }
    this.dataService.onVehicleDetailsLoaded.subscribe(vehicleDetailed => {
      this.vehicleDetailed = vehicleDetailed
    })
  }
}
