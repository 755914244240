<!-- 
<ng-container
  *ngTemplateOutlet="
    appService.template('transifexTranslation');
    context: {
      str: day.day,
      prefix: 'customerPortal.customer-portal.dealer-details.days.',
      suffix: '.abbr'
    }
  "
></ng-container> 

  <ng-container
    *ngTemplateOutlet="
      appService.template('transifexTranslation');
      context: {
        str: d?.value?.toLowerCase(),
        key: d?.key
      }
    "
  ></ng-container>

  <ng-container
    *ngTemplateOutlet="
      appService.template('transifexTranslation');
      context: {
        str: element?.type?.toLowerCase(),
        prefix: 'customerPortal.customer-portal.'
      }
    "
  ></ng-container>
-->

<ng-template
  appTemplateKey="transifexTranslation"
  let-prefix="prefix"
  let-suffix="suffix"
  let-str="str"
  let-key="key"
  let-params="params"
  let-noLowerCase="noLowerCase"
>
  <ng-container *ngIf="key || str">
    <ng-container *ngIf="key">
      {{
        transifexService.getTransifexTranslation(
          str ?? transifexService.getTransifexStrFromKey(key),
          transifexService.addTransifexPrefix(key, 'customerPortal.') + key,
          params
        )
      }}
    </ng-container>
    <ng-container *ngIf="!key">
      {{
        transifexService.getTransifexTranslation(
          str,
          transifexService.addTransifexPrefix(prefix, 'customerPortal.') +
            prefix +
            (noLowerCase === true ? str : str.toLowerCase()) +
            (suffix ?? ''),
          params
        )
      }}
    </ng-container>
  </ng-container>
</ng-template>
