<div
  data-cy="edit-address-form"
  class="cpt-modal relative dialog-content-p-0 ef-scrollbar mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="Update address"
      key="customerPortal.customer-portal.details.change.address"
      tags="customer-portal, 3.1"
    ></T>
  </h2>
  <mat-dialog-content class="mat-typography">
    <form class="form" [formGroup]="addressForm" class="grid gap-4">
      <div class="grid grid-cols-3 gap-4">
        <mat-form-field
          appearance="legacy"
          (click)="cleanErrors()"
          class="col-span-2"
        >
          <mat-label>{{ 'address' | sharedTranslate }}</mat-label>
          <input
            data-cy="edit-address-form-address-input"
            matInput
            required
            formControlName="address"
            [errorStateMatcher]="matcher"
          />
          <mat-error
            data-cy="edit-address-form-address-error"
            *ngIf="addressForm.get('address').errors"
          >
            {{
              transifexService.getErrorTranslation(
                addressForm.get('address'),
                'customerPortal.customer-portal.appointment.error.',
                {
                  errKey: {
                    maxlength: 'maximum-length-exceeded',
                    pattern: 'only-numbers-letters-allowed'
                  }
                }
              )
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="legacy"
          (click)="cleanErrors()"
          class="col-span-1"
        >
          <mat-label>
            <T
              str="House number"
              key="customerPortal.customer-portal.details.address.number"
              tags="customer-portal, 3.1"
            ></T
          ></mat-label>
          <input
            data-cy="edit-address-form-address-number-input"
            matInput
            required
            formControlName="addressNumber"
            [errorStateMatcher]="matcher"
          />
          <mat-error
            data-cy="edit-address-form-address-number-error"
            *ngIf="addressForm.get('addressNumber').errors"
          >
            {{
              transifexService.getErrorTranslation(
                addressForm.get('addressNumber'),
                'customerPortal.customer-portal.appointment.error.',
                {
                  errKey: {
                    pattern: 'only-numbers-allowed',
                    maxlength: 'maximum-length-exceeded'
                  }
                }
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="grid gri-cols-1">
        <mat-form-field appearance="legacy" (click)="cleanErrors()">
          <mat-label>{{ 'address-suffix' | sharedTranslate }}</mat-label>
          <input
            data-cy="edit-address-form-address-opt-input"
            matInput
            formControlName="addressOpt"
            [errorStateMatcher]="matcher"
          />
          <mat-error *ngIf="addressForm.get('addressOpt').errors">
            {{
              transifexService.getErrorTranslation(
                addressForm.get('addressOpt'),
                'customerPortal.customer-portal.appointment.error.',
                {
                  errKey: {
                    maxlength: 'maximum-length-exceeded',
                    pattern: 'only-numbers-letters-allowed'
                  }
                }
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="grid grid-cols-3 gap-4">
        <mat-form-field
          appearance="legacy"
          (click)="cleanErrors()"
          class="col-span-1"
        >
          <mat-label>
            {{ 'postcode' | sharedTranslate }}
          </mat-label>
          <input
            data-cy="edit-address-form-zip-input"
            type="number"
            matInput
            required
            formControlName="zip"
            [errorStateMatcher]="matcher"
          />
          <mat-error
            data-cy="edit-address-form-zip-error"
            *ngIf="
              addressForm.get('zip').errors &&
              !addressForm.get('zip').errors.hasOwnProperty('pattern')
            "
          >
            {{
              transifexService.getErrorTranslation(
                addressForm.get('zip'),
                'customerPortal.customer-portal.appointment.error.zip.',
                {
                  num: zip,
                  errKey: {
                    minlength: 'required-length-characters',
                    maxlength: 'required-length-characters',
                    pattern: 'only-numbers-allowed'
                  }
                }
              )
            }}
          </mat-error>
          <mat-error
            data-cy="edit-address-form-zip-error"
            *ngIf="
              addressForm.get('zip').errors &&
              addressForm.get('zip').errors.hasOwnProperty('pattern')
            "
          >
            {{ 'invalid-number' | sharedTranslate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="legacy"
          (click)="cleanErrors()"
          class="col-span-2"
        >
          <mat-label>{{ 'city' | sharedTranslate }}</mat-label>
          <input
            data-cy="edit-address-form-city-input"
            matInput
            required
            formControlName="city"
            [errorStateMatcher]="matcher"
          />
          <mat-error
            data-cy="edit-address-form-city-error"
            *ngIf="addressForm.get('city').errors"
          >
            {{
              transifexService.getErrorTranslation(
                addressForm.get('city'),
                'customerPortal.customer-portal.appointment.error.',
                {
                  errKey: {
                    maxlength: 'maximum-length-exceeded',
                    pattern: 'only-numbers-letters-allowed'
                  }
                }
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
    <span class="mat-error" *ngIf="errorUpdate">
      {{ 'general-error-message' | sharedTranslate }}</span
    >
    <mat-card *ngIf="duplicateUser" class="duplicate-error">
      <mat-icon class="error" svgIcon="error_outline"></mat-icon>
      <!-- prettier-ignore -->
      <p
        [innerHTML]="getTranslation()"
      ></p>
    </mat-card>
    <h2 class="suggestions-heading" *ngIf="showSuggestions || forceSuggestion">
      {{ 'address-suggestion' | sharedTranslate }}
    </h2>
    <span
      class="warning-suggestions"
      *ngIf="showSuggestions && !forceSuggestion"
    >
      {{ 'address-validation-failed' | sharedTranslate }}</span
    >
    <span
      data-cy="edit-address-form-force-save"
      class="warning-suggestions"
      *ngIf="forceSuggestion && !streetNumCorrected"
    >
      <T
        str="Die eingegebene Adresse kann im Moment leider nicht verifiziert werden. Bitte überprüfen Sie Ihre Eingabe. Falls die die Adresse korrekt ist, drücken Sie “Speichern“."
        key="customerPortal.customer-portal.suggestions.force-save"
        tags="customer-portal, 3.1"
      ></T
    ></span>
    <span
      class="warning-suggestions"
      *ngIf="forceSuggestion && streetNumCorrected"
    >
      <T
        str="Die eingegebene Hausnummer scheint ungültig zu sein. Bitte überprüefen Sie Ihre Eingabe. Falls die Hausnummer korrekt ist, drücken Sie “Speichern“."
        key="customerPortal.customer-portal.suggestions.force-save-street"
        tags="customer-portal, 3.1"
      ></T
    ></span>
    <div *ngIf="showSuggestions && !forceSuggestion && !addressHints">
      <div
        class="grid grid-cols-1 md:grid-cols-4 border rounded-md gap-2 px-3 py-3 my-3 items-center"
      >
        <div class="grid col-span-1 md:col-span-3">
          <div class="inline-flex gap-2">
            <div
              [ngClass]="
                addSug
                  ? 'text-mat-ef-revolution-secondary-500'
                  : 'text-mat-ef-revolution-primary-500'
              "
              class="font-efSans font-normal grid box-border"
            >
              {{
                addSug
                  ? addSug
                  : address && address.street
                  ? address.street
                  : addressForm.get('address').value
              }}
            </div>
            <div
              [ngClass]="
                addNumberSug || streetNumCorrected
                  ? 'text-mat-ef-revolution-secondary-500'
                  : 'text-mat-ef-revolution-primary-500'
              "
              class="font-efSans font-normal grid box-border"
            >
              {{
                addNumberSug
                  ? addNumberSug
                  : address && address.streetNumber
                  ? address.streetNumber
                  : addressForm.get('addressNumber').value
              }}
            </div>
          </div>
          <div
            class="address-form-opt"
            *ngIf="(address && address.streetAdditional) || addOptSug"
          >
            <div
              [ngClass]="
                addOptSug
                  ? 'text-mat-ef-revolution-secondary-500'
                  : 'text-mat-ef-revolution-primary-500'
              "
              class="font-efSans font-normal grid box-border"
            >
              {{
                addOptSug
                  ? addOptSug
                  : address && address.streetAdditional
                  ? address.streetAdditional
                  : addressForm.get('addressOpt').value
              }}
            </div>
          </div>
          <div class="inline-flex gap-2">
            <div
              [ngClass]="
                zipSug
                  ? 'text-mat-ef-revolution-secondary-500'
                  : 'text-mat-ef-revolution-primary-500'
              "
              class="font-efSans font-normal grid box-border"
            >
              {{
                zipSug
                  ? zipSug
                  : address && address.zip
                  ? address.zip
                  : addressForm.get('zip').value
              }}
            </div>
            <div
              [ngClass]="
                citySug
                  ? 'text-mat-ef-revolution-secondary-500'
                  : 'text-mat-ef-revolution-primary-500'
              "
              class="font-efSans font-normal grid box-border"
            >
              {{
                citySug
                  ? citySug
                  : address && address.city
                  ? address.city
                  : addressForm.get('city').value
              }}
            </div>
          </div>
        </div>
        <button
          data-cy="edit-address-form-change-fields"
          class="revolution col-span-1 min-w-[40px]"
          mat-button
          mat-flat-button
          color="secondary"
          (click)="changeAddressFields()"
        >
          {{ 'fill' | sharedTranslate }}
        </button>
      </div>
    </div>

    <div *ngIf="showSuggestions && addressHints">
      <div *ngFor="let addHint of addressHints">
        <div
          class="grid grid-cols-1 md:grid-cols-4 border rounded-md gap-2 px-3 py-3 my-3 items-center"
        >
          <div class="grid col-span-1 md:col-span-3">
            <div class="inline-flex gap-2">
              <div
                [ngClass]="
                  addSug || existsInDiff(addHint.diff, 'street')
                    ? 'text-mat-ef-revolution-secondary-500'
                    : 'text-mat-ef-revolution-primary-500'
                "
                class="font-efSans font-normal box-border"
              >
                {{ addHint?.street }}
              </div>
              <div
                [ngClass]="
                  addNumberSug ||
                  streetNumCorrected ||
                  existsInDiff(addHint.diff, 'streetNumber')
                    ? 'text-mat-ef-revolution-secondary-500'
                    : 'text-mat-ef-revolution-primary-500'
                "
                class="font-efSans font-normal box-border"
              >
                {{ addHint?.streetNumber }}
              </div>
            </div>
            <div
              class="address-form-opt"
              *ngIf="(address && address.streetAdditional) || addOptSug"
            >
              <div
                [ngClass]="
                  addOptSug || existsInDiff(addHint.diff, 'streetAdditional')
                    ? 'text-mat-ef-revolution-secondary-500'
                    : 'text-mat-ef-revolution-primary-500'
                "
                class="font-efSans font-normal box-border"
              >
                {{ addHint?.streetAdditional }}
              </div>
            </div>
            <div class="inline-flex gap-2">
              <div
                [ngClass]="
                  zipSug || existsInDiff(addHint.diff, 'zip')
                    ? 'text-mat-ef-revolution-secondary-500'
                    : 'text-mat-ef-revolution-primary-500'
                "
                class="font-efSans font-normal box-border"
              >
                {{ addHint?.zip }}
              </div>
              <div
                [ngClass]="
                  citySug || existsInDiff(addHint.diff, 'city')
                    ? 'text-mat-ef-revolution-secondary-500'
                    : 'text-mat-ef-revolution-primary-500'
                "
                class="font-efSans font-normal box-border"
              >
                {{ addHint?.city }}
              </div>
            </div>
          </div>
          <button
            class="revolution col-span-1 min-w-[40px]"
            mat-button
            mat-flat-button
            color="secondary"
            (click)="useAddressHint(addHint)"
          >
            {{ 'fill' | sharedTranslate }}
          </button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-divider class="mt-0"></mat-divider>
  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>

    <button
      data-cy="edit-address-form-submit"
      id="saveButton"
      class="revolution button-save w-full md:w-auto"
      mat-button
      mat-flat-button
      color="secondary"
      (click)="updateAddress()"
      [disabled]="disabled || !addressForm.valid"
    >
      <mat-spinner *ngIf="loading" diameter="16"></mat-spinner>
      <span>{{ 'save' | sharedTranslate }}</span>
    </button>
  </mat-dialog-actions>
</div>
