import { Injectable } from '@angular/core'
import * as moment from 'moment'
import { SharedService } from './shared.service'
import { DayTimeDTO, WorkingDays } from './dealers.service'

export interface WorkingHoursResponse {
  open: boolean
  day?: string
  dateToday?: string
  dateToday_noSeconds?: string
}

@Injectable({
  providedIn: 'root'
})
export class WorkingHoursService {
  day = ''
  workingDays: {
    sales: WorkingDays[]
    service: WorkingDays[]
  }

  dateToday: {
    sales: string
    service: string
  }

  constructor(private readonly sharedService: SharedService) {
    this.workingDays = {
      sales: [],
      service: []
    }
    this.dateToday = {
      sales: '',
      service: ''
    }
  }

  calculateIfWorking(
    hours: DayTimeDTO[],
    date: Date,
    type: 'sales' | 'service'
  ): WorkingHoursResponse {
    // remove hours that dont have set day
    hours = this.removeHoursWithoutDay(hours)

    this.workingDays = {
      sales: [],
      service: []
    }

    this.getWorkingDays(hours, type)

    if (
      this.workingDays[type].find(
        s =>
          s.day?.toLowerCase() ===
          date.toLocaleDateString('en', { weekday: 'long' })?.toLowerCase()
      ) != null
    ) {
      const today = this.workingDays[type].find(
        s =>
          s.day?.toLowerCase() ===
          date.toLocaleDateString('en', { weekday: 'long' })?.toLowerCase()
      )
      const now = moment()
      const todayMoment = now.format('YYYY-MM-DD')

      if (today.hours.length === 1) {
        const openTime = today.hours[0].split(' - ')
        const open: string = this.sharedService.stringExists(openTime[0])
          ? openTime[0]
          : null
        const close: string = this.sharedService.stringExists(openTime[1])
          ? openTime[1]
          : null
        const openMoment = moment(todayMoment + ' ' + open)
        const closeMoment = moment(todayMoment + ' ' + close)

        const dateToday =
          today.hours[0] +
          (this.sharedService.stringExists(today.hours[1])
            ? ', ' + today.hours[1]
            : '')
        const response: WorkingHoursResponse = {
          open: now.isBetween(openMoment, closeMoment),
          day: today.day,
          dateToday,
          dateToday_noSeconds: dateToday
        }

        return response
      }

      if (today.hours.length === 2) {
        const openTime = today.hours[0].split(' - ')
        const open1: string = this.sharedService.stringExists(openTime[0])
          ? openTime[0]
          : null
        const close1: string = this.sharedService.stringExists(openTime[1])
          ? openTime[1]
          : null
        const openMoment1 = moment(todayMoment + ' ' + open1)
        const closeMoment1 = moment(todayMoment + ' ' + close1)

        const openTime2 = today.hours[1].split(' - ')
        const open2: string = this.sharedService.stringExists(openTime2[0])
          ? openTime2[0]
          : null
        const close2: string = this.sharedService.stringExists(openTime2[1])
          ? openTime2[1]
          : null
        const openMoment2 = moment(todayMoment + ' ' + open2)
        const closeMoment2 = moment(todayMoment + ' ' + close2)

        const dateToday =
          today.hours[0] +
          (this.sharedService.stringExists(today.hours[1])
            ? ', ' + today.hours[1]
            : '')
        const response: WorkingHoursResponse = {
          open:
            now.isBetween(openMoment1, closeMoment1) ||
            now.isBetween(openMoment2, closeMoment2),
          day: today.day,
          dateToday,
          dateToday_noSeconds: dateToday
        }
        return response
      }
    } else {
      const response: WorkingHoursResponse = {
        open: false
      }
      return response
    }
  }

  getWorkingDays(service: DayTimeDTO[], type: 'sales' | 'service'): void {
    // remove hours that dont have set day
    service = this.removeHoursWithoutDay(service)

    const date = new Date()
    for (const hour of service) {
      if (
        this.workingDays[type].find(
          s => s.day?.toLowerCase() === hour.openDay?.toLowerCase()
        ) != null &&
        hour.openDay?.toLowerCase() ===
          date.toLocaleDateString('en', { weekday: 'long' })?.toLowerCase()
      ) {
        this.workingDays[type]
          .find(s => s.day === hour.openDay?.toLowerCase())
          .hours.push(
            hour.openTime != null && hour.closeTime != null
              ? hour.openTime + ' - ' + hour.closeTime
              : ''
          )
      } else if (
        hour.openDay?.toLowerCase() ===
        date.toLocaleDateString('en', { weekday: 'long' })?.toLowerCase()
      ) {
        const workingDay: WorkingDays = {
          day: hour.openDay?.toLowerCase(),
          hours: [
            hour.openTime != null && hour.closeTime != null
              ? hour.openTime + ' - ' + hour.closeTime
              : ''
          ]
        }
        this.workingDays[type].push(workingDay)
      }
    }
  }

  // copied from component
  calculateIfWorking_v2(date: Date, workingDays: WorkingDays[]): boolean {
    const today = workingDays.find(
      s =>
        s.day?.toLowerCase() ===
        date.toLocaleDateString('en', { weekday: 'long' })?.toLowerCase()
    )
    if (today != null) {
      if (today.hours.length === 1) {
        const openTime = today.hours[0].split(' - ')
        const splitO = this.sharedService.stringExists(openTime[0])
          ? openTime[0].split(':')
          : null
        const splitC = this.sharedService.stringExists(openTime[1])
          ? openTime[1].split(':')
          : null

        if (splitO != null && splitC != null) {
          return this.isOpen(splitO, splitC, date)
        } else {
          return false
        }
      }
      if (today.hours.length === 2) {
        const openTime = today.hours[0].split(' - ')
        const splitO = openTime[0].split(':')
        const splitC = openTime[1].split(':')

        const openTime2 = today.hours[1].split(' - ')
        const splitO2 = openTime2[0].split(':')
        const splitC2 = openTime2[1].split(':')

        if (
          (splitO != null && splitC != null) ||
          (splitO2 != null && splitC2 != null)
        ) {
          return (
            this.isOpen(splitO, splitC, date) ||
            this.isOpen(splitO2, splitC2, date)
          )
        } else {
          return false
        }
      }
    } else {
      return false
    }
  }

  isOpen(open, close, date: Date): boolean {
    if (open != null && close != null) {
      const startDate = new Date(date.getTime())
      startDate.setHours(open[0])
      startDate.setMinutes(open[1])
      startDate.setSeconds(0)

      const endDate = new Date(date.getTime())
      endDate.setHours(close[0])
      endDate.setMinutes(close[1])
      startDate.setSeconds(0)

      return startDate < date && endDate > date
    } else {
      return false
    }
  }

  showHoursSales(
    hours: DayTimeDTO[],
    workingDaysSales
  ): [] | Map<unknown, unknown> {
    // remove hours that dont have set day
    hours = this.removeHoursWithoutDay(hours)

    for (const hour of hours) {
      const hoursSales =
        hour.openTime != null && hour.closeTime != null
          ? hour.openTime + ' - ' + hour.closeTime
          : ''

      if (
        workingDaysSales.find(s => s.day === hour.openDay?.toLowerCase()) !=
        null
      ) {
        workingDaysSales
          .find(s => s.day === hour.openDay?.toLowerCase())
          .hours.push(hoursSales)
      } else {
        const workingDay: WorkingDays = {
          day: hour.openDay?.toLowerCase(),
          hours: [hoursSales]
        }
        workingDaysSales.push(workingDay)
      }
    }
    // workingDaysSalesGrouped
    return this.groupByHours(workingDaysSales)
  }

  showHoursService(
    hours: DayTimeDTO[],
    workingDaysService
  ): [] | Map<unknown, unknown> {
    // remove hours that dont have set day
    hours = this.removeHoursWithoutDay(hours)

    for (const hour of hours) {
      const hoursService =
        hour.openTime != null && hour.closeTime != null
          ? hour.openTime + ' - ' + hour.closeTime
          : ''
      if (
        workingDaysService.find(s => s.day === hour.openDay?.toLowerCase()) !=
        null
      ) {
        workingDaysService
          .find(s => s.day === hour.openDay?.toLowerCase())
          .hours.push(hoursService)
      } else {
        const workingDay: WorkingDays = {
          day: hour.openDay?.toLowerCase(),
          hours: [hoursService]
        }
        workingDaysService.push(workingDay)
      }
    }
    // workingDaysServiceGrouped
    return this.groupByHours(workingDaysService)
  }

  groupByHours(arr): [] | Map<unknown, unknown> {
    if (arr?.length > 0) {
      const memo = new Map()
      arr.forEach(x => {
        const property = x.hours.join('-')
        const value = memo.get(property) != null ? memo.get(property) : []
        value.push(x)
        memo.set(property, value)
      })
      return memo
    }
    return []
  }

  // remove hours that dont have set day
  public removeHoursWithoutDay(hours: DayTimeDTO[]): DayTimeDTO[] {
    return hours.filter(hour => {
      return hour.openDay
    })
  }
}
