<div
  class="max-w-full text-sm font-extralight leading-none flex gap-2.5"
  *ngIf="
    calc &&
    notification.scope !== 'RECALL' &&
    notification.scope !== 'TIRES_HEALTH' &&
    !maxMileage
  "
>
  <div [ngClass]="styleBlock()" class="rounded-xl"></div>

  <div class="flex-1 whitespace-normal leading-[1.1rem]" *ngIf="calc && calc.tillExpires <= 0">
    <ng-container
      *ngTemplateOutlet="
        sharedService.template('transifexTranslation');
        context: {
          str: type,
          prefix: 'customerPortal.customer-portal.warning-indicator-'
        }
      "
    ></ng-container>
    <T
      str="has expired."
      key="customerPortal.customer-portal.warning-indicator-expired"
      tags="customer-portal, 3.1"
    ></T>
  </div>

  <div
    class="flex-1 whitespace-normal leading-[1.1rem]"
    *ngIf="
      calc && calc.tillExpires > 0 && calc.tillExpires <= expirationDaysCritical
    "
  >
    <ng-container
      *ngTemplateOutlet="
        sharedService.template('transifexTranslation');
        context: {
          str: type,
          prefix: 'customerPortal.customer-portal.warning-indicator-'
        }
      "
    ></ng-container>
    <T
      str="expires in {days} days."
      key="customerPortal.customer-portal.warning-indicator-expire"
      tags="customer-portal, 3.1"
      [vars]="{ days: calc.tillExpires }"
    ></T>
  </div>
  <div
    class="flex-1 whitespace-normal leading-[1.1rem]"
    *ngIf="
      calc &&
      calc.tillExpires > expirationDaysCritical &&
      calc.months <= 12 &&
      calc.months !== 1
    "
  >
    <ng-container
      *ngTemplateOutlet="
        sharedService.template('transifexTranslation');
        context: {
          str: type,
          prefix: 'customerPortal.customer-portal.warning-indicator-'
        }
      "
    ></ng-container>
    <T
      str="expires in {months} months."
      key="customerPortal.customer-portal.warning-indicator-expire-months"
      tags="customer-portal, 3.1"
      [vars]="{ months: calc.months }"
    ></T>
  </div>
  <div
    class="flex-1 whitespace-normal leading-[1.1rem]"
    *ngIf="
      calc && calc.tillExpires > expirationDaysCritical && calc.months === 1
    "
  >
    <ng-container
      *ngTemplateOutlet="
        sharedService.template('transifexTranslation');
        context: {
          str: type,
          prefix: 'customerPortal.customer-portal.warning-indicator-'
        }
      "
    ></ng-container>
    <T
      str="läuft in einem Monat aus."
      key="customerPortal.customer-portal.warning-indicator-expire-month"
      tags="customer-portal, 3.1"
      [vars]="{ months: calc.months }"
    ></T>
  </div>
  <div
    class="flex-1 whitespace-normal leading-[1.1rem]"
    *ngIf="
      calc &&
      calc.tillExpires > expirationDaysCritical &&
      calc.months > 12 &&
      calc.years > 1 &&
      calc.months - calc.years * 12 > 0
    "
  >
    <ng-container
      *ngTemplateOutlet="
        sharedService.template('transifexTranslation');
        context: {
          str: type,
          prefix: 'customerPortal.customer-portal.warning-indicator-'
        }
      "
    ></ng-container>
    <T
      str="expires in {years} years and {months} months."
      key="customerPortal.customer-portal.warning-indicator-expire-years"
      tags="customer-portal, 3.1"
      [vars]="{ years: calc.years, months: calc.months - calc.years * 12 }"
    ></T>
  </div>
  <div
    class="flex-1 whitespace-normal leading-[1.1rem]"
    *ngIf="
      calc &&
      calc.tillExpires > expirationDaysCritical &&
      calc.months > 12 &&
      calc.years === 1 &&
      calc.months - calc.years * 12 > 0
    "
  >
    <ng-container
      *ngTemplateOutlet="
        sharedService.template('transifexTranslation');
        context: {
          str: type,
          prefix: 'customerPortal.customer-portal.warning-indicator-'
        }
      "
    ></ng-container>
    <T
      str="expires in {years} year and {months} month(s)."
      key="customerPortal.customer-portal.warning-indicator-expire-year"
      tags="customer-portal, 3.1"
      [vars]="{ years: calc.years, months: calc.months - calc.years * 12 }"
    ></T>
  </div>
  <div
    class="flex-1 whitespace-normal leading-[1.1rem]"
    *ngIf="
      calc &&
      calc.tillExpires > expirationDaysCritical &&
      calc.months > 12 &&
      calc.years > 1 &&
      calc.months - calc.years * 12 === 0
    "
  >
    <ng-container
      *ngTemplateOutlet="
        sharedService.template('transifexTranslation');
        context: {
          str: type,
          prefix: 'customerPortal.customer-portal.warning-indicator-'
        }
      "
    ></ng-container>
    <T
      str="expires in {years} years."
      key="customerPortal.customer-portal.warning-indicator-expire-years-zero-month"
      tags="customer-portal, 3.1"
      [vars]="{ years: calc.years }"
    ></T>
  </div>
  <div
    class="flex-1 whitespace-normal leading-[1.1rem]"
    *ngIf="
      calc &&
      calc.tillExpires > expirationDaysCritical &&
      calc.months > 12 &&
      calc.years === 1 &&
      calc.months - calc.years * 12 === 0
    "
  >
    <ng-container
      *ngTemplateOutlet="
        sharedService.template('transifexTranslation');
        context: {
          str: type,
          prefix: 'customerPortal.customer-portal.warning-indicator-'
        }
      "
    ></ng-container>
    <T
      str="expires in {years} year."
      key="customerPortal.customer-portal.warning-indicator-expire-year-zero-month"
      tags="customer-portal, 3.1"
      [vars]="{ years: calc.years }"
    ></T>
  </div>
</div>

<div
  class="max-w-full text-sm font-extralight leading-none flex gap-2.5"
  *ngIf="notification && notification.scope === 'RECALL'"
>
  <div
    class="w-[14px] h-[14px] min-w-[14px] min-h-[14px] rounded-xl bg-mat-ef-red-secondary-500"
  ></div>
  <div class="flex-1 whitespace-normal leading-[1.1rem]">
    <T
      str="You have an open service promotion."
      key="customerPortal.customer-portal.warning-indicator-recall"
      tags="customer-portal, 3.1"
    ></T>
  </div>
</div>

<div
  class="max-w-full text-sm font-extralight leading-none flex gap-2.5"
  *ngIf="notification && notification.scope === 'TIRES_HEALTH'"
>
  <div [ngClass]="styleBlock()" class="rounded-xl"></div>
  <div class="flex-1 whitespace-normal leading-[1.1rem]">
    <T
      str="The tires are classified as safety-critical."
      key="customerPortal.customer-portal.warning-indicator-tirehealth"
      tags="customer-portal, 3.1"
    ></T>
  </div>
</div>

<div
  class="max-w-full text-sm font-extralight leading-none flex gap-2.5"
  *ngIf="notification && maxMileage"
>
  <div [ngClass]="styleBlock()" class="rounded-xl"></div>
  <div class="flex-1 whitespace-normal leading-[1.1rem]">
    <T
      str="The factory warranty expired on {{ maxMileage }} km."
      key="customerPortal.customer-portal.vehicle.warranty.expired.mileage"
      tags="customer-portal, 3.1"
      [vars]="{ maxMileage: maxMileage | number: '1.0-0':currencyLocale }"
    ></T>
  </div>
</div>
