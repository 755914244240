import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams
} from '@ngx-translate/core'
import { PpTranslationLoader } from './pp-translation-loader'

export class PpMissingTranslationHandler implements MissingTranslationHandler {
  private windowReference = window as any
  handle (params: MissingTranslationHandlerParams) {
    if (
      params.key &&
      params.translateService.currentLoader instanceof PpTranslationLoader
    ) {
      const translationTypes = params.translateService.currentLoader.getTypes()
      if (typeof translationTypes === 'string') {
        const prefix = params.translateService.currentLoader.getPrefix()

        if (prefix && params.key.startsWith(prefix)) {
          params.key = params.key.substr(prefix.length)
        } else if (params.key.startsWith(translationTypes)) {
          params.key = params.key.substr(translationTypes.length + 1)
        }
        const translationKey = params.key + ', type=[' + translationTypes + ']'
        this.logMissingTranslation(translationKey)
        return translationKey
      } else {
        const translationType = translationTypes.find(t =>
          this.keyStartsWithPrefix(params.key, t.prefix, t.type)
        )

        if (
          translationType?.prefix &&
          params.key.startsWith(translationType.prefix)
        ) {
          params.key = params.key.substr(translationType.prefix.length)
        } else if (
          (translationType != null) &&
          params.key.startsWith(translationType.type)
        ) {
          params.key = params.key.substr(translationType.type.length + 1)
        }
        const translationKey = (translationType != null)
          ? params.key + ', type=[' + translationType.type + ']'
          : params.key
        this.logMissingTranslation(translationKey)
        return translationKey
      }
    } else {
      this.logMissingTranslation(params.key)
      return params.key
    }
  }

  keyStartsWithPrefix (key, prefix, type) {
    return prefix ? key.startsWith(prefix) : key.startsWith(type)
  }

  logMissingTranslation (translationKey) {
    // log missing translations to window variable, limited to 100 keys
    if (!this.windowReference.missingTranslations) {
      this.windowReference.missingTranslations = []
    }
    if (
      this.windowReference.missingTranslations.indexOf(translationKey) === -1 &&
      this.windowReference.missingTranslations.length < 100
    ) {
      this.windowReference.missingTranslations.push(translationKey)
    }
  }
}
