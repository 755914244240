import { Component, type OnInit, Input } from '@angular/core'
import { SharedService } from '../../../../services/shared.service'
import { CustomerPortalConfig, type TrackStep } from '@inside-hub-app/customer-portal-config'
import { NGXLogger } from 'ngx-logger'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'

@Component({
  selector: 'customer-portal-tracker-stepper',
  templateUrl: './tracker-stepper.component.html'
})
export class TrackerStepperComponent implements OnInit {
  @Input() data
  @Input() mode: 'appointment' | 'TNT' = 'appointment'
  @Input() prefix: string
  @Input() stepList: TrackStep[] = []

  dateFormat: string

  constructor (
    public readonly sharedService: SharedService,
    public readonly logger: NGXLogger,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>

  ) {
    this.dateFormat = this.remoteConfigService.get('dateFormat.long')
  }

  ngOnInit (): void {
    this.logger.info(this.data)
  }

  getStatuses (d: TrackStep): string[] {
    const length = this.stepList.length
    const stepList = this.stepList.map(i => i.step).splice(d.position - length)
    return stepList
  }

  isActive (d: TrackStep): boolean {
    if (this.data == null) {
      return false
    }

    // check current time passed start date
    if ((this.mode === 'appointment' && this.sharedService.checkAppointment(this.data)) ||
    this.mode !== 'appointment'
    ) {
      const status = d.step
      switch (status) {
        case `${String(status)}`:
          return this.getStatuses(d).includes(this.data.status)
        default:
          return false
      }
    }
    return false
  }

  getProgress (d: TrackStep): number {
    if (this.data == null) {
      return 0
    }

    // check current time passed start date
    if ((this.mode === 'appointment' && this.sharedService.checkAppointment(this.data)) ||
      this.mode !== 'appointment'
    ) {
      const status = d.step

      switch (status) {
        case `${String(status)}`:
          return this.calculateProgress(d, this.data)
        default:
          return 0
      }
    }

    return 0
  }

  calculateProgress (d: TrackStep, data): number {
    if (this.getStatuses(d).includes(data.status)) {
      return 100
    }

    if (this.stepList[d.position - 1]?.step === data.status) {
      return 50
    }

    return 0
  }
}
