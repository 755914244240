<div class="content">
  <div class="content-container">
    <div class="searchagents">
      <div *ngIf="loading">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>

      <ng-container *ngIf="!loading && !hasSearchAgents">
        <div class="have-no-card">
          <div class="text-mat-ef-revolution-supergrey-900">
            <mat-icon svgIcon="no_sim"></mat-icon>
          </div>
          <div class="text-base font-efSans-primary">
            <T
              str="No saved searches"
              key="customerPortal.customer-portal.marketplace.searchagent.none"
              tags="customer-portal, 3.1"
            ></T>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!loading && hasSearchAgents">
        <div *ngFor="let s of searchAgents">
          <mat-divider class="relative w-full"></mat-divider>

          <mat-expansion-panel
            (afterExpand)="
              sendContentModulesData(
                true,
                'customerPortal.customer-portal.navigation.searchagent'
              )
            "
            (afterCollapse)="
              sendContentModulesData(
                false,
                'customerPortal.customer-portal.navigation.searchagent'
              )
            "
            class="p-2.5"
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="flex-col m-0 items-baseline">
                <span
                  class="font-efSans text-base md:text-lg font-bold text-mat-ef-revolution-primary-500"
                >
                  {{ s?.data?.name }}
                </span>
                <span
                  class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                >
                  {{ createAgentSubTitle(s) }}
                </span>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div>
              <div class="grid grid-cols-1 gap-2.5">
                <ng-container
                  *ngIf="s.data.filters && s.data.filters?.length > 0"
                  class="mt-2.5"
                >
                  <div class="flex gap-2.5" *ngFor="let a of s.data.filters">
                    <div
                      class="font-efSans text-base text-mat-ef-revolution-grey-two-500"
                    >
                      {{ a?.label }}
                    </div>
                    <div
                      class="font-efSans text-base text-mat-ef-revolution-primary-500"
                    >
                      {{ a?.value }}
                    </div>
                  </div>
                </ng-container>
              </div>

              <div
                class="flex flex-col lg:flex-row mt-5 gap-2 lg:gap-6 justify-end"
              >
                <button
                  class="revolution"
                  mat-button
                  mat-stroked-button
                  color="warn"
                  (click)="deleteSearch(s)"
                >
                  <mat-icon class="error" svgIcon="delete"></mat-icon>
                  {{ 'clear' | sharedTranslate }}
                </button>
                <button
                  mat-button
                  mat-flat-button
                  class="revolution"
                  color="secondary"
                  (click)="showSearchResults(s)"
                >
                  <mat-icon svgIcon="car_inspect"></mat-icon>
                  <T
                    str="Show results"
                    key="customerPortal.customer-portal.marketplace.searchagent.showresults"
                    tags="customer-portal, 3.1"
                  ></T>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
        <mat-divider class="relative w-full"></mat-divider>
      </ng-container>
    </div>
  </div>
</div>
