<div class="a-link" *ngIf="country === 'ch'">
  <h3 class="privacy-title-text">
    <T
      str="Privacy Settings"
      key="customerPortal.customer-portal.privacy.privacysettings"
      tags="customer-portal, 3.1"
    ></T>
  </h3>
  <!-- prettier-ignore -->
  <p
    *ngIf="lang"
    class="font-efSans text-sm text-justify text-mat-ef-revolution-primary-500"
    [innerHTML]="getTranslation1()"
  ></p>
</div>

<div class="CTP-gdpr a-link block" *ngIf="country === 'de'">
  <div class="privacy-title-text">
    {{ 'data-protection' | sharedTranslate }}
  </div>
  <div
    *ngIf="newGdprNotice"
    class="font-efSans text-base text-justify text-mat-ef-revolution-primary-500"
  >
    <!-- prettier-ignore -->
    <p
      [innerHTML]="getTranslation2()"
    ></p>
    <!-- prettier-ignore -->
    <p
      [innerHTML]="getTranslation3()"
    ></p>
  </div>
  <div
    *ngIf="!newGdprNotice"
    class="font-efSans text-base text-justify text-mat-ef-revolution-primary-500"
  >
    <p>
      {{ 'consent-channels-message' | sharedTranslate }}
    </p>
  </div>
  <p
    *ngIf="!group"
    class="font-efSans text-base text-justify text-mat-ef-revolution-primary-500"
  >
    *
    <T
      str="No consents provided."
      key="customerPortal.customer-portal.communication.noconsents"
      tags="customer-portal, 3.1"
    ></T>
  </p>
  <mat-list *ngIf="group" class="gdpr-list">
    <mat-list-item class="hidden md:block">
      <div
        [ngStyle]="getStyles()"
        class="grid w-full items-center uppercase text-mat-ef-revolution-grey-two-500"
      >
        <h3></h3>
        <h3></h3>
        <h3
          matListItemTitle
          *ngFor="let channel of headingChannels"
          class="font-light font-efSans text-mat-ef-revolution-grey-two-500 flex justify-end"
        >
          <ng-container
            *ngTemplateOutlet="communication; context: { channel: channel }"
          ></ng-container>
        </h3>
      </div>
      <mat-divider></mat-divider>
    </mat-list-item>
    <mat-divider class="block md:hidden relative w-full"></mat-divider>

    <mat-list-item *ngIf="dealers?.length > 0">
      <div
        matListItemTitle
        [ngStyle]="getStyles()"
        class="hidden md:grid w-full items-center"
        *ngFor="let d of dealers"
      >
        <span class="flex flex-col col-span-2">
          <span
            class="font-efSans font-semibold text-mat-ef-revolution-primary-500"
          >
            {{ d.name }}
          </span>
          <span class="font-efSans-primary text-sm">
            {{ d.street }}{{ d.zip ? ', ' + d.zip : '' }} {{ d.city }}
          </span>
        </span>
        <span
          class="grid-list-items list-none"
          *ngFor="let channel of headingChannels"
        >
          <mat-checkbox
            class="h-6 w-6 float-right"
            *ngIf="!checkIfDisabled(d, channel.name)"
            [disabled]="!checkIfExist(d, channel.name)"
            (click)="errorMessage(channel.name)"
            color="primary"
            [checked]="getConsentDealer(d.id, channel.name)"
            (change)="updateConsentValue('dealer', d.id, channel.name, $event)"
          ></mat-checkbox>
        </span>
      </div>
      <div matListItemLine class="grid md:hidden" *ngFor="let d of dealers">
        <span class="flex flex-col">
          <span class="font-semibold font-efSans-primary">
            {{ d.name }}
          </span>
          <span class="font-efSans-primary text-sm">
            {{ d.street }}{{ d.zip ? ', ' + d.zip : '' }} {{ d.city }}
          </span>
        </span>
        <div class="flex flex-col gap-5 my-4">
          <span
            class="grid-list-items flex justify-between items-center"
            [ngClass]="{ hidden: checkIfDisabled(d, channel.name) }"
            *ngFor="let channel of headingChannels"
          >
            <h6 matListItemLine>
              <ng-container *ngIf="!checkIfDisabled(d, channel.name)">
                <ng-container
                  *ngTemplateOutlet="
                    communication;
                    context: { channel: channel }
                  "
                ></ng-container>
              </ng-container>
            </h6>
            <mat-checkbox
              class="h-6 w-6"
              *ngIf="!checkIfDisabled(d, channel.name)"
              [disabled]="!checkIfExist(d, channel.name)"
              (click)="errorMessage(channel.name)"
              color="primary"
              [checked]="getConsentDealer(d.id, channel.name)"
              (change)="
                updateConsentValue('dealer', d.id, channel.name, $event)
              "
            ></mat-checkbox>
          </span>
        </div>
      </div>
      <mat-divider></mat-divider>
    </mat-list-item>

    <mat-list-item *ngIf="group">
      <div
        matListItemTitle
        [ngStyle]="getStyles()"
        class="hidden md:grid 5 w-full items-center"
      >
        <div class="flex flex-col col-span-2">
          <div class="font-semibold font-efSans-primary">
            <T
              str="Emil Frey AG"
              key="customerPortal.customer-portal.communication.group.name"
              tags="customer-portal, 3.1"
            ></T>
          </div>
          <div class="font-efSans-primary text-sm">
            <T
              str="Badenerstrasse 600, 8048 Zürich, Schweiz"
              key="customerPortal.customer-portal.communication.group.street"
              tags="customer-portal, 3.1"
            ></T>
          </div>
        </div>
        <span
          class="grid-list-items list-none"
          *ngFor="let channel of headingChannels"
        >
          <mat-checkbox
            class="h-6 w-6 float-right"
            *ngIf="!checkIfDisabled(group, channel.name)"
            [disabled]="!checkIfExist(group, channel.name)"
            (click)="errorMessage(channel.name)"
            color="primary"
            [checked]="getConsentGroup(group.name, channel.name)"
            (change)="
              updateConsentValue('group', group.id, channel.name, $event)
            "
          >
          </mat-checkbox>
        </span>
      </div>

      <div matListItemLine class="grid md:hidden">
        <div class="flex flex-col">
          <div class="font-semibold font-efSans-primary">
            <T
              str="Emil Frey AG"
              key="customerPortal.customer-portal.communication.group.name"
              tags="customer-portal, 3.1"
            ></T>
          </div>
          <div class="font-efSans-primary text-sm">
            <T
              str="Badenerstrasse 600, 8048 Zürich, Schweiz"
              key="customerPortal.customer-portal.communication.group.street"
              tags="customer-portal, 3.1"
            ></T>
          </div>
        </div>
        <div class="flex flex-col gap-5 my-4">
          <span
            class="grid-list-items flex justify-between items-center"
            [ngClass]="{ hidden: checkIfDisabled(group, channel.name) }"
            *ngFor="let channel of headingChannels"
          >
            <h6 matListItemLine>
              <ng-container *ngIf="!checkIfDisabled(group, channel.name)">
                <ng-container
                  *ngTemplateOutlet="
                    communication;
                    context: { channel: channel }
                  "
                ></ng-container>
              </ng-container>
            </h6>
            <mat-checkbox
              class="h-6 w-6"
              *ngIf="!checkIfDisabled(group, channel.name)"
              [disabled]="!checkIfExist(group, channel.name)"
              (click)="errorMessage(channel.name)"
              color="primary"
              [checked]="getConsentGroup(group.name, channel.name)"
              (change)="
                updateConsentValue('group', group.id, channel.name, $event)
              "
              >rr</mat-checkbox
            >
          </span>
        </div>
      </div>
      <mat-divider></mat-divider>
    </mat-list-item>

    <mat-list-item *ngIf="brands?.length > 0">
      <div
        matListItemTitle
        [ngStyle]="getStyles()"
        class="hidden md:grid w-full items-center"
        *ngFor="let b of brands"
      >
        <span class="flex flex-col col-span-2">
          <span class="font-semibold font-efSans-primary">
            {{ b.name }}
          </span>
          <span class="font-efSans-primary text-sm">
            {{ b.street }}{{ b.zip ? ', ' + b.zip : '' }} {{ b.city }}
          </span>
        </span>
        <span
          class="grid-list-items list-none"
          *ngFor="let channel of headingChannels"
        >
          <mat-checkbox
            class="h-6 w-6 float-right"
            *ngIf="!checkIfDisabled(b, channel.name)"
            [disabled]="!checkIfExist(b, channel.name)"
            (click)="errorMessage(channel.name)"
            color="primary"
            [checked]="getConsentBrand(b.id, channel.name)"
            (change)="updateConsentValue('brand', b.id, channel.name, $event)"
          ></mat-checkbox>
        </span>
      </div>
      <div matListItemLine class="grid md:hidden" *ngFor="let b of brands">
        <span class="flex flex-col">
          <span class="font-semibold font-efSans-primary">
            {{ b.name }}
          </span>
          <span class="font-efSans-primary text-sm">
            {{ b.street }}{{ b.zip ? ', ' + b.zip : '' }} {{ b.city }}
          </span>
        </span>
        <div class="flex flex-col gap-5 my-4">
          <span
            class="grid-list-items flex justify-between items-center"
            [ngClass]="{ hidden: checkIfDisabled(b, channel.name) }"
            *ngFor="let channel of headingChannels"
          >
            <h6 matListItemLine>
              <ng-container *ngIf="!checkIfDisabled(b, channel.name)">
                <ng-container
                  *ngTemplateOutlet="
                    communication;
                    context: { channel: channel }
                  "
                ></ng-container>
              </ng-container>
            </h6>
            <mat-checkbox
              class="h-6 w-6"
              *ngIf="!checkIfDisabled(b, channel.name)"
              [disabled]="!checkIfExist(b, channel.name)"
              (click)="errorMessage(channel.name)"
              color="primary"
              [checked]="getConsentBrand(b.id, channel.name)"
              (change)="updateConsentValue('brand', b.id, channel.name, $event)"
            ></mat-checkbox>
          </span>
        </div>
      </div>
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-list>

  <div *ngIf="newGdprNotice" class="grid gap-3 mt-4">
    <p
      class="font-efSans text-base text-justify text-mat-ef-revolution-primary-500"
    >
      <T
        str="The declaration of consent can be revoked or changed at any time for the future and without giving reasons. Simply send us a message (e.g. with the e-mail you have stored with us)."
        key="customerPortal.customer-portal.communication.gdpr.bottom-notice1"
        tags="customer-portal, 3.1"
      ></T>
    </p>
    <p
      class="font-efSans text-base text-justify text-mat-ef-revolution-primary-500"
    >
      <T
        str="Your personal data will be treated confidentially in accordance with data protection regulations. A profile is created for the purposes mentioned."
        key="customerPortal.customer-portal.communication.gdpr.bottom-notice2"
        tags="customer-portal, 3.1"
      ></T>
    </p>
    <!-- prettier-ignore -->
    <p
      class="font-efSans text-base text-justify text-mat-ef-revolution-primary-500"
      [innerHTML]="getTranslation4()"
    ></p>
  </div>
  <div *ngIf="!newGdprNotice" class="grid gap-3 mt-4">
    <p
      class="font-efSans text-base text-justify text-mat-ef-revolution-primary-500"
    >
      <T
        str="Contact is made for advertising purposes, for invitations to product presentations and test drives, and for market research purposes, including satisfaction surveys. For this purpose, the personal data provided is evaluated and analyzed in order to enable a customer-specific approach and to be able to submit tailor-made offers."
        key="customerPortal.customer-portal.communication.gdpr-notice2"
        tags="customer-portal, 3.1"
      ></T>
    </p>
    <!-- prettier-ignore -->
    <p
      class="font-efSans text-base text-justify text-mat-ef-revolution-primary-500"
      *ngIf="editDisabled"
      [innerHTML]="getTranslation5()"
    >
      <T
      str="gdpr notice ch"
      key="customerPortal.customer-portal.communication.gdpr-notice-ch"
      tags="customer-portal, 3.1"
    ></T>
    </p>
    <p
      class="font-efSans text-base text-justify text-mat-ef-revolution-primary-500"
    >
      <T
        str="Further information on data protection and your rights in this regard can be found in the"
        key="customerPortal.customer-portal.communication.gdpr-notice3"
        tags="customer-portal, 3.1"
      ></T>
      <a href="{{ privacyLink.url }}" target="_blank">
        {{ privacyLink.translation }}</a
      >
    </p>
  </div>
  <div
    class="flex flex-row-reverse max-w-[1000px] mt-2.5"
    *ngIf="group && !editDisabled"
  >
    <button
      mat-button
      mat-flat-button
      color="secondary"
      id="saveButton"
      class="revolution"
      (click)="updateConsents()"
      [disabled]="disabled || updates.length === 0"
    >
      <mat-spinner [diameter]="16" *ngIf="disabled"></mat-spinner
      ><span> {{ 'save' | sharedTranslate }}</span>
    </button>
  </div>
</div>

<ng-template #communication let-channel="channel">
  {{
    {
      str: channel.name,
      prefix: 'customerPortal.customer-portal.communication.'
    } | transifexTranslate
  }}
</ng-template>
