import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
export type VehicleEFWebDataEncryptedDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.VehicleEFWebDataEncryptedDTO

@Injectable({
  providedIn: 'root'
})
export class TradeinService {
  private readonly apiUrl

  constructor(
    private readonly http: HttpClient,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  getTradeInData(
    vin: string,
    mileage: number
  ): Observable<VehicleEFWebDataEncryptedDTO> {
    const body = {
      mileage
    }
    return this.http.post<VehicleEFWebDataEncryptedDTO>(
      this.apiUrl + '/vehicle/ef-web-data/' + vin,
      body
    )
  }
}
