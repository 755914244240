/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from '../../../../services/shared.service'
import { DataService } from '../../../../services/data.service'
import {
  GalleryService,
  VehicleGalleryDTO
} from '../../../../services/gallery.service'
import { ConfirmationQuestionPopupComponent } from '../../../basic/confirmation-question-popup/confirmation-question-popup.component'
import { LocalStorageService } from '../../../../services/local-storage.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { MatomoIntegrationService } from '@inside-hub-app/customer-portal-shared'

@Component({
  selector: 'customer-portal-app-vehicle-profile-picture',
  templateUrl: './vehicle-profile-picture.component.html'
})
export class VehicleProfilePictureComponent {
  @Input() vehicle: VehicleDTOExtended
  @Input() set setPicture (picture) {
    this.picture = picture
  }

  @Input() showAlways = false

  picture: VehicleGalleryDTO

  constructor (
    public sharedService: SharedService,
    private readonly data: DataService,
    private readonly dialog: MatDialog,
    private readonly logger: NGXLogger,
    private readonly galleryService: GalleryService,
    private readonly localStorage: LocalStorageService,
    private readonly matomoIntegrationService: MatomoIntegrationService,
    private readonly transifexTranslationsService: TranslationService
  ) {}

  profilePictureUpdate (): void {
    let text =
      'customerPortal.customer-portal.vehicle-profile-picture.remove.text'
    let matomoAction = 'Delete vehicle profile picture click'
    if (this.picture.isProfilePicture !== true) {
      text = 'customerPortal.customer-portal.vehicle-profile-picture.add.text'
      matomoAction = 'Add vehicle profile picture click'
    }
    // matomo
    this.matomoIntegrationService.trackEvent(
      'Gallery',
      matomoAction,
      this.matomoIntegrationService.formatDocumentData(this.picture)
    )
    const dialogRef = this.dialog.open(ConfirmationQuestionPopupComponent, {
      data: {
        title:
          'customerPortal.customer-portal.vehicle-profile-picture.update.title',
        text,
        cancel: 'customerPortal.customer-portal.general.actions.no',
        save: 'customerPortal.customer-portal.general.actions.yes',
        preventCloseBeforeComplete: true
      },
      panelClass: 'mat-dialog-cpt'
    })
    const sub = dialogRef.componentInstance.confirm.subscribe(() => {
      if (this.picture.isProfilePicture === true) {
        // remove profile picture
        this.removeVehicleProfilePicture(dialogRef)
      } else {
        // add profile picture
        this.addVehicleProfilePicture(dialogRef)
      }
    })
    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe()
    })
  }

  removeVehicleProfilePicture (dialogRef): void {
    this.galleryService.removeVehicleProfilePicture(this.vehicle.vin).subscribe(
      picture => {
        this.galleryService.vehicleProfilePictureChanged({
          vin: this.vehicle.vin,
          publicUrl: picture?.publicUrl != null ? picture.publicUrl : null
        })
        this.updateSuccess(dialogRef)
      },
      error => {
        this.updateFailure(dialogRef, error)
      }
    )
  }

  addVehicleProfilePicture (dialogRef): void {
    const body = {
      pictureId: this.picture.id,
      sourceType: this.picture.documentSource
    }
    this.galleryService
      .addVehicleProfilePicture(this.vehicle.vin, body)
      .subscribe(
        () => {
          this.galleryService.vehicleProfilePictureChanged({
            vin: this.vehicle.vin,
            publicUrl: this.picture.publicUrl
          })
          this.updateSuccess(dialogRef)
        },
        error => {
          this.updateFailure(dialogRef, error)
        }
      )
  }

  updateSuccess (dialogRef): void {
    dialogRef.close()
    this.sharedService.openConfirmationPopup(
      'shared.successful',
      'customerPortal.customer-portal.vehicle-profile-picture.update.success'
    )
  }

  updateFailure (dialogRef, error): void {
    this.logger.error(error)
    dialogRef.close()
    this.sharedService.openConfirmationPopup(
      'shared.error',
      'customerPortal.customer-portal.vehicle-profile-picture.update.failure'
    )
  }

  getTranslation (): string {
    if (this.picture.isProfilePicture === true) {
      return this.transifexTranslationsService.translate(
        'Remove as vehicle profile picture',
        {
          _key: 'customerPortal.customer-portal.vehicle-profile-picture.remove',
          _tags: 'customer-portal, 3.1'
        }
      )
    } else {
      return this.transifexTranslationsService.translate(
        'Make as vehicle profile picture',
        {
          _key: 'customerPortal.customer-portal.vehicle-profile-picture.add',
          _tags: 'customer-portal, 3.1'
        }
      )
    }
  }
}
