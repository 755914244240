<div class="container">
  <div class="message">
    <div *ngIf="snackBarData.translationKey">
      <div *ngIf="isArray">
        <div *ngFor="let key of snackBarData.translationKey">
          {{ getTranslation(key) }}
        </div>
      </div>
      <div *ngIf="!isArray">
        {{ getTranslation(snackBarData.translationKey) }}
      </div>
    </div>

    <div *ngIf="snackBarData.text">{{ snackBarData.text }}</div>
  </div>
  <div *ngIf="snackBarData.action" class="action">
    <button data-cy="snackbar-close-button" mat-button (click)="snackBar.dismiss()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
</div>
