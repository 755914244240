<div class="grid grid-cols-1 lg:grid-cols-10 gap-6">
  <div class="col-span-1 lg:col-span-7">
    <div class="w-full" *ngIf="loadingGalleries">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
        [count]="12"
      ></ngx-skeleton-loader>
    </div>
    <div *ngIf="!loadingGalleries" class="col-span-1 lg:col-span-7 h-full">
      <ng-container *ngIf="!(vehicleImages?.length > 0)">
        <div
          class="w-full h-full bg-mat-ef-revolution-grey-200 rounded-xl flex flex-col justify-center items-center py-4"
        >
          <div class="text-mat-ef-revolution-supergrey-900">
            <mat-icon svgIcon="no_sim"></mat-icon>
          </div>
          <div class="text-base font-efSans-primary">
            <T
              str="You currently have no images"
              key="customerPortal.customer-portal.no-images"
              tags="customer-portal, 3.1"
            ></T>
          </div>
        </div>
      </ng-container>
      <div *ngIf="vehicleImages?.length > 0">
        <div>
          <div class="mb-6">
            <p
              class="font-efSans font-bold text-xl text-mat-ef-revolution-primary-500"
            >
              <T
                str="Photos"
                key="customerPortal.customer-portal.vehicles-view.documents.photos"
                tags="customer-portal, 3.1"
              ></T>
            </p>
          </div>

          <div class="hidden flex-col lg:flex xl:flex-row gap-6">
            <div class="h-full relative">
              <ngx-glide
                #ngxGlide
                class="documents block shadow-vehicleGallery overflow-hidden rounded-lg h-full w-[530px] max-w-[530px]"
                [arrowLeftTemplate]="left"
                [arrowRightTemplate]="right"
              >
                <div
                  class="image-preview relative bg-mat-ef-revolution-grey-500 w-[530px] max-w-full h-96 flex-shrink-0 flex flex-col"
                  *ngFor="let img of vehicleImages; let i = index"
                >
                  <div
                    data-cy="vehicle-image-editable"
                    class="pl-4 pr-2 w-full min-h-[50px] bg-white border border-mat-ef-revolution-grey-500 rounded-t-lg flex justify-between items-center"
                  >
                    <div
                      class="font-efSans text-sm text-mat-ef-revolution-secondary-500 font-bold max-w-[calc(100%_-_50px)] overflow-hidden whitespace-nowrap text-ellipsis"
                    >
                      {{ vehicle?.brand }} {{ vehicle?.model }}
                    </div>
                    <customer-portal-app-cpt-menu
                      *ngIf="img?.type !== 'VEHICLE_PICTURE'"
                      class="w-7 h-7 flex justify-center cursor-pointer"
                      [menuType]="'fileMenu'"
                      (click)="hamburgerClicked(img, 'burger')"
                      (actionClicked)="actionClicked($event, img)"
                    >
                    </customer-portal-app-cpt-menu>
                    <mat-icon
                      class="text-mat-ef-primary-500"
                      *ngIf="img?.type === 'VEHICLE_PICTURE'"
                      svgIcon="ef-logo-icon"
                    ></mat-icon>
                  </div>

                  <div
                    class="w-full max-h-[calc(100%_-_50px)] h-[calc(100%_-_50px)] cursor-pointer relative"
                  >
                    <div class="absolute top-0 right-0 z-10 p-1 w-10 h-10">
                      <customer-portal-app-vehicle-profile-picture
                        [setPicture]="img"
                        [vehicle]="vehicle"
                        [showAlways]="true"
                      ></customer-portal-app-vehicle-profile-picture>
                    </div>
                    <img
                      alt="vehicle img"
                      *ngIf="img?.publicUrl"
                      class="rounded-lg w-full h-full"
                      src="{{ img?.publicUrl }}"
                      (click)="openImage(vehicleImages, i)"
                    />
                  </div>
                </div>
              </ngx-glide>
            </div>

            <div
              class="grid grid-cols-3 xl:grid-cols-1 gap-6 cursor-pointer max-h-full h-full xl:max-w-28 xl:h-28"
            >
              <div
                *ngFor="let img of vehicleImages | slice: 0:3; let i = index"
                class="relative image-preview"
              >
                <div class="absolute top-0 right-0 z-10 p-1 w-8 h-8">
                  <customer-portal-app-vehicle-profile-picture
                    [vehicle]="vehicle"
                    [setPicture]="img"
                  ></customer-portal-app-vehicle-profile-picture>
                </div>

                <img
                  class="rounded-lg max-w-28 h-28 max-h-20 lg:w-48 lg:max-w-48 lg:h-28 lg:max-h-28 bg-mat-ef-revolution-grey-500"
                  [src]="img?.publicUrl"
                  (click)="openImage(vehicleImages, i)"
                  alt="vehicle img"
                />

                <div
                  class="badge absolute inset-y-2/4 inset-x-1/4 h-8 w-24 m-auto rounded-lg flex justify-center items-center"
                  *ngIf="
                    vehicleImages &&
                    (vehicleImages?.length > 3
                      ? i + 1 === 3
                      : i === vehicleImages?.length - 1) &&
                    vehicleImages?.length > 3
                  "
                >
                  <div
                    class="flex justify-center items-center text-white bg-black bg-opacity-50 px-2 gap-1 rounded-lg font-efSans text-xs font-bold"
                  >
                    <mat-icon
                      class="max-w-[14px] min-w-[14px]"
                      svgIcon="collections"
                    ></mat-icon>
                    <div class="font-efSans text-xs font-bold pr-2">
                      {{
                        vehicleImages?.length -
                          vehicleImages?.slice(0, 3)?.length
                      }}
                      <T
                        str="Photos"
                        key="customerPortal.customer-portal.vehicles-view.documents.photos"
                        tags="customer-portal, 3.1"
                      ></T>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- SM -->
          <div class="grid lg:hidden gap-16 cursor-pointer">
            <div
              *ngFor="let img of vehicleImages; let i = index"
              class="image-preview min-h-[420px] max-h-[420px] relative"
            >
              <div class="absolute top-[49px] right-0 z-10 p-1 w-10 h-10">
                <customer-portal-app-vehicle-profile-picture
                  [vehicle]="vehicle"
                  [setPicture]="img"
                  [showAlways]="true"
                ></customer-portal-app-vehicle-profile-picture>
              </div>
              <div
                class="pl-4 pr-2 w-full min-h-[50px] bg-white border border-mat-ef-revolution-grey-500 rounded-t-lg flex justify-between items-center"
              >
                <div
                  class="font-efSans text-sm text-mat-ef-revolution-secondary-500 font-bold max-w-[calc(100%_-_50px)] overflow-hidden whitespace-nowrap text-ellipsis"
                >
                  {{ vehicle?.brand }} {{ vehicle?.model }}
                </div>
                <customer-portal-app-cpt-menu
                  class="w-7 h-7 flex justify-center cursor-pointer"
                  *ngIf="img?.type !== 'VEHICLE_PICTURE'"
                  [menuType]="'fileMenu'"
                  (click)="hamburgerClicked(img, 'burger')"
                  (actionClicked)="actionClicked($event, img)"
                >
                </customer-portal-app-cpt-menu>
                <mat-icon
                  class="text-mat-ef-primary-500"
                  *ngIf="img?.type === 'VEHICLE_PICTURE'"
                  svgIcon="ef-logo-icon"
                ></mat-icon>
              </div>
              <img
                alt="vehicle img"
                class="rounded-b-lg bg-mat-ef-revolution-grey-500"
                (click)="openImage(vehicleImages, i)"
                src="{{ img?.publicUrl }}"
              />
            </div>
          </div>
        </div>

        <div class="documents_car">
          <ng-template #left>
            <div
              class="arrowTemplate mr-6 flex justify-center items-center"
              [ngClass]="{ hidden: vehicleImages.length === 1 }"
              (click)="
                sendContentModulesSliderData(
                  'Left',
                  'customerPortal.customer-portal.gallery',
                  'Vehicle images'
                )
              "
            >
              <mat-icon svgIcon="arrow_left"></mat-icon>
            </div>
          </ng-template>

          <ng-template #right>
            <div
              class="arrowTemplate flex justify-center items-center"
              [ngClass]="{ hidden: vehicleImages.length === 1 }"
              (click)="
                sendContentModulesSliderData(
                  'Right',
                  'customerPortal.customer-portal.gallery',
                  'Vehicle images'
                )
              "
            >
              <mat-icon svgIcon="arrow_right"></mat-icon>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="col-span-1 lg:col-span-3">
    <div
      *ngIf="!loadingGalleries"
      class="rounded-md bg-mat-ef-revolution-grey-500 mt-5 md:mt-11"
    >
      <div class="px-6 py-2 md:py-6">
        <p
          class="font-efSans text-[15px] font-bold text-mat-ef-revolution-primary-500 mb-3"
        >
          <T
            str="Filter"
            key="customerPortal.customer-portal.image-filter"
            tags="customer-portal, 3.1"
          ></T>
        </p>

        <form [formGroup]="documentSelectFormGroup">
          <mat-form-field appearance="legacy" class="white w-full">
            <mat-label>
              <T
                str="Image type"
                key="customerPortal.customer-portal.image-type"
                tags="customer-portal, 3.1"
              ></T>
            </mat-label>

            <mat-select formControlName="documentType">
              <mat-option *ngFor="let d of imageTypes" [value]="d">
                <ng-container
                  *ngTemplateOutlet="
                    sharedService.template('transifexTranslation');
                    context: {
                      str: d?.value?.toLowerCase(),
                      key: d?.key
                    }
                  "
                ></ng-container>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="loader"> </ng-container>

    <div
      class="vehicle-boxes-rounded mt-6 grid gap-2"
      *ngIf="!loadingGalleries"
    >
      <p class="font-efSans text-xl font-bold">
        <T
          str="Add image"
          key="customerPortal.customer-portal.add-images"
          tags="customer-portal, 3.1"
        ></T>
      </p>

      <button
        data-cy="vehicle-upload-image-button"
        class="revolution small-button md:medium-button"
        mat-button
        mat-stroked-button
        color="secondary"
        (click)="
          onUploadImageClicked();
          sendContentModulesMiniCTAData(
            'customerPortal.customer-portal.add-images'
          )
        "
      >
        <mat-icon svgIcon="add"></mat-icon>
        <T
          str="Add image"
          key="customerPortal.customer-portal.add-images"
          tags="customer-portal, 3.1"
        ></T>
      </button>
    </div>
    <ng-container *ngTemplateOutlet="loader"> </ng-container>
  </div>
  <ng-template #loader>
    <div
      class="border rounded-xl p-6 w-full flex flex-col h-40 mb-6"
      *ngIf="loadingGalleries"
    >
      <div class="w-full">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>
      <div class="w-full flex-1 flex justify-center items-center">
        <customer-portal-ef-spinner
          class="absolute"
          [color]="'mat-ef-revolution-grey-two-500'"
          [opacity]="0.6"
          [diameter]="40"
        >
        </customer-portal-ef-spinner>
      </div>
    </div>
  </ng-template>
</div>
