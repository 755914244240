<div
  class="hidden w-screen min-h-[40px] bg-mat-ef-revolution-supergrey-500 md:flex justify-center"
>
  <ul
    class="flex gap-10 text-sm items-center font-efSans font-semibold text-mat-ef-revolution-primary-500"
  >
    <li
      class="inline-block bg-mat-ef-revolution-supergrey-500 py-2 rounded-t-lg border-b-2"
      [ngClass]="{
        'border-mat-ef-revolution-secondary-500': router.url === homepage
      }"
    >
      <a
        [routerLink]="homepage"
        (click)="
          sendGTMNavigationData(
            'customerPortal.customer-portal.summary.title',
            'White Box Link'
          )
        "
      >
        <T
          str="Overview"
          key="customerPortal.customer-portal.summary.title"
          tags="customer-portal, 3.1"
        ></T>
      </a>
    </li>
    <li
      class="inline-block bg-mat-ef-revolution-supergrey-500 py-2 rounded-t-lg border-b-2"
      *ngIf="vehicles?.length > 0"
      [ngClass]="{
        'border-mat-ef-revolution-secondary-500':
          router.url.includes('/vehicles')
      }"
    >
      <a
        [routerLink]="['/vehicles', firstVehicleVin, 'general']"
        (click)="sendGTMNavigationData('shared.my-vehicles', 'White Box Link')"
      >
        {{ 'my-vehicles' | sharedTranslate }}
      </a>
    </li>
    <li
      class="inline-block bg-mat-ef-revolution-supergrey-500 py-2 rounded-t-lg border-b-2"
      *ngIf="vehicles?.length === 0"
      [ngClass]="{
        'border-mat-ef-revolution-secondary-500': router.url === '/vehicles-not'
      }"
    >
      <a
        routerLink="/vehicles-not"
        (click)="sendGTMNavigationData('shared.my-vehicles', 'White Box Link')"
      >
        {{ 'my-vehicles' | sharedTranslate }}
      </a>
    </li>
    <li
      class="inline-block bg-mat-ef-revolution-supergrey-500 py-2 rounded-t-lg border-b-2"
      [ngClass]="{
        'border-mat-ef-revolution-secondary-500': router.url === '/appointments'
      }"
    >
      <a
        [routerLink]="['/appointments']"
        (click)="
          sendGTMNavigationData(
            'customerPortal.customer-portal.top-bar.appointments',
            'White Box Link'
          )
        "
      >
        <T
          str="My dates"
          key="customerPortal.customer-portal.top-bar.appointments"
          tags="customer-portal, 3.1"
        ></T>
      </a>
    </li>
    <li
      class="relative inline-block bg-mat-ef-revolution-supergrey-500 py-2 rounded-t-lg border-b-2"
      [ngClass]="{
        'border-mat-ef-revolution-secondary-500': router.url === '/my-documents'
      }"
    >
      <a
        [routerLink]="['/my-documents']"
        matBadgePosition="after"
        matBadgeOverlap="false"
        matBadgeSize="small"
        matBadgeColor="primary"
        (click)="
          sendGTMNavigationData(
            'customerPortal.customer-portal.top-bar.my-documents',
            'White Box Link'
          )
        "
      >
        <T
          str="Customer History"
          key="customerPortal.customer-portal.top-bar.my-documents"
          tags="customer-portal, 3.1"
        ></T>
        <customer-portal-app-unread-document
          class="absolute ml-2"
        ></customer-portal-app-unread-document>
      </a>
    </li>
    <li
      class="inline-block bg-mat-ef-revolution-supergrey-500 py-2 rounded-t-lg border-b-2"
      [ngClass]="{
        'border-mat-ef-revolution-secondary-500': router.url === '/services'
      }"
    >
      <a
        [routerLink]="['/services']"
        (click)="
          sendGTMNavigationData(
            'customerPortal.customer-portal.top-bar.additional-services'
          )
        "
      >
        <T
          str="Services"
          key="customerPortal.customer-portal.top-bar.additional-services"
          tags="customer-portal, 3.1"
        ></T>
      </a>
    </li>
    <li
      class="inline-block bg-mat-ef-revolution-supergrey-500 py-2 rounded-t-lg border-b-2"
      [ngClass]="{
        'border-mat-ef-revolution-secondary-500':
          router.url.includes('/marketplace')
      }"
    >
      <a
        [routerLink]="[navigationMarketplace?.[0]?.path]"
        (click)="
          sendGTMNavigationData('shared.my-marketplace', 'White Box Link')
        "
      >
        {{ 'my-marketplace' | sharedTranslate }}
      </a>
    </li>
    <li
      *ngIf="hasTestView"
      class="inline-block bg-mat-ef-revolution-supergrey-500 py-2 rounded-t-lg border-b-2"
      [ngClass]="{
        'border-mat-ef-revolution-secondary-500': router.url === '/test'
      }"
    >
      <a [routerLink]="['/test']">
        <T
          str="Test"
          key="customerPortal.customer-portal.test"
          tags="customer-portal, 3.3"
        ></T>
      </a>
    </li>
  </ul>
</div>
