import {
  animate,
  group,
  query,
  style,
  transition,
  trigger
} from '@angular/animations'
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { SharedService } from '../../../../services/shared.service'
import {
  VehicleGalleryDTO,
  GalleryService
} from '../../../../services/gallery.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'

const left = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), {
    optional: true
  }),
  group([
    query(
      ':enter',
      [
        style({ transform: 'translateX(-100%)' }),
        animate('.3s ease-out', style({ transform: 'translateX(0%)' }))
      ],
      {
        optional: true
      }
    ),
    query(
      ':leave',
      [
        style({ transform: 'translateX(0%)' }),
        animate('.3s ease-out', style({ transform: 'translateX(100%)' }))
      ],
      {
        optional: true
      }
    )
  ])
]

const right = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), {
    optional: true
  }),
  group([
    query(
      ':enter',
      [
        style({ transform: 'translateX(100%)' }),
        animate('.3s ease-out', style({ transform: 'translateX(0%)' }))
      ],
      {
        optional: true
      }
    ),
    query(
      ':leave',
      [
        style({ transform: 'translateX(0%)' }),
        animate('.3s ease-out', style({ transform: 'translateX(-100%)' }))
      ],
      {
        optional: true
      }
    )
  ])
]
@Component({
  selector: 'customer-portal-app-revolution-image-slider',
  templateUrl: './image-slider.component.html',
  animations: [
    trigger('animImageSlider', [
      transition(':increment', right),
      transition(':decrement', left)
    ])
  ]
})
export class RevolutionImageSliderComponent implements OnInit, OnDestroy {
  index: number
  imageArr: VehicleGalleryDTO[]
  defaultTouch = { x: 0, y: 0, time: 0 }
  vehicle: VehicleDTOExtended

  sub = {
    onVehicleProfilePictureChanged: null
  }

  constructor(
    public dialogRef: MatDialogRef<RevolutionImageSliderComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private readonly cdf: ChangeDetectorRef,
    private readonly galleryService: GalleryService,
    private readonly sharedService: SharedService
  ) {
    this.index = data.index
    this.imageArr = data.imageArray
    this.vehicle = data.vehicle
  }

  ngOnDestroy(): void {
    this.sharedService.unsubscribe(this.sub)
  }

  ngOnInit(): void {
    this.sub.onVehicleProfilePictureChanged =
      this.galleryService.onVehicleProfilePictureChanged.subscribe(
        profilePicture => {
          this.close()
        }
      )
  }

  back(): void {
    if (this.index === 0) {
      this.index = this.imageArr.length - 1
    } else {
      this.index = this.index - 1
    }
  }

  forward(): void {
    if (this.index === this.imageArr.length - 1) {
      this.index = 0
    } else {
      this.index = this.index + 1
    }
  }

  setImage(index): void {
    this.index = index
  }

  close(reloadGallery?: boolean): void {
    this.dialogRef.close(reloadGallery)
  }

  @HostListener('touchstart', ['$event'])
  // @HostListener('touchmove', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  handleTouch(event): void {
    const touch =
      event.touches[0] != null ? event.touches[0] : event.changedTouches[0]

    // check the events
    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX
      this.defaultTouch.y = touch.pageY
      this.defaultTouch.time = event.timeStamp
    } else if (event.type === 'touchend') {
      const deltaX = touch.pageX - this.defaultTouch.x
      const deltaTime = event.timeStamp - this.defaultTouch.time

      // simulte a swipe -> less than 500 ms and more than 60 px
      if (deltaTime < 500) {
        // touch movement lasted less than 500 ms
        if (Math.abs(deltaX) > 60) {
          // delta x is at least 60 pixels
          if (deltaX > 0) {
            this.back()
          } else {
            this.forward()
          }
        }

        // if (Math.abs(deltaY) > 60) {
        //   if (deltaY > 0) {
        //     this.doSwipeDown(event);
        //   } else {
        //     this.doSwipeUp(event);
        //   }
        // }
      }
    }
  }
}
