<div
  class="RevolutionArticlesComponent w-full"
  [ngClass]="{ 'bg-mat-ef-revolution-grey-200': hasData() }"
>
  <div class="container px-4">
    <div class="w-full pt-14 md:pb-14 md:pt-24">
      <div class="flex flex-col text-center w-full md:text-left">
        <div
          class="font-canelablack text-mat-ef-revolution-primary-500 text-4xl md:text-5xl pb-10 md:pb-12"
        >
          <T
            str="For me"
            key="customerPortal.customer-portal.for-me"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </div>

      <ng-container *ngIf="!dashEcoService.loadingArticles">
        <div
          class="articles w-full relative"
          [ngClass]="{ 'pb-[7rem] ': showBullets || showArrows }"
          *ngIf="hasData()"
        >
          <ngx-glide
            #ngxGlide
            [arrowLeftTemplate]="left"
            [arrowRightTemplate]="right"
            [breakpoints]="breakpoints"
            [peek]="{ before: 0, after: 40 }"
            (resized)="recreate()"
            [gap]="0"
            [showBullets]="showBullets"
            [showArrows]="showArrows"
            [bound]="bound"
            [ngClass]="{ showBullets: showBullets, fitGlider: fitGlider }"
          >
            <!-- 100.ch iframe-->
            <div
              class="flex-1 flex flex-col h-auto p-1 px-1.5 md:px-3 pb-5"
              [ngClass]="{
                'md:max-w-[440px]': !hasArticleData()
              }"
              *ngIf="showIframe && user?.contactId"
            >
              <mat-card
                class="min-h-[540px] relative overflow-hidden rounded-t-lg article select-none flex flex-col transition duration-300 ease-in-out !shadow-none hover:!shadow-lg !rounded-lg h-full !p-0 cursor-pointer"
                (click)="sharedService.preventClick || open100WEBLink()"
                (mouseup)="sharedService.handleGliderEvents($event, '100_WEB')"
                (mousedown)="
                  sharedService.handleGliderEvents($event, '100_WEB')
                "
              >
                <!-- need this for scrolling  -->
                <div
                  class="absolute top-0 right-0 left-0 bottom-0 bg-transparent z-50"
                ></div>
                <iframe
                  id="100WEB"
                  class="h-full overflow-hidden"
                  [src]="iframeSrc"
                ></iframe>
              </mat-card>
            </div>
            <ng-container *ngIf="hasArticleData()">
              <div
                class="flex-1 flex flex-col h-auto p-1 px-1.5 md:px-3 pb-5"
                [ngClass]="{
                  'md:max-w-[440px]': articleResponse.data.length === 1
                }"
                *ngFor="let article of articleResponse.data; let i = index"
              >
                <mat-card
                  class="article select-none flex flex-col transition duration-300 ease-in-out !shadow-none hover:!shadow-lg !rounded-lg h-full !p-0 cursor-pointer"
                  (click)="
                    sharedService.preventClick || openLink(article);
                    sendContentModulesBigTeaserData(
                      'customerPortal.customer-portal.for-me',
                      article.title
                    )
                  "
                  (mouseup)="
                    sharedService.handleGliderEvents($event, article.id)
                  "
                  (mousedown)="
                    sharedService.handleGliderEvents($event, article.id)
                  "
                >
                  <div class="relative h-[274px]">
                    <img
                      alt="article image"
                      *ngIf="article.header_image[0]"
                      class="rounded-t-lg h-full w-full object-cover"
                      src="{{ article.header_image[0] }}"
                    />
                  </div>

                  <div
                    class="flex flex-col justify-between text-center pt-6 px-6 pb-12 rounded-b-lg bg-light-bg"
                  >
                    <div>
                      <div
                        class="font-canelablack text-mat-ef-revolution-primary-500 !text-2xl whitespace-normal pb-3.5"
                      >
                        {{ article.title }}
                      </div>
                      <div
                        class="ef-paragraph text-mat-ef-revolution-primary-500 block text-base whitespace-normal"
                      >
                        {{ article.subtitle }}
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </ng-container>
          </ngx-glide>
        </div>

        <div
          *ngIf="!hasData()"
          class="w-full h-36 bg-mat-ef-revolution-grey-200 rounded-xl flex flex-col justify-center items-center"
        >
          <div class="text-mat-ef-revolution-supergrey-900">
            <mat-icon svgIcon="no_sim"></mat-icon>
          </div>
          <div class="text-base font-efSans-primary text-center">
            <T
              str="There are currently no related items for you"
              key="customerPortal.customer-portal.articles.no-articles"
              tags="customer-portal, 3.1"
            ></T>
          </div>
        </div>
      </ng-container>

      <div *ngIf="dashEcoService.loadingArticles" class="p-4">
        <ngx-skeleton-loader appearance="line" [count]="8" animation="progress">
        </ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>

<ng-template #left>
  <div
    class="hidden arrowTemplate leftArrow md:flex justify-center items-center bottomArrows"
    (click)="
      sendContentModulesSliderData(
        'Left',
        'customerPortal.customer-portal.for-me',
        'Revolution articles'
      )
    "
  >
    <mat-icon svgIcon="arrow_left"></mat-icon>
  </div>
</ng-template>

<ng-template #right>
  <div
    class="hidden arrowTemplate rightArrow md:flex justify-center items-center bottomArrows"
    (click)="
      sendContentModulesSliderData(
        'Right',
        'customerPortal.customer-portal.for-me',
        'Revolution articles'
      )
    "
  >
    <mat-icon svgIcon="arrow_right"></mat-icon>
  </div>
</ng-template>
