<div
  class="text-lg md:text-xl font-bold mb-5 text-mat-ef-revolution-primary-500"
  *ngIf="!loading"
>
  <T
    str="Basic details"
    key="customerPortal.customer-portal.vehicle.basic.details"
    tags="customer-portal, 3.1"
  ></T>
</div>
<div *ngIf="loading" class="heading-skeleton px-5">
  <ngx-skeleton-loader
    appearance="line"
    animation="progress"
  ></ngx-skeleton-loader>
  <br />
</div>
<mat-divider class="relative w-full"></mat-divider>
<div class="flex items-center h-16 mr-0">
  <div class="flex gap-5 items-center w-full">
    <div class="panel-icon" *ngIf="!loading">
      <mat-icon
        class="text-mat-ef-revolution-primary-500 mt-2.5 min-w-[20px] min-h-[20px]"
        svgIcon="vin"
      ></mat-icon>
    </div>
    <div *ngIf="loading" class="panel-icon">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>

    <div class="w-full mr-5">
      <div
        class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500 font-semibold"
        *ngIf="!loading"
      >
        <T
          str="VIN (chassis number)"
          key="customerPortal.customer-portal.vehicle.details.vin"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div *ngIf="loading">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>

      <div
        data-cy="vehicle-basic-details-vin"
        class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500"
        *ngIf="!loading"
      >
        {{ vehicle?.vin }}
      </div>
      <div *ngIf="loading" class="skeleton-margin">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>
<mat-divider class="relative w-full"></mat-divider>
<div class="flex items-center h-16 mr-0">
  <div class="flex gap-5 items-center w-full">
    <mat-icon
      *ngIf="!loading"
      class="text-mat-ef-revolution-primary-500 mt-2.5 min-w-[20px] min-h-[20px]"
      svgIcon="carVin"
    ></mat-icon>
    <div *ngIf="loading" class="panel-icon">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>
    <div class="w-full mr-5">
      <div
        class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500 font-semibold"
        *ngIf="!loading"
      >
        <T
          str="Licence plate"
          key="customerPortal.customer-portal.vehicle.details.registration-plate"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div *ngIf="loading">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>

      <div
        class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500"
        *ngIf="!loading && showMask"
      >
        {{ vehicle?.licensePlate | mask: licensePlateMask }}
      </div>
      <div
        class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500"
        *ngIf="!loading && !showMask"
      >
        {{ vehicle?.licensePlate }}
      </div>

      <div *ngIf="loading" class="skeleton-margin">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <span
      data-cy="edit-license-plate-button"
      *ngIf="!loading"
      class="border-mat-ef-revolution-secondary-500 flex items-center cursor-pointer text-xs text-mat-ef-revolution-secondary-500 border-2 font-bold rounded h-6 leading-3 px-2 mr-2.5"
      (click)="
        editLicensePlate(vehicle);
        sendContentModulesMiniCTAData('shared.edit', 'license plate')
      "
    >
      <mat-icon
        color="secondary"
        class="text-mat-ef-revolution-secondary-500 min-w-[16px] min-h-[16px] max-w-[16px]"
        svgIcon="create"
      ></mat-icon>
      <ng-container *ngTemplateOutlet="edit"></ng-container>
    </span>
  </div>
</div>
<mat-divider class="relative w-full"></mat-divider>
<div class="flex items-center h-16 mr-0">
  <div class="flex gap-5 items-center w-full">
    <mat-icon
      class="text-mat-ef-revolution-primary-500 mt-2.5 min-w-[20px] min-h-[20px]"
      *ngIf="!loading"
      svgIcon="calendarDetails"
    ></mat-icon>
    <div *ngIf="loading" class="panel-icon">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>
    <div class="w-full mr-5">
      <div
        class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500 font-semibold"
        *ngIf="!loading"
      >
        <T
          str="Initial placing on the market"
          key="customerPortal.customer-portal.vehicle.details.first-registration"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div *ngIf="loading">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>
      <div
        class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500"
        *ngIf="!loading"
      >
        {{ (vehicle?.firstRegistration | date: dateFormat) || '-' }}
      </div>
      <div *ngIf="loading" class="skeleton-margin">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <span
      data-cy="edit-first-registration-button"
      *ngIf="
        vehicle?.createdByDataSource === 'customer_portal' &&
        !loading &&
        !vehicle?.business
      "
      class="border-mat-ef-revolution-secondary-500 flex items-center cursor-pointer text-xs text-mat-ef-revolution-secondary-500 border-2 font-bold rounded h-6 leading-3 px-2 mr-2.5"
      (click)="
        editFirstRegistration(vehicle);
        sendContentModulesMiniCTAData('shared.edit', 'first registration')
      "
    >
      <mat-icon
        color="secondary"
        class="text-mat-ef-revolution-secondary-500 min-w-[16px] min-h-[16px] max-w-[16px]"
        svgIcon="create"
      ></mat-icon>
      <ng-container *ngTemplateOutlet="edit"></ng-container>
    </span>
  </div>
</div>
<mat-divider class="relative w-full"></mat-divider>
<div class="flex items-center h-16 mr-0">
  <div class="flex gap-5 items-center w-full">
    <mat-icon
      class="text-mat-ef-revolution-primary-500 mt-2.5 min-w-[20px] min-h-[20px]"
      *ngIf="!loading"
      svgIcon="Kilometerstand"
    ></mat-icon>
    <div *ngIf="loading" class="panel-icon">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>
    <div class="w-full mr-5">
      <div
        class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500 font-semibold"
        *ngIf="!loading"
      >
        <T
          str="Last service mileage"
          key="customerPortal.customer-portal.vehicle.details.mileage"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div *ngIf="loading">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>
      <div
        class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500"
        *ngIf="!loading"
      >
        {{ serviceKm || '-' }} km
      </div>
      <div *ngIf="loading" class="skeleton-margin">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <span
      data-cy="edit-mileage-button"
      *ngIf="!loading && !vehicle?.business"
      mat-button
      mat-stroked-button
      class="border-mat-ef-revolution-secondary-500 flex items-center cursor-pointer text-xs text-mat-ef-revolution-secondary-500 border-2 font-bold rounded h-6 leading-3 px-2 mr-2.5"
      color="secondary"
      (click)="
        editMileage(vehicle);
        sendContentModulesMiniCTAData('shared.edit', 'mileage')
      "
    >
      <mat-icon
        class="secondary"
        class="text-mat-ef-revolution-secondary-500 min-w-[16px] min-h-[16px] max-w-[16px]"
        svgIcon="create"
      ></mat-icon>
      <ng-container *ngTemplateOutlet="edit"></ng-container>
    </span>
  </div>
</div>
<mat-divider class="relative w-full"></mat-divider>

<div *ngIf="vehicle?.createdByDataSource === 'customer_portal'">
  <div class="flex items-center h-16 mr-0" data-cy="crud-leasing-expiry">
    <div class="flex gap-5 items-center w-full">
      <mat-icon
        class="text-mat-ef-revolution-primary-500 mt-2.5 min-w-[20px] min-h-[20px]"
        *ngIf="!loading"
        svgIcon="calendarDetails"
      ></mat-icon>
      <div *ngIf="loading" class="panel-icon">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>
      <div class="w-full mr-5">
        <div
          class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500 font-semibold"
          *ngIf="!loading"
        >
          <T
            str="Leasing expires"
            key="customerPortal.customer-portal.leasingExpiryDate"
            tags="customer-portal, 3.1"
          ></T>
        </div>
        <div *ngIf="loading">
          <ngx-skeleton-loader
            appearance="line"
            animation="progress"
          ></ngx-skeleton-loader>
        </div>
        <div
          class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500"
          *ngIf="!loading"
        >
          {{ (vehicle?.leasingExpiryDate | date: 'MM/yyyy') || '-' }}
        </div>
        <div *ngIf="loading" class="skeleton-margin">
          <ngx-skeleton-loader
            appearance="line"
            animation="progress"
          ></ngx-skeleton-loader>
        </div>
      </div>
      <div class="displayFlex" *ngIf="vehicle?.leasingExpiryDate && !loading">
        <customer-portal-app-cpt-menu
          data-cy="edit-leasing-expiry-menu"
          [menuType]="'editDeleteMenu'"
          (actionClicked)="
            leasingExpiryNextServiceActionClicked($event, vehicle)
          "
        >
        </customer-portal-app-cpt-menu>
      </div>
      <span
        data-cy="edit-leasing-expiry-button"
        *ngIf="!vehicle?.leasingExpiryDate && !loading"
        class="border-mat-ef-revolution-secondary-500 flex items-center cursor-pointer text-xs text-mat-ef-revolution-secondary-500 border-2 font-bold rounded h-6 leading-3 px-2 mr-2.5"
        (click)="
          editLeasingExpiryNextService(vehicle, 'leasingExpiryDate');
          sendContentModulesMiniCTAData('shared.edit', 'leasing expiry')
        "
      >
        <mat-icon
          color="secondary"
          class="text-mat-ef-revolution-secondary-500 min-w-[16px] min-h-[16px] max-w-[16px]"
          svgIcon="create"
        ></mat-icon>
        <ng-container *ngTemplateOutlet="edit"></ng-container>
      </span>
    </div>
  </div>
  <mat-divider class="relative w-full"></mat-divider>
  <div class="flex items-center h-16 mr-0" data-cy="crud-next-service">
    <div class="flex gap-5 items-center w-full">
      <mat-icon
        class="text-mat-ef-revolution-primary-500 mt-2.5 min-w-[20px] min-h-[20px]"
        *ngIf="!loading"
        svgIcon="calendarDetails"
      ></mat-icon>
      <div *ngIf="loading" class="panel-icon">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>
      <div class="w-full mr-5">
        <div
          class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500 font-semibold"
          *ngIf="!loading"
        >
          <T
            str="Next appointment"
            key="customerPortal.customer-portal.nextServiceDate"
            tags="customer-portal, 3.1"
          ></T>
        </div>
        <div *ngIf="loading">
          <ngx-skeleton-loader
            appearance="line"
            animation="progress"
          ></ngx-skeleton-loader>
        </div>
        <div
          class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500"
          *ngIf="!loading"
        >
          {{ (vehicle?.nextServiceDate | date: 'MM/yyyy') || '-' }}
        </div>
        <div *ngIf="loading" class="skeleton-margin">
          <ngx-skeleton-loader
            appearance="line"
            animation="progress"
          ></ngx-skeleton-loader>
        </div>
      </div>
      <div class="displayFlex" *ngIf="vehicle?.nextServiceDate && !loading">
        <customer-portal-app-cpt-menu
          data-cy="edit-next-service-menu"
          [menuType]="'editDeleteMenu'"
          (actionClicked)="nextServiceDateActionClicked($event, vehicle)"
        >
        </customer-portal-app-cpt-menu>
      </div>
      <span
        data-cy="edit-next-service-button"
        *ngIf="!vehicle?.nextServiceDate && !loading"
        class="border-mat-ef-revolution-secondary-500 flex items-center cursor-pointer text-xs text-mat-ef-revolution-secondary-500 border-2 font-bold rounded h-6 leading-3 px-2 mr-2.5"
        (click)="
          editLeasingExpiryNextService(vehicle, 'nextServiceDate');
          sendContentModulesMiniCTAData('shared.edit', 'next service')
        "
      >
        <mat-icon
          color="secondary"
          class="text-mat-ef-revolution-secondary-500 min-w-[16px] min-h-[16px] max-w-[16px]"
          svgIcon="create"
        ></mat-icon>
        <ng-container *ngTemplateOutlet="edit"></ng-container>
      </span>
    </div>
  </div>
  <mat-divider class="relative w-full"></mat-divider>
</div>

<div class="flex items-center h-16 mr-0">
  <div class="flex gap-5 items-center w-full">
    <mat-icon
      class="text-mat-ef-revolution-primary-500 mt-2.5 min-w-[20px] min-h-[20px]"
      *ngIf="!loading"
      svgIcon="offer"
    ></mat-icon>
    <div *ngIf="loading" class="panel-icon">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>
    <div class="w-full mr-5">
      <div
        class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500 font-semibold"
        *ngIf="!loading"
      >
        <T
          str="Bought from"
          key="customerPortal.customer-portal.vehicle.details.purchased-at"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div *ngIf="loading">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>
      <div
        class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500"
        *ngIf="!loading && vehicle"
      >
        {{
          vehicle?.dealers?.purchasedDealer?.name
            ? vehicle.dealers.purchasedDealer.name +
                (', ' + vehicle.dealers.purchasedDealer.subname) || ''
            : ' - '
        }}
      </div>
      <div *ngIf="loading" class="skeleton-margin">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>
<mat-divider class="relative w-full"></mat-divider>

<div class="flex items-center h-16 mr-0" *ngIf="enableFrequentDriver">
  <div class="flex gap-5 items-center w-full" data-cy="crud-frequent-driver">
    <mat-icon
      class="text-mat-ef-revolution-primary-500 mt-2.5 min-w-[20px] min-h-[20px]"
      *ngIf="!loading"
      svgIcon="driverIcon"
    ></mat-icon>
    <div *ngIf="loading" class="panel-icon">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>
    <div class="w-full mr-5">
      <div
        class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500 font-semibold"
        *ngIf="!loading"
      >
        <T
          str="Most frequent driver"
          key="customerPortal.customer-portal.vehicle.details.most-frequent-driver"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div *ngIf="loading">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>
      <div
        class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500"
        *ngIf="mostFrequentDriver && !loading"
      >
        <ng-container
          *ngIf="
            mostFrequentDriver?.salutation?.toLowerCase() !== 'unspecified'
          "
        >
          {{
            'salutation-' + mostFrequentDriver?.salutation?.toLowerCase()
              | sharedTranslate
          }}</ng-container
        >
        {{
          mostFrequentDriver
            ? mostFrequentDriver?.firstName + ' ' + mostFrequentDriver?.lastName
            : ' - '
        }}
      </div>
      <div
        class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500"
        *ngIf="!mostFrequentDriver && !loading"
      >
        -
      </div>
      <div *ngIf="loading" class="skeleton-margin">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div *ngIf="mostFrequentDriver && !loading" class="cptMenuContainer p-0">
      <customer-portal-app-cpt-menu
        data-cy="edit-frequent-driver-menu"
        [menuType]="'editDeleteMenu'"
        (actionClicked)="frequentDriverActionClicked($event)"
      >
      </customer-portal-app-cpt-menu>
    </div>
    <span
      data-cy="add-frequent-driver-button"
      *ngIf="!mostFrequentDriver && !loading"
      class="border-mat-ef-revolution-secondary-500 flex items-center cursor-pointer text-xs text-mat-ef-revolution-secondary-500 border-2 font-bold rounded h-6 leading-3 px-2 mr-2.5"
      (click)="
        openFrequentDriverPopUp();
        sendContentModulesMiniCTAData('shared.edit', 'frequent driver')
      "
    >
      <mat-icon
        color="secondary"
        class="text-mat-ef-revolution-secondary-500 min-w-[16px] min-h-[16px] max-w-[16px]"
        svgIcon="create"
      ></mat-icon>
      <ng-container *ngTemplateOutlet="edit"></ng-container>
    </span>
  </div>
</div>
<mat-divider class="relative w-full"></mat-divider>
<div class="flex items-center h-16 mr-0" data-cy="crud-nickname">
  <div class="flex gap-5 items-center w-full">
    <mat-icon
      class="text-mat-ef-revolution-primary-500 mt-2.5 min-w-[20px] min-h-[20px]"
      *ngIf="!loading"
      svgIcon="directions_car"
    ></mat-icon>
    <div *ngIf="loading" class="panel-icon">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>
    <div class="w-full mr-5">
      <div
        class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500 font-semibold"
        *ngIf="!loading"
      >
        <T
          str="Does your car have a name?"
          key="customerPortal.customer-portal.vehicle.details.nickname"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div *ngIf="loading">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>
      <div
        class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500"
        *ngIf="!loading"
      >
        {{ vehicle?.nickname ? vehicle?.nickname : ' - ' }}
      </div>
      <div *ngIf="loading" class="skeleton-margin">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div *ngIf="vehicle?.nickname && !loading" class="cptMenuContainer p-0">
      <customer-portal-app-cpt-menu
        data-cy="edit-nickname-menu"
        [menuType]="'editDeleteMenu'"
        (actionClicked)="nicknameActionClicked($event, vehicle)"
      >
      </customer-portal-app-cpt-menu>
    </div>
    <span
      data-cy="edit-nickname-button"
      *ngIf="!vehicle?.nickname && !loading"
      class="border-mat-ef-revolution-secondary-500 flex items-center cursor-pointer text-xs text-mat-ef-revolution-secondary-500 border-2 font-bold rounded h-6 leading-3 px-2 mr-2.5"
      (click)="
        editNickname(vehicle);
        sendContentModulesMiniCTAData('shared.edit', 'nickname')
      "
    >
      <mat-icon
        color="secondary"
        class="text-mat-ef-revolution-secondary-500 min-w-[16px] min-h-[16px] max-w-[16px]"
        svgIcon="create"
      ></mat-icon>
      <ng-container *ngTemplateOutlet="edit"></ng-container>
    </span>
  </div>
</div>
<mat-divider class="relative w-full"></mat-divider>

<ng-template #edit>
  {{ 'edit' | sharedTranslate }}
</ng-template>
