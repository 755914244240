import { ChangeDetectorRef, Component, type OnDestroy, type OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import {
  type CptDateFormats,
  CptGoogleTagmanagerService,
  EcondaService,
  MatomoIntegrationService,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { type Canton } from '../../../services/appointments.service'
import { DataService } from '../../../services/data.service'
import { DatepickerScrollService } from '../../../services/datepickerScroll.service'
import { InstantErrorStateMatcherService } from '../../../services/instant-error-state-matcher.service'
import { SharedService } from '../../../services/shared.service'
import {
  type BrandDTO,
  type ModelDTO,
  type VehicleDTOExtended,
  VehiclesService
} from '../../../services/vehicles.service'
import { ConfirmationQuestionPopupComponent } from '../../basic/confirmation-question-popup/confirmation-question-popup.component'
import { CustomCalendarHeaderComponent } from '../../basic/custom-calendar-header/custom-calendar-header.component'
import { type MatCheckbox } from '@angular/material/checkbox'
import { add, format } from 'date-fns'

export const APP_DATE_FORMATS: CptDateFormats = {
  parse: {
    dateInput: 'MM/YYYY'
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    default: 'MMMM YYYY'
  }
}

@Component({
  selector: 'customer-portal-app-add-new-vehicle',
  templateUrl: './add-new-vehicle.component.html'
})
export class AddNewVehicleComponent implements OnInit, OnDestroy {
  hidden = false
  matcher = new InstantErrorStateMatcherService()
  brands: BrandDTO[] = []
  models: ModelDTO[] = []

  brand: BrandDTO
  model: ModelDTO

  loading = false
  saving = false

  licensePlateNormalRegex: string
  licensePlateOfficialRegex: string
  licensePlateAnyRegex

  customHeader = CustomCalendarHeaderComponent

  deLicencePlateRegex

  addVehicleForm

  sub = {
    onTransifexLanguageChange: null
  }

  country: string
  now = new Date()
  fiveYearsAfter = add(new Date(), {years: 5})

  public cantons: Canton[] = [
    { id: 'AG' },
    { id: 'AI' },
    { id: 'AR' },
    { id: 'BE' },
    { id: 'BL' },
    { id: 'BS' },
    { id: 'FR' },
    { id: 'GE' },
    { id: 'GL' },
    { id: 'GR' },
    { id: 'JU' },
    { id: 'LU' },
    { id: 'NE' },
    { id: 'NW' },
    { id: 'OW' },
    { id: 'SG' },
    { id: 'SH' },
    { id: 'SO' },
    { id: 'SZ' },
    { id: 'TG' },
    { id: 'TI' },
    { id: 'UR' },
    { id: 'VD' },
    { id: 'VS' },
    { id: 'ZG' },
    { id: 'ZH' }
  ]

  canton: Canton

  filteredBrands
  filteredModels
  dateFormat: string

  otherLicencePlateCheckbox = false
  constructor (
    private readonly dialogRef: MatDialogRef<AddNewVehicleComponent>,
    private readonly vehiclesService: VehiclesService,
    private readonly dataService: DataService,
    private readonly datepickerScrollService: DatepickerScrollService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public sharedService: SharedService,
    private readonly dialog: MatDialog,
    private readonly transifexTranslationsService: TranslationService,
    private readonly cdf: ChangeDetectorRef,
    public transifexService: TransifexService,
    private readonly econdaService: EcondaService,
    private readonly matomoIntegrationService: MatomoIntegrationService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.dateFormat = this.remoteConfigService.get('dateFormat.long').toUpperCase()
    this.country = this.remoteConfigService.get('country.code')
    this.licensePlateNormalRegex = this.remoteConfigService.get(
      'regEx.licencePlateRegEx.types.normal.regex'
    )
    this.licensePlateOfficialRegex = this.remoteConfigService.get(
      'regEx.licencePlateRegEx.types.official.regex'
    )
    this.licensePlateAnyRegex = this.remoteConfigService.get(
      'regEx.licencePlateRegEx.types.any.regex'
    )
    this.deLicencePlateRegex = new RegExp(
      this.licensePlateNormalRegex + '|' + this.licensePlateOfficialRegex,
      'i'
    )

    this.addVehicleForm = new FormGroup({
      brand: new FormControl<string | null>(null),
      model: new FormControl<string | null>(null),
      modelLine: new FormControl<string | null>(null),
      vin: new FormControl<string | null>(null, [
        Validators.minLength(17),
        Validators.maxLength(17)
      ]),
      licencePlateCanton: new FormControl<string | null>(null),
      otherLicencePlate: new FormControl<string | null>(
        null,
        Validators.pattern(this.licensePlateAnyRegex)
      ),
      licencePlateDE: new FormControl<string | null>(
        null,
        Validators.pattern(this.deLicencePlateRegex)
      ),
      licencePlateCH: new FormControl<string | null>(
        null,
        Validators.compose([
          Validators.pattern(this.licensePlateOfficialRegex),
          Validators.maxLength(6)
        ])
      ),
      manualMileage: new FormControl<number | null>(
        null,
        Validators.maxLength(7)
      ),
      firstRegistration: new FormControl<string | null>(null),
      leasingExpiry: new FormControl<string | null>(null),
      nextService: new FormControl<string | null>(null)
    })
  }

  ngOnInit (): void {
    this.cptGtmService.sendAddCarData(1)

    // this dialog is always opened after click
    this.matomoIntegrationService.trackEvent(
      'Vehicle action',
      'Add new vehicle click'
    )

    this.load()

    this.addVehicleForm.get('brand').valueChanges.subscribe(value => {
      this.filteredBrands = this._filter(value)
      // clear selected model
      this.addVehicleForm.get('model').setValue(undefined)
      this.models = []
      this.filteredModels = []
    })

    this.addVehicleForm.get('model').valueChanges.subscribe(value => {
      this.filteredModels = this._filterModels(value)
    })
  }

  load (): void {
    this.cdf.detectChanges()
    this.vehiclesService.getBrands().subscribe(value => {
      this.brands = value.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
      this.filteredBrands = this.sharedService.deepCopy(this.brands)
    })
    this.brandValid()
  }

  brandValid (): void {
    this.addVehicleForm.get('brand').invalid
      ? this.addVehicleForm.get('model').disable()
      : this.addVehicleForm.get('model').enable()
  }

  getBrandModels (): void {
    this.brandValid()
    const brand = this.getSelectedBrand()
    if (brand?.id != null) {
      this.vehiclesService.getBrandModels(brand.id).subscribe(value => {
        this.models = value.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
        this.filteredModels = this.sharedService.deepCopy(this.models)
        if (this.models.length === 1) {
          this.addVehicleForm.get('model').setValue(this.models[0].name)
        }
      })
    }
  }

  getSelectedBrand (): BrandDTO {
    return this.brands.find(el => {
      return (
        el.name.toLowerCase() ===
        this.addVehicleForm.get('brand').value.toLowerCase()
      )
    })
  }

  chosenYearHandler (normalizedYear: Date, formControl: string): void {
    const ctrlValue: Date = this.sharedService.dateExists(
      this.addVehicleForm.get(formControl).value
    )
      ? this.addVehicleForm.get(formControl).value
      : new Date()
    ctrlValue.setFullYear(normalizedYear.getFullYear())

    this.addVehicleForm.get(formControl).setValue(ctrlValue)
  }

  chosenMonthHandler (normalizedMonth: Date, picker, formControl: string): void {
    const ctrlValue: Date = this.sharedService.dateExists(
      this.addVehicleForm.get(formControl).value
    )
      ? this.addVehicleForm.get(formControl).value
      : new Date()
    ctrlValue.setMonth(normalizedMonth.getMonth())

    this.addVehicleForm.get(formControl).setValue(ctrlValue)
    picker.close()
  }

  addScrolling (picker): void {
    this.datepickerScrollService.addScrolling(picker)
  }

  addVehicle (): void {
    this.saving = true

    const newVehicle: VehicleDTOExtended = {
      id: null,
      vin: this.addVehicleForm.get('vin').value,
      brand: this.addVehicleForm.get('brand').value,
      model: this.addVehicleForm.get('model').value,
      description: this.addVehicleForm.get('modelLine').value,
      manualMileage: this.addVehicleForm.get('manualMileage').value,
      currentMileage: null,
      dateCreated: null,
      firstRegistration: this.sharedService.dateExists(
        this.addVehicleForm.get('firstRegistration').value
      )
        ? format(new Date(this.addVehicleForm.get('firstRegistration').value), 'yyyy-MM-dd')
        : '',
      leasingExpiryDate: this.sharedService.dateExists(
        this.addVehicleForm.get('leasingExpiry').value
      )
        ? format(new Date(this.addVehicleForm.get('leasingExpiry').value), 'yyyy-MM-dd')
        : '',
      nextServiceDate: this.sharedService.dateExists(
        this.addVehicleForm.get('nextService').value
      )
        ? format(new Date(this.addVehicleForm.get('nextService').value), 'yyyy-MM-dd')
        : '',
      licensePlate: null,
      fuelType: null,
      dealerId: null,
      dealerCompanyNumber: null,
      efSale: null,
      imageUrl: null,
      details: null
    }

    if (this.otherLicencePlateCheckbox === true) {
      newVehicle.licensePlate =
        this.addVehicleForm.get('otherLicencePlate').value
    } else {
      if (this.country === 'de') {
        newVehicle.licensePlate =
          this.addVehicleForm.get('licencePlateDE').value
      }
      if (this.country === 'ch') {
        newVehicle.licensePlate =
          String(this.addVehicleForm.get('licencePlateCanton').value) +
          String(this.addVehicleForm.get('licencePlateCH').value)
      }
    }

    this.vehiclesService.addUserVehicle(newVehicle).subscribe(
      vehicle => {
        this.saving = false
        if (vehicle?.business === true) {
          this.confirmAddCompanyVehicle(vehicle)
        } else {
          this.closeAfterSave(vehicle)
        }
      },
      error => {
        this.dialogRef.close(error)
      }
    )
  }

  closeAfterSave (vehicle: VehicleDTOExtended): void {
    this.dialogRef.close(vehicle)
    const v = vehicle
    this.dataService.vehicleVinChanged(v.vin)
    this.cptGtmService.sendAddCarData(2, vehicle)
    this.econdaService.send({
      content: 'Add new vehicle',
      Target: ['Added new vehicle', JSON.stringify(vehicle), 1, 'd']
    })
    this.matomoIntegrationService.trackEvent(
      'Vehicle action',
      'Added new vehicle',
      this.matomoIntegrationService.formatVehicleData(vehicle)
    )
  }

  saveCompanyVehicle (vehicle: VehicleDTOExtended, dialogRef): void {
    this.vehiclesService.addCompanyVehicle(vehicle).subscribe(
      vehicle => {
        this.saving = false
        dialogRef.close()
        this.closeAfterSave(vehicle)
      },
      error => {
        this.saving = false
        dialogRef.close()
        this.dialogRef.close(error)
      }
    )
  }

  confirmAddCompanyVehicle (vehicle: VehicleDTOExtended): void {
    this.hidden = true

    this.sub.onTransifexLanguageChange =
      this.transifexService.onTransifexLanguageChange.subscribe(() => {
        const translation = this.transifexTranslationsService.translate(
          'Are you the driver of the company vehicle <br><br> <b>Brand:</b> { brand }<br> <b>Model:</b> { model }<br> <b>VIN ( FIN):</b> { vin }<br> <b>Licence:</b> { licensePlate }<br> <b>First registration:</b> { firstRegistration }<br>< br> from { vehicleCompany }?"',
          {
            _key: 'customerPortal.customer-portal.add-new-vehicle.company-vehicle.text',
            _tags: 'customer-portal, 3.1',

            brand: vehicle.brand,
            model: vehicle.model,
            vin: vehicle.vin,
            licensePlate: vehicle.licensePlate,
            firstRegistration:
              vehicle.firstRegistration != null
                ? format(new Date(vehicle.firstRegistration), 'yyyy-MM-dd')
                : '',
            vehicleCompany: vehicle.vehicleCompany.name
          }
        )
        const dialogRef = this.dialog.open(ConfirmationQuestionPopupComponent, {
          data: {
            title:
              'customerPortal.customer-portal.add-new-vehicle.company-vehicle.title',
            translatedText: translation,
            cancel: 'customerPortal.customer-portal.general.actions.no',
            save: 'customerPortal.customer-portal.general.actions.yes',
            preventCloseBeforeComplete: true,
            useInnerHtml: true
          },
          panelClass: 'mat-dialog-cpt'
        })
        const sub = dialogRef.componentInstance.confirm.subscribe(() => {
          this.saveCompanyVehicle(vehicle, dialogRef)
        })
        dialogRef.afterClosed().subscribe(() => {
          this.dialogRef.close()
          sub.unsubscribe()
        })
      })
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  // autocomplete
  checkOptions (filteredBrands: BrandDTO[]): void {
    if (filteredBrands?.[0] === null) {
      this.addVehicleForm.get('brand').setErrors({ invalidMake: true })
    }
  }

  checkOptionsModels (filteredModels: ModelDTO[]): void {
    if (filteredModels?.[0] === null) {
      this.addVehicleForm.get('model').setErrors({ invalidModel: true })
    }
  }

  clearBrand (ev): void {
    this.sharedService.preventEventPropagation(ev)
    this.addVehicleForm.get('brand').setValue(undefined)
  }

  clearModel (ev): void {
    this.sharedService.preventEventPropagation(ev)
    this.addVehicleForm.get('model').setValue(undefined)
  }

  otherLicencePlateChanged (checkbox: MatCheckbox): void {
    this.otherLicencePlateCheckbox = checkbox.checked
    setTimeout(() => {
      this.addVehicleForm.get('otherLicencePlate').setValue(null)
      this.addVehicleForm.get('licencePlateCanton').setValue(null)
      this.addVehicleForm.get('licencePlateDE').setValue(null)
      this.addVehicleForm.get('licencePlateCH').setValue(null)

      this.addVehicleForm.get('otherLicencePlate').markAsTouched()
      this.addVehicleForm.get('licencePlateCanton').markAsTouched()
      this.addVehicleForm.get('licencePlateDE').markAsTouched()
      this.addVehicleForm.get('licencePlateCH').markAsTouched()

      this.addVehicleForm.get('otherLicencePlate').updateValueAndValidity()
      this.addVehicleForm.get('licencePlateCanton').updateValueAndValidity()
      this.addVehicleForm.get('licencePlateDE').updateValueAndValidity()
      this.addVehicleForm.get('licencePlateCH').updateValueAndValidity()
    })
  }

  private _filter (value: string): BrandDTO[] {
    const filterValue = value?.toLowerCase() ?? ''
    return this.brands
      .filter(brand => brand.name.toLowerCase().includes(filterValue))
      .sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
  }

  private _filterModels (value: string): ModelDTO[] {
    const filterValue = value?.toLowerCase() ?? ''
    return this.models
      .filter(model => model.name.toLowerCase().includes(filterValue))
      .sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
  }
}
