/* eslint-disable @angular-eslint/directive-selector */

import { Directive, Input, TemplateRef } from '@angular/core'
import { TemplateStoreService } from '../services/template-store.service'

@Directive({
  selector: '[appTemplateKey]'
})
export class TemplateKeyDirective {
  constructor(
    private readonly template: TemplateRef<Record<string, unknown>>,
    private readonly templateStoreService: TemplateStoreService
  ) {}

  // eslint-disable-next-line accessor-pairs
  @Input('appTemplateKey')
  public set key(value: string) {
    this.templateStoreService.add(value, this.template)
  }
}
