import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { Observable } from 'rxjs'
export type DealerUserDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.dealer.dto.DealerUserDTO
export type DealerDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.dealer.dto.DealerDTO
export type DayTimeDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.dealer.dto.DayTimeDTO
export type CallRequestDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.dealer.dto.CallRequestDTO

export interface WorkingDays {
  day: string
  hours: string[]
}

export interface EmployeeRole {
  role: cpt.emilfreydigital.customer_portal_backend.rest.dealer.dto.DealerUserFunctionEnum
}

export interface IDealersService {
  getDealers: () => Observable<DealerDTO[]>
}

export interface SearchDealerSellerResponse {
  selectedDealer: DealerDTO
  allDealers: DealerDTO[]
}

@Injectable({
  providedIn: 'root'
})
export class DealersTestingService implements IDealersService {
  getDealers(): Observable<DealerDTO[]> {
    return undefined
  }
}

@Injectable({
  providedIn: 'root'
})
export class DealersService implements IDealersService {
  private readonly apiUrl

  constructor(
    private readonly http: HttpClient,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  getDealers(): Observable<DealerDTO[]> {
    return this.http.get<DealerDTO[]>(this.apiUrl + '/dealers')
  }

  getBrandDealers(
    brand?: string,
    includeGooglePlaceId?: boolean
  ): Observable<DealerDTO[]> {
    let params = new HttpParams()
    if (brand != null) {
      params = params.append('brand', brand)
    }
    if (includeGooglePlaceId === true) {
      params = params.append('includeGoogleId', 'true')
    }
    return this.http.get<DealerDTO[]>(this.apiUrl + '/dealers', {
      params
    })
  }

  /**
   * Get employees in a dealership
   * @param dealerId number
   */
  getDealerEmployees(
    dealerId: number,
    brand: string,
    role: EmployeeRole
  ): Observable<DealerUserDTO[]> {
    let params = new HttpParams()
    params = params.append('brand', brand)
    params = params.append('role', role.role)
    return this.http.get<DealerUserDTO[]>(
      this.apiUrl + '/dealers/' + String(dealerId) + '/employees',
      {
        params
      }
    )
  }

  createCallRequest(
    description: string,
    contactTime: string,
    language: string,
    vin: string,
    dealerId?: number,
    employeeId?: number
  ): Observable<CallRequestDTO> {
    let params = new HttpParams()
    // params = params.append('vin',vin)
    if (dealerId != null) {
      params = params.append('dealerId', dealerId.toString())
    }
    if (employeeId != null) {
      params = params.append('employeeId', employeeId.toString())
    }
    params = params.append('vin', vin)
    params = params.append('lang', language)

    const body: CallRequestDTO = {
      description,
      contactTime
    }

    const formData: FormData = new FormData()
    const json = new Blob([JSON.stringify(body)], {
      type: 'application/json'
    })
    formData.append('info', json)
    return this.http.post<CallRequestDTO>(
      this.apiUrl + '/dealers/call-request',
      body,
      {
        params
      }
    )
  }
}
