import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { LanguageService } from '../../../services/language.service'
import { SharedService } from '../../../services/shared.service'

import {
  CustomerPortalConfig,
  Link,
  BrandLink
} from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
export type BrandLinksType = 'warranty' | 'assistance' | 'servicePackage'

@Component({
  selector: 'customer-portal-app-brand-links',
  templateUrl: './brand-links.component.html'
})
export class BrandLinksComponent implements OnInit {
  @Input() type: BrandLinksType
  @Input() brand: string
  @Output() hasLinks = new EventEmitter<boolean>()

  configurations = [
    { type: 'assistance', brand: 'citroen' },
    { type: 'assistance', brand: 'ds' },
    { type: 'assistance', brand: 'peugeot' },
    { type: 'warranty', brand: 'citroen' },
    { type: 'warranty', brand: 'ds' },
    { type: 'warranty', brand: 'peugeot' },
    { type: 'servicePackage', brand: 'citroen' },
    { type: 'servicePackage', brand: 'ds' },
    { type: 'servicePackage', brand: 'peugeot' }
  ]

  env_brandLinks: BrandLink
  primaryLang = ''
  brandLinks: Link[] = []
  links: Link[] = []
  constructor(
    private readonly languageService: LanguageService,
    private readonly translate: TranslateService,
    public sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.env_brandLinks = {
      hasBrandLinks: this.remoteConfigService.get('brandLinks.hasBrandLinks'),
      assistance: {
        citroen: this.remoteConfigService.getArrayValues<Link[]>(
          'brandLinks.assistance.citroen'
        ),
        ds: this.remoteConfigService.getArrayValues<Link[]>(
          'brandLinks.assistance.ds'
        ),
        peugeot: this.remoteConfigService.getArrayValues<Link[]>(
          'brandLinks.assistance.peugeot'
        )
      },
      warranty: {
        citroen: this.remoteConfigService.getArrayValues<Link[]>(
          'brandLinks.warranty.citroen'
        ),
        ds: this.remoteConfigService.getArrayValues<Link[]>(
          'brandLinks.warranty.ds'
        ),
        peugeot: this.remoteConfigService.getArrayValues<Link[]>(
          'brandLinks.warranty.peugeot'
        )
      },
      servicePackage: {
        citroen: this.remoteConfigService.getArrayValues<Link[]>(
          'brandLinks.servicePackage.citroen'
        ),
        ds: this.remoteConfigService.getArrayValues<Link[]>(
          'brandLinks.servicePackage.ds'
        ),
        peugeot: this.remoteConfigService.getArrayValues<Link[]>(
          'brandLinks.servicePackage.peugeot'
        )
      }
    }
  }

  ngOnInit(): void {
    // this.brand = 'citroen' // for test
    // this.brand = 'peugeot' // for test
    // this.brand = 'ds' // for test
    if (this.env_brandLinks?.hasBrandLinks === true) {
      this.brandLinks = this.env_brandLinks[this.type][this.brand.toLowerCase()]
      if (this.brandLinks?.[0] != null) {
        this.setHrefLinks()
        this.hasLinks.emit(true)
      } else {
        this.hasLinks.emit(false)
      }
    }
  }

  setHrefLinks(): void {
    this.brandLinks.forEach(link => {
      link.link = this.languageService.getLink(link, this.translate.currentLang)
    })
  }
}
