import { Component, Input, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import {
  User,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGoogleTagmanagerService,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { DataService } from '../../../services/data.service'
import {
  DayTimeDTO,
  DealerDTO,
  WorkingDays
} from '../../../services/dealers.service'
import { SharedService } from '../../../services/shared.service'
import {
  WorkingHoursResponse,
  WorkingHoursService
} from '../../../services/working-hours.service'
import { DealerDetailsPopupComponent } from '../../vehicle-details/dealer-details-popup/dealer-details-popup.component'

@Component({
  selector: 'customer-portal-app-dealer-info',
  templateUrl: './dealer-info.component.html'
})
export class DealerInfoComponent implements OnInit {
  @Input() dealer: DealerDTO
  @Input() showWorkingHours = true
  @Input() showOpeningTimeInContact = false

  public lat = 0
  public lng = 0
  public origin
  public destination
  private center: { lng: number; lat: number }
  zoom = 17
  streetViewControl = false
  zoomControl = false
  service: DayTimeDTO[]
  sales: DayTimeDTO[]

  workingDaysSales: WorkingDays[] = []
  workingDaysService: WorkingDays[] = []

  public user: User
  public day: string
  private dateToday: string
  open = false
  openSales = false
  public currentDate: Date
  openServices = false
  public country: string
  showMap = false
  serviceWorkingHours: WorkingHoursResponse

  constructor(
    private readonly dialogRef: MatDialogRef<DealerDetailsPopupComponent>,
    private readonly userService: UserService,
    private readonly dataService: DataService,
    private readonly workingHoursService: WorkingHoursService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public sharedService: SharedService,
    public transifexService: TransifexService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {}

  ngOnInit(): void {
    this.currentDate = new Date()
    this.country = this.remoteConfigService.get('country.code')
    this.lat = this.dealer.latitude
    this.lng = this.dealer.longitude
    this.service =
      this.dealer.openingHours?.service != null
        ? this.dealer.openingHours.service
        : []
    this.sales =
      this.dealer.openingHours?.sales != null
        ? this.dealer.openingHours.sales
        : []
    if (this.sales.length > 0) {
      this.showHoursSales(this.sales)
      this.openSales = this.calculateIfWorking(
        this.currentDate,
        this.workingDaysSales
      )
    }

    if (this.service.length > 0) {
      this.showHoursService(this.service)
      this.openServices = this.calculateIfWorking(
        this.currentDate,
        this.workingDaysService
      )
    }
    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
    })

    this.serviceWorkingHours = this.workingHoursService.calculateIfWorking(
      this.service,
      this.currentDate,
      'service'
    )

    this.center = {
      lat: this.dealer.latitude,
      lng: this.dealer.longitude
    }

    setTimeout(() => {
      this.showMap = true
    }, 80)
  }

  getDealerString(): string {
    return (
      this.dealer.name.toString() +
      ', ' +
      this.dealer.subname.toString() +
      ' ' +
      this.dealer.efitId.toString()
    )
  }

  sendOutboundLinkData(outboundLinkType: string, dealer: DealerDTO): void {
    this.cptGtmService.sendOutboundData(outboundLinkType, dealer)
  }

  showDirection(): void {
    this.origin = { lat: this.center.lat, lng: this.center.lng }
    this.destination = { lat: this.dealer.latitude, lng: this.dealer.longitude }

    const latLonO =
      (this.user.address[0].street != null ? this.user.address[0].street : '') +
      (this.user.address[0].zip != null ? ',' + this.user.address[0].zip : '') +
      (this.user.address[0].city != null ? ',' + this.user.address[0].city : '')
    const latLonD =
      String(this.destination.lat) + ',' + String(this.destination.lng)
    window.open(
      this.remoteConfigService.get('googleMaps.googleMapsUrl') +
        latLonO +
        '&destination=' +
        latLonD +
        '&travelmode=driving',
      '_blank'
    )
  }

  calculateIfWorking(date: Date, workingDays: WorkingDays[]): boolean {
    if (
      workingDays.find(
        s =>
          s.day.toLowerCase() ===
          date.toLocaleDateString('en', { weekday: 'long' }).toLowerCase()
      ) != null
    ) {
      const today = workingDays.find(
        s =>
          s.day.toLowerCase() ===
          date.toLocaleDateString('en', { weekday: 'long' }).toLowerCase()
      )
      if (today.hours.length === 1) {
        const openTime = today.hours[0].split(' - ')
        const splitO = openTime[0] != null ? openTime[0].split(':') : null
        const splitC = openTime[1] != null ? openTime[1].split(':') : null
        this.day = today.day
        this.dateToday = today.hours[0]

        if (splitO != null && splitC != null) {
          return this.isOpen(splitO, splitC, date)
        } else {
          return false
        }
      }
      if (today.hours.length === 2) {
        const openTime = today.hours[0].split(' - ')
        const splitO = openTime[0].split(':')
        const splitC = openTime[1].split(':')

        const openTime2 = today.hours[1].split(' - ')
        const splitO2 = openTime2[0].split(':')
        const splitC2 = openTime2[1].split(':')

        if (
          (splitO != null && splitC != null) ||
          (splitO2 != null && splitC2 != null)
        ) {
          return (
            this.isOpen(splitO, splitC, date) ||
            this.isOpen(splitO2, splitC2, date)
          )
        } else {
          return false
        }
      }
    } else {
      return false
    }
  }

  isOpen(open, close, date: Date): boolean {
    if (open != null && close != null) {
      const startDate = new Date(date.getTime())
      startDate.setHours(open[0])
      startDate.setMinutes(open[1])
      startDate.setSeconds(0)

      const endDate = new Date(date.getTime())
      endDate.setHours(close[0])
      endDate.setMinutes(close[1])
      startDate.setSeconds(0)

      return startDate < date && endDate > date
    } else {
      return false
    }
  }

  showHoursSales(hours: DayTimeDTO[]): void {
    // remove hours that dont have set day
    hours = this.workingHoursService.removeHoursWithoutDay(hours)

    for (const hour of hours) {
      const hoursSales =
        hour.openTime != null && hour.closeTime != null
          ? hour.openTime + ' - ' + hour.closeTime
          : ''
      if (
        this.workingDaysSales.find(s => s.day === hour.openDay.toLowerCase()) !=
        null
      ) {
        this.workingDaysSales
          .find(s => s.day === hour.openDay.toLowerCase())
          .hours.push(hoursSales)
      } else {
        const workingDay: WorkingDays = {
          day: hour.openDay.toLowerCase(),
          hours: [hoursSales]
        }
        this.workingDaysSales.push(workingDay)
      }
    }
  }

  showHoursService(hours: DayTimeDTO[]): void {
    // remove hours that dont have set day
    hours = this.workingHoursService.removeHoursWithoutDay(hours)

    for (const hour of hours) {
      const hoursService =
        hour.openTime != null && hour.closeTime != null
          ? hour.openTime + ' - ' + hour.closeTime
          : ''

      if (
        this.workingDaysService.find(
          s => s.day === hour.openDay.toLowerCase()
        ) != null
      ) {
        this.workingDaysService
          .find(s => s.day === hour.openDay.toLowerCase())
          .hours.push(hoursService)
      } else {
        const workingDay: WorkingDays = {
          day: hour.openDay.toLowerCase(),
          hours: [hoursService]
        }
        this.workingDaysService.push(workingDay)
      }
    }
  }
}
