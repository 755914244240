import { Component, Input, type OnDestroy, type OnInit, ViewChild } from '@angular/core'
import { type User } from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGoogleTagmanagerService,
  EcondaService,
  MatomoIntegrationService,
  TransifexService,
  ResizeService
} from '@inside-hub-app/customer-portal-shared'
import {
  type AdditionalService,
  type CustomerPortalConfig
} from '@inside-hub-app/customer-portal-config'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { DataService } from '../../../services/data.service'
import { SharedService } from '../../../services/shared.service'
import { NgxGlideComponent } from 'ngx-glide'
import { Router } from '@angular/router'
import { NGXLogger } from 'ngx-logger'
@Component({
  selector: 'customer-portal-app-revolution-services',
  templateUrl: './services.component.html'
})
export class RevolutionServicesComponent implements OnInit, OnDestroy {
  @Input() mode: 'services' | 'summary' = 'services'
  services: AdditionalService[]
  homepageServices: AdditionalService[]
  user: User
  country: string

  @ViewChild('ngxGlideServices') ngxGlide!: NgxGlideComponent
  public screenSizePx: number
  breakpoints
  showArrows = true
  showBullets = true
  bound = false
  fitGlider = false

  sub = {
    onResizePx: null,
    onUserLoaded: null
  }

  constructor (
    private readonly transifexTranslationsService: TranslationService,
    public transifexService: TransifexService,
    private readonly dataService: DataService,
    public sharedService: SharedService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly econdaService: EcondaService,
    public resizeService: ResizeService,
    public router: Router,
    private readonly logger: NGXLogger,

    private readonly matomoIntegrationService: MatomoIntegrationService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.screenSizePx = this.resizeService.screenSizePx
    this.country = this.remoteConfigService.get('country.code')
  }

  ngOnInit (): void {
    this.services =
      this.remoteConfigService.getArrayValues<AdditionalService[]>(
        'additionalServices'
      )

    this.sub.onUserLoaded = this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
    })

    if (this.mode === 'summary') {
      this.homepageServices = this.services.filter(service => {
        return service.visibleOnHomepage === true
      })
      if (this.homepageServices?.[1] != null) {
        this.homepageServices = this.homepageServices.sort(
          (a, b) => a.homepagePosition - b.homepagePosition
        )
      }

      this.sub.onResizePx = this.resizeService.onResizePx.subscribe(size => {
        this.screenSizePx = size
        this.setBreakpoints()
        this.recreate()
      })

      this.setBreakpoints()
      this.recreate()
    }
  }

  sendContentModulesBigTeaserData (
    contentModuleLabel: string,
    text: string
  ): void {
    const label =
      this.sharedService.translateLink(contentModuleLabel) +
        '|' +
        this.sharedService.translateLink(text) ?? ''
    this.cptGtmService.sendContentModulesData(
      'Big Teaser',
      null,
      label,
      'Big Teaser',
      label
    )
    this.econdaService.send({
      content: 'Services',
      Target: ['Big teaser', label, 1, 'd']
    })

    this.matomoIntegrationService.trackEvent(
      'Services',
      'Additional service click',
      this.transifexService.getTransifexTranslation(null, text)
    )
  }

  getTranslation (): string {
    return this.transifexTranslationsService.translate(
      'Welcome to your digital garage!</br> What can we do for you?',
      {
        _key: 'customerPortal.customer-portal.top-bar.additional-services.welcome',
        _tags: 'customer-portal, 3.1'
      }
    )
  }

  getDescriptionTranslation (s): string {
    return this.transifexTranslationsService.translate('Description', {
      _key: s?.descriptionKey,
      _tags: 'customer-portal, notranslate'
    })
  }

  getLinkTranslation (s): string {
    return this.transifexTranslationsService.translate('Link', {
      _key: s?.linkKey,
      _tags: 'customer-portal, notranslate'
    })
  }

  getLinkTextTranslation (s): string {
    return this.transifexTranslationsService.translate('LinkText', {
      _key: s?.linkTextKey,
      _tags: 'customer-portal, notranslate'
    })
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  recreate (): void {
    setTimeout(() => {
      if (this.ngxGlide != null) {
        try {
          this.ngxGlide.recreate()
        } catch (error) {
          // no need to log
        }
      }
    })
  }

  setBreakpoints (): void {
    const articleLength =
      this.homepageServices?.length != null ? this.homepageServices.length : 0
    if (articleLength >= 0 && articleLength < 3) {
      switch (articleLength) {
        case 0:
        case 1:
          this.showBullets = false
          this.showArrows = false
          this.bound = true
          this.fitGlider = true

          this.breakpoints = {
            1024: { perView: 1, focusAt: 'center' },
            1280: { perView: 1, focusAt: 'center' },
            3840: { perView: 1, focusAt: 'center' }
          }
          break
        default:
          if (this.screenSizePx < 1024) {
            // small screen
            this.defaultBreakpoints()
            this.showBullets = true
            this.showArrows = true
            this.bound = false
            this.fitGlider = false
          } else {
            this.defaultBreakpoints()
            this.showBullets = false
            this.showArrows = false
            this.bound = true
            this.fitGlider = true
          }
          break
      }
      return
    }

    this.defaultBreakpoints()
    this.showBullets = true
    this.showArrows = true
    this.bound = false
    this.fitGlider = false
  }

  defaultBreakpoints (): void {
    this.breakpoints = {
      1024: { perView: 1, focusAt: 'center' },
      1280: { perView: 2, focusAt: 0 },
      3840: { perView: 2, focusAt: 0 }
    }
  }

  goToServices (): void {
    this.router.navigate(['/services']).catch(err => {
      this.logger.log(err)
    })
    this.sharedService.scrollToTop()
  }

  goToLink (service, ev): void {
    this.sharedService.preventEventPropagation(ev)
    const url: string = this.getLinkTranslation(service)
    window.open(url, '_blank')
  }
}
