import { Injectable } from '@angular/core'
import { VehicleDTOExtended } from '../interfaces/shared'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
declare global {
  interface Window {
    emos3: any
  }
}

export interface EcondaTag {
  /**
   * Page names (Content labels)
   * Unique identifier for the content of the current web page. By entering slashes (“/”) the website context, i.e. the path, can be transferred too and then at a later point be evaluated in summarized form.
   * Example: "Home page"
   */
  content?: string
  /**
   * Search: internal search strings. Example: [“washing machine”, “Netbooks”]
   */
  search?: string[]
  Target?: [string, string, number, string]
  /**
   * ['TARGETGROUP', 'TARGETNAME', TARGETVALUE, 'TARGETRULE']
   * Example:  Target:  ['newsletter subscription', 'product news', 1, 's']
   *
   * TARGETGROUP: Group of targets. Superordinate target group description, example: "Events/2015"
   * TARGETNAME: Name of target. Name of target, as to be displayed in econda Analytics. example: "Registration Summer Contest"
   * TARGETVALUE: Value of target. Conversion value of target.
   *    1 for a target with regular value.

        2 for a target with double value compared to a target with regular value.

        0.5 for a target with half of the value of a regular target.

   * TARGETRULE: Key of the calculation rule to be used. One of the following values:

            d: Use the default calculation rule, defined in the Configuration.

            s: Count targets no more than one time per visit (‘s’ession).

            a: Count targets several times per visit (‘a’ll)
   */
}

@Injectable({
  providedIn: 'root'
})
export class EcondaService {
  hasEconda: boolean
  constructor(
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasEconda = this.remoteConfigService.get('webTracking.econda')
  }

  formatVehicleData(v: VehicleDTOExtended): string {
    const vin = v.vin
    const licencePlate = v.licensePlate
    const firstRegistration = v.firstRegistration
    const data = {
      vin,
      licencePlate,
      firstRegistration
    }
    return JSON.stringify(data)
  }

  send(econdaTag: EcondaTag): void {
    if (!this.hasEconda) {
      return
    }
    window.emos3.send(econdaTag)
  }
}
