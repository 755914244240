import { ChangeDetectorRef, Component, type OnDestroy, type OnInit } from '@angular/core'
import { ActivatedRoute, type Params, Router } from '@angular/router'
import { type User } from '@inside-hub-app/customer-portal-b2c-client'
import {
  type CustomerPortalConfig,
  type Navigation
} from '@inside-hub-app/customer-portal-config'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { type ParsedToken } from '../../../../models/keycloak'
import { DataService } from '../../../services/data.service'
import {
  ResizeService
} from '@inside-hub-app/customer-portal-shared'
import { SharedService, type Tab } from '../../../services/shared.service'

@Component({
  selector: 'customer-portal-app-revolution-my-account-view',
  templateUrl: './my-account-view.component.html'
})
export class MyAccountViewComponent implements OnInit, OnDestroy {
  error = false
  token: ParsedToken
  user: User
  public country: string

  currentTabName: Tab
  urlParams: Params

  sub = {
    onResizePx: null
  }

  public screenSizePx: number
  showMobileView: boolean
  hasNotifications
  swipeData = {
    currentTab: null,
    swipeCoord: null,
    swipeTime: null,
    tabsCount: null
  }

  tabs = {
    0: 'personal-data',
    1: 'dataprotection',
    2: 'user-personalization',
    3: 'user-notifications'
  }

  navigationItems
  hasNewGDPR
  constructor (
    private readonly keycloak: KeycloakService,
    private readonly route: ActivatedRoute,
    private readonly logger: NGXLogger,
    private readonly dataService: DataService,
    public resizeService: ResizeService,
    public sharedService: SharedService,
    private readonly cdf: ChangeDetectorRef,
    private readonly router: Router,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.navigationItems =
    this.remoteConfigService.getArrayValues<Navigation[]>('navigationAccount')
    this.hasNewGDPR = this.remoteConfigService.get('hasNewGDPR')

    route.params.subscribe(params => {
      this.urlParams = params
      this.switchRoute(this.urlParams)
    })
  }

  scrollToTop (): void {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
    this.logger.debug('Scrolled to top')
  }

  ngOnInit (): void {
    this.token = this.keycloak.getKeycloakInstance().tokenParsed as ParsedToken
    this.country = this.remoteConfigService.get('country.code')

    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user != null ? user : null
    })

    this.sub.onResizePx = this.resizeService.onResizePx.subscribe(size => {
      this.screenSizePx = size
      this.isMobileView()
    })

    this.screenSizePx = this.resizeService.screenSizePx
    this.isMobileView()
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  isMobileView (): void {
    if (this.screenSizePx > 0) {
      if (this.screenSizePx < 1024) {
        this.showMobileView = true
        this.setMobileTabs()
      } else {
        this.showMobileView = false
      }
      this.cdf.detectChanges()
    }
  }

  switchRoute (urlParams: Params): void {
    if (urlParams.detailsSubmenu != null) {
      const param = this.urlParams.detailsSubmenu

      switch (param) {
        case 'user-personalization':
          this.currentTabName = 'myAccountUserPersonalization'
          this.swipeData.currentTab = 2
          break
        case 'dataprotection':
          this.currentTabName = 'myAccountDataProtection'
          this.swipeData.currentTab = 1
          break
        case 'user-notifications':
          this.currentTabName = 'myAccountUserNotifications'
          this.swipeData.currentTab = 3
          break
        default:
          // this.scrollToTop()
          this.currentTabName = 'myAccountPersonalData'
          this.swipeData.currentTab = 0
      }
    } else {
      this.scrollToTop()
      this.currentTabName = 'myAccountPersonalData'
      this.swipeData.currentTab = 0
    }
  }

  setMobileTabs (): void {
    const tabsLength = this.navigationItems.length
    const tabValues = this.navigationItems
    this.swipeData.tabsCount = tabsLength
    if (
      tabValues.find(el => {
        return el.path === '/user/user-notifications'
      }) != null
    ) {
      this.hasNotifications = true
    } else {
      this.hasNotifications = false
    }
  }

  swipe (e: TouchEvent, when: string): void {
    const currTab = this.swipeData.currentTab
    this.sharedService.tabSwipe(e, when, this.swipeData)
    if (currTab !== this.swipeData.currentTab) {
      // change route
      const tab = this.tabs[this.swipeData.currentTab]
      void this.router.navigate(['/user', tab])
    }
  }
}
