/* eslint-disable accessor-pairs */
/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectorRef, Component, type OnDestroy, type OnInit } from '@angular/core'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { type Expiration, InsuranceService, type VehicleInsuranceDTO, type VehicleInsurancePolicyDTO } from '../../../services/insurance.service'
import { CptGoogleTagmanagerService } from '../../../services/cpt-google-tagmanager.service'
import { TransifexService } from '../../../services/transifex.service'
import { SharedService } from '../../../services/shared.service'
import { Subscription } from 'rxjs'
import { TranslationService } from '@emilfreydigital/transifex-angular'

@Component({
  selector: 'customer-portal-app-insurance-molecule',
  templateUrl: './insurance-molecule.component.html'
})
export class InsuranceMoleculeComponent implements OnInit, OnDestroy {
  hasInsurance
  showEflexButtons
  requestInsuranceLink
  countryCode: string

  loading
  policies: VehicleInsurancePolicyDTO[]
  policyIndex
  expiration: Expiration
  insuranceIsDisabled = true
  downloadStarted = false
  private readonly subscriptions = new Subscription()
  constructor (
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>,
    private readonly insuranceService: InsuranceService,
    private readonly sharedService: SharedService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public transifexService: TransifexService,
    private readonly translationService: TranslationService
  ) {
    this.hasInsurance = this.remoteConfigService.get('hasInsurance')
    this.showEflexButtons = this.remoteConfigService.get('insurance.showEflexButtons')
    this.requestInsuranceLink = this.remoteConfigService.get('requestInsuranceLink')
    this.countryCode = this.remoteConfigService.get('country.code')
  }

  ngOnInit (): void {
    this.setDisabled()
    const onInsuranceLoaded = this.insuranceService.onInsuranceLoaded.subscribe((insurance: VehicleInsuranceDTO) => {
      this.policies = insurance?.policies
      // sort by endDate desc
      this.sharedService.orderArrayByDate(this.policies, 'endDate')
      this.setDisabled()
      this.calcExpiration()
    })
    this.subscriptions.add(onInsuranceLoaded)
    const loadingInsurance = this.insuranceService.loadingInsurance.subscribe(loading => {
      this.loading = loading
    })
    this.subscriptions.add(loadingInsurance)
  }

  ngOnDestroy (): void {
    this.subscriptions.unsubscribe()
  }

  setDisabled (): void {
    if (
      this.hasInsuranceData()
    ) {
      this.insuranceIsDisabled = false
    } else {
      this.insuranceIsDisabled = true
    }
    this.cdRef.detectChanges()
  }

  hasInsuranceData (): boolean {
    return this.policies?.length > 0
  }

  hasOnePolicy (): boolean {
    return this.policies?.length === 1
  }

  hasActiveInsurance (): boolean {
    return this.expiration?.days > 0
  }

  hasInsuranceWithEndDate (): boolean {
    return this.policies?.[0]?.endDate != null
  }

  hasRequestInsuranceLink (): boolean {
    return this.sharedService.stringExists(this.requestInsuranceLink)
  }

  openRequestInsuranceLink (): void {
    window.open(this.requestInsuranceLink, '_blank')
  }

  calcExpiration (): void {
    if (this.hasInsuranceData() && this.hasInsuranceWithEndDate()) {
      this.expiration = this.insuranceService.calculateTillExpires(this.policies[0])
    }
  }

  styleProgressBar (): string {
    const tillExpires = this.expiration?.days
    let style = 'warn-red'
    if (tillExpires > 0) {
      style = 'ok-green'
    }
    return style
  }

  sendContentModulesData (expanded: boolean, contentModuleLabel: string): void {
    const translation = this.transifexService.getTransifexTranslation(contentModuleLabel, contentModuleLabel)
    this.cptGtmService.sendContentModulesData(
      'Accordion',
      expanded ? 'Open' : 'Close',
      translation ?? '',
      `Accordion|${translation ?? ''}`,
      expanded ? 'Open' : 'Close'
    )
  }

  getPDF (
    insurance: VehicleInsurancePolicyDTO,
    mode: 'download' | 'open',
    docType?: 'DOC_OFFER' | 'DOC_PRODUCT' | 'DOC_PRIVACY'
  ): void {
    this.downloadStarted = true
    if (insurance.token != null || docType != null) {
      this.sharedService.showSnackbar(
        'customerPortal.customer-portal.please-wait-loading-data',
        null,
        0
      )
      this.insuranceService.getInsurancePDF({
        token: insurance.token,
        offerId: insurance.offerId,
        docType,
        policyNumber: insurance.policyNo
      }).subscribe(
        response => {
          this.sharedService.closeSnackbar()
          this.downloadStarted = false
          if (response != null) {
            const preview = URL.createObjectURL(response)
            if (mode === 'open') {
              this.sharedService.openPreviewPopup(preview, 'pdf')
            } else {
              const name = this.sharedService.stringExists(insurance.policyNo)
                ? insurance.policyNo + '.pdf'
                : 'insurance.pdf'
              this.sharedService.downloadFile(preview, name)
            }
          } else {
            this.showErrorMessage(insurance.policyNo)
          }
        },
        (error) => {
          this.showErrorMessage(insurance.policyNo, error)
          this.downloadStarted = false
        }
      )
    }
  }

  showErrorMessage (policyNo, response?): void {
    let translation = ''
    if (response?.error?.code === 204 || response?.statusCode === 204) {
      translation = this.translationService.translate(
        'There is no insurance document for insurance { policyNo }',
        {
          _key: 'customerPortal.customer-portal.vehicle.insurance.no-pdf-content',
          _tags: 'customer-portal, 3.11',
          policyNo
        }
      )
    } else {
      translation = this.translationService.translate(
        'There has been a problem downloading insurance documents, please try again later.',
        {
          _key: 'customerPortal.customer-portal.vehicle.insurance.download-pdf-error',
          _tags: 'customer-portal, 3.11'
        }
      )
    }

    this.sharedService.showSnackbar(
      null,
      translation
    )
  }
}
