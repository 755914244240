import { type ElementRef, Injectable } from '@angular/core'
import { PwaService } from './pwa.service'
import { TranslateService } from '@ngx-translate/core'
import { type RevolutionLink } from '@inside-hub-app/customer-portal-config'
import moment from 'moment'
import { MatSnackBar } from '@angular/material/snack-bar'
import { SnackbarComponent } from '../components/revolution/snackbar/snackbar.component'
import { type hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { Subject } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { NGXLogger } from 'ngx-logger'
import { FilePreviewComponent } from '../components/revolution/file-preview/file-preview.component'
export type GenericObject = Record<string, string | boolean | number | object>
export type ContactDocumentFormatEnum =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.ContactDocumentFormatEnum

export interface PhoneMask {
  mask?: string
  prefix?: string
  number?: string
}
export interface TransifexTranslationObj {
  str: string
  key: string
}
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  snackBarRef
  private readonly _filePreviewOpened = new Subject<boolean>()
  onFilePreviewOpened = this._filePreviewOpened.asObservable()
  constructor (
    private readonly pwaService: PwaService,
    private readonly translation: TranslateService,
    public dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly logger: NGXLogger
  ) {}

  isNonNull<T>(value: T): value is NonNullable<T> {
    return value !== null
  }

  stringExists (string: string): boolean {
    return Boolean(
      string != null && typeof string === 'string' && string.trim() !== ''
    )
  }

  splitOnLastOccurrence (str, substring): null | [string, string] {
    if (this.stringExists(str) && this.stringExists(substring)) {
      const lastIndex: number = str.lastIndexOf(substring)
      const before = str.slice(0, lastIndex)
      const after = str.slice(lastIndex + 1)
      return [before, after]
    }
    return null
  }

  isMobilePwa (): boolean {
    const isPwa = this.pwaService.checkPwa()
    const isMobile = this.pwaService.checkMobile()
    return isPwa && isMobile
  }

  isMobile (): boolean {
    return this.pwaService.checkMobile()
  }

  currentLanguage (): string {
    return this.translation.currentLang != null
      ? this.translation.currentLang?.toLowerCase()
      : this.translation.defaultLang?.toLowerCase() ?? 'de'
  }

  getLink (link: RevolutionLink): string {
    const language = this.currentLanguage()
    if (language != null) {
      const index = `urls.${language}`
      return link[index]
    }
    return ''
  }

  isNumber (evt: KeyboardEvent): boolean {
    const char = evt.key
    if (/[0-9]/.test(char)) {
      return true
    }
    return false
  }

  dateExists (date: Date | string): boolean {
    try {
      let dateObj
      if (typeof date === 'string') {
        if (this.stringExists(date)) {
          dateObj = new Date(date)
        } else {
          return false
        }
      } else {
        dateObj = date
      }

      return dateObj instanceof Date && !isNaN(dateObj.valueOf())
    } catch (error) {
      return false
    }
  }

  orderArrayByDate (array: any, property: string, order?: 'desc' | 'asc'): void {
    array?.sort((a, b) => {
      let one
      if (this.dateExists(a[property])) {
        one = moment(a[property]).toDate().getTime()
      }

      let two
      if (this.dateExists(b[property])) {
        two = moment(b[property]).toDate().getTime()
      }

      if ((one > 0) && (two > 0)) {
        if (order === 'asc') {
          return one - two
        } else {
          return two - one
        }
      }

      if ((one > 0) && !(two > 0)) {
        if (order === 'asc') {
          return 1
        } else {
          return -1
        }
      }

      if (!(one > 0) && (two > 0)) {
        if (order === 'asc') {
          return -1
        } else {
          return 1
        }
      }

      return 0
    })
  }

  public preventEventPropagation (ev: Event): boolean {
    try {
      ev.stopPropagation()
      ev.preventDefault()
      ev.returnValue = false
    } catch (error) {
      // no need to show log
    }
    return false
  }

  downloadFile (url: string, name: string): void {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = url
    a.download = name
    a.target = '_blank'
    a.click()
  }

  /**
   *
   * @param translationKey
   * @param text
   * @param duration seconds, 0 - wont close automatically
   */
  showSnackbar (
    translationKey: string | string[],
    text: string,
    duration?: number
  ): void {
    this.closeSnackbar()
    const data: Record<string, unknown> = {
      text,
      action: true
    }
    if (Array.isArray(translationKey)) {
      data.translationKeyArray = translationKey
    } else {
      data.translationKey = translationKey
    }
    this.snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
      panelClass: 'ef-snackbar',
      data,
      duration: (duration >= 0 ? duration : 5) * 1000
    })
    this.snackBarRef.afterDismissed().subscribe(() => {
      this.snackBarRef = undefined
    })
  }

  closeSnackbar (): void {
    try {
      this.snackBarRef.dismiss()
    } catch (error) {
      // no need to show log
    }
  }

  openPreviewPopup (preview, format): void {
    this._filePreviewOpened.next(true)
    const dialogRef = this.dialog.open(FilePreviewComponent, {
      data: {
        preview,
        format
      },
      panelClass: ['mat-dialog-cpt', 'file-preview']
    })

    dialogRef.afterClosed().subscribe(result => {
      this._filePreviewOpened.next(false)
      this.logger.debug(result)
    })
  }

  isLocalhost (): boolean {
    return window?.location?.origin?.includes('localhost:4200')
  }

  public setPhoneMask (prefix, currentNumber?: string): string {
    /*
    0 digits (like 0 to 9 numbers)
    9 digits (like 0 to 9 numbers), but optional
    A letters (uppercase or lowercase) and digits
    S only letters (uppercase or lowercase)
    U only letters uppercase
    L only letters lowercase
    */
    let phoneMask
    switch (prefix) {
      case '+41':
        phoneMask = '00 000 00 00'
        if (this.stringExists(currentNumber)) {
          if (currentNumber.startsWith('0')) {
            phoneMask = '000 000 00 00'
          }
        }
        break
    }
    return phoneMask
  }

  getTelephoneMask (
    phone: string,
    prefixLength: number,
    comparePrefix
  ): PhoneMask {
    const prefix = phone.substring(0, prefixLength)
    if (prefix === comparePrefix) {
      const mask = this.setPhoneMask(prefix)
      if (this.stringExists(mask)) {
        return {
          mask,
          prefix,
          number: phone.substring(prefixLength, phone.length)
        }
      }
    }
    return undefined
  }

  setFocus (el: ElementRef): void {
    setTimeout(function () {
      try {
        el.nativeElement.focus()
      } catch (error) {
        // no need to show log
      }
    }, 10)
  }
}
