import { Injectable, TemplateRef } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class TemplateStoreService {
  private readonly templates: Map<
    string,
    TemplateRef<Record<string, unknown>>
  > = new Map<string, TemplateRef<Record<string, unknown>>>()

  public add(
    key: string,
    template: TemplateRef<Record<string, unknown>>
  ): void {
    // The argument validation is omitted for brevity
    this.templates.set(key, template)
  }

  public get(key: string): TemplateRef<Record<string, unknown>> {
    return this.templates.get(key)
  }
}
