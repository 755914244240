import { Pipe, PipeTransform } from '@angular/core'
import { TransifexService } from '../services/transifex.service'

@Pipe({
  name: 'sharedTranslate',
  pure: false // it will execute on every cycle
})
export class SharedTranslatePipe implements PipeTransform {
  constructor(public transifexService: TransifexService) {}

  /**
   *
   * - SALUTATION:
   * 'salutation-ms'
   * 'salutation-mrs'
   * 'salutation-mr'
   * 'salutation-mr_mrs'
   * 'salutation-family'
   * 'salutation-gentlemen'
   * 'salutation-dealership'
   * 'salutation-lawfirm'
   * 'salutation-company'
   * 'salutation-undefined'
   *
   *
   * - TITLE:
   * 'title-prof_dr'
   * 'title-dr'
   * 'title-dipl_ing'
   * 'title-mba'
   * 'title-architect'
   * 'title-lawyer'
   * 'title-reverend'
   * 'title-prof'
   *
   *
   * - ERROR:
   * 'required-field'
   * 'only-numbers-allowed'
   * 'only-numbers-letters-allowed'
   * 'enter-valid-email'
   * 'invalid-number'
   * 'current-number'
   * 'max-length-characters'
   * 'required-length-characters'
   * 'maximum-length-exceeded'
   * 'min-password-length'
   * 'password-lowercase-required'
   * 'password-uppercase-required'
   * 'password-number-required'
   * 'password-invalid'
   * 'password-notsame'
   * 'invalid-characters'
   * 'general-error-message'
   * 'this-field-required'
   * 'error'
   * 'address-validation-failed'
   * 'confirmation-code-invalid'
   * 'enter-mobile-number'
   *
   *
   * - USER:
   * 'last-name'
   * 'first-name'
   * 'e-mail'
   * 'email'
   * 'postcode'
   * 'city'
   * 'address'
   * 'password'
   * 'address-suffix'
   * 'mobile-number'
   * 'number'
   * 'username'
   * 'mail'
   * 'post'
   * 'date-of-birth'
   * 'prefix'
   * 'customer-number'
   *
   *
   * - ACTIONS:
   * 'next'
   * 'back'
   * 'forward'
   * 'edit'
   * 'success'
   * 'successful'
   * 'clear'
   * 'delete'
   * 'save'
   * 'cancel'
   * 'update'
   *
   *
   * - FOOTER:
   * 'emil-frey-select'
   * 'alternative-motorisations'
   * 'appointment'
   * 'other-companies'
   * 'vacancies'
   * 'insurance'
   * 'financing'
   * 'online-car-dealership'
   * 'emil-frey-autoteilewelt'
   * 'emil-frey-logistic'
   * 'career'
   * 'emil-frey-fleet'
   * 'commercial-customers'
   * 'emil-frey-financial-services'
   * 'emil-frey-insurance-service'
   *
   *
   * - MENU:
   * 'actions'
   * 'configurator'
   * 'car-purchase'
   * 'electromobility'
   * 'test-drive'
   * 'service-accessories'
   * 'locations'
   * 'emil-frey-about-us'
   * 'shop'
   * 'find-car'
   * 'conditions-of-sale-title'
   * 'conditions-of-sale-notice'
   * 'guarantee-service-title'
   * 'guarantee-service-notice'
   * 'co2-emissions-title'
   * 'co2-emissions-notice'
   * 'gasoline-equivalent-title'
   * 'gasoline-equivalent-notice'
   *
   *
   * 'brand'
   * 'model'
   * 'emil-frey-customer-portal'
   * 'customer-portal'
   * 'emil-frey-digital-services'
   * 'emil-frey-group'
   * 'my-emil-frey-customer-portal'
   * 'germany'
   * 'deutschland'
   * 'de'
   * 'switzerland'
   * 'schweiz'
   * 'ch'
   * 'german'
   * 'data-protection'
   * 'service'
   * 'customer-portal-request'
   * 'warranty'
   * 'register'
   * 'salutation'
   * 'title'
   * 'dealer'
   * 'communication'
   * 'country'
   * 'preferences'
   * 'contact'
   * 'my-account'
   * 'sale'
   * 'thank-you'
   * 'address-suggestion'
   * 'favorites'
   * 'customer-service-advisor'
   * 'personal'
   * 'type'
   * 'fill'
   * 'consent-channels-message'
   * 'general-info'
   * 'other'
   * 'documents'
   * 'e-mail-confirmed'
   * 'vehicle-attention'
   * 'preffered-dealer'
   * 'impressum'
   * 'account-delete'
   * 'my-marketplace'
   * 'my-vehicles'
   * 'newsletter-subscribe'
   */
  transform(key: string, ...args: unknown[]): string {
    return this.transifexService.getSharedTranslation(key, args?.[0])
  }
}
