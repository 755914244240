<div
  class="cpt-modal text-mat-ef-revolution-primary-500 relative mat-dialog-actions-pb-20 ef-scrollbar"
>
  <h2 class="font-canelablack text-4x" mat-dialog-title>
    <T
      str="Request a callback"
      key="customerPortal.customer-portal.support.callrequest.title"
      tags="customer-portal, 3.1"
    ></T>
  </h2>

  <!--
    <mat-divider></mat-divider>
    -->

  <mat-dialog-content class="py-0.5">
    <div class="font-efSans">
      <p class="text-mat-ef-revolution-secondary-500 text-xl font-bold">
        <T
          str="Our contact person will get in touch with you promptly."
          key="customerPortal.customer-portal.support.callrequest.subtitle"
          tags="customer-portal, 3.1"
        ></T>
      </p>
      <p class="text-mat-ef-revolution-secondary-500">
        <T
          str="Please check your phone number and confirm the callback request. If the desired contact person is not available at short notice, another contact person will contact you."
          key="customerPortal.customer-portal.support.callrequest.description"
          tags="customer-portal, 3.1"
        ></T>
      </p>

      <div
        class="mb-1 mt-5 text-mat-ef-revolution-primary-500 text-xl font-bold"
      >
        <T
          str="Your phone number"
          key="customerPortal.customer-portal.support.callrequest.yournumber"
          tags="customer-portal, 3.1"
        ></T>
      </div>
    </div>

    <form [formGroup]="callRequestContactForm" class="form call-request-form">
      <div
        class="w-full flex flex-col md:flex-row gap-5 md:gap-0 items-start md:items-center justify-between rounded bg-mat-ef-revolution-grey-500 my-5 mx-0 p-2.5 box-border"
      >
        <div class="phone-number-placeholder">
          <mat-icon svgIcon="phone"></mat-icon>
          <input
            matInput
            type="tel"
            required
            readonly
            class="min-w-[50px] w-auto"
            formControlName="phoneNumber"
          />
        </div>

        <ng-container>
          <button
            mat-button
            mat-stroked-button
            color="secondary"
            class="revolution btn-dialog"
            (click)="changePhoneNumber()"
          >
            <mat-icon svgIcon="create"></mat-icon>
            {{ 'edit' | sharedTranslate }}
          </button>
        </ng-container>
      </div>
      <div class="time-of-day">
        <mat-form-field
          class="w-full hidden md:inline-block"
          appearance="legacy"
        >
          <mat-label>
            <T
              str="When is the best time to reach you?"
              key="customerPortal.customer-portal.support.callrequest.when"
              tags="customer-portal, 3.1"
            ></T>
          </mat-label>
          <mat-select
            data-cy="request-callback-timeOfDay-select"
            required
            formControlName="timeOfDay"
          >
            <mat-option *ngFor="let d of timesOfDay" [value]="d">
              <ng-container
                *ngTemplateOutlet="
                  sharedService.template('transifexTranslation');
                  context: {
                    str: d,
                    prefix:
                      'customerPortal.customer-portal.support.callrequest.timeofday.'
                  }
                "
              ></ng-container>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="inline-block md:hidden w-full"
          appearance="legacy"
        >
          <mat-label>
            <T
              str="When is the best time to reach you?"
              key="customerPortal.customer-portal.support.callrequest.when"
              tags="customer-portal, 3.1"
            ></T>
          </mat-label>
          <select matNativeControl required formControlName="timeOfDay">
            <option *ngFor="let d of timesOfDay" [value]="d">
              <ng-container
                *ngTemplateOutlet="
                  sharedService.template('transifexTranslation');
                  context: {
                    str: d,
                    prefix:
                      'customerPortal.customer-portal.support.callrequest.timeofday.'
                  }
                "
              ></ng-container>
            </option>
          </select>
        </mat-form-field>
      </div>
      <div class="mt-2.5">
        <mat-form-field class="w-full" appearance="legacy">
          <mat-label
            ><T
              str="Your message"
              key="customerPortal.customer-portal.support.callrequest.yourmessage"
              tags="customer-portal, 3.1"
            ></T
          ></mat-label>
          <textarea
            data-cy="request-callback-message"
            cdkTextareaAutosize
            matInput
            cdkAutosizeMinRows="4"
            maxlength="1000"
            formControlName="message"
          ></textarea>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <mat-divider></mat-divider>

  <mat-dialog-actions>
    <ng-container>
      <button class="dialog-close-button" [mat-dialog-close]="false">
        <mat-icon svgIcon="close"></mat-icon>
      </button>

      <button
        data-cy="request-callback-submit"
        mat-button
        mat-flat-button
        color="secondary"
        class="revolution btn-dialog"
        cdkFocusInitial
        [disabled]="saving || !callRequestContactForm.valid"
        (click)="onSubmitCallRequestForm()"
      >
        <mat-spinner *ngIf="saving" diameter="20"></mat-spinner>
        <T
          str="Send"
          key="customerPortal.customer-portal.leasing.contact.send"
          tags="customer-portal, 3.1"
        ></T>
      </button>
    </ng-container>
  </mat-dialog-actions>
</div>
