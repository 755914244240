<div class="vehicle-boxes-rounded px-4">
  <p class="font-efSans text-xl font-bold mb-2 px-2">
    <T
      str="Best fuel prices nearby"
      key="customerPortal.customer-portal.fuel-price-comparison.title"
      tags="customer-portal, 3.3"
    ></T>
  </p>

  <ng-container *ngIf="fuelPrices?.[0]">
    <div class="flex flex-col">
      <div *ngFor="let price of fuelPrices; last as last; first as first">
        <div
          (click)="openMap(price)"
          class="flex flex-col justify-between gap-0.5 flex-1 cursor-pointer hover:bg-mat-ef-revolution-secondary-50 p-2 rounded"
        >
          <div class="flex justify-between gap-2">
            <div class="flex gap-2 max-w-[calc(100%-60px)]">
              <div
                matTooltip="{{ getTranslation(price) }}"
                [ngClass]="styleBlock(price)"
                class="rounded-xl m-auto"
              ></div>
              <div
                class="flex-1 max-w-[calc(100%-20px)] text-base font-medium leading-5 break-words"
              >
                {{ price.name }}
              </div>
            </div>
            <div class="text-lg font-semibold text-end leading-none flex-1">
              {{
                price.price | currency: currency:'symbol':'1.2-2':currencyLocale
              }}
            </div>
          </div>

          <div class="text-xs">
            {{ price.street }} {{ price.zip }} {{ price.city }}
          </div>
        </div>
        <mat-divider *ngIf="!last" class="mx-1"></mat-divider>
      </div>
    </div>
  </ng-container>
</div>
