import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core'
import { Router } from '@angular/router'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { DataService } from '../../../../services/data.service'
import { SharedService } from '../../../../services/shared.service'
@Component({
  selector: 'customer-portal-app-user-notice-card',
  templateUrl: './user-notice-card.component.html'
})
export class UserNoticeCardComponent implements OnInit, OnDestroy {
  @Input() mode = 'summary'
  @Output() showUserNoticeCard = new EventEmitter<boolean>()

  userDataMissing = false
  hideUserNotice = false
  public showMissingUserDataNotification

  sub = {
    onUserLoaded: null,
    onHideUserNotice: null
  }

  user: User
  constructor(
    public sharedService: SharedService,
    private readonly dataService: DataService,
    private readonly router: Router,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.showMissingUserDataNotification = this.remoteConfigService.get(
      'notifications.missingUserData'
    )
  }

  ngOnInit(): void {
    this.sub.onUserLoaded = this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
      this.checkUserData()
      this.setShowUserNoticeCard()
    })
    this.sub.onHideUserNotice = this.dataService.onHideUserNotice.subscribe(
      hide => {
        this.hideUserNotice = hide
        this.setShowUserNoticeCard()
      }
    )
  }

  ngOnDestroy(): void {
    this.sharedService.unsubscribe(this.sub)
  }

  goToUserDetails(): void {
    void this.router.navigate(['/user'])
  }

  clearNotice(ev: Event): void {
    this.sharedService.preventEventPropagation(ev)
    this.dataService.hideUserNotice(true)
  }

  checkUserData(): void {
    this.userDataMissing = false

    const requiredFields = [
      'firstName',
      'lastName',
      'email',
      'telephone',
      'address'
    ]
    requiredFields.forEach(field => {
      if (this.user?.[field] == null) {
        this.userDataMissing = true
      }
    })

    if (!this.userDataMissing) {
      for (const [key, value] of Object.entries(this.user)) {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (key === ('firstName' || 'lastName' || 'email')) {
          if (!this.valueExists(value)) {
            this.userDataMissing = true
            break
          }
        }

        if (key === 'telephone') {
          let hasMobile = false
          if (this.user?.telephone?.[0] != null) {
            for (const phone of this.user.telephone) {
              if (phone.type === 'mobile' && this.valueExists(phone.number)) {
                hasMobile = true
              }
            }
          }
          if (!hasMobile) {
            this.userDataMissing = true
            break
          }
        }

        if (key === 'address') {
          let hasAddress = false
          if (this.user?.address?.[0] != null) {
            for (const address of this.user.address) {
              if (
                this.valueExists(address.city) &&
                this.valueExists(address.street) &&
                this.valueExists(address.streetNumber) &&
                this.valueExists(address.zip)
              ) {
                hasAddress = true
              }
            }
          }
          if (!hasAddress) {
            this.userDataMissing = true
            break
          }
        }
      }
    }
  }

  valueExists(value): boolean {
    return value != null && value !== ''
  }

  setShowUserNoticeCard(): void {
    if (
      this.userDataMissing &&
      !this.hideUserNotice &&
      this.showMissingUserDataNotification
    ) {
      this.showUserNoticeCard.next(true)
    } else {
      this.showUserNoticeCard.next(false)
    }
  }
}
