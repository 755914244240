import { Injectable } from '@angular/core'
import { createMessenger, type Observable, type v1 } from '@userlike/messenger'
import { from } from 'rxjs'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { SharedService } from './shared.service'

@Injectable({
  providedIn: 'root'
})
export class ChatBotService {
  apiLoaded: Observable<v1.Api>

  private readonly webWidget: string

  constructor(
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>,
    private readonly sharedService: SharedService
  ) {
    this.webWidget =
      this.remoteConfigService.get('chatBot.webWidget')
  }

  loadChatBot() {
    this.apiLoaded = from(this.createApi().then(this.mount).then(this.logout))
  }

  async createApi(): Promise<v1.Api> {
    const result = await createMessenger({
      version: 1,
      widgetKey: this.webWidget
    })

    if (result.kind === 'error') throw new Error(result.error)

    const { api } = result.value

    if (api === null) {
      throw new Error(
        'api reached end-of-life, please check documentation and upgrade.'
      )
    }

    api.state$.subscribe({
      next: (state) => {
        const minimize = function minimizeFun() {
          try {
            if (state.state === 'maximized') {
              api.minimize()
              window.parent.removeEventListener('click', minimize, false)
            }
          } catch (error) {
          }
        }
        if (state.state === 'maximized') {
          this.hideUserLikeIcon()
          // set up click event to close iframe when clicked outside
          window.parent.addEventListener('click', minimize, false)
        }
      }
    })
    return api
  }

  async mount(api: v1.Api): Promise<v1.Api> {
    const result = await api.mount()
    if (result.kind === 'error') {
      if (!this.sharedService.isLocalhost()) {
        throw new Error(result.error)
      } else {
        console.log(result.error)
      }
    }
    return api
  }

  async visibility(api: v1.Api, visible: boolean): Promise<v1.Api> {
    const result = await api.setVisibility({
      button: visible,
      main: visible,
      notifications: visible
    })
    if (result.kind === 'error') {
      if (!this.sharedService.isLocalhost()) {
        throw new Error(result.error)
      } else {
        console.log(result.error)
      }
    }
    return api
  }

  async logout(api: v1.Api): Promise<v1.Api> {
    const result = await api.logout()
    if (result.kind === 'error' && !this.sharedService.isLocalhost()) throw new Error(result.error)
    return api
  }

  hideUserLikeIcon(): void {
    // try it also in timeout since it sometimes wont work without 
    this.hideUserLikeIconFun()
    setTimeout(() => {
      this.hideUserLikeIconFun()
    })
  }
  hideUserLikeIconFun(): void {
    const iframe: HTMLIFrameElement = document?.querySelector('iframe[title="Messenger"]')
    const icon = iframe?.contentWindow?.document.querySelector('a[title="Powered by Userlike"]')
    if (icon) {
      icon.setAttribute('style', 'display: none')
    }
  }
}
