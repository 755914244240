<div
  class="w-full min-h-[60px] h-fit relative ef-scrollbar document-preview"
  *ngIf="showPreview"
>
  <div *ngIf="preview" class="w-full h-fit">
    <div
      *ngIf="preview.preview"
      class="file-box bg-mat-ef-revolution-grey-two-50 rounded-xl"
    >
      <div class="w-full h-full" *ngIf="preview.format === 'pdf'">
        <ngx-extended-pdf-viewer
          class="ngx-extended-pdf-viewer"
          [src]="preview.preview"
          [showToolbar]="false"
          [showDownloadButton]="false"
        >
        </ngx-extended-pdf-viewer>
      </div>
      <div
        class="w-full image-preview h-[360px] flex items-center justify-center"
        *ngIf="
          preview.format === 'jpg' ||
          preview.format === 'jpeg' ||
          preview.format === 'png'
        "
      >
        <img
          class="w-full max-h-[calc(100%_-_50px)] h-[calc(100%_-_50px)]"
          alt="image preview"
          [src]="image"
        />
      </div>

      <div
        class="absolute right-0 top-0 z-20"
        *ngIf="hideSpinner && showPreviewButton"
      >
        <button
          mat-button
          mat-flat-button
          class="revolution min-w-[50%] min-h-fit"
          color="primary"
        >
          <mat-icon
            svgIcon="picture_as_pdf"
            (click)="PDF_openPreviewPopup(preview)"
          >
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!preview">
    <div
      class="w-full h-[360px] p-4 bg-mat-ef-revolution-grey-200 rounded-xl flex flex-col justify-center items-center mt-2.5"
    >
      <div class="text-mat-ef-revolution-supergrey-900">
        <mat-icon svgIcon="no_sim"></mat-icon>
      </div>
      <div class="text-base font-efSans-primary">
        {{ 'general-error-message' | sharedTranslate }}
      </div>
    </div>
  </ng-container>
  <div
    class="absolute inset-0 bg-mat-ef-revolution-grey-200 p-5"
    [ngClass]="{ hidden: hideSpinner }"
  >
    <ngx-skeleton-loader appearance="line" animation="progress">
    </ngx-skeleton-loader>
  </div>
</div>
