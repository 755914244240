import { Component, OnInit, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'customer-portal-ios-add-homescreen-banner',
  templateUrl: './ios-add-homescreen-banner.component.html'
})
export class IosAddHomescreenBannerComponent implements OnInit {
  @Output() public bannerDismiss = new EventEmitter<MouseEvent>()
  constructor() {}
  dismissBanner(event: MouseEvent): void {
    this.bannerDismiss.emit(event)
  }
  ngOnInit(): void {}
}
