import { ChangeDetectorRef, Component, type OnInit, ViewChild } from '@angular/core'
import { MatMenu } from '@angular/material/menu'
import { type User } from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGoogleTagmanagerService,
  LoginService,
  ResizeService, type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { SharedService } from '../../../../services/shared.service'
import { type VehicleDTOExtended } from '../../../../services/vehicles.service'

@Component({
  selector: 'customer-portal-user-menu',
  templateUrl: './user-menu.component.html'
})
export class UserMenuComponent implements OnInit {
  @ViewChild(MatMenu, { static: true }) menu: MatMenu
  user: User
  loggedIn: boolean
  vehicles: VehicleDTOExtended[] = []
  vin: string
  hasMarketplace
  hasMyMailbox
  channels: boolean
  hasTestView
  navigationMarketplace

  public screenSize: SCREEN_SIZE
  constructor (
    private readonly dataService: DataService,
    private readonly keycloak: KeycloakService,
    private readonly logger: NGXLogger,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>,
    public resizeService: ResizeService,
    private readonly loginService: LoginService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.screenSize = this.resizeService.screenSize
    this.hasMarketplace = this.remoteConfigService.get('hasMarketPlace')
    this.hasMyMailbox = this.remoteConfigService.get('hasMyMailbox')
    this.hasTestView = this.remoteConfigService.get('hasTestView')
    this.navigationMarketplace = this.remoteConfigService.getArrayValues<any>(
      'navigationMarketplace'
    )
  }

  logout (): void {
    this.loginService.logout(this.user)
  }

  sendGTMNavigationData (label?: string, linkType?: string): void {
    this.cptGtmService.sendNavigationLinkData(
      null,
      'Main Navigation',
      this.sharedService.translateLink(label),
      linkType
    )
  }

  ngOnInit (): void {
    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
      if (user != null) {
        this.loggedIn = true
      }
    })

    this.dataService.onUserConsentsLoaded.subscribe(consents => {
      this.channels = this.sharedService.hasConsentsForEFDS(consents)
    })

    this.dataService.onUserVehiclesLoaded.subscribe(
      data => {
        this.vehicles = data
        if (this.vin == null && data?.[0] != null) {
          this.vin = data[0].vin
        }
      },
      err => {
        if (err.status === 404) {
          this.vehicles = []
        }
      }
    )

    this.resizeService.onResize.subscribe(size => {
      this.screenSize = size
      this.cdRef.detectChanges()
    })
  }
}
