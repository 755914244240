import { Injectable } from '@angular/core'
import { B2CModuleConfig } from '../b2c.module'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'

@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  constructor(
    private readonly config: B2CModuleConfig,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {}

  public personalize() {
    window.location.href = this.remoteConfigService.get('b2c.personalizeUrl')
  }
}
