import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from '../../../services/shared.service'
import { DataService } from '../../../services/data.service'
import { DealerUserDTO } from '../../../services/dealers.service'
import {
  TrackAndTraceVehicleDTOExtended,
  VehicleDTOExtended
} from '../../../services/vehicles.service'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import {
  TrackStep,
  CustomerPortalConfig
} from '@inside-hub-app/customer-portal-config'
@Component({
  selector: 'customer-portal-app-vehicle-track-trace',
  templateUrl: './vehicle-track-trace.component.html'
})
export class VehicleTrackTraceComponent
  implements OnInit, OnDestroy, OnChanges
{
  homepage
  sub = {
    onUserTrackingLoaded: null,
    activatedRoute: null
  }

  stepList

  vehiclesTNT: TrackAndTraceVehicleDTOExtended[]
  vehicleTNT: TrackAndTraceVehicleDTOExtended
  salesman: DealerUserDTO
  public commissionNumber: string
  public loading = true

  @Input() vehicle: VehicleDTOExtended
  constructor(
    public readonly sharedService: SharedService,
    private readonly dataService: DataService,
    private readonly logger: NGXLogger,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly cdRef: ChangeDetectorRef,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.homepage = this.remoteConfigService.get('homepage')
    this.stepList = this.remoteConfigService.getArrayValues<TrackStep[]>('tntSteps')
  }

  ngOnInit(): void {
    this.sub.activatedRoute = this.route.params.subscribe(params => {
      if (params.vin != null) {
        if (this.sharedService.isComissionNumber(params.vin)) {
          const commissionNumber = params.vin.replace('commissionNumber-', '')
          if (this.commissionNumber !== commissionNumber) {
            this.showLoader()
          }
          this.commissionNumber = commissionNumber
          this.setTNT_tracking()
        }
      }
    })

    this.sub.onUserTrackingLoaded =
      this.dataService.onUserTrackingLoaded.subscribe(
        (allVehicles: TrackAndTraceVehicleDTOExtended[]) => {
          this.loading = false
          if (allVehicles.length > 0) {
            this.vehiclesTNT = allVehicles

            // for tnt view
            this.setTNT_tracking()
            // for vehicle details view
            this.setTNT_tracking_tab()
          }
        },
        error => {
          this.loading = false
          this.logger.debug(error)
        }
      )

    this.showLoader()
  }

  ngOnDestroy(): void {
    this.sharedService.unsubscribe(this.sub)
  }

  showLoader(): void {
    this.loading = true
    // show loader after vehicle switch
    setTimeout(() => {
      this.loading = false
    }, 500)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.vehicle != null &&
      changes.vehicle.currentValue !== changes.vehicle.previousValue
    ) {
      this.showLoader()
      this.setTNT_tracking_tab()
    }
  }

  setTNT_tracking_tab(): void {
    if (this.vehicle != null && this.vehiclesTNT?.[0] != null) {
      this.vehicleTNT = this.vehiclesTNT.find(el => {
        return (
          el.vin === this.vehicle.vin ||
          el.commissionNumber === this.vehicle.vin
        )
      })

      this.salesman = this.vehicleTNT?.salesman
    }
  }

  setTNT_tracking(): void {
    if (this.vehiclesTNT?.length != null && this.commissionNumber != null) {
      this.vehicleTNT = this.vehiclesTNT.find(
        vehicle => vehicle.commissionNumber === this.commissionNumber
      )
      this.salesman = this.vehicleTNT?.salesman

      if (this.vehicleTNT == null) {
        void this.router.navigate([this.homepage])
      }
    }
  }
}
