<customer-portal-app-skeleton-loader
  [loading]="!loading"
></customer-portal-app-skeleton-loader>

<ng-container *ngIf="!loading">
  <div
    class="text-lg mt-12 md:text-xl font-bold mb-5 md:mt-14 text-mat-ef-revolution-primary-500"
  >
    <T
      str="Reminders"
      key="customerPortal.customer-portal.reminders"
      tags="customer-portal, 3.2"
    ></T>
  </div>
  <mat-divider
    class="relative w-full text-mat-ef-revolution-supergrey-500"
  ></mat-divider>
  <mat-expansion-panel
    data-cy="vehicle-reminders-main-panel"
    #mainPanel
    class="noPaddingPanelBody"
    [ngClass]="{ 'pb-0': mainPanel.expanded }"
  >
    <mat-expansion-panel-header
      class="p-0 md:px-6"
      [collapsedHeight]="'auto'"
      [expandedHeight]="'auto'"
    >
      <mat-panel-title>
        <div class="mt-1 mr-1">
          <mat-icon
            class="text-mat-ef-revolution-primary-500"
            [ngClass]="mainPanel.expanded ? 'blue-icon' : ''"
            svgIcon="calendarDetails"
          ></mat-icon>
        </div>

        <div class="vehicle-expansion-panel-text">
          <div class="w-full overflow-hidden whitespace-nowrap text-ellipsis">
            <div class="flex text-sm sm:text-base font-bold">
              <span>
                <T
                  str="Your reminders"
                  key="customerPortal.customer-portal.reminders.title"
                  tags="customer-portal, 3.2"
                ></T>
              </span>
            </div>
          </div>
          <div class="text-sm text-efSans">
            <div>
              <T
                str="Set reminders for important vehicle-related appointments"
                key="customerPortal.customer-portal.reminders.subtitle"
                tags="customer-portal, 3.2"
              ></T>
            </div>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-divider
      class="relative w-full text-mat-ef-revolution-supergrey-500 mt-[10px]"
    ></mat-divider>
    <div class="m-0 md:ml-6">
      <div *ngFor="let reminder of vehicleReminders; last as last">
        <ng-container
          *ngTemplateOutlet="reminderPanelTemplate; context: { data: reminder }"
        >
        </ng-container>
        <mat-divider
          *ngIf="!last"
          class="relative w-full text-mat-ef-revolution-supergrey-500"
        ></mat-divider>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-divider
    class="relative w-full text-mat-ef-revolution-supergrey-500"
  ></mat-divider>
</ng-container>

<ng-template #reminderPanelTemplate let-reminder="data">
  <mat-expansion-panel
    data-cy="vehicle-reminders-sub-panel"
    #reminderPanel
    class="p-0"
    (opened)="selectedReminder = reminder"
    [expanded]="selectedReminder?.type?.id === reminder.type.id"
  >
    <mat-expansion-panel-header
      [collapsedHeight]="'auto'"
      [expandedHeight]="'auto'"
      class="py-0 pl-0"
    >
      <mat-panel-title>
        <div class="mt-1 mr-1 pl-6 py-2 relative">
          <mat-icon
            class="text-mat-ef-revolution-primary-500"
            [svgIcon]="reminderTypesSVG[reminder.type?.name] ?? 'error'"
          >
          </mat-icon>
          <mat-icon
            *ngIf="reminder.id"
            class="w-5 h-5 text-mat-ef-green-secondary-900 absolute top-0 left-4"
            svgIcon="check_circle"
          ></mat-icon>
        </div>

        <div
          class="flex gap-1 lg:gap-4 flex-col lg:flex-row vehicle-expansion-panel-text"
        >
          <div class="md:w-1/2 font-bold text-sm text-efSans">
            <T
              *ngIf="reminder.type.name === 'insurance-end-reminder'"
              str="Insurance end reminder"
              key="customerPortal.customer-portal.reminders.insurance-end-reminder"
              tags="customer-portal, 3.2"
            ></T>
            <T
              *ngIf="reminder.type.name === 'warranty-end-reminder'"
              str="Warranty end reminder"
              key="customerPortal.customer-portal.reminders.warranty-end-reminder"
              tags="customer-portal, 3.2"
            ></T>
            <T
              *ngIf="reminder.type.name === 'road-tax-reminder'"
              str="Road tax reminder"
              key="customerPortal.customer-portal.reminders.road-tax-reminder"
              tags="customer-portal, 3.2"
            ></T>
            <T
              *ngIf="reminder.type.name === 'tüv-reminder'"
              str="Tüv reminder"
              key="customerPortal.customer-portal.reminders.tüv-reminder"
              tags="customer-portal, 3.5"
            ></T>
          </div>
          <div class="lg:w-1/2 xl:w-2/3 text-sm text-efSans">
            <T
              *ngIf="reminder.type.name === 'insurance-end-reminder'"
              str="Insurance end reminder"
              key="customerPortal.customer-portal.reminders.insurance-end-reminder"
              tags="customer-portal, 3.2"
            ></T>
            <T
              *ngIf="reminder.type.name === 'warranty-end-reminder'"
              str="Warranty end reminder"
              key="customerPortal.customer-portal.reminders.warranty-end-reminder"
              tags="customer-portal, 3.2"
            ></T>
            <T
              *ngIf="reminder.type.name === 'road-tax-reminder'"
              str="Road tax reminder"
              key="customerPortal.customer-portal.reminders.road-tax-reminder"
              tags="customer-portal, 3.2"
            ></T>
            <T
              *ngIf="reminder.type.name === 'tüv-reminder'"
              str="Tüv reminder"
              key="customerPortal.customer-portal.reminders.tüv-reminder"
              tags="customer-portal, 3.5"
            ></T>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-divider
      class="relative w-full text-mat-ef-revolution-supergrey-500"
    ></mat-divider>

    <customer-portal-app-vehicle-reminders-form
      *ngIf="!updatingVehicleReminders && !loading"
      [setReminder]="reminder"
      [channelsOptions]="channels"
      [vehicle]="vehicle"
    ></customer-portal-app-vehicle-reminders-form>

    <div class="p-4" *ngIf="!(!updatingVehicleReminders && !loading)">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
        [count]="16"
      ></ngx-skeleton-loader>
    </div>
  </mat-expansion-panel>
</ng-template>
