import { HttpClient, HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { StorageService } from '@inside-hub-app/hub-storage'
import { com } from 'efd-cdb_backend-interfaces-ts'
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs'
import { catchError, flatMap, map, tap } from 'rxjs/operators'
import { Environment } from '../environments'
import { ENVIRONMENT, STORAGE_PREFIX } from '../injection-tokens'
type TokenDTO = com.mocira.inside.cdb.client.dto.security.TokenDTO
type Token2FaDTO = com.mocira.inside.cdb.client.dto.security.Token2FaDTO
type TokenLoginDTO = com.mocira.inside.cdb.client.dto.security.TokenLoginDTO

export interface PartnerProfileLoginRequest {
  username: string
  password: string
  clientInfo: string
}

export interface Status2FA {
  configured2fa: boolean
  disabled2fa: boolean
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly apiUrl: string
  private _token: string
  private _loginInfo: TokenLoginDTO
  private readonly _tokenSubject: BehaviorSubject<TokenDTO> =
    new BehaviorSubject<TokenDTO>(null)

  public _tokenObservable: Observable<TokenDTO>

  constructor (
    private readonly http: HttpClient,
    private readonly storage: StorageService,
    @Inject(ENVIRONMENT) private readonly environment: Environment,
    @Inject(STORAGE_PREFIX) private readonly storagePrefix: string
  ) {
    this._tokenSubject = new BehaviorSubject<TokenDTO>(null)
    this._tokenObservable = this._tokenSubject.asObservable()
    this.apiUrl = environment.baseUrl

    this.getTokenFromStorage().subscribe(token => {
      if (token) {
        this.getLoginInfoFromStorage().subscribe(loginInfo => {
          this._tokenSubject.next({
            token: token,
            loginInfo: loginInfo,
            details: null
          })
        })
      }
    })
  }

  public observeLoginInfo (): Observable<TokenDTO> {
    return this._tokenObservable
  }

  public getToken (): Observable<string> {
    if (this._token) {
      return of(this._token)
    }

    return this.getTokenFromStorage().pipe(
      tap(token => {
        if (token) {
          this._token = token
        }
      })
    )
  }

  private getTokenFromStorage (): Observable<string> {
    return this.storage
      .get<string>(this.storagePrefix + 'token')
      .pipe(map(token => token))
  }

  setToken (token: string): Observable<void> {
    this._token = token
    return this.storage.set(this.storagePrefix + 'token', token)
  }

  public removeToken () {
    this._token = null
    return this.storage.delete(this.storagePrefix + 'token')
  }

  public getLoginInfo (): Observable<TokenLoginDTO> {
    if (this._loginInfo) {
      return of(this._loginInfo)
    }

    return this.getLoginInfoFromStorage().pipe(
      tap(loginInfo => {
        this._loginInfo = loginInfo
      })
    )
  }

  private getLoginInfoFromStorage (): Observable<TokenLoginDTO> {
    return this.storage
      .get<TokenLoginDTO>(this.storagePrefix + 'loginInfo')
      .pipe(map(loginInfo => loginInfo))
  }

  setLoginInfo (loginInfo: TokenLoginDTO) {
    this._loginInfo = loginInfo
    return this.storage.set(this.storagePrefix + 'loginInfo', loginInfo)
  }

  public removeLoginInfo () {
    this._loginInfo = null
    return this.storage.delete(this.storagePrefix + 'loginInfo')
  }

  setLogin (loginResponse: TokenDTO): Observable<TokenDTO> {
    return forkJoin([
      this.setToken(loginResponse.token),
      this.setLoginInfo(loginResponse.loginInfo)
    ]).pipe(
      tap(() => this._tokenSubject.next(loginResponse)),
      map(_ => loginResponse)
    )
  }

  public login (
    loginRequest: PartnerProfileLoginRequest,
    platformId: number = 1
  ): Observable<TokenDTO> {
    return this.http
      .post<TokenDTO>(
        `${this.apiUrl}/cdb/security/auth/${platformId}`,
        loginRequest
    )
      .pipe(
        flatMap(loginResponse => {
          this._tokenSubject.next(loginResponse)
          return forkJoin([
            this.setToken(loginResponse.token),
            this.setLoginInfo(loginResponse.loginInfo)
          ]).pipe(map(_ => loginResponse))
        })
      )
  }

  public loginJwt (token: string): Observable<any> {
    return this.http.get<TokenDTO>(`${this.apiUrl}/cdb/security/validate`, {
      headers: { 'X-Auth-Token': token }
    }).pipe(
      flatMap(loginResponse => {
        this._tokenSubject.next(loginResponse)
        return forkJoin([
          this.setToken(loginResponse.token),
          this.setLoginInfo(loginResponse.loginInfo)
        ]).pipe(map(_ => loginResponse))
      })
    )
  }

  public login2fa (
    loginRequest: PartnerProfileLoginRequest,
    code?: string,
    platformId: number = 1
  ): Observable<any> {
    const params = code ? new HttpParams().set('code', code) : null
    return this.http
      .post<Token2FaDTO>(
        `${this.apiUrl}/cdb/security/auth2fa/${platformId}`,
        loginRequest,
        { params }
    )
      .pipe(
        flatMap(loginResponse => {
          this._tokenSubject.next(loginResponse)
          return forkJoin([
            this.setToken(loginResponse.token),
            this.setLoginInfo(loginResponse.loginInfo)
          ]).pipe(map(_ => loginResponse))
        })
      )
  }

  public reset2fa (enable?: boolean): Observable<any> {
    const params = enable
      ? new HttpParams().set('enabled', enable.toString())
      : null
    return this.http.post<any>(
      `${this.apiUrl}/cdb/security/reset2fa/`,
      {},
      { params }
    )
  }

  public get2FAConfig (userId?: number): Observable<Status2FA> {
    let params = {}
    if (userId) params['userId'] = userId

    return this.http.get<Status2FA>(`${this.apiUrl}/cdb/security/status2fa`, { params: params })
  }

  public getKc2FAConfig (userId?: number): Observable<Status2FA> {
    let params = {}
    if (userId) params['userId'] = userId

    return this.http.get<Status2FA>(`${this.apiUrl}/cdb/security/kc/status2fa`, { params: params })
  }

  public verifyToken (token?: string): Observable<boolean> {
    const tokenObservable = of(token).pipe(
      flatMap(token => {
        if (!token) {
          return this.getToken()
        }

        return of(token)
      })
    )

    return tokenObservable.pipe(
      flatMap(token => {
        if (!token) return of(false)
        return this.http
          .get<TokenDTO>(`${this.apiUrl}/cdb/security/validate`, {
          observe: 'response',
          headers: { 'X-Auth-Token': token }
        })
          .pipe(
            catchError(_ => of(false)),
            map(response =>
              typeof response === 'boolean' ? false : response.ok
            )
          )
      })
    )
  }

  public invalidToken (): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/cdb/security/invalidate`, {})
  }

  public logout (): Observable<void> {
    this._tokenSubject.next(null)
    return forkJoin([this.removeToken(), this.removeLoginInfo()]).pipe(
      map(value => undefined)
    )
  }

  public switchDealer (
    dealerId: number,
    platformId: number
  ): Observable<TokenDTO> {
    return this.http
      .get<TokenDTO>(
        `${this.apiUrl}/cdb/security/exchangeToken/${dealerId}/${platformId}`
    )
      .pipe(
        flatMap(response => {
          this._tokenSubject.next(response)
          return forkJoin([
            this.removeToken(),
            this.removeLoginInfo(),
            this.setToken(response.token),
            this.setLoginInfo(response.loginInfo)
          ]).pipe(map(_ => response))
        })
      )
  }
}
