/* eslint-disable @typescript-eslint/ban-ts-comment */
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { SharedService } from './shared.service'
import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'

export type LoanDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.LoanDTO
export type MultileaseContactDataDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.loan.dto.MultileaseContactDataDTO
export type MultileaseContactRequestDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.loan.dto.MultileaseContactRequestDTO
export type LoanContactDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.loan.dto.LoanContactDTO

export interface LeasingCalculations {
  totalDaysLength: number
  tillExpires: number
  percentage: number
  percentageLeft: number
  months: number
  years: number
}

@Injectable({
  providedIn: 'root'
})
export class LeasingTestingService {
  // LoanDTO[]
  getLeasingInfo(): Observable<any> {
    return of([
      {
        id: 0,
        providerName: 'Bananko',
        startDate: '2020-03-22',
        endDate: '2024-03-22',
        kmLimit: 10000,
        interestRate: 24,
        allowContact: false,
        costPerKm: 0,
        durationMonth: 0,
        monthlyRate: 0,
        multileaseContractId: '',
        offerId: 1,
        remainingValue: 0,
        residualValue: 0
      }
    ])
  }
}

@Injectable({
  providedIn: 'root'
})
export class LeasingService {
  private readonly apiUrl
  token: string

  constructor(
    private readonly http: HttpClient,
    private readonly sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  /**
   * Get Multilease loan contact data by loan offer ID.
   * @param offerId number Leasing contract id
   */
  public getMultiLeaseLoanContactData(
    offerId: number
  ): Observable<MultileaseContactDataDTO> {
    return this.http.get<MultileaseContactDataDTO>(
      this.apiUrl + '/loan/multilease-contact/' + String(offerId)
    )
  }

  /**
   * Send Multilease loan contact by loan offer ID.
   * @param offerId
   * @param data
   * @param lang
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public sendMultileaseContactForm(
    offerId: number,
    data: MultileaseContactRequestDTO,
    lang?: string
  ): Observable<any> {
    let params = new HttpParams()
    if (this.sharedService.stringExists(lang)) {
      params = params.append('lang', lang)
    }
    return this.http.post(
      this.apiUrl + '/loan/multilease-contact/' + String(offerId),
      data,
      {
        params
      }
    )
  }

  /**
   * Send loan contact
   * @param data
   * @param lang
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public sendContactForm(data: LoanContactDTO, lang?: string): Observable<any> {
    let params = new HttpParams()
    if (this.sharedService.stringExists(lang)) {
      params = params.append('lang', lang)
    }
    return this.http.post(this.apiUrl + '/loan/contact', data, {
      params
    })
  }

  calculateDays(leasing: LoanDTO): LeasingCalculations {
    const oneDay = 24 * 60 * 60 * 1000
    let firstDay: Date
    let lastDay: Date
    const currentDay = new Date()
    let years = 0
    let months = 0

    if (leasing.startDate != null && leasing.endDate != null) {
      firstDay = new Date(leasing.startDate)
      lastDay = new Date(leasing.endDate)
    } else if (leasing.startDate != null && leasing.interestRate != null) {
      firstDay = new Date(leasing.startDate)
      lastDay = new Date(
        firstDay.setMonth(firstDay.getMonth() + leasing.interestRate)
      )
    }

    years = lastDay.getFullYear() - currentDay.getFullYear()
    months = this.sharedService.monthDiff(currentDay, lastDay)

    if (years * 12 > months) {
      years = years - 1
    }

    // @ts-expect-error
    const diffDays = Math.round(Math.abs((firstDay - lastDay) / oneDay))
    // @ts-expect-error
    const numOfDaysPast = Math.round(Math.abs((firstDay - currentDay) / oneDay))

    if (diffDays - numOfDaysPast < 0) {
      return null
    } else {
      return {
        totalDaysLength: diffDays,
        tillExpires: diffDays - numOfDaysPast,
        percentage: (numOfDaysPast / diffDays) * 100,
        percentageLeft: 100 - (numOfDaysPast / diffDays) * 100,
        months,
        years
      }
    }
  }
}
