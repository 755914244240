<div
  class="text-lg mt-12 md:text-xl font-bold mb-5 md:mt-14 text-mat-ef-revolution-primary-500"
  *ngIf="!dataIsLoading()"
>
  <ng-container
    *ngIf="sharedService.showServicePackageSection() && !hasInsurance"
  >
    <T
      str="Service package"
      key="customerPortal.customer-portal.vehicle.servicepackage"
      tags="customer-portal, 3.1"
    ></T>
  </ng-container>

  <ng-container
    *ngIf="sharedService.showServicePackageSection() && hasInsurance"
  >
    <T
      str="Service package and insurance"
      key="customerPortal.customer-portal.vehicle.service.package.and.insurance"
      tags="customer-portal, 3.1"
    ></T>
  </ng-container>
  <ng-container
    *ngIf="!sharedService.showServicePackageSection() && hasInsurance"
  >
    <T
      str="Insurance"
      key="customerPortal.customer-portal.vehicle.insurance"
      tags="customer-portal, 3.10"
    ></T>
  </ng-container>
</div>
<mat-divider
  class="relative w-full text-mat-ef-revolution-supergrey-500"
></mat-divider>

<ng-container *ngIf="sharedService.showServicePackageSection()">
  <customer-portal-app-skeleton-loader
    [loading]="!loadingServicePackage"
  ></customer-portal-app-skeleton-loader>
  <customer-portal-app-revolution-service-molecule
    [vehicle]="vehicle"
    [loading]="loadingServicePackage"
  >
  </customer-portal-app-revolution-service-molecule>
  <mat-divider
    class="relative w-full text-mat-ef-revolution-supergrey-500"
  ></mat-divider>
</ng-container>

<ng-container *ngIf="hasInsurance">
  <customer-portal-app-skeleton-loader
    [loading]="!loadingInsurance"
  ></customer-portal-app-skeleton-loader>
  <customer-portal-app-insurance-molecule>
  </customer-portal-app-insurance-molecule>
</ng-container>
