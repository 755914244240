/* eslint-disable @typescript-eslint/ban-ts-comment */
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ReplaceDateWithStringType } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { Observable, of } from 'rxjs'
import { ErrorResponseDTO, SharedService } from './shared.service'
import { WarrantyService } from './warranty.service'
export type ServicePackageDTO =
  ReplaceDateWithStringType<cpt.emilfreydigital.customer_portal_backend.rest.service_package.dto.ServicePackageDTO>
export type ServicePackageContactRequestDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.service_package.dto.ServicePackageContactRequestDTO

export interface ServicePackageCalculations {
  totalDaysLength: number
  tillExpires: number
  percentage: number
  percentageLeft: number
  months: number
  years: number
}

const SERVICE_PACKAGE: ServicePackageDTO[] = [
  {
    purchased: true,
    expireReason: 'DATE',
    expired: false,
    extendDate: '2025-01-01',
    maxMileage: 100000,
    startDate: '2019-01-01',
    endDate: '2022-04-01',
    description: ''
  }
]

export interface IServicePackageService {
  getServicePackage: (
    vin: string,
    lang: string
  ) => Observable<ServicePackageDTO[]>
}

@Injectable({
  providedIn: 'root'
})
export class TestServicePackageService implements IServicePackageService {
  getServicePackage(): Observable<ServicePackageDTO[]> {
    return of(SERVICE_PACKAGE)
  }
}

@Injectable({
  providedIn: 'root'
})
export class ServicePackageService implements IServicePackageService {
  private readonly apiUrl
  constructor(
    private readonly http: HttpClient,
    private readonly warrantyService: WarrantyService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>,
    public sharedService: SharedService
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  getServicePackage(
    vin: string,
    lang: string
  ): Observable<ServicePackageDTO[]> {
    let params = new HttpParams()
    params = params.append('lang', lang)
    return this.http.get<ServicePackageDTO[]>(
      this.apiUrl + '/service-package/' + vin,
      {
        params
      }
    )
  }

  requestServicePackageContact(
    vin: string,
    data: ServicePackageContactRequestDTO,
    buy: boolean
  ): Observable<ErrorResponseDTO> {
    let params = new HttpParams()
    if (buy) {
      params = params.append('buy', 'true')
    }
    return this.http.post<ErrorResponseDTO>(
      this.apiUrl + '/service-package/' + vin,
      data,
      {
        params
      }
    )
  }

  servicePackageCalculations(
    servicePackages: ServicePackageDTO[]
  ): ServicePackageCalculations {
    if (servicePackages?.[0] != null) {
      const oneDay = 24 * 60 * 60 * 1000
      let firstDay: Date
      let lastDay: Date
      const currentDay = new Date()
      let years = 0
      let months = 0

      const servicePackage = servicePackages[servicePackages.length - 1]

      if (servicePackage.startDate != null && servicePackage.endDate != null) {
        firstDay = new Date(servicePackage.startDate)
        lastDay = new Date(servicePackage.endDate)
      } else {
        return null
      }

      const tillExpires = this.warrantyService.calculateTillExpires(
        servicePackage.startDate,
        servicePackage.endDate
      ).days
      const percentageTillExpires = this.warrantyService.calculateTillExpires(
        servicePackage.startDate,
        servicePackage.endDate
      ).percentage

      years = lastDay.getFullYear() - currentDay.getFullYear()
      months = this.sharedService.monthDiff(currentDay, lastDay)

      if (years * 12 > months) {
        years = years - 1
      }

      // @ts-expect-error
      const diffDays = Math.round(Math.abs((firstDay - lastDay) / oneDay))

      const numOfDaysPast = Math.round(
        // @ts-expect-error
        Math.abs((firstDay - currentDay) / oneDay)
      )

      if (diffDays - numOfDaysPast < 0) {
        return null
      } else {
        return {
          totalDaysLength: diffDays,
          tillExpires,
          percentage: percentageTillExpires,
          percentageLeft: 100 - percentageTillExpires,
          months,
          years
        }
      }
    }
  }
}
