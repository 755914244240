<mat-expansion-panel
  *ngIf="!loading"
  [hideToggle]="warrantyIsDisabled"
  [disabled]="warrantyIsDisabled"
  #warrantyPanel
  (afterExpand)="
    sendContentModulesData(
      true,
      'customerPortal.customer-portal.vehicle.warranty'
    )
  "
  (afterCollapse)="
    sendContentModulesData(
      false,
      'customerPortal.customer-portal.vehicle.warranty'
    )
  "
>
  <!-- Standard warranty -->
  <mat-expansion-panel-header
    class="p-0 md:px-6"
    [collapsedHeight]="'auto'"
    [expandedHeight]="'auto'"
  >
    <mat-panel-title>
      <div class="mt-1 mr-1">
        <mat-icon
          class="text-mat-ef-revolution-primary-500"
          [ngClass]="warrantyPanel.expanded ? 'blue-icon' : ''"
          svgIcon="car_insurance"
        >
        </mat-icon>
      </div>

      <div class="vehicle-expansion-panel-text" *ngIf="warranty?.standard">
        <div
          class="w-full pt-1 overflow-hidden whitespace-nowrap text-ellipsis"
        >
          <div class="flex text-sm sm:text-base font-bold">
            <span>
              <T
                str="Warranty"
                key="customerPortal.customer-portal.vehicle.warranty"
                tags="customer-portal, 3.1"
              ></T
            ></span>
            <span
              *ngIf="warranty?.standard?.purchased"
              class="leasing-title-warning"
              [ngClass]="styleLeasingWarningIcon(warranty?.standard)"
            >
              <mat-icon
                *ngIf="tillExpiresWarn(warranty?.standard)"
                svgIcon="error"
              ></mat-icon>
              <mat-icon
                *ngIf="!tillExpiresWarn(warranty?.standard)"
                svgIcon="check_circle"
              ></mat-icon>
            </span>
          </div>
        </div>

        <!-- Standard purchased warranty -->
        <ng-container
          *ngIf="!warranty?.standard?.expired && warranty?.standard?.purchased"
        >
          <mat-progress-bar
            [ngClass]="styleLeasingProgressBar(warranty?.standard)"
            class="h-0.5"
            mode="determinate"
            value="{{
              tillExpiration(warranty?.standard)?.percentage?.toString()
            }}"
            dir="rtl"
          >
          </mat-progress-bar>
        </ng-container>

        <div
          class="text-sm text-efSans"
          *ngIf="!warranty?.standard?.expired && warranty?.standard?.purchased"
        >
          <T
            str="The factory guarantee {maxMileage} km or period up to {endDate} - currently {currentMileage} km."
            key="customerPortal.customer-portal.vehicle.warranty.expiry"
            tags="customer-portal, 3.1"
            [vars]="{
              endDate: warranty.standard.endDate | date: dateFormat,
              maxMileage:
                warranty.standard.maxMileage | number: '1.0-0':currencyLocale,
              currentMileage: getMileage() | number: '1.0-0':currencyLocale
            }"
          ></T>
        </div>

        <!-- Expired warranty, show reason why -->
        <ng-container *ngIf="warranty.standard.expired">
          <div
            class="text-sm text-efSans"
            *ngIf="warranty.standard.expireReason === 'DATE'"
          >
            <T
              str="The factory warranty expired on {endDate}."
              key="customerPortal.customer-portal.vehicle.warranty.expired.time"
              tags="customer-portal, 3.1"
              [vars]="{
                endDate: warranty.standard.endDate | date: dateFormat
              }"
            ></T>
          </div>
          <div
            class="text-sm text-efSans"
            *ngIf="warranty.standard.expireReason === 'MILEAGE'"
          >
            <T
              str="The factory warranty expired on {maxMileage} km."
              key="customerPortal.customer-portal.vehicle.warranty.expired.mileage"
              tags="customer-portal, 3.1"
              [vars]="{
                maxMileage:
                  warranty.standard.maxMileage | number: '1.0-0':currencyLocale
              }"
            ></T>
          </div>
        </ng-container>
      </div>

      <div
        class="vehicle-expansion-panel-text"
        *ngIf="!warranty || !warranty?.standard"
      >
        <div
          class="w-full pt-1 overflow-hidden whitespace-nowrap text-ellipsis"
        >
          <div class="flex text-sm sm:text-base font-bold">
            <span>
              <T
                str="Warranty"
                key="customerPortal.customer-portal.vehicle.warranty"
                tags="customer-portal, 3.1"
              ></T
            ></span>
          </div>
          <div class="text-sm text-efSans" *ngIf="!validWarranties">
            <T
              str="No warranty"
              key="customerPortal.customer-portal.vehicle.warranty.none"
              tags="customer-portal, 3.1"
            ></T>
          </div>
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <mat-divider class="leasing-divider -mx-6 md:mx-0"></mat-divider>

  <div class="-mx-6 md:m-0 text-mat-ef-revolution-primary-500 font-normal">
    <!-- brand warranty link -->
    <div [ngClass]="{ 'ml-0 md:ml-10 ': hasLinks }">
      <customer-portal-app-brand-links
        [type]="'warranty'"
        [brand]="vehicle.brand"
        (hasLinks)="hasLinksChanged($event)"
      >
      </customer-portal-app-brand-links>
    </div>

    <!-- promotions -->
    <div
      *ngIf="hasPromotions && promotions && promotions[0]"
      class="ml-0 md:ml-12 mt-2.5 flex flex-col"
    >
      <h4 class="text-base text-efSans font-semibold">
        <T
          str="Warranty Information"
          key="customerPortal.customer-portal.current-actions"
          tags="customer-portal, 3.1"
        ></T>
      </h4>

      <div class="gap-2 p-0 py-2 w-full flex flex-col">
        <div *ngFor="let promotion of promotions; let i = index" class="w-full">
          <mat-expansion-panel
            [hideToggle]="true"
            (opened)="promotionIndex = i"
            class="p-0"
            (afterExpand)="sendContentModulesData(true, 'Promotion')"
            (afterCollapse)="sendContentModulesData(false, 'Promotion')"
            (closed)="promotionIndex === i ? (promotionIndex = undefined) : ''"
            [expanded]="promotionIndex === i"
          >
            <mat-expansion-panel-header
              class="p-0 bg-gray-100"
              [collapsedHeight]="'auto'"
              [expandedHeight]="'auto'"
            >
              <mat-panel-title class="flex justify-between p-0">
                <div
                  class="flex flex-1 items-center pl-1 max-w-[calc(100%_-_70px)]"
                >
                  <mat-icon
                    class="mx-1 text-mat-ef-revolution-primary-500"
                    svgIcon="insert_drive_file"
                  ></mat-icon>
                  <div
                    class="flex-1 max-w-[calc(100%_-_35px)] text-sm text-efSans pl-2 text-ellipsis whitespace-nowrap overflow-hidden"
                  >
                    {{ promotion.name }}
                  </div>
                </div>
                <div class="flex">
                  <button
                    class="flex items-center"
                    (click)="openLinkInNewTab(promotion, $event)"
                  >
                    <mat-icon
                      class="text-mat-ef-revolution-primary-500"
                      svgIcon="visibility_on"
                    ></mat-icon>
                  </button>
                  <a
                    [href]="promotion.url"
                    type="application/pdf"
                    [download]="fileNameFromUrl(promotion.url)"
                  >
                    <!-- prettier-ignore -->
                    <button
                      class="flex items-center"
                      matTooltip="{{ 'Download' | translate:{ _key: 'customerPortal.customer-portal.vehicle.documents-menu.download', _tags: 'customer-portal, 3.1'} }}"
                      [matTooltipPosition]="'right'"
                    >
                      <mat-icon
                        svgIcon="download"
                        class="ml-2 my-auto py-0 text-mat-ef-revolution-primary-500"
                        ></mat-icon
                      >
                    </button>
                  </a>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div
              *ngIf="promotionIndex === i"
              class="app-document-preview-container"
            >
              <customer-portal-app-document-preview
                [promotion]="promotion"
                [showPreviewButton]="false"
                (closePreview)="promotionIndex = undefined"
              >
              </customer-portal-app-document-preview>
            </div>
          </mat-expansion-panel>
        </div>
      </div>
    </div>

    <!-- Extended warranties -->
    <div
      class="ml-0 md:ml-12 mt-2.5 flex flex-col"
      *ngIf="warranty?.extended?.length > 0"
    >
      <h4 class="text-base text-efSans font-semibold">
        <T
          str="Extended warranties"
          key="customerPortal.customer-portal.vehicle.warranty.extended"
          tags="customer-portal, 3.1"
        ></T>
      </h4>

      <ng-container
        *ngFor="let extendedWarranty of purchasedExtendedWarranties"
      >
        <div class="mt-1">
          <span class="text-base text-efSans">{{
            extendedWarranty?.description
          }}</span>
        </div>

        <mat-progress-bar
          *ngIf="!extendedWarranty?.expired"
          [ngClass]="styleExtendedWarrantyProgressBar(extendedWarranty)"
          mode="determinate"
          value="{{ tillExpiration(extendedWarranty)?.percentage?.toString() }}"
          class="h-0.5"
          dir="rtl"
        >
        </mat-progress-bar>

        <ul class="ml-5">
          <li
            class="text-sm text-efSans list-disc"
            *ngIf="!extendedWarranty?.expired"
          >
            <T
              str="Until"
              key="customerPortal.customer-portal.until"
              tags="customer-portal, 3.1"
            ></T>
            {{ extendedWarranty?.endDate | date: dateFormat }}
          </li>
        </ul>
        <ng-container *ngIf="extendedWarranty?.expired">
          <ul class="ml-5">
            <li
              class="text-sm text-efSans list-disc"
              *ngIf="extendedWarranty?.expireReason === 'DATE'"
            >
              <T
                str="The warranty expired on {endDate}."
                key="customerPortal.customer-portal.vehicle.extendedwarranty.expired.time"
                tags="customer-portal, 3.1"
                [vars]="{
                  endDate: extendedWarranty?.endDate | date: dateFormat
                }"
              ></T>
            </li>
            <li
              class="text-sm text-efSans list-disc"
              *ngIf="extendedWarranty?.expireReason === 'MILEAGE'"
            >
              <T
                str="The warranty expired on {maxMileage} km."
                key="customerPortal.customer-portal.vehicle.extendedwarranty.expired.mileage"
                tags="customer-portal, 3.1"
                [vars]="{
                  maxMileage:
                    extendedWarranty?.maxMileage
                    | number: '1.0-0':currencyLocale
                }"
              ></T>
            </li>
          </ul>
        </ng-container>
      </ng-container>

      <!--Unpurchased waaranties-->
      <ng-container *ngIf="unpurchasedExtendedWarranties?.length > 0">
        <div
          class="my-2.5 mx-0 p-2.5 bg-gray-100 rounded-md flex flex-col"
          *ngFor="let o of unpurchasedExtendedWarranties"
        >
          <div class="flex flex-row justify-between items-center">
            <div class="flex flex-col md:flex-row gap-2.5 items-baseline">
              <span class="text-sm text-efSans font-semibold">
                <T
                  str="Optional"
                  key="customerPortal.customer-portal.vehicle.warranty.optional"
                  tags="customer-portal, 3.1"
                ></T>
              </span>
              <span class="text-sm text-efSans">{{ o.description }}</span>
            </div>
            <div
              class="min-w-fit text-blue-500 text-base text-efSans cursor-pointer bg-white px-4 py-1 rounded-2xl font-semibold inline-block overflow-hidden whitespace-nowrap min-w-36"
              (click)="
                openWarrantyPackageContactPopup(
                  'offerRequest',
                  'warranty',
                  o,
                  'EXTENDED'
                )
              "
            >
              <T
                str="Inquire now"
                key="customerPortal.customer-portal.vehicle.warranty.requestNow"
                tags="customer-portal, 3.1"
              ></T>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- Tires warranties -->
    <!-- purchasedTireWarranties -->
    <!--  -->
    <div
      class="ml-0 md:ml-12 mt-2.5 flex flex-col"
      *ngIf="warranty?.tire?.length > 0"
    >
      <h4 class="text-base text-efSans font-semibold">
        <T
          str="Tyre warranties"
          key="customerPortal.customer-portal.vehicle.warranty.tires"
          tags="customer-portal, 3.1"
        ></T>
      </h4>

      <ng-container *ngFor="let tireWarranty of purchasedTireWarranties">
        <div class="mt-1">
          <span class="text-base text-efSans">{{
            tireWarranty.description
          }}</span>
        </div>

        <mat-progress-bar
          *ngIf="!tireWarranty.expired"
          [ngClass]="styleTyreWarrantyProgressBar(tireWarranty)"
          mode="determinate"
          value="{{ tillExpiration(tireWarranty)?.percentage?.toString() }}"
          class="h-0.5 my-2"
          dir="rtl"
        >
        </mat-progress-bar>

        <div class="text-sm text-efSans" *ngIf="!tireWarranty.expired">
          <T
            str="Until"
            key="customerPortal.customer-portal.until"
            tags="customer-portal, 3.1"
          ></T>
          {{ tireWarranty.endDate | date: dateFormat }}
        </div>
        <ng-container *ngIf="tireWarranty.expired">
          <div
            class="text-sm text-efSans"
            *ngIf="tireWarranty.expireReason === 'DATE'"
          >
            <T
              str="The warranty expired on {endDate}."
              key="customerPortal.customer-portal.vehicle.extendedwarranty.expired.time"
              tags="customer-portal, 3.1"
              [vars]="{ endDate: tireWarranty.endDate | date: dateFormat }"
            ></T>
          </div>
          <div
            class="text-sm text-efSans"
            *ngIf="tireWarranty.expireReason === 'MILEAGE'"
          >
            <T
              str="The warranty expired on {maxMileage} km."
              key="customerPortal.customer-portal.vehicle.extendedwarranty.expired.mileage"
              tags="customer-portal, 3.1"
              [vars]="{
                maxMileage:
                  tireWarranty.maxMileage | number: '1.0-0':currencyLocale
              }"
            ></T>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="unpurchasedTireWarranties?.length > 0">
        <div
          class="my-2.5 mx-0 p-2.5 bg-gray-100 rounded-md flex flex-col"
          *ngFor="let o of unpurchasedTireWarranties"
        >
          <div class="flex flex-row justify-between items-center">
            <div class="flex flex-col md:flex-row gap-2.5 items-baseline">
              <span class="text-sm text-efSans font-semibold">
                <T
                  str="Optional"
                  key="customerPortal.customer-portal.vehicle.warranty.optional"
                  tags="customer-portal, 3.1"
                ></T>
              </span>
              <span class="text-sm text-efSans">{{ o.description }}</span>
            </div>
            <div
              class="min-w-fit text-blue-500 text-base text-efSans cursor-pointer bg-white px-4 py-1 rounded-2xl font-semibold inline-block overflow-hidden whitespace-nowrap min-w-36"
              (click)="
                openWarrantyPackageContactPopup(
                  'offerRequest',
                  'tires',
                  o,
                  'TIRE'
                )
              "
            >
              <T
                str="Inquire now"
                key="customerPortal.customer-portal.vehicle.warranty.requestNow"
                tags="customer-portal, 3.1"
              ></T>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- End extended warranties-->

    <div class="relative -r-10 w-full mt-5">
      <button
        *ngIf="warranty?.standard || unpurchasedExtendedWarranties?.length > 0"
        class="w-full md:w-auto float-right md:ml-5 revolution"
        mat-button
        mat-flat-button
        color="secondary"
        (click)="
          openWarrantyPackageContactPopup('contact', 'warranty');
          sendContentModulesCTAData(
            'customerPortal.customer-portal.leasing.manage.contact'
          )
        "
      >
        <T
          str="Manage contact"
          key="customerPortal.customer-portal.leasing.manage.contact"
          tags="customer-portal, 3.1"
        ></T>
      </button>
      <button
        (click)="
          onWarrantyDocumentButtonClicked();
          sendContentModulesCTAData(
            'customerPortal.customer-portal.leasing-documents'
          )
        "
        *ngIf="hasWarrantyDocuments"
        [routerLink]="[
          '/vehicles/' + vehicle.vin + '/vehicle-history/warranty'
        ]"
        class="w-full md:w-auto float-right md:ml-5 revolution"
        mat-button
        mat-stroked-button
        color="secondary"
      >
        {{ 'documents' | sharedTranslate }}
      </button>
    </div>
  </div>
</mat-expansion-panel>
