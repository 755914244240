<div
  class="RevolutionDealershipContactMoleculeComponent w-full min-w-fit h-fit"
>
  <mat-card
    *ngIf="employee"
    class="p-6 gap-6 w-full h-fit !flex !flex-col !shadow-none !rounded-lg"
    [ngClass]="{ 'bg-mat-ef-revolution-grey-200': view !== 'appointment' }"
  >
    <div class="h-fit w-full flex gap-6 flex-col md:flex-row">
      <div
        class="flex-1 flex gap-6"
        [ngClass]="{ 'flex-col items-center': view === 'tracking' }"
      >
        <div class="h-18 w-18">
          <img
            *ngIf="!showAltIcon"
            class="object-cover rounded-full"
            [src]="profilePictureUrl"
            (error)="showAltIcon = true"
            alt=""
          />
          <mat-icon
            *ngIf="showAltIcon"
            svgIcon="ef-logo-icon"
            class="!w-full !h-full !text-mat-ef-primary-500"
          >
          </mat-icon>
        </div>

        <div class="flex-1 relative">
          <button
            data-cy="dealership-contact-molecule-editPrefferedemployee-button"
            *ngIf="!disableEdit && view !== 'appointment' && !vehicle?.business"
            mat-button
            mat-stroked-button
            color="secondary"
            (click)="!disableEdit && editPrefferedemployee()"
            class="revolution !hidden md:block !absolute top-0 right-0 small-button"
          >
            {{ 'update' | sharedTranslate }}
          </button>

          <div
            class="text-xs text-mat-ef-revolution-primary-300"
            [ngClass]="{ 'text-center': view === 'tracking' }"
          >
            <T
              str="Your contact person"
              key="customerPortal.customer-portal.dealer.key-contact"
              tags="customer-portal, 3.1"
            ></T>
          </div>
          <div
            class="text-lg text-mat-ef-revolution-primary-500 font-bold"
            [ngClass]="{ 'text-center': view === 'tracking' }"
          >
            {{ employee.name }} {{ employee.familyName }}
          </div>
          <div
            *ngIf="employee.functionRole"
            class="text-base text-mat-ef-revolution-primary-500"
            [ngClass]="{ 'text-center': view === 'tracking' }"
          >
            <ng-container
              *ngTemplateOutlet="
                sharedService.template('transifexTranslation');
                context: {
                  key: 'customerPortal.customer-portal.' + employee.functionRole
                }
              "
            ></ng-container>
          </div>
        </div>
      </div>

      <div
        *ngIf="view === 'appointment'"
        class="flex-1 flex flex-col gap-x-12 text-mat-ef-revolution-secondary-500 justify-around"
      >
        <ng-container *ngTemplateOutlet="employeeTemplate"> </ng-container>
      </div>
    </div>

    <div
      *ngIf="view !== 'appointment'"
      class="h-fit w-full flex flex-col gap-x-12 gap-y-1 text-mat-ef-revolution-secondary-500 justify-between"
      [ngClass]="{
        'lg:flex-row': view !== 'tracking',
        'gap-y-4': view === 'tracking'
      }"
    >
      <ng-container *ngTemplateOutlet="employeeTemplate"> </ng-container>
    </div>
  </mat-card>
</div>

<ng-template #employeeTemplate>
  <div class="flex" *ngIf="employee.phone">
    <div>
      <mat-icon class="!h-5 !w-5 mr-2" svgIcon="phone"></mat-icon>
    </div>
    <a
      data-rel="external"
      class="text-sm font-semibold cursor-pointer"
      (click)="
        openPhoneCLient($event); sendOutboundLinkData('E-Mail Click', dealer)
      "
    >
      <div *ngIf="phoneNumber?.mask">
        {{ phoneNumber.prefix }}
        {{ phoneNumber.number | mask: phoneNumber.mask }}
      </div>
      <div *ngIf="!phoneNumber?.mask">
        {{ employee.phone }}
      </div>
    </a>
  </div>
  <div class="flex" *ngIf="employee.email">
    <div>
      <mat-icon class="!h-5 !w-5 mr-2" svgIcon="mail"></mat-icon>
    </div>
    <a
      data-rel="external"
      class="text-sm font-semibold cursor-pointer"
      (click)="
        openEmailClient($event); sendOutboundLinkData('Phone Click', dealer)
      "
    >
      {{ employee.email }}</a
    >
  </div>
  <div *ngIf="view !== 'appointment' && dealer?.id" class="flex">
    <div>
      <mat-icon class="!h-5 !w-5 mr-2" svgIcon="phone_callback"></mat-icon>
    </div>
    <div
      data-cy="request-callback-button-employee"
      class="text-sm font-semibold cursor-pointer"
      (click)="openCallRequestPopup(); $event.stopPropagation()"
    >
      <T
        str="Request a callback"
        key="customerPortal.customer-portal.support.callrequest"
        tags="customer-portal, 3.1"
      ></T>
    </div>
  </div>
  <button
    *ngIf="!disableEdit && view !== 'appointment' && !vehicle?.business"
    mat-button
    mat-stroked-button
    color="secondary"
    (click)="
      !disableEdit && editPrefferedemployee();
      sendOutboundLinkData('Phone Click', dealer)
    "
    class="revolution block md:hidden small-button"
  >
    {{ 'update' | sharedTranslate }}
  </button>
</ng-template>
