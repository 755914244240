import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'customer-portal-app-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss']
})
export class DividerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
