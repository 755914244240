import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'

import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import {
  CarmatoSearchAgentDTO,
  CarmatoService
} from '../../../../services/carmato.service'
import { SharedService } from '../../../../services/shared.service'
import { ConfirmationPopupComponent } from '../../../basic/confirmation-popup/confirmation-popup.component'
import { ConfirmationQuestionPopupComponent } from '../../../basic/confirmation-question-popup/confirmation-question-popup.component'
@Component({
  selector: 'customer-portal-app-revolution-marketplace-searchagent',
  templateUrl: './marketplace-searchagent.component.html'
})
export class RevolutionMarketplaceSearchagentComponent implements OnInit {
  loading = true
  searchAgents: CarmatoSearchAgentDTO[]
  hasSearchAgents
  constructor(
    private readonly carmatoService: CarmatoService,
    private readonly sharedService: SharedService,
    private readonly dialog: MatDialog,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasNewFooter = this.remoteConfigService.get('hasNewFooter')
    this.currency = this.remoteConfigService.get('currency')
    this.currencyLocale = this.remoteConfigService.get('currencyLocale')
  }

  public hasNewFooter
  currency: string
  currencyLocale: string

  sendContentModulesData(expanded: boolean, contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Accordion',
      expanded ? 'Open' : 'Close',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Accordion|${this.sharedService.translateLink(contentModuleLabel) ?? ''}`,
      expanded ? 'Open' : 'Close'
    )
  }

  createAgentSubTitle(s: CarmatoSearchAgentDTO): string {
    let subtitle = ''
    if (
      s.data.filters?.[0] != null &&
      s.data.filters.find(
        ({ label }) => label === 'Hersteller/Modell(e)/Variante'
      ) != null
    ) {
      subtitle = s.data.filters.find(
        ({ label }) => label === 'Hersteller/Modell(e)/Variante'
      ).value
    }
    return subtitle
  }

  capitalizeFirstLetter(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  concatenateList(items: string[]): string {
    let contanenated = ''
    if (items != null) {
      items.forEach(i => {
        contanenated += this.capitalizeFirstLetter(i.toString()) + ', '
      })
    }
    return contanenated.slice(0, -2)
  }

  showSearchResults(s: CarmatoSearchAgentDTO): void {
    const searchUrl = this.remoteConfigService.get(
      'marketPlace.searchagent.url'
    )
    const fullURL = `${searchUrl}${s.data.uriPath}`
    window.open(fullURL, '_blank')
  }

  deleteSearch(s: CarmatoSearchAgentDTO): void {
    const searchAgentId = s.uid
    this.dialog
      .open(ConfirmationQuestionPopupComponent, {
        data: {
          title:
            'customerPortal.customer-portal.marketplace.searchagent.delete',
          text: 'customerPortal.customer-portal.marketplace.searchagent.delete.question',
          cancel: 'shared.cancel',
          save: 'shared.clear',
          saveButtonColor: 'warn'
        },
        panelClass: 'mat-dialog-cpt'
      })
      .afterClosed()
      .subscribe(result => {
        if (result != null && result !== false) {
          this.carmatoService.deleteSearchAgent(searchAgentId).subscribe(
            () => {
              this.dialog.open(ConfirmationPopupComponent, {
                data: {
                  title: 'shared.successful',
                  text: 'customerPortal.customer-portal.marketplace.searchagent.delete.success.text'
                },
                panelClass: 'mat-dialog-cpt'
              })
              this.getSearchAgents()
            },
            () => {
              this.dialog.open(ConfirmationPopupComponent, {
                data: {
                  title: 'shared.error',
                  text: 'customerPortal.customer-portal.marketplace.searchagent.delete.failure.text'
                },
                panelClass: 'mat-dialog-cpt'
              })
            }
          )
        }
      })
  }

  getSearchAgents(): void {
    this.carmatoService.getSearchAgent().subscribe(
      searchagents => {
        this.loading = false
        this.searchAgents = searchagents
        this.hasSearchAgents = this.searchAgents?.length > 0
      },
      () => {
        this.loading = false
        this.hasSearchAgents = false
      }
    )
  }

  ngOnInit(): void {
    this.getSearchAgents()
  }
}
