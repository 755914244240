<div
  class="cpt-modal relative dialog-content-p-0 mat-dialog-actions-pb-20 ef-scrollbar"
>
  <h2
    class="font-canelablack-primary text-4xl"
    *ngIf="data.requestType === 'offerRequest'"
  >
    <ng-container
      *ngTemplateOutlet="
        sharedService.template('transifexTranslation');
        context: {
          str: data.title,
          prefix:
            'customerPortal.customer-portal.vehicle.warranty.contact.title.'
        }
      "
    ></ng-container>
  </h2>
  <h2
    class="font-canelablack-primary text-4xl"
    *ngIf="data.requestType === 'contact'"
  >
    <T
      str="Contact"
      key="customerPortal.customer-portal.vehicle.servicepackage.contact"
      tags="customer-portal, 3.1"
    ></T>
  </h2>

  <mat-dialog-content class="leasing-warranty-contact-dialog">
    <div
      class="font-efSans mb-1 normal-case text-xl text-mat-ef-revolution-primary-500"
    >
      <T
        str="Your data"
        key="customerPortal.customer-portal.leasing.manage.contact.yourData"
        tags="customer-portal, 3.1"
      ></T>
    </div>

    <div
      class="flex flex-col gap-3 border-2 rounded-lg px-3.5 py-2.5"
      *ngIf="user"
    >
      <div class="flex flex-col md:flex-row md:justify-between">
        <div class="text-base text-efSans text-mat-ef-revolution-grey-two-500">
          <T
            str="Customer"
            key="customerPortal.customer-portal.leasing.manage.contact.yourData.kunde"
            tags="customer-portal, 3.1"
          ></T>
        </div>
        <div
          class="text-base text-efSans text-mat-ef-revolution-primary-500 md:text-end"
        >
          {{ user | username | async }}
        </div>
      </div>
      <div class="flex flex-col md:flex-row md:justify-between">
        <div class="text-base text-efSans text-mat-ef-revolution-grey-two-500">
          {{ 'customer-number' | sharedTranslate }}
        </div>
        <div
          class="text-base text-efSans text-mat-ef-revolution-primary-500 md:text-end"
        >
          {{ user.careId }}
        </div>
      </div>
      <div class="flex flex-col md:flex-row md:justify-between">
        <div class="text-base text-efSans text-mat-ef-revolution-grey-two-500">
          <T
            str="VIN (chassis number)"
            key="customerPortal.customer-portal.vehicle.details.vin"
            tags="customer-portal, 3.1"
          ></T>
        </div>
        <div
          class="text-base text-efSans text-mat-ef-revolution-primary-500 md:text-end"
        >
          {{ data.vehicle.vin }}
        </div>
      </div>
      <div
        class="flex flex-col md:flex-row md:justify-between"
        *ngIf="prefferedDealer"
      >
        <div class="text-base text-efSans text-mat-ef-revolution-grey-two-500">
          <T
            str="Service company"
            key="customerPortal.customer-portal.leasing.manage.contact.prefferedDealer"
            tags="customer-portal, 3.1"
          ></T>
        </div>
        <div
          class="text-base text-efSans text-mat-ef-revolution-primary-500 md:text-end"
        >
          {{ prefferedDealer | dealername }}
        </div>
      </div>
      <div
        class="flex flex-col md:flex-row md:justify-between"
        *ngIf="prefferedEmployee"
      >
        <div class="text-base text-efSans text-mat-ef-revolution-grey-two-500">
          <T
            str="Contact person"
            key="customerPortal.customer-portal.leasing.manage.contact.prefferedEmployee"
            tags="customer-portal, 3.1"
          ></T>
        </div>
        <div
          class="text-base text-efSans text-mat-ef-revolution-primary-500 md:text-end"
        >
          {{ prefferedEmployee.name }}&nbsp;{{ prefferedEmployee.familyName }}
        </div>
      </div>
    </div>

    <form
      [formGroup]="warrantyContactForm"
      [ngClass]="{
        'mt-6': data.requestType === 'contact',
        'mt-8': data.requestType !== 'contact'
      }"
    >
      <ng-container *ngIf="data.requestType === 'contact'">
        <div
          class="font-efSans mb-1 normal-case text-xl text-mat-ef-revolution-primary-500"
        >
          <T
            str="Your topic"
            key="customerPortal.customer-portal.vehicle.warranty.contact.subject"
            tags="customer-portal, 3.1"
          ></T>
        </div>
        <ul [formGroup]="subject" class="p-0 mb-7 list-none">
          <li class="mb-2.5">
            <mat-checkbox
              formControlName="standard"
              color="primary"
              [checked]="subject.get('standard').value"
            >
              <T
                str="Warranty"
                key="customerPortal.customer-portal.vehicle.warranty.contact.subject.standard"
                tags="customer-portal, 3.1"
              ></T>
            </mat-checkbox>
          </li>
          <li class="mb-2.5">
            <mat-checkbox
              formControlName="extended"
              color="primary"
              [checked]="subject.get('extended').value"
            >
              <T
                str="Assistance"
                key="customerPortal.customer-portal.vehicle.warranty.contact.subject.extended"
                tags="customer-portal, 3.1"
              ></T>
            </mat-checkbox>
          </li>
          <li class="mb-2.5">
            <mat-checkbox
              formControlName="tire"
              color="primary"
              [checked]="subject.get('tire').value"
            >
              <T
                str="Tire warranty"
                key="customerPortal.customer-portal.vehicle.warranty.contact.subject.tire"
                tags="customer-portal, 3.1"
              ></T>
            </mat-checkbox>
          </li>
        </ul>
      </ng-container>

      <div class="w-full mb-2.5 grid grid-cols-2 gap-3">
        <mat-form-field class="col-span-2" appearance="legacy">
          <mat-label>
            <T
              str="Kilometers"
              key="customerPortal.customer-portal.leasing.manage.contact.yourMessage.km"
              tags="customer-portal, 3.1"
            ></T>
          </mat-label>
          <mat-icon matPrefix svgIcon="mileage"></mat-icon>
          <input
            type="number"
            min="0"
            max="9999999"
            maxlength="7"
            formControlName="mileage"
            matInput
            required
            [errorStateMatcher]="matcher"
          />
          <mat-error *ngIf="warrantyContactForm.get('mileage').errors">
            {{
              transifexService.getErrorTranslation(
                warrantyContactForm.get('mileage'),
                'customerPortal.customer-portal.leasing.manage.contact.yourMessage.error.mileage.'
              )
            }}
          </mat-error>
        </mat-form-field>
        <!--  -->
        <!--Date picker for tires warranty-->

        <mat-form-field
          class="col-span-1"
          *ngIf="data.requestType === 'offerRequest' && data.title === 'tires'"
          appearance="legacy"
        >
          <!-- prettier-ignore -->
          <input
            matInput
            (click)="picker.open()"
            readonly
            [(ngModel)]="startDate"
            [matDatepicker]="picker"
            [min]="minDate"
            placeholder="{{ 'Desired start date' | translate:{ _key: 'customerPortal.customer-portal.vehicle.servicepackage.startDate', _tags: 'customer-portal, 3.1'} }}"
            formControlName="startDate"
          />
          <mat-datepicker-toggle
            class="calendar-size"
            matPrefix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker
            [calendarHeaderComponent]="customHeader"
            #picker
          ></mat-datepicker>
          <span
            *ngIf="!picker.disabled"
            class="cursor-pointer"
            (click)="picker.open()"
            matSuffix
          >
            <mat-icon svgIcon="create"></mat-icon>
          </span>
        </mat-form-field>

        <mat-form-field
          class="col-span-1"
          appearance="legacy"
          *ngIf="data.requestType === 'offerRequest' && data.title === 'tires'"
        >
          <!-- prettier-ignore -->
          <input
            matInput
            readonly
            placeholder=" {{ 'End date' | translate:{ _key: 'customerPortal.customer-portal.vehicle.servicepackage.endDate', _tags: 'customer-portal, 3.1'} }}"
            formControlName="endDate"
          />
        </mat-form-field>

        <!--  -->
        <!--Date picker for service package -->

        <mat-form-field
          class="col-span-1"
          appearance="legacy"
          *ngIf="
            data.requestType === 'offerRequest' &&
            data.title === 'servicePackage'
          "
        >
          <!-- prettier-ignore -->
          <input
            matInput
            (click)="picker.open()"
            readonly
            [(ngModel)]="startDate"
            [matDatepicker]="picker"
            placeholder="{{ 'Desired start date' | translate:{ _key: 'customerPortal.customer-portal.vehicle.servicepackage.startDate', _tags: 'customer-portal, 3.1'} }}"
            formControlName="startDate"
          />
          <mat-datepicker-toggle
            class="calendar-size"
            matPrefix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker
            [calendarHeaderComponent]="customHeader"
            #picker
          ></mat-datepicker>
          <span
            *ngIf="!picker.disabled"
            class="cursor-pointer"
            (click)="picker.open()"
            matSuffix
          >
            <mat-icon svgIcon="create"></mat-icon>
          </span>
        </mat-form-field>

        <mat-form-field
          class="col-span-1"
          appearance="legacy"
          *ngIf="
            data.requestType === 'offerRequest' &&
            data.title === 'servicePackage'
          "
        >
          <!-- prettier-ignore -->
          <input
            matInput
            readonly
            placeholder="{{ 'End date' | translate:{ _key: 'customerPortal.customer-portal.vehicle.servicepackage.endDate', _tags: 'customer-portal, 3.1'} }}"
            formControlName="endDate"
          />
        </mat-form-field>

        <mat-form-field class="col-span-2" appearance="legacy">
          <mat-label>
            <T
              str="Your message"
              key="customerPortal.customer-portal.leasing.manage.contact.yourMessage"
              tags="customer-portal, 3.1"
            ></T>
          </mat-label>
          <textarea
            rows="4"
            maxlength="512"
            formControlName="message"
            required
            matInput
          ></textarea>
          <mat-error *ngIf="warrantyContactForm.get('message').errors">
            {{
              transifexService.getErrorTranslation(
                warrantyContactForm.get('message'),
                'customerPortal.customer-portal.leasing.manage.contact.yourMessage.error.message.'
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <mat-divider></mat-divider>

  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>

    <button
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      cdkFocusInitial
      [disabled]="saving || !warrantyContactForm.valid"
      (click)="onSubmit()"
    >
      <mat-spinner *ngIf="saving" diameter="20"></mat-spinner>

      <T
        str="Send"
        key="customerPortal.customer-portal.leasing.contact.send"
        tags="customer-portal, 3.1"
      ></T>
    </button>
  </mat-dialog-actions>
</div>
