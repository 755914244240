<div class="cpt-modal relative dialog-content-p-0 mat-dialog-actions-pb-20">
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="Delete document"
      key="customerPortal.customer-portal.vehicle.documents-menu.delete-title"
      tags="customer-portal, 3.1"
    ></T>
  </h2>

  <mat-dialog-content class="mat-typography">
    <div class="address-form">
      <T
        str="Do you really want to delete document?"
        key="customerPortal.customer-portal.vehicle.documents-menu.delete-text"
        tags="customer-portal, 3.1"
      ></T>
    </div>
  </mat-dialog-content>
  <mat-divider class="mb-5"></mat-divider>
  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <button
      data-cy="confirm-vehicle-document-delete"
      class="revolution"
      color="warn"
      mat-button
      mat-flat-button
      (click)="onDeleteConfirmationSubmit()"
      [disabled]="disabled"
      cdkFocusInitial
    >
      <mat-spinner
        class="inline-block mr-2.5"
        [diameter]="16"
        *ngIf="disabled"
      ></mat-spinner>
      {{ 'clear' | sharedTranslate }}
    </button>
  </mat-dialog-actions>
</div>
