import { Inject, Injectable } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { CptSharedDataService } from './kc-shared-data.service'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { MatIconModuleConfig, MatIconModuleConfigService } from '../icon.module'

export interface Icon {
  name: string
  path: string
}

@Injectable({
  providedIn: 'root'
})
export class IconService {
  iconsPath: string
  useKcResourcePath: boolean
  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly kcSharedDataService: CptSharedDataService,
    public matIconRegistry: MatIconRegistry,
    @Inject(MatIconModuleConfigService)
    private readonly config: MatIconModuleConfig
  ) {
    this.iconsPath = this.config.iconsPath
    this.useKcResourcePath = this.config.useKcResourcePath
  }
  /* Add here your custom SVG icons to get registered as mat-icons
      Usage example:
      <mat-icon svgIcon="calendar_today"></mat-icon> */

  private readonly icons: Icon[] = [
    {
      name: 'Cabrio',
      path: 'Cabrio.svg'
    },
    {
      name: 'Coupe',
      path: 'Coupe.svg'
    },
    {
      name: 'Kilometerstand',
      path: 'Kilometerstand.svg'
    },
    {
      name: 'Kleinwagen',
      path: 'Kleinwagen.svg'
    },
    {
      name: 'Kombi',
      path: 'Kombi.svg'
    },
    {
      name: 'Limousine',
      path: 'Limousine.svg'
    },
    {
      name: 'Minibus',
      path: 'Minibus.svg'
    },
    {
      name: 'Nutzfahrzeug',
      path: 'Nutzfahrzeug.svg'
    },
    {
      name: 'Pickup',
      path: 'Pickup.svg'
    },
    {
      name: 'RV',
      path: 'RV.svg'
    },
    {
      name: 'SUV',
      path: 'SUV.svg'
    },
    {
      name: 'Sportwagen',
      path: 'Sportwagen.svg'
    },
    {
      name: 'account_circle',
      path: 'account_circle_24px.svg'
    },
    {
      name: 'add',
      path: 'add_24px.svg'
    },
    {
      name: 'add_photo_alternate',
      path: 'add_photo_alternate_24px.svg'
    },
    {
      name: 'add_to_photos',
      path: 'add_to_photos_24px.svg'
    },
    {
      name: 'alternate_email',
      path: 'alternate_email_24px.svg'
    },
    {
      name: 'arrow_forward_ios',
      path: 'arrow_forward_ios_24px.svg'
    },
    {
      name: 'arrow_right',
      path: 'arrow_forward_24px.svg'
    },
    {
      name: 'arrow_left',
      path: 'arrow_back_24px.svg'
    },
    {
      name: 'arrow_drop_down',
      path: 'arrow_drop_down_24px.svg'
    },
    {
      name: 'broken_image',
      path: 'broken_image_24px.svg'
    },
    {
      name: 'build',
      path: 'build_24px.svg'
    },
    {
      name: 'business',
      path: 'business_24px.svg'
    },
    {
      name: 'bulletpoint',
      path: 'bulletpoint_24px.svg'
    },
    {
      name: 'cake',
      path: 'cake_24px.svg'
    },
    {
      name: 'calendarDetails',
      path: 'calendarDetails.svg'
    },
    {
      name: 'calendar_today',
      path: 'calendar_today_24px.svg'
    },
    {
      name: 'carVin',
      path: 'carVin.svg'
    },
    {
      name: 'car_compare',
      path: 'car_compare_24px.svg'
    },
    {
      name: 'car_door',
      path: 'car_door_24px.svg'
    },
    {
      name: 'car_ins',
      path: 'car_ins_24px.svg'
    },
    {
      name: 'car_inspect',
      path: 'car_inspect_24px.svg'
    },
    {
      name: 'car_insurance',
      path: 'car_insurance_24px.svg'
    },
    {
      name: 'car_off',
      path: 'car_off_24px.svg'
    },
    {
      name: 'car_return',
      path: 'car_return_24px.svg'
    },
    {
      name: 'car_tire',
      path: 'car_tire_24px.svg'
    },
    {
      name: 'car_verified',
      path: 'car_verified_24px.svg'
    },
    {
      name: 'chat',
      path: 'chat_24px.svg'
    },
    {
      name: 'chat_outline',
      path: 'chat_bubble_outline_24px.svg'
    },
    {
      name: 'check_circle',
      path: 'check_circle_24px.svg'
    },
    {
      name: 'check_circle_outline',
      path: 'check_circle_outline_24px.svg'
    },
    {
      name: 'chevron_left',
      path: 'chevron_left_24px.svg'
    },
    {
      name: 'chevron_right',
      path: 'chevron_right_24px.svg'
    },
    {
      name: 'clear',
      path: 'clear_24px.svg'
    },
    {
      name: 'close',
      path: 'close_24px.svg'
    },
    {
      name: 'clipboard_check',
      path: 'clipboard_check_24px.svg'
    },
    {
      name: 'collections',
      path: 'collections_24px.svg'
    },
    {
      name: 'compare',
      path: 'item_compare_border_24px.svg'
    },
    {
      name: 'create',
      path: 'create_24px.svg'
    },
    {
      name: 'delete',
      path: 'delete_24px.svg'
    },
    {
      name: 'directions',
      path: 'directions_24px.svg'
    },
    {
      name: 'directions_car',
      path: 'directions_car_24px.svg'
    },
    {
      name: 'domain',
      path: 'domain_24px.svg'
    },
    {
      name: 'done',
      path: 'done_24px.svg'
    },
    {
      name: 'download',
      path: 'download_24px.svg'
    },
    {
      name: 'driverIcon',
      path: 'driverIcon.svg'
    },
    {
      name: 'driverIcon',
      path: 'driverIcon.svg'
    },
    {
      name: 'drivers_license',
      path: 'drivers_license_24px.svg'
    },
    {
      name: 'drive_eta',
      path: 'drive_eta_24px.svg'
    },
    {
      name: 'ef-logo-icon',
      path: 'ef-logo-icon.svg'
    },
    {
      name: 'electro_range',
      path: 'electro_range_24px.svg'
    },
    {
      name: 'email',
      path: 'email_24px.svg'
    },
    {
      name: 'email-open',
      path: 'email-open_24px.svg'
    },
    {
      name: 'engine',
      path: 'engine_24px.svg'
    },
    {
      name: 'error',
      path: 'error_24px.svg'
    },
    {
      name: 'error_outline',
      path: 'error_outline_24px.svg'
    },
    {
      name: 'event',
      path: 'event_24px.svg'
    },
    {
      name: 'event_add',
      path: 'event_add_24px.svg'
    },
    {
      name: 'event_busy',
      path: 'event_busy_24px.svg'
    },
    {
      name: 'exit_to_app',
      path: 'exit_to_app_24px.svg'
    },
    {
      name: 'expand_less',
      path: 'expand_less_24px.svg'
    },
    {
      name: 'expand_more',
      path: 'expand_more_24px.svg'
    },
    {
      name: 'facebook',
      path: 'facebook_36px.svg'
    },
    {
      name: 'favorite',
      path: 'favorite_24px.svg'
    },
    {
      name: 'favorite_border',
      path: 'favorite_border_24px.svg'
    },
    {
      name: 'filter_list',
      path: 'filter_list_24px.svg'
    },
    {
      name: 'flash_on',
      path: 'flash_on_24px.svg'
    },
    {
      name: 'format_list_bulleted',
      path: 'format_list_bulleted_24px.svg'
    },
    {
      name: 'fuel',
      path: 'fuel_24px.svg'
    },
    {
      name: 'grocery_cart',
      path: 'local_grocery_store_24px.svg'
    },
    {
      name: 'group',
      path: 'group_24px.svg'
    },
    {
      name: 'home',
      path: 'home_black_24dp.svg'
    },
    {
      name: 'info',
      path: 'info_24px.svg'
    },
    {
      name: 'insert_drive_file',
      path: 'insert_drive_file_24px.svg'
    },
    {
      name: 'instagram',
      path: 'instagram_36px.svg'
    },
    {
      name: 'keyboard_arrow_down',
      path: 'keyboard_arrow_down_24px.svg'
    },
    {
      name: 'keyboard_arrow_up',
      path: 'keyboard_arrow_up_24px.svg'
    },
    {
      name: 'kilometers',
      path: 'kilometers_24px.svg'
    },
    {
      name: 'language',
      path: 'language_24px.svg'
    },
    {
      name: 'link',
      path: 'link_24px.svg'
    },
    {
      name: 'linkedin',
      path: 'linkedin_36px.svg'
    },
    {
      name: 'location_on',
      path: 'location_on_24px.svg'
    },
    {
      name: 'lock',
      path: 'lock_24px.svg'
    },
    {
      name: 'library_books',
      path: 'library_books_24px.svg'
    },
    {
      name: 'mail',
      path: 'mail_24px.svg'
    },
    {
      name: 'menu',
      path: 'menu_24px.svg'
    },
    {
      name: 'mileage',
      path: 'mileage_24px.svg'
    },
    {
      name: 'monetization_on',
      path: 'monetization_on_24px.svg'
    },
    {
      name: 'more_vert',
      path: 'more_vert_24px.svg'
    },
    {
      name: 'move_to_inbox',
      path: 'move_to_inbox_24px.svg'
    },
    {
      name: 'my_location',
      path: 'my_location_24px.svg'
    },
    {
      name: 'no_sim',
      path: 'no_sim_24px.svg'
    },
    {
      name: 'offer',
      path: 'local_offer_24px.svg'
    },
    {
      name: 'paste',
      path: 'paste_24px.svg'
    },
    {
      name: 'palette',
      path: 'palette_24px.svg'
    },
    {
      name: 'pdf_document',
      path: 'pdf_document_24px.svg'
    },
    {
      name: 'person',
      path: 'person_24px.svg'
    },
    {
      name: 'person_circle',
      path: 'person_circle.svg'
    },
    {
      name: 'person_outline',
      path: 'person_outline_24px.svg'
    },
    {
      name: 'phone',
      path: 'phone_24px.svg'
    },
    {
      name: 'phone_callback',
      path: 'phone_callback_24px.svg'
    },
    {
      name: 'phone_iphone',
      path: 'phone_iphone_24px.svg'
    },
    {
      name: 'photo_filter',
      path: 'photo_filter_24px.svg'
    },
    {
      name: 'picture_as_pdf',
      path: 'picture_as_pdf_24px.svg'
    },
    {
      name: 'playlist_add_check',
      path: 'playlist_add_check_24px.svg'
    },
    {
      name: 'power',
      path: 'power_24px.svg'
    },
    {
      name: 'report',
      path: 'report_24px_outlined.svg'
    },
    {
      name: 'search',
      path: 'search_24px.svg'
    },
    {
      name: 'settings',
      path: 'settings_24px.svg'
    },
    {
      name: 'speaker_notes_off',
      path: 'speaker_notes_off_24px.svg'
    },
    {
      name: 'stay_current_portrait',
      path: 'stay_current_portrait_24px.svg'
    },
    {
      name: 'switz',
      path: 'switz.svg'
    },
    {
      name: 'thumbs_down',
      path: 'thumbs_down.svg'
    },
    {
      name: 'thumbs_down_line',
      path: 'thumbs_down_line.svg'
    },
    {
      name: 'thumbs_up_line',
      path: 'thumbs_up_line.svg'
    },
    {
      name: 'thumbs_up',
      path: 'thumbs_up.svg'
    },
    {
      name: 'tools',
      path: 'tools_24px.svg'
    },
    {
      name: 'track_trace_delivered_v1',
      path: 'delivered_v1.svg'
    },
    {
      name: 'track_trace_delivered_v2',
      path: 'delivered_v2.svg'
    },
    {
      name: 'track_trace_order_v1',
      path: 'order_v1.svg'
    },
    {
      name: 'track_trace_order_v2',
      path: 'order_v2.svg'
    },
    {
      name: 'track_trace_production_v1',
      path: 'production_v1.svg'
    },
    {
      name: 'track_trace_production_v2',
      path: 'production_v2.svg'
    },
    {
      name: 'track_trace_registered_v1',
      path: 'registered_v1.svg'
    },
    {
      name: 'track_trace_registered_v2',
      path: 'registered_v2.svg'
    },
    {
      name: 'transmission',
      path: 'transmission_24px.svg'
    },
    {
      name: 'tune',
      path: 'tune_24px.svg'
    },
    {
      name: 'turned_in',
      path: 'turned_in_24px.svg'
    },
    {
      name: 'turned_in_not',
      path: 'turned_in_not_24px.svg'
    },
    {
      name: 'Van',
      path: 'Van.svg'
    },
    {
      name: 'Van2',
      path: 'Van2.svg'
    },
    {
      name: 'vehicle',
      path: 'vehicle_24px.svg'
    },
    {
      name: 'vin',
      path: 'vin_24px.svg'
    },
    {
      name: 'visibility_off',
      path: 'visibility_off_24px.svg'
    },
    {
      name: 'visibility_on',
      path: 'visibility_24px.svg'
    },
    {
      name: 'vpn',
      path: 'vpn_key_24px.svg'
    },
    {
      name: 'warning',
      path: 'warning_24px.svg'
    },
    {
      name: 'warranty',
      path: 'warranty_24px.svg'
    },
    {
      name: 'watch_later',
      path: 'watch_later_24px.svg'
    },
    {
      name: 'weight',
      path: 'weight_24px.svg'
    },
    {
      name: 'wheel_drive',
      path: 'wheel_drive_24px.svg'
    },
    {
      name: 'youtube',
      path: 'youtube_36px.svg'
    }
  ]

  public registerIcons(): void {
    // For KC forms, we need to insert Keycloak resource path
    if (this.useKcResourcePath) {
      this.kcSharedDataService.resourcesPath.subscribe(path => {
        this.icons.forEach(i => {
          this.matIconRegistry.addSvgIcon(
            i.name,
            this.setPath(path + '/' + this.iconsPath + '/' + i.path)
          )
        })
      })
    } else {
      this.icons.forEach(i => {
        this.matIconRegistry.addSvgIcon(
          i.name,
          this.setPath(this.iconsPath + '/' + i.path)
        )
      })
    }
  }

  private setPath(url: string): SafeResourceUrl {
    // const finalUrl = window.location.origin + '/' + url
    const finalUrl = url
    const sanitized = this.domSanitizer.bypassSecurityTrustResourceUrl(finalUrl)
    return sanitized
  }
}
