import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { type Observable } from 'rxjs'
import { HashingService } from './hashing.service'
import { TokenService } from './token.service'
import { type hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { DataService } from './data.service'
import { NGXLogger } from 'ngx-logger'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { LocalStorageService } from './local-storage.service'

export type VehicleNotificationsDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.dashboard.dto.VehicleNotificationsDTO

@Injectable({
  providedIn: 'root'
})
export class VehicleNotificationsService {
  private readonly apiUrl

  constructor (
    private readonly http: HttpClient,
    private readonly hashingService: HashingService,
    private readonly tokenService: TokenService,
    private readonly dataService: DataService,
    private readonly logger: NGXLogger,
    private readonly localStorage: LocalStorageService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  getVehiclesNotifications (): Observable<VehicleNotificationsDTO> {
    const token = this.tokenService.getToken()
    let params = new HttpParams()
    params = params.append('c', this.hashingService.hashCode(token.cp_id))
    return this.http.get<VehicleNotificationsDTO>(
      this.apiUrl + '/vehicles-notifications/',
      {
        params
      }
    )
  }

  setVehicleNotifications (
    notifications: VehicleNotificationsDTO,
    vin: string
  ): VehicleNotificationsDTO {
    let result = null
    if (notifications?.vehicleNotifications != null) {
      let vehicleNotifications = null
      vehicleNotifications = notifications.vehicleNotifications.find(el => {
        return el.vin === vin
      })
      if (vehicleNotifications != null) {
        const distinctTypeNotifications = []
        // remove duplicate types
        vehicleNotifications.notifications.forEach(notification => {
          this.distinctTypeNotifications_add(
            notification,
            distinctTypeNotifications
          )
        })
        // remove standard warranty if extended warranty exists
        this.removeStandardWarranty(distinctTypeNotifications)
        result = distinctTypeNotifications
        result = result.sort((a, b) => {
          if (a.type === 'ERR' && b.type === 'WARN') {
            return -1
          }
          if (b.type === 'ERR' && b.type === 'WARN') {
            return 1
          }
          return 0
        })
      }
    }
    return result
  }

  distinctTypeNotifications_add (obj, list): void {
    for (let i = 0; i < list.length; i++) {
      if (list[i].scope === obj.scope) {
        try {
          if (
            obj.data[0].key === 'endDate' &&
            list[i].data[0].key === 'endDate'
          ) {
            const d1 = new Date(obj.data[0].value)
            const d2 = new Date(list[i].data[0].value)
            if (d1 > d2) {
              Object.assign(list[i], obj)
            }
          }
        } catch (error) {
          Object.assign(list[i], obj)
        }
        return
      }
    }
    list.push(obj)
  }

  removeStandardWarranty (list): void {
    const standardWarrantyIndex = this.arrayObjectIndexOf(
      list,
      'WARRANTY',
      'scope'
    )
    const extendedWarrantyIndex = this.arrayObjectIndexOf(
      list,
      'EXTENDED_WARRANTY',
      'scope'
    )
    if (standardWarrantyIndex !== -1 && extendedWarrantyIndex !== -1) {
      list.splice(standardWarrantyIndex, 1)
    }
  }

  arrayObjectIndexOf (myArray, searchTerm, property): number {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i][property] === searchTerm) return i
    }
    return -1
  }

  async getVehiclesNotificationsFun (): Promise<void> {
    // show data from local storage, will never show loading
    let vehicleNotifications: VehicleNotificationsDTO = null
    try {
      vehicleNotifications = (await this.localStorage.getVehicleNotifications())
    } catch (error) {
      this.logger.debug(error)
    }

    this.dataService.vehicleNotificationsLoaded(vehicleNotifications)
    this.dataService.loading.vehicleNotifications.next(false)

    this.getVehiclesNotifications().subscribe(
      notifications => {
        this.dataService.vehicleNotificationsLoaded(notifications)
        this.dataService.loading.vehicleNotifications.next(false)
        void this.localStorage.setVehicleNotifications(notifications, true)
      },
      error => {
        this.logger.debug(error)
        this.dataService.vehicleNotificationsLoaded(null)
        this.dataService.loading.vehicleNotifications.next(false)
        void this.localStorage.setVehicleNotifications(null, true)

        // // mock data - replace vin in mock data
        // this.dataService.vehicleNotificationsLoaded(NOTIFICATIONS as any)
      }
    )
  }
}
