<div
  id="VehicleEnergyComponent"
  class="grid"
  *ngIf="
    vehicleDetailed?.details?.consumptionCombined ||
    vehicleDetailed?.details?.consumptionUrban ||
    vehicleDetailed?.details?.consumptionExtra ||
    vehicleDetailed?.details?.co2Emission ||
    vehicleDetailed?.details?.energyLabel
  "
>
  <div
    class="text-lg md:text-xl font-bold mb-7 text-mat-ef-revolution-primary-500 mt-5"
  >
    <T
      str="Environmental & Energy Data"
      key="customerPortal.customer-portal.vehicle.environmental.and.energy.data"
      tags="customer-portal, 3.1"
    ></T>
  </div>

  <div class="p-0">
    <div class="grid grid-cols-1 md:grid-cols-10 gap-6">
      <div class="col-span-1 md:col-span-5">
        <ul class="pl-5">
          <li
            *ngIf="vehicleDetailed?.details?.consumptionCombined"
            class="py-1 flex justify-between"
          >
            <span
              class="font-efSans text-sm sm:text-base text-mat-ef-revolution-grey-two-500"
            >
              <T
                str="Combined"
                key="customerPortal.customer-portal.vehicle.details.extended.technical.consumption.combined"
                tags="customer-portal, 3.1"
              ></T
            ></span>
            <span
              class="font-efSans text-sm sm:text-base text-mat-ef-revolution-primary-500"
              >{{
                vehicleDetailed?.details.consumptionCombined | number: '1.0-2'
              }}
              l/100km
            </span>
          </li>
          <li
            *ngIf="vehicleDetailed?.details?.consumptionUrban"
            class="py-1 flex justify-between"
          >
            <span
              class="font-efSans text-sm sm:text-base text-mat-ef-revolution-grey-two-500"
            >
              <T
                str="Urban"
                key="customerPortal.customer-portal.vehicle.details.extended.technical.consumption.urban"
                tags="customer-portal, 3.1"
              ></T
            ></span>
            <span
              class="font-efSans text-sm sm:text-base text-mat-ef-revolution-primary-500"
              >{{ vehicleDetailed?.details.consumptionUrban }}
              l/100km
            </span>
          </li>
          <li
            *ngIf="vehicleDetailed?.details?.consumptionExtra"
            class="py-1 flex justify-between"
          >
            <span
              class="font-efSans text-sm sm:text-base text-mat-ef-revolution-grey-two-500"
            >
              <T
                str="Extra-urban"
                key="customerPortal.customer-portal.vehicle.details.extended.technical.consumption.extraurban"
                tags="customer-portal, 3.1"
              ></T
            ></span>
            <span
              class="font-efSans text-sm sm:text-base text-mat-ef-revolution-primary-500"
              >{{ vehicleDetailed?.details.consumptionExtra }} l/100km</span
            >
          </li>
          <li
            *ngIf="vehicleDetailed?.details?.co2Emission"
            class="py-1 flex justify-between"
          >
            <span
              class="font-efSans text-sm sm:text-base text-mat-ef-revolution-grey-two-500"
            >
              <T
                str="CO2 emissions:"
                key="customerPortal.customer-portal.vehicle.details.extended.technical.coemission"
                tags="customer-portal, 3.1"
              ></T
            ></span>
            <span
              class="font-efSans text-sm sm:text-base text-mat-ef-revolution-primary-500"
              >{{ vehicleDetailed?.details.co2Emission }} g/km</span
            >
          </li>
        </ul>
        <div
          *ngIf="
            !vehicleDetailed?.details?.consumptionCombined &&
            !vehicleDetailed?.details?.consumptionUrban &&
            !vehicleDetailed?.details?.consumptionExtra &&
            !vehicleDetailed?.details?.co2Emission
          "
          class="w-full h-36 bg-mat-ef-revolution-grey-200 text-mat-ef-revolution-primary-500 rounded-xl flex flex-col justify-center items-center"
        >
          <div class="text-mat-ef-revolution-supergrey-900">
            <mat-icon svgIcon="no_sim"></mat-icon>
          </div>

          <T
            str="Data on energy efficiency is not available"
            key="customerPortal.customer-portal.energy-efficiency.not"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </div>

      <div
        class="border-2 border-black w-full md:w-80 p-1.5 col-span-1 md:col-span-3"
        *ngIf="vehicleDetailed?.details?.energyLabel"
      >
        <span class="flex items-center border-b-2 border-black mb-4 ef-lead">
          <mat-icon
            svgIcon="switz"
            class="text-mat-ef-energy-efficiency-ch-g-500"
          ></mat-icon>

          <T
            str="Energy label"
            key="customerPortal.customer-portal.energy.labels"
            tags="customer-portal, 3.1"
          ></T>
          {{ year }}
        </span>

        <div class="flex flex-1 px-2.5 pb-2.5">
          <div class="flex flex-1 flex-col gap-1.5">
            <span
              *ngFor="let energyEfficiency of energyEfficiencies"
              color="primary"
              class="energy-efficiency-class h-9 pr-5 text-white uppercase text-end text-xl font-bold"
              [ngClass]="efficiencyClassArrowColor(energyEfficiency)"
            >
              {{ energyEfficiency }}
            </span>
          </div>
          <div class="energy-levels-arrows flex flex-col gap-1.5">
            <span
              *ngFor="let energyEfficiency of energyEfficiencies"
              class="h-9"
            >
              <ng-container
                *ngIf="
                  energyEfficiency?.toLowerCase() ===
                  vehicleDetailed?.details?.energyLabel?.toLowerCase()
                "
              >
                <div class="h-full text-white">
                  <div
                    class="arrow w-14 sm:w-16 h-9 l-0 t-0 px-3.5 uppercase text-end mb-5 bg-mat-ef-revolution-primary-500 text-xl font-bold text-white"
                  >
                    {{ energyEfficiency }}
                  </div>
                </div>
              </ng-container>
            </span>
          </div>
        </div>
      </div>

      <div
        *ngIf="!vehicleDetailed?.details?.energyLabel"
        class="col-span-1 md:col-span-5 w-full h-36 bg-mat-ef-revolution-grey-200 rounded-xl flex flex-col justify-center items-center"
      >
        <div class="text-mat-ef-revolution-supergrey-900">
          <mat-icon svgIcon="no_sim"></mat-icon>
        </div>

        <T
          str="Data on energy efficiency is not available"
          key="customerPortal.customer-portal.energy-efficiency.not"
          tags="customer-portal, 3.1"
        ></T>
      </div>
    </div>
  </div>
</div>
