import { Component, type OnDestroy, type OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { CptGoogleTagmanagerService, TransifexService } from '@inside-hub-app/customer-portal-shared'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from '../../../../services/shared.service'
import {
  AppointmentsService,
  type UpcomingAppointmentDTO
} from '../../../../services/appointments.service'
import { DataService } from '../../../../services/data.service'
import { type VehicleNotificationsDTO } from '../../../../services/vehicle-notifications.service'
import { type VehicleDTOExtended } from '../../../../services/vehicles.service'
import { AppointmentsPopupComponent } from '../../../revolution/appointments-popup/appointments-popup.component'
import { LocalStorageService } from '../../../../services/local-storage.service'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'customer-portal-app-notifications-panel',
  templateUrl: './notifications-panel.component.html'
})
export class NotificationsPanelComponent implements OnInit, OnDestroy {
  appointments: UpcomingAppointmentDTO[]
  userVehicles: VehicleDTOExtended[]

  notifications: VehicleNotificationsDTO

  loadingNotifications

  loadingAppointments

  sub = {
    onVehicleNotificationsLoaded: null,
    onUpcomingAppointmentsLoaded: null,
    onUserVehiclesLoaded: null,
    onVehicleVinChange: null,
    route: null,
    vehicleNotificationsLoading: null,
    upcomingAppointmentsLoading: null,
    onLocalStorageDataChanged: null
  }

  constructor (
    private readonly dataService: DataService,
    private readonly sharedService: SharedService,
    private readonly logger: NGXLogger,
    public appointmentsService: AppointmentsService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly router: Router,
    private readonly localStorage: LocalStorageService,
    private readonly dialog: MatDialog,
    public transifexService: TransifexService
  ) {}

  ngOnInit (): void {
    this.sub.onVehicleNotificationsLoaded =
      this.dataService.onVehicleNotificationsLoaded.subscribe(notifications => {
        if (notifications != null) {
          this.notifications = notifications
        }
      })
    this.sub.vehicleNotificationsLoading =
      this.dataService.loading.vehicleNotifications.subscribe(loading => {
        this.loadingNotifications = loading
      })

    this.sub.upcomingAppointmentsLoading =
      this.dataService.loading.upcomingAppointments.subscribe(loading => {
        this.loadingAppointments = loading
      })

    this.sub.onUpcomingAppointmentsLoaded =
      this.dataService.onUpcomingAppointmentsLoaded.subscribe(appointments => {
        this.appointments = appointments
        this.appointmentsService.setImg(this.userVehicles, this.appointments)
      })
    this.sub.onUserVehiclesLoaded =
      this.dataService.onUserVehiclesLoaded.subscribe(userVehicles => {
        this.userVehicles = userVehicles
        this.appointmentsService.setImg(this.userVehicles, this.appointments)
      })

    this.sub.onLocalStorageDataChanged =
      this.localStorage.onLocalStorageDataChanged.subscribe(data => {
        this.showDataIsChangedToast(data)
      })
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  action (selectedFunction): void {
    try {
      this[selectedFunction.name](selectedFunction.params)
    } catch (error) {
      this.logger.log(error)
    }
  }

  goToVehicleNeedsAttention (vehicle: VehicleDTOExtended): void {
    const vin = vehicle.vin
    void this.router.navigate(['/vehicles', vin])
  }

  goToAppointments (): void {
    void this.router.navigate(['/appointments'])
  }

  newAppointment (): void {
    this.appointmentsService.newAppointment(AppointmentsPopupComponent)
  }

  showDataIsChangedToast (changed: string): void {
    // dont show notice if dialog or snackbar is open
    if (!this.dialogIsOpen() && this.sharedService.getSnackbarRef() == null) {
      if (changed === 'notifications' || changed === 'upcomingAppointments') {
        const dataChanged = this.transifexService.getTranslation({ key: 'customerPortal.customer-portal.data-changed' })
        const panel = this.transifexService.getTranslation({ key: 'customerPortal.customer-portal.notification-panel' })
        this.sharedService.showSnackbar(
          null,
          panel + ' - ' + dataChanged
        )
      }
    }
  }

  dialogIsOpen (): boolean {
    return Boolean(this.dialog?.openDialogs?.length > 0)
  }
}
