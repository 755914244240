<ng-container
  *ngIf="userDataMissing && !hideUserNotice && showMissingUserDataNotification"
>
  <div class="container select-none">
    <mat-card
      class="flex flex-col items-center justify-between rounded-md w-full m-auto mb-1 bg-mat-ef-revolution-grey-500"
      (click)="mode !== 'summary' || goToUserDetails()"
      [ngClass]="{
        ' p-6 md:flex-row cursor-pointer lg:w-3/5 py-2.5 px-6 md:pr-2.5 gap-2 md:gap-6':
          mode === 'summary',
        'gap-4 p-4': mode === 'generalUserData'
      }"
    >
      <div
        class="flex flex-col"
        [ngClass]="{ 'gap-1': mode === 'generalUserData' }"
      >
        <div class="w-full overflow-hidden whitespace-nowrap text-ellipsis">
          <div
            class="flex font-efSans text-xl font-black text-mat-ef-revolution-primary-500"
          >
            <span>
              <T
                str="Profile Completeness"
                key="customerPortal.customer-portal.summary.notice.title"
                tags="customer-portal, 3.1"
              ></T
            ></span>
          </div>
        </div>
        <div class="text-sm font-efSans-primary">
          <T
            str="You can save time the next time you visit a dealer by completing your profile."
            key="customerPortal.customer-portal.summary.notice.text"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </div>

      <div
        class="text-center w-full whitespace-nowrap text-mat-ef-revolution-primary-500 text-base font-efSans cursor-pointer bg-white px-4 py-1 rounded-2xl font-semibold"
        [ngClass]="{
          'md:w-fit': mode === 'summary'
        }"
        (click)="clearNotice($event)"
      >
        <T
          str="Hide hint"
          key="customerPortal.customer-portal.summary.notice.hide"
          tags="customer-portal, 3.1"
        ></T>
      </div>
    </mat-card>
  </div>
</ng-container>
