<google-map
  *ngIf="apiLoaded | async"
  [height]="height"
  width="100%"
  [zoom]="zoom"
  [center]="center"
  [options]="options"
  (mapInitialized)="onMapReady($event)"
>
  <map-marker
    *ngFor="let marker of markers"
    [position]="marker.position"
    [options]="marker.options"
    (mapClick)="onMarkerClicked(marker)"
    #mapMarker1
  ></map-marker>
  <map-marker
    *ngFor="let marker of markersSelected"
    [position]="marker.position"
    [options]="marker.options"
    (mapClick)="onMarkerClicked(marker)"
    #mapMarker2
  ></map-marker>
</google-map>
