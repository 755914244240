/* eslint-disable @typescript-eslint/member-ordering */
import {
  type AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  Optional,
  ViewChild
} from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { TransifexService } from '../../../services/transifex.service'
import { type TransifexTranslationObj } from '../../../services/shared.service'

export interface EfSpinnerDialogData {
  diameter?: number
  opacity?: number
  color?: string
  message?: TransifexTranslationObj
}

@Component({
  selector: 'customer-portal-ef-spinner',
  templateUrl: './ef-spinner.component.html'
})
export class EfSpinnerComponent implements AfterViewInit {
  @Input() diameter = 50
  @Input() opacity = 1

  transifexReady = false
  ngClassColor = 'text-mat-ef-revolution-supergrey-500'
  styleColor = '#000e3d'
  message: TransifexTranslationObj
  @Input() set color (string: string) {
    this.setColor(string)
  }

  @ViewChild('colorContainer') _colorContainer: any

  constructor (
    private readonly cdRef: ChangeDetectorRef,
    private readonly transifexService: TransifexService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: EfSpinnerDialogData
  ) {
    if (data != null) {
      this.message = data.message
      if (data.diameter != null) {
        this.diameter = data.diameter
      }
      if (data.opacity != null) {
        this.opacity = data.opacity
      }
      if (data.color != null) {
        this.setColor(data.color)
      }
    }
  }

  ngAfterViewInit (): void {
    if (this.ngClassColor != null) {
      this.styleColor =
        getComputedStyle(this._colorContainer?.nativeElement)?.color ??
        this.styleColor
      this.cdRef.detectChanges()
    }
    this.transifexService.transifexLanguageIsLoading.subscribe((loading) => {
      this.transifexReady = !loading
    })
  }

  getStyle (): string {
    return '--color: ' + this.styleColor + '; opacity: ' + this.opacity
  }

  setColor (string: string): void {
    if (string.startsWith('mat-')) {
      this.ngClassColor = 'text-' + string
    } else {
      this.ngClassColor = undefined
      this.styleColor = string
    }
  }
}
