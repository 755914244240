<div class="h-full w-full">
  <div class="fixed bottom-0 w-full inline-block z-[9999]">
    <div
      class="bg-mat-ef-revolution-primary-500 bg-opacity-95 text-white justify-center align-middle font-efSans mb-0 m-2 p-4 flex rounded-lg"
    >
      <div class="absolute p-2 left-2 top-4">
        <button class="flex-none" (click)="dismissBanner($event)">
          <mat-icon svgIcon="close"></mat-icon>
        </button>
      </div>
      <span class="text-center indent-1 leading-6">
        <T
          str="Install this webapp on your iPhone for best experience. Just tap here and press 'Add to home screen'"
          key="customerPortal.customer-portal.pwa.ios-add-to-home-screen"
          tags="customer-portal, 3.3"
        ></T>
      </span>
    </div>
    <div class="w-full flex justify-center z-50">
      <div
        class="h-0 w-0 border-x-[16px] border-l-transparent border-r-transparent border-y-transparent border-t-[16px] border-t-mat-ef-revolution-primary-500 opacity-95"
      ></div>
    </div>
  </div>
</div>
