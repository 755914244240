import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
// import { BOOKMARKS } from '../mock/bookmarks'
// import { COMPARE } from '../mock/compare'
import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'

export type CarmatoVehicleDataDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.carmato.dto.CarmatoVehicleDataDTO
export type CarmatoCompareVehicleDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.carmato.dto.CarmatoCompareVehicleDTO
type CarmatoWatchlistDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.carmato.dto.CarmatoWatchlistDTO
export interface CarmatoWatchlistDTOExtended extends CarmatoWatchlistDTO {
  selectedForCompare?: boolean
}
export type CarmatoValuationDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.carmato.dto.CarmatoValuationDTO
export type CarmatoSearchFilterDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.carmato.dto.CarmatoSearchFilterDTO
export type CarmatoSearchAgentDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.carmato.dto.CarmatoSearchAgentDTO

export interface CarmatoSearchParamMapping {
  key: string
  value: string
}

@Injectable({
  providedIn: 'root'
})
export class CarmatoService {
  private readonly apiUrl

  private readonly _watchlist = new BehaviorSubject<
    CarmatoWatchlistDTOExtended[]
  >(null)
  onWatchListLoaded = this._watchlist.asObservable()

  private readonly CarmatoSearchAgentParamMaping: CarmatoSearchParamMapping[] =
    [
      {
        key: 'newCars',
        value: 'artofuse_neuwagen'
      },
      {
        key: 'usedCars',
        value: 'artofuse_gebrauchtwagen'
      },
      {
        key: 'youngCars',
        value: 'artofuse_jungwagen'
      },
      {
        key: 'manufacturerNames',
        value: 'manufacturer_name'
      },
      {
        key: 'baseModelNames',
        value: 'base_model_name'
      },
      {
        key: 'customSearch',
        value: 'custom_search'
      },
      {
        key: 'vehicleTypeNames',
        value: 'vehicle_type_name'
      },
      {
        key: 'rateRange',
        value: 'rate'
      },
      {
        key: 'priceCustomerRange',
        value: 'price_customer'
      },
      {
        key: 'percent',
        value: 'percent'
      },
      {
        key: 'exteriorColors',
        value: 'exterior_color'
      },
      {
        key: 'initialRegistrationYearRange',
        value: 'initial_registration_year'
      },
      {
        key: 'powerHpRange',
        value: 'power_hp'
      },
      {
        key: 'withoutImages',
        value: 'ohnebilder'
      },
      {
        key: 'vatable',
        value: 'mwst_able'
      },
      {
        key: 'currentMilesRange',
        value: 'current_miles'
      },
      {
        key: 'fuelMethods',
        value: 'fuel_method'
      },
      {
        key: 'gearboxTypes',
        value: 'gearbox_type'
      },
      {
        key: 'drives',
        value: 'drive'
      },
      {
        key: 'equipments',
        value: 'equipment'
      },
      {
        key: 'vehicleDoorsFrom',
        value: 'vehicle_doors'
      },
      {
        key: 'vehicleDoorsTo',
        value: 'vehicle_doors_2'
      },
      {
        key: 'vehicleSeatsFrom',
        value: 'vehicle_seats'
      },
      {
        key: 'vehicleSeatsTo',
        value: 'vehicle_seats_2'
      },
      {
        key: 'consumptionRange',
        value: 'consumption'
      },
      {
        key: 'coEmissionRange',
        value: 'co_emission'
      },
      {
        key: 'tankVolumeRange',
        value: 'tank_volume'
      },
      {
        key: 'distanceRange',
        value: 'distance'
      },
      {
        key: 'speedUpRange',
        value: 'speed_up'
      },
      {
        key: 'maxSpeedRange',
        value: 'max_speed'
      },
      {
        key: 'luggageSpaceMaxRange',
        value: 'luggage_space_max'
      },
      {
        key: 'luggageSpaceRange',
        value: 'luggage_space'
      },
      {
        key: 'uriPath',
        value: 'uri'
      },
      {
        key: 'filters',
        value: 'filters'
      },
      {
        key: 'name',
        value: 'name'
      }
    ]

  constructor(
    private readonly http: HttpClient,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  compareVehicles(uids: string[]): Observable<CarmatoCompareVehicleDTO[]> {
    let params = new HttpParams()
    uids.forEach(u => {
      params = params.append('uid', u)
    })
    // return of(COMPARE)
    return this.http.get<CarmatoCompareVehicleDTO[]>(
      `${this.apiUrl}/carmato/compare-vehicles`,
      {
        params
      }
    )
  }

  getWatchlist(): Observable<CarmatoWatchlistDTOExtended[]> {
    // return of(BOOKMARKS) // Mock data
    return this.http.get<CarmatoWatchlistDTOExtended[]>(
      `${this.apiUrl}/carmato/watchlist`
    )
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  unsubscribeFromWatchList(vehicleId: string) {
    const apiUrl =
      this.apiUrl + '/carmato/watchlist/' + vehicleId + '/unsubscribe'
    return this.http.delete(apiUrl)
  }

  getValuations(): Observable<CarmatoValuationDTO[]> {
    return this.http.get<CarmatoValuationDTO[]>(
      `${this.apiUrl}/carmato/valuations`
    )
  }

  getSearchAgent(): Observable<CarmatoSearchAgentDTO[]> {
    return this.http.get<CarmatoSearchAgentDTO[]>(
      `${this.apiUrl}/carmato/searchagent`
    )
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  deleteSearchAgent(searchAgentId: string) {
    const apiUrl = this.apiUrl + '/carmato/searchagent/' + searchAgentId
    return this.http.delete(apiUrl)
  }

  watchListLoaded(watchList: CarmatoWatchlistDTOExtended[]): void {
    this._watchlist.next(watchList)
  }

  getSearchAgentParamMappings(): Observable<CarmatoSearchParamMapping[]> {
    return of(this.CarmatoSearchAgentParamMaping)
  }
}
