import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import {
  DealerDTO,
  DealerUserDTO,
  DealersService
} from '../../../services/dealers.service'
import { SharedService } from '../../../services/shared.service'
import {
  VehicleDTOExtended,
  VehiclesService
} from '../../../services/vehicles.service'
import { ConfirmationPopupComponent } from '../../basic/confirmation-popup/confirmation-popup.component'

import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import {
  MatomoIntegrationService
} from '@inside-hub-app/customer-portal-shared'

export type DealerUserFunctionEnum =
  cpt.emilfreydigital.customer_portal_backend.rest.dealer.dto.DealerUserFunctionEnum

interface DialogData {
  vehicle: VehicleDTOExtended
  dealer: DealerDTO
  employee: DealerUserDTO
  employeeType: DealerUserFunctionEnum
}

@Component({
  selector: 'customer-portal-app-change-salesman-popup',
  templateUrl: './change-salesman-popup.component.html'
})
export class ChangeSalesmanPopupComponent implements OnInit {
  vehicle: VehicleDTOExtended
  dealer: DealerDTO
  employees: DealerUserDTO[] = []
  salesmanForm: FormGroup = new FormGroup({
    salesmanFormControl: new FormControl<string | null>(null)
  })

  salesman: DealerUserDTO
  saving = false
  employeeType: DealerUserFunctionEnum
  selectedEmployee: DealerUserDTO
  selectedEmployeeId: number
  loading = true
  profileImageUrlPrefix

  constructor (
    private readonly dealersService: DealersService,
    private readonly vehicleService: VehiclesService,
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<ChangeSalesmanPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public sharedService: SharedService,
    private readonly matomoIntegrationService: MatomoIntegrationService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.salesmanForm.controls.salesmanFormControl.setValue(
      this.data.employee.id
    )
    this.profileImageUrlPrefix =
    'https://' +
    this.remoteConfigService.get('ppEnvironments.cpt.environment.domain')
  }

  getEmployees (dealer: DealerDTO): void {
    this.loading = true
    const role = this.employeeType
    this.dealersService
      .getDealerEmployees(dealer.id, this.vehicle.brand, {
        role
      })
      .subscribe(response => {
        this.loading = false
        this.employees = response.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
        this.salesmanForm
          .get('salesmanFormControl')
          .setValue(this.selectedEmployee.id)
      })
  }

  onEmployeeSelected (e: DealerUserDTO): void {
    this.selectedEmployee = e

    if (this.employeeType === 'SALESMAN') {
      this.vehicle.dealers.salesDealer.salesman = this.selectedEmployee
    }
    if (this.employeeType === 'SALES_ADVISOR') {
      // If there is no serviceDealer, it menas it is the same as salesDealer
      if (this.vehicle.dealers.serviceDealer == null) {
        this.vehicle.dealers.serviceDealer = {
          ...this.vehicle.dealers.salesDealer
        }
      }
      this.vehicle.dealers.serviceDealer.salesAdvisor = this.selectedEmployee
    }
  }

  saveChanges (): void {
    this.saving = true
    const vin = this.vehicle.vin
    const dealerId = this.dealer.id
    const salesmanId = this.vehicle.dealers.salesDealer.salesman.id
    let salesAdvisorId = null

    if (this.vehicle.dealers.serviceDealer == null) {
      this.vehicle.dealers.serviceDealer = {
        ...this.vehicle.dealers.salesDealer
      }
      if (this.vehicle.dealers.serviceDealer.salesAdvisor != null) {
        salesAdvisorId = this.vehicle.dealers.serviceDealer.salesAdvisor.id
      }
    } else {
      if (this.vehicle.dealers.serviceDealer.salesAdvisor != null) {
        salesAdvisorId = this.vehicle.dealers.serviceDealer.salesAdvisor.id
      }
    }

    this.vehicleService
      .updateVehiclePrefferedDealerContacts(
        vin,
        dealerId,
        salesmanId,
        salesAdvisorId
      )
      .subscribe(
        response => {
          this.saving = false
          if (response.serviceDealer == null) {
            response.serviceDealer = { ...response.salesDealer }
          }
          this.dialog.open(ConfirmationPopupComponent, {
            data: {
              title: 'shared.successful',
              text: 'customerPortal.customer-portal.change-preferred-contact.success'
            },
            panelClass: 'mat-dialog-cpt'
          })
          this.dialogRef.close({
            dealers: response,
            employeeType: this.employeeType
          })
        },
        () => {
          this.saving = false
          this.dialog.open(ConfirmationPopupComponent, {
            data: {
              title: 'shared.error',
              text: 'customerPortal.customer-portal.change-preferred-contact.failure'
            },
            panelClass: 'mat-dialog-cpt'
          })
          this.dialogRef.close()
        }
      )
  }

  ngOnInit (): void {
    this.vehicle = this.data.vehicle
    this.dealer = this.data.dealer
    this.employeeType = this.data.employeeType
    this.selectedEmployee = this.data.employee
    this.selectedEmployeeId = this.selectedEmployee.id
    this.getEmployees(this.dealer)

    this.matomoIntegrationService.trackEvent(
      'Vehicle action',
      'Change contact person click',
      this.matomoIntegrationService.formatVehicleData(this.vehicle)
    )
  }
}
