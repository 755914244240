<div class="cpt-modal relative dialog-content-p-0 mat-dialog-actions-pb-20">
  <div mat-dialog-title class="!text-mat-ef-revolution-primary-500">
    <T
      str="Select dealer"
      key="customerPortal.customer-portal.change-dealer-seller.select-dealer"
      tags="customer-portal, 3.1"
    ></T>
  </div>

  <mat-dialog-content class="flex flex-col">
    <ngx-skeleton-loader
      *ngIf="dealersLoading"
      appearance="line"
      animation="progress"
    ></ngx-skeleton-loader>

    <form
      data-cy="preferred-dealer-form"
      [formGroup]="dealerSellerForm"
      *ngIf="!dealersLoading"
      class="flex flex-col gap-4"
    >
      <!-- Sales -->
      <div class="flex flex-col gap-4">
        <div
          class="flex-1 flex flex-col md:gap-6 md:flex-row md:justify-between"
        >
          <div
            class="flex-1 text-base text-mat-ef-revolution-primary-500 font-bold font-efSans"
          >
            <ng-container *ngIf="!sameDealerAndService">
              {{ 'sale' | sharedTranslate }}
            </ng-container>
            <ng-container *ngIf="sameDealerAndService">
              <T
                str="Sales and service"
                key="customerPortal.customer-portal.change-dealer-seller.sales-and-service"
                tags="customer-portal, 3.1"
              ></T>
            </ng-container>

            <ng-container
              *ngIf="checkbox_carDealer?.checked; else officialDealersText"
            >
              <ng-container *ngTemplateOutlet="allDealersText"> </ng-container>
            </ng-container>
          </div>

          <div>
            <mat-checkbox
              data-cy="preferred-dealer-form-sales-checkbox"
              class="h-6 w-full text-sm"
              color="primary"
              #checkbox_carDealer
              (change)="addToOfficialDealer()"
            >
              <T
                str="Show all dealers"
                key="customerPortal.customer-portal.change-dealer-seller.show-all-dealers"
                tags="customer-portal, 3.1"
              ></T>
            </mat-checkbox>
          </div>
        </div>
        <div class="flex gap-4 flex-col md:flex-row">
          <div
            *ngIf="
              checkbox_carDealer?.checked
                ? allDealersLoadingError
                : dealersLoadingError
            "
            class="px-4 border border-mat-ef-revolution-grey-200 rounded flex-1 flex items-center text-base text-mat-ef-revolution-primary-500"
          >
            <T
              str="This car brand has no dealers"
              key="customerPortal.customer-portal.change-dealer-seller.no-dealer"
              tags="customer-portal, 3.1"
            ></T>
          </div>
          <ng-container
            *ngIf="
              !(checkbox_carDealer?.checked
                ? allDealersLoadingError
                : dealersLoadingError)
            "
          >
            <mat-form-field class="flex-1 relative">
              <div *ngIf="isMobile===true" class="absolute top-0 right-0 bottom-0 left-0 cursor-pointer"
                (click)="openSelectOptionsPopup($event,checkbox_carDealer,'sales')">
              </div>
              <mat-label>
                <T
                  str="Select dealer"
                  key="customerPortal.customer-portal.change-dealer-seller.select-dealer"
                  tags="customer-portal, 3.1"
                ></T>
              </mat-label>
              <input
                data-cy="preferred-dealer-form-sales-select"
                required
                formControlName="carDealer"
                type="text"
                aria-label="Number"
                matInput
                [matAutocomplete]="auto"
                [errorStateMatcher]="matcher"
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="sharedService.formatDealerName"
              >
                <mat-option *ngIf="salesDealer?.id" [value]="salesDealer">
                  {{ sharedService.formatDealerName(salesDealer) }}
                  (<ng-container
                    *ngTemplateOutlet="prefferedDealer"
                  ></ng-container
                  >)
                </mat-option>
                <mat-option
                  *ngFor="
                    let d of checkbox_carDealer?.checked
                      ? filteredAllDealers
                      : filteredDealers
                  "
                  [value]="d"
                >
                  {{ sharedService.formatDealerName(d) }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </ng-container>

          <button
            mat-button
            mat-stroked-button
            (click)="onDealerChangeClick('sales', salesDealer)"
            class="revolution"
            color="secondary"
          >
            <T
              str="Find a dealership"
              key="customerPortal.customer-portal.change-dealer-seller.find-dealer"
              tags="customer-portal, 3.1"
            ></T>
          </button>
        </div>
      </div>

      <!-- Service -->
      <div *ngIf="!sameDealerAndService" class="flex flex-col gap-4">
        <div
          class="flex-1 flex flex-col md:gap-6 md:flex-row md:justify-between"
        >
          <div
            class="flex-1 text-base text-mat-ef-revolution-primary-500 font-bold font-efSans"
          >
            {{ 'service' | sharedTranslate }}

            <ng-container
              *ngIf="checkbox_carService?.checked; else officialDealersText"
            >
              <ng-container *ngTemplateOutlet="allDealersText"> </ng-container>
            </ng-container>
          </div>
          <div>
            <mat-checkbox
              data-cy="preferred-dealer-form-service-checkbox"
              class="h-6 w-full text-sm"
              color="primary"
              #checkbox_carService
              (change)="addToOfficialDealer()"
            >
              <T
                str="Show all dealers"
                key="customerPortal.customer-portal.change-dealer-seller.show-all-dealers"
                tags="customer-portal, 3.1"
              ></T>
            </mat-checkbox>
          </div>
        </div>
        <div class="flex gap-4 flex-col md:flex-row">
          <div
            *ngIf="
              checkbox_carService?.checked
                ? allDealersLoadingError
                : dealersLoadingError
            "
            class="px-4 border border-mat-ef-revolution-grey-200 rounded flex-1 flex items-center text-base text-mat-ef-revolution-primary-500"
          >
            <T
              str="This car brand has no dealers"
              key="customerPortal.customer-portal.change-dealer-seller.no-dealer"
              tags="customer-portal, 3.1"
            ></T>
          </div>
          <ng-container
            *ngIf="
              !(checkbox_carService?.checked
                ? allDealersLoadingError
                : dealersLoadingError)
            "
          >
            <mat-form-field class="flex-1 relative">
              <div *ngIf="isMobile===true" class="absolute top-0 right-0 bottom-0 left-0 cursor-pointer"
              (click)="openSelectOptionsPopup($event,checkbox_carService,'service')">
            </div>
              <mat-label>
                <T
                  str="Select dealer"
                  key="customerPortal.customer-portal.change-dealer-seller.select-dealer"
                  tags="customer-portal, 3.1"
                ></T>
              </mat-label>
              <input
                data-cy="preferred-dealer-form-service-select"
                required
                formControlName="carService"
                type="text"
                aria-label="Number"
                matInput
                [matAutocomplete]="auto2"
                [errorStateMatcher]="matcher"
              />
              <mat-autocomplete
                #auto2="matAutocomplete"
                [displayWith]="sharedService.formatDealerName"
              >
                <mat-option *ngIf="serviceDealer?.id" [value]="serviceDealer">
                  {{ sharedService.formatDealerName(serviceDealer) }}
                  (<ng-container
                    *ngTemplateOutlet="prefferedDealer"
                  ></ng-container
                  >)
                </mat-option>
                <mat-option
                  *ngFor="
                    let d of checkbox_carService?.checked
                      ? filteredAllDealers
                      : filteredDealers
                  "
                  [value]="d"
                >
                  {{ sharedService.formatDealerName(d) }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </ng-container>
          <button
            mat-button
            mat-stroked-button
            (click)="onDealerChangeClick('service', serviceDealer)"
            class="revolution"
            color="secondary"
          >
            <T
              str="Find a dealership"
              key="customerPortal.customer-portal.change-dealer-seller.find-dealer"
              tags="customer-portal, 3.1"
            ></T>
          </button>
        </div>
      </div>

      <mat-checkbox
        data-cy="preferred-dealer-form-same-dealer-checkbox"
        formControlName="sameDealerAndService"
        class="text-sm text-mat-ef-revolution-primary-500 pt-2"
        color="primary"
        (change)="setSameDealer($event)"
      >
        <T
          str="Seller and service"
          key="customerPortal.customer-portal.change-dealer-seller.seller-and-service"
          tags="customer-portal, 3.1"
        ></T>
      </mat-checkbox>
    </form>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions class="!flex">
    <button class="absolute top-0 right-0 scale-150" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <button
      data-cy="preferred-dealer-form-submit-button"
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      (click)="saveChanges()"
      cdkFocusInitial
      [disabled]="saveIsDisabled()"
    >
      <mat-spinner
        class="inline-block mr-2.5"
        [diameter]="16"
        *ngIf="saving"
      ></mat-spinner>

      {{ 'save' | sharedTranslate }}
    </button>
  </mat-dialog-actions>
</div>

<ng-template #officialDealersText>
  &nbsp;-&nbsp;&nbsp;
  <T
    str="Official brand representatives"
    key="customerPortal.customer-portal.change-dealer-seller.official-dealers"
    tags="customer-portal, 3.1"
  ></T>
</ng-template>
<ng-template #allDealersText>
  &nbsp;-&nbsp;&nbsp;
  <T
    str="All brand representatives"
    key="customerPortal.customer-portal.change-dealer-seller.all-dealers"
    tags="customer-portal, 3.1"
  ></T>
</ng-template>

<ng-template #prefferedDealer>
  {{ 'preffered-dealer' | sharedTranslate }}
</ng-template>
