<div
  class="w-full ef-scrollbar h-fit flex flex-col text-mat-ef-revolution-primary-500 font-efSans overflow-y-auto"
>
  <div
    class="overflow-y-auto flex-1 h-fit max-h-full md:max-h-[360px] mt-5 gap-3 grid grid-cols-1 md:grid-cols-3"
  >
    <div *ngIf="document?.invNumber" class="text">
      <div class="text-base font-semibold">
        <T
          str="Invoice number"
          key="customerPortal.customer-portal.invoice.invoice-number"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div class="text-base text-mat-ef-revolution-grey-two-500 font-normal">
        {{ document.invNumber }}
      </div>
    </div>

    <div *ngIf="document?.price" class="text">
      <div class="text-base font-semibold">
        <T
          str="Price"
          key="customerPortal.customer-portal.offer-contract.price"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div class="text-base text-mat-ef-revolution-grey-two-500 font-normal">
        {{
          document.price
            | currency: currency:'symbol':'1.2-2':currencyLocale
        }}
      </div>
    </div>

    <div *ngIf="document?.number" class="text">
      <div class="text-base font-semibold">
        <T
          str="ID"
          key="customerPortal.customer-portal.offer-contract.id"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div class="text-base text-mat-ef-revolution-grey-two-500 font-normal">
        {{ document.number }}
      </div>
    </div>

    <div *ngIf="document?.dateCreated" class="text">
      <div class="text-base font-semibold">
        <T
          str="Created on"
          key="customerPortal.customer-portal.offer-contract.created-on"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div class="text-base text-mat-ef-revolution-grey-two-500 font-normal">
        {{ document.dateCreated | date: dateFormat }}
      </div>
    </div>

    <div *ngIf="document?.createdBy" class="text">
      <div class="text-base font-semibold">
        <T
          str="Created by"
          key="customerPortal.customer-portal.offer-contract.created-by"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div class="text-base text-mat-ef-revolution-grey-two-500 font-normal">
        {{ document.createdBy }}
      </div>
    </div>

    <div *ngIf="document?.contractStatus" class="text">
      <div class="text-base font-semibold">
        <T
          str="Status"
          key="customerPortal.customer-portal.offer-contract.status"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div class="text-base text-mat-ef-revolution-grey-two-500 font-normal">
        <ng-container
          *ngTemplateOutlet="
            sharedService.template('transifexTranslation');
            context: {
              str: document.contractType + '.' + document.contractStatus,
              prefix: 'customerPortal.customer-portal.'
            }
          "
        ></ng-container>
      </div>
    </div>

    <div *ngIf="document?.vin" class="text">
      <div class="text-base font-semibold">
        <T
          str="VIN"
          key="customerPortal.customer-portal.offer-contract.VIN"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div class="text-base text-mat-ef-revolution-grey-two-500 font-normal">
        {{ document.vin }}
      </div>
    </div>

    <div *ngIf="document?.brandName" class="text">
      <div class="text-base font-semibold">
        {{ 'brand' | sharedTranslate }}
      </div>
      <div class="text-base text-mat-ef-revolution-grey-two-500 font-normal">
        {{ document.brandName }}
      </div>
    </div>

    <div *ngIf="document?.modelName" class="text">
      <div class="text-base font-semibold">
        {{ 'model' | sharedTranslate }}
      </div>
      <div class="text-base text-mat-ef-revolution-grey-two-500 font-normal">
        {{ document.modelName }}
      </div>
    </div>

    <div *ngIf="document?.engine" class="text">
      <div class="text-base font-semibold">
        <T
          str="Engine"
          key="customerPortal.customer-portal.offer-contract.engine"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div class="text-base text-mat-ef-revolution-grey-two-500 font-normal">
        {{ document.engine }}
      </div>
    </div>
  </div>

  <ng-container *ngIf="hasServices(document)">
    <mat-divider class="divider my-5"></mat-divider>
    <div *ngFor="let s of document?.services">
      <h2 class="text-efSans text-base font-bold text-mat-ef-revolution-primary-500">
        {{ s.name }}
      </h2>
      <div *ngFor="let i of s.items" class="font-efSans text-base text-mat-ef-revolution-grey-two-500 font-normal">
        <span *ngIf="i.description">{{ i.description }}</span>
      </div>
    </div>
  </ng-container>


</div>
