<!--PWA tests-->
<div class="container px-0">
  <div>Is this IOS? {{ isIos }}</div>
  <div>Is this PWA? {{ isPwa }}</div>
  <div>Is this Mobile device? {{ isMobile }}</div>
  <div>Is pwa installed? {{ pwaInstalled }}</div>
</div>

<div class="container py-14 px-4">
  <h1 class="head-title">
    <T
      str="Test"
      key="customerPortal.customer-portal.test"
      tags="customer-portal, 3.3"
    ></T>
  </h1>

  <div class="w-full flex flex-col gap-10 mt-10">
    <div #pushNotification>
      <div
        class="text-mat-ef-revolution-primary-500 text-2xl font-semibold px-2"
      >
        <T
          str="Push notification"
          key="customerPortal.customer-portal.test.push-notification"
          tags="customer-portal, 3.3"
        ></T>
      </div>
      <mat-divider></mat-divider>
      <div class="w-full flex flex-col md:flex-row gap-4">
        <div class="flex-1 flex flex-col py-5 gap-12">
          <form
            [formGroup]="formGroup"
            class="overflow-y-auto overflow-x-hidden px-2 md:px-6"
          >
            <div class="flex flex-col gap-2">
              <div
                class="text-mat-ef-revolution-primary-500 text-base font-bold"
              >
                <T
                  str="Title"
                  key="customerPortal.customer-portal.test.title"
                  tags="customer-portal, 3.3"
                ></T>
              </div>
              <mat-form-field class="w-full">
                <input matInput formControlName="title" />
              </mat-form-field>

              <div
                class="text-mat-ef-revolution-primary-500 text-base font-bold"
              >
                <T
                  str="Text"
                  key="customerPortal.customer-portal.test.text"
                  tags="customer-portal, 3.3"
                ></T>
              </div>
              <mat-form-field class="w-full">
                <textarea
                  #notificationText
                  cdkTextareaAutosize
                  matInput
                  cdkAutosizeMinRows="4"
                  [maxlength]="maxLength"
                  formControlName="text"
                ></textarea>
                <mat-hint class="-mt-2"
                  >{{ notificationText.value.length }} /
                  {{ maxLength }}</mat-hint
                >
              </mat-form-field>

              <div
                class="text-mat-ef-revolution-primary-500 text-base font-bold mt-2"
              >
                <T
                  str="User IDs"
                  key="customerPortal.customer-portal.test.user-id"
                  tags="customer-portal, 3.3"
                ></T>
              </div>
              <mat-form-field class="w-full">
                <input matInput formControlName="userID" />
              </mat-form-field>

              <div
                class="text-mat-ef-revolution-primary-500 text-base font-bold mt-2"
              >
                <T
                  str="Push link"
                  key="customerPortal.customer-portal.test.push-link"
                  tags="customer-portal, 3.5"
                ></T>
              </div>
              <mat-form-field class="w-full">
                <input matInput formControlName="pushLink" />
              </mat-form-field>
            </div>
          </form>
          <div
            class="flex flex-1 justify-end gap-3 flex-col md:flex-row px-2 md:px-6"
          >
            <button
              class="revolution w-full md:w-auto"
              mat-button
              mat-flat-button
              color="secondary"
              (click)="sendNotification()"
              [disabled]="!formGroup.valid || disabled"
            >
              <span>
                <T
                  str="Send notification"
                  key="customerPortal.customer-portal.test.send-notification"
                  tags="customer-portal, 3.3"
                ></T
              ></span>
            </button>
          </div>
        </div>
        <div
          class="flex-1 flex gap-10 flex-col text-mat-ef-revolution-primary-500 p-5"
        >
          <div class="whitespace-pre" *ngIf="pushNotificationPayload">
            <div class="font-semibold">
              <T
                str="Payload"
                key="customerPortal.customer-portal.test.payload"
                tags="customer-portal, 3.3"
              ></T
              >:
            </div>
            <div [innerHTML]="pushNotificationPayload"></div>
          </div>
          <div class="whitespace-pre" *ngIf="pushNotificationResponse">
            <div class="font-semibold">
              <T
                str="Response"
                key="customerPortal.customer-portal.test.response"
                tags="customer-portal, 3.3"
              ></T
              >:
            </div>
            <div [innerHTML]="pushNotificationResponse"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
