import {
  ChangeDetectorRef,
  Component,
  type OnDestroy,
  type OnInit,
  ViewChild
} from '@angular/core'
import { type DashEcoUrl, type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import {
  CptGoogleTagmanagerService,
  ResizeService, type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'
import { NgxGlideComponent } from 'ngx-glide'
import {
  type ArticleData,
  type ArticleDataExtended,
  type ArticleResponse,
  DashEcoService
} from '../../../services/dash-eco.service'
import { DataService } from '../../../services/data.service'
import { LocalStorageService } from '../../../services/local-storage.service'
import { SharedService } from '../../../services/shared.service'
// import { ARTICLES } from '../../../mock/articles'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { type User } from '@inside-hub-app/customer-portal-b2c-client'
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'customer-portal-app-revolution-articles',
  templateUrl: './revolution-articles.component.html'
})
export class RevolutionArticlesComponent implements OnInit, OnDestroy {
  articleResponse: ArticleResponse
  disablePrevious: boolean
  disableNext: boolean
  currentLang: string
  dashEcoParams: {
    de: DashEcoUrl
    fr: DashEcoUrl
    it: DashEcoUrl
  }

  sub = {
    onArticlesLoadedSub: null,
    onLanguageChangeSub: null,
    onArticlesFromLocalStorageLoadedSub: null,
    onResizePx: null,
    onUserLoaded: null
  }

  articles: ArticleResponse

  @ViewChild('ngxGlide') ngxGlide!: NgxGlideComponent
  perView: number
  public screenSize: SCREEN_SIZE
  public screenSizePx: number

  breakpoints
  showArrows = true
  showBullets = true
  bound = false
  fitGlider = false

  user: User
  showIframe
  iframeSrc
  constructor (
    private readonly dataService: DataService,
    public dashEcoService: DashEcoService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public sharedService: SharedService,
    private readonly localStorage: LocalStorageService,
    public resizeService: ResizeService,
    private readonly cdRef: ChangeDetectorRef,
    protected sanitizer: DomSanitizer,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.screenSizePx = this.resizeService.screenSizePx
    this.currentLang = this.sharedService.currentLanguage()
    this.dashEcoParams = this.sharedService.getDashEco()
    this.showIframe = this.remoteConfigService.get('100WEB.showIframe')
  }

  ngOnInit (): void {
    this.sub.onArticlesLoadedSub =
      this.dataService.onDashEcoArticlesLoaded.subscribe(articles => {
        if (articles != null) {
          this.setArticles(articles)
          this.setBreakpoints()
          this.recreate()
        }
      })
    this.sub.onArticlesFromLocalStorageLoadedSub =
      this.dataService.onDashEcoArticlesFromLocalStorageLoaded.subscribe(
        articles => {
          if (articles != null && this.articleResponse == null) {
            this.articleResponse = this.sharedService.deepCopy(articles)
            this.setBreakpoints()
            this.recreate()
          }
        }
      )

    this.sub.onResizePx = this.resizeService.onResizePx.subscribe(size => {
      this.screenSizePx = size
      this.setBreakpoints()
      this.recreate()
    })

    this.sub.onUserLoaded = this.dataService.onUserLoaded.subscribe(user => {
      this.user = user != null ? user : null
      if (this.showIframe) {
        this.setIframeSrc()
        this.setBreakpoints()
        this.recreate()
      }
    })

    this.dataService.onLanguageChange.subscribe(lang => {
      if (this.showIframe) {
        this.setIframeSrc()
        this.setBreakpoints()
        this.recreate()
      }
    })

    this.setBreakpoints()
    this.recreate()
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  setArticles (articlesRes: ArticleResponse): void {
    // const articles = JSON.parse(JSON.stringify(ARTICLES)) // mock data
    const articles: ArticleResponse = JSON.parse(JSON.stringify(articlesRes))

    // show only articles from user dealers, user brands or articles that have corporate flag
    articles.data = this.filter(articles.data)

    // 1.dealership  2.brand  3.corporate
    articles.data = this.sort(articles.data)

    this.articleResponse = articles

    // set articles to local storage
    void this.localStorage.setArticles(articles)

    this.recreate()
  }

  openLink (article: ArticleData): void {
    const lang = this.sharedService.currentLanguage()
    const url = String(this.dashEcoParams[lang].detailsUrl) + article.slug
    if (url != null) {
      window.open(url, '_self')
    }
  }

  setIframeSrc (): void {
    try {
      let iframeSrc = this.remoteConfigService.get('100WEB.iframeSrc')
      const lang = this.sharedService.currentLanguage()
      iframeSrc = iframeSrc + '?lang=' + lang + '&email=' + this.user?.email
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(iframeSrc)
    } catch (error) {}
  }

  open100WEBLink (): void {
    const url = this.remoteConfigService.get('100WEB.url')
    if (url != null) {
      window.open(url, '_top')
    }
  }

  filter (array: ArticleDataExtended[]): ArticleDataExtended[] {
    return array.filter(el => {
      // make array with only brand names
      const brandsArray = []
      if (el.brands?.[0] != null) {
        el.brands.forEach(brand => {
          if (brand.name != null) {
            brandsArray.push(brand.name.toLowerCase())
          }
        })
      }

      // show only news which have “Corporate” flag
      if (el.is_corporate === true) {
        // News of owned vehicle brands and “preferences” section (brand related news)
        el.hasCommonElementBrand = this.hasCommonElements(
          brandsArray,
          this.dashEcoService.userBrands != null
            ? this.dashEcoService.userBrands
            : []
        )
        // News of dealerships which this user has (vehicles or preferences)
        el.hasCommonElementDealership = this.hasCommonElements(
          el.dealerships,
          this.dashEcoService.userDealers != null
            ? this.dashEcoService.userDealers
            : []
        )
        // show if there are no brands and dealerships in article
        el.showCorporate = !(
          el.brands?.[0] != null || el.dealerships?.[0] != null
        )
      } else {
        el.hasCommonElementBrand = false
        el.hasCommonElementDealership = false
        el.showCorporate = false
      }

      return (
        el.hasCommonElementDealership ||
        el.hasCommonElementBrand ||
        el.showCorporate
      )
    })
  }

  sort (array: ArticleDataExtended[]): ArticleDataExtended[] {
    return array.sort((a, b) => {
      // order by dealership then brand (if its 0)
      const orderByDealership = this.sharedService.sortObjectsByBooleanField(a, b, 'hasCommonElementDealership')
      if (orderByDealership === 0) {
        return this.sharedService.sortObjectsByBooleanField(a, b, 'hasCommonElementBrand')
      } else {
        return orderByDealership
      }
    })
  }

  hasCommonElements (array1, array2): boolean {
    return this.sharedService.hasCommonElements(array1, array2)
  }

  recreate (): void {
    setTimeout(() => {
      if (this.ngxGlide != null) {
        try {
          this.ngxGlide.recreate()
        } catch (error) {
          // no need to log
        }
      }
    })
  }

  sendContentModulesSliderData (
    side: string,
    contentModuleLabel: string,
    galleryName: string
  ): void {
    this.cptGtmService.sendContentModulesData(
      'Gallery',
      side,
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Gallery|(${galleryName})`,
      side
    )
  }

  sendContentModulesBigTeaserData (
    contentModuleLabel: string,
    text: string
  ): void {
    const label =
      this.sharedService.translateLink(contentModuleLabel) + '|' + text ?? ''
    this.cptGtmService.sendContentModulesData(
      'Big Teaser',
      null,
      label,
      'Big Teaser',
      label
    )
  }

  setBreakpoints (): void {
    let articleLength =
      this.articleResponse?.data?.length != null
        ? this.articleResponse.data.length
        : 0
    if (this.showIframe) {
      articleLength = articleLength + 1
    }
    if (articleLength >= 0 && articleLength < 4) {
      switch (articleLength) {
        case 0:
        case 1:
          this.showBullets = false
          this.showArrows = false
          this.bound = true
          this.fitGlider = true

          this.breakpoints = {
            1024: { perView: 1, focusAt: 'center' },
            1280: { perView: 1, focusAt: 'center' },
            3840: { perView: 1, focusAt: 'center' }
          }
          break
        default:
          if (this.screenSizePx < 1024) {
            // small screen
            this.defaultBreakpoints()
            this.showBullets = true
            this.showArrows = true
            this.bound = false
            this.fitGlider = false
          } else if (this.screenSizePx < 1280) {
            // medium screen
            this.defaultBreakpoints()
            this.showBullets = articleLength > 2
            this.showArrows = articleLength > 2
            this.bound = !(articleLength > 2)
            this.fitGlider = !(articleLength > 2)
          } else {
            // large screen
            this.defaultBreakpoints()
            this.showBullets = false
            this.showArrows = false
            this.bound = true
            this.fitGlider = true
          }
          break
      }
      return
    }

    this.defaultBreakpoints()
    this.showBullets = true
    this.showArrows = true
    this.bound = false
    this.fitGlider = false
  }

  defaultBreakpoints (): void {
    this.breakpoints = {
      1024: { perView: 1, focusAt: 'center' },
      1280: { perView: 2, focusAt: 0 },
      3840: { perView: 3, focusAt: 0 }
    }
  }

  hasData (): boolean {
    return this.hasArticleData() || this.showIframe
  }

  hasArticleData (): boolean {
    return this.articleResponse?.data?.length > 0
  }
}
