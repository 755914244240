import { TranslateLoader } from '@ngx-translate/core'
import { forkJoin, Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { Translation, TranslationService } from './services/translation.service'

// for use with @ngx-translate
export class PpTranslationLoader implements TranslateLoader {
  /**
   * Creates a loader which can load one or multiple translations, depending on the types parameter.
   * @param types Input a single type, or multiple types with prefixes. If not prefix is given, the type name will be used as a prefix.
   * @param prefix Only used when the types parameter is a single type.
   * @param translationService Partner Profile translation service.
   * @param fallbackLanguages A list of fallback languages.
   * If a translation string isn't found in the given language, it will be replaced by the translation string in the next language.
   * @param localTranslations Only used when the types parameter is a single type.
   * If translations on pp aren't available, local translations are loaded.
   */
  constructor (
    private readonly types: string | Array<{ type: string, prefix?: string }>,
    private readonly prefix: string | undefined,
    private readonly translationService: TranslationService,
    private readonly fallbackLanguages = ['it', 'fr', 'de', 'en'],
    private readonly localTranslations: Observable<Translation[]> = of([])
  ) {}

  getTranslation (lang: string): Observable<any> {
    let translationRequest: Observable<{ [key: string]: Translation[] }>

    if (typeof this.types === 'string') {
      // case where type is given as a string
      translationRequest = this.translationService.get(this.types, lang).pipe(
        catchError(() => {
          return this.localTranslations
        }),

        map(translations => {
          return {
            [this.types as string]: translations
          }
        })
      )
    } else {
      // case where type is given as an array
      const translationRequests: { [key: string]: Observable<Translation[]> } = {}
      const loadTypes = new Set(this.types.map(e => e.type))

      for (const type of loadTypes) {
        translationRequests[type] = this.translationService.get(type, lang)
      }

      translationRequest = forkJoin(translationRequests)
    }

    return translationRequest.pipe(
      // map translations to a simple object
      map(translations => {
        const mapped: { [key: string]: string } = {}

        let translationTypes: Array<{ type: string, prefix?: string }>
        if (typeof this.types === 'string') {
          translationTypes = [
            {
              type: this.types,
              prefix: this.prefix || ''
            }
          ]
        } else {
          translationTypes = [...this.types]
        }

        for (const typeDefinition of translationTypes) {
          const typeTranslations = translations[typeDefinition.type]
          typeTranslations.forEach(t => {
            let identifier = t.identifier
            const languageIndex = this.fallbackLanguages.indexOf(lang)

            if (typeof this.types === 'string') {
              // case where this.prefix is given
              if (identifier.startsWith(typeDefinition.prefix)) {
                identifier = identifier.substr(typeDefinition.prefix.length)
                mapped[identifier] = this.getFallbackLanguage(
                  t,
                  lang,
                  languageIndex
                )
              }
            } else {
              // case where this.prefix is omitted
              const prefix = typeDefinition.prefix || `${typeDefinition.type}.`
              mapped[`${prefix}${identifier}`] = this.getFallbackLanguage(
                t,
                lang,
                languageIndex
              )
            }
          })
        }

        return mapped
      })
    )
  }

  getFallbackLanguage (t: Translation, lang: string, index: number): string {
    if (t[lang]) {
      return t[lang]
    } else if (
      this.fallbackLanguages &&
      this.fallbackLanguages.length > index + 1
    ) {
      return this.getFallbackLanguage(
        t,
        this.fallbackLanguages[index + 1],
        index + 1
      )
    } else if (
      this.fallbackLanguages &&
      this.fallbackLanguages.length <= index + 1
    ) {
      return t.identifier + ', type=[' + t.type + ']'
    } else {
      return t.identifier + ', type=[' + t.type + ']'
    }
  }

  getTypes () {
    return this.types
  }

  getPrefix () {
    return this.prefix
  }
}
