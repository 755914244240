import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'

export type MarketFilterDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.market.dto.MarketFilterDTO

@Injectable({
  providedIn: 'root'
})
export class MarketFilterService {
  private readonly apiUrl

  constructor(
    private readonly http: HttpClient,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  getMarketFilter(): Observable<MarketFilterDTO[]> {
    return this.http.get<MarketFilterDTO[]>(`${this.apiUrl}/market/filter`)
  }

  deleteMarketFilter(marketFilterId: string | number): Observable<any> {
    const apiUrl = this.apiUrl + '/market/filter/' + marketFilterId
    return this.http.delete(apiUrl)
  }
}
