import { Component, Inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from '../../../services/shared.service'
import { InstantErrorStateMatcherService } from '../../../services/instant-error-state-matcher.service'
import {
  VehicleDTOExtended,
  VehiclesService
} from '../../../services/vehicles.service'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'

@Component({
  selector: 'customer-portal-app-edit-mileage',
  templateUrl: './edit-mileage.component.html'
})
export class EditMileageComponent {
  mileageForm = new FormGroup({
    manualMileage: new FormControl<number | null>(
      null,
      Validators.compose([
        Validators.required,
        Validators.maxLength(7),
        Validators.pattern('^[0-9]+$'),
        Validators.min(1)
      ])
    )
  })

  matcher = new InstantErrorStateMatcherService()
  saving = false
  vehicleUpdated: VehicleDTOExtended
  imageUrl: string

  constructor(
    private readonly dialogRef: MatDialogRef<EditMileageComponent>,
    @Inject(MAT_DIALOG_DATA) public vehicle: VehicleDTOExtended,
    private readonly vehiclesService: VehiclesService,
    private readonly logger: NGXLogger,
    public sharedService: SharedService,
    public transifexService: TransifexService
  ) {
    this.vehicleUpdated = vehicle
    this.mileageForm
      .get('manualMileage')
      .setValue(
        this.vehicle.manualMileage != null ? this.vehicle.manualMileage : null
      )
  }

  saveChanges(): void {
    this.saving = true
    const vehicleUpdated = this.sharedService.deepCopy(this.vehicleUpdated)
    delete vehicleUpdated.pictureType
    delete vehicleUpdated.leasingInfo
    delete vehicleUpdated.dealers
    delete vehicleUpdated.dealer
    delete vehicleUpdated.brandPicture
    delete vehicleUpdated.relations
    vehicleUpdated.manualMileage = this.mileageForm.get('manualMileage').value
    this.vehiclesService.addUserVehicle(vehicleUpdated).subscribe(
      value => {
        this.saving = false
        if (value?.manualMileage != null) {
          this.dialogRef.close({ success: true, value: value.manualMileage })
        }
      },
      error => {
        this.saving = false
        this.logger.debug(error)
        this.dialogRef.close({ success: false })
      }
    )
  }
}
