<div
  *ngIf="!loadingAppointments"
  id="appointments"
  class="min-h-full h-fit flex w-full"
>
  <div class="w-full flex flex-col min-h-full h-fit p-4 md:p-6 gap-4 md:gap-6">
    <div
      class="flex basis-1/2 text-mat-ef-revolution-primary-500 items-center gap-6 pl-6"
    >
      <div class="font-bold font-canelablack text-5xl">
        {{ upcomingAppointments?.length || 0 }}
      </div>
      <div class="font-bold font-efSans">
        <ng-container
          *ngTemplateOutlet="
            sharedService.template('transifexTranslation');
            context: {
              str: 'Upcoming dates',
              key: upcomingAppointmentsKey
            }
          "
        ></ng-container>
      </div>
      <div
        class="font-bold font-canelablack flex justify-center items-center cursor-pointer"
        (click)="action.next({ name: 'goToAppointments' })"
      >
        <mat-icon svgIcon="chevron_right"></mat-icon>
      </div>
    </div>
    <div class="flex basis-1/2 items-center">
      <div class="w-full flex items-center">
        <div
          *ngIf="!upcomingAppointments?.[0]"
          class="cursor-pointer flex-1 flex justify-center items-center text-mat-ef-revolution-secondary-500 gap-4 pl-4 bg-mat-ef-revolution-supergrey-100 rounded-lg min-h-[60px]"
          (click)="
            action.next({ name: 'newAppointment' });
            sendContentModulesCTAData(
              'customerPortal.customer-portal.notification-panel.make-appointment'
            )
          "
          data-cy="new-appointment"
        >
          <mat-icon svgIcon="event_add"></mat-icon>
          <span class="flex-1">
            <T
              str="Book a new appointment"
              key="customerPortal.customer-portal.notification-panel.make-appointment"
              tags="customer-portal, 3.1"
            ></T>
          </span>
        </div>

        <div *ngIf="upcomingAppointments?.[0]" class="h-full w-full relative">
          <ngx-glide
            #AppointmentsComponent_ngxGlide
            [perView]="1"
            [focusAt]="'center'"
            [arrowLeftTemplate]="left"
            [arrowRightTemplate]="right"
            [showBullets]="false"
            [rewind]="false"
            [bound]="true"
            [showArrows]="upcomingAppointments[1] ? true : false"
            [gap]="16"
          >
            <div
              *ngFor="
                let appointment of upcomingAppointments;
                let first = first;
                let last = last
              "
              (click)="
                sharedService.preventClick ||
                  action.next({ name: 'goToAppointments' })
              "
              (mouseup)="
                sharedService.handleGliderEvents($event, appointment.id)
              "
              (mousedown)="
                sharedService.handleGliderEvents($event, appointment.id)
              "
              data-cy="notification-panel-appointment-widget"
              class="select-none cursor-pointer flex flex-col lg:flex-row text-mat-ef-revolution-primary-500 relative bg-mat-ef-revolution-supergrey-100 rounded-lg min-h-[60px]"
            >
              <div
                class="flex-1 flex flex-col p-5 pl-6 md:max-h-full gap-2 md:gap-3 lg:max-w-[50%]"
                [ngClass]="{
                  'pt-3':
                    appointment.requested ||
                    (checkAppointment(appointment) && hasLiveStatusAppointments)
                }"
              >
                <div class="flex gap-4" *ngIf="appointment.requested">
                  <div
                    class="text-sm whitespace-normal uppercase font-bold text-mat-ef-revolution-secondary-500"
                  >
                    <T
                      str="Requested"
                      key="customerPortal.customer-portal.appointments.requested"
                      tags="customer-portal, 3.1"
                    ></T>
                  </div>
                </div>

                <!-- Live status appointment warning-->
                <ng-container *ngIf="hasLiveStatusAppointments">
                  <div
                    class="flex gap-2 h-fit pl-0.5 items-center animation-pulse text-mat-ef-revolution-red-500"
                    *ngIf="checkAppointment(appointment)"
                  >
                    <mat-icon svgIcon="vehicle" class="h-5 w-5"></mat-icon>
                    <p class="text-sm whitespace-normal uppercase font-bold">
                      <T
                        str="Status"
                        [key]="
                          'customerPortal.customer-portal.appointment.tracking.tracking-status.' +
                          appointment?.status
                        "
                        tags="customer-portal, 3.5"
                      ></T>
                    </p>
                  </div>
                </ng-container>

                <div class="flex gap-4">
                  <div>
                    <mat-icon svgIcon="calendarDetails"></mat-icon>
                  </div>
                  <div class="flex flex-col">
                    <div class="text-base font-black">
                      {{
                        appointmentsService.getDayMonth(appointment.startDate)
                      }}
                      {{ appointmentsService.getYear(appointment.startDate) }}
                    </div>
                    <div class="text-sm">
                      <ng-container *ngIf="!appointment.requested">
                        {{ appointmentsService.getHour(appointment.startDate) }}
                        <ng-container *ngIf="appointment.endDate">
                          -
                          {{ appointmentsService.getHour(appointment.endDate) }}
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="appointment.requested">
                        {{
                          {
                            str: appointment.timeOfDay?.toLowerCase(),
                            prefix:
                              'customerPortal.customer-portal.appointments.times.'
                          } | transifexTranslate
                        }}
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="flex gap-4">
                  <mat-icon svgIcon="location_on"></mat-icon>
                  <div class="flex flex-col flex-1 max-w-[calc(100%-40px)]">
                    <div
                      class="flex-1 text-base font-black whitespace-normal leading-5 break-words"
                    >
                      {{ sharedService.getDealerSubname(appointment.dealer) }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="mx-6 flex-grow-0 border-mat-ef-revolution-supergrey-500 border-b lg:border-r lg:border-b-0 lg:mx-0 lg:my-6"
              ></div>
              <div
                class="flex-1 flex flex-col p-5 pr-6 max-h-full lg:max-w-[50%] gap-3"
                [ngClass]="{
                  'md:pt-[42px]':
                    appointment.requested ||
                    (checkAppointment(appointment) &&
                      hasLiveStatusAppointments),
                  'md:pt-[18px]':
                    !appointment.requested || !checkAppointment(appointment)
                }"
              >
                <div
                  class="text-lg font-black whitespace-normal flex flex-1 flex-col overflow-hidden leading-5"
                >
                  <ng-container *ngIf="!appointment.requested">
                    {{ appointment.typeName }}
                  </ng-container>
                  <ng-container
                    *ngIf="appointment.requested && appointment.serviceTypes?.[0]"
                  >
                    <ng-container
                      *ngIf="appointment.serviceTypes?.length === 1"
                    >
                      {{
                        {
                          str: appointment.serviceTypes[0].toLowerCase(),
                          prefix:
                            'customerPortal.customer-portal.appointments.services.'
                        } | transifexTranslate
                      }}
                    </ng-container>
                    <ng-container
                      *ngIf="!(appointment.serviceTypes?.length === 1)"
                    >
                      <T
                        str="service appointment"
                        key="customerPortal.customer-portal.service-appointment"
                        tags="customer-portal, 3.1"
                      ></T>
                    </ng-container>
                  </ng-container>
                </div>

                <div class="flex gap-2 h-fit justify-between">
                  <div
                    class="flex flex-1 flex-col gap-1"
                    [ngClass]="{
                      'justify-end': appointment.licensePlate,
                      'justify-center': !appointment.licensePlate
                    }"
                  >
                    <div
                      class="text-xs whitespace-normal"
                      data-cy="notification-panel-appointment-brand-model"
                    >
                      {{ appointment.brand }} {{ appointment.model }}
                    </div>
                    <ng-container *ngIf="appointment.licensePlate">
                      <div
                        data-cy="notification-panel-appointment-license-plate"
                        class="w-fit h-fit px-2 py-1 font-efSans font-semibold text-mat-ef-revolution-secondary-500 !text-sm rounded-[3px] border border-mat-ef-revolution-supergrey-500"
                      >
                        <ng-container *ngIf="licensePlateMask">{{
                          appointment.licensePlate
                            ? (appointment.licensePlate
                              | mask: licensePlateMask)
                            : '-'
                        }}</ng-container>
                        <ng-container *ngIf="!licensePlateMask"
                          >{{ appointment.licensePlate }}
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>

                  <div
                    *ngIf="!appointment.licensePlate"
                    class="max-w-[4px]"
                  ></div>
                  <div
                    class="relative flex content-center items-center min-w-[72px] max-w-[50%]"
                  >
                    <img
                      class="w-auto max-w-full max-h-[64px] h-[64px] object-contain rounded m-auto"
                      *ngIf="appointment.brandPicture"
                      src="{{ appointment.brandPicture }}"
                      alt="brand image"
                    />
                    <img
                      class="rounded-lg object-cover m-auto w-16 h-16"
                      *ngIf="!appointment.brandPicture"
                      src="assets/ef-logo.svg"
                      alt="ef logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </ngx-glide>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #left>
  <div
    class="arrowTemplate leftArrow flex justify-center items-center sideArrows !-left-5 border-none"
    (click)="
      sendContentModulesSliderData(
        'Right',
        'customerPortal.customer-portal.notification-panel.make-appointment',
        'Appointments'
      )
    "
  >
    <mat-icon svgIcon="chevron_left"></mat-icon>
  </div>
</ng-template>

<ng-template #right>
  <div
    class="arrowTemplate rightArrow flex justify-center items-center sideArrows !-right-5 border-none"
    (click)="
      sendContentModulesSliderData(
        'Left',
        'customerPortal.customer-portal.notification-panel.make-appointment',
        'Appointments'
      )
    "
  >
    <mat-icon svgIcon="chevron_right"></mat-icon>
  </div>
</ng-template>
