import { Component } from '@angular/core'
import { SharedService } from '../../../../services/shared.service'
import {
  NotificationPageDTO,
  MyMailboxService
} from '../../../../services/my-mailbox.service'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
@Component({
  selector: 'customer-portal-app-unread-mail',
  templateUrl: './unread-mail.component.html'
})
export class UnreadMailComponent {
  unread = 0

  hasMyMailbox

  sub = {
    onMessageCountLoaded: null
  }

  constructor(
    public sharedService: SharedService,
    private readonly myMailboxService: MyMailboxService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasMyMailbox = this.remoteConfigService.get('hasMyMailbox')

    this.sub.onMessageCountLoaded =
      this.myMailboxService.onMessageCountLoaded.subscribe(
        (myMailbox: NotificationPageDTO) => {
          if (myMailbox?.totalUnread != null) {
            this.unread = myMailbox.totalUnread
          }
        }
      )
  }
}
