import { ChangeDetectorRef, Component, type OnDestroy, type OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatDialog, type MatDialogRef } from '@angular/material/dialog'
import {
  type EmailChangeDetailsResponse,
  type User,
  UserService,
  type UserTelephone,
  type UserTelephoneType,
  type UserTelephoneUsage
} from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGoogleTagmanagerService,
  MatomoIntegrationService,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { type ParsedToken } from '../../../../models/keycloak'
import { DataService } from '../../../services/data.service'
import {
  DocumentsService,
  type DriversLicenceDTO
} from '../../../services/documents.service'
import { SharedService } from '../../../services/shared.service'
import { ConfirmationPopupComponent } from '../../basic/confirmation-popup/confirmation-popup.component'
import { ConfirmationQuestionPopupComponent } from '../../basic/confirmation-question-popup/confirmation-question-popup.component'
import { type cptMenuAction } from '../../basic/cpt-menu/cpt-menu.component'
import { MultilanguagePopupComponent } from '../../basic/multilanguage-popup/multilanguage-popup.component'
import { CompanyNumberLandlineComponent } from '../../user-details/company-number-landline/company-number-landline.component'
import { PasswordChangePopupComponent } from '../../user-details/password-change-popup/password-change-popup.component'
import { PersonalNumberLandlineComponent } from '../../user-details/personal-number-landline/personal-number-landline.component'
import { TelephoneChangePopupComponent } from '../../user-details/telephone-change-popup/telephone-change-popup.component'
import { RevolutionAddressChangePopupComponent } from './address-change-popup/address-change-popup.component'
import { RevolutionBirthdayPopupComponent } from './birthday-popup/birthday-popup.component'
import { RevolutionDeleteAccountPopupComponent } from './delete-account-popup/delete-account-popup.component'
import { RevolutionEmailChangePopupComponent } from './email-change-popup/email-change-popup.component'
import { RevolutionNameChangePopupComponent } from './name-change-popup/name-change-popup.component'
import { RevolutionUploadDrivingLicensePopupComponent } from './upload-driving-license-popup/upload-driving-license-popup.component'
import { RevolutionUserImgDetailsPopupComponent } from './user-img-details-popup/user-img-details.component'

import { type hr as cpt } from 'efd-cpt-backend-interfaces-ts'

export type DocumentSideEnum =
  cpt.emilfreydigital.customer_portal_backend.rest.documents.dto.DocumentSideEnum

export declare type UserTelephoneKey =
  | 'personalMobile'
  | 'personalLandline'
  | 'businessLandline'
  | 'businessMobile'

export interface UserTelephoneExtended extends UserTelephone {
  mask?: string
  prefix?: string
  phone?: string
}

export type PhoneUsage = 'private' | 'business'

@Component({
  selector: 'customer-portal-app-revolution-general-user-data',
  templateUrl: './general-user-data.component.html'
})
export class RevolutionGeneralUserDataComponent implements OnInit, OnDestroy {
  show: boolean
  public user: User
  date: string
  token: ParsedToken
  mobile: UserTelephoneExtended
  mobileBusiness: UserTelephoneExtended
  landlineBusiness: UserTelephoneExtended
  landlinePersonal: UserTelephoneExtended
  loading = true
  nameInitials: string
  tel: string
  lang: string
  note = new FormControl<string | null>(null)
  disable = false
  error = false
  tooltipFrontText: string
  tooltipBackText: string
  profilePictureUrl = null as string
  documents = {
    front: '',
    back: ''
  }
  dateFormat: string

  showAltIcon = false
  emailChangeDetails: EmailChangeDetailsResponse

  sub = {
    onUserLoaded: null,
    onTransifexLanguageChange: null,
    onNotEditableChanged: null
  }

  // right side of the screen
  hasDrivingLicense
  showUserNoticeCard = false
  hasFAQ

  notEditable = false
  constructor (
    private readonly keycloak: KeycloakService,
    private readonly userService: UserService,
    private readonly documentsService: DocumentsService,
    public dialog: MatDialog,
    private readonly logger: NGXLogger,
    private readonly data: DataService,
    public readonly sharedService: SharedService,
    public cdRef: ChangeDetectorRef,
    private readonly transifexTranslationsService: TranslationService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public transifexService: TransifexService,
    private readonly matomoIntegrationService: MatomoIntegrationService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasDrivingLicense = this.remoteConfigService.get('hasDrivingLicense')
    this.hasFAQ = this.remoteConfigService.get('hasFAQ')
    this.dateFormat = this.remoteConfigService.get('dateFormat.long')
  }

  ngOnInit (): void {
    this.token = this.keycloak.getKeycloakInstance().tokenParsed as ParsedToken
    this.checkIfSelected()
    this.load()

    if (this.hasDrivingLicense) {
      this.getDriversLicence()
    }

    this.profilePictureUrl = null
    this.data.onLanguageChange.subscribe(newLanguage => {
      this.lang = newLanguage.toUpperCase()
    })

    this.sub.onTransifexLanguageChange =
      this.transifexService.onTransifexLanguageChange.subscribe(() => {
        this.refreshLanguage()
      })

    this.sub.onNotEditableChanged =
      this.userService.onNotEditableChanged.subscribe((notEditable) => {
        this.notEditable = notEditable === true
      })

    this.getEmailChangeDetails()
  }

  refreshLanguage (): void {
    this.tooltipBackText = this.transifexTranslationsService.translate(
      'Upload image back',
      {
        _key: 'customerPortal.customer-portal.upload-image.back',
        _tags: 'customer-portal, 3.1'
      }
    )
    this.tooltipFrontText = this.transifexTranslationsService.translate(
      'Upload image front',
      {
        _key: 'customerPortal.customer-portal.upload-image.front',
        _tags: 'customer-portal, 3.1'
      }
    )
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  reloadUserData (): void {
    this.data.getUser()
  }

  sendContentModulesData (expanded: boolean, contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Accordion',
      expanded ? 'Open' : 'Close',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Accordion|${this.sharedService.translateLink(contentModuleLabel) ?? ''}`,
      expanded ? 'Open' : 'Close'
    )
  }

  sendContentModulesMiniCTAData (contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Mini CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'Mini CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }

  sendContentModulesCTAData (contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }

  load (): void {
    this.loading = true
    this.sub.onUserLoaded = this.data.onUserLoaded.subscribe(user => {
      this.loading = false
      this.showAltIcon = false

      this.user = user

      this.profilePictureUrl = user.profilePictureUrl

      this.mobile = this.sharedService.findUserPhone(this.user)?.mobile
      this.mobileBusiness = this.sharedService.findUserPhone(
        this.user
      )?.mobileBusiness
      this.landlinePersonal = this.sharedService.findUserPhone(
        this.user
      )?.landlinePersonal
      this.landlineBusiness = this.sharedService.findUserPhone(
        this.user
      )?.landlineBusiness

      this.lang =
        this.user != null && user.language != null && user.language.length !== 0
          ? user.language.find(s => s.primary).language
          : ''

      if (this.user?.lastPassswordChange != null) {
        const newDate = new Date(this.user.lastPassswordChange)
        this.logger.debug('date: ' + this.user.lastPassswordChange)
        this.date = newDate.toISOString()
      }

      this.setTelephoneMask(this.landlinePersonal)
      this.setTelephoneMask(this.landlineBusiness)
      this.setTelephoneMask(this.mobile)
      this.setTelephoneMask(this.mobileBusiness)

      this.cdRef.detectChanges()
    })
  }

  setTelephoneMask (phone: UserTelephoneExtended): void {
    if (phone?.number != null) {
      // if number starts with +41 add mask
      this.setTelephoneMaskInner(phone, 3, '+41')
    }
  }

  setTelephoneMaskInner (
    phone: UserTelephoneExtended,
    prefixLength: number,
    comparePrefix
  ): void {
    const prefix = phone.number.substring(0, prefixLength)
    if (prefix === comparePrefix) {
      const mask = this.sharedService.setPhoneMask(prefix)
      if (mask != null) {
        phone.mask = mask
        phone.prefix = prefix
        phone.phone = phone.number.substring(prefixLength, phone.number.length)
      }
    }
  }

  getDriversLicence (): void {
    this.documentsService
      .getDriversLicence()
      .subscribe((res: DriversLicenceDTO) => {
        if (Object.keys(res).length > 0) {
          for (const key of Object.keys(res)) {
            // avoiding caching images with extra params, params ignored by server
            this.documents[key] =
              String(res[key].publicUrl) + '&ts=' + String(new Date().getTime())
          }
        }
      })
  }

  getEmailChangeDetails (): void {
    this.userService
      .getEmailChangeDetails()
      .subscribe((res: EmailChangeDetailsResponse) => {
        this.emailChangeDetails = res
      })
  }

  openUserImgDetails (): void {
    this.sendMatomoEvent('profile picture')
    const dialogRef = this.dialog.open(RevolutionUserImgDetailsPopupComponent, {
      data: this.user,
      panelClass: 'mat-dialog-cpt'
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result?.url != null) {
        this.reloadUserData()
      }
    })
  }

  openModal (component, user?: User, css?: string[], usage?: PhoneUsage): void {
    const cssClass = css != null ? css : 'mat-dialog-cpt'
    const userCopy = JSON.parse(JSON.stringify(user)) // { ...user } // spread operator doesnt work here
    let dialogData = userCopy
    if (usage != null) {
      dialogData = {
        user: userCopy,
        usage
      }
    }
    const dialogRef = this.dialog.open(component, {
      data: dialogData,
      panelClass: cssClass,
      restoreFocus: false
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== false) {
        this.reloadUserData()
        // eslint-disable-next-line @typescript-eslint/dot-notation
        const successCallback = dialogRef.componentInstance['onSuccessCallback']
        if (successCallback != null) {
          this[successCallback]()
        }
      }
    })
  }

  emailChanged (): void {
    this.sharedService.openConfirmationPopup(
      'shared.success',
      'customerPortal.customer-portal.details.change.email.success.text'
    )
    this.getEmailChangeDetails()
  }

  addressChanged (): void {
    this.sharedService.openConfirmationPopup(
      'shared.success',
      'customerPortal.customer-portal.details.change.addressChanged'
    )
  }

  openPasswordDialog (user: User): void {
    this.sendMatomoEvent('password')
    this.openModal(PasswordChangePopupComponent, user, [
      'mat-dialog-cpt',
      'scrollable'
    ]) // revolution dialog doesnt work
  }

  openNameDialog (user: User): void {
    this.sendMatomoEvent('name')
    this.openModal(RevolutionNameChangePopupComponent, user)
  }

  openEmailDialog (user: User): void {
    this.sendMatomoEvent('email')
    this.openModal(RevolutionEmailChangePopupComponent, user)
  }

  openAddressDialog (user: User): void {
    this.sendMatomoEvent('address')
    this.openModal(RevolutionAddressChangePopupComponent, user)
  }

  openTelephoneDialog (user: User, usage: PhoneUsage): void {
    this.sendMatomoEvent(usage + ' mobile')
    this.openModal(
      TelephoneChangePopupComponent,
      user,
      ['mat-dialog-cpt', 'change-mobile-phone'],
      usage
    ) // revolution dialog doesnt work
  }

  openBirthdateDialog (user: User): void {
    this.sendMatomoEvent('birthday')
    this.openModal(RevolutionBirthdayPopupComponent, user)
  }

  openLanguageDialog (user: User): void {
    this.sendMatomoEvent('language')
    this.openModal(MultilanguagePopupComponent, user) // using same popup as in main menu
  }

  openCompanyNumberLandline (user: User): void {
    this.sendMatomoEvent('business landline')
    this.openModal(CompanyNumberLandlineComponent, user)
  }

  openNumberLandline (user: User): void {
    this.sendMatomoEvent('private landline')
    this.openModal(PersonalNumberLandlineComponent, user) // revolution dialog doesnt work
  }

  checkIfSelected (): void {
    this.disable = (this.note.value ?? false) === false
  }

  loadLicence (side: DocumentSideEnum): void {
    this.sendMatomoEvent("driver's license")
    const dialogRef: MatDialogRef<RevolutionUploadDrivingLicensePopupComponent> =
      this.dialog.open(RevolutionUploadDrivingLicensePopupComponent, {
        data: this.documents, // for immediate display in modal
        panelClass: 'mat-dialog-cpt',
        restoreFocus: false
      })
    dialogRef.componentInstance.imgType = side
  }

  deleteAccount (user: User): void {
    this.sendMatomoEvent('delete account')
    this.openModal(RevolutionDeleteAccountPopupComponent, user)
  }

  mobileActionClicked (action: cptMenuAction, user): void {
    if (action === 'edit') {
      this.openTelephoneDialog(user, 'private')
    } else {
      this.deletePhoneConfirm('personalMobile')
    }
  }

  landlinePersonalActionClicked (action: cptMenuAction, user): void {
    if (action === 'edit') {
      this.openNumberLandline(user)
    } else {
      this.deletePhoneConfirm('personalLandline')
    }
  }

  landlineBusinessActionClicked (action: cptMenuAction, user): void {
    if (action === 'edit') {
      this.openCompanyNumberLandline(user)
    } else {
      this.deletePhoneConfirm('businessLandline')
    }
  }

  mobileBusinessActionClicked (action: cptMenuAction, user): void {
    if (action === 'edit') {
      this.openTelephoneDialog(user, 'business')
    } else {
      this.deletePhoneConfirm('businessMobile')
    }
  }

  deletePhoneConfirm (key: UserTelephoneKey): void {
    const dialogRef = this.dialog.open(ConfirmationQuestionPopupComponent, {
      data: {
        title: 'customerPortal.customer-portal.' + key + '.delete',
        text: 'customerPortal.customer-portal.' + key + '.delete.confirmation',
        cancel: 'shared.cancel',
        save: 'shared.clear',
        saveButtonColor: 'warn',
        preventCloseBeforeComplete: true
      },
      panelClass: 'mat-dialog-cpt'
    })
    const sub = dialogRef.componentInstance.confirm.subscribe(() => {
      this.deletePhone(dialogRef, key)
    })
    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe()
    })
  }

  deletePhone (dialogRef, key: UserTelephoneKey): void {
    let type: UserTelephoneType
    let usage: UserTelephoneUsage
    switch (key) {
      case 'personalMobile':
        type = 'mobile'
        usage = 'private'
        break
      case 'personalLandline':
        type = 'landline'
        usage = 'private'
        break
      case 'businessLandline':
        type = 'landline'
        usage = 'business'
        break
      case 'businessMobile':
        type = 'mobile'
        usage = 'business'
        break
    }
    this.sendMatomoEvent(usage + ' ' + type)
    this.userService.deleteTelephone(type, usage).subscribe(
      () => {
        if (key === 'businessLandline') {
          this.landlineBusiness = null
        }
        if (key === 'personalLandline') {
          this.landlinePersonal = null
        }
        if (key === 'personalMobile') {
          this.mobile = null
        }
        if (key === 'businessMobile') {
          this.mobileBusiness = null
        }
        dialogRef.close()
        this.reloadUserData()
        this.openDeleteConfirmationDialog(key, 'success')
      },
      error => {
        dialogRef.close()
        this.logger.log(error)
        this.openDeleteConfirmationDialog(key, 'failure')
      }
    )
  }

  openDeleteConfirmationDialog (
    key: UserTelephoneKey,
    result: 'success' | 'failure'
  ): void {
    this.loading = false
    this.dialog.open(ConfirmationPopupComponent, {
      data: {
        title: 'customerPortal.customer-portal.general.' + result,
        text: 'customerPortal.customer-portal.' + key + '.delete.' + result
      },
      panelClass: 'mat-dialog-cpt'
    })
  }

  hasPendingEmailChange (): boolean {
    if (this.emailChangeDetails?.newEmail != null) {
      if (this.emailChangeDetails.validTo != null) {
        if (new Date(this.emailChangeDetails.validTo) < new Date()) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    }
    return false
  }

  setShowUserNoticeCard (show): void {
    this.showUserNoticeCard = show
  }

  isPerson (): boolean {
    return (
      this.sharedService.stringExists(this.user?.firstName) &&
      this.sharedService.stringExists(this.user?.lastName) &&
      this.sharedService.stringExists(this.user?.salute)
    )
  }

  sendMatomoEvent (property: string): void {
    this.matomoIntegrationService.trackEvent(
      'My account',
      'Personal data change',
      property
    )
  }
}
