<customer-portal-vehicle-documents-disclaimer
  [translateKey]="'customerPortal.customer-portal.documents.limited-usability'"
></customer-portal-vehicle-documents-disclaimer>

<div class="grid grid-cols-1 lg:grid-cols-10 gap-6">
  <div class="col-span-1 lg:col-span-7" *ngIf="loadingDocuments">
    <ngx-skeleton-loader
      appearance="line"
      animation="progress"
      [count]="12"
    ></ngx-skeleton-loader>
  </div>

  <div *ngIf="!loadingDocuments" class="col-span-1 lg:col-span-7">
    <ng-container *ngIf="vehicleDocuments.length === 0">
      <div
        class="w-full h-full bg-mat-ef-revolution-grey-200 rounded-xl flex flex-col justify-center items-center py-4"
      >
        <div class="text-mat-ef-revolution-supergrey-900">
          <mat-icon svgIcon="no_sim"></mat-icon>
        </div>
        <div class="text-base font-efSans-primary">
          <T
            str="You currently have no documents"
            key="customerPortal.customer-portal.no-documents"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </div>
    </ng-container>
    <div class="flex justify-end mb-3" *ngIf="unread > 0">
      <button
        mat-button
        mat-stroked-button
        class="revolution min-h-[32px] leading-none small-button"
        (click)="onMarkAllAsReadClicked()"
        color="secondary"
      >
        <mat-icon svgIcon="visibility_on" class="mr-2 min-h-[18px] min-w-[18px]"></mat-icon>
        <T
          str="Mark all as read"
          key="customerPortal.customer-portal.documents.mark-all-as-read"
          tags="customer-portal, 3.11"
        ></T>
      </button>
    </div>
    <div *ngIf="vehicleDocuments.length > 0">
      <div>
        <div class="flex items-center justify-between">
          <!-- prettier-ignore -->
          <p
              class="font-efSans-primary  font-bold text-xl"
            >
            {{ 'documents' | sharedTranslate | titlecase }}
            </p>
          <p class="font-efSans-primary text-lg">
            {{ allFilteredDocumentsLength }}
            <T
              str="Result"
              key="customerPortal.customer-portal.result"
              tags="customer-portal, 3.1"
            ></T>
          </p>
        </div>
      </div>

      <mat-divider class="relative w-full"></mat-divider>

      <div>
        <div
          *ngFor="let element of vehicleDocuments; let i = index"
          class="panel"
        >
          <!-- DocumentSales -->
          <mat-expansion-panel
            *ngIf="filterOnlyDocumentSales(element)"
            class="p-0"
            (expandedChange)="(false)"
            (afterExpand)="
              sendContentModulesDataWithoutTranslate(
                true,
                element?.brandName + ' ' + element?.modelName
              );
              documentRead(element)
            "
            (afterCollapse)="
              sendContentModulesDataWithoutTranslate(
                false,
                element?.brandName + ' ' + element?.modelName
              )
            "
            (opened)="documentIndex = i"
            [expanded]="documentIndex === i"
          >
            <mat-expansion-panel-header
              class="cpt-panel px-2 md:px-6"
              [collapsedHeight]="'auto'"
              [expandedHeight]="'auto'"
            >
              <mat-panel-title class="flex m-0">
                <div class="w-8 items-center">
                  <customer-portal-app-document-icon
                  [setDocument]="element">
                </customer-portal-app-document-icon>
                </div>
                <div class="w-6 flex items-center justify-center">
                  <span
                    *ngIf="!element?.read"
                    (click)="documentRead(element)"
                    class="bg-mat-ef-revolution-secondary-500 rounded-full h-2 w-2 mx-2"
                  ></span>
                </div>
                <customer-portal-app-document-header class="flex-1" [setDocument]="element">
                </customer-portal-app-document-header>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div>
              <div *ngIf="documentIndex === i">
                <div class="flex m-0">
                  <div class="flex-1 ml-[44px] md:ml-[62px]">
                    <customer-portal-app-offer-contract-info
                      [setDocument]="element"
                    >
                    </customer-portal-app-offer-contract-info>
                  </div>
                </div>
              </div>

              <div class="relative -r-10 w-full mt-5">
                <customer-portal-app-document-buttons
                [setDocument]="element"
                [downloadStarted]="downloadStarted"
                >
                </customer-portal-app-document-buttons>
              </div>
            </div>
          </mat-expansion-panel>
          <!-- DocumentSales -->

          <!-- Service -->
          <mat-expansion-panel
            *ngIf="filterService(element)"
            class="p-0"
            (expandedChange)="(false)"
            (afterExpand)="
              sendContentModulesData(
                true,
                'customerPortal.customer-portal.service-history.servicecode.' +
                  element?.code?.toLowerCase() || element?.description
              );
              documentRead(element)
            "
            (afterCollapse)="
              sendContentModulesData(
                false,
                'customerPortal.customer-portal.service-history.servicecode.' +
                  element?.code?.toLowerCase() || element?.description
              )
            "
          >
            <mat-expansion-panel-header
              class="cpt-panel px-2 md:px-6"
              [collapsedHeight]="'auto'"
              [expandedHeight]="'auto'"
            >
              <mat-panel-title class="flex m-0">
                <div class="w-8 items-center">
                  <div class="items-center">
                  <customer-portal-app-document-icon
                    [setDocument]="element">
                  </customer-portal-app-document-icon>
                  </div>
                </div>
                <div class="w-6 flex items-center justify-center">
                  <span
                    *ngIf="!element?.read"
                    (click)="documentRead(element)"
                    class="bg-mat-ef-revolution-secondary-500 rounded-full h-2 w-2 mx-2"
                  ></span>
                </div>
                <customer-portal-app-document-header class="flex-1" [setDocument]="element">
                </customer-portal-app-document-header>
              </mat-panel-title>
            </mat-expansion-panel-header>

          
            <div class="mr-0 mt-0 w-full">

              <div class="flex-1 ml-[45px] md:ml-[60px] flex flex-col gap-3"> 
                <customer-portal-app-offer-contract-info
                  [setDocument]="element">
                </customer-portal-app-offer-contract-info>
              </div>
    
              <div
                class="relative -r-10 w-full mt-5"
              >
              <customer-portal-app-document-buttons
              [setDocument]="element"
              [downloadStarted]="downloadStarted"
              >
              </customer-portal-app-document-buttons>
              </div>
            </div>
          </mat-expansion-panel>
          <!-- Service -->

          <!-- Document -->
          <mat-expansion-panel
            data-cy="vehicle-document-editable"
            *ngIf="filterOnlyDocument(element)"
            (click)="documentRead(element)"
            class="p-0"
            (expandedChange)="(false)"
            hideToggle
            disabled
          >
            <mat-expansion-panel-header
              class="cpt-panel px-2 md:px-6"
              [collapsedHeight]="'auto'"
              [expandedHeight]="'auto'"
            >
              <mat-panel-title class="flex m-0">
                <div class="w-8 items-center">
                  <customer-portal-app-document-icon [setDocument]="element">
                  </customer-portal-app-document-icon>
                </div>

                <div class="w-6 flex items-center justify-center">
                  <span
                    *ngIf="!element?.read"
                    (click)="documentRead(element)"
                    class="bg-mat-ef-revolution-secondary-500 rounded-full h-2 w-2 mx-2"
                  ></span>
                </div>

                <div class="flex-1 grid grid-cols-12 gap-2 m-0">
                  <div
                    class="col-span-6 ml-1 text-mat-ef-revolution-primary-500"
                  >
                    <div
                      data-cy="vehicle-document-name"
                      class="font-efSans text-sm md:text-base font-bold break-words"
                      *ngIf="element?.name"
                    >
                      {{ element?.name }}
                    </div>
                    <div class="font-efSans text-sm md:text-base">
                      {{
                        sharedService.getDocumentDate(element)
                          | date: dateFormat
                      }}
                    </div>
                    <div
                      data-cy="vehicle-document-format"
                      class="font-efSans text-sm md:text-base"
                    >
                      {{ element?.format | uppercase }}
                    </div>
                  </div>

                  <div
                    class="grid col-span-6 text-mat-ef-revolution-primary-500 items-center"
                  >
                    <div
                      class="ml-1 font-efSans text-sm md:text-base font-bold break-words"
                      *ngIf="element?.type"
                    >
                      <ng-container
                        *ngTemplateOutlet="
                          typeTranslation;
                          context: { element: element }
                        "
                      >
                      </ng-container>
                    </div>
                    <div
                      class="font-efSans text-sm md:text-base font-bold break-words"
                      *ngIf="!element?.type && !element?.invNumber"
                    >
                      -
                    </div>
                    <p
                      *ngIf="element?.invNumber"
                      class="font-efSans text-sm md:text-base"
                    >
                      {{ element?.invNumber }}
                    </p>
                  </div>
                </div>
                <div
                  class="flex flex-col md:flex-row items-center absolute"
                  [ngClass]="{
                    'right-0 md:right-4': !element?.readOnly,
                    '-right-2 md:right-2': element?.readOnly
                  }"
                >
                  <button
                    class="min-h-[36px]"
                    mat-icon-button
                    *ngIf="element?.readOnly"
                    (click)="downloadFile(element, true)"
                    [disabled]="downloadStarted"
                  >
                    <mat-icon
                      svgIcon="visibility_on"
                      class="text-mat-ef-revolution-secondary-500 cursor-pointer"
                    ></mat-icon>
                  </button>
                  <button
                    class="min-h-[36px]"
                    mat-icon-button
                    *ngIf="element?.readOnly"
                    (click)="downloadFile(element)"
                    [disabled]="downloadStarted"
                  >
                    <!-- prettier-ignore -->
                    <mat-icon
                        class="text-mat-ef-revolution-secondary-500 cursor-pointer"
                        matTooltip="{{ 'Download' | translate:{ _key: 'customerPortal.customer-portal.vehicle.documents-menu.download', _tags: 'customer-portal, 3.1'} }}"
                        [matTooltipPosition]="'above'"
                        svgIcon="download"
                      ></mat-icon>
                  </button>
                  <customer-portal-app-cpt-menu
                    *ngIf="!element?.readOnly"
                    [menuType]="'fileMenu'"
                    class="inline-flex"
                    (actionClicked)="actionClicked($event, element)"
                  ></customer-portal-app-cpt-menu>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel>

          <!-- Document  -->
          <mat-divider></mat-divider>
        </div>

        <mat-paginator
          *ngIf="allFilteredDocumentsLength > 10 && showPaginator"
          ef-paginator
          [showTotalPages]="10"
          [length]="allFilteredDocumentsLength"
          [pageSize]="pageSize"
          (page)="onPageChange($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
  <div class="col-span-1 lg:col-span-3">
    <div
      *ngIf="!loadingDocuments"
      class="rounded-md bg-mat-ef-revolution-grey-500 mt-5 md:mt-0"
    >
      <div class="px-6 py-2 md:py-6">
        <p
          class="font-efSans text-[15px] font-bold text-mat-ef-revolution-primary-500 mb-3"
        >
          <T
            str="Filter documents"
            key="customerPortal.customer-portal.documents.filter"
            tags="customer-portal, 3.1"
          ></T>
        </p>

        <form [formGroup]="documentSelectFormGroup">
          <mat-form-field appearance="legacy" class="white w-full">
            <mat-label>
              <T
                str="Data type"
                key="customerPortal.customer-portal.date-type"
                tags="customer-portal, 3.1"
              ></T
            ></mat-label>

            <mat-select
              [compareWith]="compareWith"
              formControlName="documentType"
              multiple
            >
              <mat-option *ngFor="let d of documentTypes" [value]="d">
                <ng-container
                  *ngTemplateOutlet="
                    sharedService.template('transifexTranslation');
                    context: {
                      str: d?.value?.toLowerCase(),
                      key: d?.key
                    }
                  "
                ></ng-container>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="loader"> </ng-container>

    <div
      class="vehicle-boxes-rounded mt-6 grid gap-2"
      *ngIf="!loadingDocuments"
    >
      <p class="font-efSans text-xl font-bold">
        <T
          str="Add documents"
          key="customerPortal.customer-portal.own.documents"
          tags="customer-portal, 3.1"
        ></T>
      </p>

      <button
        data-cy="vehicle-upload-document-button"
        class="revolution small-button md:medium-button"
        mat-button
        mat-stroked-button
        color="secondary"
        (click)="
          uploadDocument();
          sendContentModulesMiniCTAData(
            'customerPortal.customer-portal.vehicle.documents-upload.title'
          )
        "
      >
        <mat-icon svgIcon="add"></mat-icon>

        <T
          str="Add document"
          key="customerPortal.customer-portal.vehicle.documents-upload.title"
          tags="customer-portal, 3.1"
        ></T>
      </button>
    </div>
    <ng-container *ngTemplateOutlet="loader"> </ng-container>
  </div>
</div>

<ng-template #loader>
  <div
    class="border rounded-xl p-6 w-full flex flex-col h-40 mb-6"
    *ngIf="loadingDocuments"
  >
    <div class="w-full">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>
    <div class="w-full flex-1 flex justify-center items-center">
      <customer-portal-ef-spinner
        class="absolute"
        [color]="'mat-ef-revolution-grey-two-500'"
        [opacity]="0.6"
        [diameter]="40"
      >
      </customer-portal-ef-spinner>
    </div>
  </div>
</ng-template>

<ng-template #typeTranslation let-element="element">
  <!-- https://emilfreygroup.atlassian.net/browse/CPEFG-3843  -->
  {{
    {
      str:
        element?.type === 'SERVICE' ? 'invoice' : element?.type?.toLowerCase(),
      prefix: 'customerPortal.customer-portal.'
    } | transifexTranslate
  }}
</ng-template>
