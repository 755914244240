<div
  class="RevolutionSearchDealerSellerPopupComponent cpt-modal max-h-[unset] relative ef-scrollbar"
>
  <div mat-dialog-title class="md:px-10">
    <T
      str="Select dealer"
      key="customerPortal.customer-portal.change-dealer-seller.select-dealer"
      tags="customer-portal, 3.1"
    ></T>
  </div>

  <mat-dialog-content
    class="m-0 p-0 flex flex-col flex-1 relative"
    *ngIf="data.dealers"
  >
    <div class="absolute top-0 bottom-0 left-0 right-0">
      <div
        class="content-wrapper p-2 md:px-10 md:py-8 flex flex-col flex-1 w-full h-full"
      >
        <form class="z-20" [formGroup]="searchDealerForm">
          <div
            class="search-dealer-form w-full flex gap-2 md:gap-6 flex-col md:flex-row justify-between"
          >
            <mat-form-field
              class="no-padding max-w-[400px] flex-1 relative bg-transparent white"
            >
              <input
                class="max-w-[calc(100%_-_80px)] px-10"
                type="text"
                matInput
                formControlName="filter"
              />
              <mat-icon
                class="absolute left-0 top-3 cursor-pointer text-mat-ef-revolution-primary-500"
                svgIcon="search"
              >
              </mat-icon>
              <mat-icon
                class="absolute right-0 top-3 cursor-pointer text-mat-ef-revolution-primary-500"
                (click)="clearFilter()"
                svgIcon="close"
              >
              </mat-icon>
            </mat-form-field>

            <mat-form-field
              class="max-w-[400px] no-padding hidden md:flex md:flex-grow-0 brand-field bg-transparent white"
            >
              <mat-label>
                {{ 'brand' | sharedTranslate }}
              </mat-label>
              <mat-select formControlName="brand" class="w-full">
                <mat-option *ngIf="selectedBrand" [value]="selectedBrand">
                  {{ selectedBrand.name }}
                </mat-option>
                <mat-option [value]="'any'">
                  <T
                    str="All brands"
                    key="customerPortal.customer-portal.change-dealer-seller.search.brands.all"
                    tags="customer-portal, 3.1"
                  ></T>
                </mat-option>
                <mat-option *ngFor="let b of brands" [value]="b">
                  {{ b.name | uppercase }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              class="max-w-[400px] no-padding w-full flex-1 md:hidden brand-field bg-transparent white"
            >
              <mat-label>
                {{ 'brand' | sharedTranslate }}
              </mat-label>
              <select matNativeControl formControlName="brand">
                <option *ngIf="selectedBrand" [ngValue]="selectedBrand">
                  {{ selectedBrand.name }}
                </option>
                <option [ngValue]="null">
                  <T
                    str="All brands"
                    key="customerPortal.customer-portal.change-dealer-seller.search.brands.all"
                    tags="customer-portal, 3.1"
                  ></T>
                </option>
                <option *ngFor="let b of brands" [ngValue]="b">
                  {{ b.name | uppercase }}
                </option>
              </select>
            </mat-form-field>
          </div>
        </form>

        <div
          class="max-w-[400px] z-20 overflow-auto bg-white rounded-md mt-4 py-6 flex justify-center items-center"
          *ngIf="showDealerList && loadingDealers"
        >
          <customer-portal-ef-spinner
            [color]="'mat-ef-revolution-secondary-500'"
            [opacity]="1"
            [diameter]="50"
          ></customer-portal-ef-spinner>
        </div>

        <div
          class="max-w-[400px] z-20 overflow-auto bg-white rounded-md mt-4 min-h-[50px] flex flex-col"
          *ngIf="showDealerList && filteredOptions_filter?.length > 0"
        >
          <div class="flex relative justify-end p-2 pb-0">
            <button class="outline-0 h-6" (click)="toggleDealerList()">
              <mat-icon svgIcon="close"></mat-icon>
            </button>
          </div>

          <div class="flex-1 overflow-y-auto">
            <div *ngFor="let dealer of filteredOptions_filter; let last = last">
              <ng-container
                *ngTemplateOutlet="dealerTemplate; context: { data: dealer }"
              >
              </ng-container>
              <div *ngIf="!last">
                <hr class="text-mat-ef-revolution-supergrey-500 mx-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="map w-full h-full flex flex-1 relative z-10">
      <customer-portal-agm-map
        #map
        [view]="'seller'"
        [zoom]="zoom"
        [longitude]="lng"
        [latitude]="lat"
        [markDraggable]="true"
        [zoomControl]="true"
        [fitBounds]="true"
        [usePanning]="true"
        [height]="'100%'"
        [markers]="markers"
        [markersSelected]="markersSelected"
        (markerClicked)="markerClicked($event)"
        class="h-full w-full"
      >
      </customer-portal-agm-map>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="!flex !justify-center mt-7">
    <button
      class="absolute top-0 right-0 md:right-10 scale-150 w-6 h-6 outline-0"
      [mat-dialog-close]="false"
    >
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <button
      mat-button
      mat-stroked-button
      color="secondary"
      class="revolution btn-dialog"
      cdkFocusInitial
      (click)="onSubmit()"
    >
      <mat-icon svgIcon="arrow_left"></mat-icon>
      {{ 'back' | sharedTranslate }}
    </button>
  </mat-dialog-actions>
</div>

<ng-template #dealerTemplate let-currentDealer="data">
  <div
    #mapDealerTemplateList
    id="{{ currentDealer.id }}"
    class="w-full p-4 flex flex-col text-mat-ef-revolution-primary-500 font-efSans"
  >
    <div class="flex gap-4">
      <div class="flex-1 flex flex-col">
        <div class="text-base font-bold">
          {{ currentDealer.name }} {{ currentDealer.subname }}
        </div>
        <div class="text-sm">
          <div>{{ currentDealer.street }}</div>
          <div>{{ currentDealer.zip }} {{ currentDealer.city }}</div>
        </div>
      </div>
      <ng-container
        *ngIf="
          !data.serviceHoursCondition ||
          (data.serviceHoursCondition &&
            currentDealer.openingHours?.service?.length > 0)
        "
      >
        <button
          *ngIf="currentDealer.id === selectedDealer?.id"
          mat-button
          mat-flat-button
          color="primary"
          disabled
          class="revolution text-sm !max-h-8 !min-h-fit h-8 !text-mat-ef-revolution-primary-500 min-w-[100px]"
        >
          <mat-icon class="mr-1" svgIcon="check_circle"></mat-icon>
          <T
            str="Selected"
            key="customerPortal.customer-portal.change-dealer-seller.selected"
            tags="customer-portal, 3.1"
          ></T>
        </button>
        <button
          *ngIf="currentDealer.id !== selectedDealer?.id"
          mat-button
          mat-stroked-button
          color="secondary"
          class="revolution text-sm !max-h-8 h-8 !min-h-fit min-w-[100px]"
          (click)="dealerSelected(currentDealer)"
        >
          <T
            str="Select"
            key="customerPortal.customer-portal.change-dealer-seller.select"
            tags="customer-portal, 3.1"
          ></T>
        </button>
      </ng-container>
    </div>

    <div
      *ngIf="currentDealer?.telephone || currentDealer?.email"
      class="mt-2 gap-1 h-fit w-full flex flex-col text-mat-ef-revolution-secondary-500"
    >
      <ng-container
        *ngTemplateOutlet="
          dealerContactTemplate;
          context: { data: currentDealer }
        "
      >
      </ng-container>
    </div>

    <div class="flex relative">
      <div class="absolute -bottom-[34px] right-0 z-10">
        <div
          (click)="
            showDirection(currentDealer);
            sendOutboundLinkData('Routefinder', currentDealer)
          "
          class="cursor-pointer relative text-mat-ef-revolution-secondary-500 w-fit text-xs flex font-bold pl-6"
        >
          <mat-icon
            svgIcon="location_on"
            class="scale-75 absolute -top-[4px] left-0"
          ></mat-icon>
          <T
            str="Route"
            key="customerPortal.customer-portal.dealer-info.route"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </div>
    </div>
    <div class="w-full mt-4">
      <customer-portal-app-dealer-hours
        [dealer]="currentDealer"
      ></customer-portal-app-dealer-hours>
    </div>
  </div>
</ng-template>

<ng-template #dealerContactTemplate let-currentDealer="data">
  <div class="flex items-center" *ngIf="currentDealer.telephone">
    <div class="flex items-center">
      <mat-icon class="!h-[18px] !w-[18px] mr-2" svgIcon="phone"></mat-icon>
    </div>
    <a
      data-rel="external"
      class="text-xs font-semibold cursor-pointer"
      (click)="openPhoneClient($event, currentDealer)"
    >
      {{ currentDealer.telephone }}
    </a>
  </div>
  <div class="flex items-center" *ngIf="currentDealer.email">
    <div class="flex items-center">
      <mat-icon class="!h-[18px] !w-[18px] mr-2" svgIcon="mail"></mat-icon>
    </div>
    <a
      data-rel="external"
      class="text-xs font-semibold cursor-pointer"
      (click)="openEmailClient($event, currentDealer)"
    >
      {{ currentDealer.email }}</a
    >
  </div>
</ng-template>
