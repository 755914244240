import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import { SharedService } from '../../../../services/shared.service'

import { CptGoogleTagmanagerService, VehicleDTOExtended } from '@inside-hub-app/customer-portal-shared'
import { AppointmentsService } from '../../../../services/appointments.service'
import {
  WorkingHoursResponse,
  WorkingHoursService
} from '../../../../services/working-hours.service'
import { AppointmentsPopupComponent } from '../../../revolution/appointments-popup/appointments-popup.component'
import {
  DayTimeDTO,
  DealerDTO,
  WorkingDays
} from '../../../../services/dealers.service'
import { AppointmentsPopupChooseStepComponent } from '../../appointments-popup/appointments-popup-choose-step/appointments-popup-choose-step.component'
import { MatDialog } from '@angular/material/dialog'
import { DataService } from '../../../../services/data.service'
import { TiresAppointmentDTO } from '../../../../services/tires.service'
import { NGXLogger } from 'ngx-logger'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'

@Component({
  selector: 'customer-portal-app-revolution-dealer-info',
  templateUrl: './revolution-dealer-info.component.html'
})
export class RevolutionDealerInfoComponent implements OnInit {
  @Input() dealer: DealerDTO
  @Input() vehicle: VehicleDTOExtended
  @Input() type: string
  @Input() liveChatAvailable
  @Output() openChatWindow = new EventEmitter()

  service: DayTimeDTO[]
  sales: DayTimeDTO[]

  workingDaysSales: WorkingDays[] = []
  workingDaysService: WorkingDays[] = []

  workingDaysSalesGrouped = {}
  workingDaysServiceGrouped = {}

  public user: User
  public day: string

  open = false
  public currentDate: Date
  workingHours: WorkingHoursResponse

  componentSub = {
    appointmentLinkLoaded: null
  }
  link: TiresAppointmentDTO
  hasServiceManager = false
  constructor(
    private readonly workingHoursService: WorkingHoursService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly appointmentsService: AppointmentsService,
    public readonly sharedService: SharedService,
    private readonly dataService: DataService,
    private readonly logger: NGXLogger,
    public dialog: MatDialog,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasServiceManager = this.remoteConfigService.get(
      'hasServiceManager'
    )
  }

  ngOnInit(): void {
    this.componentSub.appointmentLinkLoaded =
    this.dataService.onVehicleTiresAppintmentLinkLoaded.subscribe(
      data => {
        this.link = data
      },
      err => {
        this.logger.debug(err)
      }
    )


    this.currentDate = new Date()
    this.service =
      this.dealer.openingHours?.service != null
        ? this.dealer.openingHours.service
        : []
    this.sales =
      this.dealer.openingHours?.sales != null
        ? this.dealer.openingHours.sales
        : []
    if (this.type === 'sales') {
      if (this.sales.length > 0) {
        this.workingDaysSalesGrouped = this.workingHoursService.showHoursSales(
          this.sales,
          this.workingDaysSales
        )
        this.workingHours = this.workingHoursService.calculateIfWorking(
          this.sales,
          this.currentDate,
          'sales'
        )
      } else {
        this.workingHours = this.workingHoursService.calculateIfWorking(
          this.service,
          this.currentDate,
          'service'
        )
      }
    } else {
      if (this.service.length > 0) {
        this.workingDaysServiceGrouped =
          this.workingHoursService.showHoursService(
            this.service,
            this.workingDaysService
          )
        this.workingHours = this.workingHoursService.calculateIfWorking(
          this.service,
          this.currentDate,
          'service'
        )
      } else {
        this.workingHours = this.workingHoursService.calculateIfWorking(
          this.sales,
          this.currentDate,
          'sales'
        )
      }
    }

    if (this.workingHours == null) {
      this.workingHours = { open: false }
    }
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.componentSub)
  }

  sendOutboundLinkData(outboundLinkType: string, dealer: DealerDTO): void {
    this.cptGtmService.sendOutboundData(outboundLinkType, dealer)
  }

  sendContentModulesCTAData(contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }

  sendLiveChatOpenedGtmData(): void {
    this.cptGtmService.sendLiveChatOpenedGtmData()
  }

  openPhoneClient(event: Event): void {
    event.stopPropagation()
    const phone = this.dealer.telephone.toLowerCase()
    const link = 'tel:' + phone
    window.open(link, '_blank')
  }

  openEmailClient(event: Event): void {
    event.stopPropagation()
    const mail = this.dealer.email.toLowerCase()
    const link = 'mailto:' + mail
    window.open(link, '_blank')
  }

  sendGTMData (): void {
    const data = {
      delivery: 'Delivery',
      reifenManager: true
    }
    this.cptGtmService.sendServiceAppointmentCheckoutData(data)
  }

  decideNextStep (): void {
    const dialogRef = this.dialog.open(AppointmentsPopupChooseStepComponent, {
      panelClass: ['mat-dialog-cpt', 'choose-step'],
      data: {
        vehicle: this.vehicle
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'CPT') {
        // open appointment popup
        this.newAppointment()
      }
      if (result === 'serviceManager') {
        this.openServiceManager()
      }
    })
  }

  openServiceManager (): void {
    window.open(this.link != null ? this.link.url : '', '_blank')
    this.sendGTMData()
  }

  newAppointment(): void {
    const data =  {
      vehicle: this.vehicle,
      dealer: this.dealer,
      step: 1
    }
    this.appointmentsService.newAppointment(AppointmentsPopupComponent, data)
  }
}
