<!-- Container for the keycloak login form, user is not logged in -->

<ng-container *ngIf="!loggedIn">
  <customer-portal-app-login-view></customer-portal-app-login-view>
</ng-container>

<!-- User logged in -->
<ng-container *ngIf="loggedIn">
  <router-outlet></router-outlet>

  <ng-container *ngIf="kcUpdateIframeSrc" class="kc-update-iframe">
    <iframe
      #kcupdateframe
      [src]="kcUpdateIframeSrc"
      (load)="kcUpdateIframeLoaded()"
      title="kc update iframe"
    ></iframe>
  </ng-container>

  <!--Size detector-->
  <customer-portal-app-size-detector></customer-portal-app-size-detector>
  <customer-portal-pwa-detector></customer-portal-pwa-detector>
  <customer-portal-app-bottom-navbar></customer-portal-app-bottom-navbar>
  <!-- IOS add to home screen -->
  <customer-portal-ios-add-homescreen-banner
    *ngIf="isIos && !isPwa && showIosAddHomeScreenBanner && pwaEnabled"
    class="h-screen w-screen absolute top-0"
    (bannerDismiss)="hideIosAddHomeScreenBanner()"
  ></customer-portal-ios-add-homescreen-banner>
</ng-container>

<customer-portal-lib-cpt-templates></customer-portal-lib-cpt-templates>

<customer-portal-ef-spinner-dialog
  *ngIf="showTransifexSpinner"
></customer-portal-ef-spinner-dialog>
