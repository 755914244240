import { Component, Input } from '@angular/core'
import { SharedService } from '../../../../services/shared.service'

@Component({
  selector: 'customer-portal-vehicle-documents-disclaimer',
  templateUrl: './vehicle-documents-disclaimer.component.html'
})
export class VehicleDocumentsDisclaimerComponent {
  @Input()
  translateKey

  constructor(public sharedService: SharedService) {}
}
