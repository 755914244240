import { Component } from '@angular/core'
import { CptGoogleTagmanagerService } from '../../../services/cpt-google-tagmanager.service'
import { SharedService } from '../../../services/shared.service'
@Component({
  selector: 'customer-portal-app-revolution-footer',
  templateUrl: './revolution-footer.component.html'
})
export class RevolutionFooterComponent {
  isMobilePwa
  constructor(
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly sharedService: SharedService
  ) {
    this.isMobilePwa = this.sharedService.isMobilePwa()
  }

  sendGTMNavigationData(label?: string): void {
    this.cptGtmService.sendNavigationLinkData(null, 'Footer Navigation', label)
  }
}
