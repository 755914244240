import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { DateAdapter } from '@angular/material/core'
import { MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS } from '@angular/material/legacy-core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import {
  CptDateAdapter,
  CptDateFormats,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { SharedService } from '../../../services/shared.service'
import { DatepickerScrollService } from '../../../services/datepickerScroll.service'
import { InstantErrorStateMatcherService } from '../../../services/instant-error-state-matcher.service'
import {
  VehicleDTOExtended,
  VehiclesService
} from '../../../services/vehicles.service'
import {
  ConfirmationPopupComponent,
  ConfirmationPopupDialogData
} from '../../basic/confirmation-popup/confirmation-popup.component'
import { CustomCalendarHeaderComponent } from '../../basic/custom-calendar-header/custom-calendar-header.component'

export const APP_DATE_FORMATS: CptDateFormats = {
  parse: {
    dateInput: 'MM/YYYY'
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    default: 'DD.MM.YYYY.'
  }
}

@Component({
  selector: 'customer-portal-app-edit-first-registration',
  templateUrl: './edit-first-registration.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: CptDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS
    }
  ]
})
export class EditFirstRegistrationComponent implements OnInit {
  registrationForm = new FormGroup({
    firstRegistration: new FormControl(null)
  })

  loading = false
  private readonly vehicleUpdated: VehicleDTOExtended
  matcher = new InstantErrorStateMatcherService()
  customHeader = CustomCalendarHeaderComponent

  now = new Date()
  constructor(
    private readonly dialogRef: MatDialogRef<EditFirstRegistrationComponent>,
    @Inject(MAT_DIALOG_DATA) public vehicle: VehicleDTOExtended,
    private readonly vehiclesService: VehiclesService,
    private readonly datepickerScrollService: DatepickerScrollService,
    public sharedService: SharedService,
    private readonly dialog: MatDialog,
    public transifexService: TransifexService
  ) {
    this.vehicleUpdated = vehicle
  }

  ngOnInit(): void {
    this.registrationForm
      .get('firstRegistration')
      .setValue(this.vehicle.firstRegistration)
  }

  chosenYearHandler(normalizedYear: Date): void {
    const ctrlValue: Date =
      this.registrationForm.get('firstRegistration').value != null
        ? new Date(this.registrationForm.get('firstRegistration').value)
        : new Date()
    ctrlValue.setFullYear(normalizedYear.getFullYear())

    this.registrationForm.get('firstRegistration').setValue(ctrlValue)
  }

  chosenMonthHandler(normalizedMonth: Date, picker): void {
    const ctrlValue: Date =
      this.registrationForm.get('firstRegistration').value != null
        ? new Date(this.registrationForm.get('firstRegistration').value)
        : new Date()
    ctrlValue.setMonth(normalizedMonth.getMonth())

    this.registrationForm.get('firstRegistration').setValue(ctrlValue)
    picker.close()
  }

  update(): void {
    this.loading = true
    const vehicleUpdated = this.sharedService.deepCopy(this.vehicleUpdated)
    delete vehicleUpdated.pictureType
    delete vehicleUpdated.leasingInfo
    delete vehicleUpdated.dealers
    delete vehicleUpdated.dealer
    delete vehicleUpdated.brandPicture
    delete vehicleUpdated.relations
    vehicleUpdated.firstRegistration =
      this.registrationForm.get('firstRegistration').value
    this.vehiclesService.addUserVehicle(vehicleUpdated).subscribe(
      value => {
        this.loading = false
        if (value != null) {
          this.dialogRef.close(value.firstRegistration)
        }
      },
      () => {
        this.dialogRef.close()
        const data: ConfirmationPopupDialogData = {
          title: 'shared.error',
          text: 'customerPortal.customer-portal.edit-firstRegistration.update.failure.text'
        }
        this.dialog.open(ConfirmationPopupComponent, {
          data,
          panelClass: 'mat-dialog-cpt'
        })
      }
    )
  }

  addScrolling(picker): void {
    this.datepickerScrollService.addScrolling(picker)
  }
}
