import { Component, type OnInit, type OnDestroy, Input } from '@angular/core'
import { MatDialog, type MatDialogRef } from '@angular/material/dialog'
import { EfSpinnerComponent, type EfSpinnerDialogData } from '../ef-spinner/ef-spinner.component'
import { TransifexTranslationObj } from '../../../services/shared.service'

@Component({
  selector: 'customer-portal-ef-spinner-dialog',
  template: '',
  styles: []
})
export class EfSpinnerDialogComponent implements OnInit, OnDestroy {
  @Input() message: TransifexTranslationObj
  private dialog: MatDialogRef<EfSpinnerComponent>
  constructor (
    private readonly matDialog: MatDialog
  ) { }

  ngOnInit (): void {
    const spinnerDialogData: EfSpinnerDialogData = {
      diameter: 100,
      opacity: 1,
      color: 'mat-ef-revolution-secondary-500',
      message: this.message
    }
    this.dialog = this.matDialog.open(EfSpinnerComponent, {
      data: spinnerDialogData,
      panelClass: 'EfSpinnerDialogComponent',
      disableClose: true,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      enterAnimationDuration: '0ms' // number throws error
    })
  }

  ngOnDestroy (): void {
    setTimeout(() => {
      this.dialog.close()
    })
  }
}
