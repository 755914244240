import { Injectable } from '@angular/core'
import { NGXLogger } from 'ngx-logger'
import { Subject } from 'rxjs'
import { throttleTime } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class DatepickerScrollService {
  private readonly datepickerScrollChanged: Subject<HTMLElement> =
    new Subject<HTMLElement>()

  // HTML
  // #scrollableDatePicker (opened)='addScrolling(scrollableDatePicker)'
  constructor(private readonly logger: NGXLogger) {}

  public addScrolling(picker): void {
    try {
      const element = picker._popupRef.overlayElement
      if (element.addEventListener != null) {
        // older FF
        element.addEventListener(
          'DOMMouseScroll',
          event => {
            this.onScroll(event, picker)
          },
          false
        )
      }
      element.onwheel = event => {
        this.onScroll(event, picker)
      } // modern standard
      element.onmousewheel = event => {
        this.onScroll(event, picker)
      } // older browsers, IE
      element.ontouchmove = event => {
        this.onScroll(event, picker)
      } // mobile
      element.onkeydown = event => {
        this.onScroll(event, picker)
      }
    } catch (error) {}
  }

  private onScroll(event, picker): void {
    this.preventScrollPropagation(event)

    if (event.deltaY < 0) {
      try {
        const prevBtn =
          picker._popupRef.overlayElement.querySelectorAll(
            '.mat-calendar-previous-button'
          )[0] != null
            ? picker._popupRef.overlayElement.querySelectorAll(
                '.mat-calendar-previous-button'
              )[0]
            : picker._popupRef.overlayElement.querySelectorAll(
                '.CustomCalendarHeaderComponent-previous-button'
              )[0]
        if (prevBtn != null) {
          this.onDatepickerScrollChanged(prevBtn)
        }
      } catch (error) {}
    } else {
      try {
        const nextBtn =
          picker._popupRef.overlayElement.querySelectorAll(
            '.mat-calendar-next-button'
          )[0] != null
            ? picker._popupRef.overlayElement.querySelectorAll(
                '.mat-calendar-next-button'
              )[0]
            : picker._popupRef.overlayElement.querySelectorAll(
                '.CustomCalendarHeaderComponent-next-button'
              )[0]
        if (nextBtn != null) {
          this.onDatepickerScrollChanged(nextBtn)
        }
      } catch (error) {}
    }
  }

  private preventScrollPropagation(ev: Event): false {
    ev.stopPropagation()
    ev.preventDefault()
    ev.returnValue = false
    return false
  }

  private onDatepickerScrollChanged(value): void {
    if (this.datepickerScrollChanged.observers.length === 0) {
      this.datepickerScrollChanged.pipe(throttleTime(200)).subscribe(value => {
        value.click()
      })
    }
    this.datepickerScrollChanged.next(value)
  }
}
