import { Component, type OnDestroy, type OnInit, Input, ChangeDetectorRef } from '@angular/core'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { SharedService } from '../../../services/shared.service'
import {
  ResizeService, type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'

@Component({
  selector: 'customer-portal-footer-container',
  templateUrl: './footer-container.component.html'
})
export class FooterContainerComponent implements OnInit, OnDestroy {
  @Input() aboutView = false
  public screenSize: SCREEN_SIZE

  hasNewFooter

  sub = {
    onResize: null
  }

  constructor (
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>,
    public resizeService: ResizeService,
    private readonly sharedService: SharedService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.screenSize = this.resizeService.screenSize
    this.hasNewFooter = this.remoteConfigService.get('hasNewFooter')
  }

  ngOnInit (): void {
    this.sub.onResize = this.resizeService.onResize.subscribe(size => {
      this.screenSize = size
      this.cdRef.detectChanges()
    })
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }
}
