<div
  data-cy="edit-vehicle-document-form"
  class="cpt-modal relative dialog-content-p-0 mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    {{ 'edit' | sharedTranslate }}
  </h2>

  <mat-dialog-content class="mat-typography">
    <form [formGroup]="documentEditFormGroup" class="flex flex-col">
      <mat-form-field class="mt-2.5">
        <mat-label for="name"
          ><T
            str="Name"
            key="customerPortal.customer-portal.vehicle.documents-upload.name"
            tags="customer-portal, 3.1"
          ></T
        ></mat-label>
        <input
          data-cy="edit-vehicle-document-input"
          matInput
          type="text"
          formControlName="name"
          [errorStateMatcher]="matcher"
        />
        <mat-error *ngIf="documentEditFormGroup.get('name').errors">
          <T
            str="Maximum filename length excceeded"
            key="customerPortal.customer-portal.vehicle.documents-upload.error.maxlength"
            tags="customer-portal, 3.1"
          ></T>
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-divider class="mb-5"></mat-divider>

  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>

    <button
      data-cy="edit-vehicle-document-submit"
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      (click)="onEditSubmit()"
      [disabled]="saving || !documentEditFormGroup.valid"
    >
      <mat-spinner *ngIf="saving" [diameter]="16"></mat-spinner>
      <span>{{ 'save' | sharedTranslate }}</span>
    </button>
  </mat-dialog-actions>
</div>
