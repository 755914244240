import { Component, type OnDestroy } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import {
  PwaService,
  ResizeService, type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'
import { SharedService } from '../../../services/shared.service'
import { TestService } from '../../../services/test.service'
@Component({
  selector: 'customer-portal-app-test',
  templateUrl: './test.component.html'
})
export class TestComponent implements OnDestroy {
  public screenSize: SCREEN_SIZE
  maxLength = 256

  formGroup = new FormGroup({
    title: new FormControl<string | null>(
      null,
      Validators.compose([Validators.required])
    ),
    text: new FormControl<string | null>(
      null,
      Validators.compose([
        Validators.required,
        Validators.maxLength(this.maxLength)
      ])
    ),
    userID: new FormControl<string | null>(null),
    pushLink: new FormControl<string | null>(null)
  })

  sub = {
    sendingPushNotification: null,
    onPushNotificationResponse: null
  }

  pushNotificationResponse
  pushNotificationPayload

  disabled = false
  isIos: boolean
  isPwa: boolean
  isMobile: boolean
  pwaInstalled: boolean

  constructor (
    public resizeService: ResizeService,
    private readonly testService: TestService,
    public sharedService: SharedService,
    private readonly pwaService: PwaService
  ) {
    this.screenSize = this.resizeService.screenSize

    this.sub.sendingPushNotification =
      this.testService.loading.sendingPushNotification.subscribe(
        sendingPushNotification => {
          this.disabled = sendingPushNotification
        }
      )

    this.sub.onPushNotificationResponse =
      this.testService.onPushNotificationResponse.subscribe(
        pushNotificationResponse => {
          this.pushNotificationResponse = pushNotificationResponse
        }
      )

    this.isIos = this.pwaService.isIos
    this.isPwa = this.pwaService.isPwa
    this.isMobile = this.pwaService.isMobile
    this.pwaInstalled = this.pwaService.isPwaInstalled
  }

  sendNotification (): void {
    let cpIds = []
    if (this.sharedService.stringExists(this.formGroup.controls.userID.value)) {
      cpIds = this.formGroup.controls.userID.value
        .split(',')
        .map(function (item) {
          return item.trim()
        })
    }
    const pushNotification = {
      text: this.formGroup.controls.text.value,
      title: this.formGroup.controls.title.value,
      pushLink: this.formGroup.controls.pushLink.value,
      cpIds,
      lang: this.sharedService.currentLanguage()
    }

    this.pushNotificationResponse = undefined
    this.pushNotificationPayload = undefined
    this.pushNotificationPayload = this.sharedService.previewJSON(
      pushNotification,
      { key: 'font-semibold' }
    )

    this.testService.sendPushNotificationClicked(pushNotification)
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }
}
