import { Component } from '@angular/core'
import { PwaService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
@Component({
  selector: 'customer-portal-app-summary',
  templateUrl: './summary.component.html'
})
export class SummaryComponent {
  isMobile = false
  isPwa = false
  isIos = false
  hasInstallPrompt = false
  isInstallable = false
  constructor(
    private readonly pwaService: PwaService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.hasInstallPrompt = Boolean(window['installPrompt'])
    this.isMobile = this.pwaService.checkMobile()
    this.isPwa = this.pwaService.checkPwa()
    this.isIos = this.pwaService.checkIos()
    this.isInstallable =
      this.hasInstallPrompt && this.isMobile && !this.isPwa && !this.isIos
    this.hasNewFooter = this.remoteConfigService.get('hasNewFooter')
    this.showPwaPromoBanner = this.remoteConfigService.get('pwaEnabled')
    this.showDashEcoNews = this.remoteConfigService.get('showDashEcoNews')
    this.showServices = this.remoteConfigService.get(
      'additionalServices.visibleOnHomepage'
    )
  }
  public showPwaPromoBanner
  showDashEcoNews
  showServices
  public hasNewFooter
  hidePwaPromoBanner(): void {
    this.showPwaPromoBanner = false
  }
}
