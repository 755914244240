import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { PersonalizationService } from '../../../../services/personalization.service'
import { SharedService } from '../../../../services/shared.service'
import {
  EquipmentDTO,
  VehicleDTOExtended,
  VehiclesService
} from '../../../../services/vehicles.service'
@Component({
  selector: 'customer-portal-app-revolution-vehicle-energy',
  templateUrl: './vehicle-energy.component.html'
})
export class VehicleEnergyComponent implements OnInit {
  user: User
  basicEquipment: EquipmentDTO[] = []
  additionalEquipment: EquipmentDTO[] = []
  vehicleDetailed: VehicleDTOExtended
  energyEfficiencies: string[]
  year = new Date().getFullYear()

  // serviceKm: number

  sub = {
    onLanguageChangeSub: null,
    onVehicleDetailsLoadedSub: null,
    onVehicleFrequentDriverLoadedSub: null
  }

  @Input()
  public loading = false

  licensePlateMask
  constructor(
    public dialog: MatDialog,
    private readonly logger: NGXLogger,
    private readonly vehicleService: VehiclesService,
    private readonly personalizationService: PersonalizationService,
    private readonly dataService: DataService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
    })
  }

  @Input()
  public vehicle: VehicleDTOExtended

  ngOnInit(): void {
    this.personalizationService.getPersonalizationEnums().subscribe(value => {
      this.energyEfficiencies = value.energyEfficiencies
    })

    this.sub.onVehicleDetailsLoadedSub =
      this.dataService.onVehicleDetailsLoaded.subscribe(vehicleDetails => {
        this.vehicleDetailed = vehicleDetails
        if (
          vehicleDetails != null &&
          vehicleDetails?.vin === this.vehicle?.vin
        ) {
          // need to update main vehicle propertys
          Object.keys(vehicleDetails).forEach(key => {
            this.vehicle[key] = vehicleDetails[key]
          })
        }
      })
  }

  efficiencyClassArrowColor(efficiencyClass: string): string {
    let cssClass = ''
    const country: string = this.remoteConfigService.get('country.code')
    if (efficiencyClass === 'a+') {
      efficiencyClass = 'a-plus'
    }
    cssClass = country + ' ' + efficiencyClass.toLowerCase()
    return cssClass
  }
}
