import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { DateAdapter } from '@angular/material/core'
import { MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS } from '@angular/material/legacy-core'
import {
  CptDateAdapter,
  CptDateFormats,
  MatomoIntegrationService
} from '@inside-hub-app/customer-portal-shared'
import { SharedService } from '../../../../../services/shared.service'
import { CustomCalendarHeaderComponent } from '../../../../basic/custom-calendar-header/custom-calendar-header.component'
import {
  ReminderChannelDTO,
  VehicleReminderDTO,
  VehicleRemindersService
} from '../../../../../services/vehicle-reminders.service'
import { delay, distinctUntilChanged } from 'rxjs'
import { VehicleDTOExtended } from '../../../../../services/vehicles.service'
import { addDays, format } from 'date-fns'

export const APP_DATE_FORMATS: CptDateFormats = {
  parse: {
    dateInput: 'MM/YYYY'
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    default: 'DD.MM.YYYY.'
  }
}

@Component({
  selector: 'customer-portal-app-vehicle-reminders-form',
  templateUrl: './vehicle-reminders-form.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: CptDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS
    }
  ]
})
export class VehicleRemindersFormComponent implements OnDestroy, OnInit {
  reminder: VehicleReminderDTO
  reminderOriginal: VehicleReminderDTO
  @Input()
  set setReminder (reminder: VehicleReminderDTO) {
    this.reminderOriginal = reminder
    this.reminder = this.sharedService.deepCopy(reminder)
    this.setForm(reminder)
  }

  @Input()
    channelsOptions: ReminderChannelDTO[]

  @Input()
    vehicle: VehicleDTOExtended

  today = new Date()
  minDate = addDays(this.today, 1)
  maxLength = 255
  remindBeforeDaysOptions = [
    {
      remindBeforeDays: 30,
      disabled: false
    },
    {
      remindBeforeDays: 7,
      disabled: false
    },
    {
      remindBeforeDays: 1,
      disabled: false
    },
    {
      remindBeforeDays: 0,
      disabled: false
    }
  ]

  saveDisabled = true

  formGroup = new FormGroup({
    endDate: new FormControl<string | null>(
      null,
      Validators.compose([Validators.required])
    ),
    description: new FormControl<string | null>(
      null,
      Validators.compose([Validators.maxLength(this.maxLength)])
    ),
    remindBeforeDays: new FormControl<number | null>(
      null,
      Validators.compose([Validators.required])
    ),
    channels: new FormControl<ReminderChannelDTO[] | null>(
      null,
      Validators.compose([Validators.required])
    )
  })

  customHeader = CustomCalendarHeaderComponent

  sub = {
    endDate: null,
    remindBeforeDays: null,
    channels: null,
    description: null
  }

  constructor (
    public sharedService: SharedService,
    private readonly vehicleRemindersService: VehicleRemindersService,
    private readonly matomoIntegrationService: MatomoIntegrationService
  ) {}

  ngOnInit (): void {
    this.sub.endDate = this.formGroup.controls.endDate.valueChanges.subscribe(
      value => {
        this.reminder.endDate = value
        this.checkIfEqual()
      }
    )
    this.sub.remindBeforeDays =
      this.formGroup.controls.remindBeforeDays.valueChanges.subscribe(value => {
        this.reminder.remindBeforeDays = value
        this.checkIfEqual()
      })
    this.sub.channels = this.formGroup.controls.channels.valueChanges.subscribe(
      value => {
        this.reminder.channels = value
        this.checkIfEqual()
      }
    )
    this.sub.description = this.formGroup.controls.description.valueChanges
      .pipe(delay(500), distinctUntilChanged())
      .subscribe(value => {
        this.reminder.description = value
        this.checkIfEqual()
      })
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  checkIfEqual (): void {
    const date1 = this.reminderOriginal.endDate ? 
      format(new Date(this.reminderOriginal?.endDate), 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd')
    const date2 = format(new Date(this.reminder.endDate), 'yyyy-MM-dd')
    const endDateEqual = date1 === date2
    const remindBeforeDaysEqual =
      this.reminderOriginal.remindBeforeDays === this.reminder.remindBeforeDays
    const descriptionEqual =
      this.reminderOriginal.description === this.reminder.description

    const originalChannelsId = []
    this.reminderOriginal?.channels?.forEach(channel => {
      originalChannelsId.push(channel.id)
    })
    const channelsId = []
    this.reminder?.channels?.forEach(channel => {
      channelsId.push(channel.id)
    })
    const channelsEqual = this.sharedService.isEqual(
      this.sharedService.sortArray(originalChannelsId),
      this.sharedService.sortArray(channelsId)
    )

    this.saveDisabled =
      endDateEqual && remindBeforeDaysEqual && descriptionEqual && channelsEqual
  }

  setForm (reminder: VehicleReminderDTO): void {
    if (reminder.id != null) {
      this.formGroup.controls.endDate.setValue(reminder.endDate)
      this.formGroup.controls.remindBeforeDays.setValue(
        reminder.remindBeforeDays
      )
      this.formGroup.controls.channels.setValue(reminder.channels)

      if (this.sharedService.stringExists(reminder.description)) {
        this.formGroup.controls.description.setValue(reminder.description)
      }

      // disable remindBeforeDays options based on endDate
      this.checkRemindBeforeDays(false)
    }
  }

  remindBeforeDaysExists (): boolean {
    return this.reminder.remindBeforeDays != null
  }

  checkRemindBeforeDays (updateRemindBeforeDays: boolean): void {
    this.remindBeforeDaysOptions.forEach(when => {
      if (this.reminder.endDate != null) {
        const remindBeforeDaysDate = addDays(new Date(this.reminder.endDate), -when.remindBeforeDays).getTime()
        const today = new Date(this.today).getTime()
        if (today - remindBeforeDaysDate > 0) {
          when.disabled = true
          if (
            updateRemindBeforeDays &&
            this.reminder.remindBeforeDays === when.remindBeforeDays
          ) {
            this.setRemindBeforeDays(undefined)
          }
        } else {
          when.disabled = false
        }
      } else {
        when.disabled = false
      }
    })
  }

  setRemindBeforeDays (value): void {
    let newValue = value
    if (this.reminder.remindBeforeDays === value) {
      newValue = undefined
    }
    this.formGroup.get('remindBeforeDays').setValue(newValue)
    this.reminder.remindBeforeDays = newValue
  }

  setChannels (channel: ReminderChannelDTO): void {
    this.reminder.channels = this.reminder.channels ?? []
    // if its already in array remove
    if (this.channelIsChecked(channel)) {
      this.reminder.channels = this.reminder.channels.filter(c => {
        return c.id !== channel.id
      })
    } else {
      this.reminder.channels.push(channel)
    }
    this.formGroup.get('channels').setValue(this.reminder.channels)
  }

  channelIsChecked (channel: ReminderChannelDTO): boolean {
    if (this.reminder.channels?.find(c => c.id === channel.id) != null) {
      return true
    }
    return false
  }

  updateReminder (): void {
    if (this.reminderOriginal.id != null) {
      this.matomoEvent('Edit vehicle reminder')
      this.vehicleRemindersService.putVehicleReminderClicked(
        this.vehicle?.vin,
        this.reminder
      )
    } else {
      this.matomoEvent('Add vehicle reminder')
      this.vehicleRemindersService.postVehicleReminderClicked(
        this.vehicle?.vin,
        this.reminder
      )
    }
  }

  deleteReminder (): void {
    this.matomoEvent('Delete vehicle reminder')
    this.vehicleRemindersService.deleteVehicleReminderClicked(
      this.vehicle?.vin,
      this.reminder
    )
  }

  matomoEvent (action: string): void {
    this.matomoIntegrationService.trackEvent(
      'Vehicle action',
      action,
      this.matomoIntegrationService.formatVehicleData(this.vehicle)
    )
  }
}
