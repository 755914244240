<customer-portal-app-navbar></customer-portal-app-navbar>
<customer-portal-app-navbar-second></customer-portal-app-navbar-second>
<customer-portal-pwa-promo-banner
  *ngIf="showPwaPromoBanner && isInstallable"
  (bannerDismiss)="hidePwaPromoBanner()"
  class="w-full flex"
></customer-portal-pwa-promo-banner>
<customer-portal-app-hero></customer-portal-app-hero>
<customer-portal-app-notifications-panel></customer-portal-app-notifications-panel>
<div class="px-4">
  <customer-portal-app-user-notice-card></customer-portal-app-user-notice-card>
</div>
<customer-portal-revolution-vehicle-list></customer-portal-revolution-vehicle-list>
<customer-portal-app-revolution-articles
  *ngIf="showDashEcoNews"
></customer-portal-app-revolution-articles>

<customer-portal-app-revolution-services
  *ngIf="showServices"
  [mode]="'summary'"
>
</customer-portal-app-revolution-services>
<div class="px-4 my-14">
  <customer-portal-app-support-card></customer-portal-app-support-card>
</div>
<customer-portal-footer-container></customer-portal-footer-container>
