import { Inject, Injectable, Optional } from '@angular/core'
import {
  MatLegacyDateFormats as MatDateFormats,
  MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS,
  MAT_LEGACY_DATE_LOCALE as MAT_DATE_LOCALE,
  LegacyNativeDateAdapter as NativeDateAdapter
} from '@angular/material/legacy-core'
import * as moment from 'moment'
import { SharedService } from '../services/shared.service'

export interface CptDateFormats {
  parse: {
    dateInput: string
  }
  display: {
    dateInput: string
    monthYearLabel: string
    dateA11yLabel: string
    monthYearA11yLabel: string
    default: string
  }
}

@Injectable()
export class CptDateAdapter extends NativeDateAdapter {
  dateFormats: CptDateFormats

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
    @Inject(MAT_DATE_FORMATS) dateFormats: MatDateFormats,
    private readonly sharedService: SharedService
  ) {
    super(dateLocale)
    this.dateFormats = dateFormats as CptDateFormats
  }

  override getFirstDayOfWeek(): number {
    return 1
  }

  override parse(value: any): Date | null {
    if (typeof value === 'string' && value.includes('.')) {
      const str = value.split('.')
      const year = Number(str[2])
      const month = Number(str[1]) - 1
      return new Date(year, month)
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value)
    console.log(new Date(timestamp), ' Parse')

    return isNaN(timestamp) ? null : new Date(timestamp)
  }

  override format(date: Date): string {
    moment.locale(this.sharedService.currentLanguage())
    return moment(date).format(this.dateFormats.display.default)
  }

  override createDate(year: number, month: number, date: number): Date {
    // Moment.js will create an invalid date if any of the components are out of bounds, but we
    // explicitly check each case so we can throw more descriptive errors.
    if (month < 0 || month > 11) {
      throw Error(
        `Invalid month index "${month}". Month index has to be between 0 and 11.`
      )
    }

    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`)
    }

    const result = moment.utc({ year, month, date }).locale(this.locale)

    // If the result isn't valid, the date must have been out of bounds for this month.
    if (!result.isValid()) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`)
    }

    const d = result.toDate()

    return d
  }
}
