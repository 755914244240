import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ReplaceDateWithStringType } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import * as moment from 'moment'
import { Observable, of } from 'rxjs'
import { ServicePackageDTO } from './service-package.service'
import { ErrorResponseDTO, SharedService } from './shared.service'
export type WarrantyDetailsDTO =
  ReplaceDateWithStringType<cpt.emilfreydigital.customer_portal_backend.rest.warranty.dto.WarrantyDetailsDTO>
export type WarrantyContactRequestDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.warranty.dto.WarrantyContactRequestDTO

export type WarrantyContactReasonEnum =
  cpt.emilfreydigital.customer_portal_backend.rest.warranty.dto.WarrantyContactRequestDTO.WarrantyContactReasonEnum
export interface Warranty {
  standard: WarrantyDetailsDTO
  extended: WarrantyDetailsDTO[]
  tire: WarrantyDetailsDTO[]
}

export interface WarrantySP {
  warranty: Warranty
  servicePackages: ServicePackageDTO[]
}

export interface WarrantyCalculations {
  totalDaysLength: number
  tillExpires: number
  percentage: number
  percentageLeft: number
  months: number
  years: number
}

export type ExpirationType = 'servicePackage' | 'warranty' | 'extendedWarranty'

// const WARRANTY: Warranty = {
//   standard: {
//     id: 1,
//     startDate: '2019-01-01',
//     endDate: '2022-11-01',
//     maxMileage: 100000,
//     includes: [
//       '3 Jahre Lackgarantie ohne Kilometerbeschränkung',
//       '12 Jahre Durchrostungsgarantie (Nutzfahrzeuge 6 Jahre) ohne Kilometerbeschränkung '
//     ],
//     expired: true,
//     expireReason: 'DATE',
//     description: 'Standard Garantie',
//     purchased: true
//   },
//   extended: [
//     {
//       id: 2,
//       startDate: '2019-01-01',
//       endDate: '2022-11-01',
//       maxMileage: 200000,
//       includes: [
//         '3 Jahre Lackgarantie ohne Kilometerbeschränkung',
//         '12 Jahre Durchrostungsgarantie (Nutzfahrzeuge 6 Jahre) ohne Kilometerbeschränkung '
//       ],
//       expired: true,
//       expireReason: 'DATE',
//       description: 'Standard Garantie',
//       purchased: true
//     }
//   ],
//   tire: [
//     {
//       id: 3,
//       startDate: '2019-01-01',
//       endDate: '2022-11-01',
//       maxMileage: 100000,
//       includes: [
//         '3 Jahre Lackgarantie ohne Kilometerbeschränkung',
//         '12 Jahre Durchrostungsgarantie (Nutzfahrzeuge 6 Jahre) ohne Kilometerbeschränkung '
//       ],
//       expired: true,
//       expireReason: 'DATE',
//       description: 'Standard Garantie',
//       purchased: true
//     }
//   ]
// }

const WARRANTY2: Warranty = {
  standard: {
    purchased: true,
    startDate: '2021-07-16',
    endDate: '2024-07-15',
    maxMileage: 100000,
    expired: true,
    expireReason: 'MILEAGE'
  },
  extended: [],
  tire: []
}
export interface Expiration {
  days: number
  percentage: number
}

export interface IWarrantyService {
  calculateTillExpires: (startDate: string, endDate: string) => Expiration
  getWarranty: (vin: string, lang: string) => Observable<Warranty>
}

function calculateTillExpires(startDate: string, endDate: string): Expiration {
  const wStartDate = moment(startDate)
  const wTermDate = moment(endDate)
  const now = moment()
  const daysDiff = Math.round(wTermDate.diff(now, 'days', true))
  const duration = Math.round(wTermDate.diff(wStartDate, 'days', true))
  let percentage = Math.round((daysDiff / duration) * 100)
  percentage = percentage < 5 ? 5 : percentage
  return {
    days: daysDiff,
    percentage
  }
}

@Injectable({
  providedIn: 'root'
})
export class TestWarrantyService implements IWarrantyService {
  calculateTillExpires: (startDate: string, endDate: string) => Expiration =
    calculateTillExpires

  getWarranty(): Observable<Warranty> {
    return of(WARRANTY2)
  }
}

@Injectable({
  providedIn: 'root'
})
export class WarrantyService implements IWarrantyService {
  private readonly apiUrl
  calculateTillExpires: (startDate: string, endDate: string) => Expiration =
    calculateTillExpires

  constructor(
    private readonly http: HttpClient,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>,
    public sharedService: SharedService
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  getWarranty(vin: string, lang: string): Observable<Warranty> {
    let params = new HttpParams()
    params = params.append('lang', lang)
    return this.http.get<Warranty>(this.apiUrl + '/warranty/' + vin, {
      params
    })
  }

  getWarrantySP(vin: string, lang: string): Observable<WarrantySP> {
    let params = new HttpParams()
    params = params.append('lang', lang)
    params = params.append('type', 'ALL')
    // params = params.append('vin', vin)
    return this.http.get<WarrantySP>(this.apiUrl + '/warranty-sp/' + vin, {
      params
    })
  }

  requestWarrantyContact(
    vin: string,
    data: WarrantyContactRequestDTO
  ): Observable<ErrorResponseDTO> {
    let params = new HttpParams()
    const lang = this.sharedService.currentLanguage()
    params = params.append('lang', lang)
    return this.http.post<ErrorResponseDTO>(
      this.apiUrl + '/warranty/' + vin,
      data,
      {
        params
      }
    )
  }

  warrantyCalculations(
    warrantyData: Warranty,
    type: string
  ): WarrantyCalculations {
    let warranty
    switch (type) {
      case 'standard':
        warranty = warrantyData.standard
        break
      case 'extended':
        warranty = warrantyData.extended[warrantyData.extended.length - 1]
        break
      case 'tire':
        warranty = warrantyData.tire[warrantyData.tire.length - 1]
        break
    }
    if (warranty != null) {
      const oneDay = 24 * 60 * 60 * 1000
      let firstDay: Date
      let lastDay: Date
      const currentDay = new Date()
      let years = 0
      let months = 0

      if (warranty.startDate != null && warranty.endDate != null) {
        firstDay = new Date(warranty.startDate)
        lastDay = new Date(warranty.endDate)
      } else {
        return null
      }

      const tillExpires = this.calculateTillExpires(
        warranty.startDate,
        warranty.endDate
      ).days
      const percentageTillExpires = this.calculateTillExpires(
        warranty.startDate,
        warranty.endDate
      ).percentage

      years = lastDay.getFullYear() - currentDay.getFullYear()
      months = this.sharedService.monthDiff(currentDay, lastDay)

      if (years * 12 > months) {
        years = years - 1
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const diffDays = Math.round(Math.abs((firstDay - lastDay) / oneDay))
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment

      const numOfDaysPast = Math.round(
        // @ts-expect-error
        Math.abs((firstDay - currentDay) / oneDay)
      )

      if (diffDays - numOfDaysPast < 0) {
        return null
      } else {
        return {
          totalDaysLength: diffDays,
          tillExpires,
          percentage: percentageTillExpires,
          percentageLeft: 100 - percentageTillExpires,
          months,
          years
        }
      }
    }
  }

  styleProgressBar(w, type: ExpirationType): string {
    const tillExpires =
      this.calculateTillExpires(w?.startDate, w?.endDate).days | w?.tillExpires
    let style = 'ok-green'
    const warn: number = this.remoteConfigService.get(
      `${type}.expirationDays.warn`
    )
    const critical: number = this.remoteConfigService.get(
      `${type}.expirationDays.critical`
    )
    if (tillExpires >= critical && tillExpires <= warn) {
      style = 'warn-orange'
    }
    if (tillExpires < critical && tillExpires > 0) {
      style = 'warn-red'
    }
    return style
  }
}
