import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
@Component({
  selector: 'customer-portal-app-revolution-marketplace-view',
  templateUrl: './marketplace-view.component.html'
})
export class RevolutionMarketplaceViewComponent {
  country
  public hasNewFooter
  constructor(
    private readonly router: Router,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.country = this.remoteConfigService.get('country.code')
    this.hasNewFooter = this.remoteConfigService.get('hasNewFooter')
  }
}
