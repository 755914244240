import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ReplaceDateWithStringType } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { NGXLogger } from 'ngx-logger'
import { BehaviorSubject, Observable } from 'rxjs'
import { GenericObject, SharedService } from './shared.service'
import { format } from 'date-fns'
export type VehicleReminderDTO =
  ReplaceDateWithStringType<cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.VehicleReminderDTO>
export type ReminderChannelDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.common.dto.MessageChannelDTO

@Injectable({
  providedIn: 'root'
})
export class VehicleRemindersService {
  private readonly apiUrl

  private readonly _vehicleReminders = new BehaviorSubject<
    VehicleReminderDTO[]
  >(null)
  onVehicleRemindersLoaded = this._vehicleReminders.asObservable()

  private readonly _channels = new BehaviorSubject<ReminderChannelDTO[]>([])
  onChannelsLoaded = this._channels.asObservable()

  public loading = {
    vehicleReminders: new BehaviorSubject<boolean>(true),
    updatingVehicleReminders: new BehaviorSubject<boolean>(false),
    channels: new BehaviorSubject<boolean>(true)
  }

  public vehicleReminderTypes: string[]

  constructor(
    private readonly http: HttpClient,
    private readonly logger: NGXLogger,
    private readonly sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  public getChannelsData(): void {
    this.loading.channels.next(true)
    this.getChannels().subscribe({
      next: channels => {
        this._channels.next(channels)
        this.loading.channels.next(false)
      },
      error: error => {
        this.logger.log(error)
        this._channels.next([])
        this.loading.channels.next(false)
      }
    })
  }

  private getChannels(): Observable<ReminderChannelDTO[]> {
    // return of(REMINDER_CHANNELS) // Mock data
    return this.http.get<ReminderChannelDTO[]>(
      this.apiUrl + '/vehicle/reminder/channels'
    )
  }

  public getVehicleRemindersData(
    vin: string,
    updatingVehicleReminders?: boolean
  ): void {
    if (updatingVehicleReminders === true) {
      this.loading.updatingVehicleReminders.next(true)
    } else {
      this.loading.vehicleReminders.next(true)
    }
    this.getVehicleReminders(vin).subscribe({
      next: vehicleReminders => {
        this._vehicleReminders.next(vehicleReminders)
        if (updatingVehicleReminders === true) {
          this.loading.updatingVehicleReminders.next(false)
        } else {
          this.loading.vehicleReminders.next(false)
        }
      },
      error: error => {
        this.logger.log(error)
        this._vehicleReminders.next(null)
        if (updatingVehicleReminders === true) {
          this.loading.updatingVehicleReminders.next(false)
        } else {
          this.loading.vehicleReminders.next(false)
        }
      }
    })
  }

  public getVehicleReminders(vin: string): Observable<VehicleReminderDTO[]> {
    // return of(VEHICLE_REMINDERS) // Mock data
    const lang = this.sharedService.currentLanguage()
    let params = new HttpParams()
    params = params.append('lang', lang)
    params = params.append('vin', vin)
    return this.http.get<VehicleReminderDTO[]>(
      this.apiUrl + '/vehicle/reminder',
      {
        params
      }
    )
  }

  public vehicleRemindersLoaded(vehicleReminders: VehicleReminderDTO[]): void {
    this._vehicleReminders.next(vehicleReminders)
  }

  public postVehicleReminderClicked(
    vin: string,
    vehicleReminder: VehicleReminderDTO
  ): void {
    this.loading.updatingVehicleReminders.next(true)
    const vehicleReminderCopy = this.sharedService.deepCopy(vehicleReminder)
    vehicleReminderCopy.endDate = format(new Date(vehicleReminderCopy.endDate), 'yyyy-MM-dd')
    this.postVehicleReminder(vin, vehicleReminderCopy).subscribe({
      next: data => {
        this.handleUpdateSuccess(vin, 'create')
      },
      error: error => {
        this.handleUpdateError(error, vin)
      }
    })
  }

  private postVehicleReminder(
    vin: string,
    newVehicleReminder: VehicleReminderDTO
  ): Observable<VehicleReminderDTO> {
    const lang = this.sharedService.currentLanguage()
    let params = new HttpParams()
    params = params.append('lang', lang)
    params = params.append('vin', vin)
    return this.http.post<VehicleReminderDTO>(
      this.apiUrl + '/vehicle/reminder',
      newVehicleReminder,
      {
        params
      }
    )
  }

  public putVehicleReminderClicked(
    vin: string,
    vehicleReminder: VehicleReminderDTO
  ): void {
    this.loading.updatingVehicleReminders.next(true)
    const vehicleReminderCopy = this.sharedService.deepCopy(vehicleReminder)
    vehicleReminderCopy.endDate = format(new Date(vehicleReminderCopy.endDate), 'yyyy-MM-dd')
    this.putVehicleReminder(vehicleReminderCopy).subscribe({
      next: data => {
        this.handleUpdateSuccess(vin, 'update')
      },
      error: error => {
        this.handleUpdateError(error, vin)
      }
    })
  }

  private putVehicleReminder(
    vehicleReminder: VehicleReminderDTO
  ): Observable<VehicleReminderDTO> {
    return this.http.put<VehicleReminderDTO>(
      this.apiUrl + '/vehicle/reminder/',
      vehicleReminder
    )
  }

  public deleteVehicleReminderClicked(
    vin: string,
    vehicleReminder: VehicleReminderDTO
  ): void {
    this.loading.updatingVehicleReminders.next(true)
    this.deleteVehicleReminder(vehicleReminder.id).subscribe({
      next: () => {
        this.handleUpdateSuccess(vin, 'delete')
      },
      error: error => {
        this.handleUpdateError(error, vin)
      }
    })
  }

  private deleteVehicleReminder(id: number): Observable<GenericObject> {
    const apiUrl = this.apiUrl + '/vehicle/reminder/' + String(id)
    return this.http.delete<GenericObject>(apiUrl)
  }

  private handleUpdateError(error, vin: string): void {
    this.logger.log(error)
    this.loading.updatingVehicleReminders.next(false)
    let key = 'shared.general-error-message'
    if (error?.error?.code === 2001 || error?.code === 2001) {
      key = 'customerPortal.customer-portal.reminders.already-exists'
      this.getVehicleRemindersData(vin, true)
    }
    this.sharedService.openConfirmationPopup('shared.error', key)
  }

  private handleUpdateSuccess(
    vin: string,
    action: 'create' | 'update' | 'delete'
  ): void {
    this.getVehicleRemindersData(vin, true)
    this.sharedService.openConfirmationPopup(
      'shared.success',
      'customerPortal.customer-portal.reminders.' + action
    )
  }
}
