<div class="vehicle-boxes-rounded">
  <ng-container *ngIf="vehicle.fuelType === 'gas'">
    <p class="font-efSans text-xl font-bold mb-2">
      <T
        str="Your nearest gas filling stations"
        key="customerPortal.customer-portal.nearest-fuel-station"
        tags="customer-portal, 3.1"
      ></T>
    </p>

    <p class="font-efSans text-sm">
      <T
        str="LPG gas station"
        key="customerPortal.customer-portal.gas-station.lpg-cng"
        tags="customer-portal, 3.1"
      ></T>
    </p>

    <a
      [href]="nearestFuelStationGas"
      target="_blank"
      (click)="
        sendContentModulesMiniCTAData(
          'customerPortal.customer-portal.gas-station.lpg-cng'
        )
      "
      class="revolution medium-button mt-6"
      mat-button
      mat-stroked-button
      color="secondary"
    >
      <mat-icon svgIcon="fuel" class="min-w-[20px] min-h-[20px]"></mat-icon>
      <mat-icon svgIcon="arrow_right"></mat-icon>
    </a>
  </ng-container>

  <ng-container
    *ngIf="vehicle.fuelType === 'electro' || vehicle.fuelType === 'hybrid'"
  >
    <p class="font-efSans text-xl font-bold mb-2">
      <T
        str="Your nearest electric vehicle charging stations"
        key="customerPortal.customer-portal.nearest-charging-station"
        tags="customer-portal, 3.1"
      ></T>
    </p>
    <p class="font-efSans text-sm">
      <T
        str="Charging stations for electric vehicles"
        key="customerPortal.customer-portal.charging-station"
        tags="customer-portal, 3.1"
      ></T>
    </p>
    <a
      [href]="nearestFuelStationElectro"
      target="_blank"
      (click)="
        sendContentModulesMiniCTAData(
          'customerPortal.customer-portal.charging-station'
        )
      "
      class="revolution medium-button mt-6"
      mat-button
      mat-stroked-button
      color="secondary"
    >
      <mat-icon
        svgIcon="electro_range"
        class="min-w-[20px] min-h-[20px]"
      ></mat-icon>
      <mat-icon svgIcon="arrow_right"></mat-icon>
    </a>
  </ng-container>
</div>
