import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core'

export interface MatIconModuleConfig {
  iconsPath: string
  useKcResourcePath: boolean
}
export const MatIconModuleConfigService =
  new InjectionToken<MatIconModuleConfig>('MatIconModuleConfig')
@NgModule()
export class IconModule {
  static forRoot(
    iconsPathOptions: MatIconModuleConfig
  ): ModuleWithProviders<IconModule> {
    return {
      ngModule: IconModule,
      providers: [
        {
          provide: MatIconModuleConfigService,
          useValue: iconsPathOptions
        }
      ]
    }
  }
}
