import { Component, Inject, OnInit, OnDestroy } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGoogleTagmanagerService,
  MatomoIntegrationService,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { TranslateService } from '@ngx-translate/core'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from '../../../services/shared.service'
import { DataService } from '../../../services/data.service'
import {
  DealerDTO,
  DealersService,
  DealerUserDTO
} from '../../../services/dealers.service'
import { VehicleDTOExtended } from '../../../services/vehicles.service'
import {
  ConfirmationPopupComponent,
  ConfirmationPopupDialogData
} from '../../basic/confirmation-popup/confirmation-popup.component'
import { TelephoneChangePopupComponent } from '../../user-details/telephone-change-popup/telephone-change-popup.component'

interface CallRequestDialogData {
  vehicle: VehicleDTOExtended
  type: 'employee' | 'dealer'
  dealer?: DealerDTO
  employee?: DealerUserDTO
}

@Component({
  selector: 'customer-portal-app-call-request-popup',
  templateUrl: './call-request-popup.component.html'
})
export class CallRequestPopupComponent implements OnInit, OnDestroy {
  saving = false

  sub = {
    onTransifexLanguageChange: null
  }

  callRequestContactForm = new FormGroup({
    phoneNumber: new FormControl<string | null>(null, [Validators.required]),
    timeOfDay: new FormControl<string | null>(null, Validators.required),
    message: new FormControl<string | null>(null, [
      Validators.required,
      Validators.maxLength(1000)
    ])
  })

  timesOfDay: string[] = ['morning', 'noon', 'afternoon']
  user: User
  phoneNumber: string

  constructor (
    private readonly dataService: DataService,
    private readonly dealersService: DealersService,
    private readonly logger: NGXLogger,
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<CallRequestPopupComponent>,
    private readonly translate: TranslateService,
    private readonly transifexTranslationsService: TranslationService,
    private readonly transifexService: TransifexService,

    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly matomoIntegrationService: MatomoIntegrationService,
    @Inject(MAT_DIALOG_DATA) public data: CallRequestDialogData,
    public sharedService: SharedService
  ) {}

  onSubmitCallRequestForm (): void {
    this.saving = true
    const dayTime: string = this.callRequestContactForm.get('timeOfDay').value
    const message = this.callRequestContactForm.get('message').value
    let request
    const lang =
      this.user.language != null
        ? this.user.language.find(s => s.primary).language
        : 'de'
    if (this.data.type === 'dealer') {
      request = this.dealersService.createCallRequest(
        message,
        dayTime,
        lang,
        this.data.vehicle.vin,
        this.data.dealer.id
      )
      this.cptGtmService.sendContactUsData(2, this.getTitle(), this.data.dealer)
    }
    if (this.data.type === 'employee') {
      request = this.dealersService.createCallRequest(
        message,
        dayTime,
        lang,
        this.data.vehicle.vin,
        this.data.dealer.id,
        this.data.employee.id
      )
      this.cptGtmService.sendContactUsData(2, this.getTitle(), this.data.dealer)
    }

    this.sub.onTransifexLanguageChange =
      this.transifexService.onTransifexLanguageChange.subscribe(() => {
        const dayTimeTranslated = this.transifexTranslationsService.translate(
          this.sharedService.capitalize(dayTime),
          {
            _key:
              'customerPortal.customer-portal.support.callrequest.timeofday.' +
              dayTime,
            _tags: 'customer-portal, 3.1, notranslate'
          }
        )

        const text = this.transifexTranslationsService.translate(
          'Your contact person will call you as soon as possible {dayTime} on {phoneNumber}.<br><br>Best regards.<br>Your Emil Frey Germany Team',
          {
            _key: 'customerPortal.customer-portal.support.callrequest.success.text',
            _tags: 'customer-portal, 3.1',
            dayTime: dayTimeTranslated,
            phoneNumber: this.callRequestContactForm.get('phoneNumber').value
          }
        )
        request.subscribe(
          response => {
            this.saving = false
            const data: ConfirmationPopupDialogData = {
              title: 'shared.thank-you',
              subtitle:
                'customerPortal.customer-portal.support.callrequest.success.subtitle',
              text,
              useInnerHtml: true,
              skipTranslate: true
            }
            this.dialog.open(ConfirmationPopupComponent, {
              data,
              panelClass: 'mat-dialog-cpt'
            })
            this.dialogRef.close(response)
          },
          error => {
            this.saving = false
            this.logger.debug(error)
            const data: ConfirmationPopupDialogData = {
              title:
                'customerPortal.customer-portal.support.callrequest.failure.title',
              text: 'customerPortal.customer-portal.support.callrequest.failure.text'
            }
            this.dialog.open(ConfirmationPopupComponent, {
              data,
              panelClass: 'mat-dialog-cpt'
            })
            this.dialogRef.close(error)
          }
        )
      })
  }

  getPhoneNumber (u: User): string {
    if (u?.telephone != null) {
      const mobile = u.telephone.find(({ type }) => type === 'mobile')
      return mobile?.number != null ? mobile.number : ''
    }
    return ''
  }

  changePhoneNumber (): void {
    const dialogRef = this.dialog.open(TelephoneChangePopupComponent, {
      data: {
        user: this.user,
        usage: 'private'
      },
      panelClass: ['mat-dialog-cpt', 'change-mobile-phone'],
      restoreFocus: false
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== false) {
        this.logger.debug(result)
      }
    })
  }

  getTitle (): string {
    if (this.data.type === 'dealer') {
      return 'Händler Rückruf anfragen'
    } else {
      return 'Ansprechpartner Rückruf anfragen'
    }
  }

  ngOnInit (): void {
    this.cptGtmService.sendContactUsData(1, this.getTitle(), this.data.dealer)
    this.dataService.onPhoneNumberVerified.subscribe(phoneNumber => {
      this.phoneNumber = phoneNumber
      this.callRequestContactForm.get('phoneNumber').setValue(this.phoneNumber)
    })
    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
      this.phoneNumber = this.getPhoneNumber(user)
      this.callRequestContactForm.get('phoneNumber').setValue(this.phoneNumber)
    })

    let matomoAction = 'Request a contact person callback'
    if (this.data.type === 'dealer') {
      matomoAction = 'Request a dealer callback'
    }
    this.matomoIntegrationService.trackEvent(
      'CTA',
      matomoAction,
      this.matomoIntegrationService.formatVehicleData(this.data.vehicle)
    )
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }
}
