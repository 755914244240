import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { B2cAuthGuard } from '@inside-hub-app/customer-portal-b2c-client'
import { LoginViewComponent } from './views/login/login.component'
import { RevolutionDetailsViewComponent } from './views/revolution/details-view/details-view.component'
import { FAQViewComponent } from './views/revolution/faq-view/faq-view.component'
import { RevolutionMarketplaceViewComponent } from './views/revolution/marketplace-view/marketplace-view.component'
import { RevolutionMyDocumentsViewComponent } from './views/revolution/my-documents-view/my-documents-view.component'
import { RevolutionMyMailboxViewComponent } from './views/revolution/my-mailbox-view/my-mailbox-view.component'
import { RevolutionAppointmentsViewComponent } from './views/revolution/revolution-appointments-view/revolution-appointments-view.component'
import { RevolutionServicesViewComponent } from './views/revolution/services-view/services-view.component'
import { SummaryComponent } from './views/revolution/summary/summary.component'
import { VehiclesNotViewComponent } from './views/revolution/vehicles-not-view/vehicles-not-view.component'
import { VehiclesViewComponent } from './views/revolution/vehicles-view/vehicles-view.component'
import { TestViewComponent } from './views/revolution/test-view/test-view.component'
import { AboutViewComponent } from './views/revolution/about-view/about-view.component'

let routes: Routes = []

routes = [
  {
    path: '',
    component: SummaryComponent,
    canActivate: [B2cAuthGuard]
  },
  {
    path: 'login',
    component: LoginViewComponent
  },
  {
    path: 'about',
    component: AboutViewComponent,
    canActivate: [B2cAuthGuard]
  },
  {
    path: 'appointments',
    component: RevolutionAppointmentsViewComponent,
    canActivate: [B2cAuthGuard]
  },
  {
    path: 'services',
    component: RevolutionServicesViewComponent,
    canActivate: [B2cAuthGuard]
  },
  {
    path: 'vehicles-not',
    component: VehiclesNotViewComponent,
    canActivate: [B2cAuthGuard]
  },
  {
    path: 'vehicles',
    component: VehiclesViewComponent,
    canActivate: [B2cAuthGuard]
  },
  {
    path: 'vehicles/:vin',
    component: VehiclesViewComponent,
    canActivate: [B2cAuthGuard]
  },
  {
    path: 'vehicles/:vin/:vehicleAction',
    component: VehiclesViewComponent,
    canActivate: [B2cAuthGuard],
    pathMatch: 'full'
  },
  {
    path: 'vehicles/:vin/:vehicleAction/:documentType',
    component: VehiclesViewComponent,
    canActivate: [B2cAuthGuard],
    pathMatch: 'full'
  },
  {
    path: 'user',
    component: RevolutionDetailsViewComponent,
    canActivate: [B2cAuthGuard]
  },
  {
    path: 'user/:detailsSubmenu',
    component: RevolutionDetailsViewComponent,
    canActivate: [B2cAuthGuard],
    pathMatch: 'full'
  },
  {
    path: 'my-documents',
    component: RevolutionMyDocumentsViewComponent,
    canActivate: [B2cAuthGuard]
  },
  {
    path: 'marketplace',
    component: RevolutionMarketplaceViewComponent,
    canActivate: [B2cAuthGuard]
  },
  {
    path: 'marketplace/:marketplaceComponent',
    component: RevolutionMarketplaceViewComponent,
    canActivate: [B2cAuthGuard],
    pathMatch: 'full'
  },
  {
    path: 'my-mailbox',
    component: RevolutionMyMailboxViewComponent,
    canActivate: [B2cAuthGuard]
  },
  {
    path: 'faq',
    component: FAQViewComponent,
    canActivate: [B2cAuthGuard]
  },
  {
    path: 'test',
    component: TestViewComponent,
    canActivate: [B2cAuthGuard]
  },
  {
    // Routing will be decided in app component based on logged in status
    path: '**',
    redirectTo: ''
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
