import { Component, OnInit, Inject } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { DateAdapter } from '@angular/material/core'
import { MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS } from '@angular/material/legacy-core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { NGXLogger } from 'ngx-logger'
import {
  CptDateFormats,
  CptDateAdapter,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { SharedService } from '../../../services/shared.service'
import { DatepickerScrollService } from '../../../services/datepickerScroll.service'
import { InstantErrorStateMatcherService } from '../../../services/instant-error-state-matcher.service'

import { CustomCalendarHeaderComponent } from '../../basic/custom-calendar-header/custom-calendar-header.component'
import {
  VehicleDTOExtended,
  VehiclesService
} from '../../../services/vehicles.service'
import { add } from 'date-fns'

export const APP_DATE_FORMATS: CptDateFormats = {
  parse: {
    dateInput: 'MM/YYYY'
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    default: 'DD.MM.YYYY.'
  }
}

@Component({
  selector: 'customer-portal-app-edit-leasing-expiry',
  templateUrl: './edit-leasing-expiry.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: CptDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS
    }
  ]
})
export class EditLeasingExpiryComponent implements OnInit {
  form = new FormGroup({
    date: new FormControl(null)
  })

  loading = false
  private readonly vehicleUpdated: VehicleDTOExtended
  matcher = new InstantErrorStateMatcherService()
  customHeader = CustomCalendarHeaderComponent
  now = new Date()
  fiveYearsAfter = add(new Date(), {years: 5})
  constructor(
    private readonly dialogRef: MatDialogRef<EditLeasingExpiryComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      vehicle: VehicleDTOExtended
      property: 'leasingExpiryDate' | 'nextServiceDate'
    },
    private readonly vehiclesService: VehiclesService,
    private readonly datepickerScrollService: DatepickerScrollService,
    private readonly logger: NGXLogger,
    public sharedService: SharedService,
    public transifexService: TransifexService
  ) {
    this.vehicleUpdated = data.vehicle
  }

  ngOnInit(): void {
    this.form.get('date').setValue(this.data.vehicle[this.data.property])
  }

  chosenYearHandler(normalizedYear: Date): void {
    const ctrlValue: Date =
      this.form.get('date').value != null
        ? new Date(this.form.get('date').value)
        : new Date()
    ctrlValue.setFullYear(normalizedYear.getFullYear())

    this.form.get('date').setValue(ctrlValue)
  }

  chosenMonthHandler(normalizedMonth: Date, picker): void {
    const ctrlValue: Date =
      this.form.get('date').value != null
        ? new Date(this.form.get('date').value)
        : new Date()
    ctrlValue.setMonth(normalizedMonth.getMonth())

    this.form.get('date').setValue(ctrlValue)
    picker.close()
  }

  update(): void {
    this.loading = true
    const vehicleUpdated = this.sharedService.deepCopy(this.vehicleUpdated)
    delete vehicleUpdated.pictureType
    delete vehicleUpdated.leasingInfo
    delete vehicleUpdated.dealers
    delete vehicleUpdated.brandPicture
    delete vehicleUpdated.relations
    vehicleUpdated[this.data.property] = this.form.get('date').value
    this.vehiclesService.addUserVehicle(vehicleUpdated).subscribe(
      () => {
        this.loading = false
        this.dialogRef.close({
          success: true,
          value: vehicleUpdated[this.data.property]
        })
      },
      error => {
        this.loading = false
        this.logger.debug(error)
        this.dialogRef.close({ success: false })
      }
    )
  }

  addScrolling(picker): void {
    this.datepickerScrollService.addScrolling(picker)
  }
}
