<div
  class="RevolutionDealershipSummaryMoleculeComponent w-full h-fit"
  [ngClass]="view"
>
  <mat-card
    class="gap-6 md:gap-4 w-full rounded-lg h-fit flex flex-col md:flex-row !shadow-none"
    [ngClass]="{
      'p-6 !rounded-lg border border-mat-ef-revolution-supergrey-500':
        view !== 'appointment',
      'p-6': view === 'appointment'
    }"
  >
    <!-- prettier-ignore -->
    <div
      class="h-fit w-full md:w-fit flex cursor-pointer justify-center"
      [matTooltip]="'Click here' | translate:{ _key: 'customerPortal.customer-portal.click-here', _tags: 'customer-portal, 3.1'}"
      (click)="openDealerPopup(dealer)"
    >
      <customer-portal-agm-map
        #map
        [zoom]="zoom"
        [longitude]="lng"
        [latitude]="lat"
        [markDraggable]="false"
        [height]="view !== 'appointment' ? '220px' : '260px'"
        class="w-full md:w-[278px]"
        [ngClass]="{
          'md:h-[220px]': view !== 'appointment',
          'md:h-[260px]': view === 'appointment'
        }"
        (mapReady)="refreshMapDetails($event)"
      >
      </customer-portal-agm-map>
    </div>

    <div class="info h-max flex-1 relative gap-6">
      <button
        data-cy="edit-preferred-dealer-button"
        *ngIf="view !== 'appointment' && !vehicle?.business"
        mat-button
        mat-stroked-button
        color="secondary"
        (click)="
          changeDealerSeller.emit(null);
          $event.stopPropagation();
          sendContentModulesMiniCTAData('shared.update')
        "
        class="revolution !absolute top-0 right-0 small-button"
      >
        {{ 'update' | sharedTranslate }}
      </button>

      <div
        class="text-xs text-mat-ef-revolution-primary-500 break-words"
        [ngClass]="{ 'max-w-[calc(100%_-_80px)]': view !== 'appointment' }"
      >
        {{ dealer.name || '' }}
      </div>
      <div
        class="text-2xl text-mat-ef-revolution-primary-500 font-bold break-words leading-7 md:leading-8"
        [ngClass]="{ 'max-w-[calc(100%_-_80px)]': view !== 'appointment' }"
      >
        {{ dealer.subname || '' }}
      </div>

      <div
        class="text-base text-mat-ef-revolution-primary-500 leading-5 md:leading-6"
      >
        <div>{{ dealer.street }}</div>
        <div>{{ dealer.zip }} {{ dealer.city }}</div>
      </div>

      <div class="mt-6" *ngIf="loading">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>

      <div
        class="flex gap-2 flex-col xl:flex-row items-baseline xl:items-center"
        [ngClass]="{ 'justify-between': view !== 'appointment' }"
        *ngIf="rating && !loading"
      >
        <a [href]="url" target="_blank" class="flex mt-6 gap-6 cursor-pointer">
          <ng-container *ngTemplateOutlet="google"> </ng-container>
        </a>
        <a
          mat-button
          mat-stroked-button
          color="secondary"
          [href]="url"
          target="_blank"
          (click)="
            sendContentModulesMiniCTAData(
              'customerPortal.customer-portal.review-us-on-google'
            )
          "
          class="revolution small-button w-full md:w-fit md:min-w-[200px]"
        >
          <T
            str="Rate us!"
            key="customerPortal.customer-portal.review-us-on-google"
            tags="customer-portal, 3.1"
          ></T>
        </a>
      </div>

      <div
        data-cy="request-callback-button-dealer"
        class="cursor-pointer text-mat-ef-revolution-secondary-500 pt-3 flex gap-2 items-center font-semibold text-sm"
        (click)="openCallRequestPopup(); $event.stopPropagation()"
      >
        <mat-icon class="!h-5 !w-5" svgIcon="phone_callback"></mat-icon>
        <T
          str="Request a callback"
          key="customerPortal.customer-portal.support.callrequest"
          tags="customer-portal, 3.1"
        ></T>
      </div>

      <div class="pt-3" (click)="$event.stopPropagation()">
        <customer-portal-app-dealer-hours
          [dealer]="dealer"
          [expanded]="view === 'appointment' ? true : false"
        ></customer-portal-app-dealer-hours>
      </div>
    </div>
  </mat-card>
</div>

<ng-template #google>
  <div class="text-3xl text-mat-ef-revolution-secondary-500 font-canelablack">
    {{ rating }}
  </div>
  <div class="flex flex-col">
    <star-rating
      [starType]="'svg'"
      [rating]="rating"
      [readOnly]="true"
      [showHalfStars]="true"
    >
    </star-rating>
    <div class="text-xs text-mat-ef-revolution-primary-200 font-efSans px-2">
      <T
        str="at Google"
        key="customerPortal.customer-portal.rating.at-google"
        tags="customer-portal, 3.1"
      ></T>
    </div>
  </div>
</ng-template>
