import { Pipe, PipeTransform } from '@angular/core'

interface DigitalManuals {
  name?: string
  link?: string
}

@Pipe({ name: 'digitalManuals' })
export class DigitalManualsPipe implements PipeTransform {
  transform(digitalManuals: DigitalManuals[], element: string): DigitalManuals {
    return digitalManuals?.find(vehicle => {
      return (vehicle.name != null ? vehicle.name.toLowerCase() : '') ===
        (element != null ? element.toLowerCase() : '')
        ? vehicle
        : null
    })
  }
}
