
import { Injectable, OnDestroy } from '@angular/core'
import { OneSignal } from 'onesignal-ngx'
import { NGXLogger } from 'ngx-logger'
import { Subject } from 'rxjs'
import { AutoPromptOptions, IOSNotification, NotificationEvent, OneSignalInitParams } from './ef-onesignal.interface'

@Injectable({
  providedIn: 'root'
})
export class EfOneSignalService implements OnDestroy {
  onIncomingPushNotification = new Subject<IOSNotification>()
  onNotificationClick = new Subject<IOSNotification>()
  constructor (
    private readonly oneSignal: OneSignal,
    private readonly logger: NGXLogger
  ) {}

  async init (initParams: OneSignalInitParams): Promise<void> {
    return (await this.oneSignal.init({
      appId: initParams.appId,
      autoResubscribe: initParams.autoResubscribe,
      path: initParams.path,
      serviceWorkerParam: {
        scope: initParams.serviceWorkerParam.scope
      },
      serviceWorkerUpdaterPath: initParams.serviceWorkerUpdaterPath,
      notificationClickHandlerMatch: initParams.notificationClickHandlerMatch,
      notificationClickHandlerAction: initParams.notificationClickHandlerAction,
      allowLocalhostAsSecureOrigin: initParams.allowLocalhostAsSecureOrigin
    }).then((success) => {
      this.logger.info('OneSignal init successfully.')
      if (initParams.debug === true) {
        this.oneSignal.Debug.setLogLevel('trace')
      }
      // Listener for incoming messages - add others if you need
      this.oneSignal.Notifications.addEventListener('foregroundWillDisplay', (notificationEvent: NotificationEvent) => {
        this.onIncomingPushNotification.next(notificationEvent.notification)
      })
      this.oneSignal.Notifications.addEventListener('click', (notificationClickEvent: NotificationEvent) => {
        this.onNotificationClick.next(notificationClickEvent.notification)
      })
    }).catch((err) => {
      this.logger.warn('OneSignal init error:' + err)
    }))
  }

  async updateUserid (userId: string): Promise<void> {
    return (await this.oneSignal.login(userId).then((success) => {
      this.logger.info('User id :' + userId + ' successfully logged in.')
    }).catch((err) => {
      this.logger.warn(err)
    }))
  }

  async showNotificationPrompt (options?: AutoPromptOptions): Promise<void> {
    return (await this.oneSignal.Slidedown.promptPush(options).catch((err) => {
      this.logger.warn(err)
    }))
  }

  async checkSubscription (): Promise<boolean> {
    const isPushSupported = this.oneSignal.Notifications.permission
    const optedIn = this.oneSignal.User.PushSubscription.optedIn
    const promise = new Promise<boolean>((resolve) => {
      resolve(isPushSupported && optedIn)
    })
    return await promise
  }

  async optIn (): Promise<void> {
    return (await this.oneSignal.setConsentGiven(true))
  }

  ngOnDestroy (): void {
    this.oneSignal.Notifications.removeEventListener('foregroundWillDisplay', (foregroundWillDisplayEvent) => {
      this.logger.info(foregroundWillDisplayEvent)
    })
    this.oneSignal.Notifications.removeEventListener('click', (notificationClickevent) => {
      this.logger.info(notificationClickevent)
    })
  }
}
