import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { SharedService } from './shared.service'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
export type PersonalizeEnumsDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.personalize.dto.PersonalizeEnumsDTO
export type PersonalizeDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.personalize.dto.PersonalizeDTO
export type InterestDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.personalize.dto.InterestDTO
export interface PersonalizeBrandDetail {
  name: string
  color: string
}
export interface VehicleType {
  icon: string
  type: string
  country: string
}

@Injectable({
  providedIn: 'root'
})
export class PersonalizationTestingService {
  public getBrands(): Observable<PersonalizeBrandDetail[]> {
    return of([
      { name: 'Alfa Romeo', color: 'primary' },
      { name: 'Aston Martin', color: 'default' },
      { name: 'BMW', color: 'default' },
      { name: 'Bentley', color: 'default' },
      { name: 'Cadillac', color: 'default' },
      { name: 'Chervolet', color: 'default' },
      { name: 'DFSK', color: 'default' },
      { name: 'Fiat', color: 'primary' },
      { name: 'Ford', color: 'default' },
      { name: 'Isuzu', color: 'default' },
      { name: 'Jaguar', color: 'default' },
      { name: 'Jeep', color: 'default' },
      { name: 'Kia', color: 'default' },
      { name: 'Land Rover', color: 'default' },
      { name: 'Lexus', color: 'primary' },
      { name: 'Mini', color: 'default' },
      { name: 'Maseratti', color: 'default' },
      { name: 'Mazda', color: 'default' },
      { name: 'Mercedes-Benz', color: 'default' },
      { name: 'Mitsubishi', color: 'default' },
      { name: 'Opel', color: 'default' },
      { name: 'Piaggio', color: 'primary' },
      { name: 'Smart', color: 'default' },
      { name: 'Subaru', color: 'default' },
      { name: 'Suzuki', color: 'default' },
      { name: 'Toyota', color: 'default' },
      { name: 'Volvo', color: 'default' }
    ])
  }

  getPersonalizationEnums(): Observable<PersonalizeEnumsDTO> {
    return undefined
  }
}

@Injectable({
  providedIn: 'root'
})
export class PersonalizationService {
  private readonly apiUrl

  constructor(
    private readonly http: HttpClient,
    private readonly sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  getBrands(): Observable<PersonalizeBrandDetail[]> {
    return this.http.get<PersonalizeBrandDetail[]>(
      this.apiUrl + '/personalize/brands'
    )
  }

  getPersonalizationEnums(): Observable<PersonalizeEnumsDTO> {
    return this.http
      .get<PersonalizeEnumsDTO>(this.apiUrl + '/personalize/enums')
      .pipe(
        map((personalizeEnums: PersonalizeEnumsDTO) => {
          const excludedBrands: string[] =
            this.remoteConfigService.get('excludedBrands')
          if (
            personalizeEnums?.brands?.[0] != null &&
            excludedBrands?.[0] != null
          ) {
            personalizeEnums.brands = personalizeEnums.brands.filter(brand => {
              if (this.sharedService.stringExists(brand.name)) {
                return (
                  excludedBrands.findIndex(
                    b =>
                      b.toLowerCase().trim() === brand.name.toLowerCase().trim()
                  ) === -1
                )
              } else {
                return true
              }
            })
          }
          return personalizeEnums
        })
      )
  }

  getPersonalizationValues(): Observable<PersonalizeDTO> {
    return this.http.get<PersonalizeDTO>(this.apiUrl + '/personalize')
  }

  putPersonalization(
    personalization: PersonalizeDTO
  ): Observable<PersonalizeDTO> {
    return this.http.put<PersonalizeDTO>(
      this.apiUrl + '/personalize',
      personalization
    )
  }
}
