import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGoogleTagmanagerService,
  GTMEventData
} from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import * as moment from 'moment'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { SharedService } from '../../../../services/shared.service'
import { VehiclePromotionDocumentDTOExtended } from '../../../../services/vehicle-documents.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'
import {
  Expiration,
  Warranty,
  WarrantyDetailsDTO,
  WarrantyService,
  WarrantyContactReasonEnum
} from '../../../../services/warranty.service'
import {
  RevolutionWarrantyContactPopupComponent,
  WarrantyContactPopupComponentDialogData,
  WarrantyContactPopupComponentDialogTitle
} from '../warranty-contact-popup/warranty-contact-popup.component'

@Component({
  selector: 'customer-portal-app-revolution-warranty-molecule',
  templateUrl: './warranty-molecule.component.html'
})
export class RevolutionWarrantyMoleculeComponent implements OnInit {
  warranty: Warranty = null
  loading = true
  purchasedExtendedWarranties: WarrantyDetailsDTO[]
  unpurchasedExtendedWarranties: WarrantyDetailsDTO[]
  allWarrantiesExpired = false
  user: User
  unpurchasedTireWarranties: WarrantyDetailsDTO[] = []
  purchasedTireWarranties: WarrantyDetailsDTO[] = []
  hasWarrantyDocuments = false
  validWarranties: boolean
  warrantyIsDisabled = true
  currencyLocale: string
  hasPromotions
  dateFormat: string

  promotions: VehiclePromotionDocumentDTOExtended[] = []
  promotionIndex

  hasLinks = false // brand links
  constructor(
    private readonly warrantyService: WarrantyService,
    private readonly logger: NGXLogger,
    private readonly dialog: MatDialog,
    private readonly dataService: DataService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly transifexTranslationsService: TranslationService,
    private readonly sharedService: SharedService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.dateFormat = this.remoteConfigService.get('dateFormat.long')
    this.currencyLocale = this.remoteConfigService.get('currencyLocale')
    this.hasPromotions = this.remoteConfigService.get('hasPromotions')
    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
    })
  }

  @Input()
  public vehicle: VehicleDTOExtended

  styleLeasingProgressBar(w: WarrantyDetailsDTO): string {
    return this.warrantyService.styleProgressBar(w, 'warranty')
  }

  styleExtendedWarrantyProgressBar(w: WarrantyDetailsDTO): string {
    return this.warrantyService.styleProgressBar(w, 'extendedWarranty')
  }

  styleTyreWarrantyProgressBar(w: WarrantyDetailsDTO): string {
    return this.warrantyService.styleProgressBar(w, 'extendedWarranty')
  }

  styleLeasingWarningIcon(w: WarrantyDetailsDTO): string {
    let style = this.warrantyService.styleProgressBar(w, 'warranty')

    // if expired flag is true - red
    if (w.expired) {
      style = 'warn-red'
    }

    // Always show a green checkmark  if there is any “not-expired” extended warranty
    if (this.nonExpiredExtendedWarrantiesExists()) {
      style = 'ok-green'
    }

    return style
  }

  nonExpiredExtendedWarrantiesExists(): boolean {
    const nonExpiredExtendedWarranties = this.warranty.extended?.filter(
      warranty => {
        return !warranty.expired && warranty.purchased
      }
    )
    if (nonExpiredExtendedWarranties?.[0] != null) {
      return true
    }
    return false
  }

  openWarrantyPackageContactPopup(
    contactType: 'contact' | 'offerRequest',
    title: WarrantyContactPopupComponentDialogTitle,
    w?: WarrantyDetailsDTO,
    reason?: WarrantyContactReasonEnum
  ): void {
    const data: WarrantyContactPopupComponentDialogData = {
      vehicle: this.vehicle,
      warrantyServicePackage: w,
      requestType: contactType,
      title
    }
    if (reason != null) {
      data.reason = [reason]
    }
    this.dialog.open(RevolutionWarrantyContactPopupComponent, {
      data,
      panelClass: 'mat-dialog-cpt'
    })

    /** GTM 2.7.3 */
    const gtmData: GTMEventData = {
      event: 'gaEvent2',
      event_name: 'service',
      service: {
        button_action: title,
        service_type:
          contactType === 'contact' ? 'Get in touch' : 'Offer request',
        event_action:
          contactType === 'contact' ? 'Get in touch' : 'Offer request',
        event_category: 'Service',
        event_label: title
      }
    }
    this.cptGtmService.send(gtmData)
  }

  tillExpiration(w: WarrantyDetailsDTO): Expiration {
    return this.warrantyService.calculateTillExpires(w.startDate, w.endDate)
  }

  tillExpiresWarn(w: WarrantyDetailsDTO): boolean {
    // Always show a green checkmark  if there is any “not-expired” extended warranty
    if (this.nonExpiredExtendedWarrantiesExists()) {
      return false
    } else {
      return (
        w.expired ||
        this.tillExpiration(w).days <=
          this.remoteConfigService.get('warranty.expirationDays.warn')
      )
    }
  }

  tillExpiresCritical(w: WarrantyDetailsDTO): boolean {
    return (
      this.tillExpiration(w).days <=
      this.remoteConfigService.get('warranty.expirationDays.critical')
    )
  }

  sortPurchasedExtendedWarrantiesByDateDesc(): void {
    this.purchasedExtendedWarranties.sort(
      (a: WarrantyDetailsDTO, b: WarrantyDetailsDTO) => {
        const one = moment(a.endDate).toDate().getTime()
        const two = moment(b.endDate).toDate().getTime()
        return two - one
      }
    )
  }

  hasValidWarranties(): boolean {
    let validWarranties = false
    if (this.warranty == null) {
      validWarranties = false
    } else {
      validWarranties = Boolean(
        this.warranty.standard != null ||
          (this.warranty.extended != null &&
            !!(this.warranty.extended.length > 0)) ||
          (this.warranty.tire != null && !!(this.warranty.tire.length > 0))
      )
    }
    this.validWarranties = validWarranties
    return validWarranties
  }

  getMileage(): number {
    const mileage =
      this.vehicle.manualMileage != null
        ? this.vehicle.manualMileage
        : this.vehicle.currentMileage != null
        ? this.vehicle.currentMileage
        : 0
    return mileage
  }

  sendContentModulesData(expanded: boolean, contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Accordion',
      expanded ? 'Open' : 'Close',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Accordion|${this.sharedService.translateLink(contentModuleLabel) ?? ''}`,
      expanded ? 'Open' : 'Close'
    )
  }

  sendContentModulesCTAData(contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }

  onWarrantyDocumentButtonClicked(): void {
    /** GTM 2.7.6 */
    const gtmData: GTMEventData = {
      event: 'gaEvent2',
      event_name: 'service',
      service: {
        button_action: 'Warranty',
        service_type: 'Documents',
        event_action: 'Documents',
        event_category: 'Service',
        event_label: 'Warranty'
      }
    }
    this.cptGtmService.send(gtmData)
  }

  ngOnInit(): void {
    this.dataService.onVehicleDocumentsLoaded.subscribe(documents => {
      if (documents != null) {
        const warrantyDocs = documents.find(({ type }) => type === 'WARRANTY')
        this.hasWarrantyDocuments = warrantyDocs != null
      }
    })
    this.dataService.onVehicleWarrantyLoaded.subscribe(warranty => {
      this.loading = false
      this.warranty = warranty

      if (warranty != null) {
        this.setDisabled()

        if (warranty.standard != null) {
          this.allWarrantiesExpired = warranty.standard.expired
        }
        if (this.warranty.extended != null) {
          this.unpurchasedExtendedWarranties = this.warranty.extended?.filter(
            w => !w.purchased
          )
          this.purchasedExtendedWarranties = this.warranty.extended?.filter(
            w => w.purchased
          )
          this.sortPurchasedExtendedWarrantiesByDateDesc()
          this.warranty.extended.forEach(w => {
            if (!w.expired) {
              this.allWarrantiesExpired = false
            }
          })
        }
        if (this.warranty.extended != null) {
          this.unpurchasedTireWarranties = this.warranty.tire?.filter(
            w => !w.purchased
          )
          this.purchasedTireWarranties = this.warranty.tire?.filter(
            w => w.purchased
          )
        }
        this.hasValidWarranties()
      } else {
        this.validWarranties = false
      }
    })
    this.dataService.onVehiclePromotionsLoaded.subscribe(promotions => {
      this.promotions = promotions != null ? promotions : []
      // this.promotions = JSON.parse(JSON.stringify(PROMOTIONS)) // mock data
      this.setDisabled()

      if (this.promotions?.[0] != null) {
        this.promotions.forEach(promo => {
          if (!this.sharedService.stringExists(promo.name)) {
            const lastIndex = promo.url.lastIndexOf('/')
            if (lastIndex !== -1) {
              promo.name = promo.url.slice(lastIndex + 1)
            }
          }
          try {
            const searchMask = '.pdf'
            const regEx = new RegExp(searchMask, 'ig')
            const replaceMask = ''
            promo.name = promo.name.replace(regEx, replaceMask)
          } catch (error) {
            this.logger.debug(error)
          }
        })
      }
    })
  }

  hasLinksChanged(hasLinks): void {
    this.hasLinks = hasLinks

    this.setDisabled()
  }

  setDisabled(): void {
    if (
      this.warranty?.standard != null ||
      this.warranty?.extended?.[0] != null ||
      this.warranty?.tire?.[0] != null ||
      this.hasLinks ||
      this.promotions?.[0] != null
    ) {
      this.warrantyIsDisabled = false
    } else {
      this.warrantyIsDisabled = true
    }
    this.cdRef.detectChanges()
  }

  downloadLink(v: VehiclePromotionDocumentDTOExtended): void {
    const url = v.url
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = url
    a.download = '1.pdf'
    a.click()
  }

  openLinkInNewTab(
    v: VehiclePromotionDocumentDTOExtended,
    evt: MouseEvent
  ): void {
    evt.stopPropagation()
    const url = v.url
    window.open(url, '_blank')
  }

  fileNameFromUrl(url: string): string {
    return this.sharedService.fileNameFromUrl(url)
  }
}
