<div
  *ngIf="mode === 'services'"
  class="background-color my-14 container xs:px-16"
>
  <!-- Services view -->
  <div class="flex-1">
    <div class="md:mb-5">
      <h1 class="services-head-title text-4xl md:text-6xl">
        <T
          str="Services"
          key="customerPortal.customer-portal.top-bar.additional-services"
          tags="customer-portal, 3.1"
        ></T>
      </h1>
      <div
        class="flex w-full justify-center p-6 text-mat-ef-revolution-primary-500"
      >
        <span class="lg:w-1/4 sm:w-full">
          <!-- prettier-ignore -->
          <h3
            class="text-center"
            [innerHTML]="getTranslation()"
          ></h3>
        </span>
      </div>
    </div>

    <div
      class="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-6 md:my-6 p-6 md:p-0"
    >
      <div
        data-cy="additional-services"
        class="bg-mat-ef-revolution-grey-two-50 flex flex-col rounded-lg hover:!shadow-2xl relative"
        *ngFor="let s of services"
      >
        <div>
          <img
            [alt]="s.categoryKey"
            [src]="s.imageUrl"
            class="w-full rounded-t-lg"
          />
        </div>
        <div class="grid py-4 text-mat-ef-revolution-primary-500">
          <div
            class="font-canelablack md:text-3xl text-2xl text-center break-words px-2.5"
          >
            <T
              data-cy="service-text"
              str="Text"
              [key]="s?.textKey"
              tags="customer-portal, notranslate"
            ></T>
          </div>
          <div
            data-cy="service-description"
            class="text-center break-words py-7 px-6 lg:px-12"
            [innerHTML]="getDescriptionTranslation(s) | safeHtml"
          ></div>
          <div class="flex items-center justify-center pt-12">
            <button
              class="bg-mat-ef-revolution-secondary-500 absolute bottom-3 font-bold text-mat-ef-revolution-grey-500 font-efSans rounded p-3 my-2 w-11/12 sm:w-1/2 cursor-pointer"
              (click)="
                sendContentModulesBigTeaserData(
                  'customerPortal.customer-portal.top-bar.additional-services',
                  s.textKey
                )
              "
            >
              <!-- prettier-ignore -->
              <a data-cy="service-link" [href]="'Link' | translate:{ _key: s?.linkKey, _tags: 'customer-portal, notranslate'}" target="_blank">
                <T
                  str="LinkText"
                  [key]="s?.linkTextKey"
                  tags="customer-portal, notranslate"
                ></T>
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="mode === 'summary'"
  class="RevolutionServicesComponent w-full"
  [ngClass]="{'bg-mat-ef-revolution-grey-200': homepageServices?.[0]}"
>
  <div class="container px-4">
    <div class="w-full pt-14 md:pb-14 md:pt-24">
      <div class="flex flex-col text-center w-full md:text-left">
        <div
          class="font-canelablack text-mat-ef-revolution-primary-500 text-4xl md:text-5xl pb-10 md:pb-12"
        >
          <T
            str="For me"
            key="customerPortal.customer-portal.for-me"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </div>

      <div
        class="articles w-full relative"
        [ngClass]="{ 'pb-[7rem] ': showBullets || showArrows }"
        *ngIf="homepageServices?.[0]"
      >
        <ngx-glide
          #ngxGlideServices
          [arrowLeftTemplate]="left"
          [arrowRightTemplate]="right"
          [breakpoints]="breakpoints"
          [peek]="{ before: 0, after: homepageServices?.[1] ? 40 : 0 }"
          (resized)="recreate()"
          [gap]="0"
          [showBullets]="showBullets"
          [showArrows]="showArrows"
          [bound]="bound"
          [ngClass]="{ showBullets: showBullets, fitGlider: fitGlider }"
        >
          <div
            class="flex-1 flex flex-col h-auto p-1 px-1.5 md:px-3 pb-5"
            [ngClass]="{
              'md:max-w-[440px]': homepageServices.length === 1
            }"
            *ngFor="let service of homepageServices; let i = index"
          >
            <mat-card
              class="article select-none flex flex-col transition duration-300 ease-in-out !shadow-none hover:!shadow-lg !rounded-lg h-full !p-0 cursor-pointer"
              (click)="
                sharedService.preventClick || goToServices();
                sendContentModulesBigTeaserData(
                  'customerPortal.customer-portal.top-bar.additional-services',
                  service.textKey
                )
              "
              (mouseup)="
                sharedService.handleGliderEvents(
                  $event,
                  service.homepagePosition
                )
              "
              (mousedown)="
                sharedService.handleGliderEvents(
                  $event,
                  service.homepagePosition
                )
              "
            >
              <div class="relative h-[274px]">
                <img
                  alt="service image"
                  class="rounded-t-lg h-full w-full object-cover"
                  src="{{ service.imageUrl }}"
                />
              </div>

              <div
                class="flex-1 flex flex-col justify-between text-center p-4 md:p-6 rounded-b-lg bg-light-bg gap-4 md:gap-6"
              >
                <div class="flex-1">
                  <div
                    class="font-canelablack text-mat-ef-revolution-primary-500 !text-2xl whitespace-normal pb-3.5"
                  >
                    <T
                      data-cy="service-text"
                      str="Text"
                      [key]="service.textKey"
                      tags="customer-portal, notranslate"
                    ></T>
                  </div>
                  <div
                    class="ef-paragraph text-mat-ef-revolution-primary-500 block text-base whitespace-normal"
                    [innerHTML]="getDescriptionTranslation(service) | safeHtml"
                  ></div>
                </div>
                <div class="flex items-center justify-center">
                  <button
                    class="bg-mat-ef-revolution-secondary-500 font-bold text-mat-ef-revolution-grey-500 font-efSans rounded p-3 md:my-2 w-full md:w-1/2 cursor-pointer"
                    (click)="
                      goToLink(service, $event);
                      sendContentModulesBigTeaserData(
                        'customerPortal.customer-portal.top-bar.additional-services',
                        service.textKey
                      )
                    "
                  >
                    {{ getLinkTextTranslation(service) }}
                  </button>
                </div>
              </div>
            </mat-card>
          </div>
        </ngx-glide>
      </div>

      <div
        *ngIf="!(homepageServices?.[0])"
        class="w-full h-36 bg-mat-ef-revolution-grey-200 rounded-xl flex flex-col justify-center items-center"
      >
        <div class="text-mat-ef-revolution-supergrey-900">
          <mat-icon svgIcon="no_sim"></mat-icon>
        </div>
        <div class="text-base font-efSans-primary text-center">
          <T
            str="There are currently no related items for you"
            key="customerPortal.customer-portal.articles.no-articles"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #left>
  <div
    class="hidden arrowTemplate leftArrow md:flex justify-center items-center bottomArrows"
  >
    <mat-icon svgIcon="arrow_left"></mat-icon>
  </div>
</ng-template>

<ng-template #right>
  <div
    class="hidden arrowTemplate rightArrow md:flex justify-center items-center bottomArrows"
  >
    <mat-icon svgIcon="arrow_right"></mat-icon>
  </div>
</ng-template>
