import { Component, Input } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { SharedService } from '../../../../services/shared.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'

import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
@Component({
  selector: 'customer-portal-app-revolution-nearest-fuel-station',
  templateUrl: './nearest-fuel-station.component.html'
})
export class NearestFuelStationComponent {
  @Input()
  vehicle: VehicleDTOExtended

  nearestFuelStationGas
  nearestFuelStationElectro

  constructor(
    public dialog: MatDialog,
    public sharedService: SharedService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.nearestFuelStationGas = this.remoteConfigService.get('nearestFuelStation.gas')
    this.nearestFuelStationElectro = this.remoteConfigService.get(
      'nearestFuelStation.electro'
    )
  }

  sendContentModulesMiniCTAData(contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Mini CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'Mini CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }
}
