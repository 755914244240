<div class="container py-14 px-4">
  <h1 class="head-title">
    <T
      str="Customer History"
      key="customerPortal.customer-portal.top-bar.my-documents"
      tags="customer-portal, 3.1"
    ></T>
  </h1>

  <customer-portal-vehicle-documents-disclaimer
    [translateKey]="
      'customerPortal.customer-portal.documents.limited-usability'
    "
  ></customer-portal-vehicle-documents-disclaimer>

  <div class="w-full mt-4" *ngIf="loadingDocuments">
    <ngx-skeleton-loader
      appearance="line"
      animation="progress"
      [count]="12"
    ></ngx-skeleton-loader>
  </div>
  <div
    class="flex flex-col-reverse lg:flex-row gap-6"
    *ngIf="!loadingDocuments"
  >
    <div class="w-full lg:w-3/4">
      <ng-container *ngIf="filteredDocuments.length === 0">
        <div
          class="mt-4 md:mt-14 w-full h-[155px] bg-mat-ef-revolution-grey-200 rounded-xl flex flex-col justify-center items-center"
        >
          <div class="text-mat-ef-revolution-supergrey-900">
            <mat-icon svgIcon="no_sim"></mat-icon>
          </div>
          <div class="text-base font-efSans-primary">
            <T
              str="You currently have no documents"
              key="customerPortal.customer-portal.no-documents"
              tags="customer-portal, 3.1"
            ></T>
          </div>
        </div>
      </ng-container>

      <div class="mt-4 md:mt-14 md:p-0">
        <div class="flex justify-end mb-3" *ngIf="unread > 0">
          <button
            mat-button
            mat-stroked-button
            class="revolution min-h-[32px] leading-none small-button"
            (click)="onMarkAllAsReadClicked()"
            color="secondary"
          >
            <mat-icon svgIcon="visibility_on" class="mr-2 min-h-[18px] min-w-[18px]"></mat-icon>
            <T
              str="Mark all as read"
              key="customerPortal.customer-portal.documents.mark-all-as-read"
              tags="customer-portal, 3.11"
            ></T>
          </button>
        </div>
        <div
          class="flex items-center justify-between"
          *ngIf="filteredDocuments.length > 0"
        >
          <p
            *ngIf="allFilteredDocumentsLength === 1"
            class="font-efSans text-xl font-bold text-mat-ef-revolution-primary-500"
          >
            <T
              str="Document"
              key="customerPortal.customer-portal.vehicle.document-single"
              tags="customer-portal, 3.1"
            ></T>
          </p>
          <p
            *ngIf="allFilteredDocumentsLength !== 1"
            class="font-efSans text-xl font-bold text-mat-ef-revolution-primary-500"
          >
            {{ 'documents' | sharedTranslate }}
          </p>
          <p class="font-efSans text-lg text-mat-ef-revolution-primary-500">
            {{ allFilteredDocumentsLength + ' ' }}
            <T
              str="Results"
              key="customerPortal.customer-portal.results"
              tags="customer-portal, 3.1"
            ></T>
          </p>
        </div>

        <div *ngIf="filteredDocuments.length > 0">
          <mat-divider></mat-divider>
          <div *ngFor="let element of filteredDocuments; let i = index">
            <mat-expansion-panel
              [hideToggle]="false"
              disabled="false"
              class="p-0"
              (afterExpand)="
                sendContentModulesData(
                  true,
                  element?.brandName + ' ' + element?.modelName
                );
                documentRead(element)
              "
              (afterCollapse)="
                sendContentModulesData(
                  false,
                  element?.brandName + ' ' + element?.modelName
                )
              "
              (opened)="documentIndex = i"
              [expanded]="documentIndex === i"
              [ngClass]="{ 'italic opacity-50': !element?.active }"
            >
              <mat-expansion-panel-header
                class="px-2 md:px-6"
                [collapsedHeight]="'auto'"
                [expandedHeight]="'auto'"
              >
                <mat-panel-title class="flex gap-1 m-0">
                  <div class="w-8 items-center">
                    <customer-portal-app-document-icon
                    [setDocument]="element">
                  </customer-portal-app-document-icon>
                  </div>
                  <div class="w-6 flex items-center justify-center">
                    <span
                      *ngIf="!element?.read"
                      (click)="documentRead(element)"
                      class="bg-mat-ef-revolution-secondary-500 rounded-full h-2 w-2"
                    ></span>
                  </div>
                  <customer-portal-app-document-header class="flex-1" [setDocument]="element">
                  </customer-portal-app-document-header>
                </mat-panel-title>

                <ng-template matExpansionPanelContent>
                  <div *ngIf="documentIndex === i">
                    <div class="flex m-0">
                      <div
                        class="flex-1 ml-[52px] md:ml-[70px] flex flex-col gap-3"
                      >
                        <customer-portal-app-offer-contract-info
                          [setDocument]="element"
                        >
                        </customer-portal-app-offer-contract-info>

                        <div
                          *ngIf="offerAlreadyAcceptedDeclined(element)"
                          class="text-mat-ef-revolution-primary-500"
                        >
                          <!-- same key for accept and decline  -->
                          <T
                            str="Your request was successfully sent to salesman."
                            key="customerPortal.customer-portal.my-documents.decline-offer.success"
                            tags="customer-portal, 3.11"
                          ></T>

                          <ng-container
                            *ngIf="element.mailOfferState === 'ACCEPTED'"
                          >
                            ({{ 'accept' | sharedTranslate }})
                          </ng-container>
                          <ng-container
                            *ngIf="element.mailOfferState === 'DECLINED'"
                          >
                            ({{ 'decline' | sharedTranslate }})
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full mt-5 flex gap-5 justify-end flex-wrap">
                    <div *ngIf="showOfferButtons(element)" class="flex gap-5">
                      <button
                        mat-button
                        mat-raised-button
                        color="warn"
                        class="revolution min-h-[36px] h-[36px] pr-5"
                        [disabled]="downloadStarted"
                        (click)="confirmAcceptDeclineOffer(element, 'decline')"
                      >
                        <mat-icon svgIcon="close"></mat-icon>
                        {{ 'decline' | sharedTranslate }}
                      </button>
                      <button
                        mat-button
                        mat-raised-button
                        color="secondary"
                        class="revolution min-h-[36px] h-[36px] pr-5"
                        [disabled]="downloadStarted"
                        (click)="confirmAcceptDeclineOffer(element, 'accept')"
                      >
                        <mat-icon
                          svgIcon="check_circle_outline"
                        ></mat-icon>
                        {{ 'accept' | sharedTranslate }}
                      </button>
                    </div>
                    <customer-portal-app-document-buttons
                    [setDocument]="element"
                    [downloadStarted]="downloadStarted"
                    >
                    </customer-portal-app-document-buttons>
                  </div>
                </ng-template>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
            <mat-divider></mat-divider>
          </div>

          <mat-paginator
            *ngIf="allFilteredDocumentsLength > 10 && showPaginator"
            ef-paginator
            [showTotalPages]="10"
            [length]="allFilteredDocumentsLength"
            [pageSize]="pageSize"
            (page)="onPageChange($event)"
          >
          </mat-paginator>
        </div>
      </div>
    </div>

    <div class="w-full lg:w-1/4">
      <div class="rounded-md bg-mat-ef-revolution-grey-500 md:mt-14">
        <div class="px-6 py-2 md:py-6">
          <p
            class="font-efSans text-[15px] font-bold text-mat-ef-revolution-primary-500 mb-3"
          >
            <T
              str="Filter documents"
              key="customerPortal.customer-portal.documents.filter"
              tags="customer-portal, 3.1"
            ></T>
          </p>

          <form [formGroup]="documentSelectFormGroup">
            <mat-form-field appearance="legacy" class="white w-full">
              <mat-label>
                <T
                  str="Data type"
                  key="customerPortal.customer-portal.date-type"
                  tags="customer-portal, 3.1"
                ></T
              ></mat-label>

              <mat-select formControlName="documentType">
                <mat-option *ngFor="let d of documentTypes" [value]="d">
                  <ng-container
                    *ngTemplateOutlet="
                      sharedService.template('transifexTranslation');
                      context: {
                        str: d?.value?.toLowerCase(),
                        key: d?.key
                      }
                    "
                  ></ng-container>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
