import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

declare global {
  interface Window {
    webkit?: any
  }
}

export interface oneSignalNavigationPushMessage {
  data: {
    link: string
  }
}

@Injectable({
  providedIn: 'root'
})
export class IosMessagingService {
  public iosNotification = new Subject<oneSignalNavigationPushMessage>()
  public setIosPushNotificationListener(): void {
    window.addEventListener('push-notification-ios', (e: CustomEvent) => {
      const data: oneSignalNavigationPushMessage = JSON.parse(e.detail)
      this.iosNotification.next(data)
    })
  }

  public logout(cpId: number): void {
    if (
      window?.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.logoutHandler
    ) {
      window.webkit.messageHandlers.logoutHandler.postMessage({
        cpId
      })
    }
  }

  public login(cpId: number): void {
    if (
      window?.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.loginHandler
    ) {
      window.webkit.messageHandlers.loginHandler.postMessage({
        cpId
      })
    }
  }
}
