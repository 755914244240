/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { SharedService } from '../../../../services/shared.service'
import { ConfirmationPopupComponent } from '../../../basic/confirmation-popup/confirmation-popup.component'
import { ConfirmationQuestionPopupComponent } from '../../../basic/confirmation-question-popup/confirmation-question-popup.component'
import {
  MarketFilterDTO,
  MarketFilterService
} from '../../../../services/market-filter.service'
@Component({
  selector: 'customer-portal-app-revolution-market-filter',
  templateUrl: './market-filter.component.html'
})
export class MarketFilterComponent implements OnInit {
  loading = true
  marketFilters: MarketFilterDTO[]
  nasMarketFilters
  
  dateFormat: string
  
  constructor(
    private readonly marketFilterService: MarketFilterService,
    private readonly sharedService: SharedService,
    private readonly dialog: MatDialog,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.dateFormat = this.remoteConfigService.get('dateFormat.long')
  }

  sendContentModulesData(expanded: boolean, contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Accordion',
      expanded ? 'Open' : 'Close',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Accordion|${this.sharedService.translateLink(contentModuleLabel) ?? ''}`,
      expanded ? 'Open' : 'Close'
    )
  }

  deleteFilter(filter: MarketFilterDTO): void {
    this.dialog
      .open(ConfirmationQuestionPopupComponent, {
        data: {
          title:
            'customerPortal.customer-portal.marketplace.searchagent.delete',
          text: 'customerPortal.customer-portal.marketplace.searchagent.delete.question',
          cancel: 'shared.cancel',
          save: 'shared.clear',
          saveButtonColor: 'warn'
        },
        panelClass: 'mat-dialog-cpt'
      })
      .afterClosed()
      .subscribe(result => {
        if (result != null && result !== false) {
          this.marketFilterService.deleteMarketFilter(filter.id).subscribe(
            () => {
              this.dialog.open(ConfirmationPopupComponent, {
                data: {
                  title: 'shared.successful',
                  text: 'customerPortal.customer-portal.marketplace.searchagent.delete.success.text'
                },
                panelClass: 'mat-dialog-cpt'
              })
              this.getMarketFilters()
            },
            () => {
              this.dialog.open(ConfirmationPopupComponent, {
                data: {
                  title: 'shared.error',
                  text: 'customerPortal.customer-portal.marketplace.searchagent.delete.failure.text'
                },
                panelClass: 'mat-dialog-cpt'
              })
            }
          )
        }
      })
  }

  getMarketFilters(): void {
    this.marketFilterService.getMarketFilter().subscribe(
      marketFilters => {
        this.loading = false
        this.marketFilters = marketFilters
        this.nasMarketFilters = this.marketFilters?.length > 0
      },
      () => {
        this.loading = false
        this.nasMarketFilters = false
      }
    )
  }

  ngOnInit(): void {
    this.getMarketFilters()
  }

  openMarketplace(): void {
    const marketplaceUrl = this.getMarketplaceUrl()
    window.open(marketplaceUrl, '_blank')
  }

  showFilterResults(filter: MarketFilterDTO): void {
    const marketplaceUrl = this.getMarketplaceUrl()
    const fullURL = marketplaceUrl + '?' + filter.urlParams
    window.open(fullURL, '_blank')
  }

  getMarketplaceUrl(): string {
    const links = {
      de: this.remoteConfigService.get('links.automarkt.url_de'),
      fr: this.remoteConfigService.get('links.automarkt.url_fr'),
      it: this.remoteConfigService.get('links.automarkt.url_it')
    }
    const language = this.sharedService.currentLanguage()
    if (language != null) {
      return links[language]
    }
  }
}
