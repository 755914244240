import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { SharedService } from '../../../../../services/shared.service'
import {
  VehicleNotificationsDTO,
  VehicleNotificationsService
} from '../../../../../services/vehicle-notifications.service'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'

import { NgxGlideComponent } from 'ngx-glide'
import { NGXLogger } from 'ngx-logger'
import { VehicleDTOExtended } from '../../../../../services/vehicles.service'

interface VehicleExtendedNotifications extends VehicleDTOExtended {
  vehicleNotifications?: VehicleNotificationsDTO
}
@Component({
  selector: 'customer-portal-app-notifications',
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit {
  @Input()
  set setNotifications(notifications: VehicleNotificationsDTO) {
    this.notifications = this.sharedService.deepCopy(notifications)
    this.setVehicleNotifications()
  }

  notifications: VehicleNotificationsDTO

  @Input()
  set setVehicles(vehicles: VehicleExtendedNotifications[]) {
    this.vehicles = this.sharedService.deepCopy(vehicles)
    this.setVehicleNotifications()
  }

  vehicles: VehicleExtendedNotifications[]

  @Input() loadingNotifications = true

  vehicleNotifications

  @Output() action = new EventEmitter()

  licensePlateMask

  @ViewChild('NotificationsComponent_ngxGlide')
  NotificationsComponent_ngxGlide!: NgxGlideComponent

  firstVehicle
  notificationsKey
  constructor(
    public sharedService: SharedService,
    private readonly logger: NGXLogger,
    private readonly vehicleNotificationsService: VehicleNotificationsService,
    private readonly cptGtmService: CptGoogleTagmanagerService
  ) {}

  ngOnInit(): void {
    this.licensePlateMask = this.sharedService.setLicensePlateMask()
  }

  setVehicleNotifications(): void {
    if (
      this.notifications?.vehicleNotifications?.[0] != null &&
      this.vehicles?.[0] != null &&
      this.vehicleNotifications?.[0] == null
    ) {
      this.vehicles.forEach(vehicle => {
        vehicle.vehicleNotifications =
          this.vehicleNotificationsService.setVehicleNotifications(
            this.notifications,
            vehicle.vin
          )
      })
      this.vehicleNotifications = this.vehicles.filter(vehicle => {
        return vehicle.vehicleNotifications
      })
      this.updateItems()
      this.firstVehicle = this.sharedService.deepCopy(
        this.vehicleNotifications[0]
      )
    } else {
      if (this.vehicles?.[0] != null) {
        this.firstVehicle = this.sharedService.deepCopy(this.vehicles[0])
      }
    }

    this.setTranslateKey()
  }

  updateItems(): void {
    setTimeout(() => {
      if (this.NotificationsComponent_ngxGlide != null) {
        try {
          this.NotificationsComponent_ngxGlide.recreate()
        } catch (error) {
          this.logger.log(error)
        }
      }
    })
  }

  setTranslateKey(): void {
    const length =
      this.notifications?.attentionsCount != null
        ? this.notifications?.attentionsCount
        : 0
    switch (length) {
      case 1:
        this.notificationsKey =
          'customerPortal.customer-portal.vehicles_need_attention.1'
        break
      default:
        this.notificationsKey = 'shared.vehicle-attention'
        break
    }
  }

  sendContentModulesCTAData(contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }

  sendContentModulesSliderData(
    side: string,
    contentModuleLabel: string,
    galleryName: string
  ): void {
    this.cptGtmService.sendContentModulesData(
      'Gallery',
      side,
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Gallery|(${galleryName})`,
      side
    )
  }
}
