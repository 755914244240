import { Injectable } from '@angular/core'
import { MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav'

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {
  private sidenav: MatSidenav

  public setSidenav(sidenav: MatSidenav): void {
    this.sidenav = sidenav
  }

  public async open(): Promise<MatDrawerToggleResult> {
    return await this.sidenav.open()
  }

  public async close(): Promise<MatDrawerToggleResult> {
    return await this.sidenav.close()
  }

  public toggle(): void {
    void this.sidenav.toggle()
  }
}
