import { Component, Input, OnInit } from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS } from '@angular/material/legacy-core'
import {
  CptDateAdapter,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import {
  APP_DATE_FORMATS_1,
  SharedService
} from '../../../../services/shared.service'
import {
  ServicesCH,
  ServicesDE
} from '../../../../services/appointments-popup.service'
import { delay, distinctUntilChanged } from 'rxjs'
import { MatStepper } from '@angular/material/stepper'

@Component({
  selector: 'customer-portal-app-appointments-popup-second-form',
  templateUrl: './appointments-popup-second-form.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: CptDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS_1
    }
  ]
})
export class AppointmentsPopupSecondFormComponent implements OnInit {
  @Input() appointmentsPopupService

  @Input()
    stepper: MatStepper

  maxLength = 1500

  validationMessages = {
    mileage: [
      {
        type: 'pattern',
        message:
          'customerPortal.customer-portal.appointments.validation-error-numbers'
      },
      {
        type: 'maxlength',
        message: 'customerPortal.customer-portal.appointments.maxLength'
      },
      {
        type: 'min',
        message:
          'customerPortal.customer-portal.appointments.validation.invalid-value'
      }
    ]
  }

  services
  reifenVehicleBrand = ''

  constructor (
    public sharedService: SharedService,
    public transifexService: TransifexService
  ) {}

  async ngOnInit (): Promise<void> {
    this.reifenVehicleBrand =
      this.appointmentsPopupService.reifenData?.vehicle != null
        ? this.appointmentsPopupService.reifenData.vehicle.brand
        : null

    if (this.appointmentsPopupService.country === 'de') {
      this.services = Object.keys(ServicesDE)
        .filter(key => !isNaN(Number(ServicesDE[key])))
        .map(a => {
          return {
            text: a,
            value: ServicesDE[a]
          }
        })
    } else if (this.appointmentsPopupService.country === 'ch') {
      this.services = Object.keys(ServicesCH)
        .filter(key => !isNaN(Number(ServicesCH[key])))
        .map(a => {
          return {
            text: a,
            value: ServicesCH[a]
          }
        })
    }

    this.appointmentsPopupService.sub.mileageSub =
      this.appointmentsPopupService.secondFormGroup.controls.mileage.valueChanges
        .pipe(delay(1), distinctUntilChanged())
        .subscribe(() => {
          this.appointmentsPopupService.secondFormGroup.controls.mileage.markAsTouched()
          this.appointmentsPopupService.secondFormGroup.controls.mileage.markAsDirty()
          this.appointmentsPopupService.secondFormGroup.controls.mileage.updateValueAndValidity()
        })

    if (this.appointmentsPopupService.reifenData?.serviceAction === true) {
      this.addService('RECALL')
    }
  }

  isChecked (service: string): boolean {
    if (
      this.appointmentsPopupService.selectedServices.find(
        s => s.toLowerCase() === service.toLowerCase()
      ) != null
    ) {
      return true
    }
  }

  addService (service: string): void {
    this.appointmentsPopupService.addService(service)
  }

  checkIfReifen (): void {
    this.appointmentsPopupService.checkIfReifen()
  }
}
