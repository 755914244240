/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import {
  KeycloakAuthGuard,
  KeycloakService
} from '@emilfreydigital/keycloak-angular'
import { Location } from '@angular/common'
import { RedirectService } from '../services/redirect.service'
import { B2CModuleConfig } from '../b2c.module'

@Injectable({
  providedIn: 'root'
})
export class B2cAuthGuard extends KeycloakAuthGuard  {
  constructor(
    protected override router: Router,
    protected override keycloakAngular: KeycloakService,
    protected location: Location,
    protected redirectService: RedirectService,
    private readonly config: B2CModuleConfig
  ) {
    super(router, keycloakAngular)
  }

  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      // if not authenticated redirect to login
      if (!this.authenticated) {
        this.keycloakAngular
          .login({
            redirectUri: window['cordova']
              ? this.config.cordovaLoginRedirectUri
              : window.location.origin +
                this.location.prepareExternalUrl(state.url)
          })
          .catch(e => console.error(e))
        return resolve(false)
      }

      /*
      const requiredRoles: string[] = route.data.roles;
      if (!requiredRoles || requiredRoles.length === 0) {
        return resolve(true);
      } else {
        if (!this.roles || this.roles.length === 0) {
          resolve(false);
        }
        resolve(requiredRoles.every(role => this.roles.indexOf(role) > -1));
      } */

      resolve(true)
    })
  }
}
