import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog'
import {
  User,
  UserAddress,
  UserService,
  UserUpdateAddressValidationError,
  isUserUpdateAddressValidationError
} from '@inside-hub-app/customer-portal-b2c-client'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { InstantErrorStateMatcherService } from '../../../../services/instant-error-state-matcher.service'
import { SharedService } from '../../../../services/shared.service'
import { DuplicateAddressPopupComponent } from '../duplicate-address-popup/duplicate-address-popup.component'
import { ConfirmationQuestionPopupComponent } from '../../../basic/confirmation-question-popup/confirmation-question-popup.component'

export interface UserAddressHint extends UserAddress {
  diff?: string[]
}

export interface UserAddressUpdateData {
  contactId: number
  email: string
  address: UserAddress[]
}

@Component({
  selector: 'customer-portal-app-revolution-address-change-popup',
  templateUrl: './address-change-popup.component.html'
})
export class RevolutionAddressChangePopupComponent implements OnInit {
  matcher = new InstantErrorStateMatcherService()
  address: UserAddress
  addressHints: UserAddressHint[]

  addressPattern: string
  addressOptPattern: string
  addressNumberPattern: string
  zipPattern: string
  cityPattern: string
  
  addressForm

  addSug = ''
  addOptSug = ''
  zipSug = 0
  citySug = ''
  addNumberSug = ''
  public loading: boolean
  disabled = false
  public errorUpdate: boolean
  public zip: number
  public forceSuggestion: boolean
  private add: UserAddress
  duplicateUser = false
  onSuccessCallback = 'addressChanged'
  countryCode: string
  constructor(
    private readonly dialogRef: MatDialogRef<RevolutionAddressChangePopupComponent>,
    private readonly duplicateAddressDialogref: MatDialogRef<DuplicateAddressPopupComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public user: User,
    private readonly userService: UserService,
    private readonly logger: NGXLogger,
    public sharedService: SharedService,
    private readonly transifexTranslationsService: TranslationService,
    public transifexService: TransifexService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.countryCode = this.remoteConfigService.get('country.code')

    this.addressPattern = this.remoteConfigService.get(
      'regEx.addressRegEx.address.pattern'
    )
  
    this.addressOptPattern = this.remoteConfigService.get(
      'regEx.addressRegEx.addressOpt.pattern'
    )
  
    this.addressNumberPattern = this.remoteConfigService.get(
      'regEx.addressRegEx.addressNumber.pattern'
    )
  
    this.zipPattern = this.remoteConfigService.get(
      'regEx.addressRegEx.zip.pattern'
    )
  
    this.cityPattern = this.remoteConfigService.get(
      'regEx.addressRegEx.city.pattern'
    )
  
    this.addressForm = new FormGroup({
      address: new FormControl<string | null>(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.addressPattern),
          Validators.maxLength(
            this.remoteConfigService.get('regEx.addressRegEx.address.maxLength')
          ),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)
        ])
      ),
      addressOpt: new FormControl<string | null>(
        null,
        Validators.compose([
          Validators.pattern(this.addressOptPattern),
          Validators.maxLength(
            this.remoteConfigService.get(
              'regEx.addressRegEx.addressOpt.maxLength'
            )
          ),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)
        ])
      ),
      zip: new FormControl<string | null>(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.zipPattern),
          Validators.minLength(
            this.remoteConfigService.get('regEx.addressRegEx.zip.minLength')
          ),
          Validators.maxLength(
            this.remoteConfigService.get('regEx.addressRegEx.zip.maxLength')
          )
        ])
      ),
      city: new FormControl<string | null>(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.cityPattern),
          Validators.maxLength(
            this.remoteConfigService.get('regEx.addressRegEx.city.maxLength')
          ),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)
        ])
      ),
      addressNumber: new FormControl<string | null>(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.addressNumberPattern),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)
        ])
      )
    })
  }

  public showSuggestions: boolean
  public streetNumCorrected = false

  ngOnInit(): void {
    this.load()
    this.showSuggestions = false
    this.errorUpdate = false

    this.zip = this.remoteConfigService.get('regEx.addressRegEx.zip.minLength')

    if (!this.addressForm.valid) {
      Object.keys(this.addressForm.controls).forEach(field => {
        const control = this.addressForm.get(field)
        control.markAsTouched({ onlySelf: true })
      })
      return
    }

    this.addressForm.valueChanges.subscribe(() => {
      this.disabled = false
      this.forceSuggestion = false
      this.duplicateUser = false
    })
  }

  load(): void {
    if (
      this.user?.address != null &&
      this.user?.address.find(a => a.type === 'home') == null
    ) {
      this.address = this.user.address[0]
    } else {
      this.address =
        this.user.address != null
          ? this.user.address.find(a => a.type === 'home')
          : null
    }
    this.logger.debug(this.user)
    this.loading = false

    this.setAddressFields()
  }

  setAddressFields(): void {
    if (this.address != null) {
      this.addressForm.controls.address.setValue(this.address.street)
      this.addressForm.controls.zip.setValue(this.address.zip)
      this.addressForm.controls.city.setValue(this.address.city)
      this.addressForm.controls.addressOpt.setValue(
        this.address.streetAdditional
      )
      this.addressForm.controls.addressNumber.setValue(
        this.address.streetNumber
      )
    }
  }

  updateAddress(): void {
    this.disabled = true
    this.loading = true

    const updateUserData: UserAddressUpdateData = {
      address: this.user.address,
      contactId: this.user.contactId,
      email: this.user.email
    }
    if (updateUserData.address?.[0] != null) {
      updateUserData.address[0].street = this.addressForm.controls.address.value
      updateUserData.address[0].streetNumber =
        this.addressForm.controls.addressNumber.value
      updateUserData.address[0].zip = this.addressForm.controls.zip.value
      updateUserData.address[0].city = this.addressForm.controls.city.value
      updateUserData.address[0].streetAdditional =
        this.addressForm.controls.addressOpt.value
    } else {
      const addresses: UserAddress[] = []
      this.add = {
        city: this.addressForm.controls.city.value,
        country: this.countryCode.toUpperCase(),
        region: 'unk',
        streetAdditional:
          this.addressForm.controls.addressOpt != null
            ? this.addressForm.controls.addressOpt.value
            : '',
        streetNumber: this.addressForm.controls.addressNumber.value,
        type: 'home',
        zip: this.addressForm.controls.zip.value,
        street: this.addressForm.controls.address.value
      }
      addresses.push(this.add)
      updateUserData.address = addresses
    }

    this.userService
      .putUser(updateUserData, null, this.forceSuggestion)
      .subscribe(
        data => {
          this.logger.debug('Request is successful ', data)
          this.loading = false
          this.dialogRef.close(true)
        },
        error => {
          this.duplicateUser = false
          this.loading = false

          if (error.status === 500) {
            this.errorUpdate = true
          }

          if (error?.error?.code === 2005 || error?.error?.code === '2005') {
            this.duplicateUser = true
            const duplicateAddressConfirmationPopup = this.dialog.open(
              ConfirmationQuestionPopupComponent,
              {
                data: {
                  title:
                    'customerPortal.customer-portal.address.overwrite.warning',
                  text: 'customerPortal.customer-portal.address.overwrite.confirm',
                  cancel: 'shared.cancel',
                  save: 'shared.save',
                  saveButtonColor: 'warn',
                  preventCloseBeforeComplete: true
                },
                panelClass: 'mat-dialog-cpt'
              }
            )
            duplicateAddressConfirmationPopup.componentInstance.confirm.subscribe(
              () => {
                this.logger.log('Overwrite user')
                this.userService
                  .putUser(updateUserData, null, true)
                  .subscribe(() => {
                    duplicateAddressConfirmationPopup.close()
                    this.dialogRef.close(true)
                  })
              }
            )
            duplicateAddressConfirmationPopup.componentInstance.reject.subscribe(
              () => {
                duplicateAddressConfirmationPopup.close()
                this.dialogRef.close(true)
              }
            )
          }

          if (isUserUpdateAddressValidationError(error.error) === true) {
            this.addressHints = null
            const addressValidationError: UserUpdateAddressValidationError =
              error.error

            if (
              addressValidationError.fields != null &&
              addressValidationError.fields.length > 0 &&
              addressValidationError.fields.find(
                s => s.id === 'address-hints'
              ) != null
            ) {
              this.addressHints = addressValidationError.fields[0].addressHints

              this.addressHintsShowDiff(updateUserData?.address?.[0])
            }

            if (
              addressValidationError.fields != null &&
              addressValidationError.fields.length > 0
            ) {
              this.addSug = null
              this.addOptSug = null
              this.addNumberSug = null
              this.zipSug = null
              this.citySug = null
              this.showSuggestions = true
              const streetCorrection = addressValidationError.fields.find(
                f => f.id === 'street'
              )
              if (streetCorrection != null) {
                this.addSug = streetCorrection.correction
              }
              const streetAdditionalCorrection =
                addressValidationError.fields.find(
                  f => f.id === 'street-additional'
                )
              if (streetAdditionalCorrection != null) {
                this.addOptSug = streetAdditionalCorrection.correction
              }
              const streetNumberCorrection = addressValidationError.fields.find(
                f => f.id === 'street-number'
              )
              if (streetNumberCorrection != null) {
                this.addNumberSug = streetNumberCorrection.correction
                this.streetNumCorrected = true
                if (
                  streetNumberCorrection.correction == null &&
                  addressValidationError.fields.length === 1
                ) {
                  this.showSuggestions = false
                  this.forceSuggestion = true
                  this.disabled = false
                }
              }
              const zipCorrection = addressValidationError.fields.find(
                f => f.id === 'zip'
              )
              if (zipCorrection != null) {
                this.zipSug = Number(zipCorrection.correction)
              }
              const cityCorrection = addressValidationError.fields.find(
                f => f.id === 'city'
              )
              if (cityCorrection != null) {
                this.citySug = cityCorrection.correction
              }
            } else if (addressValidationError.fields == null) {
              this.forceSuggestion = true
              this.disabled = false
            }
          }
        }
      )
  }

  addressHintsShowDiff(formAddress: UserAddress): void {
    if (this.addressHints?.[0] != null && formAddress != null) {
      this.addressHints.forEach(hint => {
        hint.diff = []
        for (const key in hint) {
          if (Object.prototype.hasOwnProperty.call(hint, key) === true) {
            this.areEqual(hint, formAddress, key)
          }
        }
      })
    }
  }

  areEqual(hint: UserAddressHint, formAddress: UserAddress, key: string): void {
    const string1: string = hint[key]
    const string2: string = formAddress[key]
    if (typeof string1 === 'string' && string1?.trim() !== string2?.trim()) {
      hint.diff.push(key)
    }
  }

  existsInDiff(diffArray: string[], field: string): boolean {
    return !!diffArray.includes(field)
  }

  changeAddressFields(): void {
    this.showSuggestions = false
    this.addressForm.controls.address.setValue(
      this.addSug != null
        ? this.addSug
        : this.address?.street != null
        ? this.address.street
        : this.add.street
    )
    this.addressForm.controls.zip.setValue(
      this.zipSug != null
        ? String(this.zipSug)
        : this.address?.zip != null
        ? this.address.zip
        : this.add.zip
    )
    this.addressForm.controls.city.setValue(
      this.citySug != null
        ? this.citySug
        : this.address?.city != null
        ? this.address.city
        : this.add.city
    )
    this.addressForm.controls.addressOpt.setValue(
      this.addOptSug != null
        ? this.addOptSug
        : this.address?.streetAdditional != null
        ? this.address.streetAdditional
        : this.add?.streetAdditional != null
        ? this.add.streetAdditional
        : ''
    )
    this.addressForm.controls.addressNumber.setValue(
      this.addNumberSug != null
        ? this.addNumberSug
        : this.address?.streetNumber != null
        ? this.address.streetNumber
        : this.add.streetNumber
    )
    this.disabled = false
  }

  cleanErrors(): void {
    this.disabled = false
    this.forceSuggestion = false
    this.duplicateUser = false
  }

  useAddressHint(address: UserAddress): void {
    this.showSuggestions = false
    this.addressForm.controls.address.setValue(
      address != null
        ? address.street
        : this.address?.street != null
        ? this.address.street
        : this.add.street
    )
    this.addressForm.controls.zip.setValue(
      address != null
        ? address.zip
        : this.address?.zip != null
        ? this.address.zip
        : this.add.zip
    )
    this.addressForm.controls.city.setValue(
      address != null
        ? address.city
        : this.address?.city != null
        ? this.address.city
        : this.add.city
    )
    this.addressForm.controls.addressOpt.setValue(
      address != null
        ? address.streetAdditional
        : this.address?.streetAdditional != null
        ? this.address.streetAdditional
        : this.add?.streetAdditional != null
        ? this.add.streetAdditional
        : ''
    )
    this.addressForm.controls.addressNumber.setValue(
      address != null
        ? address.streetNumber
        : this.address?.streetNumber != null
        ? this.address.streetNumber
        : this.add.streetNumber
    )
    this.disabled = false
  }

  getTranslation(): string {
    return this.transifexTranslationsService.translate(
      'Unfortunately, the entered data is not valid. Please check your entries.',
      {
        _key: 'customerPortal.customer-portal.address-save.duplicate-user.error',
        _tags: 'customer-portal, 3.1'
      }
    )
  }
}
