<div class="cpt-modal SelectOptionsPopupComponent-dialog relative ef-scrollbar">

  <mat-dialog-content class="max-h-[calc(100%-80px)] overflow-hidden pt-8 pb-0">
    <form [formGroup]="form" *ngIf="showFilter">
      <mat-form-field class="w-full relative">
        <input class="max-w-[calc(100%_-_60px)] px-6 my-1" type="text" matInput formControlName="filter" />
        <mat-icon class="absolute left-0 top-3 cursor-pointer text-mat-ef-revolution-primary-500" svgIcon="search">
        </mat-icon>
        <mat-icon class="absolute right-0 top-3 cursor-pointer text-mat-ef-revolution-primary-500"
          (click)="clearFilter()" svgIcon="close">
        </mat-icon>
      </mat-form-field>
    </form>
    <ng-container *ngIf="data?.type==='dealer'">
      <div class="w-full h-[calc(100%-65px)] overflow-y-auto cursor-pointer font-normal leading-5" cdkScrollable>
        <div (click)="selected = data?.salesDealer" class="py-2 px-1 border-b border-current/10"
          *ngIf="data.dealerType==='sales' && data?.salesDealer?.id" [ngClass]="{
        'bg-mat-ef-revolution-secondary-50':selected?.id === data?.salesDealer?.id
      }">
          {{ sharedService.formatDealerName(data?.salesDealer) }}
          ({{ 'preffered-dealer' | sharedTranslate }})
        </div>
        <div (click)="selected = data.dealerType" class="py-2 px-1 border-b border-current/10"
          *ngIf="data.dealerType==='service' && data?.serviceDealer?.id" [ngClass]="{
        'bg-mat-ef-revolution-secondary-50':selected?.id ===data?.serviceDealer?.id
      }">
          {{ sharedService.formatDealerName(data?.serviceDealer) }}
          ({{ 'preffered-dealer' | sharedTranslate }})
        </div>
        <div *ngFor="let d of array" class="py-2 px-1 border-b border-current/10" [ngClass]="{
        'bg-mat-ef-revolution-secondary-50':selected?.id === d.id
      }" (click)="selected = d">
          {{sharedService.formatDealerName(d)}}
        </div>
      </div>
    </ng-container>
  </mat-dialog-content>

  <button class="dialog-close-button" [mat-dialog-close]="false">
    <mat-icon svgIcon="close"></mat-icon>
  </button>

  <mat-dialog-actions>
    <button mat-button mat-stroked-button color="secondary" class="revolution btn-dialog" cdkFocusInitial
      (click)="onSubmit()">
      <mat-icon svgIcon="arrow_left"></mat-icon>
      {{ 'back' | sharedTranslate }}
    </button>
  </mat-dialog-actions>
</div>