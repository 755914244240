<div class="container select-none">
  <div class="flex flex-col md:flex-row gap-6">
    <mat-card
      class="flex flex-col gap-3 items-center justify-between rounded-md bg-mat-ef-revolution-grey-500"
      [ngClass]="{
        'flex-1 md:max-w-[calc(50%_-_12px)] lg:flex-row ': hasFAQ,
        'md:flex-row md:gap-0 w-full lg:w-3/5 m-auto': !hasFAQ
      }"
    >
      <div class="w-full">
        <div
          class="w-full flex font-efSans text-xl font-black text-mat-ef-revolution-primary-500 mb-1"
        >
          <T
            str="Customer portal support"
            key="customerPortal.customer-portal.support.title"
            tags="customer-portal, 3.1"
          ></T>
        </div>
        <!-- prettier-ignore -->
        <div
          class="text-sm text-efSans text-mat-ef-revolution-primary-500"
          [innerHTML]="getTranslation()"
        ></div>
      </div>

      <div
        class="btn-rounded-btn lg:w-fit"
        (click)="
          openMailClient();
          sendContentModulesCTAData(
            'customerPortal.customer-portal.support.contact'
          )
        "
      >
        <T
          str="Contact us"
          key="customerPortal.customer-portal.support.contact"
          tags="customer-portal, 3.1"
        ></T>
      </div>
    </mat-card>

    <customer-portal-app-faq-card
      *ngIf="hasFAQ"
      class="flex-1 md:max-w-[calc(50%_-_12px)]"
    ></customer-portal-app-faq-card>
  </div>
</div>
