/* eslint-disable @typescript-eslint/dot-notation */
import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import * as Sentry from '@sentry/angular-ivy'
import { Integrations } from '@sentry/tracing'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'

const remoteConfig = (window as any).ef.remoteConfig
  .configuration as CustomerPortalConfig

if (remoteConfig.production) {
  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch(err => console.error(err))

// insert analytics script
const script = document.createElement('script')
script.src =
  'https://www.googletagmanager.com/gtag/js?id=' +
  remoteConfig['googleAnalytics.id']
script.async = true
document.head.prepend(script)

// Sentry
Sentry.init({
  debug: false,
  dsn: remoteConfig['sentry.dsn'],
  environment: remoteConfig.environmentName,
  ignoreErrors: ['Non-Error exception captured'],
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: remoteConfig['sentry.performanceTracing.tracingOrigins'],
      routingInstrumentation: Sentry.routingInstrumentation
    })
  ],
  tracesSampleRate: remoteConfig['sentry.tracesSampleRate']
})
