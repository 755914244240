/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpRequest,
  JsonpInterceptor
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable()
export class CptJsonpInterceptor extends JsonpInterceptor {
  override intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request?.method === 'JSONP') {
      request = request.clone({
        headers: new HttpHeaders()
      })
    }

    return next.handle(request)
  }
}

// CPEFG-3457
