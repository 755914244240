<div class="DealerHoursComponent w-full">
  <mat-expansion-panel
    #info
    hideToggle="true"
    [expanded]="expanded"
    (afterExpand)="
      sendContentModulesData(
        true,
        openServices || openSales
          ? 'customerPortal.customer-portal.vehicle.nowOpen'
          : 'customerPortal.customer-portal.vehicle.nowClosed'
      )
    "
    (afterCollapse)="
      sendContentModulesData(
        false,
        openServices || openSales
          ? 'customerPortal.customer-portal.vehicle.nowOpen'
          : 'customerPortal.customer-portal.vehicle.nowClosed'
      )
    "
    class="!shadow-none"
  >
    <mat-expansion-panel-header
      class="!p-0 h-fit w-fit !bg-mat-ef-revolution-primary-contrast-500"
    >
      <mat-panel-title class="text-sm !mr-2 !p-0">
        <div class="flex items-center" *ngIf="openServices || openSales">
          <span class="font-efSans font-medium text-xs text-green-500">
            <T
              str="Now open"
              key="customerPortal.customer-portal.vehicle.nowOpen"
              tags="customer-portal, 3.1"
            ></T
          ></span>
          <mat-icon
            svgIcon="keyboard_arrow_down"
            *ngIf="!info?.expanded"
            class="w-3.5 h-3.5 text-mat-ef-revolution-primary-500"
          ></mat-icon>
          <mat-icon
            svgIcon="keyboard_arrow_up"
            *ngIf="info?.expanded"
            class="w-3.5 h-3.5 text-mat-ef-revolution-primary-500"
          >
          </mat-icon>
        </div>

        <div class="flex items-center" *ngIf="!(openServices || openSales)">
          <span class="font-efSans font-medium text-xs text-red-500">
            <T
              str="Now closed"
              key="customerPortal.customer-portal.vehicle.nowClosed"
              tags="customer-portal, 3.1"
            ></T
          ></span>
          <mat-icon
            svgIcon="keyboard_arrow_down"
            *ngIf="!info?.expanded"
            class="w-3.5 h-3.5 text-mat-ef-revolution-primary-500"
          ></mat-icon>
          <mat-icon
            svgIcon="keyboard_arrow_up"
            *ngIf="info?.expanded"
            class="w-3.5 h-3.5 text-mat-ef-revolution-primary-500"
          >
          </mat-icon>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="flex w-full border-t border-grey-100 mt-2 pt-2 gap-6">
      <div class="flex gap-8 w-full">
        <div class="">
          <div class="text-sm text-mat-ef-revolution-primary-500 font-bold">
            {{ 'sale' | sharedTranslate }}
          </div>
          <div class="text-xs py-2 text-mat-ef-revolution-primary-500">
            <ng-container
              *ngTemplateOutlet="
                hours;
                context: { data: workingDaysSalesGrouped }
              "
            >
            </ng-container>
          </div>
        </div>

        <div class="">
          <div class="text-sm text-mat-ef-revolution-primary-500 font-bold">
            {{ 'service' | sharedTranslate }}
          </div>
          <div class="text-xs py-2 text-mat-ef-revolution-primary-500">
            <ng-container
              *ngTemplateOutlet="
                hours;
                context: { data: workingDaysServiceGrouped }
              "
            >
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>

<ng-template #hours let-hours="data">
  <div *ngFor="let hour of hours | keyvalue: originalOrder">
    <span *ngFor="let day of $any(hour).value; let last = last">
      <span class="font-bold">
        <span class="inline-flex">
          <ng-container
            *ngTemplateOutlet="
              sharedService.template('transifexTranslation');
              context: {
                str: day.day,
                prefix: 'customerPortal.customer-portal.dealer-details.days.',
                suffix: '.abbr'
              }
            "
          ></ng-container>
        </span>
        <span>{{ last ? ':' : ', ' }}</span>
      </span>

      <div class="font-normal" *ngIf="last">
        <div *ngIf="day.hours.length === 1 && day.hours[0] !== ''">
          {{ day.hours[0] }}
          <T
            str="Clock"
            key="customerPortal.customer-portal.dealers.time"
            tags="customer-portal, 3.1"
          ></T>
        </div>
        <div
          *ngIf="
            day.hours.length > 1 && day.hours[0] !== '' && day.hours[1] !== ''
          "
        >
          {{ day.hours[0] }}
          <T
            str="Clock"
            key="customerPortal.customer-portal.dealers.time"
            tags="customer-portal, 3.1"
          ></T>
        </div>
        <div></div>
        <div
          *ngIf="
            day.hours.length > 1 && day.hours[0] !== '' && day.hours[1] !== ''
          "
        >
          {{ day.hours[1] }}

          <T
            str="Clock"
            key="customerPortal.customer-portal.dealers.time"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </div>
    </span>
  </div>
</ng-template>
