import { Component, Input, type OnDestroy, type OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import {
  type CustomerPortalConfig,
  type TrackStep
} from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import {
  AppointmentsService,
  type UpcomingAppointmentDTOExtended
} from '../../../../services/appointments.service'
import { DataService } from '../../../../services/data.service'
import { SharedService } from '../../../../services/shared.service'
import {
  type VehicleDTOExtended,
  VehiclesService
} from '../../../../services/vehicles.service'
import { AppointmentsPopupComponent } from '../../../revolution/appointments-popup/appointments-popup.component'
import { LocalStorageService } from '../../../../services/local-storage.service'
@Component({
  selector: 'customer-portal-app-revolution-upcoming-appointments',
  templateUrl: './revolution-upcoming-appointments.component.html'
})
export class RevolutionUpcomingAppointmentsComponent
implements OnInit, OnDestroy {
  @Input()
    upcomingAppointments: UpcomingAppointmentDTOExtended[] = []

  @Input() loading = true

  stepList

  vehicles: VehicleDTOExtended[] = []
  hasAppointments: boolean
  hasLiveStatusAppointments = false
  licensePlateMask

  sub = {
    onUserVehiclesLoaded: null,
    onLocalStorageDataChanged: null
  }

  anyAppointmentHasLiveStatus = false

  constructor (
    public dialog: MatDialog,
    private readonly vehiclesService: VehiclesService,
    private readonly logger: NGXLogger,
    public appointmentsService: AppointmentsService,
    private readonly dataService: DataService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public readonly sharedService: SharedService,
    private readonly localStorage: LocalStorageService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.stepList = this.remoteConfigService.getArrayValues<TrackStep[]>('liveAppointmentSteps')
  }

  ngOnInit (): void {
    this.hasAppointments = this.remoteConfigService.get('hasAppointments')
    this.hasLiveStatusAppointments = this.remoteConfigService.get(
      'hasLiveStatusAppointments'
    )
    this.licensePlateMask = this.sharedService.setLicensePlateMask()
    this.dataService.onUpcomingAppointmentsLoaded.subscribe((appointments) => {
      this.upcomingAppointments = appointments
      this.sub.onUserVehiclesLoaded =
      this.dataService.onUserVehiclesLoaded.subscribe(data => {
        this.vehicles = data
        this.appointmentsService.setImg(
          this.vehicles,
          this.upcomingAppointments
        )
      })
      this.appointmentsService.setImg(this.vehicles, this.upcomingAppointments)
      this.getDetails()
      this.anyAppointmentHasLiveStatus = this.checkAnyAppointmentHasLiveStatus(this.upcomingAppointments)
    })
    this.sub.onLocalStorageDataChanged =
    this.localStorage.onLocalStorageDataChanged.subscribe(data => {
      this.showDataIsChangedToast(data)
    })
  }

  checkAnyAppointmentHasLiveStatus (upcomingAppointments: UpcomingAppointmentDTOExtended[]): boolean {
    let anyOfAppointmentsHasLiveStatus = false
    if (upcomingAppointments?.length > 0) {
      upcomingAppointments.forEach((a) => {
        if (a.status) {
          anyOfAppointmentsHasLiveStatus = true
        }
      })
      return (anyOfAppointmentsHasLiveStatus)
    } else {
      return (false)
    }
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  checkAppointment (appointment: UpcomingAppointmentDTOExtended, ignoreTime?: boolean): boolean {
    return this.sharedService.checkAppointment(appointment, ignoreTime)
  }

  sendContentModulesData (expanded: boolean, contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Accordion',
      expanded ? 'Open' : 'Close',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Accordion|${this.sharedService.translateLink(contentModuleLabel) ?? ''}`,
      expanded ? 'Open' : 'Close'
    )
  }

  sendContentModulesCTAData (contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }

  getDetails (): void {
    if (this.upcomingAppointments?.[0] != null) {
      this.upcomingAppointments.forEach(appointment => {
        if (
          appointment.salesAdvisor == null &&
          appointment.salesmanUser == null &&
          !(appointment.requested === true)
        ) {
          this.getAppointmentDetails(appointment)
        }
      })
    }
  }

  getAppointmentDetails (appointment: UpcomingAppointmentDTOExtended): void {
    if (
      this.sharedService.stringExists(appointment.vin) &&
      appointment.id != null
    ) {
      appointment.loadingDetails = true
      this.appointmentsService
        .getUpcomingVehicleAppointment(appointment.vin, appointment.id)
        .subscribe(
          appointmentDetails => {
            appointment.loadingDetails = false
            appointment.salesAdvisor = appointmentDetails.dealer.salesAdvisor
          },
          error => {
            this.logger.debug(error)
            appointment.loadingDetails = false
          }
        )
    }
  }

  newAppointment (): void {
    this.appointmentsService.newAppointment(AppointmentsPopupComponent)
  }

  showDataIsChangedToast (changed: string): void {
    // dont show notice if dialog or snackbar is open
    if (!this.dialogIsOpen() && this.sharedService.getSnackbarRef() == null) {
      if (changed === 'upcomingAppointments') {
        this.sharedService.showSnackbar(
          'customerPortal.customer-portal.data-changed',
          null
        )
      }
    }
  }

  dialogIsOpen (): boolean {
    return Boolean(this.dialog?.openDialogs?.length > 0)
  }
}
