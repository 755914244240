import { Injectable } from '@angular/core'
import * as idb from 'idb-keyval'
import { Observable, defer } from 'rxjs'

/**
 * A service which provides a wrapper around idb-keyval, using a custom defined store to keep back-compatibility.
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  // we set our own db name and store name, to remain compatible with the old implementation
  private readonly store: idb.UseStore = idb.createStore('ngStorage', 'localStorage')

  constructor () {
    // move all values from localstorage to idb
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      const value = localStorage.getItem(key)
      try {
        const jsonValue = JSON.parse(value)
        this.set(key, jsonValue).subscribe()
      } catch (e) {
        this.set(key, value).subscribe()
      }
    }

    // we could clear localstorage, but others are still using localstorage
  }

  /**
   * Get a value by it's key. Includes a fallback to localstorage values.
   * @param key
   * @returns An observable with the stored value.
   */
  get<T> (key: IDBValidKey): Observable<T> {
    return defer(async () => await idb.get(key, this.store))
  }

  /**
   * Set a value with a key. Take care because this is a cold observable, which will fire only when you subscribe.
   * @param key
   * @param value
   * @returns An observable which emits when the value is stored.
   * @example set('my-key', { name: 'test' }).subscribe()
   */
  set<T> (key: IDBValidKey, value: T): Observable<void> {
    return defer(async () => await idb.set(key, value, this.store))
  }

  /**
   * Deletes a particular key within the store. Take care because this is a cold observable, which will fire only when you subscribe.
   * @param key
   * @returns An observable which emits when the key is deleted.
   * @example delete('my-key').subscribe()
   */
  delete (key: IDBValidKey): Observable<void> {
    return defer(async () => await idb.del(key, this.store))
  }

  /**
   * Clear all values in the store. Take care because this is a cold observable, which will fire only when you subscribe.
   * @returns An observable which emits when the store is cleared.
   * @example clear().subscribe()
   */
  clear (): Observable<void> {
    return defer(async () => await idb.clear(this.store))
  }

  /**
   * Get all keys in the store.
   * @returns An observable which emits all keys present in the store.
   */
  keys (): Observable<IDBValidKey[]> {
    return defer(async () => await idb.keys(this.store))
  }
}
