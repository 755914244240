import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, type Observable, of, Subject } from 'rxjs'
import { SharedService, type GenericObject } from './shared.service'
import { HashingService } from './hashing.service'
import { TokenService } from './token.service'
import { type hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { NGXLogger } from 'ngx-logger'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { ConfirmationQuestionPopupComponent } from '../components/basic/confirmation-question-popup/confirmation-question-popup.component'
import { MatDialog } from '@angular/material/dialog'
import { ConfirmationPopupComponent } from '../components/basic/confirmation-popup/confirmation-popup.component'
// import { DOCUMENTS } from '../mock/documents'

export type VehicleDocumentTypeEnum =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.VehicleDocumentTypeEnum
export type VehicleGalleryTypeEnum =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.VehicleGalleryTypeEnum

type BasicDocumentDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.BasicDocumentDTO
export type ContactDocumentFormatEnum =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.ContactDocumentFormatEnum

export type SaleContractFeedbackDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.documents.dto.SaleContractFeedbackDTO

export interface BasicDocumentDTOExtended extends BasicDocumentDTO {
  preview?: string
  type?: VehicleDocumentTypeEnum | VehicleGalleryTypeEnum
  showMenu?: boolean
}

interface UpdateDocumentPayload {
  name: string
  type: VehicleDocumentTypeEnum | VehicleGalleryTypeEnum
}

export type ServiceDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.ServiceDTO

type VehiclePromotionDocumentDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.VehiclePromotionDocumentDTO
export interface VehiclePromotionDocumentDTOExtended
  extends VehiclePromotionDocumentDTO {
  preview?: string
  format?: string
}

export interface DocumentType {
  key: string
  value: VehicleDocumentTypeEnum | VehicleGalleryTypeEnum
  acceptedTypes: string
  readOnly: boolean
  multiple?: boolean
}

export type ServiceHistoryPageDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.ServiceHistoryPageDTO

@Injectable({
  providedIn: 'root'
})
export class VehicleDocumentsService {
  private readonly apiUrl

  private readonly documentTypes: DocumentType[] = [
    {
      key: 'customerPortal.customer-portal.vehicle.documents-upload.art-all',
      value: null,
      acceptedTypes: 'image,pdf',
      readOnly: true,
      multiple: false
    },
    {
      key: 'customerPortal.customer-portal.leasing_contract',
      value: 'LEASING_CONTRACT',
      acceptedTypes: 'pdf',
      readOnly: false,
      multiple: true
    },
    {
      key: 'customerPortal.customer-portal.vehicle.documents-upload.art-acc-registration',
      value: 'REGISTRATION',
      acceptedTypes: 'image,pdf',
      readOnly: false,
      multiple: true
    },
    {
      key: 'customerPortal.customer-portal.vehicle.documents-upload.art-acc-guarantee',
      value: 'WARRANTY',
      acceptedTypes: 'image,pdf',
      readOnly: false,
      multiple: true
    },
    {
      key: 'customerPortal.customer-portal.vehicle.documents-upload.art-acc-insurance',
      value: 'INSURANCE',
      acceptedTypes: 'image,pdf',
      readOnly: false,
      multiple: true
    },
    {
      key: 'customerPortal.customer-portal.vehicle.documents-upload.art-acc-user-guide',
      value: 'USER_MANUAL',
      acceptedTypes: 'pdf',
      readOnly: false,
      multiple: true
    },
    {
      key: 'customerPortal.customer-portal.vehicle.documents-upload.art-acc-offers',
      value: 'OFFER',
      acceptedTypes: 'pdf',
      readOnly: true,
      multiple: true
    },
    {
      key: 'customerPortal.customer-portal.vehicle.documents-upload.art-acc-offers',
      value: 'SALES_OFFER',
      acceptedTypes: 'image,pdf',
      readOnly: true,
      multiple: false
    },
    {
      key: 'customerPortal.customer-portal.vehicle.documents-upload.art-acc-contracts',
      value: 'CONTRACT',
      acceptedTypes: 'pdf',
      readOnly: true,
      multiple: true
    },
    {
      key: 'customerPortal.customer-portal.vehicle.documents-upload.art-acc-contracts',
      value: 'SALES_CONTRACT',
      acceptedTypes: 'image,pdf',
      readOnly: true,
      multiple: false
    },
    {
      key: 'customerPortal.customer-portal.invoice',
      value: 'INVOICE',
      acceptedTypes: 'pdf',
      readOnly: true,
      multiple: true
    },
    {
      key: 'shared.service',
      value: 'SERVICE',
      acceptedTypes: 'image,pdf',
      readOnly: true,
      multiple: true
    }
  ]

  downoladStarted = new BehaviorSubject<boolean>(false)
  public download: Observable<boolean>

  _unreadMyDocuments = new BehaviorSubject<number>(0)
  onUnreadMyDocuments = this._unreadMyDocuments.asObservable()

  _unreadVehicleDocuments = new BehaviorSubject<number>(0)
  onUnreadVehicleDocuments = this._unreadVehicleDocuments.asObservable()

  private readonly _reloadAfterMarkAsRead = new Subject<string>()
  onReloadAfterMarkAsRead = this._reloadAfterMarkAsRead.asObservable()

  showLoadingDocumentPopup_timeout
  showLoadingDocumentPopup_dialogRef
  previewSub
  constructor (
    private readonly http: HttpClient,
    private readonly hashingService: HashingService,
    private readonly tokenService: TokenService,
    private readonly sharedService: SharedService,
    private readonly logger: NGXLogger,
    private readonly dialog: MatDialog,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.download = this.downoladStarted.asObservable()
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  /**
   * Returns list of sales documents for a vehicle
   * @param vin number Vehicle VIN number
   * @returns BasicDocumentDTOExtended[]
   */
  public getDocuments (
    vin?: string,
    myDocumentsView = false
  ): Observable<BasicDocumentDTOExtended[]> {
    let types
    if (myDocumentsView === true) {
      types =
        this.remoteConfigService.get('country.code') === 'de'
          ? ['OFFER', 'LEASING_CONTRACT', 'CONTRACT']
          : ['SALES_OFFER', 'SALES_CONTRACT', 'LEASING_CONTRACT']
    } else {
      types = [
        'REGISTRATION',
        'LEASING_CONTRACT',
        'CONTRACT',
        'OFFER',
        'WARRANTY',
        'INSURANCE',
        'USER_MANUAL',
        'SALES_CONTRACT',
        'SALES_OFFER'
      ]
    }

    let params = new HttpParams()
    if (this.sharedService.stringExists(vin)) {
      params = params.append('vin', vin)
    }

    types.forEach(typeEl => {
      params = params.append('type', typeEl)
    })

    return this.http.get<BasicDocumentDTOExtended[]>(
      this.apiUrl + '/vehicle/documents',
      {
        params
      }
    )
  }

  public getInvoices (): Observable<BasicDocumentDTOExtended[]> {
    // return of(DOCUMENTS)
    return this.http.get<BasicDocumentDTOExtended[]>(
      this.apiUrl + '/vehicle/documents/invoices'
    )
  }

  public documentRead (
    documentId: string,
    type: VehicleDocumentTypeEnum | VehicleGalleryTypeEnum
  ): Observable<BasicDocumentDTOExtended> {
    return this.http.put<BasicDocumentDTOExtended>(
      this.apiUrl + '/vehicle/documents/' + documentId + '/read?type=' + type,
      {}
    )
  }

  filterOnlyDocument (document: BasicDocumentDTOExtended): boolean {
    return !(
      document?.type === 'SERVICE' ||
      document?.type === 'SALES_CONTRACT' ||
      document?.type === 'INVOICE' ||
      document?.type === 'LEASING_CONTRACT' ||
      document?.type === 'SALES_OFFER' ||
      document?.type === 'CONTRACT' ||
      document?.type === 'OFFER'
    )
  }

  filterOnlyDocumentSales (document: BasicDocumentDTOExtended): boolean {
    return (
      document?.type === 'SALES_CONTRACT' ||
      document?.type === 'INVOICE' ||
      document?.type === 'LEASING_CONTRACT' ||
      document?.type === 'SALES_OFFER' ||
      document?.type === 'CONTRACT' ||
      document?.type === 'OFFER'
    )
  }

  filterService (document: BasicDocumentDTOExtended): boolean {
    return document?.type === 'SERVICE'
  }

  /**
   * Saves a document for a vehicle
   *
   * @param vin number VIN number
   * @param type document type
   * @param name document name
   * @param description string document description
   * @param publicUrl string Document public url (set only for document types that need public access url, like gallery images).
   * @param mimeType string File mime type
   * @param file binary - file to upload
   */
  public saveDocument (
    vin: string,
    type: string,
    name: string,
    description: string,
    publicUrl: string,
    mimeType: string,
    file: Blob
  ): Observable<BasicDocumentDTOExtended> {
    const info = {
      name,
      description,
      type,
      publicUrl
    }

    const formData: FormData = new FormData()

    const json = new Blob([JSON.stringify(info)], {
      type: 'application/json'
    })
    formData.append('info', json)
    formData.append('mimeType', mimeType)
    formData.append('data', file)

    return this.http.post<BasicDocumentDTOExtended>(
      this.apiUrl + '/vehicle/documents/' + vin,
      formData
    )
  }

  /**
   * Update vehicle document attributes
   * @param vin Vehicle number
   * @param documentId number Document id
   * @param name string Document name
   * @param documentType string Document type
   *
   */
  public updateDocumentInfo (
    vin: string,
    documentId: string,
    documentName: string,
    documentType: VehicleGalleryTypeEnum | VehicleDocumentTypeEnum
  ): Observable<BasicDocumentDTOExtended> {
    const info: UpdateDocumentPayload = {
      name: documentName,
      type: documentType
    }

    const formData: FormData = new FormData()
    const json = new Blob([JSON.stringify(info)], {
      type: 'application/json'
    })
    formData.append('info', json)
    return this.http.put<BasicDocumentDTOExtended>(
      this.apiUrl + '/vehicle/documents/' + vin + '/' + documentId,
      formData
    )
  }

  /**
   * Deletes a vehicle document
   * @param documentId document id
   */
  public deleteVehicleDocument (documentId: string): Observable<GenericObject> {
    return this.http.delete<GenericObject>(
      `${this.apiUrl}/documents/${documentId}`
    )
  }

  public getDocumentTypes (): Observable<DocumentType[]> {
    return of(this.documentTypes)
  }

  public getVehiclePromotions (
    vin: string,
    lang: string
  ): Observable<VehiclePromotionDocumentDTOExtended[]> {
    let params = new HttpParams()
    params = params.append('lang', lang)
    params = params.append('type', 'promo%')
    return this.http.get<VehiclePromotionDocumentDTOExtended[]>(
      this.apiUrl + '/vehicle/' + vin + '/promotions',
      {
        params
      }
    )
  }

  public getDocumentSalesPDF (publicUrl: string): Observable<Blob> {
    return this.http.get(publicUrl, {
      responseType: 'blob'
    })
  }

  public getDocumentsPDF_request (docId: number | string): Observable<Blob> {
    return this.http.get(this.apiUrl + '/documents/' + String(docId), {
      responseType: 'blob'
    })
  }

  getDocumentsPDF (
    id: number | string,
    invNumber: string,
    mode: 'download' | 'open',
    event?: Event
  ): void {
    this.unsubPreview()
    this.showLoadingDocumentPopup()

    this.downoladStarted.next(true)
    if (event != null) {
      this.sharedService.preventEventPropagation(event)
    }
    if (id != null) {
      this.sharedService.showSnackbar(
        'customerPortal.customer-portal.please-wait-loading-data',
        null,
        0
      )
      this.previewSub = this.getDocumentsPDF_request(id).subscribe(
        response => {
          this.cancelLoadingDocumentPopup()
          this.sharedService.closeSnackbar()
          const preview = URL.createObjectURL(response)
          if (mode === 'open') {
            this.sharedService.openPreviewPopup(preview, 'pdf')
          } else {
            const name = this.sharedService.stringExists(invNumber)
              ? invNumber + '.pdf'
              : 'invoice.pdf'
            this.sharedService.downloadFile(preview, name)
          }
          this.downoladStarted.next(false)
        },
        () => {
          this.cancelLoadingDocumentPopup()
          this.sharedService.showSnackbar(
            'customerPortal.customer-portal.loading-data.error',
            null
          )
          this.downoladStarted.next(false)
        }
      )
    }
  }

  getInvoicePDF (
    id: number | string,
    invNumber: string,
    date,
    mode: 'download' | 'open',
    event?: Event
  ): void {
    this.unsubPreview()
    this.showLoadingDocumentPopup()

    this.downoladStarted.next(true)
    if (event != null) {
      this.sharedService.preventEventPropagation(event)
    }
    if (id != null) {
      this.sharedService.showSnackbar(
        'customerPortal.customer-portal.please-wait-loading-data',
        null,
        0
      )
      this.previewSub = this.getInvoicePDF_request(id, date).subscribe(
        response => {
          this.cancelLoadingDocumentPopup()
          this.sharedService.closeSnackbar()
          const preview = URL.createObjectURL(response)
          if (mode === 'open') {
            this.sharedService.openPreviewPopup(preview, 'pdf')
          } else {
            const name = this.sharedService.stringExists(invNumber)
              ? invNumber + '.pdf'
              : 'invoice.pdf'
            this.sharedService.downloadFile(preview, name)
          }
          this.downoladStarted.next(false)
        },
        () => {
          this.cancelLoadingDocumentPopup()
          this.sharedService.showSnackbar(
            'customerPortal.customer-portal.loading-data.error',
            null
          )
          this.downoladStarted.next(false)
        }
      )
    }
  }

  public getInvoicePDF_request (
    invoiceId: number | string,
    date?
  ): Observable<Blob> {
    let params = new HttpParams()
    if (this.sharedService.stringExists(date)) {
      params = params.append('date', date)
    }
    return this.http.get(
      this.apiUrl + '/documents/' + String(invoiceId) + '/invoice',
      {
        params,
        responseType: 'blob'
      }
    )
  }

  // unread vehicle documents
  unreadVehicleDocuments (documentNumber: number): void {
    this._unreadVehicleDocuments.next(documentNumber)
  }

  // unread my documents
  unreadMyDocuments (documentNumber: number): void {
    this._unreadMyDocuments.next(documentNumber)
  }

  unreadDocumentsCount (documents: BasicDocumentDTOExtended[]): number {
    return documents != null
      ? documents.filter(document => document.read !== true).length
      : 0
  }

  showServicePDFButtons (h: BasicDocumentDTOExtended): boolean {
    if (
      h?.code?.startsWith('F') === true ||
      h?.code?.startsWith('G') === true
    ) {
      return false
    }
    return true
  }

  // handle Offers
  public acceptDeclineOffer (
    body: SaleContractFeedbackDTO
  ): Observable<SaleContractFeedbackDTO> {
    return this.http.post<SaleContractFeedbackDTO>(
      this.apiUrl + '/documents/sale-contract-feedback',
      body
    )
  }

  public markAllDocumentsAsReadRequest (vin?: string): Observable<any> {
    let urlSuffix = ''
    if (this.sharedService.stringExists(vin)) {
      urlSuffix = '?vin=' + vin
    }
    return this.http.put<any>(
      this.apiUrl + '/vehicle/documents/read' + urlSuffix,
      {}
    )
  }

  markAllDocumentsAsRead (vin?: string): void {
    const dialogRef = this.dialog.open(ConfirmationQuestionPopupComponent, {
      data: {
        title: 'customerPortal.customer-portal.documents.mark-all-as-read',
        text: 'customerPortal.customer-portal.documents.mark-all-as-read.confirmation-text',
        cancel: 'shared.cancel',
        save: 'shared.accept',
        saveButtonColor: 'secondary',
        preventCloseBeforeComplete: true
      },
      panelClass: 'mat-dialog-cpt'
    })
    const sub = dialogRef.componentInstance.confirm.subscribe(() => {
      this.markAllDocumentsAsReadRequest(vin).subscribe(
        (data: any) => {
          dialogRef.close()
          this.dialog.open(ConfirmationPopupComponent, {
            data: {
              title: 'shared.successful',
              text: 'customerPortal.customer-portal.documents.mark-all-as-read.success'
            },
            panelClass: 'mat-dialog-cpt'
          })
          // reload data
          this._reloadAfterMarkAsRead.next(vin)
        },
        () => {
          dialogRef.close()
          this.dialog.open(ConfirmationPopupComponent, {
            data: {
              title: 'shared.error',
              text: 'shared.general-error-message'
            },
            panelClass: 'mat-dialog-cpt'
          })
        }
      )
    })
    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe()
    })
  }

  showLoadingDocumentPopup (): void {
    const showLoadingInvoicePopup = this.remoteConfigService.get('showLoadingInvoicePopup')
    // open dialog after timeout has passed
    const timeout = (this.remoteConfigService.get('showLoadingInvoicePopup.timeout') ?? 2000)

    // check if we should show dialog
    if (showLoadingInvoicePopup !== true) {
      return
    }

    this.cancelLoadingDocumentPopup()
    this.showLoadingDocumentPopup_timeout = setTimeout(() => {
      this.showLoadingDocumentPopup_dialogRef = this.dialog.open(
        ConfirmationQuestionPopupComponent,
        {
          data: {
            title:
              'customerPortal.customer-portal.documents.loading-invoice.title',
            text: 'customerPortal.customer-portal.documents.loading-invoice.text',
            save: 'shared.cancel',
            showTextSpinner: true
          },
          panelClass: 'mat-dialog-cpt'
        }
      )
      this.showLoadingDocumentPopup_dialogRef.afterClosed().subscribe(() => {
        // prevent preview from opening
        setTimeout(() => {
          this.unsubPreview()
        })
      })
    }, timeout)
  }

  cancelLoadingDocumentPopup (): void {
    try {
      clearTimeout(this.showLoadingDocumentPopup_timeout)
    } catch (error) {}
    try {
      this.showLoadingDocumentPopup_dialogRef.close()
    } catch (error) {}
  }

  unsubPreview (): void {
    try {
      this.previewSub.unsubscribe()
    } catch (error) {
      // no need to show log
    }
    this.sharedService.closeSnackbar()
    this.downoladStarted.next(false)
  }
}
