/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { BehaviorSubject, type Observable, of } from 'rxjs'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import moment from 'moment'
import { type hr as cpt } from 'efd-cpt-backend-interfaces-ts'
export type VehicleInsuranceDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.VehicleInsuranceDTO
export type VehicleInsurancePolicyDTO =
cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.VehicleInsurancePolicyDTO

export interface Expiration {
  days: number
  percentage: number
}

@Injectable({
  providedIn: 'root'
})
export class InsuranceService {
  // mock
  INSURANCE =
    {
      vin: 'U5YH5F15GNL098497',
      policies: [
        // {
        //   offerId: 209,
        //   policyNo: 'ECCALP',
        //   startDate: '2023-09-19',
        //   endDate: '2024-09-19'
        // },
        {
          token: '322c1eccd3da15ec8bed8be8b7040005',
          status: 'queued',
          name: 'Schutzbrief',
          requestDate: '2023-06-01',
          sendDate: '2023-11-08',
          sendBy: 'download',
          types: [
            'accident_cover'
          ]
        }
      ]
    }

  private readonly apiUrl

  private readonly _insurance = new BehaviorSubject<VehicleInsuranceDTO>(null)
  public onInsuranceLoaded = this._insurance.asObservable()

  public loadingInsurance = new BehaviorSubject<boolean>(true)
  constructor (
    private readonly http: HttpClient,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  public getInsurance (
    vin: string
  ): Observable<VehicleInsuranceDTO> {
    // return of(this.INSURANCE) as any // MOCK
    let params = new HttpParams()
    params = params.append('vin', vin)
    return this.http.get<VehicleInsuranceDTO>(this.apiUrl + '/vehicle/insurance', {
      params
    })
  }

  public getInsurancePDF (data: {
    token?: string
    offerId?: number
    docType?: string
    policyNumber?: string
  }): Observable<Blob> {
    let params = new HttpParams()
    if (data?.token != null) {
      params = params.append('token', data.token)
    }
    if (data?.offerId != null) {
      params = params.append('offerId', data.offerId)
    }
    if (data?.docType != null) {
      params = params.append('docType', data.docType)
    }
    if (data?.policyNumber != null) {
      params = params.append('policyNumber', data.policyNumber)
    }
    return this.http.get(this.apiUrl + '/vehicle/insurance/document', {
      responseType: 'blob',
      params
    })
  }

  public insuranceLoaded (insurance: VehicleInsuranceDTO): void {
    this._insurance.next(insurance)
  }

  calculateTillExpires (insurance: VehicleInsurancePolicyDTO): Expiration {
    const wStartDate = moment(insurance.startDate)
    const wTermDate = moment(insurance.endDate)
    const now = moment()
    const daysDiff = Math.round(wTermDate.diff(now, 'days', true))
    const duration = Math.round(wTermDate.diff(wStartDate, 'days', true))
    let percentage = Math.round((daysDiff / duration) * 100)
    percentage = percentage < 5 ? 5 : percentage
    return {
      days: daysDiff,
      percentage
    }
  }
}
