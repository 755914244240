import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient, HttpParams } from '@angular/common/http'
import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'

export type PreferenceDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.preferences.dto.PreferenceDTO
export type NewsletterDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.newsletter.dto.NewsletterDTO

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private readonly apiUrl

  constructor(
    private readonly http: HttpClient,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  getNotificationValues(): Observable<PreferenceDTO[]> {
    return this.http.get<PreferenceDTO[]>(this.apiUrl + '/preferences')
  }

  putNotifications(
    notifications: PreferenceDTO[]
  ): Observable<PreferenceDTO[]> {
    return this.http.put<PreferenceDTO[]>(
      this.apiUrl + '/preferences',
      notifications
    )
  }

  getNewsletter(): Observable<NewsletterDTO> {
    return this.http.get<NewsletterDTO>(this.apiUrl + '/newsletter/rapidmail')
  }

  postNewsletter(lang: string): Observable<NewsletterDTO> {
    let params = new HttpParams()
    params = params.append('lang', lang)
    return this.http.post<NewsletterDTO>(
      this.apiUrl + '/newsletter/rapidmail',
      {
        params
      }
    )
  }

  deleteNewsletter(): Observable<NewsletterDTO> {
    return this.http.delete<NewsletterDTO>(
      this.apiUrl + '/newsletter/rapidmail'
    )
  }
}
