<div
  class="cpt-modal PasswordChangePopupComponent-revolution-dialog relative dialog-content-p-0 mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="Update Password"
      key="customerPortal.customer-portal.details.change.password"
      tags="customer-portal, 3.1"
    ></T>
  </h2>
  <mat-dialog-content class="mat-typography py-2 px-6">
    <form class="form" [formGroup]="currentPasswordForm" autocomplete="off">
      <div class="grid mt-3">
        <mat-form-field appearance="legacy">
          <mat-label>
            <T
              str="Current password"
              key="customerPortal.customer-portal.details.current-password"
              tags="customer-portal, 3.1"
            ></T>
          </mat-label>
          <input
            matInput
            type="text"
            [ngClass]="{ hidePassword: !showCurrentPassword }"
            required
            formControlName="currentPass"
            autocomplete="off"
            name="currentPass"
          />
          <mat-icon
            svgIcon="visibility_on"
            class="cursor-pointer"
            matSuffix
            *ngIf="showCurrentPassword"
            (click)="toggleShowCurrentPassword()"
          >
          </mat-icon>
          <mat-icon
            svgIcon="visibility_off"
            class="cursor-pointer"
            matSuffix
            *ngIf="!showCurrentPassword"
            (click)="toggleShowCurrentPassword()"
          >
          </mat-icon>
          <mat-error *ngIf="currentPasswordForm.controls.currentPass.invalid">
            {{
              transifexService.getErrorTranslation(
                currentPasswordForm.controls.currentPass,
                'customerPortal.customer-portal.details.error.',
                {
                  num: 8,
                  errKey: {
                    length: 'min-password-length',
                    minlength: 'min-password-length',
                    lowercase: 'password-lowercase-required',
                    uppercase: 'password-uppercase-required',
                    numeric: 'password-number-required',
                    invalid: 'password-invalid'
                  }
                }
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
    <form class="form" [formGroup]="newPasswordForm" autocomplete="off">
      <div class="grid mt-3">
        <mat-form-field appearance="legacy">
          <mat-label>
            <T
              str="New password"
              key="customerPortal.customer-portal.details.new-password"
              tags="customer-portal, 3.1"
            ></T>
          </mat-label>
          <input
            matInput
            type="text"
            [ngClass]="{ hidePassword: !showNewPassword }"
            required
            formControlName="newPass"
            autocomplete="off"
            name="newPass"
          />
          <mat-icon
            svgIcon="visibility_on"
            class="cursor-pointer"
            matSuffix
            *ngIf="showNewPassword"
            (click)="toggleShowNewPassword()"
          >
          </mat-icon>
          <mat-icon
            svgIcon="visibility_off"
            class="cursor-pointer"
            matSuffix
            *ngIf="!showNewPassword"
            (click)="toggleShowNewPassword()"
          >
          </mat-icon>
          <mat-error *ngIf="newPasswordForm.controls.newPass.invalid">
            {{
              transifexService.getErrorTranslation(
                newPasswordForm.controls.newPass,
                'customerPortal.customer-portal.details.error.',
                {
                  num: 8,
                  errKey: {
                    length: 'min-password-length',
                    minlength: 'min-password-length',
                    lowercase: 'password-lowercase-required',
                    uppercase: 'password-uppercase-required',
                    numeric: 'password-number-required',
                    invalid: 'password-invalid'
                  }
                }
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="grid mt-3">
        <mat-form-field appearance="legacy">
          <mat-label>
            <T
              str="Repeat password"
              key="customerPortal.customer-portal.details.repeat-new-password"
              tags="customer-portal, 3.1"
            ></T>
          </mat-label>
          <input
            matInput
            type="text"
            [ngClass]="{ hidePassword: !showRepeatedPassword }"
            required
            [errorStateMatcher]="matcher"
            formControlName="repeatPass"
            autocomplete="off"
            name="repeatPass"
          />
          <mat-icon
            svgIcon="visibility_on"
            class="cursor-pointer"
            matSuffix
            *ngIf="showRepeatedPassword"
            (click)="toggleShowRepeatedPassword()"
          >
          </mat-icon>
          <mat-icon
            svgIcon="visibility_off"
            class="cursor-pointer"
            matSuffix
            *ngIf="!showRepeatedPassword"
            (click)="toggleShowRepeatedPassword()"
          >
          </mat-icon>
          <mat-error *ngIf="newPasswordForm.hasError('notsame')">
            {{ 'password-notsame' | sharedTranslate }}
          </mat-error>
          <mat-error
            *ngIf="
              newPasswordForm.controls.repeatPass.invalid &&
              !newPasswordForm.hasError('notsame')
            "
          >
            {{
              transifexService.getErrorTranslation(
                newPasswordForm.controls.repeatPass,
                'customerPortal.customer-portal.details.error.',
                {
                  num: 8,
                  errKey: {
                    length: 'min-password-length',
                    minlength: 'min-password-length',
                    lowercase: 'password-lowercase-required',
                    uppercase: 'password-uppercase-required',
                    numeric: 'password-number-required',
                    invalid: 'password-invalid'
                  }
                }
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <mat-divider class="form-divider"></mat-divider>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <ng-container>
      <button class="dialog-close-button" [mat-dialog-close]="false">
        <mat-icon svgIcon="close"></mat-icon>
      </button>

      <button
        mat-button
        mat-flat-button
        color="secondary"
        class="revolution btn-dialog"
        (click)="updatePassword()"
        cdkFocusInitial
        [disabled]="
          disabled || !currentPasswordForm.valid || !newPasswordForm.valid
        "
      >
        <mat-spinner *ngIf="disabled" diameter="20"></mat-spinner>

        {{ 'save' | sharedTranslate }}
      </button>
    </ng-container>
  </mat-dialog-actions>
</div>
