import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of, Subject } from 'rxjs'
import { SharedService, GenericObject } from './shared.service'
import { HashingService } from './hashing.service'
import { TokenService } from './token.service'
import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { DocumentType } from './vehicle-documents.service'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
export type VehicleGalleryDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.VehicleGalleryDTO

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  private readonly apiUrl

  private readonly imageTypes: DocumentType[] = [
    {
      key: 'customerPortal.customer-portal.vehicle.documents-upload.art-all',
      value: null,
      acceptedTypes: 'image',
      readOnly: true
    },
    {
      key: 'customerPortal.customer-portal.vehicle.documents-upload.art-acc-damage',
      value: 'VEHICLE_DAMAGE',
      acceptedTypes: 'image',
      readOnly: false
    },
    {
      key: 'customerPortal.customer-portal.vehicle.documents-upload.art-acc-car-pic',
      value: 'VEHICLE_IMAGE',
      acceptedTypes: 'image',
      readOnly: false
    }
  ]

  private readonly _gallery = new BehaviorSubject<VehicleGalleryDTO[]>([])
  onGalleryLoaded = this._gallery.asObservable()

  private readonly _vehicleProfilePictureChanged = new Subject<GenericObject>()
  onVehicleProfilePictureChanged =
    this._vehicleProfilePictureChanged.asObservable()
  constructor(
    private readonly http: HttpClient,
    private readonly hashingService: HashingService,
    private readonly tokenService: TokenService,
    private readonly sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  galleriesLoaded(galleries: VehicleGalleryDTO[]): void {
    this._gallery.next(galleries)
  }

  vehicleProfilePictureChanged(picture: GenericObject): void {
    this._vehicleProfilePictureChanged.next(picture)
  }

  /**
   * Returns list of images for a vehicle
   * @param vin number Vehicle VIN number
   * @returns VehicleGalleryDTO[]
   */
  public getGalleries(vin: string): Observable<VehicleGalleryDTO[]> {
    return this.http.get<VehicleGalleryDTO[]>(
      this.apiUrl + '/vehicle/gallery/' + vin
    )
  }

  /**
   * Saves a image for a vehicle
   *
   * @param vin number VIN number
   * @param type image type
   * @param name image name
   * @param description string image description
   * @param publicUrl string Document public url (set only for image types that need public access url, like gallery images).
   * @param mimeType string File mime type
   * @param file binary - file to upload
   */
  public saveGallery(
    vin: string,
    type: string,
    name: string,
    description: string,
    publicUrl: string,
    mimeType: string,
    file: Blob
  ): Observable<VehicleGalleryDTO> {
    const info = {
      name,
      description,
      type,
      publicUrl
    }

    const formData: FormData = new FormData()

    const json = new Blob([JSON.stringify(info)], {
      type: 'application/json'
    })
    formData.append('info', json)
    formData.append('mimeType', mimeType)
    formData.append('data', file)

    return this.http.post<VehicleGalleryDTO>(
      this.apiUrl + '/vehicle/gallery/' + vin,
      formData
    )
  }

  /**
   * Update vehicle image attributes
   * @param vin Vehicle number
   * @param galleryId number Document id
   * @param name string Document name
   * @param type string Document type
   *
   */
  public updateGalleryInfo(
    vin: string,
    galleryId: string,
    name: string
  ): Observable<VehicleGalleryDTO> {
    const info = {
      name
    }

    const formData: FormData = new FormData()
    const json = new Blob([JSON.stringify(info)], {
      type: 'application/json'
    })
    formData.append('info', json)
    return this.http.put<VehicleGalleryDTO>(
      this.apiUrl + '/vehicle/gallery/' + vin + '/' + galleryId,
      formData
    )
  }

  /**
   * Deletes a vehicle image
   * @param galleryId image id
   */
  public deleteGallery(galleryId: string): Observable<unknown> {
    return this.http.delete(`${this.apiUrl}/gallery/${galleryId}`)
  }

  public getImageTypes(): Observable<DocumentType[]> {
    return of(this.imageTypes)
  }

  public getDocumentSalesPDF(publicUrl: string): Observable<Blob> {
    return this.http.get(publicUrl, {
      responseType: 'blob'
    })
  }

  public addVehicleProfilePicture(vin: string, data): Observable<unknown> {
    return this.http.post(
      this.apiUrl + '/vehicle/gallery/' + vin + '/profile-picture',
      data
    )
  }

  public removeVehicleProfilePicture(vin: string): Observable<GenericObject> {
    return this.http.delete<GenericObject>(
      `${this.apiUrl}/vehicle/gallery/${vin}/profile-picture`
    )
  }
}
