import { Component } from '@angular/core'
import {
  type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
@Component({
  selector: 'customer-portal-app-revolution-my-documents-view',
  templateUrl: './my-documents-view.component.html'
})
export class RevolutionMyDocumentsViewComponent {
  public screenSize: SCREEN_SIZE
  public hasNewFooter

  constructor (
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasNewFooter = this.remoteConfigService.get('hasNewFooter')
  }
}
