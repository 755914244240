import { Pipe, PipeTransform } from '@angular/core'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import { BehaviorSubject } from 'rxjs'

@Pipe({ name: 'userinitials' })
export class UserInitialsPipe implements PipeTransform {
  transform(user: User): BehaviorSubject<string> {
    const initialsSubject = new BehaviorSubject('')
    let initials = ''
    if (user != null) {
      if (user.firstName != null && user.firstName.trim() !== '') {
        const name = user.firstName.charAt(0)
        const surname =
          user.lastName != null && user.lastName.trim() !== ''
            ? user.lastName.charAt(0)
            : ''
        initials = name + surname
        initialsSubject.next(initials)
      } else {
        initialsSubject.next('EF')
      }
    }
    return initialsSubject
  }
}
