import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'
@Component({
  selector: 'customer-portal-app-redirect-consents-popup',
  templateUrl: './redirect-consents-popup.component.html'
})
export class RedirectConsentsPopupComponent {
  user: User

  constructor(
    public transifexService: TransifexService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    if (data?.user != null) {
      this.user = data.user
    }
  }
}
