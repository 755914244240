import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Params, Router } from '@angular/router'
import {
  User,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { TranslateService } from '@ngx-translate/core'
import { take } from 'rxjs'
import { ParsedToken } from '../../../../models/keycloak'
import { DataService } from '../../../services/data.service'
import { SharedService, Tab } from '../../../services/shared.service'
@Component({
  selector: 'customer-portal-app-revolution-details-view',
  templateUrl: './details-view.component.html'
})
export class RevolutionDetailsViewComponent implements OnInit {
  private urlParams: Params

  constructor(
    private readonly keycloak: KeycloakService,
    private readonly userService: UserService,
    private readonly translateService: TranslateService,
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly dataService: DataService,
    private readonly route: ActivatedRoute,
    private readonly sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    route.params.subscribe(params => {
      this.urlParams = params
      this.switchRoute(this.urlParams)
    })
    this.hasNewFooter = this.remoteConfigService.get('hasNewFooter')
  }

  token: ParsedToken
  user: User
  loading = true
  currentTab: Tab
  public hasNewFooter
  translateLoaded: boolean

  ngOnInit(): void {
    this.token = this.keycloak.getKeycloakInstance().tokenParsed as ParsedToken
    this.translateService.onDefaultLangChange
      .pipe(take(1))
      .subscribe(() => (this.translateLoaded = true))
    this.load()
  }

  load(): void {
    this.loading = true
    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
      this.loading = false
      if (user != null) {
        if (
          this.sharedService.stringExists(this.user.profilePictureUrl) &&
          this.user.profilePictureUrl != null
        ) {
          this.user.profilePictureUrl = `${
            this.user.profilePictureUrl
          }${this.remoteConfigService.get(
            'b2c.apiKey'
          )}&ts=${new Date().getTime()}`
        }
      }
    })
  }

  switchRoute(urlParams: Params): void {
    if (this.sharedService.stringExists(urlParams.detailsSubmenu)) {
      const param = this.urlParams.detailsSubmenu
      switch (param) {
        case 'dataprotection':
          this.currentTab = 'dataprotection'
          break
        case 'userDetails':
          this.currentTab = 'userDetails'
          break
        case 'userPersonalization':
          this.currentTab = 'userPersonalization'
          break
        case 'userCommunication':
          this.currentTab = 'userCommunication'
          break
        case 'userNotifications':
          this.currentTab = 'userNotifications'
          break
      }
    }
  }
}
