import { Inject, Injectable } from '@angular/core'
import { Environment, ExcludedUrlRegex } from '../environments'
import { ENVIRONMENT } from '../injection-tokens'

@Injectable({
  providedIn: 'root'
})
export class PpAuthInterceptorConfigurationService {
  private readonly _authorizationExcludedUrls: ExcludedUrlRegex[] = []
  public get authorizationExcludedUrls (): ExcludedUrlRegex[] {
    return this._authorizationExcludedUrls
  }

  constructor (@Inject(ENVIRONMENT) protected environment: Environment) {
    this.authorizationExcludedUrls.push(
      ...environment.authorizationExcludedUrls
    )
  }

  public add (...url: ExcludedUrlRegex[]) {
    this._authorizationExcludedUrls.push(...url)
  }
}
