import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGoogleTagmanagerService,
  EcondaService,
  MatomoIntegrationService,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { LocalStorageService } from '../../../../services/local-storage.service'
import { PersonalizationService } from '../../../../services/personalization.service'
import { SharedService } from '../../../../services/shared.service'
import {
  BasicDocumentDTOExtended,
  VehicleDocumentsService
} from '../../../../services/vehicle-documents.service'
import {
  FrequentDriverDialogData,
  VehicleDTOExtended,
  VehicleFrequentDriverDTO,
  VehiclesService
} from '../../../../services/vehicles.service'
import { ConfirmationPopupComponent } from '../../../basic/confirmation-popup/confirmation-popup.component'
import { ConfirmationQuestionPopupComponent } from '../../../basic/confirmation-question-popup/confirmation-question-popup.component'
import { cptMenuAction } from '../../../basic/cpt-menu/cpt-menu.component'
import { EditFirstRegistrationComponent } from '../../../vehicle-details/edit-first-registration/edit-first-registration.component'
import { EditLeasingExpiryComponent } from '../../../vehicle-details/edit-leasing-expiry/edit-leasing-expiry.component'
import { EditMileageComponent } from '../../../vehicle-details/edit-mileage/edit-mileage.component'
import { EditNicknameComponent } from '../../../vehicle-details/edit-nickname/edit-nickname.component'
import { FrequentDriverPopupComponent } from '../../../vehicle-details/frequent-driver-popup/frequent-driver-popup.component'
import { RegistrationPlateChangePopupComponent } from '../../../vehicle-details/registration-plate-change-popup/registration-plate-change-popup.component'

import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
@Component({
  selector: 'customer-portal-app-revolution-vehicle-basic-details',
  templateUrl: './vehicle-basic-details.component.html'
})
export class VehicleBasicDetailsComponent implements OnInit {
  mostFrequentDriver: VehicleFrequentDriverDTO
  vehicleDetailed: VehicleDTOExtended

  enableFrequentDriver: boolean
  enableVehicleDetails: boolean
  user: User
  serviceKm: number
  dateFormat: string

  data: FrequentDriverDialogData = {
    mostFrequentDriver: null,
    id: null
  }

  sub = {
    onLanguageChangeSub: null,
    onVehicleServiceHistoryLoadedSub: null,
    onVehicleDetailsLoadedSub: null,
    onVehicleFrequentDriverLoadedSub: null
  }

  public loading: boolean

  licensePlateMask
  showMask = false

  serviceHistory
  constructor (
    public dialog: MatDialog,
    private readonly logger: NGXLogger,
    private readonly vehicleService: VehiclesService,
    private readonly vehicleDocumentsService: VehicleDocumentsService,
    private readonly personalizationService: PersonalizationService,
    private readonly dataService: DataService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public readonly sharedService: SharedService,
    private readonly localStorage: LocalStorageService,
    public transifexService: TransifexService,
    private readonly econdaService: EcondaService,
    private readonly matomoIntegrationService: MatomoIntegrationService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.dateFormat = this.remoteConfigService.get('dateFormat.short')

    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
    })
    this.dataService.loading.vehicleDetails.subscribe(loading => {
      this.loading = loading
    })
  }

  vehicle: VehicleDTOExtended
  @Input()
  public set setVehicle (vehicle: VehicleDTOExtended) {
    this.vehicle = vehicle
    this.setVehicleDetails()
    this.setMileage()
    this.showMask = this.sharedService.showLicensePlateMask(
      this.vehicle?.licensePlate
    )
  }

  filterService (doc: BasicDocumentDTOExtended): boolean {
    return this.vehicleDocumentsService.filterService(doc)
  }

  sendContentModulesMiniCTAData (contentModuleLabel: string, property?: string): void {
    this.cptGtmService.sendContentModulesData(
      'Mini CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'Mini CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
    this.econdaService.send({
      content: 'Vehicle details',
      Target: [
        'Vehicle details edit',
        this.sharedService.translateLink(contentModuleLabel),
        1,
        'd'
      ]
    })
    this.matomoIntegrationService.trackEvent(
      'Vehicle action',
      'Vehicle details edit click (' + property + ')',
      this.matomoIntegrationService.formatVehicleData(this.vehicle)
    )
  }

  ngOnInit (): void {
    this.enableFrequentDriver = this.remoteConfigService.get(
      'features.frequentDriver'
    )
    this.enableVehicleDetails = this.remoteConfigService.get(
      'features.vehicleDetails'
    )

    this.sub.onVehicleDetailsLoadedSub =
      this.dataService.onVehicleDetailsLoaded.subscribe(vehicleDetails => {
        this.vehicleDetailed = vehicleDetails
        this.setVehicleDetails()
      })

    this.sub.onVehicleServiceHistoryLoadedSub =
      this.dataService.onVehicleDocumentsLoaded.subscribe(data => {
        this.serviceHistory = data?.filter(document =>
          this.filterService(document)
        )
        this.setMileage()
      })
    this.sub.onVehicleFrequentDriverLoadedSub =
      this.dataService.onVehicleFrequentDriverLoaded.subscribe(fqd => {
        this.mostFrequentDriver = fqd
      })
    this.sub.onLanguageChangeSub = this.dataService.onLanguageChange.subscribe(
      () => {
        this.dataService.refreshVehicleData(this.vehicle)
      }
    )

    this.licensePlateMask = this.sharedService.setLicensePlateMask()
  }

  setMileage (): void {
    this.serviceKm = undefined
    if (this.vehicle != null) {
      if (this.vehicle.manualMileage != null) {
        this.serviceKm = this.vehicle.manualMileage
      } else {
        this.serviceKm =
          this.serviceHistory?.[0] != null
            ? this.serviceHistory[0].serviceKm
            : this.vehicle.currentMileage
      }
    }
  }

  setVehicleDetails (): void {
    if (
      this.vehicleDetailed != null &&
      this.vehicleDetailed.vin === this.vehicle.vin
    ) {
      // need to update main vehicle propertys
      Object.keys(this.vehicleDetailed).forEach(key => {
        this.vehicle[key] = this.vehicleDetailed[key]
      })
    }
  }

  updateLocalStorage (vehicle): void {
    const copy = this.sharedService.deepCopy(vehicle)
    delete copy.relations
    void this.localStorage.setVehicleDetails(copy.vin, copy)
  }

  openFrequentDriverPopUp (): void {
    this.sendUserCarSettingsForm('Step 1', '')
    const data: FrequentDriverDialogData = {
      id: this.vehicle.id,
      mostFrequentDriver: this.mostFrequentDriver
    }
    if (this.vehicle.business === true) {
      data.ownershipId = this.vehicle.ownershipId
    }
    const dialogRef = this.dialog.open(FrequentDriverPopupComponent, {
      data,
      panelClass: 'mat-dialog-cpt',
      restoreFocus: false
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== false) {
        if (result.success === true) {
          this.data.mostFrequentDriver = result.value
          this.mostFrequentDriver = result.value
          this.sendUserCarSettingsForm(
            'Step 2',
            this.sharedService.translateLink(
              'customerPortal.customer-portal.vehicle.details.most-frequent-driver'
            )
          )
          void this.dataService.loadVehicleData(this.vehicle)
          this.openConfirmationPopup(
            'shared.success',
            'customerPortal.customer-portal.edit-most-frequent-driver.success'
          )
        } else {
          this.openConfirmationPopup(
            'shared.error',
            'customerPortal.customer-portal.edit-most-frequent-driver.failure'
          )
        }
      }
    })
  }

  editLeasingExpiryNextService (
    vehicle: VehicleDTOExtended,
    property: 'leasingExpiryDate' | 'nextServiceDate'
  ): void {
    const dialogRef = this.dialog.open(EditLeasingExpiryComponent, {
      data: { vehicle, property },
      panelClass: 'mat-dialog-cpt',
      restoreFocus: false
    })

    this.sendUserCarSettingsForm('Step 1', '')

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== false) {
        if (result.success === true) {
          this.openConfirmationPopup(
            'shared.success',
            'customerPortal.customer-portal.edit.' + property + '.success'
          )
          this.vehicle[property] = result.value
          if (property === 'leasingExpiryDate') {
            this.sendUserCarSettingsForm(
              'Step 2',
              this.sharedService.translateLink(
                'customerPortal.customer-portal.leasingExpiryDate'
              )
            )
          }
          if (property === 'nextServiceDate') {
            this.sendUserCarSettingsForm(
              'Step 2',
              this.sharedService.translateLink(
                'customerPortal.customer-portal.nextServiceDate'
              )
            )
          }

          this.updateLocalStorage(vehicle)
          this.dataService.refreshVehicleData(this.vehicle)
        }
        if (!(result.success === true)) {
          this.openConfirmationPopup(
            'shared.error',
            'customerPortal.customer-portal.edit.' + property + '.failure'
          )
        }
      }
    })
  }

  deleteLeasingExpiryNextService (
    vehicle: VehicleDTOExtended,
    property: 'leasingExpiryDate' | 'nextServiceDate'
  ): void {
    const dialogRef = this.dialog.open(ConfirmationQuestionPopupComponent, {
      data: {
        title: 'customerPortal.customer-portal.' + property + '.delete.title',
        text: 'customerPortal.customer-portal.' + property + '.delete.text',
        cancel: 'shared.cancel',
        save: 'shared.clear',
        saveButtonColor: 'warn',
        preventCloseBeforeComplete: true
      },
      panelClass: 'mat-dialog-cpt'
    })
    const sub = dialogRef.componentInstance.confirm.subscribe(() => {
      const vehicleUpdated = this.sharedService.deepCopy(vehicle)
      delete vehicleUpdated.pictureType
      delete vehicleUpdated.leasingInfo
      delete vehicleUpdated.dealers
      delete vehicleUpdated.brandPicture
      delete vehicleUpdated.relations
      vehicleUpdated[property] = null
      this.vehicleService.addUserVehicle(vehicleUpdated).subscribe(
        () => {
          dialogRef.close()
          vehicle[property] = null
          this.updateLocalStorage(vehicle)
          this.dataService.refreshVehicleData(this.vehicle)
          this.openConfirmationPopup(
            'shared.successful',
            'customerPortal.customer-portal.' +
              property +
              '.delete.success.text'
          )
        },
        error => {
          dialogRef.close()
          this.logger.debug(error)
          this.openConfirmationPopup(
            'shared.error',
            'customerPortal.customer-portal.' +
              property +
              '.delete.failure.text'
          )
        }
      )
    })
    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe()
    })
  }

  deleteFrequentDriver (): void {
    const dialogRef = this.dialog.open(ConfirmationQuestionPopupComponent, {
      data: {
        title: 'customerPortal.customer-portal.most-frequent-driver.delete',
        text: 'customerPortal.customer-portal.most-frequent-driver.delete.confirmation',
        cancel: 'shared.cancel',
        save: 'shared.clear',
        saveButtonColor: 'warn',
        preventCloseBeforeComplete: true
      },
      panelClass: 'mat-dialog-cpt'
    })
    const sub = dialogRef.componentInstance.confirm.subscribe(() => {
      this.vehicleService
        .deleteFrequentDriver(
          this.vehicle.id,
          this.vehicle.business === true ? this.vehicle.ownershipId : null
        )
        .subscribe(
          response => {
            dialogRef.close()
            this.mostFrequentDriver = response
            this.data.mostFrequentDriver = null

            this.dialog.open(ConfirmationPopupComponent, {
              data: {
                title: 'shared.success',
                text: 'customerPortal.customer-portal.most-frequent-driver.delete.success'
              },
              panelClass: 'mat-dialog-cpt'
            })
            void this.dataService.loadVehicleData(this.vehicle)
          },
          error => {
            dialogRef.close()
            this.logger.log(error)
            this.dialog.open(ConfirmationPopupComponent, {
              data: {
                title:
                  'customerPortal.customer-portal.leasing.contact.send.failure.title',
                text: 'customerPortal.customer-portal.most-frequent-driver.delete.failure'
              },
              panelClass: 'mat-dialog-cpt'
            })
          }
        )
    })
    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe()
    })
  }

  frequentDriverActionClicked (action: cptMenuAction): void {
    switch (action) {
      case 'edit':
        this.openFrequentDriverPopUp()
        break
      case 'delete':
        this.deleteFrequentDriver()
        this.sendUserCarSettingsForm('Step 1', '')
        break
    }
  }

  editMileage (vehicle: VehicleDTOExtended): void {
    const dialogRef = this.dialog.open(EditMileageComponent, {
      data: vehicle,
      panelClass: 'mat-dialog-cpt',
      restoreFocus: false
    })

    this.sendUserCarSettingsForm('Step 1', '')
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== false) {
        if (result.success === true) {
          this.serviceKm = result.value
          vehicle.manualMileage = result.value
          this.updateLocalStorage(vehicle)
          this.openConfirmationPopup(
            'shared.success',
            'customerPortal.customer-portal.edit-mileage.success'
          )
        }
        if (!(result.success === true)) {
          this.openConfirmationPopup(
            'shared.error',
            'customerPortal.customer-portal.edit-mileage.failure'
          )
        }
        // this.dataService.refreshVehicleData(this.vehicle)
        this.sendUserCarSettingsForm(
          'Step 2',
          this.sharedService.translateLink(
            'customerPortal.customer-portal.vehicle.details.mileage'
          )
        )
        void this.dataService.loadVehicleData(this.vehicle)
      }
    })
  }

  editFirstRegistration (vehicle: VehicleDTOExtended): void {
    const dialogRef = this.dialog.open(EditFirstRegistrationComponent, {
      data: vehicle,
      panelClass: 'mat-dialog-cpt',
      restoreFocus: false
    })

    this.sendUserCarSettingsForm('Step 1', '')
    dialogRef.afterClosed().subscribe(result => {
      this.logger.debug(`Dialog result: ${String(result)}`)
      if (result != null && result !== false) {
        vehicle.firstRegistration = result
      }
      this.sendUserCarSettingsForm(
        'Step 2',
        this.sharedService.translateLink(
          'customerPortal.customer-portal.vehicle.details.first-registration'
        )
      )
      this.dataService.refreshVehicleData(this.vehicle)
    })
  }

  editLicensePlate (vehicle: VehicleDTOExtended): void {
    const dialogRef = this.dialog.open(RegistrationPlateChangePopupComponent, {
      data: vehicle,
      panelClass: 'mat-dialog-cpt'
    })

    this.sendUserCarSettingsForm('Step 1', '')
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== false) {
        if (result.success === true) {
          let text = 'customerPortal.customer-portal.edit-licence-plate.success'
          if (vehicle.createdByDataSource !== 'customer_portal') {
            text =
              'customerPortal.customer-portal.edit-licence-plate.success.imported'
            // we did not change licence
          } else {
            vehicle.licensePlate = result.licensePlate
          }
          this.openConfirmationPopup('shared.success', text)
        }
        if (!(result.success === true)) {
          this.openConfirmationPopup(
            'shared.error',
            'customerPortal.customer-portal.edit-licence-plate.failure'
          )
        }
        this.sendUserCarSettingsForm(
          'Step 2',
          this.sharedService.translateLink(
            'customerPortal.customer-portal.vehicle.details.registration-plate'
          )
        )
        this.dataService.refreshVehicleData(vehicle)
      }
    })
  }

  openConfirmationPopup (title: string, text: string): void {
    this.dialog.open(ConfirmationPopupComponent, {
      data: {
        title,
        text,
        useInnerHtml: true
      },
      panelClass: 'mat-dialog-cpt'
    })
  }

  sendUserCarSettingsForm (step: string, field: string): void {
    this.cptGtmService.sendUserCarSettingsForm(this.vehicle, step, field)
  }

  editNickname (vehicle: VehicleDTOExtended): void {
    this.sendUserCarSettingsForm('Step 1', '')
    this.dialog
      .open(EditNicknameComponent, {
        data: vehicle,
        panelClass: 'mat-dialog-cpt',
        restoreFocus: false
      })
      .afterClosed()
      .subscribe(result => {
        if (result != null && result !== false) {
          if (result.success === true) {
            this.vehicle.nickname = result.value
            this.updateLocalStorage(vehicle)
            this.openConfirmationPopup(
              'shared.success',
              'customerPortal.customer-portal.edit.nickname.success'
            )
            this.sendUserCarSettingsForm(
              'Step 2',
              this.sharedService.translateLink(
                'customerPortal.customer-portal.vehicle.details.nickname'
              )
            )
            this.dataService.refreshVehicleData(this.vehicle)
          } else {
            this.openConfirmationPopup(
              'shared.error',
              'customerPortal.customer-portal.edit.nickname.failure'
            )
          }
        }
      })
  }

  deleteNickname (vehicle: VehicleDTOExtended): void {
    this.sendUserCarSettingsForm('Step 1', '')

    const dialogRef = this.dialog.open(ConfirmationQuestionPopupComponent, {
      data: {
        title:
          'customerPortal.customer-portal.vehicle.details.nickname-menu.delete.title',
        text: 'customerPortal.customer-portal.vehicle.details.nickname-menu.delete.text',
        cancel: 'shared.cancel',
        save: 'shared.clear',
        saveButtonColor: 'warn',
        preventCloseBeforeComplete: true
      },
      panelClass: 'mat-dialog-cpt'
    })
    const sub = dialogRef.componentInstance.confirm.subscribe(() => {
      this.vehicleService.deleteNickname(vehicle.vin).subscribe(
        () => {
          dialogRef.close()
          vehicle.nickname = null
          this.sendUserCarSettingsForm(
            'Step 2',
            this.sharedService.translateLink(
              'customerPortal.customer-portal.vehicle.details.nickname'
            )
          )
          this.updateLocalStorage(vehicle)
          this.dataService.refreshVehicleData(this.vehicle)
          this.dialog.open(ConfirmationPopupComponent, {
            data: {
              title: 'shared.successful',
              text: 'customerPortal.customer-portal.vehicle.details.nickname-menu.delete.success.text'
            },
            panelClass: 'mat-dialog-cpt'
          })
        },
        error => {
          dialogRef.close()
          this.logger.debug(error)
          this.dialog.open(ConfirmationPopupComponent, {
            data: {
              title: 'shared.error',
              text: 'customerPortal.customer-portal.vehicle.details.nickname-menu.delete.failure.text'
            },
            panelClass: 'mat-dialog-cpt'
          })
        }
      )
    })
    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe()
    })
  }

  nextServiceDateActionClicked (action: cptMenuAction, vehicle): void {
    if (action === 'edit') {
      this.editLeasingExpiryNextService(vehicle, 'nextServiceDate')
    } else {
      this.deleteLeasingExpiryNextService(vehicle, 'nextServiceDate')
    }
  }

  leasingExpiryNextServiceActionClicked (action: cptMenuAction, vehicle): void {
    if (action === 'edit') {
      this.editLeasingExpiryNextService(vehicle, 'leasingExpiryDate')
    } else {
      this.deleteLeasingExpiryNextService(vehicle, 'leasingExpiryDate')
    }
  }

  nicknameActionClicked (
    action: cptMenuAction,
    vehicle: VehicleDTOExtended
  ): void {
    switch (action) {
      case 'edit':
        this.editNickname(vehicle)
        break
      case 'delete':
        this.deleteNickname(vehicle)
        break
    }
  }
}
