<div class="flex gap-4 items-center">
  <mat-icon
    svgIcon="info"
    class="w-8 h-8 text-mat-ef-revolution-secondary-500"
  ></mat-icon>
  <div class="flex flex-1 flex-col gap-2">
    <div class="flex flex-col">
      <div class="font-black w-full text-lg">
        <T
          str="Tracking consent"
          key="customerPortal.customer-portal.matomo-consent.title"
          tags="customer-portal, 3.11"
        ></T>
      </div>
      <div class="font-normal w-full leading-5">
        <T
          str="Help us improve your experience by allowing tracking for analytics"
          key="customerPortal.customer-portal.matomo-consent.text"
          tags="customer-portal, 3.11"
        ></T>
      </div>
    </div>
    <div class="flex gap-2 justify-end">
      <button
        class="revolution min-h-fit min-w-fit"
        mat-button
        mat-stroked-button
        color="secondary"
        (click)="decline()"
      >
        {{ 'decline' | sharedTranslate }}
      </button>
      <button
        class="revolution min-h-fit min-w-fit"
        mat-button
        mat-flat-button
        color="secondary"
        (click)="accept()"
      >
        {{ 'accept' | sharedTranslate }}
      </button>
    </div>
  </div>
</div>
