import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DealerDTO } from '../../../services/dealers.service'

@Component({
  selector: 'customer-portal-app-live-chat-dialog',
  templateUrl: './live-chat-dialog.component.html'
})
export class LiveChatDialogComponent implements OnInit {
  id: number

  constructor(
    private readonly dialogRef: MatDialogRef<LiveChatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DealerDTO
  ) {}

  ngOnInit(): void {
    this.id = this.data.liveChatGroupId
  }

  save(): void {
    this.dialogRef.close(this.data)
  }
}
