/* eslint-disable @angular-eslint/directive-selector */
import { AfterViewInit, Directive, Host, Input, Optional, Renderer2, Self, ViewContainerRef } from '@angular/core'
import { MatButton } from '@angular/material/button'
import { MatPaginator } from '@angular/material/paginator'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
interface PageObject {
  length: number
  pageIndex: number
  pageSize: number
  previousPageIndex: number
}

@Directive({
  selector: '[ef-paginator]'
})
export class EfPaginatorDirective implements AfterViewInit {
  private readonly theme
  private readonly _pageGapTxt = '...'
  private _rangeStart: number
  private _rangeEnd: number
  private _buttons = []
  private _curPageObj: PageObject = {
    length: 0,
    pageIndex: 0,
    pageSize: 0,
    previousPageIndex: 0
  }

  @Input()
  get showTotalPages(): number {
    return this._showTotalPages
  }

  set showTotalPages(value: number) {
    this._showTotalPages = value % 2 === 0 ? value + 1 : value
  }

  private _showTotalPages = 10

  get inc(): number {
    return this._showTotalPages % 2 === 0
      ? this.showTotalPages / 2
      : (this.showTotalPages - 1) / 2
  }

  get numOfPages(): number {
    return this.matPag.getNumberOfPages()
  }

  get lastPageIndex(): number {
    return this.matPag.getNumberOfPages() - 1
  }

  constructor(
    @Host() @Self() @Optional() private readonly matPag: MatPaginator,
    private readonly vr: ViewContainerRef,
    private readonly ren: Renderer2,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.theme = this.remoteConfigService.get('theme')
    // to rerender buttons on items per page change and first, last, next and prior buttons
    this.matPag.page.subscribe((e: PageObject) => {
      if (
        this._curPageObj.pageSize !== e.pageSize &&
        this._curPageObj.pageIndex !== 0
      ) {
        e.pageIndex = 0
        this._rangeStart = 0
        this._rangeEnd = this._showTotalPages - 1
      }
      this._curPageObj = e

      this.initPageRange()
    })

    this.matPag._intl.firstPageLabel = ''
    this.matPag._intl.itemsPerPageLabel = ''
    this.matPag._intl.lastPageLabel = ''
    this.matPag._intl.nextPageLabel = ''
    this.matPag._intl.previousPageLabel = ''
  }

  private buildPageNumbers(): void {
    const actionContainer = this.vr.element.nativeElement.querySelector(
      'div.mat-mdc-paginator-range-actions'
    )
    const nextPageNode = this.vr.element.nativeElement.querySelector(
      'button.mat-mdc-paginator-navigation-next'
    )
    // const prevButtonCount = this._buttons.length

    // remove buttons before creating new ones
    if (this._buttons.length > 0) {
      this._buttons.forEach(button => {
        this.ren.removeChild(actionContainer, button)
      })
      // Empty state array
      this._buttons.length = 0
    }

    // initialize next page and last page buttons
    if (this._buttons.length === 0) {
      const nodeArray =
        this.vr.element.nativeElement.childNodes[0].childNodes[0].childNodes[2]
          .childNodes
      setTimeout(() => {
        for (let i = 0; i < nodeArray.length; i++) {
          if (nodeArray[i].nodeName === 'BUTTON') {
            this.ren.addClass(nodeArray[i], 'mat-button')
            this.ren.addClass(nodeArray[i], 'mat-flat-button')
            if (nodeArray[i].innerHTML.length > 100) {
              if (this.theme === 'default') {
                this.ren.addClass(nodeArray[i], 'mat-primary')
              }
            }
            if (nodeArray[i].disabled === true) {
              this.ren.addClass(nodeArray[i], 'mat-button-disabled')
            }
          }
        }
      })
    }

    for (let i = 0; i < this.numOfPages; i++) {
      if (i >= this._rangeStart && i <= this._rangeEnd) {
        this.ren.insertBefore(
          actionContainer,
          this.createButton(i, this.matPag.pageIndex),
          nextPageNode
        )
      }

      if (i === this._rangeStart - 1) {
        this.ren.insertBefore(
          actionContainer,
          this.createButton(this._pageGapTxt, this._rangeStart, 0),
          nextPageNode
        )
      }
      if (i === this._rangeEnd + 1) {
        this.ren.insertBefore(
          actionContainer,
          this.createButton(
            this._pageGapTxt,
            this._rangeEnd,
            this.lastPageIndex
          ),
          nextPageNode
        )
      }
    }
  }

  private createButton(i, pageIndex: number, newIndex?): MatButton {
    const linkBtn: MatButton = this.ren.createElement('button')
    this.ren.addClass(linkBtn, 'mat-button')
    this.ren.addClass(linkBtn, 'mat-focus-indicator')
    this.ren.addClass(linkBtn, 'mat-button-base')

    const pagingTxt = isNaN(i) ? this._pageGapTxt : +(Number(i) + 1)
    const text = this.ren.createText(String(pagingTxt))

    this.ren.addClass(linkBtn, 'mat-custom-page')
    switch (i) {
      case pageIndex:
        this.ren.addClass(linkBtn, 'selectedPage')
        break
      case this._pageGapTxt:
        this.ren.listen(linkBtn, 'click', () => {
          this.switchPage(newIndex)
        })
        break
      default:
        this.ren.listen(linkBtn, 'click', () => {
          this.switchPage(i)
        })
        break
    }

    this.ren.appendChild(linkBtn, text)
    // Add button to private array for state
    this._buttons.push(linkBtn)
    return linkBtn
  }

  // calculates the button range based on class input parameters and based on current page index value. Used to render new buttons after event.
  private initPageRange(): void {
    const middleIndex = (this._rangeStart + this._rangeEnd) / 2

    this._rangeStart = this.calcRangeStart(middleIndex)
    this._rangeEnd = this.calcRangeEnd(middleIndex)

    this.buildPageNumbers()
  }

  // Helper function To calculate start of button range
  private calcRangeStart(middleIndex: number): number {
    switch (true) {
      case this._curPageObj.pageIndex === 0 && this._rangeStart !== 0:
        return 0
      case this._curPageObj.pageIndex > this._rangeEnd:
        return this._curPageObj.pageIndex + this.inc > this.lastPageIndex
          ? this.lastPageIndex - this.inc * 2
          : this._curPageObj.pageIndex - this.inc
      case this._curPageObj.pageIndex > this._curPageObj.previousPageIndex &&
        this._curPageObj.pageIndex > middleIndex &&
        this._rangeEnd < this.lastPageIndex:
        return this._rangeStart + 1
      case this._curPageObj.pageIndex < this._curPageObj.previousPageIndex &&
        this._curPageObj.pageIndex < middleIndex &&
        this._rangeStart > 0:
        return this._rangeStart - 1
      default:
        return this._rangeStart
    }
  }

  // Helpter function to calculate end of button range
  private calcRangeEnd(middleIndex: number): number {
    switch (true) {
      case this._curPageObj.pageIndex === 0 &&
        this._rangeEnd !== this._showTotalPages:
        return this._showTotalPages - 1
      case this._curPageObj.pageIndex > this._rangeEnd:
        return this._curPageObj.pageIndex + this.inc > this.lastPageIndex
          ? this.lastPageIndex
          : this._curPageObj.pageIndex + 1
      case this._curPageObj.pageIndex > this._curPageObj.previousPageIndex &&
        this._curPageObj.pageIndex > middleIndex &&
        this._rangeEnd < this.lastPageIndex:
        return this._rangeEnd + 1
      case this._curPageObj.pageIndex < this._curPageObj.previousPageIndex &&
        this._curPageObj.pageIndex < middleIndex &&
        this._rangeStart >= 0 &&
        this._rangeEnd > this._showTotalPages - 1:
        return this._rangeEnd - 1
      default:
        return this._rangeEnd
    }
  }

  // Helper function to switch page on non first, last, next and previous buttons only.
  private switchPage(i: number): void {
    const previousPageIndex = this.matPag.pageIndex
    this.matPag.pageIndex = i
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.matPag['_emitPageEvent'](previousPageIndex)
    this.initPageRange()
  }

  // Initialize default state after view init
  public ngAfterViewInit(): void {
    this._rangeStart = 0
    this._rangeEnd = this._showTotalPages - 1
    this.initPageRange()
  }
}
