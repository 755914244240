<ng-container *ngIf="hasInsurance">
  <mat-expansion-panel
    data-cy="insurance-molecule-panel"
    *ngIf="!loading"
    [hideToggle]="insuranceIsDisabled"
    [disabled]="insuranceIsDisabled"
    #insurancePanel
    (afterExpand)="
      sendContentModulesData(
        true,
        'customerPortal.customer-portal.vehicle.insurance'
      )
    "
    (afterCollapse)="
      sendContentModulesData(
        false,
        'customerPortal.customer-portal.vehicle.insurance'
      )
    "
  >
    <mat-expansion-panel-header
      class="p-0 md:px-6"
      [collapsedHeight]="'auto'"
      [expandedHeight]="'auto'"
    >
      <mat-panel-title>
        <div class="mt-1 mr-1">
          <mat-icon
            class="text-mat-ef-revolution-primary-500"
            [ngClass]="insurancePanel.expanded ? 'blue-icon' : ''"
            svgIcon="car_verified"
          >
          </mat-icon>
        </div>

        <div class="vehicle-expansion-panel-text" *ngIf="hasInsuranceData()">
          <div
            class="w-full pt-1 overflow-hidden whitespace-nowrap text-ellipsis"
          >
            <div class="flex text-sm sm:text-base font-bold">
              <span>
                <T
                  str="Insurance information"
                  key="customerPortal.customer-portal.vehicle.insurance-information"
                  tags="customer-portal, 3.10"
                ></T
              ></span>
              <ng-container *ngIf="hasInsuranceWithEndDate() && hasOnePolicy()">
                <span
                  class="leasing-title-warning"
                  [ngClass]="styleProgressBar()"
                >
                  <mat-icon
                    *ngIf="!hasActiveInsurance()"
                    svgIcon="error"
                  ></mat-icon>
                  <mat-icon
                    *ngIf="hasActiveInsurance()"
                    svgIcon="check_circle"
                  ></mat-icon>
                </span>
              </ng-container>
            </div>
          </div>

          <!-- show policy details in header if we have only 1 -->
          <ng-container *ngIf="hasOnePolicy()">
            <!-- if policy has endDate we can put progress bar  -->
            <ng-container *ngIf="hasInsuranceWithEndDate()">
              <ng-container *ngIf="expiration">
                <mat-progress-bar
                  [ngClass]="styleProgressBar()"
                  class="h-0.5"
                  mode="determinate"
                  value="{{ expiration.percentage?.toString() }}"
                  dir="rtl"
                >
                </mat-progress-bar>
              </ng-container>

              <div class="text-sm text-efSans" *ngIf="hasActiveInsurance()">
                <T
                  str="Insurance until {endDate}."
                  key="customerPortal.customer-portal.vehicle.insurance-until"
                  tags="customer-portal, 3.10"
                  [vars]="{
                    endDate: policies[0].endDate | date: 'dd.MM.yyyy'
                  }"
                ></T>
              </div>

              <div class="text-sm text-efSans" *ngIf="!hasActiveInsurance()">
                <T
                  str="Insurance expired on {endDate}."
                  key="customerPortal.customer-portal.vehicle.insurance-expired"
                  tags="customer-portal, 3.10"
                  [vars]="{
                    endDate: policies[0].endDate | date: 'dd.MM.yyyy'
                  }"
                ></T>
              </div>
            </ng-container>

            <ng-container *ngIf="!hasInsuranceWithEndDate()">
              <div class="text-sm text-efSans">
                <ng-container
                  *ngTemplateOutlet="
                    insuranceHeader;
                    context: { insurance: policies[0] }
                  "
                ></ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <div class="vehicle-expansion-panel-text" *ngIf="!hasInsuranceData()">
          <ng-container *ngTemplateOutlet="noInsurance"></ng-container>
        </div>
      </mat-panel-title>
      <mat-panel-description
        *ngIf="hasRequestInsuranceLink() && !hasInsuranceData()"
        class="justify-start sm:justify-end text-mat-ef-revolution-primary-500"
      >
        <div
          class="w-full md:w-fit text-center select-none text-base font-efSans cursor-pointer bg-mat-ef-revolution-supergrey-500 px-4 py-1 rounded-2xl font-semibold"
          (click)="openRequestInsuranceLink()"
        >
          <T
            str="Request Insurance"
            key="customerPortal.customer-portal.vehicle.insurance.request-insurance"
            tags="customer-portal, 3.10"
          ></T>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="ml-5 md:ml-11 mt-2.5" *ngIf="hasInsuranceData()">
      <!-- if there is only one policy  -->
      <ng-container *ngIf="hasOnePolicy()">
        <ng-container
          *ngTemplateOutlet="insuranceInfo; context: { insurance: policies[0] }"
        ></ng-container>
      </ng-container>

      <!-- if there are multiple  -->
      <ng-container *ngIf="!hasOnePolicy()">
        <mat-divider class="leasing-divider -mx-6 md:mx-0"></mat-divider>

        <div class="w-full flex flex-col gap-2 pt-4">
          <ng-container
            *ngFor="let insurance of policies; last as last; let i = index"
          >
            <div class="w-full">
              <mat-expansion-panel
                [hideToggle]="true"
                (opened)="policyIndex = i"
                class="panelBodyGreyBorder p-0"
                (closed)="policyIndex === i ? (policyIndex = undefined) : ''"
                [expanded]="policyIndex === i"
              >
                <mat-expansion-panel-header
                  class="p-0 bg-gray-100"
                  [collapsedHeight]="'auto'"
                  [expandedHeight]="'auto'"
                >
                  <mat-panel-title class="flex justify-between p-0">
                    <div
                      class="flex flex-1 items-center pl-1 max-w-[calc(100%_-_70px)]"
                    >
                      <div
                        class="flex-1 max-w-[calc(100%_-_35px)] text-sm text-efSans pl-2 text-ellipsis whitespace-nowrap overflow-hidden text-mat-ef-revolution-primary-500"
                      >
                        <ng-container
                          *ngTemplateOutlet="
                            insuranceHeader;
                            context: { insurance: insurance }
                          "
                        ></ng-container>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="pt-2 w-full" *ngIf="policyIndex === i">
                  <ng-container
                    *ngTemplateOutlet="
                      insuranceInfo;
                      context: { insurance: insurance }
                    "
                  ></ng-container>
                </div>
              </mat-expansion-panel>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </mat-expansion-panel>
  <mat-divider
    class="relative w-full text-mat-ef-revolution-supergrey-500"
  ></mat-divider>
</ng-container>

<ng-template #insuranceInfo let-insurance="insurance">
  <div class="flex flex-col gap-2" data-cy="insurance-molecule-insurance-data">
    <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
      <div class="m-1 grid" *ngIf="insurance.name">
        <span
          class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
        >
          <T
            str="Policy"
            key="customerPortal.customer-portal.vehicle.insurance.name"
            tags="customer-portal, 3.10"
          ></T
        ></span>
        <span class="text-sm text-mat-ef-revolution-primary-500 font-normal">{{
          insurance.name
        }}</span>
      </div>
      <div class="m-1 grid" *ngIf="insurance.policyNo">
        <span
          class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
        >
          <T
            str="Policy number"
            key="customerPortal.customer-portal.vehicle.insurance.policyNo"
            tags="customer-portal, 3.10"
          ></T
        ></span>
        <span class="text-sm text-mat-ef-revolution-primary-500 font-normal">{{
          insurance.policyNo
        }}</span>
      </div>
      <div class="m-1 grid" *ngIf="insurance.status">
        <span
          class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
        >
          <T
            str="Status"
            key="customerPortal.customer-portal.vehicle.insurance.status"
            tags="customer-portal, 3.10"
          ></T
        ></span>
        <span class="text-sm text-mat-ef-revolution-primary-500 font-normal">
          {{
            {
              str: insurance.status,
              prefix: 'customerPortal.customer-portal.vehicle.insurance.status.'
            } | transifexTranslate
          }}
        </span>
      </div>
      <div class="m-1 grid" *ngIf="insurance.startDate">
        <span
          class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
        >
          <T
            str="Policy started on"
            key="customerPortal.customer-portal.vehicle.insurance.startDate"
            tags="customer-portal, 3.10"
          ></T
        ></span>
        <span class="text-sm text-mat-ef-revolution-primary-500 font-normal">{{
          insurance.startDate | date: 'dd/MM/yyyy'
        }}</span>
      </div>
      <div class="m-1 grid" *ngIf="insurance.endDate">
        <span
          class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
        >
          <T
            str="Policy ends on"
            key="customerPortal.customer-portal.vehicle.insurance.endDate"
            tags="customer-portal, 3.10"
          ></T
        ></span>
        <span class="text-sm text-mat-ef-revolution-primary-500 font-normal">
          {{ insurance.endDate | date: 'dd/MM/yyyy' }}
        </span>
      </div>
      <div class="m-1 grid" *ngIf="insurance.lastRenewalDate">
        <span
          class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
          ><T
            str="Policy renewed on"
            key="customerPortal.customer-portal.vehicle.insurance.lastRenewalDate"
            tags="customer-portal, 3.10"
          ></T
        ></span>
        <span class="text-sm text-mat-ef-revolution-primary-500 font-normal">{{
          insurance.lastRenewalDate | date: 'dd/MM/yyyy'
        }}</span>
      </div>
      <div class="m-1 grid" *ngIf="insurance.requestDate">
        <span
          class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
          ><T
            str="Requested on"
            key="customerPortal.customer-portal.vehicle.insurance.requestDate"
            tags="customer-portal, 3.10"
          ></T
        ></span>
        <span class="text-sm text-mat-ef-revolution-primary-500 font-normal">{{
          insurance.requestDate | date: 'dd/MM/yyyy'
        }}</span>
      </div>
      <div class="m-1 grid" *ngIf="insurance.desired_start_date">
        <span
          class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
          ><T
            str="Desired start date"
            key="customerPortal.customer-portal.vehicle.insurance.desired_start_date"
            tags="customer-portal, 3.10"
          ></T
        ></span>
        <span class="text-sm text-mat-ef-revolution-primary-500 font-normal">{{
          insurance.desired_start_date | date: 'dd/MM/yyyy'
        }}</span>
      </div>
      <div class="m-1 grid" *ngIf="insurance.approvalDate">
        <span
          class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
          ><T
            str="Approved on"
            key="customerPortal.customer-portal.vehicle.insurance.approvalDate"
            tags="customer-portal, 3.10"
          ></T
        ></span>
        <span class="text-sm text-mat-ef-revolution-primary-500 font-normal">{{
          insurance.approvalDate | date: 'dd/MM/yyyy'
        }}</span>
      </div>
      <div class="m-1 grid" *ngIf="insurance.cancellationDate">
        <span
          class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
          ><T
            str="Cancelled on"
            key="customerPortal.customer-portal.vehicle.insurance.cancellationDate"
            tags="customer-portal, 3.10"
          ></T
        ></span>
        <span class="text-sm text-mat-ef-revolution-primary-500 font-normal">{{
          insurance.cancellationDate | date: 'dd/MM/yyyy'
        }}</span>
      </div>
      <div class="m-1 grid" *ngIf="insurance.sendDate">
        <span
          class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
          ><T
            str="Sent on"
            key="customerPortal.customer-portal.vehicle.insurance.sendDate"
            tags="customer-portal, 3.10"
          ></T
        ></span>
        <span class="text-sm text-mat-ef-revolution-primary-500 font-normal">{{
          insurance.sendDate | date: 'dd/MM/yyyy'
        }}</span>
      </div>
      <div class="m-1 grid" *ngIf="insurance.types?.[0]">
        <span
          class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
        >
          <T
            str="Type"
            key="customerPortal.customer-portal.vehicle.insurance.type"
            tags="customer-portal, 3.10"
          ></T
        ></span>
        <span class="text-sm text-mat-ef-revolution-primary-500 font-normal">
          <ng-container *ngFor="let type of insurance.types; last as last">
            <span>
              {{
                {
                  str: type,
                  prefix:
                    'customerPortal.customer-portal.vehicle.insurance.type.'
                } | transifexTranslate
              }}<ng-container *ngIf="!last">,</ng-container>
            </span>
          </ng-container>
        </span>
      </div>
    </div>
    <!-- download button  -->
    <div
      *ngIf="insurance.token || showEflexButtons"
      class="relative w-full flex flex-col md:flex-row gap-3 justify-end"
    >
      <button
        data-cy="insurance-molecule-token-download"
        *ngIf="insurance.token"
        mat-button
        mat-raised-button
        class="revolution small-button min-w-fit"
        color="secondary"
        (click)="getPDF(insurance, 'download')"
        [disabled]="downloadStarted"
      >
        <mat-icon class="m-0" svgIcon="download"></mat-icon>
      </button>

      <ng-container *ngIf="showEflexButtons && insurance.offerId && insurance.policyNo">
        <button
          data-cy="insurance-molecule-eflex-menu"
          mat-button
          mat-raised-button
          class="revolution small-button"
          color="secondary"
          [disabled]="downloadStarted"
          [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{ insurance: insurance, docType: 'DOC_OFFER' }"
        >
          <T
            str="DOC_OFFER"
            key="customerPortal.customer-portal.vehicle.insurance.doc_offer"
            tags="customer-portal, 3.11"
          ></T>
        </button>
        <button
          mat-button
          mat-raised-button
          class="revolution small-button"
          color="secondary"
          [disabled]="downloadStarted"
          [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{
            insurance: insurance,
            docType: 'DOC_PRODUCT'
          }"
        >
          <T
            str="DOC_PRODUCT"
            key="customerPortal.customer-portal.vehicle.insurance.doc_product"
            tags="customer-portal, 3.11"
          ></T>
        </button>
        <button
          mat-button
          mat-raised-button
          class="revolution small-button"
          color="secondary"
          [disabled]="downloadStarted"
          [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{
            insurance: insurance,
            docType: 'DOC_PRIVACY'
          }"
        >
          <T
            str="DOC_PRIVACY"
            key="customerPortal.customer-portal.vehicle.insurance.doc_privacy"
            tags="customer-portal, 3.11"
          ></T>
        </button>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #insuranceHeader let-insurance="insurance">
  <ng-container *ngIf="insurance.name"> {{ insurance.name }}</ng-container>
  <ng-container *ngIf="insurance.policyNo">
    {{ insurance.policyNo }}</ng-container
  >
  <ng-container *ngIf="insurance.status">
    &#8226;
    {{
      {
        str: insurance.status,
        prefix: 'customerPortal.customer-portal.vehicle.insurance.status.'
      } | transifexTranslate
    }}</ng-container
  >
</ng-template>

<ng-template #noInsurance>
  <div class="w-full pt-1 overflow-hidden whitespace-nowrap text-ellipsis">
    <div class="flex text-sm sm:text-base font-bold">
      <span>
        <T
          str="Insurance information"
          key="customerPortal.customer-portal.vehicle.insurance-information"
          tags="customer-portal, 3.10"
        ></T
      ></span>
    </div>
    <div class="text-sm text-efSans">
      <T
        str="No insurance"
        key="customerPortal.customer-portal.vehicle.insurance.none"
        tags="customer-portal, 3.10"
      ></T>
    </div>
  </div>
</ng-template>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-insurance="insurance" let-docType="docType">
    <button
      data-cy="insurance-molecule-eflex-preview"
      class="h-[44px] hover:bg-mat-ef-revolution-grey-two-100"
      mat-menu-item
      (click)="getPDF(insurance, 'open', docType)"
    >
      <mat-icon svgIcon="visibility_on"></mat-icon>
      <T
        str="Preview"
        key="customerPortal.customer-portal.general.actions.preview"
        tags="customer-portal, 3.11"
      ></T>
    </button>
    <button
      data-cy="insurance-molecule-eflex-download"
      class="h-[44px] hover:bg-mat-ef-revolution-grey-two-100"
      mat-menu-item
      (click)="getPDF(insurance, 'download', docType)"
    >
      <mat-icon svgIcon="download"></mat-icon>
      <T
        str="Download"
        key="customerPortal.customer-portal.general.actions.download"
        tags="customer-portal, 3.1"
      ></T>
    </button>
  </ng-template>
</mat-menu>
