<div class="container py-14 px-4 bg-white cpt-tracking">
  <h1 class="head-title py-8">
    <T
      str="My appointments"
      key="customerPortal.customer-portal.navigation.appointments"
      tags="customer-portal, 3.1"
    ></T>
  </h1>

  <customer-portal-vehicle-documents-disclaimer *ngIf="anyAppointmentHasLiveStatus"
    [translateKey]="
      'customerPortal.customer-portal.appointment.tracking.disclaimer'
    "
  ></customer-portal-vehicle-documents-disclaimer>

  <div class="w-full flex flex-col pb-10" *ngIf="loading">
    <ngx-skeleton-loader
      appearance="line"
      [count]="12"
      animation="progress"
    ></ngx-skeleton-loader>
  </div>

  <div class="w-full flex flex-col pb-20" *ngIf="!loading">
    <ng-container *ngIf="!upcomingAppointments?.[0]">
      <div
        class="w-full h-36 bg-mat-ef-revolution-grey-200 rounded-xl flex flex-col justify-center items-center"
      >
        <div class="text-mat-ef-revolution-supergrey-900">
          <mat-icon svgIcon="event_busy"></mat-icon>
        </div>
        <div class="text-base font-efSans-primary">
          <T
            str="You currently have no appointments"
            key="customerPortal.customer-portal.appointments.no-appointments"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="upcomingAppointments?.[0]">
      <div class="w-full flex flex-col gap-4 md:gap-6 ef-scrollbar">
        <div
          class="noPaddingPanelBody box-shadow-full my-2 md:m-0 !rounded-lg"
          *ngFor="let appointment of upcomingAppointments; let i = index"
          data-cy="appointments-appointment-widget"
        >
          <mat-expansion-panel
            class="!rounded-lg cursor-pointer p-0 font-efSans-primary"
            (afterExpand)="
              sendContentModulesData(
                true,
                'customerPortal.customer-portal.navigation.appointments'
              )
            "
            (afterCollapse)="
              sendContentModulesData(
                false,
                'customerPortal.customer-portal.navigation.appointments'
              )
            "
          >
            <mat-expansion-panel-header
              class="px-2 flex flex-col md:flex-row h-fit gap-0 mat-expansion-indicator-less-padding"
            >
              <div
                class="p-3 md:p-6 md:border-r border-mat-ef-revolution-supergrey-500 text-mat-ef-revolution-primary-500 flex flex-col w-full md:w-[303px] md:grow-0 md:shrink-0 items-center md:items-start"
              >
                <div class="text-base">
                  <T
                    str="Date"
                    key="customerPortal.customer-portal.vehicle.date"
                    tags="customer-portal, 3.1"
                  ></T>
                </div>
                <div class="text-xl md:text-2xl font-black">
                  {{ appointmentsService.getDayMonth(appointment.startDate) }}
                  {{ appointmentsService.getYear(appointment.startDate) }}
                </div>
                <div
                  class="text-lg font-semibold text-mat-ef-revolution-grey-two-500"
                >
                  <ng-container *ngIf="!appointment.requested">
                    {{ appointmentsService.getHour(appointment.startDate) }}
                    <ng-container *ngIf="appointment.endDate">
                      -
                      {{ appointmentsService.getHour(appointment.endDate) }}
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="appointment.requested">
                    {{
                      {
                        str: appointment.timeOfDay?.toLowerCase(),
                        prefix:
                          'customerPortal.customer-portal.appointments.times.'
                      } | transifexTranslate
                    }}
                  </ng-container>
                </div>
              </div>
              <mat-divider class="md:hidden"></mat-divider>
              <div class="flex-1 flex flex-col lg:flex-row overflow-auto">
                <div
                  class="pt-3 md:pt-6 px-0 md:p-4 lg:p-6 flex gap-2 md:gap-4 lg:gap-6 w-full lg:w-1/2 flex-1 justify-center md:justify-start"
                >
                  <div class="flex flex-col justify-center gap-2 items-center">
                    <div
                      class="flex-1 image-wrapper relative max-h-[60px] max-w-[100px] min-w-[100px] flex content-center items-center"
                    >
                      <img
                        class="h-auto w-auto object-contain rounded m-auto max-h-full max-w-[calc(100%_-_10px)]"
                        *ngIf="appointment.brandPicture"
                        src="{{ appointment.brandPicture }}"
                        alt="brand image"
                      />
                      <img
                        class="object-contain m-auto w-full h-full"
                        *ngIf="!appointment.brandPicture"
                        src="assets/ef-logo.svg"
                        alt="ef logo"
                      />
                    </div>
                    <ng-container *ngIf="appointment.licensePlate">
                      <div
                        data-cy="appointments-appointment-license-plate"
                        class="w-fit px-2 py-1 font-efSans font-semibold text-mat-ef-revolution-secondary-500 !text-sm rounded-[3px] border border-mat-ef-revolution-supergrey-500"
                      >
                        <ng-container *ngIf="licensePlateMask">{{
                          appointment.licensePlate | mask: licensePlateMask
                        }}</ng-container>
                        <ng-container *ngIf="!licensePlateMask"
                          >{{ appointment.licensePlate || '&emsp;-&emsp;' }}
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>

                  <div
                    class="flex flex-col min-w-[200px] text-mat-ef-revolution-primary-500"
                  >
                    <div
                      class="text-base"
                      data-cy="appointments-appointment-brand-model"
                    >
                      {{ appointment.brand }} {{ appointment.model }}
                    </div>
                    <div class="text-xl md:text-2xl font-black">
                      <ng-container *ngIf="!appointment.requested">
                        {{ appointment.typeName }}
                      </ng-container>
                      <ng-container
                        *ngIf="appointment.requested && appointment.serviceTypes?.[0]"
                      >
                        <ng-container
                          *ngFor="
                            let service of appointment.serviceTypes;
                            last as last
                          "
                        >
                          <span>
                            {{
                              {
                                str: service.toLowerCase(),
                                prefix:
                                  'customerPortal.customer-portal.appointments.services.'
                              } | transifexTranslate
                            }}<ng-container *ngIf="!last">,</ng-container>
                          </span>
                        </ng-container>
                      </ng-container>
                    </div>
                    <div class="text-lg font-semibold flex items-center gap-2">
                      <mat-icon
                        class="w-6 h-6 grow-0 shrink-0"
                        svgIcon="location_on"
                      ></mat-icon>
                      <div class="flex-1 leading-5 text-sm md:text-lg">
                        {{ sharedService.getDealerSubname(appointment.dealer) }}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="checkAppointment(appointment)"
                  class="w-full lg:w-28 lg:grow-0 lg:shrink-0 flex pt-2 md:pt-[-16px] md:pb-4 lg:pt-6 lg:px-0"
                >
                  <!-- Live appointment status warning -->
                  <ng-container *ngIf="hasLiveStatusAppointments">
                    <div
                      class="text-sm whitespace-normal uppercase font-bold text-center md:text-right flex-1 text-mat-ef-revolution-red-500 animation-pulse"
                    >
                      <T
                        str="Status"
                        [key]="
                          'customerPortal.customer-portal.appointment.tracking.tracking-status.' +
                          appointment?.status
                        "
                        tags="customer-portal, 3.5"
                      ></T>
                    </div>
                  </ng-container>
                </div>

                <div
                  *ngIf="appointment.requested"
                  class="w-full lg:w-28 lg:grow-0 lg:shrink-0 flex pt-2 md:pt-[-16px] md:pb-4 lg:pt-6 lg:px-0"
                >
                  <div
                    class="text-sm whitespace-normal uppercase font-bold text-center md:text-right flex-1 text-mat-ef-revolution-secondary-500"
                  >
                    <T
                      str="Requested"
                      key="customerPortal.customer-portal.appointments.requested"
                      tags="customer-portal, 3.1"
                    ></T>
                  </div>
                </div>
              </div>
            </mat-expansion-panel-header>

            <div class="flex flex-col">
              <div class="w-full">
                <mat-divider
                  *ngIf="checkAppointment(appointment, true)"
                  class="mx-6"
                ></mat-divider>
                <div
                  class="text-center py-6 px-2 md:px-12"
                  *ngIf="checkAppointment(appointment, true)"
                >
                  <div
                    class="text-xl md:text-2xl mb-10 md:mb-6 text-mat-ef-revolution-primary-500 font-efSans font-black"
                  >
                    <T
                      str="LIVE-TRACKING"
                      key="customerPortal.customer-portal.appointments.live-tracking"
                      tags="customer-portal, 3.5"
                    ></T>
                    <ng-container *ngIf="checkAppointment(appointment)">
                      : &nbsp;
                      <T
                        str="Status"
                        [key]="
                          'customerPortal.customer-portal.appointment.tracking.tracking-status.' +
                          appointment?.status
                        "
                        tags="customer-portal, 3.5"
                      ></T>
                    </ng-container>
                  </div>
                  <div class="w-full lg:w-3/4 m-auto">
                    <customer-portal-tracker-stepper
                      [stepList]="stepList"
                      [data]="appointment"
                      [mode]="'appointment'"
                      [prefix]="
                        'customerPortal.customer-portal.appointment.tracking.tracking-status.'
                      "
                    ></customer-portal-tracker-stepper>
                  </div>
                </div>
                <mat-divider class="mx-6"></mat-divider>
                <customer-portal-app-revolution-dealership-summary-molecule
                  [setDealer]="appointment.dealer"
                  [view]="'appointment'"
                >
                </customer-portal-app-revolution-dealership-summary-molecule>
              </div>
              <div class="w-full">
                <div class="w-full p-10" *ngIf="appointment.loadingDetails">
                  <mat-divider class="mx-6"></mat-divider>
                  <ngx-skeleton-loader
                    class="max-w-full"
                    appearance="line"
                    animation="progress"
                  >
                  </ngx-skeleton-loader>
                </div>

                <div
                  *ngIf="
                    !appointment.loadingDetails &&
                    !appointment.requested &&
                    (appointment.salesmanUser || appointment.salesAdvisor)
                  "
                  class="w-full"
                >
                  <mat-divider class="mx-6"></mat-divider>
                  <customer-portal-app-revolution-dealership-contact-molecule
                    [dealer]="appointment.dealer"
                    [salesAdvisor]="
                      appointment.salesmanUser || appointment.salesAdvisor
                    "
                    [view]="'appointment'"
                  >
                  </customer-portal-app-revolution-dealership-contact-molecule>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
      </div>
    </ng-container>

    <div
      class="mt-14 w-full bg-mat-ef-revolution-grey-500 rounded-xl flex flex-col md:flex-row gap-2 px-6 py-4"
    >
      <div class="flex flex-col flex-1 text-mat-ef-revolution-primary-500">
        <div class="font-efSans text-xl font-black">
          <T
            str="Book an appointment"
            key="customerPortal.customer-portal.appointments.make-appointment"
            tags="customer-portal, 3.1"
          ></T>
        </div>
        <div class="font-efSans text-base">
          <T
            str="You can simply book an appointment online"
            key="customerPortal.customer-portal.appointments.make-appointment.text"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </div>
      <div class="flex justify-center items-center w-full md:w-fit">
        <div
          data-cy="add-new-appointment-button"
          (click)="
            newAppointment();
            sendContentModulesCTAData(
              'customerPortal.customer-portal.summary.appointments.new'
            )
          "
          class="new-appointment-btn w-full md:w-fit"
        >
          <T
            str="New appointment"
            key="customerPortal.customer-portal.summary.appointments.new"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </div>
    </div>
  </div>
</div>
