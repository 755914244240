<div class="vehicle-boxes-rounded">
  <p class="font-efSans text-xl font-bold mb-2">
    <T
      str="Parts and accessories"
      key="customerPortal.customer-portal.parts-and-accessories"
      tags="customer-portal, 3.1"
    ></T>
  </p>
  <p class="font-efSans text-sm">
    <T
      str="Quality in every detail."
      key="customerPortal.customer-portal.quality-in-every-detail"
      tags="customer-portal, 3.1"
    ></T>
  </p>

  <a
    [href]="link"
    target="_blank"
    (click)="
      sendContentModulesCTAData('customerPortal.customer-portal.to-shop')
    "
    class="revolution medium-button mt-6"
    mat-button
    mat-stroked-button
    color="secondary"
  >
    <T
      str="To the shop"
      key="customerPortal.customer-portal.to-shop"
      tags="customer-portal, 3.1"
    ></T>

    <mat-icon svgIcon="arrow_right"></mat-icon>
  </a>
</div>
