<livechat-widget
  *ngIf="!dealerLoading && liveChatAvailable && showChat"
  [group]="groupId"
  #liveChatWidget
  [license]="liveChatLicenceId"
  (onReady)="onChatLoaded()"
  (onVisibilityChanged)="onVisibilityChanged($event)"
  [customerName]="customerName"
  [customerEmail]="customerEmail"
  [visibility]="visibility"
  [sessionVariables]="sessionVariables"
>
</livechat-widget>

<div
  class="RevolutionVehicleDealersComponent flex flex-col gap-6"
  data-cy="preferred-dealer"
>
  <div
    *ngIf="
      dealerLoading &&
      !(vehicle?.dealers?.salesDealer || vehicle?.dealers?.serviceDealer)
    "
  >
    <ngx-skeleton-loader appearance="line" [count]="12" animation="progress">
    </ngx-skeleton-loader>
  </div>

  <!-- No dealer-->
  <div
    class="flex flex-col gap-6"
    *ngIf="
      vehicle &&
      vehicle?.dealers &&
      !vehicle?.dealers.salesDealer &&
      !vehicle?.dealers.serviceDealer &&
      !dealerLoading
    "
  >
    <div>
      <T
        str="No dealer information."
        key="customerPortal.customer-portal.vehicle.no-dealer"
        tags="customer-portal, 3.1"
      ></T>
    </div>
    <div class="flex gap-4 md:gap-6 flex-col md:flex-row">
      <button
        data-cy="add-preferred-dealer-button"
        *ngIf="!vehicle?.business"
        mat-stroked-button
        color="primary"
        (click)="changeDealerSeller('sales')"
        class="!border-mat-ef-revolution-secondary-500 !border-2 !text-mat-ef-revolution-secondary-500"
      >
        <T
          str="Select dealer"
          key="customerPortal.customer-portal.change-dealer-seller.select-dealer"
          tags="customer-portal, 3.1"
        ></T>
      </button>
      <a *ngIf="!dealerLoading" (click)="gotoLocation()">
        <!--Add dealer button -->
        <button
          mat-stroked-button
          color="primary"
          class="!border-mat-ef-revolution-secondary-500 !border-2 !text-mat-ef-revolution-secondary-500 w-full md:w-fit"
        >
          <T
            str="To locations"
            key="customerPortal.customer-portal.dealer.to-locations"
            tags="customer-portal, 3.1"
          ></T>
          <mat-icon svgIcon="arrow_right"></mat-icon>
        </button>
      </a>
    </div>
  </div>

  <!-- Case 1: Sales & service dealerships are different -->
  <ng-container *ngIf="!sameDealerAndService">
    <div class="flex flex-col w-full gap-6">
      <!-- Sales dealer -->
      <ng-container *ngIf="vehicle?.dealers?.salesDealer">
        <ng-container *ngTemplateOutlet="sales"> </ng-container>
      </ng-container>

      <!-- Service dealer -->
      <ng-container *ngIf="vehicle?.dealers?.serviceDealer">
        <ng-container *ngTemplateOutlet="service"> </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <!--Case 2: Sales & service dealerships are the same, or no service dealer -->
  <ng-container *ngIf="sameDealerAndService">
    <ng-container *ngIf="vehicle?.dealers?.salesDealer">
      <!-- Sales dealer -->
      <!-- service contact -->
      <ng-container *ngTemplateOutlet="sales"> </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #sales>
  <div class="flex flex-col" data-cy="sales-dealer">
    <div
      *ngIf="!dealerLoading"
      class="flex items-center text-mat-ef-revolution-primary-500 h-16 font-efSans text-xl font-black"
    >
      <ng-container *ngIf="sameDealerAndService">
        <T
          str="Service and sales"
          key="customerPortal.customer-portal.vehicle.sales-and-service"
          tags="customer-portal, 3.1"
        ></T>
      </ng-container>
      <ng-container *ngIf="!sameDealerAndService">
        {{ 'sale' | sharedTranslate }}
      </ng-container>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-10 gap-6">
      <div class="col-span-1 lg:col-span-7">
        <div class="flex gap-6 flex-col">
          <!--Dealership summary-->
          <div *ngIf="dealerLoading">
            <ngx-skeleton-loader
              appearance="line"
              [count]="4"
              animation="progress"
            >
            </ngx-skeleton-loader>
          </div>

          <div *ngIf="!dealerLoading" class="w-full">
            <customer-portal-app-revolution-dealership-summary-molecule
              [dealertype]="'sales'"
              [setDealer]="vehicle.dealers.salesDealer"
              [vehicle]="vehicle"
              (changeDealerSeller)="changeDealerSeller('sales')"
            >
            </customer-portal-app-revolution-dealership-summary-molecule>
          </div>

          <!-- Sales contact molecule-->

          <div class="w-full" *ngIf="vehicle.dealers.salesDealer.salesman">
            <div *ngIf="dealerLoading">
              <ngx-skeleton-loader
                appearance="line"
                [count]="3"
                animation="progress"
              >
              </ngx-skeleton-loader>
            </div>
            <div *ngIf="!dealerLoading" class="w-full">
              <customer-portal-app-revolution-dealership-contact-molecule
                [vehicle]="vehicle"
                [dealer]="vehicle.dealers.salesDealer"
                [salesman]="vehicle.dealers.salesDealer.salesman"
              >
              </customer-portal-app-revolution-dealership-contact-molecule>
            </div>
          </div>

          <ng-container *ngIf="sameDealerAndService">
            <!-- Service -->
            <div
              class="w-full"
              *ngIf="
                vehicle.dealers.serviceDealer?.salesAdvisor ||
                vehicle.dealers.salesDealer?.salesAdvisor
              "
            >
              <div *ngIf="dealerLoading">
                <ngx-skeleton-loader
                  appearance="line"
                  [count]="3"
                  animation="progress"
                >
                </ngx-skeleton-loader>
              </div>
              <div *ngIf="!dealerLoading" class="w-full">
                <!-- No sales advisor in "serviceDealer", take it from "salesDealer" -->
                <!-- Otherwise take it from "serviceDealer" -->
                <customer-portal-app-revolution-dealership-contact-molecule
                  [vehicle]="vehicle"
                  [dealer]="
                    vehicle.dealers.serviceDealer?.salesAdvisor
                      ? vehicle.dealers.serviceDealer
                      : vehicle.dealers.salesDealer
                  "
                  [salesAdvisor]="
                    vehicle.dealers.serviceDealer?.salesAdvisor
                      ? vehicle.dealers.serviceDealer.salesAdvisor
                      : vehicle.dealers.salesDealer.salesAdvisor
                  "
                >
                </customer-portal-app-revolution-dealership-contact-molecule>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="col-span-1 lg:col-span-3">
        <div
          *ngIf="dealerLoading"
          class="flex justify-center items-center h-full border rounded-xl"
        >
          <customer-portal-ef-spinner
            [color]="'mat-ef-revolution-grey-two-500'"
            [opacity]="0.6"
          ></customer-portal-ef-spinner>
        </div>
        <div *ngIf="!dealerLoading" class="w-full">
          <customer-portal-app-revolution-dealer-info
            [vehicle]="vehicle"
            [dealer]="vehicle.dealers.salesDealer"
            [type]="'sales'"
            [liveChatAvailable]="liveChatAvailable"
            (openChatWindow)="openChatWindow($event)"
          >
          </customer-portal-app-revolution-dealer-info>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #service>
  <div class="flex flex-col">
    <ng-container *ngIf="!dealerLoading && !sameDealerAndService">
      <div
        class="flex items-center text-mat-ef-revolution-primary-500 h-16 font-efSans text-xl font-black"
      >
        {{ 'service' | sharedTranslate }}
      </div>
    </ng-container>
    <div class="grid grid-cols-1 lg:grid-cols-10 gap-6">
      <div class="col-span-1 lg:col-span-7">
        <div class="flex gap-6 flex-col">
          <!--Dealership summary-->
          <div *ngIf="dealerLoading">
            <ngx-skeleton-loader
              appearance="line"
              [count]="4"
              animation="progress"
            >
            </ngx-skeleton-loader>
          </div>

          <div *ngIf="!dealerLoading" class="w-full">
            <customer-portal-app-revolution-dealership-summary-molecule
              [dealertype]="'service'"
              [setDealer]="vehicle.dealers.serviceDealer"
              [vehicle]="vehicle"
              (changeDealerSeller)="changeDealerSeller('service')"
            >
            </customer-portal-app-revolution-dealership-summary-molecule>
          </div>

          <!-- Sales contact molecule-->

          <div
            class="w-full"
            *ngIf="vehicle.dealers.serviceDealer.salesAdvisor"
          >
            <div *ngIf="dealerLoading">
              <ngx-skeleton-loader
                appearance="line"
                [count]="3"
                animation="progress"
              >
              </ngx-skeleton-loader>
            </div>
            <div class="w-full" *ngIf="!dealerLoading">
              <customer-portal-app-revolution-dealership-contact-molecule
                [vehicle]="vehicle"
                [dealer]="vehicle.dealers.serviceDealer"
                [salesAdvisor]="vehicle.dealers.serviceDealer.salesAdvisor"
              >
              </customer-portal-app-revolution-dealership-contact-molecule>
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-1 lg:col-span-3">
        <div
          *ngIf="dealerLoading"
          class="flex justify-center items-center h-full border rounded-xl"
        >
          <customer-portal-ef-spinner
            [color]="'mat-ef-revolution-grey-two-500'"
            [opacity]="0.6"
          ></customer-portal-ef-spinner>
        </div>
        <div *ngIf="!dealerLoading" class="w-full">
          <customer-portal-app-revolution-dealer-info
            [vehicle]="vehicle"
            [dealer]="vehicle.dealers.serviceDealer"
            [type]="'service'"
            [liveChatAvailable]="liveChatAvailable"
            (openChatWindow)="openChatWindow($event)"
          >
          </customer-portal-app-revolution-dealer-info>
        </div>
      </div>
    </div>
  </div>
</ng-template>
