import { Component } from '@angular/core'
import { TransifexService } from '../../services/transifex.service'
import { SharedTranslationsService } from '../../services/shared-translations.service'

@Component({
  selector: 'customer-portal-lib-cpt-templates',
  templateUrl: './cpt-templates.component.html'
})
export class CptTemplatesComponent {
  constructor(
    public transifexService: TransifexService,
    public sharedTranslationsService: SharedTranslationsService
  ) {}
}
