import { Component, Inject, type OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { SharedService } from '../../../services/shared.service'
import { FormControl, FormGroup } from '@angular/forms'
import { debounceTime, distinctUntilChanged } from 'rxjs'

@Component({
  selector: 'customer-portal-app-select-options-popup',
  templateUrl: './select-options-popup.component.html'
})
export class SelectOptionsPopupComponent implements OnInit {
  selected
  array
  showFilter = false
  form = new FormGroup({
    filter: new FormControl<string | null>(null)
  })

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<SelectOptionsPopupComponent>,
    public readonly sharedService: SharedService
  ) {}

  ngOnInit (): void {
    const data = this.sharedService.deepCopy(this.data)
    switch (data?.type) {
      case 'dealer':
        this.selected = data.selectedDealer
        this.array = data.dealers
        this.showFilter = true
        this.form
          .get('filter')
          .valueChanges
          .pipe(debounceTime(500), distinctUntilChanged())
          .subscribe((value: any) => {
            this.array = this.sharedService.filterDealers(
              value,
              data.dealers
            )
          })
        break
    }
  }

  onSubmit (): void {
    this.dialogRef.close({
      selected: this.selected
    })
  }

  clearFilter (): void {
    this.form.get('filter').setValue(null)
  }
}
