import { Component, Inject } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { LegacyErrorStateMatcher as ErrorStateMatcher } from '@angular/material/legacy-core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import {
  User,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from '../../../services/shared.service'
import { PasswordValidationService } from '../../../services/password-validation.service'
import { ConfirmationPopupComponent } from '../../basic/confirmation-popup/confirmation-popup.component'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    return (
      !!(control?.invalid === true && control.dirty && control.parent.dirty) ||
      control?.hasError('notsame') === true ||
      control?.parent.hasError('notsame')
    )
  }
}

@Component({
  selector: 'customer-portal-app-password-change-popup',
  templateUrl: './password-change-popup.component.html'
})
export class PasswordChangePopupComponent {
  currentPasswordForm
  newPasswordForm: FormGroup
  matcher = new MyErrorStateMatcher()

  constructor(
    @Inject(MAT_DIALOG_DATA) public user: User,
    private readonly userService: UserService,
    private readonly logger: NGXLogger,
    private readonly passValidation: PasswordValidationService,
    private readonly formBuilder: FormBuilder,
    public sharedService: SharedService,
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<PasswordChangePopupComponent>,
    public transifexService: TransifexService
  ) {
    this.currentPasswordForm = new FormGroup({
      currentPass: new FormControl<string | null>(null, [
        this.passValidation.password
      ])
    })
    this.newPasswordForm = this.formBuilder.group(
      {
        newPass: new FormControl<string | null>(null, [
          this.passValidation.password
        ]),
        repeatPass: new FormControl<string | null>(null, [
          this.passValidation.password
        ])
      },
      { validator: this.checkPasswords }
    )
  }

  errorShow = false
  disabled = false
  showCurrentPassword = false
  showNewPassword = false
  showRepeatedPassword = false
  saving: boolean

  parentErrorStateMatcher: ErrorStateMatcher

  updatePassword(): void {
    if (this.currentPasswordForm.valid && this.newPasswordForm.valid) {
      this.disabled = true
      const oldPass = this.currentPasswordForm.controls.currentPass.value
      const newPass = this.newPasswordForm.controls.newPass.value
      this.userService.changePassword(oldPass, newPass).subscribe(
        data => {
          this.logger.debug('Request is successful ', data)
          this.errorShow = false
          this.disabled = false
          this.dialogRef.close()
          this.dialog.open(ConfirmationPopupComponent, {
            data: {
              title: 'shared.success',
              text: 'customerPortal.customer-portal.details.change.passChanged'
            },
            panelClass: 'mat-dialog-cpt'
          })
        },
        error => {
          this.logger.error('Error', error)
          this.showError()
          this.disabled = false
        }
      )
    }
  }

  showError(): boolean {
    this.currentPasswordForm.controls.currentPass.setErrors({
      wrongPassword: true
    })
    return (this.errorShow = true)
  }

  toggleShowCurrentPassword(): void {
    this.showCurrentPassword = !this.showCurrentPassword
  }

  toggleShowNewPassword(): void {
    this.showNewPassword = !this.showNewPassword
  }

  toggleShowRepeatedPassword(): void {
    this.showRepeatedPassword = !this.showRepeatedPassword
  }

  checkPasswords(group: FormGroup): {
    notsame: boolean
  } {
    const newPass = group.controls.newPass.value
    const repeatPass = group.controls.repeatPass.value

    let hasError = false
    if (
      group.controls.newPass.dirty &&
      group.controls.repeatPass.dirty &&
      newPass !== repeatPass &&
      repeatPass.length > 0
    ) {
      hasError = true
    }

    return !hasError ? null : { notsame: true }
  }
}
