<div
  class="grid"
  *ngIf="
    !loading && (additionalEquipment.length > 0 || basicEquipment.length > 0)
  "
>
  <div
    class="text-lg md:text-xl font-bold text-mat-ef-revolution-primary-500 mt-5"
  >
    <T
      str="Equipment"
      key="customerPortal.customer-portal.equipment"
      tags="customer-portal, 3.1"
    ></T>
  </div>
  <div *ngIf="loading" class="heading-skeleton">
    <ngx-skeleton-loader
      appearance="line"
      animation="progress"
    ></ngx-skeleton-loader>
    <br />
  </div>

  <div class="p-0">
    <div class="mt-4">
      <!-- <mat-divider></mat-divider> -->

      <div
        class="mt-8 text-mat-ef-revolution-primary-500"
        *ngIf="additionalEquipment.length > 0"
      >
        <div>
          <span class="font-efSans text-xl">
            <T
              str="Special equipment"
              key="customerPortal.customer-portal.vehicle.special.equipment"
              tags="customer-portal, 3.1"
            ></T
          ></span>
        </div>
        <ul class="grid grid-cols-2 gap-2 mt-4 ml-6">
          <li
            *ngFor="let e of additionalEquipment"
            class="font-efSans text-sm sm:text-base break-words mr-8 list-disc"
          >
            {{ e.name }}
          </li>
        </ul>

        <!-- <mat-divider></mat-divider> -->
      </div>
      <div
        class="mt-8 text-mat-ef-revolution-primary-500"
        *ngIf="basicEquipment.length > 0"
      >
        <div>
          <span class="font-efSans text-xl">
            <T
              str="Basic equipment"
              key="customerPortal.customer-portal.vehicle.basic.equipment"
              tags="customer-portal, 3.1"
            ></T
          ></span>
        </div>
        <ul class="grid grid-cols-2 gap-2 mt-4 ml-6">
          <li
            *ngFor="let e of basicEquipment"
            class="font-efSans text-sm sm:text-base break-words mr-8 list-disc"
          >
            {{ e.name }}
          </li>
        </ul>

        <!-- <mat-divider></mat-divider> -->
      </div>
    </div>
  </div>
</div>
