<div
  *ngIf="!loadingNotifications"
  id="appointments"
  class="min-h-full h-fit flex w-full"
>
  <div class="w-full flex flex-col min-h-full h-fit p-4 md:p-6 gap-4 md:gap-6">
    <div
      class="flex basis-1/2 text-mat-ef-revolution-primary-500 items-center gap-6 pl-6"
    >
      <div class="font-bold font-canelablack text-5xl">
        {{ notifications?.attentionsCount || 0 }}
      </div>
      <div class="font-bold font-efSans">
        {{
          {
            key: notificationsKey
          } | transifexTranslate
        }}
      </div>
      <div
        class="font-bold font-canelablack flex justify-center items-center cursor-pointer"
        (click)="
          action.next({
            name: 'goToVehicleNeedsAttention',
            params: firstVehicle
          })
        "
      >
        <mat-icon svgIcon="chevron_right"></mat-icon>
      </div>
    </div>
    <div class="flex basis-1/2 items-center">
      <div class="w-full flex items-center">
        <div
          *ngIf="
            !(
              notifications?.attentionsCount &&
              notifications?.attentionsCount > 0
            )
          "
          (click)="
            sendContentModulesCTAData(
              'customerPortal.customer-portal.notifications.everything-in-the-green'
            )
          "
          class="flex-1 flex justify-center items-center text-green-600 gap-4 pl-4 bg-mat-ef-revolution-supergrey-100 rounded-lg min-h-[60px]"
        >
          <mat-icon svgIcon="car_verified"></mat-icon>
          <span class="flex-1 text-mat-ef-revolution-secondary-500">
            <T
              str="All is good"
              key="customerPortal.customer-portal.notifications.everything-in-the-green"
              tags="customer-portal, 3.1"
            ></T>
          </span>
        </div>

        <div
          *ngIf="(notifications?.attentionsCount && notifications?.attentionsCount > 0 && vehicleNotifications?.[0])"
          class="flex-1 h-full w-full relative"
        >
          <ngx-glide
            #NotificationsComponent_ngxGlide
            [perView]="1"
            [focusAt]="'center'"
            [arrowLeftTemplate]="left"
            [arrowRightTemplate]="right"
            [showBullets]="false"
            [rewind]="false"
            [bound]="true"
            [showArrows]="vehicleNotifications[1] ? true : false"
            [gap]="16"
          >
            <div
              *ngFor="
                let vehicle of vehicleNotifications;
                let first = first;
                let last = last
              "
              (click)="
                sharedService.preventClick ||
                  action.next({
                    name: 'goToVehicleNeedsAttention',
                    params: vehicle
                  })
              "
              (mouseup)="sharedService.handleGliderEvents($event, vehicle.vin)"
              (mousedown)="
                sharedService.handleGliderEvents($event, vehicle.vin)
              "
              class="select-none cursor-pointer flex flex-col lg:flex-row text-mat-ef-revolution-primary-500 relative bg-mat-ef-revolution-supergrey-100 rounded-lg min-h-[60px]"
            >
              <div class="flex-1 flex flex-col py-3 pb-4 px-5 pl-6 gap-3">
                <div class="flex flex-col">
                  <div class="text-lg font-black whitespace-normal">
                    {{ vehicle.brand }} {{ vehicle.model }}
                  </div>
                </div>

                <div class="flex flex-col gap-2">
                  <div
                    *ngFor="let notification of vehicle.vehicleNotifications"
                  >
                    <customer-portal-app-vehicle-warning-indicator
                      [notification]="notification"
                    >
                    </customer-portal-app-vehicle-warning-indicator>
                  </div>
                </div>
              </div>

              <div
                class="flex pb-3 pt-0 md:pt-3 px-5 pr-6 gap-3 h-fit items-center justify-between md:justify-end"
              >
                <div
                  *ngIf="vehicle.licensePlate"
                  class="w-fit h-fit px-2 py-1 font-efSans font-semibold text-mat-ef-revolution-secondary-500 !text-sm rounded-[3px] border border-mat-ef-revolution-supergrey-500"
                >
                  <ng-container *ngIf="licensePlateMask">{{
                    vehicle.licensePlate
                      ? (vehicle.licensePlate | mask: licensePlateMask)
                      : '-'
                  }}</ng-container>
                  <ng-container *ngIf="!licensePlateMask"
                    >{{ vehicle.licensePlate || '-' }}
                  </ng-container>
                </div>
                <div *ngIf="!vehicle.licensePlate" class="max-w-[4px]"></div>

                <div class="relative flex content-center items-center">
                  <img
                    class="object-contain m-auto w-auto h-auto max-h-[48px] max-w-[72px] rounded"
                    *ngIf="vehicle.brandPicture"
                    src="{{ vehicle.brandPicture }}"
                    alt="brand image"
                  />
                  <img
                    class="object-contain m-auto w-full h-full max-h-[48px] max-w-[72px]"
                    *ngIf="!vehicle.brandPicture"
                    src="assets/ef-logo.svg"
                    alt="ef logo"
                  />
                </div>
              </div>
            </div>
          </ngx-glide>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #left>
  <div
    class="arrowTemplate leftArrow flex justify-center items-center sideArrows !-left-5 border-none"
    (click)="
      sendContentModulesSliderData('Left', 'Notifications', 'Notifications')
    "
  >
    <mat-icon svgIcon="chevron_left"></mat-icon>
  </div>
</ng-template>

<ng-template #right>
  <div
    class="arrowTemplate rightArrow flex justify-center items-center sideArrows !-right-5 border-none"
    (click)="
      sendContentModulesSliderData('Right', 'Notifications', 'Notifications')
    "
  >
    <mat-icon svgIcon="chevron_right"></mat-icon>
  </div>
</ng-template>
