import { Inject, Injectable } from '@angular/core'
import { Environment } from '../environments'
import { ENVIRONMENT } from '../injection-tokens'

@Injectable({
  providedIn: 'root'
})
export class IconService {
  constructor (@Inject(ENVIRONMENT) private readonly environment: Environment) {}

  public logoUrl (brand: string): string {
    if (!brand) {
      return null
    }

    const domain = this.environment.domain
    const cleanedBrand = brand.toLowerCase().replace(/ /g, '')
    return `https://${domain}/resources/${cleanedBrand}/production/logo/logo.png`
  }

  public logoSmallUrl (brand: string): string {
    if (!brand) {
      return null
    }

    const domain = this.environment.domain
    const cleanedBrand = brand.toLowerCase().replace(/ /g, '')
    return `https://${domain}/resources/${cleanedBrand}/production/logo/logo_small.png`
  }
}
