import { Component } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { SharedService } from '../../../../services/shared.service'

import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
@Component({
  selector: 'customer-portal-app-revolution-vehicle-online-shop-link',
  templateUrl: './vehicle-online-shop-link.component.html'
})
export class VehicleOnlineShopLinkComponent {
  constructor(
    public dialog: MatDialog,
    public sharedService: SharedService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.link = this.remoteConfigService.get('shopLink')
  }

  link

  sendContentModulesCTAData(contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }
}
