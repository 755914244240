import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { NGXLogger } from 'ngx-logger'
// import { FAQ } from '../mock/faq'
import { SharedService } from './shared.service'
import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'

type FaqDTO = cpt.emilfreydigital.customer_portal_backend.rest.faq.dto.FaqDTO

export interface FaqDTOExtended extends FaqDTO {
  isExpanded?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class FAQService {
  private readonly apiUrl

  private readonly _FAQ = new BehaviorSubject<FaqDTOExtended[]>(null)
  onFAQLoaded = this._FAQ.asObservable()

  public loading = {
    FAQ: new BehaviorSubject<boolean>(true),
    updatingLikeUnlike: new BehaviorSubject<boolean>(false)
  }

  constructor(
    private readonly http: HttpClient,
    private readonly logger: NGXLogger,
    private readonly sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  FAQLoaded(FAQ: FaqDTOExtended[]): void {
    this._FAQ.next(FAQ)
  }

  getFAQ(lang: string): Observable<FaqDTOExtended[]> {
    // return of(FAQ) // Mock data

    let params = new HttpParams()
    params = params.append('lang', lang)
    return this.http.get<FaqDTOExtended[]>(this.apiUrl + '/faq', {
      params
    })
  }

  public getFAQData(): void {
    const lang = this.sharedService.currentLanguage()
    this.loading.FAQ.next(true)
    this.getFAQ(lang).subscribe({
      next: faq => {
        this.FAQLoaded(faq)
        this.loading.FAQ.next(false)
      },
      error: error => {
        this.logger.log(error)
        this.FAQLoaded(null)
        this.loading.FAQ.next(false)
      }
    })
  }

  putLike(faqId: number): Observable<FaqDTOExtended> {
    return this.http.put<FaqDTOExtended>(
      this.apiUrl + '/faq/like/' + String(faqId),
      {}
    )
  }

  putUnlike(faqId: number): Observable<FaqDTOExtended> {
    return this.http.put<FaqDTOExtended>(
      this.apiUrl + '/faq/unlike/' + String(faqId),
      {}
    )
  }

  likeClicked(item: FaqDTOExtended, originalArray: FaqDTOExtended[]): void {
    this.loading.updatingLikeUnlike.next(true)
    this.putLike(item.id).subscribe({
      next: data => {
        this.loading.updatingLikeUnlike.next(false)
        // update item in current array
        this.updateFAQ(item, data)
        // find item in original array and update it
        this.updateOriginalItem(data, originalArray)
      },
      error: error => {
        this.handleError(error)
      }
    })
  }

  unlikeClicked(item: FaqDTOExtended, originalArray: FaqDTOExtended[]): void {
    this.loading.updatingLikeUnlike.next(true)
    this.putUnlike(item.id).subscribe({
      next: data => {
        this.loading.updatingLikeUnlike.next(false)
        // update item in current array
        this.updateFAQ(item, data)
        // find item in original array and update it
        this.updateOriginalItem(data, originalArray)
      },
      error: error => {
        this.handleError(error)
      }
    })
  }

  updateOriginalItem(
    data: FaqDTOExtended,
    originalArray: FaqDTOExtended[]
  ): void {
    const originalItem = originalArray.find(el => {
      return el.id === data.id
    })
    this.updateFAQ(originalItem, data)
  }

  updateFAQ(item: FaqDTOExtended, res: FaqDTOExtended): void {
    if (item != null && res != null) {
      Object.keys(res).forEach(key => {
        item[key] = res[key]
      })
    }
  }

  handleError(error): void {
    this.logger.log(error)
    this.loading.updatingLikeUnlike.next(false)
    this.sharedService.openConfirmationPopup(
      'shared.error',
      'shared.general-error-message'
    )
  }
}
