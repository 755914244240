<div
  data-cy="add-new-vehicle-form"
  class="cpt-modal ef-scrollbar relative dialog-content-p-0 mat-dialog-actions-pb-20"
  [hidden]="hidden"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="Add vehicle"
      key="customerPortal.customer-portal.add-new-vehicle.title"
      tags="customer-portal, 3.1"
    ></T>
  </h2>
  <mat-dialog-content class="mat-typography" *ngIf="addVehicleForm">
    <form
      class="grid grid-cols-1 md:grid-cols-2 gap-y-2.5 gap-x-5 m-0"
      [formGroup]="addVehicleForm"
    >
      <mat-form-field appearance="legacy" class="relative" cdkScrollable>
        <mat-label> {{ 'brand' | sharedTranslate }}</mat-label>
        <input
          data-cy="add-new-vehicle-form-brand-input"
          required
          type="text"
          aria-label="Number"
          matInput
          formControlName="brand"
          [matAutocomplete]="auto"
          (keyup)="checkOptions(filteredBrands)"
          [errorStateMatcher]="matcher"
          
        />
        <mat-icon
          *ngIf="addVehicleForm.get('brand')?.value"
          class="primary absolute right-0 top-3 opacity-60 cursor-pointer"
          svgIcon="close"
          (click)="clearBrand($event)"
        ></mat-icon>
        <mat-autocomplete #auto="matAutocomplete" >
          <mat-option
            *ngFor="let brand of filteredBrands"
            [value]="brand.name | titlecase"
            (click)="getBrandModels()"
          >
            {{ brand.name | titlecase }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="legacy" class="relative" cdkScrollable>
        <mat-label>{{ 'model' | sharedTranslate }}</mat-label>
        <input
          data-cy="add-new-vehicle-form-model-input"
          [readonly]="addVehicleForm.get('brand').errors ? true : false"
          required
          type="text"
          aria-label="Number"
          matInput
          formControlName="model"
          [matAutocomplete]="modelAutocomplete"
          (keyup)="checkOptionsModels(filteredModels)"
          [errorStateMatcher]="matcher"
          
        />
        <mat-icon
          *ngIf="addVehicleForm.get('model')?.value"
          class="primary absolute right-0 top-3 opacity-60 cursor-pointer"
          svgIcon="close"
          (click)="clearModel($event)"
        ></mat-icon>
        <mat-autocomplete #modelAutocomplete="matAutocomplete" >
          <mat-option
            *ngFor="let model of filteredModels"
            [value]="model.name | titlecase"
          >
            {{ model.name | titlecase }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="legacy">
        <mat-label>
          <T
            str="Model line"
            key="customerPortal.customer-portal.add-new-vehicle.modelLine"
            tags="customer-portal, 3.1"
          ></T
        ></mat-label>
        <input
          matInput
          formControlName="modelLine"
          [errorStateMatcher]="matcher"
        />
        <mat-error *ngIf="addVehicleForm.get('modelLine').errors">
          {{
            transifexService.getErrorTranslation(
              addVehicleForm.get('modelLine'),
              'customerPortal.customer-portal.add-new-vehicle.error.'
            )
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="legacy">
        <mat-label
          ><T
            str="Vehicle identification number (VIN)"
            key="customerPortal.customer-portal.add-new-vehicle.vin"
            tags="customer-portal, 3.1"
          ></T
        ></mat-label>
        <input
          data-cy="add-new-vehicle-form-vin-input"
          pattern="^[a-zA-Z0-9]+$"
          oninput="this.value = this.value.toUpperCase()"
          matInput
          required
          formControlName="vin"
          [errorStateMatcher]="matcher"
        />
        <mat-error
          *ngIf="addVehicleForm.get('vin').errors"
          data-cy="add-new-vehicle-form-vin-error"
        >
          {{
            transifexService.getErrorTranslation(
              addVehicleForm.get('vin'),
              'customerPortal.customer-portal.add-new-vehicle.error.vin.'
            )
          }}
        </mat-error>
      </mat-form-field>

      <div class="w-full relative mt-4 md:mt-0">
        <mat-checkbox
          data-cy="add-new-vehicle-form-other-LP-checkbox"
          class="h-6 text-sm absolute top-[-22px] right-0 font-normal"
          color="primary"
          #checkbox_otherLicencePlate
          (change)="otherLicencePlateChanged(checkbox_otherLicencePlate)"
        >
          <T
            str="Other license plate"
            key="customerPortal.customer-portal.add-new-vehicle.otherlicencePlate"
            tags="customer-portal, 3.8"
          ></T>
        </mat-checkbox>

        <ng-container *ngIf="otherLicencePlateCheckbox">
          <mat-form-field appearance="legacy" class="w-full">
            <mat-label>
              <T
                str="License plate"
                key="customerPortal.customer-portal.add-new-vehicle.licencePlate"
                tags="customer-portal, 3.1"
              ></T
            ></mat-label>
            <input
            data-cy="add-new-vehicle-form-other-LP-input"
              oninput="this.value = this.value.toUpperCase()"
              matInput
              required
              formControlName="otherLicencePlate"
              [errorStateMatcher]="matcher"
            />
            <mat-error
              *ngIf="addVehicleForm.get('otherLicencePlate').errors"
              data-cy="add-new-vehicle-form-licence-plate-error"
            >
              {{
                transifexService.getErrorTranslation(
                  addVehicleForm.get('otherLicencePlate'),
                  'customerPortal.customer-portal.add-new-vehicle.error.'
                )
              }}
            </mat-error>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="!otherLicencePlateCheckbox">
          <mat-form-field
            appearance="legacy"
            class="w-full"
            *ngIf="country === 'de'"
          >
            <mat-label>
              <T
                str="License plate"
                key="customerPortal.customer-portal.add-new-vehicle.licencePlate"
                tags="customer-portal, 3.1"
              ></T
            ></mat-label>
            <input
              data-cy="add-new-vehicle-form-licence-plate-input"
              oninput="this.value = this.value.toUpperCase()"
              matInput
              required
              formControlName="licencePlateDE"
              [errorStateMatcher]="matcher"
            />
            <mat-error
              *ngIf="addVehicleForm.get('licencePlateDE').errors"
              data-cy="add-new-vehicle-form-licence-plate-error"
            >
              {{
                transifexService.getErrorTranslation(
                  addVehicleForm.get('licencePlateDE'),
                  'customerPortal.customer-portal.add-new-vehicle.error.'
                )
              }}
            </mat-error>
          </mat-form-field>

          <div class="grid grid-cols-5 gap-2.5 w-full" *ngIf="country === 'ch'">
            <mat-form-field
              class="col-span-1 md:col-span-2 hidden md:inline-block"
              appearance="legacy"
            >
              <mat-label
                ><T
                  str="Canton"
                  key="customerPortal.customer-portal.vehicle.details.registration-plate-canton"
                  tags="customer-portal, 3.1"
                ></T
              ></mat-label>
              <mat-select
                data-cy="add-new-vehicle-form-licence-plate-canton-select"
                formControlName="licencePlateCanton"
                required
                [(ngModel)]="canton"
                [errorStateMatcher]="matcher"
              >
                <mat-option *ngFor="let k of cantons" [value]="k.id">
                  {{ k.id }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              class="col-span-1 md:col-span-2 inline-block md:hidden"
              appearance="legacy"
            >
              <mat-label
                ><T
                  str="Canton"
                  key="customerPortal.customer-portal.vehicle.details.registration-plate-canton"
                  tags="customer-portal, 3.1"
                ></T
              ></mat-label>
              <select
                formControlName="licencePlateCanton"
                matNativeControl
                class="pb-0 mt-0.5"
                [(ngModel)]="canton"
                required
              >
                <option *ngFor="let k of cantons" [value]="k.id">
                  {{ k.id }}
                </option>
              </select>
            </mat-form-field>
            <mat-form-field
              class="col-span-4 md:col-span-3"
              appearance="legacy"
            >
              <mat-label
                ><T
                  str="License plate"
                  key="customerPortal.customer-portal.add-new-vehicle.licencePlate"
                  tags="customer-portal, 3.1"
                ></T
              ></mat-label>
              <input
                data-cy="add-new-vehicle-form-licence-plate-input"
                oninput="this.value = this.value.toUpperCase()"
                matInput
                required
                formControlName="licencePlateCH"
                [errorStateMatcher]="matcher"
              />
              <mat-error
                *ngIf="addVehicleForm.get('licencePlateCH').errors"
                data-cy="add-new-vehicle-form-licence-plate-error"
              >
                {{
                  transifexService.getErrorTranslation(
                    addVehicleForm.get('licencePlateCH'),
                    'customerPortal.customer-portal.add-new-vehicle.error.'
                  )
                }}
              </mat-error>
            </mat-form-field>
          </div>
        </ng-container>
      </div>

      <mat-form-field appearance="legacy">
        <mat-label
          ><T
            str="Current Mileage"
            key="customerPortal.customer-portal.add-new-vehicle.currentMileage"
            tags="customer-portal, 3.1"
          ></T
        ></mat-label>
        <input
          data-cy="add-new-vehicle-form-mileage-input"
          type="number"
          pattern="[0-9]+"
          matInput
          formControlName="manualMileage"
          [errorStateMatcher]="matcher"
        />
        <mat-error
          *ngIf="addVehicleForm.get('manualMileage').errors"
          data-cy="add-new-vehicle-form-mileage-error"
        >
          {{
            transifexService.getErrorTranslation(
              addVehicleForm.get('manualMileage'),
              'customerPortal.customer-portal.add-new-vehicle.error.'
            )
          }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="date">
        <!-- prettier-ignore -->
        <input
        data-cy="add-new-vehicle-form-first-registration-input"
          matInput
          (click)="pickerFR.open()"
          [max]="now"
          readonly
          required
          datePickerFormat={{dateFormat}}
          [matDatepicker]="pickerFR"
          placeholder="{{ 'First registration' | translate:{ _key: 'customerPortal.customer-portal.add-new-vehicle.firstRegistration', _tags: 'customer-portal, 3.1'} }}"
          formControlName="firstRegistration"
        />
        <mat-datepicker-toggle
          class="calendar-size"
          matPrefix
          [for]="pickerFR"
        ></mat-datepicker-toggle>
        <mat-datepicker
          [calendarHeaderComponent]="customHeader"
          #pickerFR
          startView="multi-year"
          (yearSelected)="chosenYearHandler($event, 'firstRegistration')"
          (monthSelected)="
            chosenMonthHandler($event, pickerFR, 'firstRegistration')
          "
          (opened)="addScrolling(pickerFR)"
        >
        </mat-datepicker>
        <span (click)="pickerFR.open()" matSuffix>
          <mat-icon class="primary" svgIcon="create"></mat-icon>
        </span>
        <mat-error *ngIf="addVehicleForm.controls.firstRegistration.invalid">
          {{
            transifexService.getErrorTranslation(
              addVehicleForm.controls.firstRegistration,
              'customerPortal.customer-portal.add-new-vehicle.error.'
            )
          }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="date">
        <!-- prettier-ignore -->
        <input
          matInput
          (click)="pickerLE.open()"
          [min]="now"
          [max]="fiveYearsAfter"
          readonly
          [matDatepicker]="pickerLE"
          datePickerFormat={{dateFormat}}
          placeholder="{{ 'Lease expires' | translate:{ _key: 'customerPortal.customer-portal.add-new-vehicle.leasingExpiry', _tags: 'customer-portal, 3.1'} }}"
          formControlName="leasingExpiry"
        />
        <mat-datepicker-toggle
          class="calendar-size"
          matPrefix
          [for]="pickerLE"
        ></mat-datepicker-toggle>
        <mat-datepicker
          [calendarHeaderComponent]="customHeader"
          #pickerLE
          startView="multi-year"
          (yearSelected)="chosenYearHandler($event, 'leasingExpiry')"
          (monthSelected)="
            chosenMonthHandler($event, pickerLE, 'leasingExpiry')
          "
          (opened)="addScrolling(pickerLE)"
        >
        </mat-datepicker>
        <span (click)="pickerLE.open()" matSuffix>
          <mat-icon class="primary" svgIcon="create"></mat-icon>
        </span>
        <mat-error *ngIf="addVehicleForm.controls.leasingExpiry.invalid">
          {{
            transifexService.getErrorTranslation(
              addVehicleForm.controls.leasingExpiry,
              'customerPortal.customer-portal.add-new-vehicle.error.'
            )
          }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="date">
        <!-- prettier-ignore -->
        <input
          matInput
          (click)="picker.open()"
          [min]="now"
          [max]="fiveYearsAfter"
          readonly
          [matDatepicker]="picker"
          datePickerFormat={{dateFormat}}
          placeholder="{{ 'Next appointment' | translate:{ _key: 'customerPortal.customer-portal.add-new-vehicle.nextService', _tags: 'customer-portal, 3.1'} }}"
          formControlName="nextService"
        />
        <mat-datepicker-toggle
          class="calendar-size"
          matPrefix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker
          [calendarHeaderComponent]="customHeader"
          #picker
          startView="multi-year"
          (yearSelected)="chosenYearHandler($event, 'nextService')"
          (monthSelected)="chosenMonthHandler($event, picker, 'nextService')"
          (opened)="addScrolling(picker)"
        >
        </mat-datepicker>
        <span (click)="picker.open()" matSuffix>
          <mat-icon class="primary" svgIcon="create"></mat-icon>
        </span>
        <mat-error *ngIf="addVehicleForm.controls.nextService.invalid">
          {{
            transifexService.getErrorTranslation(
              addVehicleForm.controls.nextService,
              'customerPortal.customer-portal.add-new-vehicle.error.'
            )
          }}
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>

    <button
      data-cy="add-new-vehicle-form-submit"
      id="saveButton"
      [disabled]="saving || !addVehicleForm.valid"
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution w-full md:w-auto text-base h-12 px-20"
      (click)="addVehicle()"
      cdkFocusInitial
    >
      <mat-spinner *ngIf="saving" diameter="20"></mat-spinner>
      {{ 'save' | sharedTranslate }}
    </button>
  </mat-dialog-actions>
</div>
