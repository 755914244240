<div class="cpt-modal relative dialog-content-p-0 mat-dialog-actions-pb-20">
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="LiveChat"
      key="customerPortal.customer-portal.live-chat.dialog.title"
      tags="customer-portal, 3.1"
    ></T>
  </h2>

  <mat-dialog-content class="mx-0" *ngIf="data">
    <span>
      <T
        str="Are you sure you wish to start LiveChat?"
        key="customerPortal.customer-portal.live-chat.dialog.text"
        tags="customer-portal, 3.1"
      ></T
    ></span>
  </mat-dialog-content>

  <mat-divider></mat-divider>

  <mat-dialog-actions>
    <button
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      cdkFocusInitial
      (click)="save()"
    >
      <T
        str="Start LiveChat"
        key="customerPortal.customer-portal.live-chat.dialog.start"
        tags="customer-portal, 3.1"
      ></T>
    </button>

    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </mat-dialog-actions>
</div>
