import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { MatMenu } from '@angular/material/menu'
import { DomSanitizer } from '@angular/platform-browser'
import {
  User,
  UserLanguage,
  UserService,
  UserUpdateRequest
} from '@inside-hub-app/customer-portal-b2c-client'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import { TRANSLATION_PREFIX } from '@inside-hub-app/ef-ng-pp-client'
import { TranslateService } from '@ngx-translate/core'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from '../../../../services/shared.service'
import { DataService } from '../../../../services/data.service'
import { LanguageService } from '../../../../services/language.service'

@Component({
  selector: 'customer-portal-language-change-menu',
  templateUrl: './language-change-menu.component.html',
  providers: [
    { provide: TRANSLATION_PREFIX, useValue: 'customerPortal.customer-portal.' }
  ]
})
export class LanguageChangeMenuComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenu, { static: true }) menu: MatMenu
  @Output() saved = new EventEmitter()

  primaryLang = ''
  languages = []
  error = null
  saving = false
  kcUpdate = false
  user: User

  sub = {
    onUserLoaded: null,
    onLanguageChange: null
  }

  constructor(
    private readonly translateService: TranslateService,
    private readonly userService: UserService,
    public logger: NGXLogger,
    private readonly languageService: LanguageService,
    private readonly dataService: DataService,
    public sanitizer: DomSanitizer,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.languageService.getLanguages().subscribe(value => {
      this.languages = value
    })

    this.sub.onLanguageChange = this.dataService.onLanguageChange.subscribe(
      newLanguage => {
        this.primaryLang = newLanguage.toUpperCase()
      }
    )

    this.sub.onUserLoaded = this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
      if (user != null) {
        this.primaryLang = this.user.language.find(s => s.primary).language
      }
    })
  }

  ngOnDestroy(): void {
    this.sharedService.unsubscribe(this.sub)
  }

  sendGTMNavigationData(label?: string, linkType?: string): void {
    this.cptGtmService.sendNavigationLinkData(
      null,
      'Main Navigation',
      this.sharedService.translateLink(label),
      linkType
    )
  }

  changeLanguage(lan: string): void {
    this.dataService.changeLanguageStarted()
    this.saving = true
    const us: User = this.user
    const lang: UserLanguage = {
      language: lan.toUpperCase(),
      primary: true
    }
    const langs = this.user.language
    langs.push(lang)
    const userUpdate: UserUpdateRequest = {
      language: langs
    }
    us.language.splice(0, us.language.length)
    us.language.push(lang)
    this.primaryLang = this.user.language.find(s => s.primary).language
    this.translateService.use(lan.toLowerCase())
    this.userService.putUser(userUpdate, '', true).subscribe(
      data => {
        this.logger.debug('Request is successful ', data)
        this.saved.emit()
        this.dataService.languageChanged(lan.toLowerCase())
        this.saving = false
      },
      error => {
        this.logger.error(error)
        this.error = error
        this.saving = false
      }
    )
  }
}
