import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  RegisterService,
  User,
  UserService,
  UserTelephone
} from '@inside-hub-app/customer-portal-b2c-client'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import {
  CountryCallingCode,
  CountryCode,
  getCountryCallingCode,
  parsePhoneNumber
} from 'libphonenumber-js/max'
import { NGXLogger } from 'ngx-logger'
import { delay } from 'rxjs/operators'
import { InstantErrorStateMatcherService } from '../../../services/instant-error-state-matcher.service'
import { SharedService } from '../../../services/shared.service'
@Component({
  selector: 'customer-portal-app-company-number-landline',
  templateUrl: './company-number-landline.component.html'
})
export class CompanyNumberLandlineComponent implements OnInit {
  matcher = new InstantErrorStateMatcherService()
  public step: 'change' | 'verify' | 'done' = 'change'
  public maxLength
  disable = false
  error = false
  public changeForm

  private readonly mobilePrefixCountries: string[]
  public mobilePrefixes: { [key: string]: CountryCallingCode } = {}

  public code: string
  public resendDisabled = false

  public loading: boolean
  disabled = false
  sending = false
  sameNumber = false
  public telephone = null
  public mobileOld: string
  public mobileBackup: string

  public phoneMask: string
  constructor(
    private readonly dialogRef: MatDialogRef<CompanyNumberLandlineComponent>,
    @Inject(MAT_DIALOG_DATA) public user: User,
    private readonly userService: UserService,
    private readonly registerService: RegisterService,
    private readonly logger: NGXLogger,
    public sharedService: SharedService,
    private readonly cdRef: ChangeDetectorRef,
    public transifexService: TransifexService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    dialogRef.disableClose = true
    this.maxLength = this.remoteConfigService.get(
      'regEx.telephoneRegEx.maxLength'
    )
    this.mobilePrefixCountries =
    this.remoteConfigService.get('b2c.mobileCountryPrefixes')

    this.changeForm = new FormGroup({
      mobilePrefix: new FormControl<string | null>(null),
      mobile: new FormControl<string | null>(null, [
        control => {
          return this.sharedService.landLineValidator(
            control,
            this.changeForm,
            this.mobilePrefixes
          )
        }
      ])
    })
  }

  ngOnInit(): void {
    this.load()

    // fill calling codes
    this.mobilePrefixCountries.forEach(countryCode => {
      this.mobilePrefixes[countryCode] = getCountryCallingCode(
        countryCode as CountryCode
      )
    })

    if (this.mobilePrefixCountries.length === 1) {
      this.changeForm.controls.mobilePrefix.setValue(
        '+' + this.mobilePrefixes[this.mobilePrefixCountries[0]].valueOf()
      )
    }

    // validate again after changing prefix
    this.changeForm.controls.mobilePrefix.valueChanges
      .pipe(delay(1))
      .subscribe(prefix => {
        this.phoneMask = this.sharedService.setPhoneMask(prefix)
        setTimeout(() => {
          this.changeForm.controls.mobile.updateValueAndValidity()
        })
      })

    // If 0 is in the first letter, remove it on post request
    this.changeForm.valueChanges.subscribe(value => {
      this.loading = false
      this.telephone =
        String(value.mobilePrefix) +
        String(
          value.mobile?.[0] === '0' ? value.mobile.substr(1) : value.mobile
        )
      if (this.telephone != null && this.telephone === this.mobileBackup) {
        this.changeForm.controls.mobile.setErrors({
          currentNumber: 'currentNumber'
        })
      }
    })

    // set form values
    this.setFormData()

    this.cdRef.detectChanges()
  }

  load(): void {
    this.loading = true
    this.logger.debug(this.user)
    this.loading = false
  }

  setFormData(): void {
    const phone = this.user?.telephone?.find(el => {
      return el.type === 'landline' && el.usage === 'business' && el.primary
    })
    if (phone != null) {
      const parsed = parsePhoneNumber(phone.number, null)
      this.changeForm
        .get('mobilePrefix')
        .setValue('+' + parsed.countryCallingCode)
      this.changeForm.get('mobile').setValue(parsed.nationalNumber)
      this.mobileBackup = phone.number
    } else {
      // set default
      const country: string = this.remoteConfigService.get('country.code')
      this.changeForm
        .get('mobilePrefix')
        .setValue('+' + this.mobilePrefixes[country.toUpperCase()])
    }
  }

  changeNumber(): void {
    const us: User = {
      contactId: this.user.contactId,
      telephone: this.user.telephone != null ? this.user.telephone : []
    }
    this.loading = true
    this.error = false
    const tel: UserTelephone = {
      number: this.telephone,
      type: 'landline',
      usage: 'business',
      primary: true
    }
    if (
      us.telephone.find(
        s => s.type === 'landline' && s.usage === 'business' && s.primary
      ) != null
    ) {
      us.telephone.find(
        s => s.type === 'landline' && s.usage === 'business' && s.primary
      ).number = this.telephone
    } else {
      us.telephone.push(tel)
    }
    this.userService.putUser(us, null).subscribe(
      data => {
        this.logger.debug('Request is successful ', data)
        this.loading = false
        this.step = 'done'
      },
      () => {
        this.error = true
        this.loading = false
        this.step = 'done'
      }
    )
  }

  close(): void {
    this.dialogRef.close(true)
  }
}
