<div class="mt-10 md:mt-14 px-0 md:px-28 pb-7 md:pb-14">
  <ng-container *ngIf="showContent()">
    <ng-container *ngIf="showMobileView === false">
      <div id="valuations" *ngIf="activeComponent === 'general'">
        <customer-portal-app-revolution-marketplace-valuations
          *ngIf="country === 'de'"
          [valuations]="valuations"
        ></customer-portal-app-revolution-marketplace-valuations>
      </div>

      <!-- Favorites -->
      <div id="favorites" *ngIf="activeComponent === 'favorites'">
        <customer-portal-app-revolution-marketplace-favorites
          *ngIf="country === 'de'"
          [favorites]="favorites"
        ></customer-portal-app-revolution-marketplace-favorites>
      </div>

      <!-- Searchagent -->
      <div id="searchagents" *ngIf="activeComponent === 'searchagent'">
        <customer-portal-app-revolution-marketplace-searchagent
          *ngIf="country === 'de'"
        ></customer-portal-app-revolution-marketplace-searchagent>
        <customer-portal-app-revolution-market-filter
          *ngIf="country === 'ch'"
        ></customer-portal-app-revolution-market-filter>
      </div>
    </ng-container>
    <ng-container *ngIf="showMobileView === true">
      <div #accView></div>
      <div
        (touchstart)="swipe($event, 'start')"
        (touchend)="swipe($event, 'end')"
      >
        <mat-tab-group
          [(selectedIndex)]="swipeData.currentTab"
          class="mat-tab-group_hide-header"
        >
          <mat-tab>
            <div id="valuations">
              <customer-portal-app-revolution-marketplace-valuations
                *ngIf="country === 'de'"
                [valuations]="valuations"
              ></customer-portal-app-revolution-marketplace-valuations>
            </div>
          </mat-tab>
          <mat-tab>
            <!-- Favorites -->
            <div id="favorites">
              <customer-portal-app-revolution-marketplace-favorites
                *ngIf="country === 'de'"
                [favorites]="favorites"
              ></customer-portal-app-revolution-marketplace-favorites>
            </div>
          </mat-tab>
          <mat-tab>
            <!-- Searchagent -->
            <div id="searchagents">
              <customer-portal-app-revolution-marketplace-searchagent
                *ngIf="country === 'de'"
              ></customer-portal-app-revolution-marketplace-searchagent>
              <customer-portal-app-revolution-market-filter
                *ngIf="country === 'ch'"
              ></customer-portal-app-revolution-market-filter>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!showContent()">
    <div
      class="w-full h-96 bg-mat-ef-revolution-grey-200 rounded-xl p-6 flex flex-col gap-6 justify-center items-center"
    >
      <div class="text-2xl md:text-center font-efSans-primary">
        <T
          str="Please give us your consent so that you can use your preferred vehicles and your personal search agent."
          key="customerPortal.customer-portal.consents.preffered-vehicles"
          tags="customer-portal, 3.1.0"
        ></T>
      </div>
      <a
        mat-button
        mat-stroked-button
        color="secondary"
        routerLink="/user/dataprotection"
        class="revolution inline-flex items-center justify-center"
      >
        <T
          str="Consents"
          key="customerPortal.customer-portal.consents.for-consents"
          tags="customer-portal, 3.1.0"
        ></T>
        <mat-icon svgIcon="arrow_right"></mat-icon>
      </a>
    </div>
  </ng-container>
</div>
