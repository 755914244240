<div class="screen cpt-modal relative dialog-content-p-0" *ngIf="dealer">
  <h2
    class="font-canelablack-primary text-4xl"
    *ngIf="country === 'de'"
    mat-dialog-title
  >
    {{ dealer && dealer.name ? dealer.name : '' }}
  </h2>
  <h2
    class="font-canelablack-primary text-4xl"
    *ngIf="country === 'ch'"
    mat-dialog-title
  >
    {{
      dealer && dealer.name ? dealer.name + (', ' + dealer.subname) || '' : ''
    }}
  </h2>
  <mat-dialog-content class="mat-typography max-h-[unset] overflow-y-auto">
    <ng-container>
      <div class="w-full h-full">
        <customer-portal-app-dealer-info
          [dealer]="dealer"
        ></customer-portal-app-dealer-info>
      </div>
    </ng-container>
  </mat-dialog-content>

  <ng-container>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </ng-container>
</div>
