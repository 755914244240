import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  User,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import { SharedService } from '../../../../services/shared.service'
import { DataService } from '../../../../services/data.service'
import { RevolutionUserImgDetailsPopupComponent } from '../user-img-details-popup/user-img-details.component'
@Component({
  selector: 'customer-portal-app-revolution-my-account-head',
  templateUrl: './my-account-head.component.html'
})
export class MyAccountHeadComponent implements OnInit, OnDestroy {
  public user: User
  loading = true
  lang

  sub = {
    onUserLoaded: null,
    onLanguageChange: null
  }

  showAltIcon = false
  profilePictureUrl
  constructor(
    private readonly data: DataService,
    private readonly sharedService: SharedService,
    public cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    this.load()
  }

  load(): void {
    this.sub.onUserLoaded = this.data.onUserLoaded.subscribe(user => {
      this.lang = undefined
      this.user = undefined
      this.cdRef.detectChanges()
      this.showAltIcon = false
      this.loading = false
      this.user = user
      this.lang =
        this.user != null && user.language != null && user.language.length !== 0
          ? user.language.find(s => s.primary).language
          : ''
      this.profilePictureUrl = user.profilePictureUrl
    })
    this.sub.onLanguageChange = this.data.onLanguageChange.subscribe(
      newLanguage => {
        this.lang = undefined
        this.cdRef.detectChanges()
        this.lang = newLanguage.toUpperCase()
      }
    )
  }

  ngOnDestroy(): void {
    this.sharedService.unsubscribe(this.sub)
  }

  openUserImgDetails(): void {
    const dialogRef = this.dialog.open(RevolutionUserImgDetailsPopupComponent, {
      data: this.user,
      panelClass: 'mat-dialog-cpt'
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result?.url != null) {
        this.data.getUser()
      }
    })
  }
}
