/* eslint-disable @typescript-eslint/member-ordering */
import { Component, inject } from '@angular/core'
import { MatSnackBarRef } from '@angular/material/snack-bar'
import { Subject } from 'rxjs'

@Component({
  selector: 'customer-portal-matomo-consent-snackbar',
  templateUrl: './consent-snackbar.component.html'
})
export class ConsentSnackbarComponent {
  private readonly snackBarRef = inject(MatSnackBarRef<any>)

  private readonly _actionClicked = new Subject<'accept' | 'decline'>()
  onActionClicked = this._actionClicked.asObservable()

  protected accept (): void {
    this._actionClicked.next('accept')
    this.snackBarRef.dismiss()
  }

  protected decline (): void {
    this._actionClicked.next('decline')
    this.snackBarRef.dismiss()
  }
}
