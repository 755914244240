import {
  Component,
  EventEmitter,
  Inject,
  type OnInit,
  type OnDestroy,
  Output
} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { SharedService } from '../../../services/shared.service'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'

interface DialogData {
  title: string
  text: string
  translatedText?: string
  cancel: string
  save?: string
  preventCloseBeforeComplete?: boolean
  useInnerHtml?: boolean
  saveButtonColor?: 'warn' | 'secondary'
  showTextSpinner?: boolean
}
// example of preventCloseBeforeComplete==true is in UserImgDetailsPopupComponent

@Component({
  selector: 'customer-portal-app-confirmation-question-popup',
  templateUrl: './confirmation-question-popup.component.html'
})
export class ConfirmationQuestionPopupComponent implements OnInit, OnDestroy {
  translatedTitle = ''
  translatedText = ''
  translatedCancel = ''
  translatedSave = ''

  saveButtonColor = 'secondary'

  @Output() confirm = new EventEmitter()

  @Output() reject = new EventEmitter()
  saving = false
  showTextSpinner = false

  sub = {
    onTransifexLanguageChange: null
  }

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ConfirmationQuestionPopupComponent>,
    private readonly transifexService: TransifexService,
    public sharedService: SharedService
  ) {}

  onButtonClicked (success: boolean): void {
    if (this.data.preventCloseBeforeComplete === true && success) {
      this.saving = true
    } else {
      this.dialogRef.close(success)
    }
    if (success) {
      this.confirm.emit()
    } else {
      this.reject.emit()
    }
  }

  ngOnInit (): void {
    this.sub.onTransifexLanguageChange =
      this.transifexService.onTransifexLanguageChange.subscribe(() => {
        this.refreshLanguage()
      })

    this.saveButtonColor = this.data.saveButtonColor ?? 'secondary'
    this.showTextSpinner = this.data.showTextSpinner
  }

  refreshLanguage (): void {
    this.translatedTitle = this.transifexService.getTranslation({
      key: this.data.title
    })

    if (this.data.translatedText != null) {
      this.translatedText = this.data.translatedText
    } else {
      this.translatedText = this.transifexService.getTranslation({
        key: this.data.text
      })
    }

    this.translatedCancel = this.transifexService.getTranslation({
      key: this.data.cancel
    })

    this.translatedSave = this.transifexService.getTranslation({
      key: this.data.save
    })
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }
}
