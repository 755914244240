<div id="RevolutionUserPersonalisationComponent" class="min-h-[600px]">
  <div class="mx-auto py-0 md:px-6" id="general">
    <div
      class="mt-5 font-efSans text-sm text-mat-ef-red-secondary-500"
      *ngIf="error && error.status !== 404"
    >
      {{ 'general-error-message' | sharedTranslate }}
    </div>
    <div
      class="mt-5 font-efSans text-sm text-mat-ef-red-secondary-500"
      *ngIf="getError && getError.status !== 404"
    >
      {{ 'general-error-message' | sharedTranslate }}
    </div>
    <div *ngIf="!getError">
      <h6 class="font-efSans text-base text-mat-ef-revolution-primary-500">
        <T
          str="If you have any preference or specific search criteria for vehicle you can specify them here:"
          key="customerPortal.customer-portal.personalization.brands.notice"
          tags="customer-portal, 3.1"
        ></T>
      </h6>

      <div
        class="uppercase text-mat-ef-revolution-primary-500 mt-5 text-base font-bold"
      >
        <T
          str="Brands"
          key="customerPortal.customer-portal.personalization.brands"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <mat-divider class="relative w-full mb-5"></mat-divider>

      <div class="" *ngIf="error">
        {{ 'general-error-message' | sharedTranslate }}
      </div>
      <div class="flexboxContainer flex flex-wrap gap-3.5">
        <button
          data-cy="personalization-brand"
          mat-button
          mat-raised-button
          *ngFor="let brand of brands"
          (click)="selectBrand(brand)"
          class="revolution shadow-none flex-grow h-9"
          [color]="checkBrand(brand) ? 'secondary' : 'basic'"
        >
          {{ brand.name }}
        </button>
      </div>
      <div
        class="uppercase text-mat-ef-revolution-primary-500 mt-5 text-base font-bold"
      >
        <T
          str="Body Type"
          key="customerPortal.customer-portal.personalization.bodyType"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <mat-divider class="relative w-full mb-5"></mat-divider>

      <div
        class="grid grid-cols-2 pb-6"
        [ngClass]="{
          'md:grid-cols-5': country === 'de',
          'md:grid-cols-4': country === 'ch'
        }"
      >
        <span
          class="py-5 px-1 m-auto"
          *ngFor="let vehicleType of vehicleTypes | vehicleType: country"
        >
          <button
            data-cy="personalization-body-type"
            mat-button
            mat-raised-button
            class="revolution vehicle-btn"
            (click)="selectBodyType(vehicleType?.type)"
            [color]="checkBodyType(vehicleType?.type) ? 'secondary' : 'basic'"
          >
            <div class="flex flex-col items-center">
              <mat-icon [svgIcon]="vehicleType?.icon"></mat-icon>
              <span>
                <ng-container
                  *ngTemplateOutlet="
                    sharedService.template('transifexTranslation');
                    context: {
                      str: vehicleType?.type,
                      prefix:
                        'customerPortal.customer-portal.personalization.bodyType.'
                    }
                  "
                ></ng-container>
              </span>
            </div>
          </button>
        </span>
      </div>
      <div
        class="uppercase text-mat-ef-revolution-primary-500 mt-5 text-base font-bold"
      >
        <T
          str="Transmission"
          key="customerPortal.customer-portal.personalization.transmission"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <mat-divider class="relative w-full mb-5"></mat-divider>

      <div class="grid">
        <span class="py-5 px-1">
          <mat-checkbox
            data-cy="personalization-transmition"
            class="py-2 px-1 mr-4"
            *ngFor="let transmission of transmissions"
            (change)="selectTransmission(transmission)"
            color="primary"
            [checked]="checkTransmission(transmission)"
          >
            <ng-container
              *ngTemplateOutlet="
                sharedService.template('transifexTranslation');
                context: {
                  str: transmission,
                  prefix: 'customerPortal.customer-portal.vehicle.equipment.',
                  noLowerCase: true
                }
              "
            ></ng-container>
          </mat-checkbox>
        </span>
      </div>
      <div
        class="uppercase text-mat-ef-revolution-primary-500 mt-5 text-base font-bold"
      >
        <T
          str="Vehicle use"
          key="customerPortal.customer-portal.personalization.vehicle-use"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <mat-divider class="relative w-full mb-5"></mat-divider>

      <div class="grid">
        <span class="py-5 px-1">
          <mat-checkbox
            data-cy="personalization-usage"
            class="py-2 px-1 mr-4"
            *ngFor="let vehicleUsage of vehicleUsages"
            (change)="selectVehicleUsage(vehicleUsage)"
            color="primary"
            [checked]="checkVehicleUsage(vehicleUsage)"
          >
            <ng-container
              *ngTemplateOutlet="
                sharedService.template('transifexTranslation');
                context: {
                  str: vehicleUsage,
                  prefix: 'customerPortal.customer-portal.vehicle.equipment.',
                  noLowerCase: true
                }
              "
            ></ng-container>
          </mat-checkbox>
        </span>
      </div>
      <div
        class="uppercase text-mat-ef-revolution-primary-500 mt-5 text-base font-bold"
      >
        <T
          str="Fuel type"
          key="customerPortal.customer-portal.personalization.fuel-type"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <mat-divider class="relative w-full mb-5"></mat-divider>

      <div class="grid">
        <span class="py-5 px-1">
          <mat-checkbox
            data-cy="personalization-fuel-type"
            class="py-2 px-1 mr-4"
            *ngFor="let fuelType of fuelTypes"
            (change)="selectFuelType(fuelType)"
            color="primary"
            [checked]="checkFuelType(fuelType)"
          >
            <ng-container
              *ngTemplateOutlet="
                sharedService.template('transifexTranslation');
                context: {
                  str: fuelType,
                  prefix: 'customerPortal.customer-portal.vehicle.equipment.',
                  noLowerCase: true
                }
              "
            ></ng-container>
          </mat-checkbox>
        </span>
      </div>
      <div
        class="uppercase text-mat-ef-revolution-primary-500 mt-5 text-base font-bold"
      >
        <T
          str="Powertrain"
          key="customerPortal.customer-portal.personalization.powertrain"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <mat-divider class="relative w-full mb-5"></mat-divider>
      <div class="grid gap-14">
        <div class="col-span-1">
          <div class="grid">
            <mat-checkbox
              data-cy="personalization-powertrain"
              class="py-2 px-1 mr-4"
              *ngFor="let powertrain of powertrains"
              (change)="selectPowertrains(powertrain)"
              color="primary"
              [checked]="checkPowertrains(powertrain)"
            >
              <ng-container
                *ngTemplateOutlet="
                  sharedService.template('transifexTranslation');
                  context: {
                    str: powertrain,
                    prefix: 'customerPortal.customer-portal.vehicle.equipment.',
                    noLowerCase: true
                  }
                "
              ></ng-container>
            </mat-checkbox>
          </div>
          <div
            class="uppercase text-mat-ef-revolution-primary-500 mt-5 text-base font-bold"
          >
            <T
              str="Equipment level"
              key="customerPortal.customer-portal.personalization.equipment-level"
              tags="customer-portal, 3.1"
            ></T>
          </div>
          <mat-divider class="relative w-full mb-5"></mat-divider>

          <mat-radio-group class="grid">
            <mat-radio-button
              data-cy="personalization-equipment-level"
              class="py-2 px-1"
              [checked]="checkEquipmentLevels(equipmentLevel)"
              (change)="selectEquipmentLevels(equipmentLevel)"
              color="primary"
              value="{{ equipmentLevel }}"
              *ngFor="let equipmentLevel of equipmentLevels"
            >
              <ng-container
                *ngTemplateOutlet="
                  sharedService.template('transifexTranslation');
                  context: {
                    str: equipmentLevel,
                    prefix: 'customerPortal.customer-portal.vehicle.equipment.',
                    noLowerCase: true
                  }
                "
              ></ng-container>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div
        class="uppercase text-mat-ef-revolution-primary-500 mt-5 text-base font-bold"
      >
        <T
          str="Energy efficiency"
          key="customerPortal.customer-portal.personalization.energy-efficiency"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <mat-divider class="relative w-full mb-5"></mat-divider>

      <div class="grid">
        <mat-checkbox
          data-cy="personalization-energy-efficiency"
          class="py-2 px-1"
          *ngFor="let energyEfficiency of energyEfficiencies"
          (change)="selectEnergyEfficiency(energyEfficiency)"
          color="primary"
          [checked]="checkEnergyEfficiency(energyEfficiency)"
        >
          <div
            class="energy-efficiency-class h-6 leading-6 pl-2.5 uppercase text-xl text-white font-bold"
            [ngClass]="efficiencyClassArrowColor(energyEfficiency)"
          >
            {{ energyEfficiency }}
          </div>
        </mat-checkbox>
      </div>
      <div class="flex flex-row-reverse max-w-[1000px]">
        <button
          data-cy="personalization-submit"
          mat-button
          mat-flat-button
          color="secondary"
          id="saveButton"
          class="revolution my-5 mx-0 px-7"
          (click)="savePersonalization()"
          [disabled]="loading || saveIsDisabled"
        >
          <mat-spinner [diameter]="16" *ngIf="loading" class="m-2"></mat-spinner
          ><span>{{ 'save' | sharedTranslate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
