import { Component, Input, OnDestroy } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  CptGoogleTagmanagerService,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { SharedService } from '../../../../services/shared.service'
import {
  FuelPriceDTO,
  VehicleDTOExtended
} from '../../../../services/vehicles.service'
import { DataService } from '../../../../services/data.service'
import { User } from '@inside-hub-app/customer-portal-b2c-client'

import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
@Component({
  selector: 'customer-portal-app-fuel-price-comparison',
  templateUrl: './fuel-price-comparison.component.html'
})
export class FuelPriceComparisonComponent implements OnDestroy {
  @Input()
  vehicle: VehicleDTOExtended

  fuelPrices: FuelPriceDTO[]
  user: User

  public currency: string
  public currencyLocale: string

  sub = {
    fuelPrices: null,
    onUserLoaded: null
  }

  constructor(
    public dialog: MatDialog,
    public sharedService: SharedService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly dataService: DataService,
    private readonly transifexService: TransifexService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.currency = this.remoteConfigService.get('currency')
    this.currencyLocale = this.remoteConfigService.get('currencyLocale')

    this.sub.fuelPrices = this.dataService.onVehicleFuelPricesLoaded.subscribe(
      fuelPrices => {
        this.fuelPrices = fuelPrices
      }
    )
    this.sub.onUserLoaded = this.dataService.onUserLoaded.subscribe(user => {
      this.user = user != null ? user : null
    })
  }

  ngOnDestroy(): void {
    this.sharedService.unsubscribe(this.sub)
  }

  openMap(fuelStation: FuelPriceDTO): void {
    const latLonO =
      (this.user?.address?.[0]?.street != null
        ? this.user.address[0].street
        : '') +
      (this.user?.address?.[0]?.zip != null
        ? ',' + this.user.address[0].zip
        : '') +
      (this.user?.address?.[0]?.city != null
        ? ',' + this.user.address[0].city
        : '')
    const latLonD = String(fuelStation.lat) + ',' + String(fuelStation.lon)
    window.open(
      this.remoteConfigService.get('googleMaps.googleMapsUrl') +
        latLonO +
        '&destination=' +
        latLonD +
        '&travelmode=driving',
      '_blank'
    )
  }

  styleBlock(fuelStation: FuelPriceDTO): string {
    if (fuelStation.openNow === true) {
      return 'w-[14px] h-[14px] min-w-[14px] min-h-[14px] bg-mat-ef-green-secondary-500'
    } else {
      return 'w-[14px] h-[14px] min-w-[14px] min-h-[14px] bg-mat-ef-red-secondary-500'
    }
  }

  getTranslation(fuelStation: FuelPriceDTO): string {
    if (fuelStation.openNow === true) {
      return this.transifexService.getTransifexTranslation(
        'Now open',
        'customerPortal.customer-portal.vehicle.nowOpen'
      )
    } else {
      return this.transifexService.getTransifexTranslation(
        'Now closed',
        'customerPortal.customer-portal.vehicle.nowClosed'
      )
    }
  }
}
