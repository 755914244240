<div class="container py-14 px-4">
  <h1 class="head-title">
    <T
      str="frequently asked questions"
      key="customerPortal.customer-portal.faq"
      tags="customer-portal, 3.1"
    ></T>
  </h1>

  <div class="w-full mt-14" *ngIf="loadingFAQ">
    <ngx-skeleton-loader
      appearance="line"
      animation="progress"
      [count]="12"
    ></ngx-skeleton-loader>
  </div>

  <div class="flex flex-col gap-6 mt-14" *ngIf="!loadingFAQ">
    <div class="w-full md:max-w-[350px] rounded-md">
      <form [formGroup]="filterFormGroup">
        <!-- white border border-mat-ef-revolution-supergrey-500 rounded-md -->
        <mat-form-field appearance="legacy" class="w-full no-padding">
          <mat-label>
            <T
              str="search"
              key="customerPortal.customer-portal.faq.filter"
              tags="customer-portal, 3.1"
            ></T>
          </mat-label>
          <input data-cy="faq-filter" matInput formControlName="filter" />
          <mat-icon matSuffix svgIcon="search"></mat-icon>
        </mat-form-field>
      </form>
    </div>

    <div class="w-full">
      <ng-container *ngIf="!faqExists()">
        <div
          class="p-5 w-full h-36 bg-mat-ef-revolution-grey-200 rounded-xl flex flex-col justify-center items-center"
        >
          <div class="text-mat-ef-revolution-supergrey-900">
            <mat-icon svgIcon="no_sim"></mat-icon>
          </div>
          <div class="text-base font-efSans-primary">
            <ng-container *ngIf="filterExists()">
              <T
                str="No search results found"
                key="customerPortal.customer-portal.faq.no-search-results-found"
                tags="customer-portal, 3.1"
              ></T>
            </ng-container>
            <ng-container *ngIf="!filterExists()">
              <T
                str="FAQ not found"
                key="customerPortal.customer-portal.faq.no-faq"
                tags="customer-portal, 3.1"
              ></T>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <div id="FAQList" *ngIf="faqExists()">
        <mat-divider></mat-divider>
        <div *ngFor="let element of faq; let i = index">
          <mat-expansion-panel
            data-cy="faq-element"
            #panel
            [hideToggle]="false"
            class="p-0 noPaddingPanelBody"
            [expanded]="element.isExpanded"
          >
            <mat-expansion-panel-header
              class="mat-expansion-indicator-less-left-padding px-2"
              [collapsedHeight]="'auto'"
              [expandedHeight]="'auto'"
            >
              <mat-panel-title class="m-0 text-mat-ef-revolution-primary-500">
                <div class="flex-1 flex gap-1 md:gap-3">
                  <div
                    class="w-[30px] hidden md:flex items-center justify-center md:justify-start"
                  >
                    <mat-icon
                      class="text-mat-ef-revolution-primary-500"
                      [svgIcon]="'bulletpoint'"
                    ></mat-icon>
                  </div>

                  <!-- title  -->
                  <div class="flex-1 flex items-center pl-1 md:pl-0">
                    <div
                      class="TextToHighlight font-efSans text-sm md:text-base font-bold text-mat-ef-revolution-primary-500"
                      [innerHTML]="element?.title | safeHtml"
                    ></div>
                  </div>

                  <!-- like unlike  -->
                  <div class="w-[80px] flex items-center justify-center gap-3">
                    <!-- like  -->
                    <div
                      class="flex-1 flex flex-col justify-end items-end gap-1"
                    >
                      <button
                        data-cy="faq-like-button"
                        class="w-fit leading-[0px]"
                        [disabled]="updatingLikeUnlike"
                        (click)="likeClicked($event, element)"
                      >
                        <mat-icon
                          [svgIcon]="
                            element.userLike ? 'thumbs_up' : 'thumbs_up_line'
                          "
                          [ngClass]="{
                            'text-green-500': element.userLike,
                            'text-mat-ef-revolution-primary-500':
                              !element.userLike
                          }"
                        ></mat-icon>
                      </button>
                      <div
                        class="text-mat-ef-revolution-primary-500 font-bold text-xs text-right leading-none"
                      >
                        {{ element.totalLikes }}
                      </div>
                    </div>

                    <!-- unlike  -->
                    <div
                      class="flex-1 flex flex-col justify-start items-start gap-1"
                    >
                      <button
                        data-cy="faq-unlike-button"
                        class="w-fit leading-[0px]"
                        [disabled]="updatingLikeUnlike"
                        (click)="unlikeClicked($event, element)"
                      >
                        <mat-icon
                          [svgIcon]="
                            element.userUnlike
                              ? 'thumbs_down'
                              : 'thumbs_down_line'
                          "
                          class="-scale-x-100"
                          [ngClass]="{
                            'text-mat-ef-revolution-red-500':
                              element.userUnlike,
                            'text-mat-ef-revolution-primary-500':
                              !element.userUnlike
                          }"
                        ></mat-icon>
                      </button>
                      <div
                        class="text-mat-ef-revolution-primary-500 font-bold text-xs text-left leading-none"
                      >
                        {{ element.totalUnlikes }}
                      </div>
                    </div>
                  </div>
                </div>
              </mat-panel-title>

              <ng-template matExpansionPanelContent>
                <div class="px-3 pt-0 pb-4 md:px-6">
                  <div
                    class="TextToHighlight md:ml-[28px] md:mr-[105px] text-mat-ef-revolution-primary-500 font-normal text-justify"
                    [innerHTML]="element.text | safeHtml"
                  ></div>
                </div>
              </ng-template>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>
  </div>
</div>
