import {
  ChangeDetectorRef,
  Component,
  type OnDestroy,
  type OnInit,
  ViewChild
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, type Params, Router } from '@angular/router'
import {
  type User,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { type ParsedToken } from '../../../../models/keycloak'
import { DataService } from '../../../services/data.service'
import { LocalStorageService } from '../../../services/local-storage.service'
import {
  ResizeService
} from '@inside-hub-app/customer-portal-shared'
import { SharedService, type Tab } from '../../../services/shared.service'
import { type VehicleDTOExtended } from '../../../services/vehicles.service'
import { RevolutionVehicleDealersComponent } from '../revolution-vehicle-dealers/revolution-vehicle-dealers.component'
import { VehicleBasicDetailsComponent } from '../vehicle-details/vehicle-basic-details/vehicle-basic-details.component'
import { RevolutionVehicleDocumentsComponent } from '../vehicle-documents/vehicle-documents.component'
import { VehicleImagesComponent } from '../vehicle-images/vehicle-images.component'
import {
  type CustomerPortalConfig,
  type Navigation
} from '@inside-hub-app/customer-portal-config'

@Component({
  selector: 'customer-portal-app-revolution-vehicle-details-view',
  templateUrl: './vehicle-details-view.component.html'
})
export class VehicleDetailsViewComponent implements OnInit, OnDestroy {
  public hasWarranty
  public enableEfitData
  public hasVehicleOnlineShop

  locationsLink: string
  loadingDealers: boolean
  dealerLoading = true
  error = false
  errorMessage: string
  token: ParsedToken
  user: User
  vehicles: VehicleDTOExtended[] = []
  public vehicle: VehicleDTOExtended = null
  public loading = true
  public vin: string
  i = 0
  public generalShow = true
  public dealerShow: boolean
  public detailsShow: boolean
  public serviceShow: boolean
  public documentsShow: boolean
  public country: string
  public hasNewFooter
  public hasTracking
  public hasNearestFuelStation

  hasFAQ
  currentTab: Tab
  urlParams: Params
  groupIdSales: number
  homepage
  notifications
  vehicleNotifications

  navigationItems: Navigation[]

  sub = {
    onLocalStorageDataChanged: null,
    onVehicleNotificationsLoadedSub: null,
    onUserVehiclesLoaded: null,
    onVehicleDealersLoadedSub: null,
    activatedRoute: null,
    tires: null,
    recalls: null,
    tireAppointments: null,
    warranty: null,
    servicePackage: null,
    onUserLoaded: null,
    onResizePx: null,
    geolocation: null,
    fuelPrices: null
  }

  fuelPrices
  leasingWarrantySPLoaded = false
  maintenanceLoaded = false
  loadingTires = true
  loadingRecalls = true
  loadingTireAppointments = true
  loadingWarranty = true
  loadingServicePackage = true
  @ViewChild(VehicleBasicDetailsComponent)
  public VehicleDetails: VehicleBasicDetailsComponent

  @ViewChild(RevolutionVehicleDocumentsComponent)
  public VehicleDocuments: RevolutionVehicleDocumentsComponent

  @ViewChild(VehicleImagesComponent)
  public VehicleImages: VehicleImagesComponent

  @ViewChild(RevolutionVehicleDealersComponent)
  public VehicleDealers: RevolutionVehicleDealersComponent

  nearestFuelStationTypes = ['gas', 'electro', 'hybrid']

  swipeData = {
    currentTab: null,
    swipeCoord: null,
    swipeTime: null,
    tabsCount: null
  }

  tabs = {
    0: 'general',
    1: 'details',
    2: 'dealer',
    3: 'vehicle-history',
    4: 'gallery',
    5: 'tracking'
  }

  showMobileView: boolean
  public screenSizePx: number
  location
  constructor (
    private readonly keycloak: KeycloakService,
    private readonly userService: UserService,
    private readonly route: ActivatedRoute,
    private readonly dataService: DataService,
    private readonly logger: NGXLogger,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    public readonly sharedService: SharedService,
    private readonly localStorageService: LocalStorageService,
    public resizeService: ResizeService,
    private readonly cdf: ChangeDetectorRef,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasWarranty = this.remoteConfigService.get('warranty.available')
    this.enableEfitData = this.remoteConfigService.get('enableEfitData')
    this.hasVehicleOnlineShop = this.remoteConfigService.get(
      'hasVehicleOnlineShop'
    )
    this.hasNewFooter = this.remoteConfigService.get('hasNewFooter')
    this.hasTracking = this.remoteConfigService.get('hasTracking')
    this.hasNearestFuelStation = this.remoteConfigService.get(
      'hasNearestFuelStation'
    )
    this.hasFAQ = this.remoteConfigService.get('hasFAQ')
    this.homepage = this.remoteConfigService.get('homepage')
    this.navigationItems =
    this.remoteConfigService.getArrayValues('navigationVehicle')

    route.params.subscribe(params => {
      this.urlParams = params
      this.switchRoute(this.urlParams)
    })

    this.sub.onUserLoaded = this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
    })
  }

  ngOnInit (): void {
    this.token = this.keycloak.getKeycloakInstance().tokenParsed as ParsedToken
    this.country = this.remoteConfigService.get('country.code')

    this.sub.activatedRoute = this.route.params.subscribe(params => {
      if (params.vin != null) {
        if (!this.sharedService.isComissionNumber(params.vin)) {
          this.vin = params.vin
          this.setVehicle()
        }
      }
    })

    this.sub.onUserVehiclesLoaded =
      this.dataService.onUserVehiclesLoaded.subscribe(
        (allVehicles: VehicleDTOExtended[]) => {
          this.loading = false
          if (allVehicles.length > 0) {
            this.vehicles = allVehicles
            this.setVehicle()
          }
        },
        error => {
          this.loading = false
          this.error = true
          this.errorMessage = error.statusText
          this.logger.debug(error)
        }
      )

    this.sub.onVehicleDealersLoadedSub =
      this.dataService.onVehicleDealersLoaded.subscribe(value => {
        if (
          this.vehicle != null &&
          value != null &&
          this.vehicle.vin === value.vin
        ) {
          this.vehicle.dealers = value
          this.dealerLoading = false
        }
      })

    this.sub.onLocalStorageDataChanged =
      this.localStorageService.onLocalStorageDataChanged.subscribe(data => {
        this.showDataIsChangedToast(data)
      })

    this.sub.recalls = this.dataService.loading.recalls.subscribe(loading => {
      this.loadingRecalls = loading
      this.checkAllLoaded()
    })
    this.sub.tires = this.dataService.loading.tires.subscribe(loading => {
      this.loadingTires = loading
      this.checkAllLoaded()
    })
    this.sub.tireAppointments =
      this.dataService.loading.tireAppointments.subscribe(loading => {
        this.loadingTireAppointments = loading
        this.checkAllLoaded()
      })
    this.sub.warranty = this.dataService.loading.warranty.subscribe(loading => {
      this.loadingWarranty = loading
      this.checkAllLoaded()
    })
    this.sub.servicePackage = this.dataService.loading.servicePackage.subscribe(
      loading => {
        this.loadingServicePackage = loading
        this.checkAllLoaded()
      }
    )

    this.sub.fuelPrices = this.dataService.onVehicleFuelPricesLoaded.subscribe(
      fuelPrices => {
        this.fuelPrices = fuelPrices
      }
    )

    this.sub.onResizePx = this.resizeService.onResizePx.subscribe(size => {
      this.screenSizePx = size
      this.isMobileView()
    })

    this.screenSizePx = this.resizeService.screenSizePx
    this.isMobileView()
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  setVehicle (): void {
    if (this.vehicles.length > 0) {
      if (
        this.vin != null &&
        this.vehicles.find(vehicle => vehicle.vin === this.vin) == null
      ) {
        void this.router.navigate([this.homepage])
      } else {
        this.loadVehicle(this.vin != null ? this.vin : this.vehicles[0].vin)
      }
    }
  }

  switchRoute (urlParams: Params): void {
    if (urlParams.vehicleAction != null) {
      const param = this.urlParams.vehicleAction
      switch (param) {
        case 'dealer':
          this.currentTab = 'vehicleDealer'
          this.swipeData.currentTab = 2
          break
        case 'details':
          this.currentTab = 'vehicleDetails'
          this.swipeData.currentTab = 1
          break
        case 'vehicle-history':
          this.currentTab = 'vehicleServiceHistory'
          this.swipeData.currentTab = 3
          break
        case 'tracking':
          this.currentTab = 'vehicleTrackingHistory'
          this.swipeData.currentTab = 5
          break
        case 'gallery':
          this.currentTab = 'vehicleGallery'
          this.swipeData.currentTab = 4
          break
        default:
          //   this.sharedService.scrollToTop()
          this.currentTab = 'vehicleGeneral'
          this.swipeData.currentTab = 0
      }
    } else {
      this.sharedService.scrollToTop()
      this.currentTab = 'vehicleGeneral'
      this.swipeData.currentTab = 0
    }
  }

  loadVehicle (vin): void {
    if (this.vehicle?.vin !== vin) {
      this.i = this.vehicles.findIndex(k => k.vin === vin)
      this.vehicle = this.vehicles.find(k => k.vin === vin)
      if (this.vehicle != null) {
        this.getDealerInfo()
        this.setMobileTabs()
      }
    }
  }

  getDealerInfo (): void {
    this.vehicle.dealers = {
      salesDealer: null,
      serviceDealer: null,
      purchasedDealer: null
    }
  }

  vehicleChanged (data: { vehicle: VehicleDTOExtended, i: number }): void {
    this.vehicle = data.vehicle
    this.vin = this.vehicle.vin
    this.i = data.i

    this.dealerLoading = true
    // this.VehicleDealers.dealerLoading = true
    this.setMobileTabs()
  }

  showDocuments (): void {
    this.generalShow = false
    this.dealerShow = false
    this.detailsShow = false
    this.serviceShow = false
    this.documentsShow = true
  }

  showContent (value: string): void {
    this.currentTab = value as Tab
  }

  leasingWarn (tillExpires: number): boolean {
    return (
      tillExpires <=
      Number(
        this.remoteConfigService.get('multilease.leasingExpirationDays.warn')
      )
    )
  }

  leasingCritical (tillExpires: number): boolean {
    return (
      tillExpires <=
      Number(
        this.remoteConfigService.get(
          'multilease.leasingExpirationDays.critical'
        )
      )
    )
  }

  servicePackageWarn (tillExpires: number): boolean {
    return (
      tillExpires <=
      Number(this.remoteConfigService.get('servicePackage.expirationDays.warn'))
    )
  }

  warrantyWarn (tillExpires: number): boolean {
    return (
      tillExpires <=
      Number(this.remoteConfigService.get('warranty.expirationDays.warn'))
    )
  }

  showDataIsChangedToast (changed: string): void {
    // dont show notice if dialog or snackbar is open
    if (!this.dialogIsOpen() && this.sharedService.getSnackbarRef() == null) {
      if (this.currentTab === 'vehicleDealer' && changed === 'dealers') {
        if (!this.VehicleDealers?.dealerLoading) {
          this.sharedService.showSnackbar(
            'customerPortal.customer-portal.data-changed',
            null
          )
        }
      }

      if (
        this.currentTab === 'vehicleServiceHistory' &&
        changed === 'documents'
      ) {
        if (!this.VehicleDocuments?.loadingDocuments) {
          this.sharedService.showSnackbar(
            'customerPortal.customer-portal.data-changed',
            null
          )
        }
      }

      if (this.currentTab === 'vehicleGallery' && changed === 'galleries') {
        if (!this.VehicleImages?.loadingGalleries) {
          this.sharedService.showSnackbar(
            'customerPortal.customer-portal.data-changed',
            null
          )
        }
      }

      if (this.currentTab === 'vehicleDetails') {
        switch (changed) {
          case 'vehicleDetails':
          case 'frequentDriver':
            if (!this.VehicleDetails?.loading) {
              this.sharedService.showSnackbar(
                'customerPortal.customer-portal.data-changed',
                null
              )
            }
            break
        }
      }

      if (this.currentTab === 'vehicleGeneral') {
        switch (changed) {
          case 'serviceHistory':
          case 'tires':
          case 'recalls':
          case 'tireAppointmentLink':
            if (this.maintenanceLoaded) {
              this.sharedService.showSnackbar(
                'customerPortal.customer-portal.data-changed',
                null
              )
            }
            break

          case 'leasing':
          case 'warranty':
          case 'servicePackage':
          case 'promotions':
            if (this.leasingWarrantySPLoaded) {
              this.sharedService.showSnackbar(
                'customerPortal.customer-portal.data-changed',
                null
              )
            }
            break
        }
      }
    }
  }

  checkAllLoaded (): void {
    this.maintenanceLoaded = !this.loadingTires
    if (this.remoteConfigService.get('features.recalls')) {
      this.maintenanceLoaded = this.maintenanceLoaded && !this.loadingRecalls
    }

    this.leasingWarrantySPLoaded = true
    if (
      this.hasWarranty &&
      this.remoteConfigService.get('features.warranty') &&
      this.enableEfitData
    ) {
      this.leasingWarrantySPLoaded =
        this.leasingWarrantySPLoaded && !this.loadingWarranty
    }
    if (
      this.sharedService.showServicePackageSection()
    ) {
      this.leasingWarrantySPLoaded =
        this.leasingWarrantySPLoaded && !this.loadingServicePackage
    }
  }

  dialogIsOpen (): boolean {
    return Boolean(this.dialog?.openDialogs?.length > 0)
  }

  isMobileView (): void {
    if (this.screenSizePx > 0) {
      if (this.screenSizePx < 1024) {
        this.showMobileView = true
        this.setMobileTabs()
      } else {
        this.showMobileView = false
      }
      this.cdf.detectChanges()
    }
  }

  setMobileTabs (): void {
    const tabsLength = this.navigationItems.length
    this.swipeData.tabsCount = tabsLength
    if (
      this.hasTracking &&
      this.vehicle?.createdByDataSource === 'customer_portal'
    ) {
      this.swipeData.tabsCount = tabsLength - 1
    }
  }

  swipe (e: TouchEvent, when: string): void {
    const currTab = this.swipeData.currentTab
    this.sharedService.tabSwipe(e, when, this.swipeData)
    if (currTab !== this.swipeData.currentTab) {
      // change route
      const tab = this.tabs[this.swipeData.currentTab]
      void this.router.navigate(['/vehicles', this.vin, tab])
    }
  }

  hasFuelPriceComparison (): boolean {
    return this.dataService.hasFuelPriceComparison(this.vehicle)
  }
}
