import { Component, Input } from '@angular/core'
import { BasicDocumentDTOExtended, VehicleDocumentsService } from '../../../services/vehicle-documents.service'
import { SharedService } from '../../../services/shared.service'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'

@Component({
  selector: 'customer-portal-app-document-header',
  templateUrl: './document-header.component.html'
})
export class DocumentHeaderComponent {
  dateFormat: string
  currencyFormat: string
  document: BasicDocumentDTOExtended
  @Input() set setDocument (document: BasicDocumentDTOExtended) {
    this.document = document
  }

  constructor (
    public readonly sharedService: SharedService,
    private readonly vehicleDocumentsService: VehicleDocumentsService,
    private readonly transifexTranslationsService: TranslationService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.dateFormat = this.remoteConfigService.get('dateFormat.long')
  }

  filterOnlyDocument (doc: BasicDocumentDTOExtended): boolean {
    return this.vehicleDocumentsService.filterOnlyDocument(doc)
  }

  filterOnlyDocumentSales (doc: BasicDocumentDTOExtended): boolean {
    return this.vehicleDocumentsService.filterOnlyDocumentSales(doc)
  }

  filterService (doc: BasicDocumentDTOExtended): boolean {
    return this.vehicleDocumentsService.filterService(doc)
  }

  getTranslation (element: BasicDocumentDTOExtended): string {
    if (this.sharedService.stringExists(element.code)) {
      return this.transifexTranslationsService.translate(element.code, {
        _key:
          'customerPortal.customer-portal.service-history.servicecode.' +
          element.code.toLowerCase(),
        _tags: 'customer-portal, notranslate'
      })
    }
    return element.description
  }


}
