<div
  data-cy="mileage-form"
  class="cpt-modal EditMileageComponent-revolution-dialog relative dialog-content-p-0 mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="Edit mileage"
      key="customerPortal.customer-portal.edit-mileage.title"
      tags="customer-portal, 3.1"
    ></T>
  </h2>

  <mat-dialog-content class="frequent-driver-dialog">
    <form [formGroup]="mileageForm" class="mt-2.5">
      <div class="my-2.5 flex justify-between w-full">
        <mat-form-field appearance="legacy" class="w-full">
          <mat-label>
            <T
              str="Manual mileage"
              key="customerPortal.customer-portal.edit-mileage.manualMileage.label"
              tags="customer-portal, 3.1"
            ></T
          ></mat-label>
          <input
            data-cy="mileage-form-input"
            matInput
            type="number"
            required
            formControlName="manualMileage"
            [errorStateMatcher]="matcher"
          />
          <mat-error *ngIf="mileageForm.get('manualMileage').errors">
            {{
              transifexService.getErrorTranslation(
                mileageForm.get('manualMileage'),
                'customerPortal.customer-portal.edit-mileage.error.'
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <mat-divider class="mt-0"></mat-divider>

  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>

    <button
      data-cy="mileage-form-submit"
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      (click)="saveChanges()"
      cdkFocusInitial
      [disabled]="saving || !mileageForm.valid"
    >
      <mat-spinner *ngIf="saving" diameter="16"></mat-spinner>
      <T
        str="Update"
        key="customerPortal.customer-portal.general.actions.update"
        tags="customer-portal, 3.1"
      ></T>
    </button>
  </mat-dialog-actions>
</div>
