import { Pipe, PipeTransform } from '@angular/core'
@Pipe({
  name: 'orderBy'
})
/**
 * | orderBy : ObjFieldName: OrderByType? - defaults to ascending
 * ObjFieldName: object field name you want to sort;
 * OrderByType: boolean; true: descending order; false: ascending;
 */
export class OrderByPipe implements PipeTransform {
  transform(array: any[], orderField: string, orderType?: boolean): string[] {
    if (orderType === undefined) {
      orderType = false
    }
    array.sort((a: any, b: any) => {
      const ae = a[orderField]
      const be = b[orderField]
      if (ae === undefined && be === undefined) return 0
      if (ae === undefined && be !== undefined)
        return orderType === true ? 1 : -1
      if (ae !== undefined && be === undefined)
        return orderType === true ? -1 : 1
      if (ae === be) return 0
      return orderType === true
        ? ae.toString().toLowerCase() > be.toString().toLowerCase()
          ? -1
          : 1
        : be.toString().toLowerCase() > ae.toString().toLowerCase()
        ? -1
        : 1
    })
    return array
  }
}
