import { Component } from '@angular/core'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
@Component({
  selector: 'customer-portal-app-vehicles-not-view',
  templateUrl: './vehicles-not-view.component.html'
})
export class VehiclesNotViewComponent {
  public hasNewFooter

  constructor(
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasNewFooter = this.remoteConfigService.get('hasNewFooter')
  }
}
