<div class="content-container">
  <div class="w-full flex flex-col gap-6">
    <div>
      <div
        class="uppercase text-mat-ef-revolution-primary-500 mt-5 text-base font-bold"
      >
        <T
          str="Notifications"
          key="customerPortal.customer-portal.userNotifications.title"
          tags="customer-portal, 3.1"
        ></T>
      </div>

      <div class="p-0">
        <mat-divider></mat-divider>

        <div *ngIf="hasMarketPlace && country === 'de'">
          <ng-container *ngTemplateOutlet="skeleton"> </ng-container>
          <div
            class="w-full flex flex-col md:flex-row justify-between p-5 text-mat-ef-revolution-primary-500 items-center gap-2 md:gap-7"
            *ngIf="!loading"
          >
            <div class="w-full flex flex-col">
              <div class="font-bold" *ngIf="!loading">
                <T
                  str="Price change"
                  key="customer-portal.userNotifications.priceChange_title"
                  tags="customer-portal, 3.3"
                ></T>
              </div>

              <div class="">
                <T
                  str="Receive notification when the price of the preferred car changes."
                  key="customer-portal.userNotifications.priceChange_text"
                  tags="customer-portal, 3.3"
                ></T>
              </div>
            </div>
            <div class="flex justify-end w-full md:w-[200px] md:min-w-[200px]">
              <div class="flex gap-4 md:gap-6 flex-row w-fit">
                <div class="flex justify-end w-32">
                  <mat-slide-toggle
                    class="ef-toggle top-label"
                    #priceChangeEmail
                    [checked]="userNotificationsEmail.priceChange"
                    (change)="
                      notificationsToggleClicked(
                        priceChangeEmail,
                        'priceChange',
                        'email'
                      )
                    "
                  >
                    {{ 'email' | sharedTranslate }}
                  </mat-slide-toggle>
                </div>
                <div class="flex justify-end w-32">
                  <mat-slide-toggle
                    class="ef-toggle top-label"
                    #priceChangePush
                    [checked]="userNotificationsPush.priceChange"
                    (change)="
                      notificationsToggleClicked(
                        priceChangePush,
                        'priceChange',
                        'push'
                      )
                    "
                    >{{ 'push' | sharedTranslate }}</mat-slide-toggle
                  >
                </div>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>

        <div *ngIf="hasMarketPlace && country === 'de'">
          <ng-container *ngTemplateOutlet="skeleton"> </ng-container>
          <div
            class="w-full flex flex-col md:flex-row justify-between p-5 text-mat-ef-revolution-primary-500 items-center gap-2 md:gap-7"
            *ngIf="!loading"
          >
            <div class="w-full flex flex-col">
              <div class="font-bold" *ngIf="!loading">
                <T
                  str="Search agent"
                  key="customer-portal.userNotifications.searchResultsChange_title"
                  tags="customer-portal, 3.3"
                ></T>
              </div>

              <div class="">
                <T
                  str="Receive an alert when new cars are released."
                  key="customer-portal.userNotifications.searchResultsChange_text"
                  tags="customer-portal, 3.3"
                ></T>
              </div>
            </div>

            <div class="flex justify-end w-full md:w-[200px] md:min-w-[200px]">
              <div class="flex gap-4 md:gap-6 flex-row w-fit">
                <div class="flex justify-end w-32">
                  <mat-slide-toggle
                    class="ef-toggle top-label"
                    #searchResultsChangeEmail
                    [checked]="userNotificationsEmail.searchResultsChange"
                    (change)="
                      notificationsToggleClicked(
                        searchResultsChangeEmail,
                        'searchResultsChange',
                        'email'
                      )
                    "
                  >
                    {{ 'email' | sharedTranslate }}
                  </mat-slide-toggle>
                </div>
                <div class="flex justify-end w-32">
                  <mat-slide-toggle
                    class="ef-toggle top-label"
                    #searchResultsChangePush
                    [checked]="userNotificationsPush.searchResultsChange"
                    (change)="
                      notificationsToggleClicked(
                        searchResultsChangePush,
                        'searchResultsChange',
                        'push'
                      )
                    "
                  >
                    {{ 'push' | sharedTranslate }}
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>

        <!-- Upcoming appointment -->

        <div>
          <ng-container *ngTemplateOutlet="skeleton"> </ng-container>
          <div
            class="w-full flex flex-col md:flex-row justify-between p-5 text-mat-ef-revolution-primary-500 items-center gap-2 md:gap-7"
            *ngIf="!loading"
          >
            <div class="w-full flex flex-col">
              <div class="font-bold" *ngIf="!loading">
                <T
                  str="Appointment"
                  key="customer-portal.userNotifications.appointment_title"
                  tags="customer-portal, 3.5"
                ></T>
              </div>

              <div class="">
                <T
                  str="Receive an alert when new appointment are released."
                  key="customer-portal.userNotifications.appointment_text"
                  tags="customer-portal, 3.5"
                ></T>
              </div>
            </div>

            <div class="flex justify-end w-full md:w-[200px] md:min-w-[200px]">
              <div class="flex gap-4 md:gap-6 flex-row w-fit">
                <div class="flex justify-end w-32"></div>
                <div class="flex justify-end w-32">
                  <mat-slide-toggle
                    class="ef-toggle top-label"
                    #appointmentPush
                    [checked]="userNotificationsPush.appointmentChange"
                    (change)="
                      notificationsToggleClicked(
                        appointmentPush,
                        'appointmentChange',
                        'push'
                      )
                    "
                  >
                    {{ 'push' | sharedTranslate }}
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>

        <!-- New document -->

        <div>
          <ng-container *ngTemplateOutlet="skeleton"> </ng-container>
          <div
            class="w-full flex flex-col md:flex-row justify-between p-5 text-mat-ef-revolution-primary-500 items-center gap-2 md:gap-7"
            *ngIf="!loading"
          >
            <div class="w-full flex flex-col">
              <div class="font-bold" *ngIf="!loading">
                <T
                  str="New document"
                  key="customer-portal.userNotifications.newDocument_title"
                  tags="customer-portal, 3.5"
                ></T>
              </div>

              <div class="">
                <T
                  str="Receive an alert when new new document are released."
                  key="customer-portal.userNotifications.newDocument_text"
                  tags="customer-portal, 3.5"
                ></T>
              </div>
            </div>

            <div class="flex justify-end w-full md:w-[200px] md:min-w-[200px]">
              <div class="flex gap-4 md:gap-6 flex-row w-fit">
                <div class="flex justify-end w-32"></div>
                <div class="flex justify-end w-32">
                  <mat-slide-toggle
                    class="ef-toggle top-label"
                    #newDocumentPush
                    [checked]="userNotificationsPush.newDocumentChange"
                    (change)="
                      notificationsToggleClicked(
                        newDocumentPush,
                        'newDocumentChange',
                        'push'
                      )
                    "
                  >
                    {{ 'push' | sharedTranslate }}
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>

        <!-- Leasing -->

        <div>
          <ng-container *ngTemplateOutlet="skeleton"> </ng-container>
          <div
            class="w-full flex flex-col md:flex-row justify-between p-5 text-mat-ef-revolution-primary-500 items-center gap-2 md:gap-7"
            *ngIf="!loading"
          >
            <div class="w-full flex flex-col">
              <div class="font-bold" *ngIf="!loading">
                <T
                  str="Leasing"
                  key="customer-portal.userNotifications.leasing_title"
                  tags="customer-portal, 3.5"
                ></T>
              </div>

              <div class="">
                <T
                  str="Receive an alert when new new leasing are released."
                  key="customer-portal.userNotifications.leasing_text"
                  tags="customer-portal, 3.5"
                ></T>
              </div>
            </div>

            <div class="flex justify-end w-full md:w-[200px] md:min-w-[200px]">
              <div class="flex gap-4 md:gap-6 flex-row w-fit">
                <div class="flex justify-end w-32"></div>
                <div class="flex justify-end w-32">
                  <mat-slide-toggle
                    class="ef-toggle top-label"
                    #leasingPush
                    [checked]="userNotificationsPush.leasingChange"
                    (change)="
                      notificationsToggleClicked(
                        leasingPush,
                        'leasingChange',
                        'push'
                      )
                    "
                  >
                    {{ 'push' | sharedTranslate }}
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>

        <!-- Warranty -->

        <div>
          <ng-container *ngTemplateOutlet="skeleton"> </ng-container>
          <div
            class="w-full flex flex-col md:flex-row justify-between p-5 text-mat-ef-revolution-primary-500 items-center gap-2 md:gap-7"
            *ngIf="!loading"
          >
            <div class="w-full flex flex-col">
              <div class="font-bold" *ngIf="!loading">
                <T
                  str="Warranty"
                  key="customer-portal.userNotifications.warranty_title"
                  tags="customer-portal, 3.5"
                ></T>
              </div>

              <div class="">
                <T
                  str="Receive an alert when new new warranty are released."
                  key="customer-portal.userNotifications.warranty_text"
                  tags="customer-portal, 3.5"
                ></T>
              </div>
            </div>

            <div class="flex justify-end w-full md:w-[200px] md:min-w-[200px]">
              <div class="flex gap-4 md:gap-6 flex-row w-fit">
                <div class="flex justify-end w-32"></div>
                <div class="flex justify-end w-32">
                  <mat-slide-toggle
                    class="ef-toggle top-label"
                    #warrantyPush
                    [checked]="userNotificationsPush.warrantyChange"
                    (change)="
                      notificationsToggleClicked(
                        warrantyPush,
                        'warrantyChange',
                        'push'
                      )
                    "
                  >
                    {{ 'push' | sharedTranslate }}
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>

        <!-- NEWSLETTER  -->
        <ng-container *ngIf="hasNewsletter">
          <div>
            <div
              class="uppercase text-mat-ef-revolution-primary-500 mt-5 text-base font-bold"
            >
              <T
                str="Newsletter"
                key="customerPortal.customer-portal.userNotifications.newsletter"
                tags="customer-portal, 3.1"
              ></T>
            </div>

            <div class="p-0">
              <mat-divider></mat-divider>

              <div>
                <ng-container *ngTemplateOutlet="skeleton"> </ng-container>
                <div
                  class="w-full flex flex-col md:flex-row justify-between p-5 text-mat-ef-revolution-primary-500 items-center gap-2 md:gap-7"
                  *ngIf="!loading"
                >
                  <div class="w-full flex flex-col">
                    <div class="font-bold" *ngIf="!loading">
                      <T
                        str="Register now for the Emil Frey Germany newsletter and don't miss any more news."
                        key="customerPortal.customer-portal.userNotifications.newsletter.title"
                        tags="customer-portal, 3.1"
                      ></T>
                    </div>

                    <div *ngIf="newsletterData?.date">
                      <ng-container *ngIf="newsletterData?.subscribed">
                        <T
                          str="You have subscribed to the Emil Frey newsletter with start date {date}."
                          key="customerPortal.customer-portal.userNotifications.newsletter.text-enabled"
                          tags="customer-portal, 3.1"
                          [vars]="{
                            date:
                              newsletterData?.date | date: dateFormat
                          }"
                        ></T>
                      </ng-container>
                      <ng-container *ngIf="!newsletterData?.subscribed">
                        <T
                          str="You unsubscribed on {date}"
                          key="customerPortal.customer-portal.userNotifications.newsletter.text-disabled"
                          tags="customer-portal, 3.1"
                          [vars]="{
                            date: newsletterData?.date | date: dateFormat
                          }"
                        ></T>
                      </ng-container>
                    </div>

                    <div *ngIf="!newsletterData?.date">-</div>
                  </div>

                  <div
                    class="flex justify-end w-full md:w-[200px] md:min-w-[200px]"
                  >
                    <div class="flex gap-0 md:gap-6 flex-row w-fit">
                      <div class="flex justify-end w-32">
                        <mat-slide-toggle
                          class="ef-toggle top-label"
                          #newsletterEmail
                          [checked]="newsletterData?.subscribed"
                          (change)="
                            notificationsToggleClicked(
                              newsletterEmail,
                              'newsletter'
                            )
                          "
                        >
                          {{ 'email' | sharedTranslate }}
                        </mat-slide-toggle>
                      </div>
                      <div class="hidden md:flex justify-end w-32"></div>
                    </div>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- MATOMO  -->
        <ng-container *ngIf="showMatomoSection()">
          <div>
            <div
              class="uppercase text-mat-ef-revolution-primary-500 mt-5 text-base font-bold"
            >
              <T
                str="Analytics"
                key="customerPortal.customer-portal.userNotifications.analytics"
                tags="customer-portal, 3.11"
              ></T>
            </div>

            <div class="p-0">
              <mat-divider></mat-divider>

              <div>
                <ng-container *ngTemplateOutlet="skeleton"> </ng-container>
                <div
                  class="w-full flex flex-col md:flex-row justify-between p-5 text-mat-ef-revolution-primary-500 items-center gap-2 md:gap-7"
                  *ngIf="!loading"
                >
                  <div class="w-full flex flex-col">
                    <div class="font-bold" *ngIf="!loading">
                      <T
                        str="Tracking consent"
                        key="customerPortal.customer-portal.matomo-consent.title"
                        tags="customer-portal, 3.11"
                      ></T>
                    </div>

                    <div class="">
                      <T
                        str="Help us improve your experience by allowing tracking for analytics"
                        key="customerPortal.customer-portal.matomo-consent.text"
                        tags="customer-portal, 3.11"
                      ></T>
                    </div>
                  </div>

                  <div
                    class="flex justify-end w-full md:w-[200px] md:min-w-[200px]"
                  >
                    <div class="flex gap-0 md:gap-6 flex-row w-fit">
                      <div class="flex justify-end w-32">
                        <mat-slide-toggle
                          class="ef-toggle top-label"
                          #matomo
                          [checked]="trackingConsent"
                          (change)="matomoConsentChanged(matomo)"
                        >
                          {{ 'accept' | sharedTranslate }}
                        </mat-slide-toggle>
                      </div>
                      <div class="hidden md:flex justify-end w-32"></div>
                    </div>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #skeleton>
  <div class="w-full p-5" *ngIf="loading">
    <ngx-skeleton-loader
      appearance="line"
      animation="progress"
      [count]="2"
    ></ngx-skeleton-loader>
  </div>
</ng-template>
