import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  UpdateUserConsentsRequest,
  User,
  UserBrandConsent,
  UserConsentsResponse,
  UserDealerConsent,
  UserGroupConsent,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import { MatomoIntegrationService, TransifexService } from '@inside-hub-app/customer-portal-shared'
import {
  CustomerPortalConfig,
  Link
} from '@inside-hub-app/customer-portal-config'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { LanguageService } from '../../../../services/language.service'
import { LocalStorageService } from '../../../../services/local-storage.service'
import { SharedService } from '../../../../services/shared.service'

@Component({
  selector: 'customer-portal-app-revolution-privacy',
  templateUrl: './privacy.component.html'
})
export class PrivacyComponent implements OnInit, OnDestroy {
  user: User
  lang
  public hasNewGDPR
  public country
  private href

  privacyLinkEnv: Link

  consentsResponse: UserConsentsResponse
  brands
  dealers
  group
  updatedBrands: UserBrandConsent[]
  updatedDealers: UserDealerConsent[]
  updatedGroup: UserGroupConsent
  disabled: boolean
  updates: UserBrandConsent[] | UserDealerConsent[] | UserGroupConsent[] = []
  update: boolean
  public email = ''
  public editDisabled = false
  headingChannels
  newGdprNotice = false
  primaryLang

  public privacyLink: {
    url: string
    translation: string
  }

  public link = ''

  sub = {
    onUserLoaded: null,
    onLanguageChange: null,
    onLanguageChangeSub: null,
    onUserConsentsLoaded: null,
    onTransifexLanguageChange: null
  }

  constructor (
    private readonly userService: UserService,
    public dialog: MatDialog,
    private readonly logger: NGXLogger,
    private readonly transifexTranslationsService: TranslationService,
    public transifexService: TransifexService,
    private readonly sharedService: SharedService,
    private readonly data: DataService,
    private readonly languageService: LanguageService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly localStorageService: LocalStorageService,
    private readonly matomoIntegrationService: MatomoIntegrationService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasNewGDPR = this.remoteConfigService.get('hasNewGDPR')
    this.country = this.remoteConfigService.get('country.code')

    this.privacyLink = {
      url: '',
      translation: ''
    }
    this.sub.onLanguageChangeSub = data.onLanguageChange.subscribe(language => {
      if (this.languageService.checkIfExists(language)) {
        this.primaryLang = language.toLowerCase()
        this.sub.onTransifexLanguageChange =
          this.transifexService.onTransifexLanguageChange.subscribe(() => {
            this.getPrivacyTranslation()
          })
      }
    })
  }

  public getStyles (): {
    'grid-template-columns': string
  } {
    const length: number =
      this.headingChannels?.length != null ? this.headingChannels.length : 0
    return {
      'grid-template-columns': `repeat(${length + 2}, 1fr)`
    }
  }

  getConsents (): void {
    this.data.getConsents()
  }

  getPrivacyTranslation (): void {
    const translation = this.transifexTranslationsService.translate(
      'Privacy notices.',
      {
        _key: 'customerPortal.customer-portal.communication.privacy.link',
        _tags: 'customer-portal, 3.1'
      }
    )
    this.setPrivacyLink(translation)
  }

  ngOnInit (): void {
    this.sub.onTransifexLanguageChange =
      this.transifexService.onTransifexLanguageChange.subscribe(() => {
        this.getPrivacyTranslation()
      })
    this.email =
      '<a href="mailto:' +
      this.remoteConfigService.get('links.privacyEmail') +
      '">' +
      this.remoteConfigService.get('links.privacyEmail') +
      '</a>'
    this.disabled = false
    this.update = false
    this.editDisabled = false
    this.newGdprNotice = this.country === 'de'
    this.editDisabled = false
    if (this.country === 'de' || this.hasNewGDPR === true) {
      this.getConsents()
    }

    this.sub.onUserLoaded = this.data.onUserLoaded.subscribe(user => {
      this.lang = undefined
      this.cdRef.detectChanges()
      this.user = user

      this.lang =
        this.user != null && user.language != null && user.language.length !== 0
          ? user.language.find(s => s.primary).language
          : ''
    })
    this.sub.onLanguageChange = this.data.onLanguageChange.subscribe(
      newLanguage => {
        this.lang = undefined
        this.cdRef.detectChanges()
        this.lang = newLanguage.toUpperCase()
      }
    )

    this.sub.onUserConsentsLoaded = this.data.onUserConsentsLoaded.subscribe(
      consents => {
        this.consentsResponse = consents
        this.setupConsents(consents)
        this.dealers = this.consentsResponse.dealers
        this.group = this.consentsResponse.group
        this.brands = this.consentsResponse.brands
        this.updatedDealers = this.dealers
        this.updatedGroup = this.group
        this.updatedBrands = this.brands
      }
    )
  }

  setupConsents (consents): void {
    let consentSize = 0
    if (consents.group != null) {
      consentSize = consents.group.consents.channels.length
      this.headingChannels = consents.group.consents.channels
    }
    if (consents.brands != null) {
      for (const b of consents.brands) {
        if (b.consents.channels.length > consentSize) {
          consentSize = b.consents.channels.length
          this.headingChannels = b.consents.channels
        }
      }
    }
    if (consents.dealers != null) {
      for (const b of consents.dealers) {
        if (b.consents.channels.length > consentSize) {
          consentSize = b.consents.channels.length
          this.headingChannels = b.consents.channels
        }
      }
    }
  }

  setPrivacyLink (privacy): void {
    this.privacyLink.translation = privacy
    this.setHrefLinks()
  }

  updateConsents (): void {
    this.matomoIntegrationService.trackEvent(
      'My account',
      'Data protection change'
    )

    this.disabled = true
    this.href = window.location.href
    const newConsents: UserConsentsResponse = {
      group: this.updatedGroup,
      dealers: this.updatedDealers,
      brands: this.updatedBrands
    }

    const updateConsents: UpdateUserConsentsRequest = {
      url: this.href,
      userConsents: newConsents
    }

    this.userService.putConsents(updateConsents).subscribe(
      text => {
        this.logger.debug(text)
        this.disabled = false
        this.sharedService.openConfirmationPopup(
          'shared.success',
          'customerPortal.customer-portal.details.change.consents-changed'
        )
        if (this.country === 'de' || this.hasNewGDPR === true) {
          this.getConsents()
        }
      },
      error => {
        this.sharedService.openConfirmationPopup(
          'shared.error',
          'shared.general-error-message'
        )
        this.logger.error(error)
      }
    )
  }

  getConsentGroup (name: string, type: string): boolean {
    const val = this.group.consents.channels.find(s => s.name === type)
    if (val != null) {
      return this.group.consents.channels.find(s => s.name === type).allow
    } else {
      return false
    }
  }

  getConsentBrand (id: number, type: string): boolean {
    const val = this.brands
      .find(s => s.id === id)
      .consents.channels.find(k => k.name === type)
    if (val != null) {
      return this.brands
        .find(s => s.id === id)
        .consents.channels.find(k => k.name === type).allow
    } else {
      return false
    }
  }

  getConsentDealer (id: number, type: string): boolean {
    const val = this.dealers
      .find(s => s.id === id)
      .consents.channels.find(k => k.name === type)
    if (val != null) {
      return this.dealers
        .find(s => s.id === id)
        .consents.channels.find(k => k.name === type).allow
    } else {
      return false
    }
  }

  findUserPhone (): boolean {
    return !(
      this.sharedService.findUserPhone(this.user)?.mobile != null ||
      this.sharedService.findUserPhone(this.user)?.mobileBusiness != null ||
      this.sharedService.findUserPhone(this.user)?.landlinePersonal != null ||
      this.sharedService.findUserPhone(this.user)?.landlineBusiness != null
    )
  }

  updateConsentValue (consent: string, id: number, type: string, event): void {
    if (consent === 'dealer') {
      this.isValidConsents(id, this.updatedDealers, event, type)
    } else if (consent === 'brand') {
      this.isValidConsents(id, this.updatedBrands, event, type)
    } else {
      this.isValidConsents(id, this.updatedGroup, event, type)
    }
  }

  errorMessage (type): void {
    if (type === 'telephone') {
      if (this.findUserPhone()) {
        this.sharedService.openConfirmationPopup(
          'shared.error',
          'customerPortal.customer-portal.no-phone'
        )
      }
    }

    if (type === 'post') {
      if (!this.addressExist()) {
        this.sharedService.openConfirmationPopup(
          'shared.error',
          'customerPortal.customer-portal.no-address'
        )
      }
    }
  }

  addressExist (): boolean {
    return this.user?.address != null
  }

  isValidConsents (id: number, element, event, type: string): void {
    const consentValue = Array.isArray(element)
      ? element
        ?.find(d => d.id === id)
        .consents.channels.find(cd => cd.name === type)
      : element?.consents.channels.find(cd => cd.name === type)
    consentValue.allow = event.checked
    consentValue.edit = this.sharedService.switchBoolean(consentValue.edit)

    if (!this.updates.includes(consentValue)) {
      this.updates.push(consentValue)
    } else {
      const index = this.updates.indexOf(consentValue)
      if (index > -1) {
        this.updates.splice(index, 1)
      }
    }
  }

  checkIfDisabled (dealer, channel: string): boolean {
    if (dealer.consents.channels.find(s => s?.name === channel) != null) {
      return false
    } else {
      return true
    }
  }

  checkIfExist (dealer, channel: string): boolean {
    if (
      this.editDisabled ||
      dealer.consents.channels.find(s => s?.name === channel) == null ||
      (this.findUserPhone() && channel === 'telephone') ||
      (!this.addressExist() && channel === 'post')
    ) {
      return false
    } else {
      return true
    }
  }

  setHrefLinks (): void {
    if (this.primaryLang == null) {
      this.primaryLang = this.getLang()
    }

    const privacyLink: Link = {
      link: this.remoteConfigService.get('links.privacyLink.link'),
      link_de: this.remoteConfigService.get('links.privacyLink.link_de'),
      link_it: this.remoteConfigService.get('links.privacyLink.link_it'),
      link_fr: this.remoteConfigService.get('links.privacyLink.link_fr'),
      eventLabel: this.remoteConfigService.get('links.privacyLink.eventLabel')
    }

    this.privacyLinkEnv = Object.assign({}, privacyLink)
    this.privacyLinkEnv.link = this.languageService.getLink(
      this.privacyLinkEnv,
      this.primaryLang
    )

    this.privacyLink.url = this.privacyLinkEnv.link
    this.link =
      '<a target="_blank" href="' +
      this.privacyLink.url +
      '">' +
      this.privacyLink.translation +
      '</a>'
  }

  getLang (): string {
    try {
      const lang = this.localStorageService.getLang()
      return this.sharedService.stringExists(lang) ? lang : 'de'
    } catch (error) {
      return 'de'
    }
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  getTranslation1 (): string {
    return this.transifexTranslationsService.translate('Privacy notice', {
      _key: 'customerPortal.customer-portal.privacy.privacynotice',
      _tags: 'customer-portal, 3.1'
    })
  }

  getTranslation2 (): string {
    return this.transifexTranslationsService.translate(
      'Gdpr personal data categories',
      {
        _key: 'customerPortal.customer-portal.communication.gdpr.personal-data-categories',
        _tags: 'customer-portal, 3.1'
      }
    )
  }

  getTranslation3 (): string {
    return this.transifexTranslationsService.translate('Gdpr purposes', {
      _key: 'customerPortal.customer-portal.communication.gdpr.purposes',
      _tags: 'customer-portal, 3.1'
    })
  }

  getTranslation4 (): string {
    return this.transifexTranslationsService.translate(
      'Further information on data protection and your rights',
      {
        _key: 'customerPortal.customer-portal.communication.gdpr.bottom-notice3',
        _tags: 'customer-portal, 3.1',
        link: this.link
      }
    )
  }

  getTranslation5 (): string {
    return this.transifexTranslationsService.translate(
      'The declaration of consent can be revoked or changed at any time for the future and without giving reasons.',
      {
        _key: 'customerPortal.customer-portal.communication.gdpr-notice-email',
        _tags: 'customer-portal, 3.1',
        email: this.email
      }
    )
  }
}
