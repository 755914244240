import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { SharedService } from '../../../services/shared.service'
import { DealerDTO } from '../../../services/dealers.service'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
@Component({
  selector: 'customer-portal-app-dealer-details-popup',
  templateUrl: './dealer-details-popup.component.html'
})
export class DealerDetailsPopupComponent {
  public country: string

  constructor(
    private readonly dialogRef: MatDialogRef<DealerDetailsPopupComponent>,
    public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public dealer: DealerDTO,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.country = this.remoteConfigService.get('country.code')
  }
}
