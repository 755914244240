<div
  *ngIf="
    vehicle?.vin &&
    picture &&
    picture.type !== 'VEHICLE_DAMAGE' &&
    (showAlways || picture.isProfilePicture === true)
  "
  class="w-fit h-fit p-0.5 cursor-pointer flex justify-center items-center bg-white/50 rounded-md relative"
  [ngClass]="{
    'text-mat-ef-revolution-secondary-500': picture.isProfilePicture === true,
    'text-mat-ef-revolution-primary-500': picture.isProfilePicture !== true
  }"
>
  <mat-icon
    class="w-fit h-fit"
    [svgIcon]="
      picture.isProfilePicture === true ? 'favorite' : 'favorite_border'
    "
  ></mat-icon>
  <!-- prettier-ignore -->
  <div
    class="absolute top-0 left-0 right-0 bottom-0 cursor-pointer rounded-md bg-transparent"
    matTooltip="{{ getTranslation() }}"
    (click)="profilePictureUpdate()"
  ></div>
</div>
