<div
  data-cy="frequent-driver-form"
  class="cpt-modal FrequentDriverPopupComponent-revolution-dialog relative dialog-content-p-0 mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="Most frequent driver"
      key="customerPortal.customer-portal.most-frequent-driver.title"
      tags="customer-portal, 3.1"
    ></T>
  </h2>

  <mat-dialog-content class="overflow-hidden">
    <form [formGroup]="frequentDriverForm">
      <div class="mb-2">
        <mat-form-field
          class="hidden md:inline-block name-salutation w-full"
          appearance="legacy"
        >
          <mat-label>{{ 'salutation' | sharedTranslate }}</mat-label>
          <mat-select
            data-cy="frequent-driver-salutation-select"
            formControlName="salutation"
            required
            [errorStateMatcher]="matcher"
          >
            <mat-option
              *ngFor="let salutation of salutations"
              [value]="salutation"
            >
              {{ 'salutation-' + salutation?.toLowerCase() | sharedTranslate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="inline-block md:hidden name-salutation w-full"
          appearance="legacy"
        >
          <mat-label>{{ 'salutation' | sharedTranslate }}</mat-label>
          <select
            matNativeControl
            formControlName="salutation"
            required
            [errorStateMatcher]="matcher"
          >
            <option *ngFor="let salutation of salutations" [value]="salutation">
              {{ 'salutation-' + salutation.toLowerCase() | sharedTranslate }}
            </option>
          </select>
        </mat-form-field>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-6">
        <mat-form-field appearance="legacy" class="col-span-1">
          <mat-label>{{ 'first-name' | sharedTranslate }}</mat-label>
          <input
            data-cy="frequent-driver-form-first-name-input"
            matInput
            required
            formControlName="firstName"
            [errorStateMatcher]="matcher"
          />
          <mat-error
            *ngIf="frequentDriverForm.get('firstName').errors"
            data-cy="frequent-driver-form-first-name-error"
          >
            {{
              transifexService.getErrorTranslation(
                frequentDriverForm.get('firstName'),
                'customerPortal.customer-portal.most-frequent-driver.firstName.error.',
                {
                  num: salutationFirstNameMaxLength,
                  errKey: {
                    maxlength: 'max-length-characters',
                    maxLength: 'max-length-characters',
                    pattern: 'only-numbers-letters-allowed'
                  }
                }
              )
            }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="legacy" class="col-span-1">
          <mat-label>{{ 'last-name' | sharedTranslate }}</mat-label>
          <input
            data-cy="frequent-driver-form-last-name-input"
            matInput
            required
            formControlName="lastName"
            [errorStateMatcher]="matcher"
          />
          <mat-error
            *ngIf="frequentDriverForm.get('lastName').errors"
            data-cy="frequent-driver-form-last-name-error"
          >
            {{
              transifexService.getErrorTranslation(
                frequentDriverForm.get('lastName'),
                'customerPortal.customer-portal.most-frequent-driver.lastName.error.',
                {
                  num: salutationLastNameMaxLength,
                  errKey: {
                    maxlength: 'max-length-characters',
                    maxLength: 'max-length-characters',
                    pattern: 'only-numbers-letters-allowed'
                  }
                }
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <mat-divider class="mt-0"></mat-divider>

  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>

    <button
      data-cy="frequent-driver-form-submit"
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      (click)="saveChanges()"
      cdkFocusInitial
      [disabled]="saving || !frequentDriverForm.valid"
    >
      <mat-spinner *ngIf="saving" diameter="20"></mat-spinner>
      {{ 'update' | sharedTranslate }}
    </button>
  </mat-dialog-actions>
</div>
