<div
  data-cy="nickname-form"
  class="cpt-modal EditNicknameComponent-revolution-dialog relative dialog-content-p-0 mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="Update Nickname"
      key="customerPortal.customer-portal.vehicle.edit-nickname.title"
      tags="customer-portal, 3.1"
    ></T>
  </h2>

  <mat-dialog-content class="mat-typography">
    <form class="form" [formGroup]="nicknameForm">
      <div class="my-2.5 flex justify-between w-full">
        <mat-form-field appearance="legacy" class="w-full">
          <mat-label
            ><T
              str="Nickname"
              key="customerPortal.customer-portal.vehicle.edit-nickname"
              tags="customer-portal, 3.1"
            ></T
          ></mat-label>
          <input
            data-cy="nickname-form-input"
            matInput
            formControlName="nickname"
            [errorStateMatcher]="matcher"
          />
          <mat-error *ngIf="nicknameForm.get('nickname').errors">
            {{
              transifexService.getErrorTranslation(
                nicknameForm.get('nickname'),
                'customerPortal.customer-portal.vehicle.edit-nickname.error.',
                {
                  errKey: {
                    maxlength: 'maximum-length-exceeded',
                    pattern: 'only-numbers-letters-allowed'
                  }
                }
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <mat-divider class="mt-0"></mat-divider>

  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <button
      data-cy="nickname-form-submit"
      [disabled]="saving || !nicknameForm.valid"
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      (click)="updateNickname()"
      cdkFocusInitial
    >
      <mat-spinner *ngIf="saving" diameter="20"></mat-spinner>
      {{ 'save' | sharedTranslate }}
    </button>
  </mat-dialog-actions>
</div>
