import { Component, Input, type OnDestroy, type OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  CptGoogleTagmanagerService,
  EcondaService,
  type GTMEventData,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { AppointmentsService } from '../../../../services/appointments.service'
import { DataService } from '../../../../services/data.service'
import { type RecallDTO } from '../../../../services/recalls.service'
import { SharedService } from '../../../../services/shared.service'
import { type TiresAppointmentDTO } from '../../../../services/tires.service'
import {
  type BasicDocumentDTOExtended,
  VehicleDocumentsService
} from '../../../../services/vehicle-documents.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'
import { AppointmentsPopupComponent } from '../../../revolution/appointments-popup/appointments-popup.component'
import { AppointmentsPopupChooseStepComponent } from '../../appointments-popup/appointments-popup-choose-step/appointments-popup-choose-step.component'
@Component({
  selector: 'customer-portal-app-revolution-vehicle-last-action-service',
  templateUrl: './vehicle-last-action-service.component.html'
})
export class VehiclLastActionServiceComponent implements OnInit, OnDestroy {
  featuresRecalls
  public hasWarranty
  public hasServicePackage
  public enableEfitData

  @Input() vehicle: VehicleDTOExtended

  link: TiresAppointmentDTO
  hasServiceManager = false

  public serviceHistories: BasicDocumentDTOExtended[] = []
  loadingServiceHistory: boolean
  months: number
  serviceKilometers
  currentDay: Date
  public currencyLocale: string

  recallResponse: RecallDTO
  hasRecalls = false
  loadingRecalls: boolean

  componentSub = {
    AppintmentLinkLoaded: null,
    RecallsLoaded: null,
    recalls: null,
    ServiceHistoryLoaded: null,
    serviceHistory: null
  }

  constructor (
    public dialog: MatDialog,
    private readonly logger: NGXLogger,
    private readonly dataService: DataService,
    private readonly vehicleDocumentsService: VehicleDocumentsService,
    private readonly appointmentsService: AppointmentsService,
    public sharedService: SharedService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly transifexTranslationsService: TranslationService,
    public transifexService: TransifexService,
    private readonly econdaService: EcondaService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.featuresRecalls = this.remoteConfigService.get('features.recalls')
    this.hasWarranty = this.remoteConfigService.get('warranty.available')
    this.hasServicePackage = this.remoteConfigService.get(
      'servicePackage.available'
    )
    this.enableEfitData = this.remoteConfigService.get('enableEfitData')
    this.hasServiceManager = this.remoteConfigService.get(
      'hasServiceManager'
    )
    this.currencyLocale = this.remoteConfigService.get('currencyLocale')
  }

  ngOnInit (): void {
    this.componentSub.AppintmentLinkLoaded =
      this.dataService.onVehicleTiresAppintmentLinkLoaded.subscribe(
        data => {
          this.link = data
        },
        err => {
          this.logger.debug(err)
        }
      )

    this.componentSub.RecallsLoaded =
      this.dataService.onVehicleRecallsLoaded.subscribe(
        recalls => {
          this.recallResponse = recalls
          this.hasRecalls = false
          if (recalls != null) {
            if (recalls.status !== 'NONE') {
              this.hasRecalls = true
            }
          }
        },
        err => {
          this.logger.debug(err)
        }
      )
    this.componentSub.recalls = this.dataService.loading.recalls.subscribe(
      loading => {
        this.loadingRecalls = loading
      }
    )

    this.componentSub.serviceHistory =
      this.dataService.loading.recalls.subscribe(loading => {
        this.loadingServiceHistory = loading
      })

    this.componentSub.ServiceHistoryLoaded =
      this.dataService.onVehicleDocumentsLoaded.subscribe(
        data => {
          this.serviceHistories = data?.filter(document =>
            this.filterService(document)
          )

          if (
            this.serviceHistories != null &&
            this.serviceHistories?.length > 0
          ) {
            this.months = this.calculateServiceHistoryMonths(
              this.serviceHistories[0]
            )
            this.serviceKilometers =
              this.serviceHistories?.[0]?.serviceKm != null
                ? this.serviceHistories[0].serviceKm
                : null
          } else {
            this.months = null
            this.serviceKilometers = null
          }
        },
        err => {
          this.logger.debug(err)
        }
      )
  }

  filterService (doc: BasicDocumentDTOExtended): boolean {
    return this.vehicleDocumentsService.filterService(doc)
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.componentSub)
  }

  sendGTMData (): void {
    const data = {
      delivery: 'Delivery',
      reifenManager: true
    }
    this.cptGtmService.sendServiceAppointmentCheckoutData(data)
  }

  decideNextStep (): void {
    const dialogRef = this.dialog.open(AppointmentsPopupChooseStepComponent, {
      panelClass: ['mat-dialog-cpt', 'choose-step'],
      data: {
        vehicle: this.vehicle
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'CPT') {
        // open appointment popup
        this.newAppointment()
      }
      if (result === 'serviceManager') {
        this.openServiceManager()
      }
    })
  }

  openServiceManager (): void {
    window.open(this.link != null ? this.link.url : '', '_blank')
    this.sendGTMData()
  }

  newAppointment (serviceAction?: boolean): void {
    const data = {
      vehicle: this.vehicle,
      step: 1,
      serviceAction
    }
    this.appointmentsService.newAppointment(AppointmentsPopupComponent, data)
    this.econdaService.send({
      content: 'CTA',
      Target: ['New appointment', JSON.stringify(data), 1, 'd']
    })
  }

  sendAppointmentGtmData (action: string): void {
    /** GTM 2.7.1 */
    const gtmData: GTMEventData = {
      event: 'gaEvent2',
      event_name: 'support',
      support: {
        button_action: action,
        event_category: 'Maintenance',
        event_action: 'Book an appointment',
        event_label: action
      }
    }
    this.cptGtmService.send(gtmData)
  }

  hasRecallDescription (): boolean {
    if (
      this.recallResponse?.descriptions != null &&
      this.recallResponse?.descriptions.length > 0
    ) {
      return true
    }
    return false
  }

  calculateServiceHistoryMonths (service: BasicDocumentDTOExtended): number {
    try {
      const first = new Date(service.date)
      this.currentDay = new Date()

      let months =
        first.getMonth() -
        this.currentDay.getMonth() +
        12 * (first.getFullYear() - this.currentDay.getFullYear())

      if (this.currentDay.getDate() < first.getDate()) {
        months--
      }

      if (months < 0) {
        months = months * -1
      }

      return months
    } catch (error) {}
  }

  sendContentModulesCTAData (contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }
}
