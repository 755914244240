<div
  id="footer"
  class="footer bg-mat-ef-revolution-primary-500 flex justify-center"
>
  <div class="container">
    <div
      class="md:pb-5 lg:py-10 flex flex-col lg:flex-row items-center justify-between"
    >
      <customer-portal-app-brand></customer-portal-app-brand>
      <customer-portal-app-social
        *ngIf="!isMobilePwa"
      ></customer-portal-app-social>
    </div>

    <customer-portal-app-divider
      class="footerDivider hidden lg:block"
    ></customer-portal-app-divider>

    <div *ngIf="!isMobilePwa" class="md:pb-5 lg:py-10 flex items-center">
      <customer-portal-app-links class="grow"></customer-portal-app-links>
    </div>

    <customer-portal-app-divider
      class="footerDivider"
    ></customer-portal-app-divider>

    <div class="md:py-5 lg:py-10 flex items-center">
      <customer-portal-app-impressum></customer-portal-app-impressum>
    </div>
  </div>
</div>
