import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { B2CModuleConfig } from '../b2c.module'
import { hr as cpt } from 'efd-b2c-backend-interfaces-ts'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'

export type RegisterVerifyResponse =
  cpt.emilfreydigital.b2c_auth_server.rest.common.dto.VerificationDTO
export interface RegisterRequest {
  isBusiness: boolean
  email: string
  firstName: string
  lastName: string
  password: string
  salute: 'MR' | 'MS' | 'MRS'
  webUrl: string
}

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  constructor(
    private readonly config: B2CModuleConfig,
    private readonly http: HttpClient,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('b2c.baseUrl')
    this.apiKey = this.config.apiKey
    this.fallbackApiKey = this.remoteConfigService.get('b2c.apiKey')
  }

  private readonly apiUrl
  private readonly apiKey
  private readonly fallbackApiKey

  public register(
    request: RegisterRequest,
    apiKey: string = null
  ): Observable<any> {
    return this.http.post(`${this.apiUrl}/register`, request, {
      headers: { 'api-key': apiKey || this.apiKey || this.fallbackApiKey }
    })
  }

  public resendEmailVerification(
    email: string,
    apiKey: string = null,
    lang: string = null
  ): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/register/resend/${email}?lang=${lang}`,
      {
        headers: { 'api-key': apiKey || this.apiKey || this.fallbackApiKey }
      }
    )
  }

  public verifyEmail(
    verificationId: string,
    lang: string = null
  ): Observable<RegisterVerifyResponse> {
    return this.http.get<RegisterVerifyResponse>(
      `${this.apiUrl}/register/verify/${verificationId}?lang=${lang}`
    )
  }

  public resendTelephoneVerification(
    telephoneNumber: string,
    apiKey: string = null,
    lang: string = null
  ): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/register/resend-telephone-verification/${telephoneNumber}?lang=${lang}`,
      {
        headers: { 'api-key': apiKey || this.apiKey || this.fallbackApiKey }
      }
    )
  }

  public verifyTelephone(
    code: string,
    number: string,
    apiKey: string = null,
    lang: string = null
  ): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/register/telephone-verification/${code}?lang=${lang}`,
      { number },
      {
        headers: { 'api-key': apiKey || this.apiKey || this.fallbackApiKey }
      }
    )
  }
}
