<h2 class="appointment-form-title">
  <T
    str="Choose vehicle"
    key="customerPortal.customer-portal.appointments.choose-vehicle"
    tags="customer-portal, 3.1"
  ></T>
</h2>
<!-- First Form -->
<form
  class="first-form-group appointment-form"
  [formGroup]="appointmentsPopupService.firstFormGroup"
>
  <mat-divider class="my-5"></mat-divider>
  <mat-radio-group formControlName="cars">
    <div *ngIf="appointmentsPopupService.loading">
      <mat-radio-button labelPosition="before">
        <div class="flex my-2.5 items-center gap-2">
          <ngx-skeleton-loader
            appearance="line"
            animation="progress"
          ></ngx-skeleton-loader>
          <div class="w-full text-xl text-left">
            <ngx-skeleton-loader
              appearance="line"
              animation="progress"
              [count]="3"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </mat-radio-button>
      <mat-divider class="my-5"></mat-divider>
    </div>
  </mat-radio-group>

  <mat-radio-group formControlName="cars">
    <div *ngFor="let v of appointmentsPopupService.vehicles">
      <mat-radio-button
        attr.data-cy="add-new-appointment-vehicle-radio-{{ v.vin }}"
        value="{{ v.vin }}"
        (click)="appointmentsPopupService.selectedCar(v)"
        labelPosition="before"
      >
        <div class="flex my-2.5 items-center gap-4" *ngIf="v">
          <div
            [ngClass]="v.pictureType"
            class="hidden md:block"
            *ngIf="!appointmentsPopupService.loading"
          >
            <img
              alt="ef logo"
              class="w-[70px] h-auto m-auto rounded"
              src="assets/ef-logo.svg"
              *ngIf="!v.brandPicture"
            />
            <img
              alt="brand image"
              class="w-[70px] h-auto m-auto rounded"
              src="{{ v.brandPicture }}"
              *ngIf="v.brandPicture"
            />
          </div>
          <div *ngIf="appointmentsPopupService.loading">
            <ngx-skeleton-loader
              appearance="line"
              animation="progress"
            ></ngx-skeleton-loader>
          </div>
          <div class="w-full text-xl text-left">
            <div
              class="license-plate-appointment"
              *ngIf="v.licensePlate && !appointmentsPopupService.loading"
            >
              {{ v.licensePlate }}
            </div>
            <div *ngIf="appointmentsPopupService.loading">
              <ngx-skeleton-loader
                appearance="line"
                animation="progress"
              ></ngx-skeleton-loader>
            </div>
            <div
              class="text-mat-ef-revolution-secondary-500 text-base mt-2 mb-[2px] font-canelablack font-extrabold"
              *ngIf="!appointmentsPopupService.loading"
            >
              {{ v.brand ? v.brand : '' }} {{ v.model ? v.model : '' }}
            </div>
            <div *ngIf="appointmentsPopupService.loading">
              <ngx-skeleton-loader
                appearance="line"
                animation="progress"
              ></ngx-skeleton-loader>
            </div>
            <div
              class="font-efSans-primary font-light text-sm"
              *ngIf="
                !appointmentsPopupService.loading &&
                v.dealer &&
                appointmentsPopupService.country === 'de'
              "
            >
              {{ v.dealer.name ? v.dealer.name : '' }}
            </div>
            <div
              class="font-efSans-primary font-light text-sm"
              *ngIf="
                !appointmentsPopupService.loading &&
                v.dealer &&
                appointmentsPopupService.country === 'ch'
              "
            >
              {{
                v.dealer.name
                  ? v.dealer.name + (', ' + v.dealer.subname) || ''
                  : ''
              }}
            </div>
            <div *ngIf="appointmentsPopupService.loading">
              <ngx-skeleton-loader
                appearance="line"
                animation="progress"
              ></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </mat-radio-button>
      <mat-divider class="my-5"></mat-divider>
    </div>
    <div class="py-2.5" *ngIf="!appointmentsPopupService.loading">
      <mat-radio-button
        labelPosition="before"
        [checked]="appointmentsPopupService.checked"
        (click)="
          selectedOther();
          getModelsDealers(appointmentsPopupService.make?.value)
        "
      >
        <div class="">
          {{ 'other' | sharedTranslate }}
        </div>
      </mat-radio-button>
      <div
        class="pt-2.5 h-auto grid auto-cols-auto"
        *ngIf="appointmentsPopupService.showOther"
      >
        <mat-form-field appearance="legacy" cdkScrollable>
          <mat-label>{{ 'brand' | sharedTranslate }}</mat-label>
          <input
            required
            type="text"
            aria-label="Number"
            matInput
            [formControl]="appointmentsPopupService.make"
            [matAutocomplete]="auto"
            (keyup)="checkOptions(filteredOptions)"
            [errorStateMatcher]="matcher"
          />
          <mat-autocomplete #auto="matAutocomplete" >
            <mat-option
              *ngFor="let brand of filteredOptions | async"
              [value]="brand.name | titlecase"
              (click)="getModelsDealers(appointmentsPopupService.make.value)"
            >
              {{ brand.name | titlecase }}
            </mat-option>
          </mat-autocomplete>

          <mat-error *ngIf="appointmentsPopupService.make.errors">
            <ng-container
              *ngTemplateOutlet="
                errorTranslation;
                context: { data: appointmentsPopupService.make }
              "
            ></ng-container>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="legacy" cdkScrollable>
          <mat-label>{{ 'model' | sharedTranslate }}</mat-label>
          <input
            [readonly]="appointmentsPopupService.make.errors ? true : false"
            required
            type="text"
            aria-label="Number"
            matInput
            [formControl]="appointmentsPopupService.model"
            [matAutocomplete]="modelAutocomplete"
            (keyup)="checkOptionsModels(filteredOptionsModels)"
            [errorStateMatcher]="matcher"
            
          />
          <mat-autocomplete #modelAutocomplete="matAutocomplete" >
            <mat-option
              *ngFor="let model of filteredOptionsModels | async"
              [value]="model.name | titlecase"
            >
              {{ model.name | titlecase }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="appointmentsPopupService.model.errors">
            <ng-container
              *ngTemplateOutlet="
                errorTranslation;
                context: { data: appointmentsPopupService.model }
              "
            ></ng-container>
          </mat-error>
        </mat-form-field>
        <div
          class="licence-plate"
          *ngIf="appointmentsPopupService.country === 'de'"
        >
          <mat-form-field appearance="legacy" class="w-full">
            <mat-label
              ><T
                str="Licence plate"
                key="customerPortal.customer-portal.vehicle.details.registration-plate"
                tags="customer-portal, 3.1"
              ></T
            ></mat-label>
            <input
              required
              type="text"
              matInput
              [formControl]="appointmentsPopupService.licencePlateDE"
              [errorStateMatcher]="matcher"
            />
            <mat-error *ngIf="appointmentsPopupService.licencePlateDE.errors">
              <ng-container
                *ngTemplateOutlet="
                  errorTranslation;
                  context: { data: appointmentsPopupService.licencePlateDE }
                "
              ></ng-container>
            </mat-error>
          </mat-form-field>
        </div>
        <div
          class="grid grid-cols-4 gap-0 md:gap-5"
          *ngIf="appointmentsPopupService.country === 'ch'"
        >
          <mat-form-field
            class="hidden md:inline-block col-span-1 custom-select"
            appearance="legacy"
          >
            <mat-label
              ><T
                str="Canton"
                key="customerPortal.customer-portal.vehicle.details.registration-plate-canton"
                tags="customer-portal, 3.1"
              ></T>
            </mat-label>
            <mat-select
              [formControl]="appointmentsPopupService.licencePlateCanton"
              required
              [errorStateMatcher]="matcher"
            >
              <mat-option *ngFor="let k of cantons" [value]="k.id">
                {{ k.id }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="appointmentsPopupService.licencePlateCanton.errors"
            >
              <ng-container
                *ngTemplateOutlet="
                  errorCHTranslation;
                  context: { data: appointmentsPopupService.licencePlateCanton }
                "
              ></ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="inline-block col-span-4 md:hidden">
            <mat-label
              ><T
                str="Canton"
                key="customerPortal.customer-portal.vehicle.details.registration-plate-canton"
                tags="customer-portal, 3.1"
              ></T>
            </mat-label>
            <select
              required
              matNativeControl
              [formControl]="appointmentsPopupService.licencePlateCanton"
              [errorStateMatcher]="matcher"
            >
              <option *ngFor="let k of cantons" [value]="k.id">
                {{ k.id }}
              </option>
            </select>
          </mat-form-field>
          <mat-form-field appearance="legacy" class="col-span-4 md:col-span-3">
            <mat-label
              ><T
                str="Licence plate"
                key="customerPortal.customer-portal.vehicle.details.registration-plate"
                tags="customer-portal, 3.1"
              ></T
            ></mat-label>
            <input
              required
              type="text"
              matInput
              [formControl]="appointmentsPopupService.licencePlateCH"
              [errorStateMatcher]="matcher"
            />
            <mat-error *ngIf="appointmentsPopupService.licencePlateCH.errors">
              <ng-container
                *ngTemplateOutlet="
                  errorCHTranslation;
                  context: { data: appointmentsPopupService.licencePlateCH }
                "
              ></ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-divider class="mt-7 hidden"></mat-divider>
    </div>
    <div class="flex flex-col gap-[10px] md:hidden">
      <mat-divider class="my-4"></mat-divider>
      <button
        *ngIf="!appointmentsPopupService.loading"
        class="next revolution w-full md:w-auto"
        mat-button
        mat-flat-button
        color="secondary"
        type="button"
        [disabled]="
          !appointmentsPopupService.car ||
          appointmentsPopupService.otherVehicleFormIsInvalid() ||
          appointmentsPopupService.loadingCarData
        "
        (click)="
          appointmentsPopupService.showChooseStepDialog
            ? appointmentsPopupService.decideNextStep(stepper)
            : stepper.next();
          appointmentsPopupService.checkSelected()
        "
      >
        <mat-spinner
          class="inline-block mr-2.5"
          [diameter]="16"
          *ngIf="appointmentsPopupService.loadingCarData"
        ></mat-spinner>
        {{ 'next' | sharedTranslate }}
      </button>
    </div>
  </mat-radio-group>
</form>

<ng-template #errorTranslation let-data="data">
  {{
    transifexService.getErrorTranslation(
      data,
      'customerPortal.customer-portal.appointment.error.',
      {
        errKey: {
          pattern: 'only-numbers-letters-allowed',
          maxlength: 'maximum-length-exceeded'
        }
      }
    )
  }}
</ng-template>

<ng-template #errorCHTranslation let-data="data">
  {{
    transifexService.getErrorTranslation(
      data,
      'customerPortal.customer-portal.appointment.ch.error.',
      {
        num: 6,
        errKey: {
          pattern: 'only-numbers-allowed',
          maxlength: 'max-length-characters'
        }
      }
    )
  }}
</ng-template>
