import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { DateAdapter } from '@angular/material/core'
import { MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS } from '@angular/material/legacy-core'
import { MatStepper } from '@angular/material/stepper'
import {
  CptDateAdapter,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { Observable, map, startWith } from 'rxjs'
import {
  APP_DATE_FORMATS_1,
  SharedService
} from '../../../../services/shared.service'
import { Canton } from '../../../../services/appointments.service'
import { InstantErrorStateMatcherService } from '../../../../services/instant-error-state-matcher.service'
import {
  BrandDTO,
  ModelDTO,
  VehiclesService
} from '../../../../services/vehicles.service'
import { DataService } from '../../../../services/data.service'
import { NGXLogger } from 'ngx-logger'
import { DealersService } from '../../../../services/dealers.service'

@Component({
  selector: 'customer-portal-app-appointments-popup-first-form',
  templateUrl: './appointments-popup-first-form.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: CptDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS_1
    }
  ]
})
export class AppointmentsPopupFirstFormComponent implements OnInit {
  @Input() appointmentsPopupService
  matcher = new InstantErrorStateMatcherService()
  public cantons: Canton[] = [
    { id: 'AG' },
    { id: 'AI' },
    { id: 'AR' },
    { id: 'BE' },
    { id: 'BL' },
    { id: 'BS' },
    { id: 'FR' },
    { id: 'GE' },
    { id: 'GL' },
    { id: 'GR' },
    { id: 'JU' },
    { id: 'LU' },
    { id: 'NE' },
    { id: 'NW' },
    { id: 'OW' },
    { id: 'SG' },
    { id: 'SH' },
    { id: 'SO' },
    { id: 'SZ' },
    { id: 'TG' },
    { id: 'TI' },
    { id: 'UR' },
    { id: 'VD' },
    { id: 'VS' },
    { id: 'ZG' },
    { id: 'ZH' }
  ]

  filteredOptions: Observable<BrandDTO[]>
  public models: ModelDTO[] = []
  filteredOptionsModels: Observable<ModelDTO[]>

  @Input()
  stepper: MatStepper

  constructor(
    private readonly dataService: DataService,
    public sharedService: SharedService,
    public transifexService: TransifexService,
    private readonly vehiclesService: VehiclesService,
    private readonly dealersService: DealersService,
    private readonly logger: NGXLogger,
    private readonly fb: FormBuilder,
    private readonly cdf: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.appointmentsPopupService.firstFormGroup = this.fb.group({
      cars: ''
    })

    this.appointmentsPopupService.sub.onUserVehiclesLoadedSub =
      this.dataService.onUserVehiclesLoaded.subscribe(vehicles => {
        if (this.appointmentsPopupService.vehicles == null) {
          this.appointmentsPopupService.vehicles = vehicles
        }
      })

    this.appointmentsPopupService.checked = false
    this.appointmentsPopupService.showOther = false

    this.filteredOptions = this.appointmentsPopupService.make.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    )
    this.filteredOptionsModels =
      this.appointmentsPopupService.model.valueChanges.pipe(
        startWith(''),
        map(value => {
          return this._filterModels(value)
        })
      )
  }

  getData(data): Record<string, unknown> {
    // to prevent error in ng-template
    return data
  }

  private _filter(value): BrandDTO[] {
    const filterValue = value.toLowerCase()
    return this.appointmentsPopupService.brands
      .filter(brand => brand.name.toLowerCase().includes(filterValue))
      .sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
  }

  private _filterModels(value): ModelDTO[] {
    const filterValue = value.toLowerCase()
    return this.models
      .filter(model => model.name.toLowerCase().includes(filterValue))
      .sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
  }

  checkOptions(filteredBrands: Observable<BrandDTO[]>): void {
    if (filteredBrands.subscribe(v => v.length === 0)) {
      this.appointmentsPopupService.make.setErrors({ invalidMake: true })
    }
  }

  checkOptionsModels(filteredModels: Observable<ModelDTO[]>): void {
    if (filteredModels.subscribe(v => v.length === 0)) {
      this.appointmentsPopupService.model.setErrors({ invalidModel: true })
    }
  }

  getModelsDealers(brand: string): void {
    // other vehicle brand is selected
    // empty previous models and dealers
    this.models = []
    this.appointmentsPopupService.brandDealers = []
    this.appointmentsPopupService.dealers = []
    this.appointmentsPopupService.dealer = undefined
    this.appointmentsPopupService.chosenDealer = undefined
    this.appointmentsPopupService.dealerFinal = undefined
    this.appointmentsPopupService.thirdFormGroup.controls.carDealer.patchValue(
      undefined
    )
    if (
      this.sharedService.stringExists(brand) &&
      this.appointmentsPopupService.brands?.find(
        s => s.name.toLowerCase() === brand.toLowerCase()
      ) != null
    ) {
      const brandObj = this.appointmentsPopupService.brands?.find(
        s => s.name.toLowerCase() === brand.toLowerCase()
      )
      const id = brandObj.id

      // models
      this.vehiclesService.getBrandModels(id).subscribe(
        models => {
          this.models = models
          this.appointmentsPopupService.model.setValue('')
        },
        error => {
          this.appointmentsPopupService.model.setValue('')
          this.logger.error(error)
          this.appointmentsPopupService.make.setErrors({ notFound: true })
        }
      )

      // dealers
      this.dealersService.getBrandDealers(brandObj.name, true).subscribe(
        value => {
          this.appointmentsPopupService.brandDealers = value
          this.appointmentsPopupService.dealers = value
        },
        error => {
          this.logger.error(error)
        }
      )
    }
  }

  selectedOther(): void {
    this.appointmentsPopupService.showOther = true
    this.appointmentsPopupService.link = null
    this.appointmentsPopupService.showChooseStepDialog = false

    this.appointmentsPopupService.car = this.appointmentsPopupService.newVehicle
    this.appointmentsPopupService.dealer =
      this.appointmentsPopupService.newVehicle.dealer
    if (this.appointmentsPopupService.dealer != null) {
      this.appointmentsPopupService.thirdFormGroup
        .get('carDealer')
        .setValue(this.appointmentsPopupService.dealers?.[0]?.id)
    }
    this.cdf.detectChanges()
  }
}
