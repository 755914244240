<div class="cpt-modal relative dialog-content-p-0 mat-dialog-actions-pb-20">
  <h2
    class="font-canelablack-primary text-4xl flex items-center"
    mat-dialog-title
  >
    <mat-icon svgIcon="error_outline" class="w-9 h-9"></mat-icon>
    <T
      str="Attention"
      key="customerPortal.customer-portal.attention"
      tags="customer-portal, 3.1"
    ></T>
  </h2>

  <p class="font-efSans-primary my-6">
    <T
      str="Dear {firstName} {lastName}, since you have not submitted a declaration of consent for EFDS, you cannot use this function. Please go to (button). And give us your declaration of consent for EFDS, then you can enjoy all the functions of CPT."
      key="customerPortal.customer-portal.not-submited-on-declaration-of-consents"
      tags="customer-portal, 3.1"
      [vars]="{ firstName: user?.firstName, lastName: user?.lastName }"
    ></T>
  </p>

  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <a
      mat-button
      mat-stroked-button
      color="secondary"
      routerLink="user/dataprotection"
      [mat-dialog-close]="true"
      class="revolution inline-flex items-center justify-center"
    >
      {{ 'data-protection' | sharedTranslate }}
      <mat-icon svgIcon="arrow_right"></mat-icon>
    </a>
  </mat-dialog-actions>
</div>
