import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS } from '@angular/material/legacy-core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { MatStepper } from '@angular/material/stepper'
import {
  CptDateAdapter,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { TranslateService } from '@ngx-translate/core'
import {
  APP_DATE_FORMATS_1,
  SharedService
} from '../../../services/shared.service'

import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import {
  AppointmentsPopupService,
  ReifenData
} from '../../../services/appointments-popup.service'

@Component({
  selector: 'customer-portal-app-appointments-popup',
  templateUrl: './appointments-popup.component.html',
  providers: [
    AppointmentsPopupService,
    {
      provide: DateAdapter,
      useClass: CptDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS_1
    }
  ]
})
export class AppointmentsPopupComponent
implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('stepper', { static: true }) stepper: MatStepper

  constructor (
    public appointmentsPopupService: AppointmentsPopupService,
    public dialog: MatDialog,
    readonly dialogRef: MatDialogRef<AppointmentsPopupComponent>,
    public translateService: TranslateService,
    public sharedService: SharedService,
    private readonly cdf: ChangeDetectorRef,
    public transifexService: TransifexService,
    @Inject(MAT_DIALOG_DATA) public reifenData_: ReifenData,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.appointmentsPopupService.reifenData = reifenData_
    this.setStepper()
  }

  async ngOnInit (): Promise<void> {
    this.setStepper()
    this.setStepperStep()

    this.appointmentsPopupService.sendGTMData(this.stepper?.selectedIndex >= 0 ? (this.stepper.selectedIndex + 1) : 1)

    this.appointmentsPopupService.loading = true
    this.appointmentsPopupService.country =
      this.remoteConfigService.get('country.code')

    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    // we want the button to be disabled from start if we started on higher steps
    if (this.appointmentsPopupService.reifenData?.vehicle != null) {
      this.appointmentsPopupService.loadingCarData = true
    }

    void this.appointmentsPopupService.getDataFromLS()

    this.appointmentsPopupService.loadData()

    this.cdf.detectChanges()
  }

  ngAfterViewInit (): void {
    this.setStepper()
  }

  setStepper (): void {
    try {
      this.stepper._getIndicatorType = () => 'number'
    } catch (error) {
      // no need for log
    }
  }

  setStepperStep (): void {
    if (
      this.appointmentsPopupService.reifenData?.vehicle != null &&
      this.appointmentsPopupService.reifenData?.step != null
    ) {
      this.stepper.selectedIndex = this.appointmentsPopupService.reifenData.step
    } else if (
      this.appointmentsPopupService.reifenData?.vehicle != null &&
      this.appointmentsPopupService.reifenData?.step == null
    ) {
      if (
        this.appointmentsPopupService.reifenData.vehicle.currentMileage !=
          null ||
        this.appointmentsPopupService.reifenData.vehicle.manualMileage != null
      ) {
        this.stepper.selectedIndex = 2
        this.appointmentsPopupService.secondFormGroup
          .get('mileage')
          .setValue(
            this.appointmentsPopupService.reifenData.vehicle.manualMileage !=
              null
              ? this.appointmentsPopupService.reifenData.vehicle.manualMileage
              : this.appointmentsPopupService.reifenData.vehicle.currentMileage
          )
      } else {
        this.stepper.selectedIndex = 1
      }
    }
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.appointmentsPopupService.sub)
  }

  isServiceAction (): boolean {
    return this.appointmentsPopupService?.reifenData?.serviceAction === true
  }
}
