<div class="RevolutionDealerInfoComponent w-full flex gap-6 flex-col">
  <mat-card
    class="p-6 w-full h-fit !flex flex-col !shadow-none !rounded-lg border border-mat-ef-revolution-supergrey-500"
  >
    <div
      class="w-full font-efSans text-xl text-mat-ef-revolution-primary-500 font-black"
    >
      <T
        str="We are here for you"
        key="customerPortal.customer-portal.dealers.info.title"
        tags="customer-portal, 3.1"
      ></T>
    </div>
    <div
      class="w-full font-efSans text-base text-mat-ef-revolution-primary-500 pt-1"
    >
      <div *ngIf="workingHours">
        <div *ngIf="workingHours.open">
          <div class="font-efSans text-sm flex flex-wrap">
            <div>
              <T
                str="Now open"
                key="customerPortal.customer-portal.vehicle.nowOpen"
                tags="customer-portal, 3.1"
              ></T>
              &nbsp;
            </div>
            <ng-container
              *ngTemplateOutlet="
                workingHoursTemplate;
                context: { workingHours: workingHours }
              "
            ></ng-container>
          </div>
        </div>
        <div *ngIf="!workingHours.open">
          <div class="font-efSans text-sm flex flex-wrap">
            <div>
              <T
                str="Now closed"
                key="customerPortal.customer-portal.vehicle.nowClosed"
                tags="customer-portal, 3.1"
              ></T>
              &nbsp;
            </div>
            <ng-container
              *ngTemplateOutlet="
                workingHoursTemplate;
                context: { workingHours: workingHours }
              "
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
    <div
      class="w-full font-efSans text-mat-ef-revolution-secondary-500 pt-4 flex"
    >
      <div class="w-1/12 lg:w-1/6">
        <mat-icon class="!h-5 !w-5 mr-5" svgIcon="phone"></mat-icon>
      </div>
      <a
        data-rel="external"
        class="text-sm font-semibold cursor-pointer"
        (click)="
          openPhoneClient($event); sendOutboundLinkData('Phone Click', dealer)
        "
        >{{ dealer.telephone }}</a
      >
    </div>

    <div
      class="w-full font-efSans text-mat-ef-revolution-secondary-500 pt-1 flex"
    >
      <div class="w-1/12 lg:w-1/6">
        <mat-icon class="!h-5 !w-5 mr-5" svgIcon="mail"></mat-icon>
      </div>
      <a
        data-rel="external"
        class="text-sm font-semibold cursor-pointer break-all"
        (click)="
          openEmailClient($event); sendOutboundLinkData('Email Click', dealer)
        "
        >{{ dealer.email }}</a
      >
    </div>

    <hr class="text-mat-ef-revolution-supergrey-500 my-3" />

    <div
      class="w-full font-efSans text-xl text-mat-ef-revolution-primary-500 font-black"
    >
      <T
        str="Appointment for service"
        key="customerPortal.customer-portal.dealers.service-termin.title"
        tags="customer-portal, 3.1"
      ></T>
    </div>
    <div
      class="w-full font-efSans text-sm text-mat-ef-revolution-primary-500 pt-1"
    >
      <T
        str="Book your appointment, fast and simple"
        key="customerPortal.customer-portal.dealers.service-termin.text"
        tags="customer-portal, 3.1"
      ></T>
    </div>
    <div class="w-full pt-4">
      <button
        (click)="
          link && hasServiceManager ? decideNextStep() : newAppointment();
          sendContentModulesCTAData(
            'customerPortal.customer-portal.dealers.service-termin.button'
          )
        "
        mat-button
        mat-raised-button
        color="secondary"
        class="revolution w-full h-12"
      >
        <mat-icon class="mb-1" svgIcon="car_inspect"></mat-icon>
        <T
          str="Book an appointment"
          key="customerPortal.customer-portal.dealers.service-termin.button"
          tags="customer-portal, 3.1"
        ></T>
      </button>
    </div>
  </mat-card>

  <mat-card
    *ngIf="liveChatAvailable && (dealer.salesman || dealer.salesAdvisor)"
    class="p-6 w-full h-fit !flex flex-col !shadow-none !rounded-lg border border-mat-ef-revolution-supergrey-500"
  >
    <div
      class="w-full font-efSans text-xl text-mat-ef-revolution-primary-500 font-black"
    >
      <T
        str="Live-Chat"
        key="customerPortal.customer-portal.live-chat.title"
        tags="customer-portal, 3.1"
      ></T>
    </div>
    <div
      class="w-full font-efSans text-sm text-mat-ef-revolution-primary-500 pt-1"
    >
      <T
        str="You can now contact us online"
        key="customerPortal.customer-portal.live-chat.text"
        tags="customer-portal, 3.1"
      ></T>
    </div>

    <div class="w-full pt-4">
      <button
        mat-stroked-button
        color="primary"
        class="w-full !border-mat-ef-revolution-secondary-500 !border-2 h-12 text-sm"
        (click)="openChatWindow.emit(dealer); sendLiveChatOpenedGtmData()"
      >
        <mat-icon class="mr-1" svgIcon="chat"></mat-icon>

        <T
          str="Start LiveChat"
          key="customerPortal.customer-portal.live-chat.start"
          tags="customer-portal, 3.1"
        ></T>
      </button>
    </div>
  </mat-card>
</div>

<ng-template #workingHoursTemplate let-workingHours="workingHours">
  <div *ngIf="workingHours.day">
    <div class="inline-flex">
      <span class="float-left">{{ '(' }}</span>
      <span class="float-left">
        {{
          {
            str: workingHours.day,
            prefix: 'customerPortal.customer-portal.dealer-details.days.'
          } | transifexTranslate
        }}
        {{ workingHours.dateToday_noSeconds + ')' }}
      </span>
    </div>
  </div>
</ng-template>
