import { Component } from '@angular/core'
import { SharedService } from '../../../../services/shared.service'
import { VehicleDocumentsService } from '../../../../services/vehicle-documents.service'

@Component({
  selector: 'customer-portal-app-unread-document',
  templateUrl: './unread-document.component.html'
})
export class UnreadDocumentComponent {
  unread = 0

  sub = {
    onUnreadMyDocuments: null
  }

  constructor(
    public sharedService: SharedService,
    private readonly vehicleDocumentsService: VehicleDocumentsService
  ) {
    this.sub.onUnreadMyDocuments =
      this.vehicleDocumentsService.onUnreadMyDocuments.subscribe(count => {
        this.unread = count
      })
  }
}
