import { Injectable } from '@angular/core'
import { FormControl } from '@angular/forms'

@Injectable({
  providedIn: 'root'
})
export class PasswordValidationService {
  public password = (control: FormControl): Record<string, unknown> => {
    if (control == null) {
      return {}
    }

    const value = control.value as string

    // one uppercase
    if (value?.match('[A-Z]') == null) {
      return { uppercase: true }
    }

    // one lowercase
    if (value?.match('[a-z]') == null) {
      return { lowercase: true }
    }

    // one numeric
    if (value?.match('[0-9]') == null) {
      return { numeric: true }
    }

    // length
    if (value?.length < 8) {
      return { length: true }
    }

    return {}
  }
}
