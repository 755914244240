/* eslint-disable @typescript-eslint/member-ordering */

import { Injectable } from '@angular/core'
import { TranslationService as TransifexTranslationService } from '@emilfreydigital/transifex-angular'
import { BehaviorSubject } from 'rxjs'
import { NGXLogger } from 'ngx-logger'
import { TransifexCache } from './transifex-cache.service'
import { SharedService, type GenericObject } from './shared.service'
import { SharedTranslationsService } from './shared-translations.service'

@Injectable({
  providedIn: 'root'
})
export class TransifexService {
  transifexInitialized = false
  private readonly _transifexInitialized = new BehaviorSubject<boolean>(false)
  onTransifexInitialized = this._transifexInitialized.asObservable()

  // show spinner if the transifex is initializing, true on default
  private readonly _transifexLanguageIsLoading = new BehaviorSubject<boolean>(
    true
  )
  transifexLanguageIsLoading = this._transifexLanguageIsLoading.asObservable()

  private readonly _transifexLanguage = new BehaviorSubject<string>(null)
  onTransifexLanguageChange = this._transifexLanguage.asObservable()

  constructor(
    private readonly transifexTranslateService: TransifexTranslationService,
    private readonly logger: NGXLogger,
    private readonly sharedService: SharedService,
    private readonly sharedTranslationsService: SharedTranslationsService
  ) {}

  initializeTransifex(transifexConfigToken: string): void {
    this._transifexLanguageIsLoading.next(true)

    let showTranslationKeys = false
    let showTranslationStr = false
    const params = new URL(window.location.href).searchParams
    if (params != null) {
      showTranslationKeys = params.get('showTranslationKeys') === 'true'
      showTranslationStr = params.get('showTranslationStr') === 'true'
    }

    this.transifexTranslateService
      .init({
        token: transifexConfigToken,
        /* @ts-expect-error Possibly wrong type definition in Transifex */
        cache: new TransifexCache(showTranslationKeys, showTranslationStr)
      })
      .then(() => {
        this.transifexInitialized = true
        this._transifexInitialized.next(true)
      })
      .catch(err => {
        this.transifexInitialized = false
        this._transifexInitialized.next(false)
        this.logger.debug(err)
      })
  }

  setLanguage(
    code: string,
    transifexLanguages: string[],
    countryCode: string
  ): void {
    if (!this.isTransifexReady()) {
      return
    }

    let _languageCode
    if (
      transifexLanguages.find(lang => {
        return lang.toLowerCase() === code.toLowerCase()
      }) != null
    ) {
      _languageCode = code
    } else {
      _languageCode = code.toLowerCase() + '_' + countryCode.toUpperCase()
    }

    this.setCurrentLocale(_languageCode)
  }

  setCurrentLocale(_languageCode: string): void {
    this.transifexTranslateService
      .setCurrentLocale(_languageCode)
      .then(() => {
        this._transifexLanguage.next(_languageCode)
        setTimeout(() => {
          this._transifexLanguageIsLoading.next(false)
        })
      })
      .catch(err => {
        this.logger.debug(err)
        setTimeout(() => {
          this._transifexLanguageIsLoading.next(false)
        })
      })
  }

  getCurrentLocale(): string {
    return this.transifexTranslateService.getCurrentLocale()
  }

  isTransifexReady(): boolean {
    return this.transifexInitialized
  }

  // ************************transifex key and str***************************
  getTransifexStrFromKey(key: string): string {
    return this.sharedService.splitOnLastOccurrence(key, '.')?.[1] ?? key
  }

  addTransifexPrefix(key: string, prefix: string): string {
    return key.startsWith('shared') ? '' : key.startsWith(prefix) ? '' : prefix
  }

  getTransifexTranslation(str, key: string, params?): string {
    const obj = { _key: key, _tags: 'notranslate' }
    return this.transifexTranslateService.translate(str, { ...obj, ...params })
  }

  getErrorTranslation(data, prefix: string, params?: GenericObject): string {
    if (data.errors != null) {
      const err = Object.keys(data.errors)?.[0]
      // check if error has shared translation
      let errSharedKey = this.sharedTranslationsService.getErrorSharedKey(err)
      if (params?.errKey != null) {
        const errKey = params.errKey[err]
        errSharedKey = errKey != null ? errKey : errSharedKey
      }
      if (errSharedKey != null) {
        return this.getSharedTranslation(errSharedKey, params)
      } else {
        return this.getTransifexTranslation(
          err,
          this.addTransifexPrefix(prefix, 'customerPortal.') + prefix + err,
          params
        )
      }
    }
    return ''
  }

  getSharedTranslation(key: string, params?): string {
    return this.sharedTranslationsService.getSharedTranslation(key, params)
  }

  getTranslation(translationObj: {
    prefix?: string
    str?: string
    key?: string
    params?: GenericObject
    skipShared?: boolean
  }): string {
    if (translationObj.str != null || translationObj.key != null) {
      if (translationObj.key != null) {
        return this.getTransifexTranslation(
          translationObj.str ?? this.getTransifexStrFromKey(translationObj.key),
          translationObj.key,
          translationObj.params
        )
      } else {
        if (!(translationObj.skipShared === true)) {
          // check if str has shared translation
          const sharedTranslation = this.getSharedTranslation(
            translationObj.str,
            translationObj.params
          )
          if (this.sharedService.stringExists(sharedTranslation)) {
            return sharedTranslation
          }
        }
        return this.getTransifexTranslation(
          translationObj.str,
          String(translationObj.prefix) + String(translationObj.str),
          translationObj.params
        )
      }
    }
    return ''
  }
}
