import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
export type DriversLicenceDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.documents.dto.DriversLicenceDTO
export type DocumentDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.documents.dto.DocumentDTO

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  private readonly apiUrl

  constructor(
    private readonly http: HttpClient,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  getDriversLicence(): Observable<DriversLicenceDTO> {
    return this.http.get<DriversLicenceDTO>(
      `${this.apiUrl}/documents/drivers-licence`
    )
  }

  postDriversLicence(side: string, image): Observable<DriversLicenceDTO> {
    return this.http.post<DriversLicenceDTO>(
      `${this.apiUrl}/documents/drivers-licence/${side}`,
      image
    )
  }

  deleteDriversLicence(side: string): Observable<unknown> {
    return this.http.delete(`${this.apiUrl}/documents/drivers-licence/${side}`)
  }

  getDocument(documentId: string): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/documents/${documentId}`, {
      responseType: 'blob'
    })
  }
}
