import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import {
  CptGoogleTagmanagerService,
  EcondaService
} from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { SharedService } from '../../../../services/shared.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'

@Component({
  selector: 'customer-portal-app-navbar-second',
  templateUrl: './navbar-second.component.html'
})
export class NavbarSecondComponent implements OnInit {
  firstVehicleVin = ''
  homepage: string
  vehicles: VehicleDTOExtended[]
  // channels: boolean
  country
  unread = 0
  hasTestView
  navigationMarketplace

  constructor (
    public dataService: DataService,
    private readonly sharedService: SharedService,
    private readonly logger: NGXLogger,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public router: Router,
    private readonly econdaService: EcondaService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.homepage = this.remoteConfigService.get('homepage')
    this.country = this.remoteConfigService.get('country.code')
    this.hasTestView = this.remoteConfigService.get('hasTestView')
    this.navigationMarketplace = this.remoteConfigService.getArrayValues<any>(
      'navigationMarketplace'
    )
  }

  ngOnInit (): void {
    this.dataService.onUserVehiclesLoaded.subscribe(vehicles => {
      this.vehicles = vehicles
      // this.logger.debug(vehicles)
      if (vehicles?.length > 0) {
        this.firstVehicleVin = vehicles[0].vin
      }
    })
  }

  sendGTMNavigationData (label?: string, linkType?: string): void {
    this.cptGtmService.sendNavigationLinkData(
      null,
      'Sub Navigation',
      this.sharedService.translateLink(label),
      linkType
    )
    this.econdaService.send({
      content: 'Navigation',
      Target: [
        'Sub Navigation',
        this.sharedService.translateLink(label),
        1,
        'd'
      ]
    })
  }
}
