import { ChangeDetectorRef, ElementRef, Injectable } from '@angular/core'
import {
  ChatBotService,
  CptGoogleTagmanagerService,
  EcondaService,
  type GTMPageViewData
} from '@inside-hub-app/customer-portal-shared'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { TranslateService } from '@ngx-translate/core'
import { NGXLogger } from 'ngx-logger'
import { combineLatest, forkJoin } from 'rxjs'
import {
  AppointmentsService,
  type UpcomingAppointmentDTO
} from './appointments.service'
import { DashEcoService } from './dash-eco.service'
import { DataService } from './data.service'
import { HashingService } from './hashing.service'
import { LocalStorageService } from './local-storage.service'
import { MyMailboxService } from './my-mailbox.service'
import { EfOneSignalService } from '@inside-hub-app/ef-onesignal'

import {
  PersonalizationService,
  type PersonalizeDTO
} from './personalization.service'
import { SharedService } from './shared.service'
import {
  type BasicDocumentDTOExtended,
  VehicleDocumentsService
} from './vehicle-documents.service'
import { VehicleNotificationsService } from './vehicle-notifications.service'
import { VehicleRemindersService } from './vehicle-reminders.service'
import { VehiclesInitialLoadService } from './vehicles-initial-load.service'
import {
  type TrackAndTraceVehicleDTOExtended,
  type VehicleDTOExtended,
  type VehicleDealersDTO,
  type VehiclesResponse,
  VehiclesService
} from './vehicles.service'
import { parseISO } from 'date-fns'

@Injectable()
export class AppService {
  public hasTracking
  public hasAppointments
  showDashEcoNews

  allVehicles: VehicleDTOExtended[]
  vin: string
  tnt: TrackAndTraceVehicleDTOExtended[]
  userDealers: VehicleDealersDTO[]
  personalization: PersonalizeDTO
  articleParams

  combineLatestSub
  constructor (
    private readonly localStorage: LocalStorageService,
    private readonly logger: NGXLogger,
    private readonly sharedService: SharedService,
    private readonly dataService: DataService,
    private readonly vehiclesService: VehiclesService,
    private readonly vehiclesInitialLoadService: VehiclesInitialLoadService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly econdaService: EcondaService,
    private readonly keyCloakService: KeycloakService,
    private readonly vehicleDocumentsService: VehicleDocumentsService,
    private readonly chatBotService: ChatBotService,
    private readonly appointmentsService: AppointmentsService,
    private readonly personalizationService: PersonalizationService,
    private readonly dashEcoService: DashEcoService,
    private readonly hashingService: HashingService,
    private readonly translate: TranslateService,
    private readonly oneSignalService: EfOneSignalService,
    private readonly vehicleNotificationsService: VehicleNotificationsService,
    private readonly myMailboxService: MyMailboxService,
    private readonly vehicleRemindersService: VehicleRemindersService,
    private readonly cdref: ChangeDetectorRef,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>,
    private readonly elementRef: ElementRef
  ) {
    this.hasTracking = this.remoteConfigService.get('hasTracking')
    this.hasAppointments = this.remoteConfigService.get('hasAppointments')
    this.showDashEcoNews = this.remoteConfigService.get('showDashEcoNews')
  }

  async getUserVehicles (): Promise<void> {
    // get data from indexed DB
    // User Vehicles
    let preventLoading = false
    try {
      this.allVehicles =
        (await this.localStorage.getVehicles()) as VehicleDTOExtended[]
    } catch (error) {
      this.logger.debug(error)
    }
    if (this.allVehicles != null) {
      this.dataService.userVehiclesLoaded(this.allVehicles)
      if (this.sharedService.stringExists(this.vin)) {
        const vehicle = this.allVehicles.find(({ vin }) => vin === this.vin)
        void this.dataService.getDatafromLocalStorage(vehicle)
        preventLoading = true
      }
    }
    // tnt
    let allVehiclesTNT: TrackAndTraceVehicleDTOExtended[]
    if (this.hasTracking) {
      try {
        allVehiclesTNT = await this.localStorage.getVehiclesTNT()
      } catch (error) {
        this.logger.debug(error)
      }

      if (allVehiclesTNT != null) {
        this.vehiclesService.setStatus(allVehiclesTNT)
        this.vehiclesService.setVehicleTNTHistory(
          this.allVehicles,
          allVehiclesTNT
        )
        this.dataService.userVehiclesTNTLoaded(
          allVehiclesTNT != null ? allVehiclesTNT : []
        )
      }
    }

    this.vehiclesService
      .getVehicles(true, false, !!this.hasTracking)
      .subscribe((allVehicles: VehiclesResponse) => {
        this.allVehicles = allVehicles.userVehicles
        this.tnt = allVehicles.tntVehicles
        // this.tnt = TNT as any // use this for mocked data

        if (this.hasTracking) {
          this.vehiclesService.setStatus(this.tnt)
          this.vehiclesService.setVehicleTNTHistory(this.allVehicles, this.tnt)
          void this.localStorage.setVehiclesTNT(
            this.tnt != null ? this.tnt : []
          )
          this.dataService.userVehiclesTNTLoaded(
            this.tnt != null ? this.tnt : []
          )
        }
        this.dataService.userVehiclesLoaded(this.allVehicles)
        this.cptGtmService.sendVehiclesLoaded(this.allVehicles)
        this.econdaService.send({
          content: 'Homepage',
          Target: [
            'Vehicles loaded',
            JSON.stringify(this.allVehicles.length),
            1,
            'd'
          ]
        })
        if (allVehicles?.userVehicles?.length > 0) {
          let currentVehicleVin
          if (this.sharedService.stringExists(this.vin)) {
            currentVehicleVin = this.vin
            const v = allVehicles.userVehicles.find(
              ({ vin }) => vin === this.vin
            )
            void this.dataService.loadVehicleData(v, preventLoading)
          } else {
            currentVehicleVin = allVehicles?.userVehicles[0].vin
            // removed loadVehicleData since its called onVehicleVinChange
            this.dataService.vehicleVinChanged(allVehicles.userVehicles[0].vin)
          }

          // get data for other vehicles and set them to local storage
          const vehicles = allVehicles?.userVehicles.filter(vehicle => {
            return vehicle.vin !== currentVehicleVin
          })
          void this.vehiclesInitialLoadService.loadVehiclesData(vehicles)
        }
      })
  }

  async getUserDealers (reloadUserDealers?: boolean): Promise<void> {
    // Get user dealers
    if (reloadUserDealers === false) {
      try {
        this.userDealers = await this.localStorage.getUserDealers()
      } catch (error) {
        this.logger.debug(error)
      }
      if (this.userDealers != null) {
        this.dataService.userDealersLoaded(this.userDealers)
      }
    }
    this.vehiclesService.getUserDealers(true).subscribe(
      dealers => {
        this.userDealers = dealers != null ? dealers : []
        this.dataService.userDealersLoaded(this.userDealers)
        void this.localStorage.setUserDealers(this.userDealers)
        this.vehiclesService.setVehicleDealer(
          this.allVehicles,
          this.userDealers
        )
      },
      error => {
        this.logger.debug(error)
        this.dataService.userDealersLoaded([])
      }
    )
  }

  filterOnlyDocumentSales (doc: BasicDocumentDTOExtended): boolean {
    return this.vehicleDocumentsService.filterOnlyDocumentSales(doc)
  }

  loadChatBot (): void {
    const visible: boolean = this.remoteConfigService.get('chatBot.visible')
    this.chatBotService.loadChatBot()  
    this.chatBotService.apiLoaded.subscribe(api => {
      const isLoggedin = this.keyCloakService.isLoggedIn()
      if (isLoggedin) {
        this.dataService.onUserLoaded.subscribe(user => {
          api
            .setContactInfo({
              name: `${String(user.firstName)} ${String(user.lastName)}`,
              email: user.email
            })
            .catch(error => {
              this.logger.error(error)
            })
        })

        api.state$.subscribe({
          next: state => {
            this.sharedService.chatBotState = state
            this.cdref.detectChanges()
          }
        })

        void this.chatBotService.visibility(api, visible)
      } else {
        void this.chatBotService.visibility(api, false)
      }
    })
      
  }

  async getUpcomingAppointments (): Promise<void> {
    if (this.hasAppointments) {
      // initialy show data from local storage
      let upcomingAppointments: UpcomingAppointmentDTO[] = null
      try {
        upcomingAppointments = (await this.localStorage.getUpcomingAppointments())
      } catch (error) {
        this.logger.debug(error)
      }
      this.dataService.upcomingAppointmentsLoaded(upcomingAppointments)
      this.dataService.loading.upcomingAppointments.next(false)

      forkJoin([
        this.appointmentsService.upcomingAppointments(),
        this.appointmentsService.upcomingActivities()
      ]).subscribe(
        (value: [UpcomingAppointmentDTO[], UpcomingAppointmentDTO[]]) => {
          // combine 2 arrays into 1
          let upcoming = (value[0] != null ? value[0] : []).concat(
            value[1] != null ? value[1] : []
          )
          // find first upcoming appointment
          let first = null
          if (upcoming?.[0] != null) {
            upcoming = upcoming.sort((a, b) => {
              if (parseISO(a.startDate) > parseISO(b.startDate)) {
                return 1
              }
              if (parseISO(a.startDate) < parseISO(b.startDate)) {
                return -1
              }
              return 0
            })
            first = upcoming[0]
          }
          this.dataService.upcomingAppointmentsLoaded(upcoming)
          this.dataService.firstUpcomingAppointmentLoaded(first)
          this.dataService.loading.upcomingAppointments.next(false)
          void this.localStorage.setUpcomingAppointments(upcoming, true)

          // // mock data
          // this.dataService.upcomingAppointmentsLoaded(UPCOMING_APPOINTMENTS as any)
        }
      )
    } else {
      this.dataService.loading.upcomingAppointments.next(false)
    }
  }

  getPersonalizationValues (): void {
    if (this.showDashEcoNews) {
      this.personalizationService
        .getPersonalizationValues()
        .subscribe(personalization => {
          this.personalization = personalization
          this.dataService.personalizationLoaded(personalization)
        })

      // get new articles
      this.combineLatestSub = combineLatest([
        this.dataService.onUserVehiclesLoaded,
        this.dataService.onPersonalizationLoaded,
        this.dataService.onUserDealersLoaded
      ]).subscribe(res => {
        // need to set only vin to prevent article reload on vehicle details change
        const vehicles = []
        try {
          res[0].forEach(vehicle => {
            vehicles.push(vehicle.vin)
          })
        } catch (error) {
          this.logger.debug(error)
        }
        const articleParams = [vehicles, res[1], res[2]]
        if (!this.sharedService.isEqual(articleParams, this.articleParams)) {
          this.articleParams = this.sharedService.deepCopy(articleParams)
          this.dashEcoService.setArticles(res[0], res[1], res[2])
        }
      })
    }
  }

  sendGtmData (user?): void {
    // gtag('config', this.remoteConfigService.get('googleAnalytics.id, { page_path: event.urlAfterRedirects })')
    const language = this.translate.currentLang
    const country: string = this.remoteConfigService.get('country.code')
    const email = user != null ? user.email : null
    const loggedIn = user != null ? 'yes' : 'no'
    const data: GTMPageViewData = {
      country,
      language,
      pageType: 'kundenportal',
      loggedIn,
      userid: this.sharedService.stringExists(email)
        ? this.hashingService.md5(email)
        : null,
      subSystem: 'Customer Portal',
      internal: '0',
      event: 'pageView'
    }
    this.cptGtmService.send(data)
  }

  onUserLoaded (user): void {
    // this.cptGtmService.sendLoginSuccessData()
    this.localStorage.setUser(user)
    this.oneSignalService.updateUserid(user.contactId.toString())
    const lang =
      user.language != null ? user.language.find(s => s.primary).language : 'de'
    this.localStorage.setLang(lang.toLowerCase())
    this.translate.addLangs(
      this.remoteConfigService.get('b2c.supportedLanguages')
    )
    this.translate.setDefaultLang('de')
    this.translate.use(lang.toLowerCase())
    this.translate.currentLang = lang.toLowerCase()
    this.sendGtmData(user)

    // User logged in, load data:
    /* Initial data loading
     *
     * appoitnemnts, user-vehicles, attentions
     *
     */

    this.vehicleNotificationsService.getVehiclesNotificationsFun()
    this.getUpcomingAppointments()
    this.getPersonalizationValues()
    this.myMailboxService.getMyMailboxCount()
    void this.dataService.getMyDocuments()
    void this.dataService.getInvoices()
    void this.getUserVehicles()
    void this.getUserDealers()
    void this.dashEcoService.getUserArticles()

    if (this.remoteConfigService.get('remindersConfig.hasReminders')) {
      void this.vehicleRemindersService.getChannelsData()
    }
  }
}
