<div id="vehicle-navigation" class="container" *ngIf="!commissionNumber && vin">
  <mat-card class="m-auto rounded-lg my-14">
    <nav
      mat-tab-nav-bar
      mat-stretch-tabs
      class="w-[calc(100%+32px)] -mx-4 md:w-full md:mx-auto lg:w-10/12 ef-tab-nav-bar"
    >
      <ng-container *ngFor="let i of navigationItems">
        <ng-container *ngIf="!i.hidden">
          <a
            data-cy="vehicle-navigation-tab"
            mat-tab-link
            (click)="sendContentModulesTabSwitchData(i)"
            [routerLink]="i.pathWithParams"
            [routerLinkActive]="['active']"
            class="relative gap-1 min-w-fit"
            #rla="routerLinkActive"
            [active]="rla.isActive"
          >
            <T
              [str]="i.name"
              [key]="i.translationKey"
              tags="customer-portal, notranslate"
            ></T>
            <span
              *ngIf="i?.unreadDocuments"
              class="absolute right-0 flex justify-center items-center bg-mat-ef-revolution-secondary-500 rounded-full text-xs h-5 w-5 text-white"
              >{{ i?.unreadDocuments }}</span
            >
          </a>
        </ng-container>
      </ng-container>
    </nav>
    <customer-portal-app-revolution-vehicle-details-view *ngIf="vinIsValid">
    </customer-portal-app-revolution-vehicle-details-view>
  </mat-card>
</div>

<customer-portal-app-vehicle-track-trace
  *ngIf="commissionNumber"
></customer-portal-app-vehicle-track-trace>
