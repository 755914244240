import {
  NgModule,
  Optional,
  SkipSelf,
  ModuleWithProviders
} from '@angular/core'
import { Environment } from './environments'
import { HttpClientModule } from '@angular/common/http'

export class B2CModuleConfig {
  environment: Environment
  apiKey: string
  cordovaLoginRedirectUri?: string
}

@NgModule({
  imports: [HttpClientModule]
})
export class B2CModule {
  constructor(@Optional() @SkipSelf() parentModule: B2CModule) {
    if (parentModule) {
      throw new Error(
        'B2CModule is already loaded. Import it in the AppModule only'
      )
    }
  }

  static forRoot(config: B2CModuleConfig): ModuleWithProviders<B2CModule> {
    return {
      ngModule: B2CModule,
      providers: [{ provide: B2CModuleConfig, useValue: config }]
    }
  }
}
