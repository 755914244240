<mat-menu
  class="visible md:invisible overflow-y-hidden w-screen max-w-none p-4"
  #menu="matMenu"
>
  <a
    href="{{ l.link }}"
    target="_blank"
    class="font-efSans flex items-center justify-between px-2 py-4 cursor-pointer"
    *ngFor="let l of mobileMenuLinks"
    (click)="sendGTMNavigationData('Burger Menu', 'White Box Link')"
  >
    <span class="text-mat-ef-revolution-primary-500">
      <ng-container
        *ngTemplateOutlet="
          sharedService.template('transifexTranslation');
          context: {
            str: l?.eventLabel,
            key: l?.translation
          }
        "
      ></ng-container>
    </span>
    <mat-icon
      svgIcon="arrow_right"
      class="text-mat-ef-revolution-secondary-500"
    ></mat-icon>
  </a>

  <!-- style="background-color: #016900;" -->
  <div
    class="pt-10"
    (click)="sendGTMNavigationData('Burger Menu', 'White Box Link')"
  >
    <div class="flex flex-wrap gap-4 md:gap-6">
      <div class="block w-full">
        <div class="w-full">
          <a
            href="/de/aktionen/details/land-rover-macht-autotraeume-wahr-28"
            target="_blank"
          >
            <div
              class="flex items-center bg-mat-ef-revolution-green-one-500 justify-between h-24 px-2 py-4 rounded-lg"
            >
              <div class="flex items-center justify-between md:flex-shrink-0">
                <h3
                  class="flex-shrink-0 font-canelablack text-white text leading-6 max-w-full text-xl text-dark-text"
                >
                  <T
                    str="All your dreams of Land Rover come true"
                    key="customerPortal.customer-portal.land-rover.makes.car.dream"
                    tags="customer-portal, 3.1"
                  ></T>
                </h3>
              </div>
              <mat-icon svgIcon="arrow_forward_ios" class="text-white">
              </mat-icon>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div
      class="grid grid-cols-2 gap-4 md:gap-6 md:mt-24 mt-16"
      (click)="sendGTMNavigationData('Burger Menu', 'White Box Link')"
    >
      <div class="block w-full">
        <div class="w-full">
          <a
            href="/de/aktionen/details/ford-fahren-mit-29-leasing-und-kundenvorteil-25"
            target="_blank"
          >
            <div class="flex items-center h-24 px-2 py-4 rounded-lg bg-white">
              <div
                class="flex md:block items-center justify-between w-full md:w-auto"
              >
                <h4
                  class="font-bold leading-3 md:leading-2 md:mb-2 md:text-xl text-sm font-efSans"
                >
                  <T
                    str="Drive Ford with a leasing at 2,9% and client benefits!"
                    key="customerPortal.customer-portal.ford.leasing.and.customer.benefits"
                    tags="customer-portal, 3.1"
                  ></T>
                </h4>
              </div>
              <mat-icon
                svgIcon="arrow_forward_ios"
                class="text-mat-ef-revolution-secondary-500"
              >
              </mat-icon>
            </div>
          </a>
        </div>
      </div>
      <div class="block w-full">
        <div class="w-full">
          <a
            href="/de/aktionen/details/kia-faehrt-mit-boni-und-top-leasing-in-den-sommer-26"
            target="_blank"
          >
            <div class="flex items-center h-24 px-2 py-4 rounded-lg bg-white">
              <div
                class="flex md:block items-center justify-between w-full md:w-auto"
              >
                <h4
                  class="font-bold leading-3 md:leading-2 md:mb-2 md:text-xl text-sm font-efSans"
                >
                  <T
                    str="Drive KIA this summer thanks to bonuses and a top leasing."
                    key="customerPortal.customer-portal.kia.bonuses.and.top"
                    tags="customer-portal, 3.1"
                  ></T>
                </h4>
              </div>
              <mat-icon
                svgIcon="arrow_forward_ios"
                class="text-mat-ef-revolution-secondary-500"
              >
              </mat-icon>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</mat-menu>
