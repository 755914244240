import { Injectable } from '@angular/core'
import { BaseService } from './base.service'
import { type hr, type com } from 'efd-cdb_backend-interfaces-ts'
import { type JsonApiResponse, type JsonApiResponseList } from '@inside-hub-app/ef-ng-json-api'
import { JsonApiRequest } from '../models/jsonApiRequest.model'
import {
  type CertificateCandidatePostParams,
  type CertificateCandidatePutParams,
  type CertificateParameters
} from '../models/importer-certificates.model'
import { HttpParams } from '@angular/common/http'
import { type Observable } from 'rxjs'
import { EfDatatableSortDirection } from '../enum/efDatatable.enum'
import { FormDataTypes } from '../enum/form-data-type.enum'

type CdbCertificateDTO = com.mocira.inside.cdb.client.dto.CdbCertificateDTO
type CertificateDealerCntDto = hr.emilfreydigital.cdb.certificate.api.rest.v1.dto.CertificateDealerCntDto
type CertificateDealerDto = hr.emilfreydigital.cdb.certificate.api.rest.v1.dto.CertificateDealerDto
type CertificateDealerInfoDto = hr.emilfreydigital.cdb.certificate.api.rest.v1.dto.CertificateDealerInfoDto
enum DocumentTypes {
  DEALER_CERTIFICATES = 'partner_profile_dealer_certificates'
}

@Injectable({
  providedIn: 'root'
})

export class CertificatesService extends BaseService {
  getCertificates (parameters: CertificateParameters): Observable<JsonApiResponseList<CertificateDealerCntDto>> {
    const params = this.setParams(parameters)
    return this.http.get<JsonApiResponseList<CertificateDealerCntDto>>(`${this.apiUrl}/cdb/certificates`, { params })
  }

  saveCertificate (certificate: CdbCertificateDTO): Observable<JsonApiResponse<CdbCertificateDTO>> {
    const wrappedData = JsonApiRequest.of(certificate)
    return this.http.post<JsonApiResponse<CdbCertificateDTO>>(`${this.apiUrl}/cdb/certificates`, wrappedData)
  }

  updateCertificate (certificate: CdbCertificateDTO): Observable<JsonApiResponse<CdbCertificateDTO>> {
    const wrappedData = JsonApiRequest.of(certificate, certificate.id)
    return this.http.put<JsonApiResponse<CdbCertificateDTO>>(`${this.apiUrl}/cdb/certificates`, wrappedData)
  }

  deleteCertificate (certificateId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/cdb/certificates/${certificateId}`)
  }

  getCertificateDealers (
    certificateId: number,
    parameters: Omit<CertificateParameters, 'brandIds'>
  ): Observable<JsonApiResponseList<CertificateDealerDto>> {
    const params = this.setParams(parameters)
    return this.http.get<JsonApiResponseList<CertificateDealerDto>>(
      `${this.apiUrl}/cdb/certificates/${certificateId}/dealers`,
      { params }
    )
  }

  getCertificateDealerCandidates (
    certificateId: number,
    parameters: Pick<CertificateParameters, 'search'>
  ): Observable<JsonApiResponseList<CertificateDealerInfoDto>> {
    const params = this.setParams(parameters)
    return this.http.get<JsonApiResponseList<CertificateDealerInfoDto>>(
      `${this.apiUrl}/cdb/certificates/${certificateId}/dealer-candidates`,
      { params }
    )
  }

  saveCertificateDealerCandidate (
    certificateData: CertificateCandidatePostParams,
    dealerId: number,
    file?: File
  ): Observable<JsonApiResponse<CertificateDealerDto>> {
    const formData = new FormData()

    if (file !== null && file !== undefined) {
      formData.append(FormDataTypes.FILE, file)
      formData.append(FormDataTypes.DOCUMENT_TYPE, DocumentTypes.DEALER_CERTIFICATES)
    }

    formData.append(FormDataTypes.DATA, JSON.stringify(certificateData))

    return this.http.post<JsonApiResponse<CertificateDealerDto>>(
      `${this.apiUrl}/cdb/dealers/${dealerId}/certificates`,
      formData
    )
  }

  updateCertificateDealerCandidate (
    certificateData: CertificateCandidatePutParams
  ): Observable<JsonApiResponse<CertificateDealerDto>> {
    const wrappedData = JsonApiRequest.of(certificateData)

    return this.http.put<JsonApiResponse<CertificateDealerDto>>(
      `${this.apiUrl}/cdb/dealers/certificates`,
      wrappedData
    )
  }

  deleteCertificateDealerCandidate (certificateCandidateId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/cdb/dealers/certificates/${certificateCandidateId}`)
  }

  getPartnerBrandCertificates (partnerId: number, parameters: CertificateParameters): Observable<JsonApiResponseList<CdbCertificateDTO>> {
    const params = this.setParams(parameters)
    return this.http.get<JsonApiResponseList<CdbCertificateDTO>>(`${this.apiUrl}/cdb/dealers/${partnerId}/certificates`, { params })
  }

  setParams (parameters: CertificateParameters): HttpParams {
    let params = new HttpParams()

    if (
      parameters?.brandIds !== null &&
      parameters?.brandIds !== undefined &&
      parameters?.brandIds.length > 0
    ) {
      parameters.brandIds.forEach((id) => {
        params = params.append('filter[brandId]', id)
      })
    }

    if (parameters?.brandId !== null && parameters?.brandId !== undefined) {
      params = params.append('brandId', `${parameters.brandId}`)
    }

    if (parameters?.search !== null && parameters?.search !== undefined) {
      params = params.append('search', `${parameters.search}`)
    }

    if (parameters?.pageSize !== null && parameters?.pageSize !== undefined) {
      params = params.append('page[size]', `${parameters.pageSize}`)
    }

    if (parameters?.pageNumber !== null && parameters?.pageNumber !== undefined) {
      params = params.append('page[number]', `${parameters.pageNumber}`)
    }

    if (parameters?.sort !== null && parameters?.sort !== undefined) {
      let sortValue = parameters.sort.column
      if (parameters.sort.direction === EfDatatableSortDirection.DESCENDING) {
        sortValue = `-${parameters.sort.column}`
      }
      params = params.append('sort', sortValue)
    }

    return params
  }
}
