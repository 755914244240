<div class="flex-1 flex flex-col py-5 gap-12">
  <form
    [formGroup]="formGroup"
    class="overflow-y-auto overflow-x-hidden px-2 md:px-6"
  >
    <div class="flex flex-col gap-2">
      <div class="text-mat-ef-revolution-primary-500 text-base font-bold">
        {{
          {
            str: 'Reminder due date',
            key: 'customerPortal.customer-portal.reminders.reminder-due-date'
          } | transifexTranslate
        }}
        *
      </div>
      <mat-form-field class="w-full md:w-1/2">
        <input
          data-cy="reminder-endDate-input"
          matInput
          (click)="picker.open()"
          (dateChange)="checkRemindBeforeDays(true)"
          readonly
          required
          [matDatepicker]="picker"
          [min]="minDate"
          formControlName="endDate"
        />
        <mat-datepicker-toggle
          class="calendar-size"
          matPrefix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker
          [calendarHeaderComponent]="customHeader"
          #picker
        ></mat-datepicker>
        <span
          *ngIf="!picker.disabled"
          class="cursor-pointer"
          (click)="picker.open()"
          matSuffix
        >
          <mat-icon svgIcon="create"></mat-icon>
        </span>
      </mat-form-field>

      <div class="text-mat-ef-revolution-primary-500 text-base font-bold">
        <T
          str="Write down useful information (optional)"
          key="customerPortal.customer-portal.reminders.notes"
          tags="customer-portal, 3.2"
        ></T>
      </div>
      <mat-form-field class="w-full">
        <textarea
          data-cy="reminder-description-input"
          #description
          cdkTextareaAutosize
          matInput
          cdkAutosizeMinRows="4"
          [maxlength]="maxLength"
          formControlName="description"
        ></textarea>
        <mat-hint class="-mt-2"
          >{{ description.value.length }} / {{ maxLength }}</mat-hint
        >
      </mat-form-field>

      <div class="text-mat-ef-revolution-primary-500 text-base mt-3 font-bold">
        <T
          str="When should we remind you?"
          key="customerPortal.customer-portal.reminders.reminder-when"
          tags="customer-portal, 3.2"
        ></T>
        *
      </div>
      <mat-radio-group class="flex flex-col gap-2">
        <mat-radio-button
          data-cy="reminder-remindBeforeDays-radio"
          [checked]="reminder.remindBeforeDays === when.remindBeforeDays"
          (change)="setRemindBeforeDays(when.remindBeforeDays)"
          color="primary"
          value="{{ when.remindBeforeDays }}"
          *ngFor="let when of remindBeforeDaysOptions"
          [disabled]="when.disabled"
        >
          <ng-container
            *ngTemplateOutlet="
              whenTemplate;
              context: { when: when.remindBeforeDays }
            "
          >
          </ng-container>
        </mat-radio-button>
      </mat-radio-group>
      <mat-form-field class="hidden" [hidden]="true">
        <input
          matInput
          [hidden]="true"
          required
          formControlName="remindBeforeDays"
        />
      </mat-form-field>

      <div class="text-mat-ef-revolution-primary-500 text-base mt-5 font-bold">
        <T
          str="How should we remind you?"
          key="customerPortal.customer-portal.reminders.reminder-how"
          tags="customer-portal, 3.2"
        ></T>
        *
      </div>
      <div class="flex flex-col md:flex-row gap-4 md:gap-8">
        <div *ngFor="let channel of channelsOptions">
          <mat-checkbox
            data-cy="reminder-channel-checkbox"
            [checked]="channelIsChecked(channel)"
            (change)="setChannels(channel)"
            color="primary"
            value="channel.id"
          >
            <div class="font-normal">
              {{
                {
                  str: channel.name?.toLowerCase(),
                  prefix: 'customerPortal.customer-portal.reminders.'
                } | transifexTranslate
              }}
            </div>
          </mat-checkbox>
        </div>
      </div>
      <mat-form-field class="hidden" [hidden]="true">
        <input matInput required [hidden]="true" formControlName="channels" />
      </mat-form-field>
    </div>
  </form>
  <div class="flex flex-1 justify-end gap-3 flex-col md:flex-row px-2 md:px-6">
    <button
      class="revolution w-full md:w-auto"
      mat-button
      mat-stroked-button
      (click)="deleteReminder()"
      *ngIf="reminder.id"
      color="warn"
    >
      <mat-icon svgIcon="delete"></mat-icon>
      {{ 'delete' | sharedTranslate }}
    </button>

    <button
      data-cy="reminder-submit"
      class="revolution w-full md:w-auto"
      mat-button
      mat-flat-button
      color="secondary"
      (click)="updateReminder()"
      [disabled]="!formGroup.valid || saveDisabled"
    >
      <span>{{ 'save' | sharedTranslate }}</span>
    </button>
  </div>
</div>

<ng-template #whenTemplate let-when="when">
  <div class="font-normal">
    <ng-container *ngIf="when === 30">
      <T
        str="1 month before (30 days)"
        key="customerPortal.customer-portal.reminders.reminder-when.month-before"
        tags="customer-portal, 3.2"
      ></T>
    </ng-container>
    <ng-container *ngIf="when === 7">
      <T
        str="1 week before (7 days)"
        key="customerPortal.customer-portal.reminders.reminder-when.week-before"
        tags="customer-portal, 3.2"
      ></T>
    </ng-container>
    <ng-container *ngIf="when === 1">
      <T
        str="1 day before"
        key="customerPortal.customer-portal.reminders.reminder-when.day-before"
        tags="customer-portal, 3.2"
      ></T>
    </ng-container>
    <ng-container *ngIf="when === 0">
      <T
        str="On defined day"
        key="customerPortal.customer-portal.reminders.reminder-when.day"
        tags="customer-portal, 3.2"
      ></T>
    </ng-container>
  </div>
</ng-template>
