import { NgModule, inject } from '@angular/core'
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer'
import { ContainerComponent } from './components/container/container.component'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { JumboComponent } from './components/jumbo/jumbo.component'
import { BrowserModule } from '@angular/platform-browser'
import { FooterNewComponent } from './components/footer-new/footer-new.component'
import { PpModule } from '@inside-hub-app/ef-ng-pp-client'
import { RevolutionFooterComponent } from './components/revolution/revolution-footer/revolution-footer.component'
import { RevolutionHeaderComponent } from './components/revolution/revolution-header/revolution-header.component'
import { SocialComponent } from './components/revolution/revolution-footer/social/social.component'
import { LinksComponent } from './components/revolution/revolution-footer/links/links.component'
import { ImpressumComponent } from './components/revolution/revolution-footer/impressum/impressum.component'
import { BrandComponent } from './components/revolution/revolution-footer/brand/brand.component'
import { NavbarAppleComponent } from './components/revolution/navbar-apple/navbar-apple.component'
import { NavbarBackComponent } from './components/revolution/navbar-back/navbar-back.component'
import { DividerComponent } from './components/revolution/divider/divider.component'
import { RevolutionLocalizationComponent } from './components/revolution/revolution-localization/revolution-localization.component'
import { IconModule } from './icon.module'
import { RevolutionHeroComponent } from './components/revolution/revolution-hero/revolution-hero.component'
import { EfSpinnerComponent } from './components/revolution/ef-spinner/ef-spinner.component'
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http'
import { GoogleMapsModule } from '@angular/google-maps'
import { AgmMapComponent } from './components/agm-map/agm-map.component'
import { CptTemplatesComponent } from './components/cpt-templates/cpt-templates.component'
import { TemplateKeyDirective } from './directives/template-key.directive'
import { SharedTranslatePipe } from './pipes/shared-translate.pipe'
import { TransifexTranslatePipe } from './pipes/transifex-translate.pipe'
import { NavigationService } from './services/navigation.service'
import { SafeHtmlPipe } from './pipes/safe-html.pipe'
import { OrderByPipe } from './pipes/order-by.pipe'
import { EfSpinnerDialogComponent } from './components/revolution/ef-spinner-dialog/ef-spinner-dialog.component'
import { MatomoInitializationMode, NgxMatomoTrackerModule } from '@ngx-matomo/tracker'
import { InsuranceMoleculeComponent } from './components/revolution/insurance-molecule/insurance-molecule.component'
import { TxNativeModule } from '@emilfreydigital/transifex-angular'
import { CPTSkeletonLoaderComponent } from './components/revolution/skeleton-loader/skeleton-loader.component'
import { CustomerPortalSharedMaterialModule } from './customer-portal-shared.material.module'
import { SnackbarComponent } from './components/revolution/snackbar/snackbar.component'
import { FilePreviewComponent } from './components/revolution/file-preview/file-preview.component'
@NgModule({
  declarations: [
    SharedTranslatePipe,
    TransifexTranslatePipe,
    ContainerComponent,
    JumboComponent,
    FooterNewComponent,
    RevolutionHeaderComponent,
    RevolutionFooterComponent,
    SocialComponent,
    LinksComponent,
    ImpressumComponent,
    BrandComponent,
    DividerComponent,
    RevolutionLocalizationComponent,
    RevolutionHeroComponent,
    EfSpinnerComponent,
    EfSpinnerDialogComponent,
    AgmMapComponent,
    CptTemplatesComponent,
    TemplateKeyDirective,
    NavbarAppleComponent,
    SafeHtmlPipe,
    OrderByPipe,
    NavbarBackComponent,
    InsuranceMoleculeComponent,
    CPTSkeletonLoaderComponent,
    SnackbarComponent,
    FilePreviewComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    GoogleMapsModule,
    NgxSkeletonLoaderModule,
    CustomerPortalSharedMaterialModule,
    NgxExtendedPdfViewerModule,
    PpModule.forChild({
      translationPrefix: 'kc.'
    }),
    IconModule,
    NgxMatomoTrackerModule.forRoot({
      mode: MatomoInitializationMode.AUTO_DEFERRED
    }),
    TxNativeModule.forRoot()
  ],
  exports: [
    SharedTranslatePipe,
    TransifexTranslatePipe,
    FooterNewComponent,
    ContainerComponent,
    JumboComponent,
    RevolutionHeaderComponent,
    RevolutionFooterComponent,
    DividerComponent,
    RevolutionHeroComponent,
    RevolutionLocalizationComponent,
    IconModule,
    EfSpinnerComponent,
    EfSpinnerDialogComponent,
    AgmMapComponent,
    CptTemplatesComponent,
    TemplateKeyDirective,
    NavbarAppleComponent,
    SafeHtmlPipe,
    OrderByPipe,
    NavbarBackComponent,
    InsuranceMoleculeComponent,
    CPTSkeletonLoaderComponent,
    SnackbarComponent,
    CustomerPortalSharedMaterialModule
  ],
  providers: [
    NavigationService,
    {
      provide: NgxMatomoTrackerModule,
      useValue: () => {
        return inject(NgxMatomoTrackerModule)
      }
    }
  ]
})
export class CustomerPortalSharedModule {}
