import { Component, Input, OnInit } from '@angular/core'
import {
  CptGoogleTagmanagerService,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { SharedService } from '../../../../services/shared.service'
import {
  DayTimeDTO,
  DealerDTO,
  WorkingDays
} from '../../../../services/dealers.service'
import {
  WorkingHoursResponse,
  WorkingHoursService
} from '../../../../services/working-hours.service'

@Component({
  selector: 'customer-portal-app-dealer-hours',
  templateUrl: './dealer-hours.component.html'
})
export class DealerHoursComponent implements OnInit {
  @Input() dealer: DealerDTO
  @Input() expanded = false

  service: DayTimeDTO[]
  sales: DayTimeDTO[]

  workingDaysSales: WorkingDays[] = []
  workingDaysService: WorkingDays[] = []

  workingDaysSalesGrouped = {}
  workingDaysServiceGrouped = {}

  openSales = false
  currentDate: Date
  openServices = false
  serviceWorkingHours: WorkingHoursResponse

  constructor(
    private readonly workingHoursService: WorkingHoursService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public readonly sharedService: SharedService,
    public transifexService: TransifexService
  ) {}

  ngOnInit(): void {
    this.currentDate = new Date()
    this.service =
      this.dealer.openingHours?.service != null
        ? this.dealer.openingHours.service
        : []
    this.sales =
      this.dealer.openingHours?.sales != null
        ? this.dealer.openingHours.sales
        : []
    if (this.sales.length > 0) {
      this.workingDaysSalesGrouped = this.workingHoursService.showHoursSales(
        this.sales,
        this.workingDaysSales
      )
      this.openSales = this.workingHoursService.calculateIfWorking_v2(
        this.currentDate,
        this.workingDaysSales
      )
    }

    if (this.service.length > 0) {
      this.workingDaysServiceGrouped =
        this.workingHoursService.showHoursService(
          this.service,
          this.workingDaysService
        )
      this.openServices = this.workingHoursService.calculateIfWorking_v2(
        this.currentDate,
        this.workingDaysService
      )
    }

    this.serviceWorkingHours = this.workingHoursService.calculateIfWorking(
      this.service,
      this.currentDate,
      'service'
    )
  }

  originalOrder(): number {
    return 0
  }

  sendContentModulesData(expanded: boolean, contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Accordion',
      expanded ? 'Open' : 'Close',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Accordion|${this.sharedService.translateLink(contentModuleLabel) ?? ''}`,
      expanded ? 'Open' : 'Close'
    )
  }
}
