<div
  class="cpt-modal ChangeSalesmanPopupComponent-revolution-dialog relative dialog-content-p-0 mat-dialog-actions-pb-20 ef-scrollbar"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="Change contact person"
      key="customerPortal.customer-portal.change-salesman"
      tags="customer-portal, 3.1"
    ></T>
  </h2>
  <span
    class="font-efSans text-mat-ef-revolution-secondary-500 font-bold text-2xl"
    *ngIf="employees && employees.length > 0"
  >
    <ng-container *ngIf="employeeType === 'SALESMAN'">
      <T
        str="Our sales representatives"
        key="customerPortal.customer-portal.change-salesman.sales-representatives"
        tags="customer-portal, 3.1"
      ></T>
    </ng-container>
    <ng-container *ngIf="employeeType === 'SALES_ADVISOR'">
      <T
        str="Our service representative"
        key="customerPortal.customer-portal.change-salesman.service-representatives"
        tags="customer-portal, 3.1"
      ></T>
    </ng-container>
  </span>

  <div *ngIf="!loading && (!employees || employees.length === 0)">
    <p>
      <T
        str="No employees"
        key="customerPortal.customer-portal.change-salesman.no-employees"
        tags="customer-portal, 3.1"
      ></T>
    </p>
  </div>

  <ngx-skeleton-loader
    *ngIf="loading"
    appearance="line"
    animation="progress"
  ></ngx-skeleton-loader>

  <mat-dialog-content class="border-none" *ngIf="employees.length > 0">
    <ngx-skeleton-loader
      *ngIf="loading"
      appearance="line"
      animation="progress"
    ></ngx-skeleton-loader>

    <form *ngIf="!loading && employees.length > 0" [formGroup]="salesmanForm">
      <!-- Sales -->
      <div class="salesman-select-form">
        <mat-radio-group
          formControlName="salesmanFormControl"
          [(ngModel)]="selectedEmployeeId"
        >
          <div id="employeeWrapper" *ngFor="let e of employees">
            <mat-radio-button
              data-cy="change-salesman-popup-radio-button"
              [checked]="selectedEmployeeId === e.id"
              class="salesman"
              labelPosition="before"
              (click)="onEmployeeSelected(e)"
              [value]="e.id"
              [ngClass]="{ selected: selectedEmployeeId === e.id }"
            >
              <div class="w-full h-full flex justify-center items-center">
                <div class="h-18 w-18 flex justify-center items-center">
                  <img
                    *ngIf="!efIcon.svgIcon"
                    class="object-cover rounded-full"
                    [src]="profileImageUrlPrefix + e?.profilePicture"
                    (error)="efIcon.svgIcon = 'ef-logo-icon'"
                    alt=""
                  />
                  <mat-icon
                    #efIcon
                    class="h-18 w-18 ef-icon text-mat-ef-primary-500"
                  ></mat-icon>
                </div>
              </div>

              <div class="text-mat-ef-revolution-secondary-500 py-5">
                <h3
                  class="font-canelablack text-lg md:text-2xl whitespace-pre-line min-w-[150px] leading-5"
                >
                  {{ e.name + ' ' }}
                  {{ e.familyName }}
                </h3>
                <span class="text-xs md:text-base">
                  <ng-container
                    *ngTemplateOutlet="
                      sharedService.template('transifexTranslation');
                      context: {
                        key: 'customerPortal.customer-portal.' + e.functionRole
                      }
                    "
                  ></ng-container>
                </span>
              </div>
            </mat-radio-button>
          </div>
        </mat-radio-group>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>

    <button
      data-cy="change-salesman-popup-submit"
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      (click)="saveChanges()"
      cdkFocusInitial
      [disabled]="saving || employees.length < 1"
    >
      <mat-spinner *ngIf="saving" diameter="20"></mat-spinner>
      {{ 'save' | sharedTranslate }}
    </button>
  </mat-dialog-actions>
</div>
