import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DomSanitizer } from '@angular/platform-browser'
import { HttpClient } from '@angular/common/http'
import { type ContactDocumentFormatEnum } from '../../../services/shared.service'

export interface FilePreviewData {
  preview: string
  format: ContactDocumentFormatEnum
}

@Component({
  selector: 'customer-portal-app-file-preview',
  templateUrl: './file-preview.component.html'
})
export class FilePreviewComponent {
  image
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: FilePreviewData,
    private readonly sanitizer: DomSanitizer,
    private readonly http: HttpClient
  ) {
    if (data.preview.startsWith('blob') !== true) {
      this.http
        .get(data.preview, {
          responseType: 'blob'
        })
        .subscribe(response => {
          data.format = response?.type.split(
            '/'
          )[1] as ContactDocumentFormatEnum
          data.preview = URL.createObjectURL(response)
          this.image = this.sanitizer.bypassSecurityTrustUrl(data.preview)
        })
    } else {
      this.image = this.sanitizer.bypassSecurityTrustUrl(data.preview)
    }
  }
}
