import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  CptGoogleTagmanagerService,
  ReplaceDateWithStringType
} from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { IconService } from '@inside-hub-app/ef-ng-pp-client'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { TranslateService } from '@ngx-translate/core'
import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import * as moment from 'moment'
import { NGXLogger } from 'ngx-logger'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { DealerUserDTO } from './dealers.service'
import { HashingService } from './hashing.service'
import { SharedService } from './shared.service'
import { TokenService } from './token.service'
import { VehicleDTOExtended } from './vehicles.service'

type _ServiceAppointmentDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.appointment.dto.ServiceAppointmentDTO
export type ServiceAppointmentDTO =
  ReplaceDateWithStringType<_ServiceAppointmentDTO>
type _UpcomingAppointmentDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.appointment.dto.UpcomingAppointmentDTO
export type UpcomingAppointmentDTO =
  ReplaceDateWithStringType<_UpcomingAppointmentDTO>

export type TimeOfDayEnum =
  cpt.emilfreydigital.customer_portal_backend.rest.appointment.dto.ServiceAppointmentDTO.TimeOfDayEnum
export type ServiceEnum =
  cpt.emilfreydigital.customer_portal_backend.rest.appointment.dto.ServiceAppointmentDTO.ServiceEnum

export interface UpcomingAppointmentDTOExtended extends UpcomingAppointmentDTO {
  brandPicture?: string
  loadingDetails?: boolean
  salesAdvisor?: DealerUserDTO
}

export interface Canton {
  id: string
}

export interface IAppointmentsService {
  postAppointment: (
    appointment: ServiceAppointmentDTO,
    lang: string
  ) => Observable<ServiceAppointmentDTO>
}

@Injectable({
  providedIn: 'root'
})
export class AppointmentsTestingService implements IAppointmentsService {
  postAppointment(): Observable<ServiceAppointmentDTO> {
    return undefined
  }
}

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService implements IAppointmentsService {
  private readonly apiUrl
  public cantons: Canton[] = [
    { id: 'AG' },
    { id: 'AI' },
    { id: 'AR' },
    { id: 'BE' },
    { id: 'BL' },
    { id: 'BS' },
    { id: 'FR' },
    { id: 'GE' },
    { id: 'GL' },
    { id: 'GR' },
    { id: 'JU' },
    { id: 'LU' },
    { id: 'NE' },
    { id: 'NW' },
    { id: 'OW' },
    { id: 'SG' },
    { id: 'SH' },
    { id: 'SO' },
    { id: 'SZ' },
    { id: 'TG' },
    { id: 'TI' },
    { id: 'UR' },
    { id: 'VD' },
    { id: 'VS' },
    { id: 'ZG' },
    { id: 'ZH' }
  ]

  constructor(
    private readonly http: HttpClient,
    private readonly tokenService: TokenService,
    private readonly hashingService: HashingService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly logger: NGXLogger,
    public dialog: MatDialog,
    private readonly translate: TranslateService,
    private readonly sharedService: SharedService,
    private readonly imagesService: IconService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  postAppointment(
    appointment: ServiceAppointmentDTO,
    lang: string
  ): Observable<ServiceAppointmentDTO> {
    return this.http.post<ServiceAppointmentDTO>(
      this.apiUrl + '/appointment/service?lang=' + lang,
      appointment
    )
  }

  getCantons(): Observable<Canton[]> {
    return of(this.cantons)
  }

  getUpcomingAppointments(): Observable<UpcomingAppointmentDTO[]> {
    const token = this.tokenService.getToken()
    let params = new HttpParams()
    params = params.append('c', this.hashingService.hashCode(token.cp_id))
    params = params.append(
      'lang',
      this.sharedService.stringExists(this.translate.currentLang)
        ? this.translate.currentLang
        : 'de'
    )
    return this.http
      .get<UpcomingAppointmentDTO[]>(this.apiUrl + '/appointment/upcoming', {
        params
      })
      .pipe(
        map((upcomingAppointments: UpcomingAppointmentDTO[]) => {
          return this.addBrandPicture(upcomingAppointments)
        })
      )
  }

  getUpcomingActivities(): Observable<UpcomingAppointmentDTO[]> {
    const token = this.tokenService.getToken()
    let params = new HttpParams()
    params = params.append('c', this.hashingService.hashCode(token.cp_id))
    params = params.append(
      'lang',
      this.sharedService.stringExists(this.translate.currentLang)
        ? this.translate.currentLang
        : 'de'
    )
    return this.http
      .get<UpcomingAppointmentDTO[]>(this.apiUrl + '/appointment/activities', {
        params
      })
      .pipe(
        map((upcomingAppointments: UpcomingAppointmentDTO[]) => {
          return this.addBrandPicture(upcomingAppointments)
        })
      )
  }

  addBrandPicture(
    upcomingAppointments: UpcomingAppointmentDTOExtended[]
  ): UpcomingAppointmentDTOExtended[] {
    if (upcomingAppointments?.[0] != null) {
      upcomingAppointments.forEach(appointment => {
        if (!this.sharedService.stringExists(appointment.brandPicture)) {
          appointment.brandPicture = this.imagesService.logoUrl(
            appointment.brand
          )
        }
      })
    }
    return upcomingAppointments
  }

  getUpcomingVehicleAppointment(
    vin: string,
    id: number
  ): Observable<UpcomingAppointmentDTO> {
    const token = this.tokenService.getToken()
    let params = new HttpParams()
    params = params.append('c', this.hashingService.hashCode(token.cp_id))
    params = params.append('id', id.toString())
    return this.http.get<UpcomingAppointmentDTO>(
      this.apiUrl + '/appointment/upcoming/' + vin,
      {
        params
      }
    )
  }

  getYear(startDate: string): string {
    const date = moment(startDate)
    date.locale(
      (this.sharedService.stringExists(this.translate.currentLang)
        ? this.translate.currentLang
        : 'de') +
        '-' +
        this.remoteConfigService.get('country.code')
    )
    return date.format('YYYY.')
  }

  getDayMonth(startDate: string): string {
    const date = moment(startDate)
    // 'de-CH'
    // 'it-CH'
    // 'fr-CH'
    // 'de-DE'
    date.locale(
      (this.sharedService.stringExists(this.translate.currentLang)
        ? this.translate.currentLang
        : 'de') +
        '-' +
        this.remoteConfigService.get('country.code')
    )
    return date.format('D.MM.')
  }

  getHour(startDate: string): string {
    const date = moment(startDate)
    date.locale(
      (this.sharedService.stringExists(this.translate.currentLang)
        ? this.translate.currentLang
        : 'de') +
        '-' +
        this.remoteConfigService.get('country.code')
    )
    return date.format('LT')
  }

  setImg(
    vehicles: VehicleDTOExtended[],
    upcomingAppointments: UpcomingAppointmentDTOExtended[]
  ): void {
    if (vehicles?.[0] != null && upcomingAppointments?.[0] != null) {
      upcomingAppointments.forEach(appointment => {
        const vehicle = vehicles.find(vehicle => {
          return vehicle.vin === appointment.vin
        })
        if (vehicle != null) {
          appointment.brandPicture = vehicle.brandPicture
        }
      })
    }
  }

  newAppointment(component, data?): void {
    this.dialog.open(component, {
      data,
      panelClass: ['mat-dialog-cpt', 'scrollable', 'new-appointment']
    })
  }

  async upcomingAppointments(): Promise<UpcomingAppointmentDTO[]> {
    return await new Promise(resolve => {
      this.getUpcomingAppointments().subscribe(
        value => {
          resolve(value)
        },
        error => {
          this.logger.debug(error)
          resolve(null)
        }
      )
    })
  }

  async upcomingActivities(): Promise<UpcomingAppointmentDTO[]> {
    return await new Promise(resolve => {
      this.getUpcomingActivities().subscribe(
        value => {
          resolve(value)
        },
        error => {
          this.logger.debug(error)
          resolve(null)
        }
      )
    })
  }
}
