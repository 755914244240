import { HTTP_INTERCEPTORS } from '@angular/common/http'
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core'
import {
  HubConfigurationModule,
  HubConfigurationService
} from '@inside-hub-app/hub-configuration'
import { HubStorageModule } from '@inside-hub-app/hub-storage'
import { NGXLogger } from 'ngx-logger'
import { PpTranslateDirective } from './directives/pp-translate.directive'
import { Environment } from './environments'
import {
  ENVIRONMENT,
  STORAGE_PREFIX,
  TRANSLATION_PREFIX
} from './injection-tokens'
import { PpAuthInterceptor } from './interceptors/pp-auth.interceptor'
import { PpTranslatePipe } from './pipes/pp-translate.pipe'

export class PpModuleConfig {
  constructor (
    public storagePrefix: string,
    public translationPrefix: string,
    public environment: Environment
  ) {}
}

export class PpModuleChildConfig {
  constructor (public translationPrefix?: string) {}
}

@NgModule({
  declarations: [PpTranslatePipe, PpTranslateDirective],
  imports: [HubStorageModule, HubConfigurationModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PpAuthInterceptor,
      multi: true
    },
    PpTranslatePipe
  ],
  exports: [PpTranslatePipe, PpTranslateDirective]
})
export class PpModule {
  constructor (
    private readonly logger: NGXLogger,
    @Optional() @SkipSelf() parentModule?: PpModule,
    @Optional() private readonly hubConfigurationService?: HubConfigurationService
  ) {
    this.hubConfigurationService?.setLoggerLevel(this.logger)
  }

  static forRoot (config: PpModuleConfig): ModuleWithProviders<PpModule> {
    return {
      ngModule: PpModule,
      providers: [
        { provide: ENVIRONMENT, useValue: config.environment },
        { provide: STORAGE_PREFIX, useValue: config.storagePrefix || '' },
        {
          provide: TRANSLATION_PREFIX,
          useValue: config.translationPrefix || ''
        }
      ]
    }
  }

  static forChild (config?: PpModuleChildConfig): ModuleWithProviders<PpModule> {
    return {
      ngModule: PpModule,
      providers: [
        ...(config.translationPrefix
          ? [
              {
                provide: TRANSLATION_PREFIX,
                useValue: config.translationPrefix
              }
            ]
          : [])
      ]
    }
  }
}
