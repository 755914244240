import { Component, Inject, OnInit } from '@angular/core'
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { DealerDTO, DealerUserDTO } from '../../../../services/dealers.service'
import { InstantErrorStateMatcherService } from '../../../../services/instant-error-state-matcher.service'
import {
  ServicePackageDTO,
  ServicePackageContactRequestDTO,
  ServicePackageService
} from '../../../../services/service-package.service'
import {
  WarrantyContactRequestDTO,
  WarrantyContactReasonEnum,
  WarrantyDetailsDTO,
  WarrantyService
} from '../../../../services/warranty.service'
import { ConfirmationPopupComponent } from '../../../basic/confirmation-popup/confirmation-popup.component'
import { CustomCalendarHeaderComponent } from '../../../basic/custom-calendar-header/custom-calendar-header.component'
import {
  CptGoogleTagmanagerService,
  MatomoIntegrationService,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'
import { SharedService } from '../../../../services/shared.service'
import { addDays, differenceInDays, format, parse, parseISO } from 'date-fns'

export type WarrantyContactPopupComponentDialogTitle =
  | 'servicePackage'
  | 'tires'
  | 'warranty'

export interface WarrantyContactPopupComponentDialogData {
  vehicle: VehicleDTOExtended
  warrantyServicePackage: WarrantyDetailsDTO | ServicePackageDTO
  requestType: 'offerRequest' | 'contact'
  title: WarrantyContactPopupComponentDialogTitle
  reason?: WarrantyContactReasonEnum[]
}

@Component({
  selector: 'customer-portal-app-revolution-warranty-contact-popup',
  templateUrl: './warranty-contact-popup.component.html'
})
export class RevolutionWarrantyContactPopupComponent implements OnInit {
  subject = new FormGroup({
    standard: new FormControl<boolean | null>(false),
    extended: new FormControl<boolean | null>(false),
    tire: new FormControl<boolean | null>(false)
  })

  warrantyContactForm = new FormGroup({
    subject: this.subject,
    mileage: new FormControl<number | null>(null, [
      Validators.required,
      Validators.max(9999999)
    ]),
    message: new FormControl<string | null>(null, [
      Validators.required,
      Validators.maxLength(512)
    ]),
    startDate: new FormControl<string | null>(null),
    endDate: new FormControl<string | null>(null)
  })

  saving = false
  user: User
  matcher = new InstantErrorStateMatcherService()
  contactSubjects = ['STANDARD', 'EXTENDED', 'TIRE']
  selectedSubjects: string[] = []
  prefferedDealer: DealerDTO
  prefferedEmployee: DealerUserDTO
  duration: number
  startDate: Date
  minDate = new Date()

  customHeader = CustomCalendarHeaderComponent

  constructor (
    @Inject(MAT_DIALOG_DATA)
    public data: WarrantyContactPopupComponentDialogData,
    private readonly dataService: DataService,
    private readonly dialogRef: MatDialogRef<RevolutionWarrantyContactPopupComponent>,
    private readonly warrantyService: WarrantyService,
    private readonly servicePackageService: ServicePackageService,
    private readonly dialog: MatDialog,
    private readonly logger: NGXLogger,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public readonly sharedService: SharedService,
    public transifexService: TransifexService,
    private readonly matomoIntegrationService: MatomoIntegrationService
  ) {}

  onSubmit (): void {
    const obj: Record<string, unknown> = {
      currentMileage: this.warrantyContactForm.get('mileage').value,
      message: this.warrantyContactForm.get('message').value
    }

    const startDate = this.warrantyContactForm.get('startDate').value
    const endDate = this.warrantyContactForm.get('endDate').value
    if (this.sharedService.dateExists(startDate)) {
      obj.preferredStartDate = format(new Date(startDate), 'yyyy-MM-dd')
    }
    if (this.sharedService.dateExists(endDate)) {
      obj.preferredEndDate = format(parse(endDate, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd')
    }

    let dataSP
    let dataW
    if (this.data.title === 'servicePackage') {
      dataSP = Object.assign({}, obj)
      if (this.data.warrantyServicePackage != null) {
        dataSP.servicePackage = this.data.warrantyServicePackage
      }
    } else {
      dataW = Object.assign({}, obj)
      dataW.reasons = []
      if (this.selectedSubjects?.[0] != null) {
        dataW.reasons = this.selectedSubjects
      } else if (this.data.reason?.[0] != null) {
        dataW.reasons = this.data.reason
      }
      if (this.data.warrantyServicePackage != null) {
        dataW.warranty = this.data.warrantyServicePackage
      }
    }

    this.sendMessage(dataW, dataSP)
  }

  subjectValidator () {
    return (): ValidationErrors | null => {
      this.selectedSubjects = []
      this.contactSubjects.forEach(s => {
        if (this.subject.get(s.toLowerCase()).value === true) {
          this.selectedSubjects.push(s)
        }
      })

      const zeroSubjects = this.selectedSubjects.length < 1
      return zeroSubjects ? { noSubjectSelected: {} } : null
    }
  }

  sendMessage (
    contactDataW: WarrantyContactRequestDTO,
    contactDataSP: ServicePackageContactRequestDTO
  ): void {
    this.saving = true
    if (this.data.title === 'servicePackage') {
      let buy = false
      if (this.data.requestType === 'offerRequest') {
        buy = true
      }
      this.servicePackageService
        .requestServicePackageContact(this.data.vehicle.vin, contactDataSP, buy)
        .subscribe(
          () => {
            this.cptGtmService.sendContactUsData(2, this.getGTMTitle())
            this.openConfirmDialog(
              'shared.thank-you',
              'customerPortal.customer-portal.vehicle.servicepackage.contact.success.text'
            )
          },
          error => {
            this.cptGtmService.sendContactUsData(2, this.getGTMTitle())
            this.openConfirmDialog(
              'customerPortal.customer-portal.vehicle.warranty.contact.failure.title',
              'customerPortal.customer-portal.vehicle.warranty.contact.failure.text'
            )
            this.logger.debug(error)
          }
        )
    } else {
      this.warrantyService
        .requestWarrantyContact(this.data.vehicle.vin, contactDataW)
        .subscribe(
          () => {
            this.cptGtmService.sendContactUsData(2, this.getGTMTitle())
            this.openConfirmDialog(
              'shared.thank-you',
              'customerPortal.customer-portal.vehicle.warranty.contact.success.text'
            )
          },
          error => {
            this.cptGtmService.sendContactUsData(2, this.getGTMTitle())
            this.openConfirmDialog(
              'customerPortal.customer-portal.vehicle.warranty.contact.failure.title',
              'customerPortal.customer-portal.vehicle.warranty.contact.failure.text'
            )
            this.logger.debug(error)
          }
        )
    }
  }

  getGTMTitle (): string {
    return this.data.title === 'tires'
      ? 'Reifen'
      : this.data.title === 'servicePackage'
        ? 'Service Packet'
        : 'Garantie'
  }

  openConfirmDialog (title, text): void {
    this.saving = false
    this.dialogRef.close()
    this.dialog.open(ConfirmationPopupComponent, {
      data: {
        title,
        text,
        useInnerHtml: true
      },
      panelClass: 'mat-dialog-cpt'
    })
  }

  setPrefferedDealer (v: VehicleDTOExtended): void {
    this.prefferedDealer =
      v.dealers.serviceDealer != null
        ? v.dealers.serviceDealer
        : v.dealers.salesDealer != null
          ? v.dealers.salesDealer
          : null
  }

  setPrefferedEmployee (v: VehicleDTOExtended): void {
    this.prefferedEmployee =
      v.dealers.serviceDealer?.salesAdvisor != null
        ? v.dealers.serviceDealer.salesAdvisor
        : v.dealers.salesDealer?.salesAdvisor != null
          ? v.dealers.salesDealer.salesAdvisor
          : v.dealers.salesDealer?.salesman != null
            ? v.dealers.salesDealer.salesman
            : null
  }

  ngOnInit (): void {
    this.cptGtmService.sendContactUsData(1, this.getGTMTitle())
    this.matomoIntegrationService.trackEvent(
      'CTA',
      'Contact form checkout (' + this.data.title + ')',
      this.matomoIntegrationService.formatVehicleData(this.data.vehicle)
    )

    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
    })
    if (this.data.requestType === 'contact') {
      this.subject.setValidators(this.subjectValidator())
    }
    if (this.data.title === 'tires' || this.data.title === 'servicePackage') {
      this.startDate = parseISO(this.data.warrantyServicePackage.startDate)
      this.warrantyContactForm.controls.startDate.setValue(
        this.data.warrantyServicePackage.startDate
      )
      this.warrantyContactForm.controls.endDate.setValue(
        this.data.warrantyServicePackage.endDate
      )

      // Warranty duration
      this.duration = differenceInDays(
        parseISO(this.data.warrantyServicePackage.endDate),
        parseISO(this.data.warrantyServicePackage.startDate)
      )

      this.warrantyContactForm.controls.startDate.valueChanges.subscribe(
        startDate => {
          const endDate = format( 
            addDays(parseISO(startDate), this.duration),
            'dd.MM.yyyy'
          )
          this.warrantyContactForm.controls.endDate.setValue(endDate)
        }
      )
    }

    if (this.data.title === 'tires') {
      this.warrantyContactForm.controls.endDate.disable()
    }

    if (this.data.title === 'servicePackage') {
      this.warrantyContactForm.controls.startDate.disable()
      this.warrantyContactForm.controls.endDate.disable()
    }

    this.setPrefferedDealer(this.data.vehicle)
    this.setPrefferedEmployee(this.data.vehicle)
  }
}
