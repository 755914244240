import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  User,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { InstantErrorStateMatcherService } from '../../../../services/instant-error-state-matcher.service'
import { SharedService } from '../../../../services/shared.service'

@Component({
  selector: 'customer-portal-app-revolution-name-change-popup',
  templateUrl: './name-change-popup.component.html'
})
export class RevolutionNameChangePopupComponent implements OnInit {
  matcher = new InstantErrorStateMatcherService()

  salutationFirstNameRegex: string
  salutationLastNameRegex: string
  nameForm

  public loading: boolean
  hidden: boolean
  disabled = false
  public errorUpdate: boolean
  public salutations: string[]
  public titles: string[]
  public salutationsWithTitles: string[]

  constructor(
    private readonly dialogRef: MatDialogRef<RevolutionNameChangePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public user: User,
    private readonly userService: UserService,
    private readonly logger: NGXLogger,
    public readonly sharedService: SharedService,
    public transifexService: TransifexService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.salutationFirstNameRegex = this.remoteConfigService.get(
      'regEx.nameSalutationRegEx.firstName.pattern'
    )
    this.salutationLastNameRegex = this.remoteConfigService.get(
      'regEx.nameSalutationRegEx.lastName.pattern'
    )
    this.nameForm = new FormGroup({
      firstName: new FormControl<string | null>(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.salutationFirstNameRegex),
          Validators.maxLength(
            this.remoteConfigService.get(
              'regEx.nameSalutationRegEx.firstName.maxLength'
            )
          )
        ])
      ),
      lastName: new FormControl<string | null>(
        null,
        Validators.compose([
          Validators.pattern(this.salutationLastNameRegex),
          Validators.maxLength(
            this.remoteConfigService.get(
              'regEx.nameSalutationRegEx.lastName.maxLength'
            )
          )
        ])
      ),
      salutation: new FormControl<string | null>(null, Validators.required),
      title: new FormControl<string | null>(null)
    })
  }

  ngOnInit(): void {
    this.load()
    this.hidden = true
  }

  load(): void {
    this.setNameFields()

    this.userService.enums().subscribe(res => {
      this.salutations = res.salutations
      this.titles = res.titles
      this.salutationsWithTitles = res.salutationsWithTitles
    })
  }

  setNameFields(): void {
    if (this.user != null) {
      this.nameForm.controls.salutation.setValue(this.user.salute)
      this.nameForm.controls.firstName.setValue(this.user.firstName)
      this.nameForm.controls.lastName.setValue(this.user.lastName)
      this.nameForm.controls.title.setValue(this.user.title)
    }
  }

  updateName(): void {
    this.disabled = true
    this.loading = true
    const user: User = {
      contactId: this.user.contactId,
      lastName: this.nameForm.controls.lastName.value,
      salute: this.nameForm.controls.salutation.value,
      firstName: this.nameForm.controls.firstName.value,
      title: this.nameForm.controls.title.value
    }

    this.userService.putUser(user).subscribe(
      data => {
        this.logger.debug('Request is successful ', data)
        this.hidden = false
        this.loading = false
      },
      error => {
        this.loading = false
        if (error.status === 500) {
          this.errorUpdate = true
        }
      }
    )
  }
}
