import { Component, OnDestroy, OnInit } from '@angular/core'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGoogleTagmanagerService,
  GTMEventData
} from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { ParsedToken } from '../../../../models/keycloak'
import { UpcomingAppointmentDTO } from '../../../services/appointments.service'
import { DataService } from '../../../services/data.service'
import { SharedService } from '../../../services/shared.service'
@Component({
  selector: 'customer-portal-app-revolution-appointments-view',
  templateUrl: './revolution-appointments-view.component.html'
})
export class RevolutionAppointmentsViewComponent implements OnInit, OnDestroy {
  upcomingAppointments: UpcomingAppointmentDTO[] = []

  sub = {
    onUpcomingAppointmentsLoaded: null,
    upcomingAppointmentsLoading: null
  }

  constructor(
    private readonly keycloak: KeycloakService,
    private readonly dataService: DataService,
    private readonly logger: NGXLogger,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasNewFooter = this.remoteConfigService.get('hasNewFooter')
  }

  token: ParsedToken
  user: User
  loading = true
  error = null
  public hasNewFooter

  ngOnInit(): void {
    this.sub.onUpcomingAppointmentsLoaded =
      this.dataService.onUpcomingAppointmentsLoaded.subscribe(
        upcomingAppointments => {
          this.upcomingAppointments = upcomingAppointments

          const gtmData: GTMEventData = {
            event: 'gaEvent2',
            event_name: 'my_appointments_listing',
            my_appointments_listing: {
              appointments: this.upcomingAppointments.length.toString(),
              event_action: 'Listing',
              event_category: 'My Appointments',
              event_label: this.upcomingAppointments.length.toString()
            }
          }
          this.cptGtmService.send(gtmData)
        },
        error => {
          this.logger.debug(error)
        }
      )

    this.sub.upcomingAppointmentsLoading =
      this.dataService.loading.upcomingAppointments.subscribe(loading => {
        this.loading = loading
      })

    this.token = this.keycloak.getKeycloakInstance().tokenParsed as ParsedToken
  }

  ngOnDestroy(): void {
    this.sharedService.unsubscribe(this.sub)
  }
}
