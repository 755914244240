import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { NGXLogger } from 'ngx-logger'
import { Observable } from 'rxjs'
import { DataService } from './data.service'
import { DealerDTO } from './dealers.service'
import { LocalStorageService } from './local-storage.service'
import { PersonalizeDTO } from './personalization.service'
import { VehicleDTOExtended, VehicleDealersDTO } from './vehicles.service'
import { SharedService } from './shared.service'
export interface SearchParams {
  pageNumber: number
  language: string
  pageSize: number
  channels: number
  brands: string[]
  dealers: string[]
}

export interface ArticleResponse {
  data: ArticleData[]
  links: {
    first: string
    last: string
    prev: string
    next: string
  }
  meta: {
    current_page: number
    from: number
    last_page: number
    path: string
    per_page: number
    to: number
    total: number
  }
}

export interface ArticleChannel {
  id: number
  name: string
}

export interface ArticleCategory {
  id: number
  name: string
}

export interface CallToActions {
  link: string
  text: string
  target: string
}

export interface ArticleData {
  id: number
  approval_status: string
  is_corporate: null
  header_image: string[]
  title: string
  subtitle: string
  publish_date: string
  start_date: string
  end_date: string
  slug: string
  content: string
  images: string[]
  videos: string[]
  call_to_actions: CallToActions[]
  dealerships: string[]
  carmarket_filter_url: string
  channels: ArticleChannel[]
  categories: ArticleCategory[]
  brands: Array<Record<string, string>>
  body_types: []
  show_content_from: string
}

export interface ArticleDataExtended extends ArticleData {
  hasCommonElementBrand?: boolean
  hasCommonElementDealership?: boolean
  showCorporate?: boolean
}

export interface ArticleLoadData {
  personalization: PersonalizeDTO
  userDealers: VehicleDealersDTO[]
}

@Injectable({
  providedIn: 'root'
})
export class DashEcoService {
  dashEco
  brands: string[] = [] // brands that are selected in user personalization
  dealers: string[] = []
  userDealers: string[] = []
  userBrands: string[] = [] // user vehicles + user personalization
  searchParams: SearchParams
  loadingArticles = true
  articlesLoaded = false

  vehicles = []

  showDashEcoNews

  getArticlesSub
  articles: ArticleResponse
  constructor(
    private readonly http: HttpClient,
    private readonly dataService: DataService,
    private readonly logger: NGXLogger,
    private readonly localStorage: LocalStorageService,
    private readonly sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.dashEco = this.sharedService.getDashEco()
    this.showDashEcoNews = this.remoteConfigService.get('showDashEcoNews')
  }

  getArticles(
    pageNumber: number,
    locale,
    pageSize: number,
    categories: string,
    channels: number,
    brands: string[],
    dealers: string[]
  ): Observable<ArticleResponse> {
    let brandString = ''
    let dealerString = ''

    const apiUrl = locale.baseUrl

    if (brands != null && brands.length > 0) {
      brandString = brands.join(',')
    }
    if (dealers != null && dealers.length > 0) {
      dealerString = dealers.join(',')
    }
    const url =
      String(apiUrl) +
      '/news' +
      '?page[number]=' +
      (pageNumber != null ? String(pageNumber) : '') +
      '&page[size]=' +
      (pageSize != null ? String(pageSize) : '') +
      '&filter[categories]=' +
      (categories != null ? categories : '') +
      '&filter[channels]=' +
      (channels != null ? String(channels) : '') +
      (dealers != null ? '&filter[dealerships]=' + dealerString : '') +
      (brands != null ? '&filter[brands]=' + brandString : '')
    return this.http.get<ArticleResponse>(encodeURI(url))
  }

  setArticles(
    vehicles: VehicleDTOExtended[],
    personalization: PersonalizeDTO,
    userDealers: VehicleDealersDTO[]
  ): void {
    if (!this.showDashEcoNews) {
      return
    }
    // this.loadingArticles = true
    const country: string = this.remoteConfigService.get('country.code')
    let channels
    const currentLang = this.sharedService.currentLanguage()
    if (country === 'de') {
      channels = 1
    } else if (country === 'ch') {
      channels = 2
    }

    const loadData: ArticleLoadData = {
      personalization,
      userDealers
    }

    this.articlesLoaded = true
    this.setUserDealers(loadData, vehicles)
    this.setUserBrands(loadData, vehicles)

    this.searchParams = {
      pageNumber: 1,
      language: currentLang,
      pageSize: 4,
      channels,
      brands: country === 'de' ? this.brands : null,
      dealers: country === 'de' ? this.dealers : null
    }
    this.getArticlesData(this.searchParams)
  }

  setUserDealers(
    loadData: ArticleLoadData,
    vehicles: VehicleDTOExtended[]
  ): void {
    this.dealers = []
    this.userDealers = []

    const userDealers = loadData.userDealers
    if (vehicles?.[0] != null) {
      for (const v of vehicles) {
        if (v.dealer != null) {
          const dealer = this.dealers.find(s => s === v.dealer.companyNumber)
          if (dealer == null) {
            this.dealers.push(v.dealer.companyNumber)
          }
        }
      }
    }

    if (userDealers?.[0] != null) {
      userDealers.forEach(element => {
        if (element.salesDealer != null) {
          this.addToUserDealersArray(element.salesDealer)
        }
        if (element.serviceDealer != null) {
          this.addToUserDealersArray(element.serviceDealer)
        }
      })
    }
  }

  addToUserDealersArray(dealer: DealerDTO): void {
    if (dealer?.efitId != null && !this.userDealers.includes(dealer.efitId)) {
      this.userDealers.push(dealer.efitId)
    }
  }

  setUserBrands(
    loadData: ArticleLoadData,
    vehicles?: VehicleDTOExtended[]
  ): void {
    this.brands = []
    this.userBrands = []
    if (loadData?.personalization?.brands != null) {
      for (const b of loadData.personalization.brands) {
        const brand = b.name.toLowerCase()
        if (this.brands.find(s => s === brand) == null) {
          this.brands.push(brand)
          this.userBrands.push(brand)
        }
      }
    }

    vehicles = vehicles != null ? vehicles : this.vehicles
    if (vehicles?.[0] != null) {
      for (const v of vehicles) {
        if (
          v.brand != null &&
          this.userBrands.find(s => s === v.brand.toLowerCase()) == null
        ) {
          this.userBrands.push(v.brand.toLowerCase())
        }
      }
    }
  }

  getArticlesData(params: SearchParams): void {
    this.unsubPreviousGetArticlesData()

    const dashEco = this.dashEco[params.language]

    if (dashEco != null) {
      this.getArticlesSub = this.getArticles(
        params.pageNumber,
        dashEco,
        null,
        null,
        params.channels,
        params.brands,
        params.dealers
      ).subscribe(
        value => {
          this.loadingArticles = false
          this.dataService.dashEcoArticlesLoaded(value)
        },
        error => {
          this.loadingArticles = false
          this.logger.error(error)
          this.dataService.dashEcoArticlesLoaded(null)
        }
      )
    }
  }

  unsubPreviousGetArticlesData(): void {
    try {
      this.getArticlesSub.unsubscribe()
    } catch (error) {
      // no need to show log
    }
  }

  async getUserArticles(): Promise<void> {
    if (!this.showDashEcoNews) {
      return
    }
    // get articles from local storage
    let articles
    try {
      articles = await this.localStorage.getArticles()
    } catch (error) {
      this.logger.debug(error)
    }
    if (articles != null) {
      this.dataService.dashEcoArticlesFromLocalStorageLoaded(articles)
      this.loadingArticles = false
    } else {
      this.loadingArticles = true
    }
  }
}
