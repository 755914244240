import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { NGXLogger } from 'ngx-logger'
import { BehaviorSubject, Observable } from 'rxjs'
import { SharedService } from './shared.service'
import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'

export type MailboxNotificationDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.mailbox.dto.MailboxNotificationDTO
export type NotificationPageDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.mailbox.dto.NotificationPageDTO
export type NotificationTypeDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.mailbox.dto.NotificationTypeDTO

@Injectable({
  providedIn: 'root'
})
export class MyMailboxService {
  private readonly apiUrl

  private readonly _myMessageCount = new BehaviorSubject<NotificationPageDTO>(
    null
  )
  onMessageCountLoaded = this._myMessageCount.asObservable()

  private readonly _myMailbox = new BehaviorSubject<NotificationPageDTO>(null)
  onMyMailboxLoaded = this._myMailbox.asObservable()

  private readonly _types = new BehaviorSubject<NotificationTypeDTO[]>(null)
  onTypesLoaded = this._types.asObservable()

  public loading = {
    myMailbox: new BehaviorSubject<boolean>(true),
    types: new BehaviorSubject<boolean>(true)
  }

  constructor(
    private readonly http: HttpClient,
    private readonly sharedService: SharedService,
    private readonly logger: NGXLogger,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  myMessageCountLoaded(myMailbox: NotificationPageDTO): void {
    this._myMessageCount.next(myMailbox)
  }

  myMailboxLoaded(myMailbox: NotificationPageDTO): void {
    this._myMailbox.next(myMailbox)
  }

  typesLoaded(types): void {
    this._types.next(types)
  }

  public getMyMailbox(data?): Observable<NotificationPageDTO> {
    let params = new HttpParams()
    if (data != null) {
      if (this.sharedService.stringExists(data.lang)) {
        params = params.append('lang', data.lang)
      }
      if (data.page > 0) {
        params = params.append('page', data.page)
      }
      if (data.pageSize > 0) {
        params = params.append('pageSize', data.pageSize)
      }
      if (this.sharedService.stringExists(data.sortDir)) {
        params = params.append('sortDir', data.sortDir)
      }
      if (this.sharedService.stringExists(data.type)) {
        params = params.append('type', data.type)
      }
      if (data.read != null) {
        params = params.append('read', data.read)
      }
      if (this.sharedService.stringExists(data.dateCreatedStart)) {
        params = params.append('dateCreatedStart', data.dateCreatedStart)
      }
      if (this.sharedService.stringExists(data.dateCreatedEnd)) {
        params = params.append('dateCreatedEnd', data.dateCreatedEnd)
      }
      if (this.sharedService.stringExists(data.sort)) {
        params = params.append('sort', data.sort)
      }
    }

    return this.http.get<NotificationPageDTO>(this.apiUrl + '/mailbox', {
      params
    })
  }

  public deleteMessage(messageIds: number[]): Observable<unknown> {
    let httpParams = '?'
    messageIds.forEach(id => {
      httpParams += 'id=' + id + '&'
    })
    httpParams = httpParams.slice(0, -1)
    return this.http.delete(`${this.apiUrl}/mailbox${httpParams}`)
  }

  public markMessagesAsRead(messageIds?: number[]): Observable<unknown> {
    let url = `${this.apiUrl}/mailbox/read`
    if (messageIds != null) {
      let httpParams = '?'
      messageIds.forEach(id => {
        httpParams += 'id=' + id + '&'
      })
      httpParams = httpParams.slice(0, -1)
      url = `${this.apiUrl}/mailbox/read${httpParams}`
    }
    return this.http.put(url, null)
  }

  public updateMessage(message: MailboxNotificationDTO): Observable<unknown> {
    // dont send whole message object since its 2 long
    return this.http.put<unknown>(
      this.apiUrl + '/mailbox/' + String(message.id),
      {}
    )
  }

  public getType(lang: string): Observable<NotificationTypeDTO[]> {
    let params = new HttpParams()
    params = params.append('lang', lang)
    return this.http.get<NotificationTypeDTO[]>(
      this.apiUrl + '/mailbox/types',
      {
        params
      }
    )
  }

  public getMyMailboxCount(): void {
    this.getMyMailbox().subscribe(
      messages => {
        this.myMessageCountLoaded(messages)
      },
      error => {
        this.logger.log(error)
        this.myMessageCountLoaded(null)
      }
    )
  }

  public getMyMailboxData(data?): void {
    this.loading.myMailbox.next(true)
    this.getMyMailbox(data).subscribe(
      messages => {
        this.myMailboxLoaded(messages)
        this.loading.myMailbox.next(false)
      },
      error => {
        this.logger.log(error)
        this.myMailboxLoaded(null)
        this.loading.myMailbox.next(false)
      }
    )
  }

  public getMessageTypes(lang: string): void {
    this.getType(lang).subscribe(
      types => {
        this.typesLoaded(types)
        this.loading.types.next(false)
      },
      error => {
        this.logger.log(error)
        this.typesLoaded(null)
        this.loading.types.next(false)
      }
    )
  }
}
