import {
  Component,
  ElementRef,
  type AfterViewInit,
  HostListener
} from '@angular/core'
import {
  ResizeService
} from '@inside-hub-app/customer-portal-shared'

@Component({
  selector: 'customer-portal-app-size-detector',
  templateUrl: './size-detector.component.html'
})
export class SizeDetectorComponent implements AfterViewInit {
  constructor (
    private readonly elementRef: ElementRef,
    private readonly resizeSvc: ResizeService
  ) {}

  @HostListener('window:resize', [])
  private onResize (): void {
    this.detectScreenSize()
  }

  ngAfterViewInit (): void {
    this.detectScreenSize()
  }

  private detectScreenSize (): void {
    const w = window.innerWidth
    this.resizeSvc.calculateSize(w)
  }
}
