import { type AfterViewInit, Component, type OnDestroy, type OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { debounceTime, distinctUntilChanged } from 'rxjs'
import { DataService } from '../../../services/data.service'
import { FAQService, type FaqDTOExtended } from '../../../services/faq.service'
import {
  ResizeService, type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'
import { SharedService } from '../../../services/shared.service'
@Component({
  selector: 'customer-portal-app-faq',
  templateUrl: './faq.component.html'
})
export class FAQComponent implements OnInit, OnDestroy, AfterViewInit {
  public screenSize: SCREEN_SIZE

  loadingFAQ = true
  updatingLikeUnlike = false

  faqHOLD: FaqDTOExtended[] = []
  faq: FaqDTOExtended[] = []

  sub = {
    onUserLoaded: null,
    onFAQLoaded: null,
    FAQLoading: null,
    onLanguageChange: null,
    filter: null,
    updatingLikeUnlike: null,
    onChangeLanguageStarted: null
  }

  filterFormGroup = new FormGroup({
    filter: new FormControl<string | null>(null)
  })

  constructor (
    public resizeService: ResizeService,
    private readonly sharedService: SharedService,
    private readonly dataService: DataService,
    private readonly faqService: FAQService
  ) {
    this.screenSize = this.resizeService.screenSize
  }

  getData (): void {
    this.faqService.getFAQData()
  }

  getFilterArray (): string[] {
    const filter: string = this.filterFormGroup.get('filter').value
    let filterArray = filter.split(' ')
    filterArray = filterArray.filter(word => {
      return word.length > 2
    })
    return filterArray
  }

  filterIsIncluded (htmlString: string): boolean {
    const filterArray = this.getFilterArray()
    const text = this.getTextFromHTML(htmlString)
    const found = filterArray.some(v => {
      return text?.toLowerCase().includes(v.toLowerCase())
    })
    return found
  }

  highlightText (text: string): string {
    const filterArray = this.getFilterArray()

    return text.replace(
      new RegExp('(' + filterArray.join('|') + ')', 'gi'),
      '<span style="background: #fb4764; color: #fff; border-radius: 2px;">$1</span>'
    ) // highlighting style css
  }

  highlightInnerHTMLText (element?): void {
    if (element == null) {
      element = document.getElementById('FAQList')
    }
    if (element?.childNodes?.[0] != null) {
      for (const node of element.childNodes) {
        if (node.nodeType !== Node.TEXT_NODE) {
          this.highlightInnerHTMLText(node)
        } else {
          if (this.filterIsIncluded(node.textContent)) {
            const replacementNode = document.createElement('span')
            replacementNode.innerHTML = this.highlightText(node.textContent)
            node.parentNode.insertBefore(replacementNode, node)
            node.parentNode.removeChild(node)
          }
        }
      }
    }
  }

  getTextFromHTML (htmlString: string): string {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html')
    return doc.body.innerText
  }

  filterFAQ (): void {
    this.faq = this.sharedService.deepCopy(this.faqHOLD)

    let matchFound = false
    if (this.filterExists()) {
      const filterArray = this.getFilterArray()
      if (filterArray?.[0] != null) {
        this.faq = this.faq?.filter(faq => {
          const found =
            this.filterIsIncluded(faq.title) || this.filterIsIncluded(faq.text)
          if (found) {
            matchFound = true
            faq.isExpanded = true // expand found item after search
          }
          return found
        })
      }
    }

    this.faq = this.faq?.sort((a, b) => {
      return a.position - b.position
    })

    if (matchFound) {
      setTimeout(() => {
        this.highlightInnerHTMLText()
      })
    }
  }

  faqExists (): boolean {
    return Boolean(this.faq?.[0])
  }

  filterExists (): boolean {
    const filter = this.filterFormGroup.get('filter').value
    return this.sharedService.stringExists(filter)
  }

  likeClicked (event, item: FaqDTOExtended): void {
    this.sharedService.preventEventPropagation(event)
    this.faqService.likeClicked(item, this.faqHOLD)
  }

  unlikeClicked (event, item: FaqDTOExtended): void {
    this.sharedService.preventEventPropagation(event)
    this.faqService.unlikeClicked(item, this.faqHOLD)
  }

  ngOnInit (): void {
    this.sub.onUserLoaded = this.dataService.onUserLoaded.subscribe(user => {
      // get faq after user data loaded since we need language
      this.getData()
    })

    this.sub.onFAQLoaded = this.faqService.onFAQLoaded.subscribe(
      (faq: FaqDTOExtended[]) => {
        this.faqHOLD = faq
        this.filterFAQ()
      }
    )
    this.sub.FAQLoading = this.faqService.loading.FAQ.subscribe(loading => {
      this.loadingFAQ = loading
    })

    this.sub.updatingLikeUnlike =
      this.faqService.loading.updatingLikeUnlike.subscribe(loading => {
        this.updatingLikeUnlike = loading
      })

    this.sub.onLanguageChange = this.dataService.onLanguageChange.subscribe(
      () => {
        // reload faq on language change
        this.getData()
      }
    )

    this.sub.onChangeLanguageStarted =
      this.dataService.onChangeLanguageStarted.subscribe(() => {
        // after the user clicks on change language show spinner
        this.loadingFAQ = true
      })

    // filter changed
    this.sub.filter = this.filterFormGroup.controls.filter.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.filterFAQ()
      })
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  ngAfterViewInit (): void {
    setTimeout(() => {
      // scroll to top
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    })
  }
}
