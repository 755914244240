<customer-portal-app-navbar></customer-portal-app-navbar>
<customer-portal-app-navbar-second></customer-portal-app-navbar-second>
<div class="bg-mat-ef-revolution-grey-500">
  <customer-portal-revolution-vehicle-list
    [mode]="'vehicles-view'"
  ></customer-portal-revolution-vehicle-list>
  <customer-portal-app-revolution-vehicle-navigation></customer-portal-app-revolution-vehicle-navigation>
  <customer-portal-app-delete-vehicle></customer-portal-app-delete-vehicle>
</div>
<customer-portal-footer-container></customer-portal-footer-container>
