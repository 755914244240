/* eslint-disable @typescript-eslint/member-ordering */
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute } from '@angular/router'
import {
  User,
  UserTelephone
} from '@inside-hub-app/customer-portal-b2c-client'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { LiveChatWidgetComponent } from '@livechat/widget-angular'
import { NGXLogger } from 'ngx-logger'
import { LiveChatDialogComponent } from '../../../components/vehicle-details/live-chat-dialog/live-chat-dialog.component'
import { DataService } from '../../../services/data.service'
import { DealerDTO } from '../../../services/dealers.service'
import { LocalStorageService } from '../../../services/local-storage.service'
import { SharedService } from '../../../services/shared.service'
import {
  VehicleDTOExtended,
  VehicleDealersDTO
} from '../../../services/vehicles.service'
import { RevolutionChangeDealerSellerPopupComponent } from './revolution-change-dealer-seller-popup/revolution-change-dealer-seller-popup.component'

import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
declare type visibility = 'maximized' | 'minimized' | 'hidden'

@Component({
  selector: 'customer-portal-app-revolution-vehicle-dealers',
  templateUrl: './revolution-vehicle-dealers.component.html'
})
export class RevolutionVehicleDealersComponent implements OnInit, OnDestroy {
  vehicle: VehicleDTOExtended
  @Input() set setVehicle (vehicle: VehicleDTOExtended) {
    this.vehicle = vehicle
    this.setDealers()
  }

  user: User
  @Input() set setUser (user: User) {
    this.user = user
    if (user != null) {
      this.customerName = user.isBusiness
        ? user.companyName
        : String(user.firstName) + ' ' + String(user.lastName)
      this.customerEmail = user.email
    }
  }

  dealers
  dealerLoading = true

  public sameDealerAndService = false
  public hasSalesDealer = false
  public hasServiceDealer = false

  liveChatAvailable: boolean
  showChat = false
  groupId: string
  liveChatLicenceId = '11722410'
  customerName: string
  customerEmail: string
  public isLiveChatWidgetLoaded = false
  visibility: visibility = 'hidden'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sessionVariables: any // Record<string, string>
  @ViewChild('liveChatWidget', { static: false })
  public liveChatWidget: LiveChatWidgetComponent

  locations: { de: string, fr: string, it: string }

  error = false

  sub = {
    onVehicleDealersLoadedSub: null,
    onVehicleVinChange: null,
    dealers: null
  }

  constructor (
    private readonly dataService: DataService,
    private readonly logger: NGXLogger,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly sharedService: SharedService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly localStorage: LocalStorageService,
    public transifexService: TransifexService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.liveChatAvailable = this.remoteConfigService.get('features.liveChat')
    this.locations = {
      de: this.remoteConfigService.get('links.locations.de'),
      fr: this.remoteConfigService.get('links.locations.fr'),
      it: this.remoteConfigService.get('links.locations.it')
    }

    route.params.subscribe(() => {
      this.hideLiveChat()
    })
  }

  ngOnInit (): void {
    // Close live chat on vehicle change
    this.sub.onVehicleVinChange = this.dataService.onVehicleVinChange.subscribe(
      () => {
        this.showChat = false
      }
    )

    this.sub.onVehicleDealersLoadedSub =
      this.dataService.onVehicleDealersLoaded.subscribe(value => {
        this.dealers = value
        if (value != null) {
          this.setDealers()
        }
      })

    this.sub.dealers = this.dataService.loading.dealers.subscribe(loading => {
      this.dealerLoading = loading
    })
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  setDealers (): void {
    if (this.vehicle != null) {
      if (this.vehicle.vin === this.dealers?.vin) {
        this.vehicle.dealers = this.dealers
      }
      this.checkSameDealerAndService(this.vehicle.dealers)
      this.dataService.dealerChanged(this.vehicle.dealers)
    }
  }

  checkSameDealerAndService (dealers: VehicleDealersDTO): void {
    this.sameDealerAndService = false
    this.hasSalesDealer = dealers.salesDealer != null
    this.hasServiceDealer = dealers.serviceDealer != null
    if (
      dealers.salesDealer != null &&
      dealers.serviceDealer != null &&
      dealers.salesDealer.id === dealers.serviceDealer.id
    ) {
      this.sameDealerAndService = true
    }
    if (dealers.serviceDealer == null) {
      this.sameDealerAndService = true
    }
  }

  changeDealerSeller (dealerType: 'sales' | 'service'): void {
    const dialogRef = this.dialog.open(
      RevolutionChangeDealerSellerPopupComponent,
      {
        data: {
          vehicle: this.vehicle,
          vehicleDealers: this.vehicle.dealers,
          type: dealerType
        },
        panelClass: 'mat-dialog-cpt'
      }
    )

    dialogRef.afterClosed().subscribe(dealers => {
      if (dealers != null && dealers !== false) {
        this.dataService.dealerChanged(dealers)
        void this.localStorage.setVehicleDealers(this.vehicle.vin, dealers)
        this.vehicle.dealers = dealers
        this.checkSameDealerAndService(dealers)
        this.dealerLoading = false
        this.dataService.reloadUserDealers()
      } else {
        this.dealerLoading = false
      }
    })
  }

  openLiveChatDialog (dealer: DealerDTO): void {
    this.hideLiveChat()
    const dialogRef = this.dialog.open(LiveChatDialogComponent, {
      data: dealer,
      panelClass: 'mat-dialog-cpt'
    })

    dialogRef.afterClosed().subscribe(group => {
      if (group != null && group !== false) {
        this.groupId = String(dealer.liveChatGroupId)
        this.showChat = true
      }
    })
  }

  onVisibilityChanged (ev: { visibility: visibility }): void {
    switch (ev.visibility) {
      case 'maximized':
        this.logger.info(ev.visibility)
        break
      case 'minimized':
      case 'hidden':
        this.hideLiveChat()
        this.logger.info(ev.visibility)
        // window.location.reload()
        break
    }
  }

  openChatWindow (dealer: DealerDTO): void {
    if (!this.sameDealerAndService) {
      this.openLiveChatDialog(dealer)
      return
    }
    this.groupId = String(dealer.liveChatGroupId)
    this.showChat = true

    if (this.isLiveChatWidgetLoaded) {
      this.visibility = 'maximized'
    }
  }

  hideLiveChat (): void {
    this.visibility = 'hidden'
    this.groupId = null
    this.showChat = false
  }

  onChatLoaded (): void {
    this.isLiveChatWidgetLoaded = true

    // im using map to keep key order
    const map = new Map()
    map.set('CARE id', this.user != null ? this.user.careId.toString() : '')
    map.set('MDM id', this.user != null ? this.user.contactId.toString() : '')
    map.set('First name', this.user != null ? this.user.firstName : '')
    map.set('Last name', this.user != null ? this.user.lastName : '')
    map.set(
      'Telephone',
      this.user != null ? this.getUserTelephone(this.user.telephone) : ''
    )
    map.set('Brand', this.vehicle != null ? this.vehicle.brand : '')
    map.set('Model', this.vehicle != null ? this.vehicle.model : '')
    map.set('VIN', this.vehicle != null ? this.vehicle.vin : '')
    this.sessionVariables = map

    this.visibility = 'maximized'
  }

  getUserTelephone (telephone: UserTelephone[]): string {
    const mobile = telephone.find(
      t =>
        t.type.toLowerCase() === 'mobile' &&
        t.usage.toLowerCase() === 'private' &&
        t.primary
    )
    const landlineBusiness = telephone.find(
      t =>
        t.type.toLowerCase() === 'landline' &&
        t.usage.toLowerCase() === 'business' &&
        t.primary
    )
    const landlinePersonal = telephone.find(
      t =>
        t.type.toLowerCase() === 'landline' &&
        t.usage.toLowerCase() === 'private' &&
        t.primary
    )

    let number =
      mobile?.number != null
        ? mobile.number
        : landlinePersonal?.number != null
          ? landlinePersonal.number
          : landlineBusiness?.number

    if (number == null) {
      telephone.forEach(t => {
        if (t.number != null) {
          number = t.number
        }
      })
    }

    return number
  }

  gotoLocation (): void {
    const lang = this.sharedService.currentLanguage()
    const url = this.locations[lang]
    window.open(url, '_blank')
  }
}
