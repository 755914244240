import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LoggerModule } from 'ngx-logger'

@NgModule({
  imports: [
    CommonModule,
    LoggerModule.forChild()
  ],
})
export class HubConfigurationModule {}
