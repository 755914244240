<mat-menu class="w-screen max-w-none md:max-w-[288px] p-4 px-6" #menu="matMenu">
  <p
    class="font-efSans text-base font-bold text-mat-ef-revolution-primary-500 mb-4"
  >
    <T
      str="Update language"
      key="customerPortal.customer-portal.details.change.language"
      tags="customer-portal, 3.1"
    ></T>
  </p>

  <div
    class="flex flex-col gap-2 relative"
    [ngClass]="{
      'opacity-50': saving
    }"
  >
    <div class="flex-1" *ngFor="let lan of languages">
      <p
        *ngIf="lan && user"
        attr.data-cy="language-change-{{ lan?.toLowerCase() }}"
        class="my-1 menu-column"
        (click)="
          sharedService.preventEventPropagation($event);
          changeLanguage(lan);
          sendGTMNavigationData(
            'customerPortal.customer-portal.language.' + lan,
            'White Box Link'
          )
        "
        [ngClass]="{
          'text-mat-ef-revolution-secondary-500 font-bold':
            lan.toString().toLowerCase() === primaryLang.toLowerCase()
        }"
      >
        {{
          {
            str: lan,
            skipShared: true,
            prefix: 'customerPortal.customer-portal.language.'
          } | transifexTranslate
        }}
      </p>
    </div>

    <div
      *ngIf="saving"
      class="absolute top-0 right-0 bottom-0 left-0 p-6 flex justify-center items-center"
      (click)="sharedService.preventEventPropagation($event)"
    >
      <div class="flex-1 flex flex-col justify-center items-center">
        <customer-portal-ef-spinner
          [color]="'mat-ef-revolution-grey-two-500'"
          [opacity]="0.7"
        ></customer-portal-ef-spinner>
      </div>
    </div>
  </div>

  <div *ngIf="error" class="text-mat-ef-revolution-primary-500 mt-4">
    {{ 'general-error-message' | sharedTranslate }}
  </div>
</mat-menu>
