import {
  ChangeDetectorRef,
  Inject,
  Injectable,
  OnDestroy,
  Pipe,
  PipeTransform
} from '@angular/core'
import { TranslatePipe, TranslateService } from '@ngx-translate/core'
import { TRANSLATION_PREFIX } from '../injection-tokens'

@Injectable()
@Pipe({
  name: 'ppTranslate',
  pure: false
})
export class PpTranslatePipe implements PipeTransform, OnDestroy {
  private readonly translatePipe: TranslatePipe

  constructor (
    translateService: TranslateService,
    changeDetectorRef: ChangeDetectorRef,
    @Inject(TRANSLATION_PREFIX) private readonly translationPrefix: string
  ) {
    this.translatePipe = new TranslatePipe(translateService, changeDetectorRef)
  }

  transform (query: string, ...args: any[]): any {
    const translation = this.translatePipe.transform(
      `${this.translationPrefix}${query}`,
      ...args
    )
    if (translation === `${this.translationPrefix}${query}`) {
      return query
    }

    return translation
  }

  ngOnDestroy () {
    this.translatePipe.ngOnDestroy()
  }
}
