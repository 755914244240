import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import {
  User,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGoogleTagmanagerService,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { TranslateService } from '@ngx-translate/core'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { InstantErrorStateMatcherService } from '../../../../services/instant-error-state-matcher.service'
import {
  LeasingService,
  LoanContactDTO,
  LoanDTO,
  MultileaseContactDataDTO,
  MultileaseContactRequestDTO
} from '../../../../services/leasing.service'
import { SharedService } from '../../../../services/shared.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'
import { ConfirmationPopupComponent } from '../../../basic/confirmation-popup/confirmation-popup.component'
interface DialogData {
  vehicle: VehicleDTOExtended
  leasingResponse: LoanDTO
  mode?: 'MultileaseContact' | 'Contact'
}

interface MultiLeaseContactFormData {
  lang: string
  offerId: number
  data: MultileaseContactRequestDTO
}

interface ContactFormData {
  lang: string
  data: LoanContactDTO
}

@Component({
  selector: 'customer-portal-app-revolution-leasing-contact-popup',
  templateUrl: './leasing-contact-popup.component.html'
})
export class RevolutionLeasingContactPopupComponent implements OnInit {
  saving = false
  loadingContactData = false
  loadingLoanContactData = false
  user: User
  loanContactData: MultileaseContactDataDTO
  loanContactForm
  disabledMultiLeaseContactReasons: number[]
  matcher = new InstantErrorStateMatcherService()

  mode: 'MultileaseContact' | 'Contact'

  title: string
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly userService: UserService,
    private readonly leasingService: LeasingService,
    private readonly dialogRef: MatDialogRef<RevolutionLeasingContactPopupComponent>,
    private readonly translate: TranslateService,
    public dialog: MatDialog,
    private readonly dataService: DataService,
    private readonly logger: NGXLogger,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public sharedService: SharedService,
    public transifexService: TransifexService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.disabledMultiLeaseContactReasons = this.remoteConfigService.get(
      'multilease.disabledMultiLeaseContactReasons'
    )
    this.mode = this.data.mode
    if (this.mode === 'MultileaseContact') {
      this.title = 'customerPortal.customer-portal.leasing.manage.contact'

      this.loanContactForm = new FormGroup({
        reason: new FormControl<string | null>(null, Validators.required),
        message: new FormControl<string | null>(null, [
          Validators.required,
          Validators.maxLength(512)
        ]),
        affiliate: new FormControl<string | null>({
          value: '',
          disabled: true
        }),
        mileage: new FormControl<string | null>(null, [
          Validators.required,
          Validators.max(9999999)
        ])
      })
    } else {
      this.title = 'customerPortal.customer-portal.leasing-extension.contact'

      this.loanContactForm = new FormGroup({
        message: new FormControl<string | null>(null, [
          Validators.required,
          Validators.maxLength(512)
        ])
      })
    }
  }

  onSubmit(): void {
    this.saving = true
    if (this.mode === 'MultileaseContact') {
      this.onSubmit_MultileaseContact()
    } else {
      this.onSubmit_Contact()
    }
  }

  onSubmit_MultileaseContact(): void {
    const data: MultileaseContactRequestDTO = {
      vin: this.data.vehicle.vin,
      reasonId: this.loanContactForm.get('reason').value,
      affiliateId: this.loanContactForm.get('affiliate').value,
      message: this.loanContactForm.get('message').value,
      mileage: this.loanContactForm.get('mileage').value
    }
    const lang = this.translate.currentLang
    this.sendMultileaseContact({
      offerId: this.data.leasingResponse.offerId,
      data,
      lang
    })
  }

  sendMultileaseContact(formData: MultiLeaseContactFormData): void {
    this.leasingService
      .sendMultileaseContactForm(formData.offerId, formData.data, formData.lang)
      .subscribe(
        () => {
          this.cptGtmService.sendContactUsData(2, 'Leasing kontakt')
          this.saving = false
          this.dialogRef.close()
          this.openConfirmDialog(
            'shared.success',
            'customerPortal.customer-portal.leasing.contact.send.success.message'
          )
        },
        error => {
          this.cptGtmService.sendContactUsData(2, 'Leasing kontakt')
          this.saving = false
          this.dialogRef.close()
          this.openConfirmDialog(
            'customerPortal.customer-portal.leasing.contact.send.failure.title',
            'customerPortal.customer-portal.leasing.contact.send.failure.message'
          )
          this.logger.debug(error)
        }
      )
  }

  onSubmit_Contact(): void {
    const data: LoanContactDTO = {
      vin: this.data.vehicle.vin,
      message: this.loanContactForm.get('message').value
    }
    const lang = this.translate.currentLang
    this.sendContact({
      data,
      lang
    })
  }

  sendContact(formData: ContactFormData): void {
    this.leasingService.sendContactForm(formData.data, formData.lang).subscribe(
      () => {
        this.cptGtmService.sendContactUsData(2, 'Leasing kontakt')
        this.saving = false
        this.dialogRef.close()
        this.openConfirmDialog(
          'shared.thank-you',
          'customerPortal.customer-portal.leasing-extension.contact.send.success.message'
        )
      },
      error => {
        this.cptGtmService.sendContactUsData(2, 'Leasing kontakt')
        this.saving = false
        this.dialogRef.close()
        this.openConfirmDialog(
          'customerPortal.customer-portal.leasing-extension.contact.send.failure.title',
          'customerPortal.customer-portal.leasing-extension.contact.send.failure.message'
        )
        this.logger.debug(error)
      }
    )
  }

  openConfirmDialog(title, text): void {
    this.dialog.open(ConfirmationPopupComponent, {
      data: {
        title,
        text,
        useInnerHtml: true
      },
      panelClass: 'mat-dialog-cpt'
    })
  }

  isDisabledContactReason(reasonId: number): number {
    return this.disabledMultiLeaseContactReasons.find(
      element => element === reasonId
    )
  }

  ngOnInit(): void {
    this.cptGtmService.sendContactUsData(1, 'Leasing kontakt')

    this.loadingContactData = true
    this.dataService.onUserLoaded.subscribe(user => {
      this.loadingContactData = false
      this.user = user
    })

    if (this.mode === 'MultileaseContact') {
      this.loadingLoanContactData = true
      this.leasingService
        .getMultiLeaseLoanContactData(this.data.leasingResponse.offerId)
        .subscribe(response => {
          this.loadingLoanContactData = false
          this.loanContactData = response
        })
      this.loanContactForm.get('reason').valueChanges.subscribe(reasonId => {
        if (this.isDisabledContactReason(reasonId) != null) {
          this.disableAffiliate()
        } else {
          this.enableAffiliate()
        }
      })
    }
  }

  disableAffiliate(): void {
    this.loanContactForm.get('affiliate').setValue(null)
    this.loanContactForm.get('affiliate').disable()
    this.loanContactForm.get('affiliate').clearValidators()
    this.loanContactForm.get('affiliate').updateValueAndValidity()
  }

  enableAffiliate(): void {
    let value = null
    if (this.loanContactData?.affiliates?.length === 1) {
      value = this.loanContactData.affiliates[0].id
    }
    this.loanContactForm.get('affiliate').enable()
    this.loanContactForm.get('affiliate').setValue(value)
    this.loanContactForm.get('affiliate').setValidators(Validators.required)
    this.loanContactForm.get('affiliate').markAsTouched()
    this.loanContactForm.get('affiliate').updateValueAndValidity()
  }
}
