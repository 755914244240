import { Component, Input, type OnInit } from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS } from '@angular/material/legacy-core'
import {
  CptDateAdapter,
  TransifexService,
  PwaService
} from '@inside-hub-app/customer-portal-shared'
import {
  APP_DATE_FORMATS_1,
  SharedService
} from '../../../../services/shared.service'
import {
  type DealerDTO,
  type SearchDealerSellerResponse
} from '../../../../services/dealers.service'
import { CustomCalendarHeaderComponent } from '../../../basic/custom-calendar-header/custom-calendar-header.component'
import { DatepickerScrollService } from '../../../../services/datepickerScroll.service'
import { RevolutionSearchDealerSellerPopupComponent } from '../../revolution-vehicle-dealers/revolution-search-dealer-seller-popup/revolution-search-dealer-seller-popup.component'
import { MatDialog } from '@angular/material/dialog'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { SelectOptionsPopupComponent } from '../../../basic/select-options-popup/select-options-popup.component'
import { addDays, isSaturday, isSunday } from 'date-fns'

@Component({
  selector: 'customer-portal-app-appointments-popup-third-form',
  templateUrl: './appointments-popup-third-form.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: CptDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS_1
    }
  ]
})
export class AppointmentsPopupThirdFormComponent implements OnInit {
  @Input() appointmentsPopupService

  customHeader = CustomCalendarHeaderComponent

  times = [
    'customerPortal.customer-portal.appointments.times.morning',
    'customerPortal.customer-portal.appointments.times.noon',
    'customerPortal.customer-portal.appointments.times.afternoon'
  ]

  closedDaysOfWeek

  isMobile
  dateFormat: string

  constructor (
    public sharedService: SharedService,
    public transifexService: TransifexService,
    private readonly datepickerScrollService: DatepickerScrollService,
    public dialog: MatDialog,
    private readonly pwaService: PwaService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.dateFormat = this.remoteConfigService.get('dateFormat.long').toUpperCase()
    this.isMobile = this.pwaService.checkMobile()
  }

  async ngOnInit (): Promise<void> {
    this.appointmentsPopupService.showDealerSection = true

    this.appointmentsPopupService.thirdFormGroup.controls.time.setValue(
      this.times[0].valueOf()
    )
    const addBusinessDays = this.remoteConfigService.get(
      'appointments.addBusinessDays'
    )
    this.appointmentsPopupService.minDate = this.addBusinessDays(
      new Date(),
      addBusinessDays
    )
    this.appointmentsPopupService.defaultDate = new Date()
    if (this.appointmentsPopupService.defaultDate.getDay() !== 5) {
      this.appointmentsPopupService.defaultDate.setDate(
        Number(this.appointmentsPopupService.defaultDate.getDate()) + 2
      )
    } else {
      this.appointmentsPopupService.defaultDate.setDate(
        Number(this.appointmentsPopupService.defaultDate.getDate()) + 3
      )
    }

    this.appointmentsPopupService.sub.dealerSub =
      this.appointmentsPopupService.thirdFormGroup.controls.carDealer.valueChanges.subscribe(
        value => {
          this.selectedDealerChanged(value)
        }
      )
  }

  selectedDealerChanged (value): void {
    this.closedDaysOfWeek = undefined
    // after dealer change reset selected date
    this.appointmentsPopupService.thirdFormGroup.get('date').setValue(undefined)
    if (value?.id != null) {
      const dealer = this.appointmentsPopupService.allDealers?.find(
        s => s.id === Number(value.id)
      )
      const chosenDealer =
        dealer != null ? dealer : this.appointmentsPopupService.dealer

      this.appointmentsPopupService.addDealerToBrandDealers(
        this.appointmentsPopupService.car.dealers,
        chosenDealer
      )
      // take service opening hours
      if (chosenDealer?.openingHours?.service != null) {
        const hours = chosenDealer.openingHours.service
        this.closedDaysOfWeek = this.sharedService.closedDaysOfWeek(hours)
      }
    } else {
      // filter values
      this.appointmentsPopupService.filteredAllDealers =
        this.sharedService.filterDealers(
          value,
          this.appointmentsPopupService.allDealers
        )
      this.appointmentsPopupService.filteredDealers =
        this.sharedService.filterDealers(
          value,
          this.appointmentsPopupService.dealers
        )
    }
  }

  addScroll (picker): void {
    this.datepickerScrollService.addScrolling(picker)
  }

  compareDealer (dealer1: DealerDTO, dealer2: DealerDTO): boolean {
    return dealer1 != null && dealer2 != null && dealer1?.id === dealer2?.id
  }

  hasDealers (allDealersChecked?: boolean): boolean {
    if (allDealersChecked === true) {
      return this.appointmentsPopupService?.allDealers?.length > 0
    } else {
      return this.appointmentsPopupService?.dealers?.length > 0
    }
  }

  getDealersForDropdown (allDealersChecked?: boolean): DealerDTO[] {
    if (allDealersChecked === true) {
      return this.appointmentsPopupService.filteredAllDealers
    } else {
      return this.appointmentsPopupService.filteredDealers
    }
  }

  // -----------------------
  addBusinessDays (date: Date, numDaysToAdd: number): Date {
    let daysRemaining = numDaysToAdd
    let newDate = date
    while (daysRemaining > 0) {
      newDate = addDays(newDate, 1)
      if (!isSunday(newDate) && !isSaturday(newDate)) {
        daysRemaining--
      }
    }
    return newDate
  }

  addSelectedDealerToOfficialDealers (): void {
    this.appointmentsPopupService.setChosenDealer()
    this.appointmentsPopupService.addDealerToBrandDealers(
      this.appointmentsPopupService.car.dealers,
      this.appointmentsPopupService.chosenDealer
    )
  }

  myDateFilter = (d: Date): boolean => {
    if (this.closedDaysOfWeek != null) {
      return this.sharedService.datepickerDateFilter(d, this.closedDaysOfWeek)
    } else {
      if (d != null) {
        const day = d.getDay()
        // Prevent Sunday from being selected.
        return day !== 0
      }
      return true
    }
  }

  onDealerChangeClick (): void {
    const selectedDealer = this.appointmentsPopupService.dealers?.find(
      dealer => {
        return (
          dealer.id ===
          Number(
            this.appointmentsPopupService.thirdFormGroup.get('carDealer').value
              ?.id
          )
        )
      }
    )

    const dialog = this.dialog.open(
      RevolutionSearchDealerSellerPopupComponent,
      {
        data: {
          brand: this.appointmentsPopupService.car.brand,
          dealers:
            this.appointmentsPopupService.dealers != null
              ? this.appointmentsPopupService.dealers
              : [],
          selectedDealer: selectedDealer != null ? selectedDealer : null,
          serviceHoursCondition: true
        },
        panelClass: ['mat-dialog-cpt', 'find-dealer', 'no-lr-padding']
      }
    )

    dialog
      .afterClosed()
      .subscribe((searchDealerSellerResponse: SearchDealerSellerResponse) => {
        if (searchDealerSellerResponse != null) {
          const selectedDealer = searchDealerSellerResponse.selectedDealer
          if (selectedDealer != null) {
            this.appointmentsPopupService.dealer = selectedDealer
            this.appointmentsPopupService.dealerFinal = selectedDealer
            this.appointmentsPopupService.thirdFormGroup.controls.carDealer.patchValue(
              selectedDealer
            )
          }
        }
      })
  }

  openSelectOptionsPopup (ev, checkbox): void {
    this.sharedService.preventEventPropagation(ev)
    const type = 'service'
    const selectedDealer = this.appointmentsPopupService.dealers?.find(
      dealer => {
        return (
          dealer.id ===
          Number(
            this.appointmentsPopupService.thirdFormGroup.get('carDealer').value
              ?.id
          )
        )
      }
    )
    const dialog = this.dialog.open(SelectOptionsPopupComponent, {
      data: {
        selectedDealer: selectedDealer != null ? selectedDealer : null,
        dealers: this.getDealersForDropdown(checkbox.checked),
        salesDealer: this.appointmentsPopupService.dealer,
        type: 'dealer',
        dealerType: type
      },
      panelClass: ['mat-dialog-cpt', 'select-options']
    })
    dialog.afterClosed().subscribe((dialogData: any) => {
      if (dialogData != null) {
        const selectedDealer = dialogData.selected
        if (selectedDealer != null) {
          this.appointmentsPopupService.dealer = selectedDealer
          this.appointmentsPopupService.dealerFinal = selectedDealer
          this.appointmentsPopupService.thirdFormGroup.controls.carDealer.patchValue(
            selectedDealer
          )
        }
      }
    })
  }
}
