/**
 * copied from TransifexHubCache and added some modifications for missing translations
 */
export class TransifexCache {
  missingTranslations: string[] = []
  private translationsByLocale: {
    [locale: string]: {
      [key: string]: string
    }
  } = {}

  constructor(
    private readonly showTranslationKeys: boolean,
    private readonly showTranslationStr: boolean
  ) {}

  /**
   * Store locale translations in cache
   *
   * @param {String} localeCode
   * @param {Object} translations - Object with translation key:value pairs
   * @param {String} translations[key] - Translation string
   */
  update(localeCode, translations): void {
    const prevTranslations = this.translationsByLocale[localeCode] ?? {}
    this.translationsByLocale[localeCode] = {
      ...prevTranslations,
      ...translations
    }
  }

  /**
   * Get translations by locale from cache
   *
   * @param {String} localeCode
   * @returns {Object} translations
   */
  getTranslations(localeCode): { [key: string]: string } {
    return this.translationsByLocale[localeCode] ?? {}
  }

  /**
   * Check if locale has translations in cache
   *
   * @param {String} localeCode
   * @returns {Boolean}
   */
  hasTranslations(localeCode): boolean {
    return Boolean(this.translationsByLocale[localeCode])
  }

  /**
   * Check if translations are stale and need refreshing
   *
   * @param {String} localeCode
   * @returns {Boolean}
   */
  isStale(localeCode): boolean {
    return !this.hasTranslations(localeCode)
  }

  /**
   * Get translation by key. If key does not exist in cache,
   * return empty string
   *
   * @param {String} key
   * @param {String} localeCode
   * @returns {String} - translation or empty string
   */
  get(key, localeCode): string {
    if (this.showTranslationKeys) {
      return key
    }
    if (this.showTranslationStr) {
      localeCode = 'en'
    }

    const translation = this.getTranslations(localeCode)[key]
    if (this.stringExists(key)) {
      if (this.stringExists(translation)) {
        // remove from missing translations
        this.isTranslated(key)
      } else {
        // add to missing translations
        this.notTranslated(key)
      }
    }

    return translation ?? ''
  }

  // ************************missing translations***************************
  notTranslated(key: string): void {
    if (this.isValidKey(key) && !this.missingTranslations.includes(key)) {
      this.missingTranslations.push(key)
    }
    // eslint-disable-next-line @typescript-eslint/dot-notation
    window['missingTransifexTranslations'] = this.missingTranslations
  }

  isTranslated(key: string): void {
    if (this.isValidKey(key)) {
      this.missingTranslations = this.missingTranslations.filter(
        missing => missing !== key
      )
    }
    // eslint-disable-next-line @typescript-eslint/dot-notation
    window['missingTransifexTranslations'] = this.missingTranslations
  }

  isValidKey(key: string): boolean {
    // valid key will always have at least 1 '.' othervise its hash
    return (
      this.lastChar(key) !== '.' &&
      this.translateKey(key) !== 'undefined' &&
      key.includes('.')
    )
  }

  lastChar(key: string): string {
    const translate = this.translateKey(key)
    return translate.slice(-1)
  }

  translateKey(key: string): string {
    return key.split(',')[0]
  }

  stringExists(string: string): boolean {
    return Boolean(
      string != null && typeof string === 'string' && string.trim() !== ''
    )
  }
}
