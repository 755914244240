import { Injectable } from '@angular/core'
import { Md5 } from 'ts-md5'

@Injectable({
  providedIn: 'root'
})
export class HashingService {
  hashCode = s =>
    s.split('').reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0)
      return a & a
    }, 0)

  md5(str: string) {
    return Md5.hashStr(str)
  }
}
