import { ChangeDetectorRef, Component, type OnDestroy, type OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  EcondaService,
  ResizeService
} from '@inside-hub-app/customer-portal-shared'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import {
  CarmatoService,
  type CarmatoValuationDTO,
  type CarmatoWatchlistDTOExtended
} from '../../../services/carmato.service'
import { DataService } from '../../../services/data.service'
import { SharedService } from '../../../services/shared.service'
// import { FAVORITES } from '../../../mock/favorites'
@Component({
  selector: 'customer-portal-app-revolution-marketplace-routes',
  templateUrl: './marketplace-routes.component.html'
})
export class RevolutionMarketplaceRoutesViewComponent
implements OnInit, OnDestroy {
  country
  favorites: CarmatoWatchlistDTOExtended[] = []
  valuations: CarmatoValuationDTO[]
  activeComponent: string
  channels: boolean

  public screenSizePx: number
  showMobileView: boolean
  swipeData = {
    currentTab: null,
    swipeCoord: null,
    swipeTime: null,
    tabsCount: null
  }

  sub = {
    onResizePx: null,
    onWatchListLoaded: null,
    onUserConsentsLoaded: null
  }

  tabs = {
    0: 'general',
    1: 'favorites',
    2: 'searchagent'
  }

  constructor (
    private readonly router: Router,
    private readonly dataService: DataService,
    private readonly sharedService: SharedService,
    private readonly carmatoService: CarmatoService,
    private readonly route: ActivatedRoute,
    private readonly econdaService: EcondaService,
    private readonly cdf: ChangeDetectorRef,
    public resizeService: ResizeService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.country = this.remoteConfigService.get('country.code')
    this.hasNewFooter = this.remoteConfigService.get('hasNewFooter')

    this.route.params.subscribe(params => {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      this.activeComponent =
        params.marketplaceComponent != null
          ? params.marketplaceComponent
          : 'marketplace'
      this.econdaService.send({
        content: 'Marketplace',
        Target: ['Marketplace tab change', this.activeComponent, 1, 'd']
      })

      this.swipeData.currentTab = Number(
        this.sharedService.getKeyByValue(this.tabs, this.activeComponent)
      )
    })
  }

  public hasNewFooter

  ngOnInit (): void {
    this.sub.onWatchListLoaded =
      this.carmatoService.onWatchListLoaded.subscribe(favorites => {
        this.favorites = favorites
        // this.favorites = FAVORITES as any // mock
      })

    this.sub.onUserConsentsLoaded =
      this.dataService.onUserConsentsLoaded.subscribe(consents => {
        this.channels = this.sharedService.hasConsentsForEFDS(consents)
      })

    this.sub.onResizePx = this.resizeService.onResizePx.subscribe(size => {
      this.screenSizePx = size
      this.isMobileView()
    })

    this.screenSizePx = this.resizeService.screenSizePx
    this.isMobileView()
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  showContent (): boolean {
    return this.country === 'ch' || this.channels
  }

  isMobileView (): void {
    if (this.screenSizePx > 0) {
      if (this.screenSizePx < 1024) {
        this.showMobileView = true
        this.swipeData.tabsCount = 3
      } else {
        this.showMobileView = false
      }
      this.cdf.detectChanges()
    }
  }

  swipe (e: TouchEvent, when: string): void {
    const currTab = this.swipeData.currentTab
    this.sharedService.tabSwipe(e, when, this.swipeData)
    if (currTab !== this.swipeData.currentTab) {
      // change route
      const tab = this.tabs[this.swipeData.currentTab]
      void this.router.navigate(['/marketplace', tab])
    }
  }
}
