<div class="cpt-tracking">
  <ng-container *ngIf="vehicle">
    <customer-portal-vehicle-documents-disclaimer
      [translateKey]="
        'customerPortal.customer-portal.tracking.limited-usability'
      "
    ></customer-portal-vehicle-documents-disclaimer>

    <div class="grid grid-cols-1 lg:grid-cols-10 gap-10 md:gap-6">
      <ng-container *ngIf="vehicleTNT" [ngTemplateOutlet]="main">
      </ng-container>

      <ng-container *ngIf="!vehicleTNT">
        <div
          *ngIf="!loading"
          class="col-span-1 lg:col-span-10 mt-6 w-full h-36 bg-mat-ef-revolution-grey-200 rounded-xl flex flex-col justify-center items-center"
        >
          <div class="text-mat-ef-revolution-supergrey-900">
            <mat-icon svgIcon="no_sim"></mat-icon>
          </div>
          <div class="text-base font-efSans-primary">
            <T
              str="You currently have no delivery history"
              key="customerPortal.customer-portal.no-tracking"
              tags="customer-portal, 3.1"
            ></T>
          </div>
        </div>

        <ng-container *ngIf="loading">
          <div class="col-span-1 lg:col-span-10 w-full">
            <ngx-skeleton-loader
              appearance="line"
              [count]="6"
              animation="progress"
            >
            </ngx-skeleton-loader>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="!vehicle">
    <div class="container py-14">
      <mat-card class="m-auto rounded-lg">
        <div class="view-container m-0 py-0">
          <customer-portal-vehicle-documents-disclaimer
            [translateKey]="
              'customerPortal.customer-portal.tracking.limited-usability'
            "
          ></customer-portal-vehicle-documents-disclaimer>
        </div>
        <div
          class="view-container grid grid-cols-1 lg:grid-cols-10 gap-10 md:gap-6"
        >
          <ng-container [ngTemplateOutlet]="main"> </ng-container>
        </div>
      </mat-card>
    </div>
  </ng-container>

  <ng-template #main>
    <div
      class="col-span-1"
      [ngClass]="{
        'lg:col-span-7': salesman?.id,
        'lg:col-span-10': !salesman?.id
      }"
    >
      <ng-container *ngIf="loading">
        <div class="mb-14">
          <ngx-skeleton-loader
            appearance="line"
            [count]="4"
            animation="progress"
          >
          </ngx-skeleton-loader>
        </div>
      </ng-container>
      <ng-container *ngIf="!loading">
        <div
          class="text-lg md:text-xl font-bold mb-5 text-mat-ef-revolution-primary-500"
        >
          <T
            str="Delivery details"
            key="customerPortal.customer-portal.vehicle.tracking.delivery-details"
            tags="customer-portal, 3.1"
          ></T>
        </div>

        <mat-divider class="relative w-full"></mat-divider>
        <div class="flex items-center h-16 mr-0">
          <div class="flex gap-5 items-center w-full">
            <div class="panel-icon text-mat-ef-revolution-primary-500">
              <mat-icon class="basicIcons" svgIcon="vin"></mat-icon>
            </div>

            <div class="w-full mr-5">
              <div
                class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500 font-semibold"
              >
                <T
                  str="VIN (chassis number)"
                  key="customerPortal.customer-portal.vehicle.details.vin"
                  tags="customer-portal, 3.1"
                ></T>
              </div>

              <div
                class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500"
                data-cy="vehicle-tnt-info-card-vin"
              >
                {{ vehicleTNT?.vin || '-' }}
              </div>
            </div>
          </div>
        </div>
        <mat-divider class="relative w-full"></mat-divider>

        <div class="flex items-center h-16 mr-0">
          <div class="flex gap-5 items-center w-full">
            <div class="panel-icon text-mat-ef-revolution-primary-500">
              <mat-icon class="basicIcons" svgIcon="paste"></mat-icon>
            </div>

            <div class="w-full mr-5">
              <div
                class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500 font-semibold"
              >
                <T
                  str="Commission number"
                  key="customerPortal.customer-portal.vehicle.tracking.commission-number"
                  tags="customer-portal, 3.1"
                ></T>
              </div>

              <div
                class="text-xs sm:text-sm text-efSans text-mat-ef-revolution-primary-500"
                data-cy="vehicle-tnt-info-card-commision-number"
              >
                {{ vehicleTNT?.commissionNumber }}
              </div>
            </div>
          </div>
        </div>
        <mat-divider class="relative w-full"></mat-divider>
      </ng-container>

      <ng-container *ngIf="loading">
        <ngx-skeleton-loader appearance="line" [count]="4" animation="progress">
        </ngx-skeleton-loader>
      </ng-container>
      <ng-container *ngIf="!loading">
        <div
          class="text-lg md:text-xl font-bold mt-14 mb-5 text-mat-ef-revolution-primary-500"
        >
          <T
            str="Delivery status"
            key="customerPortal.customer-portal.vehicle.tracking.delivery-status"
            tags="customer-portal, 3.1"
          ></T>
        </div>

        <customer-portal-tracker-stepper
          [stepList]="stepList"
          [data]="vehicleTNT"
          [mode]="'TNT'"
          [prefix]="
            'customerPortal.customer-portal.vehicle.tracking.delivery-status.'
          "
        ></customer-portal-tracker-stepper>
      </ng-container>
    </div>

    <ng-container *ngIf="salesman?.id">
      <ng-container *ngIf="loading">
        <div class="col-span-1 lg:col-span-3 flex flex-col gap-4">
          <ngx-skeleton-loader
            appearance="line"
            [count]="8"
            animation="progress"
          >
          </ngx-skeleton-loader>
        </div>
      </ng-container>
      <ng-container *ngIf="!loading">
        <div class="col-span-1 lg:col-span-3 flex flex-col gap-4">
          <div
            class="font-efSans font-black text-2xl text-mat-ef-revolution-primary-500"
          >
            <T
              str="Do you have questions?"
              key="customerPortal.customer-portal.vehicle.tracking.have-questions"
              tags="customer-portal, 3.1"
            ></T>
          </div>
          <customer-portal-app-revolution-dealership-contact-molecule
            [salesAdvisor]="salesman"
            [view]="'tracking'"
            [disableEdit]="true"
          >
          </customer-portal-app-revolution-dealership-contact-molecule>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</div>
