import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'

import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { SharedService } from '../../../../services/shared.service'
import {
  InsuranceService
} from '@inside-hub-app/customer-portal-shared'
@Component({
  selector:
    'customer-portal-app-revolution-vehicle-service-package-and-insurance',
  templateUrl: './vehicle-service-package-and-insurance.component.html'
})
export class VehicleServicePackageAndInsuranceComponent
implements OnInit, OnDestroy {
  public hasInsurance
  user: User
  loadingServicePackage: boolean
  loadingInsurance: boolean
  maintenanceLoaded = false

  componentSub = {
    UserLoaded: null,
    servicePackage: null,
    loadingInsurance: null
  }

  constructor (
    public dialog: MatDialog,
    private readonly logger: NGXLogger,
    private readonly dataService: DataService,
    public readonly sharedService: SharedService,
    private readonly insuranceService: InsuranceService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasInsurance = this.remoteConfigService.get('hasInsurance')
    this.componentSub.UserLoaded = this.dataService.onUserLoaded.subscribe(
      user => {
        this.user = user
      }
    )
    this.componentSub.servicePackage =
      this.dataService.loading.servicePackage.subscribe(loading => {
        this.loadingServicePackage = loading
      })

    this.componentSub.loadingInsurance = this.insuranceService.loadingInsurance.subscribe(loading => {
      this.loadingInsurance = loading
    })
  }

  @Input()
  public vehicle: VehicleDTOExtended

  @Input()
  public vin: string

  exists = true

  ngOnInit (): void {
    if (this.vehicle == null) {
      this.exists = false
    }
  }

  ngOnDestroy (): void {
    this.componentSubUnsubscribe()
  }

  componentSubUnsubscribe (): void {
    for (const sub in this.componentSub) {
      try {
        this.componentSub[sub].unsubscribe()
      } catch (error) {
        // no need to show log
      }
    }
  }

  dataIsLoading (): boolean {
    let SPLoading = false
    if (this.sharedService.showServicePackageSection()) {
      SPLoading = this.loadingServicePackage
    }
    let insuranceLoading = false
    if (this.hasInsurance === true) {
      insuranceLoading = this.loadingInsurance
    }

    return (SPLoading || insuranceLoading)
  }
}
