import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { SharedService } from '../../../../services/shared.service'
import { DataService } from '../../../../services/data.service'
import { InstantErrorStateMatcherService } from '../../../../services/instant-error-state-matcher.service'
import {
  DocumentType,
  BasicDocumentDTOExtended,
  VehicleDocumentsService,
  VehicleGalleryTypeEnum,
  VehicleDocumentTypeEnum
} from '../../../../services/vehicle-documents.service'
import {
  VehicleGalleryDTO,
  GalleryService
} from '../../../../services/gallery.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'

interface DialogData {
  document: BasicDocumentDTOExtended | VehicleGalleryDTO
  vehicle: VehicleDTOExtended
}

@Component({
  selector: 'customer-portal-app-revolution-vehicle-document-edit-popup',
  templateUrl: './vehicle-document-edit-popup.component.html'
})
export class RevolutionVehicleDocumentEditPopupComponent implements OnInit {
  matcher = new InstantErrorStateMatcherService()
  documentTypes: DocumentType[]
  documentEditFormGroup = new FormGroup({
    documentType: new FormControl<string | null>(null, Validators.required),
    name: new FormControl<string | null>(null, {
      validators: [Validators.required, Validators.maxLength(40)],
      updateOn: 'change'
    })
  })

  saving = false
  nameChanged = false

  galleryTypeArray = [
    'VEHICLE_IMAGE',
    'VEHICLE_PICTURE',
    'VEHICLE_DAMAGE',
    'REGISTRATION'
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly vehicleDocumentsService: VehicleDocumentsService,
    private readonly dialogRef: MatDialogRef<RevolutionVehicleDocumentEditPopupComponent>,
    private readonly dialog: MatDialog,
    private readonly dataService: DataService,
    private readonly sharedService: SharedService,
    private readonly galleryService: GalleryService
  ) {}

  onNameChanged(): void {
    this.nameChanged = true
  }

  setFormValues(): void {
    const values = {
      name: this.removeFileExtension(this.data.document.name),
      documentType: this.data.document.type
    }
    this.documentEditFormGroup.setValue(values)
  }

  ngOnInit(): void {
    this.setFormValues()
    this.vehicleDocumentsService.getDocumentTypes().subscribe(documentTypes => {
      this.documentTypes = documentTypes
    })
  }

  removeFileExtension(filename: string): string {
    return filename.replace(/\.[^/.]+$/, '')
  }

  onEditSubmit(): void {
    this.saving = true
    const name = this.removeFileExtension(
      this.documentEditFormGroup.get('name').value
    )
    const vin = this.data.vehicle.vin
    const documentId = this.data.document.id.toString()

    if (this.galleryTypeArray.includes(this.data.document.type)) {
      this.galleryUpdate(vin, documentId, name)
    } else {
      this.documentUpdate(vin, documentId, name, this.data.document.type)
    }
  }

  documentUpdate(
    vin: string,
    documentId: string,
    name: string,
    documentType: VehicleGalleryTypeEnum | VehicleDocumentTypeEnum
  ): void {
    this.vehicleDocumentsService
      .updateDocumentInfo(vin, documentId, name, documentType)
      .subscribe(
        () => {
          this.success()
        },
        () => {
          this.error()
        }
      )
  }

  galleryUpdate(vin: string, documentId: string, name: string): void {
    this.galleryService.updateGalleryInfo(vin, documentId, name).subscribe(
      () => {
        this.success()
      },
      () => {
        this.error()
      }
    )
  }

  success(): void {
    this.saving = false
    this.dialogRef.close(true)
    this.sharedService.openConfirmationPopup(
      'shared.successful',
      'customerPortal.customer-portal.vehicle.document-edit.success'
    )
    // Refresh documents
    const v = this.data.vehicle

    void this.dataService.loadVehicleData(v)
  }

  error(): void {
    this.saving = false
    this.dialogRef.close(false)
    this.sharedService.openConfirmationPopup(
      'shared.error',
      'customerPortal.customer-portal.vehicle.document-edit.failure'
    )
  }
}
