import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Environment } from '../environments'
import { ENVIRONMENT } from '../injection-tokens'

export interface Translation {
  id: number
  identifier: string
  type: string
  bezeichnungId?: any
  lastModified?: number
  en?: string
  de?: string
  fr?: string
  it?: string
  sr?: string
  bs?: string
  me?: string
  sq?: string
  hr?: string
  hu?: string
  sl?: string
  cs?: string
  pl?: string
  sk?: string
  description?: string
  status?: number
}

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  constructor (
    @Inject(ENVIRONMENT) private readonly environment: Environment,
    private readonly http: HttpClient
  ) {
    this.apiUrl = this.environment.baseUrl
  }

  private readonly apiUrl

  public get (type: string, language?: string): Observable<Translation[]> {
    return this.http.get<Translation[]>(`${this.apiUrl}/translations/all`, {
      params: {
        type,
        language
      }
    })
  }
}
