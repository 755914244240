import {
  Component,
  Inject,
  OnInit
} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BasicDocumentDTOExtended, SaleContractFeedbackDTO, VehicleDocumentsService } from '../../../../services/vehicle-documents.service'
import {
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from '../../../../services/shared.service'

interface DialogData {
  offer: BasicDocumentDTOExtended
  action: 'accept' | 'decline'
}
@Component({
  selector: 'customer-portal-app-accept-decline-offer-popup',
  templateUrl: './accept-decline-offer-popup.component.html'
})
export class AcceptDeclineOfferPopupComponent implements OnInit {
  saveButtonColor = 'secondary'
  saving = false

  maxLength = 512 // max length in mail comment

  form = new FormGroup({
    comment: new FormControl<string | null>(null, [
      Validators.maxLength(this.maxLength)
    ])
  })

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AcceptDeclineOfferPopupComponent>,
    public sharedService: SharedService,
    private readonly vehicleDocumentsService: VehicleDocumentsService,
    private readonly logger: NGXLogger

  ) {}

  onActionConfirm (): void {
    this.saving = true

    const body: SaleContractFeedbackDTO = {
      id: Number(this.data.offer.id), // its number in interface
      comment: this.form.controls.comment.value,
      mailOfferState: this.data.action === 'accept' ? 'ACCEPTED' : 'DECLINED'
    }
    this.vehicleDocumentsService.acceptDeclineOffer(body).subscribe({
      next: data => {
        this.dialogRef.close({ success: true })
      },
      error: error => {
        this.logger.log(error)
        this.dialogRef.close({ success: false })
      }
    })
  }

  ngOnInit (): void {
    this.saveButtonColor = this.data.action === 'accept' ? 'secondary' : 'warn'
  }
}
