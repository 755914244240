<div data-cy="appointment-popup"
  class="cpt-modal revolution-dialog revolution-stepper relative dialog-content-p-0 mat-dialog-actions-pb-20 mat-divider-p-0 ef-scrollbar">
  <button class="dialog-close-button" [mat-dialog-close]="false">
    <mat-icon svgIcon="close"></mat-icon>
  </button>

  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <ng-container *ngIf="isServiceAction()">
      <T str="Service actions" key="customerPortal.customer-portal.top-bar.service-action.title"
        tags="customer-portal, 3.1"></T>
    </ng-container>
    <ng-container *ngIf="!isServiceAction()">
      <T str="New appointment" key="customerPortal.customer-portal.top-bar.appointment" tags="customer-portal, 3.1"></T>
    </ng-container>
  </h2>
  <mat-dialog-content class="p-0 ml-2.5">
    <mat-horizontal-stepper labelPosition="bottom" #stepper [class]="
        'selectedIndex' +
        stepper.selectedIndex +
        ' ' +
        'mat-stepper-label-position-bottom no-padding w-full h-full'
      ">
      <ng-container>
        <ng-template matStepperIcon="number" let-index="index">
          <div *ngIf="stepper.selectedIndex > index; else defaultStepper" class="flex justify-center items-center">
            <mat-icon svgIcon="done"></mat-icon>
          </div>
          <ng-template #defaultStepper>
            {{ index + 1 }}
          </ng-template>
        </ng-template>
      </ng-container>

      <!--      first step-->
      <mat-step [editable]="!isServiceAction()" [stepControl]="appointmentsPopupService.firstFormGroup" state="home"
        [completed]="stepper.selectedIndex > 0" [aria-labelledby]="stepper.selectedIndex > 0 ? 'done' : ''">
        <ng-template matStepLabel>
          <T str="Vehicle" key="customerPortal.customer-portal.appointments.vehicle" tags="customer-portal, 3.1"></T>
        </ng-template>
        <customer-portal-app-appointments-popup-first-form class="max-w-full max-h-full flex flex-col"
          [appointmentsPopupService]="appointmentsPopupService" [stepper]="stepper">
        </customer-portal-app-appointments-popup-first-form>
        <!-- First Form -->
      </mat-step>

      <!--  second step  ---  Services-->

      <mat-step [stepControl]="appointmentsPopupService.secondFormGroup" state="home"
        [completed]="stepper.selectedIndex > 1" [aria-labelledby]="stepper.selectedIndex > 1 ? 'done' : ''">
        <ng-template matStepLabel>
          <T str="Services" key="customerPortal.customer-portal.appointments.services" tags="customer-portal, 3.1"></T>
        </ng-template>
        <customer-portal-app-appointments-popup-second-form class="max-w-full max-h-full flex flex-col"
          [appointmentsPopupService]="appointmentsPopupService"
          [stepper]="stepper"></customer-portal-app-appointments-popup-second-form>
      </mat-step>

      <!--  third step ---  Location and time-->

      <mat-step state="home" [completed]="stepper.selectedIndex > 2"
        [aria-labelledby]="stepper.selectedIndex > 2 ? 'done' : ''">
        <ng-template matStepLabel>
          <T str="Location and date" key="customerPortal.customer-portal.appointments.location-and-time"
            tags="customer-portal, 3.1"></T>
        </ng-template>
        <customer-portal-app-appointments-popup-third-form class="max-w-full max-h-full flex flex-col"
          [appointmentsPopupService]="appointmentsPopupService"></customer-portal-app-appointments-popup-third-form>
      </mat-step>

      <!--  fourth step ---  Summary-->
      <mat-step state="home" [completed]="stepper.selectedIndex === 3"
        [aria-labelledby]="stepper.selectedIndex === 3 ? 'done' : ''">
        <ng-template matStepLabel>
          <T str="Summary" key="customerPortal.customer-portal.appointments.summary" tags="customer-portal, 3.1"></T>
        </ng-template>
        <customer-portal-app-appointments-popup-fourth-form class="max-w-full max-h-full flex flex-col"
          [appointmentsPopupService]="appointmentsPopupService" [dialogRef]="dialogRef">
        </customer-portal-app-appointments-popup-fourth-form>
      </mat-step>

      <ng-template matStepperIcon="done">
        <mat-icon svgIcon="bulletpoint"></mat-icon>
      </ng-template>
      <ng-template matStepperIcon="edit">
        <mat-icon svgIcon="bulletpoint"></mat-icon>
      </ng-template>
      <ng-template matStepperIcon="home">
        <mat-icon svgIcon="bulletpoint"></mat-icon>
      </ng-template>
    </mat-horizontal-stepper>
  </mat-dialog-content>
  <mat-divider class="divider hidden md:block p-0"></mat-divider>
  <mat-dialog-actions class="hidden md:block p-0" *ngIf="stepper.selectedIndex === 0">
    <ng-container *ngTemplateOutlet="firstActions"></ng-container>
  </mat-dialog-actions>
  <mat-dialog-actions class="hidden md:flex p-0" *ngIf="stepper.selectedIndex === 1">
    <ng-container *ngTemplateOutlet="secondActions"></ng-container>
  </mat-dialog-actions>

  <mat-dialog-actions class="hidden md:flex p-0" *ngIf="stepper.selectedIndex === 2">
    <ng-container *ngTemplateOutlet="thirdActions"></ng-container>
  </mat-dialog-actions>

  <mat-dialog-actions class="hidden md:flex p-0" *ngIf="stepper.selectedIndex === 3">
    <ng-container *ngTemplateOutlet="fourthActions"></ng-container>
  </mat-dialog-actions>
</div>

<!-- 1*************************************************** -->
<ng-template #firstActions>
  <button data-cy="add-new-appointment-next-1" class="next revolution w-full float-right md:w-auto" mat-button
    mat-flat-button color="secondary" type="button" [disabled]="
      !appointmentsPopupService.car ||
      appointmentsPopupService.otherVehicleFormIsInvalid() ||
      appointmentsPopupService.loadingCarData
    " (click)="
      appointmentsPopupService.showChooseStepDialog
        ? appointmentsPopupService.decideNextStep(stepper)
        : stepper.next();
      appointmentsPopupService.sendGTMData(2);
      appointmentsPopupService.checkSelected()
    ">
    <mat-spinner class="inline-block mr-2.5" [diameter]="16"
      *ngIf="appointmentsPopupService.loadingCarData"></mat-spinner>
    {{ 'next' | sharedTranslate }}
  </button>
</ng-template>

<!-- 2*************************************************** -->
<ng-template #secondActions>
  <button data-cy="add-new-appointment-back-2" [disabled]="isServiceAction()" class="back revolution w-full md:w-auto"
    mat-button mat-stroked-button color="secondary" (click)="stepper.previous()">
    {{ 'back' | sharedTranslate }}
  </button>
  <button data-cy="add-new-appointment-next-2"
    class="next revolution w-full md:w-auto" mat-button mat-flat-button color="secondary"
    (click)="stepper.next(); appointmentsPopupService.sendGTMData(3)" type="button" [disabled]="
      appointmentsPopupService.selectedServices.length === 0 ||
      appointmentsPopupService.secondFormGroup.invalid ||
      appointmentsPopupService.loadingCarData
    ">
    <mat-spinner class="inline-block mr-2.5" [diameter]="16"
      *ngIf="appointmentsPopupService.loadingCarData"></mat-spinner>
    {{ 'next' | sharedTranslate }}
  </button>
</ng-template>

<!-- 3*************************************************** -->
<ng-template #thirdActions>
  <button data-cy="add-new-appointment-back-3" class="back revolution w-full float-right md:w-auto" mat-button
    mat-stroked-button color="secondary" (click)="stepper.previous()">
    {{ 'back' | sharedTranslate }}
  </button>
  <button data-cy="add-new-appointment-next-3" class="next revolution w-full float-right md:w-auto" mat-button
    mat-flat-button color="secondary" type="button" [disabled]="
      appointmentsPopupService.thirdFormGroup.invalid ||
      appointmentsPopupService.loadingCarData
    " (click)="
      stepper.next();
      appointmentsPopupService.setAllData();
      appointmentsPopupService.sendGTMData(4)
    ">
    <mat-spinner class="inline-block mr-2.5" [diameter]="16"
      *ngIf="appointmentsPopupService.loadingCarData"></mat-spinner>
    {{ 'next' | sharedTranslate }}
  </button>
</ng-template>

<!-- 4*************************************************** -->
<ng-template #fourthActions>
  <button data-cy="add-new-appointment-back-4" class="back float-right revolution w-full md:w-auto" mat-button
    mat-stroked-button color="secondary" (click)="stepper.previous()">
    {{ 'back' | sharedTranslate }}
  </button>
  <button data-cy="add-new-appointment-submit" class="next float-right revolution w-full md:w-auto" mat-flat-button
    color="secondary" mat-button [disabled]="appointmentsPopupService.disabledSave"
    (click)="appointmentsPopupService.createAppointment(dialogRef)">
    <mat-spinner class="inline-block mr-2.5" [diameter]="16"
      *ngIf="appointmentsPopupService.disabledSave"></mat-spinner><span> {{ 'save' | sharedTranslate }}</span>
  </button>
</ng-template>