<div class="w-full h-full VehicleCardComponent" [ngClass]="mode">
  <mat-card
    attr.data-cy="vehicle-card-{{ vehicle.vin }}"
    *ngIf="vehicle"
    class="vehicle flex flex-col transition duration-300 ease-in-out !shadow-lg !rounded-lg h-full !p-0 cursor-pointer select-none"
    [ngClass]="{
      'shadow-md pb-2.5 -mt-2.5 border-2 border-mat-ef-revolution-secondary-500':
        vin === vehicle.vin && mode !== 'summary'
    }"
    (click)="sharedService.preventClick || goToVehicle(vehicle)"
    (mouseup)="sharedService.handleGliderEvents($event, vehicle.vin)"
    (mousedown)="sharedService.handleGliderEvents($event, vehicle.vin)"
  >
    <div
      class="image-wrapper relative h-[192px] justify-center bg-mat-ef-revolution-grey-500 rounded-t-lg flex content-center items-center"
    >
      <div
        *ngIf="
          vehicleTrackingHistory?.status &&
          vehicleTrackingHistory.status !== 'registered'
        "
        class="vehicle-card-status"
      >
        <div
          class="text font-bold text-center uppercase text-sm font-efSans tracking-[2px]"
        >
          <T
            str="Delivery"
            key="customerPortal.customer-portal.vehicle.tracking.delivery"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </div>

      <img
        class="w-auto h-auto max-w-[calc(100%_-_62px)] max-h-[calc(100%_-_62px)] object-contain rounded m-auto"
        *ngIf="vehicle.brandPicture"
        src="{{ vehicle.brandPicture }}"
        alt="brand image"
      />
      <img
        alt="ef logo"
        class="max-w-[calc(100%_-_62px)] max-h-[calc(100%_-_62px)] rounded-lg object-cover m-auto w-24 h-24"
        *ngIf="!vehicle.brandPicture"
        src="assets/ef-logo.svg"
      />

      <div *ngIf="vehicle.business" class="vehicle-card-status">
        <div
          class="text font-bold text-center uppercase text-sm font-efSans tracking-[2px]"
        >
          <T
            str="company vehicle"
            key="customerPortal.customer-portal.company-vehicle"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </div>
    </div>

    <div class="content flex-1 flex flex-col p-4 pb-9 rounded-b-lg bg-light-bg">
      <div class="w-full h-full flex-1">
        <div class="font-efSans-primary !text-lg whitespace-normal font-black">
          {{ vehicle.brand ? vehicle.brand : '' }}
          {{ vehicle.model ? vehicle.model : '' }}
        </div>
        <div
          *ngIf="vehicle.nickname"
          class="font-efSans-primary !text-sm whitespace-normal font-bold"
        >
          {{ vehicle.nickname }}
        </div>
        <div
          *ngIf="
            vehicle.createdByDataSource === 'customer_portal' && lang && user
          "
          class="font-efSans-primary !text-xs whitespace-normal"
        >
          <div>
            <T
              str="Added by"
              key="customerPortal.customer-portal.vehicle-list.addedBy"
              tags="customer-portal, 3.1"
            ></T>
            {{ user | username | async }}
          </div>

          <div *ngIf="vehicle.dateCreated">
            <T
              str="Added on"
              key="customerPortal.customer-portal.vehicle-list.addedOn"
              tags="customer-portal, 3.1"
            ></T>
            {{ vehicle.dateCreated | date: dateFormat }}
          </div>
        </div>

        <div
          *ngIf="
            vehicle.licensePlate &&
            sharedService.stringExists(vehicle.licensePlate)
          "
          class="w-fit mt-4 px-2 py-1 font-efSans font-semibold text-mat-ef-revolution-secondary-500 !text-sm rounded-[3px] border border-mat-ef-revolution-supergrey-500"
        >
          <div class="w-fit" *ngIf="showMask">
            {{
              vehicle.licensePlate
                ? (vehicle.licensePlate | mask: licensePlateMask)
                : '-'
            }}
          </div>
          <div class="w-fit" *ngIf="!showMask">
            {{ vehicle.licensePlate || '-' }}
          </div>
        </div>

        <div
          *ngIf="
            vehicleTrackingHistory?.status &&
            vehicleTrackingHistory.status !== 'registered'
          "
          class="w-fit mt-4 px-2 py-1 font-efSans font-semibold text-mat-ef-revolution-secondary-500 !text-sm rounded-[3px] border border-mat-ef-revolution-supergrey-500"
        >
          <div class="w-fit">
            <!-- prettier-ignore -->
            {{ vehicleTrackingHistory.status | translate:{ _key: 'customerPortal.customer-portal.vehicle.tracking.delivery-status.'+ vehicleTrackingHistory.status, _tags: 'customer-portal, notranslate'} }}
          </div>
        </div>

        <div
          class="w-full mt-4"
          *ngIf="vehicleNotifications && vehicleNotifications[vehicle.vin]"
        >
          <div
            class="w-full"
            *ngFor="
              let notification of vehicleNotifications[vehicle.vin];
              let first = first
            "
          >
            <div
              class="w-full text-sm font-efSans-primary"
              [ngClass]="{ 'pt-2': !first }"
              *ngIf="showNotification(notification.scope)"
            >
              <customer-portal-app-vehicle-warning-indicator
                [notification]="notification"
              >
              </customer-portal-app-vehicle-warning-indicator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card
    attr.data-cy="vehicle-card-tnt-{{ (vehicleTNT?.vin || vehicleTNT.commissionNumber) }}"
    *ngIf="vehicleTNT"
    class="vehicle flex flex-col transition duration-300 ease-in-out !shadow-lg !rounded-lg h-full !p-0 cursor-pointer select-none"
    [ngClass]="{
      'shadow-md pb-2.5 -mt-2.5 border-2 border-mat-ef-revolution-secondary-500':
        commissionNumber === vehicleTNT.commissionNumber && mode !== 'summary'
    }"
    (click)="sharedService.preventClick || goToVehicleTNT(vehicleTNT)"
    (mouseup)="
      sharedService.handleGliderEvents($event, vehicleTNT.commissionNumber)
    "
    (mousedown)="
      sharedService.handleGliderEvents($event, vehicleTNT.commissionNumber)
    "
  >
    <div
      class="image-wrapper relative h-[192px] bg-mat-ef-revolution-grey-500 rounded-t-lg content-center items-center flex justify-center"
    >
      <div class="pt-8">
        <div
          *ngIf="vehicleTNT.status"
          class="w-[100px] h-[100px] rounded-full bg-mat-ef-revolution-grey-900 p-4"
        >
          <mat-icon
            class="w-full h-full fill-mat-ef-revolution-primary-500"
            [svgIcon]="'track_trace_' + vehicleTNT.status + '_v1'"
          >
          </mat-icon>
        </div>

        <div
          *ngIf="!vehicleTNT.status"
          class="w-[100px] h-[100px] rounded-full p-2"
        >
          <mat-icon
            class="w-full h-full fill-mat-ef-revolution-primary-500"
            svgIcon="ef-logo-icon"
          >
          </mat-icon>
        </div>
      </div>

      <div class="vehicle-card-status">
        <div
          class="text font-bold text-center uppercase text-sm font-efSans tracking-[2px]"
        >
          <T
            str="Delivery"
            key="customerPortal.customer-portal.vehicle.tracking.delivery"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </div>
    </div>

    <div class="content flex-1 flex flex-col p-4 pb-9 rounded-b-lg bg-light-bg vehicle-card-info">
      <div class="w-full h-full flex-1">
        <div class="font-efSans-primary !text-lg whitespace-normal font-black">
          {{ vehicleTNT.brand ? vehicleTNT.brand : '' }}
          {{ vehicleTNT.model ? vehicleTNT.model : '' }}
        </div>

        <div
          *ngIf="vehicleTNT?.status"
          class="w-fit mt-4 px-2 py-1 font-efSans font-semibold text-mat-ef-revolution-secondary-500 !text-sm rounded-[3px] border border-mat-ef-revolution-supergrey-500"
        >
          <div class="w-fit vehicle-card-delivery-status">
            <!-- prettier-ignore -->
            {{ vehicleTNT.status | translate:{ _key: 'customerPortal.customer-portal.vehicle.tracking.delivery-status.'+ vehicleTNT.status, _tags: 'customer-portal, notranslate'} }}
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>

<!-- [attr.data-cy]="vehicle.vin" -->
