<div
  data-cy="edit-telephone-form"
  class="cpt-modal relative dialog-content-p-0 mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="Change private landline"
      key="customerPortal.customer-portal.details.change.telephone-personal-landline"
      tags="customer-portal, 3.1"
    ></T>
  </h2>
  <mat-dialog-content
    class="mat-typography md:max-h-[calc(65vh_-_40px)] overflow-x-hidden"
  >
    <div [ngClass]="{ hidden: step !== 'change' }">
      <form [formGroup]="changeForm" class="gap-3 pt-2 grid grid-cols-5">
        <mat-form-field
          class="hidden md:inline-block col-span-5 md:col-span-1"
          appearance="legacy"
        >
          <mat-label>{{ 'prefix' | sharedTranslate }} </mat-label>
          <mat-select
            data-cy="edit-telephone-prefix-select"
            formControlName="mobilePrefix"
            required
          >
            <mat-option
              *ngFor="let mobilePrefix of mobilePrefixes | keyvalue"
              [value]="'+' + mobilePrefix.value"
              >+{{ mobilePrefix.value }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="inline-block md:hidden col-span-5 md:col-span-1"
          appearance="legacy"
        >
          <mat-label>
            {{ 'prefix' | sharedTranslate }}
          </mat-label>
          <select matNativeControl formControlName="mobilePrefix" required>
            <option
              *ngFor="let mobilePrefix of mobilePrefixes | keyvalue"
              [value]="'+' + mobilePrefix.value"
            >
              +{{ mobilePrefix.value }}
            </option>
          </select>
        </mat-form-field>
        <mat-form-field class="col-span-5 md:col-span-4">
          <mat-label>
            <T
              str="Landline"
              key="customerPortal.customer-portal.details.personal.landline"
              tags="customer-portal, 3.1"
            ></T>
          </mat-label>
          <input
            data-cy="edit-telephone-number-input"
            *ngIf="phoneMask"
            class="input"
            [mask]="phoneMask"
            [maxLength]="maxLength"
            matInput
            type="tel"
            required
            formControlName="mobile"
            [errorStateMatcher]="matcher"
          />
          <input
            data-cy="edit-telephone-number-input"
            *ngIf="!phoneMask"
            class="input"
            [maxLength]="maxLength"
            matInput
            type="tel"
            required
            formControlName="mobile"
            [errorStateMatcher]="matcher"
          />
          <mat-icon
            *ngIf="
              changeForm.controls.mobile.touched &&
              changeForm.controls.mobile.invalid
            "
            matSuffix
            class="error"
            svgIcon="error_outline"
          ></mat-icon>
          <mat-error
            *ngIf="
              changeForm.controls.mobile.invalid &&
              changeForm.controls.mobile.errors
            "
          >
            {{
              transifexService.getErrorTranslation(
                changeForm.controls.mobile,
                'customerPortal.customer-portal.details.personal.mobile.error.'
              )
            }}
          </mat-error>
        </mat-form-field>
      </form>
    </div>
    <div class="pb-5" [ngClass]="{ hidden: step !== 'done' || error }">
      <T
        str="Phone number was updated successfully."
        key="customerPortal.customer-portal.details.change.telChanged-personal-landline"
        tags="customer-portal, 3.1"
      ></T>
    </div>
    <div class="pb-5" [ngClass]="{ hidden: !error }">
      {{ 'general-error-message' | sharedTranslate }}
    </div>
  </mat-dialog-content>
  <mat-divider class="mt-0"></mat-divider>
  <mat-dialog-actions>
    <ng-container>
      <button
        *ngIf="step === 'change' || step === 'verify'"
        class="dialog-close-button"
        [mat-dialog-close]="false"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </button>
      <button
        *ngIf="step === 'done'"
        class="dialog-close-button"
        (click)="close()"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </button>

      <button
        *ngIf="step === 'done'"
        mat-button
        mat-flat-button
        color="secondary"
        class="revolution btn-dialog"
        (click)="close()"
        cdkFocusInitial
      >
        <T
          str="Close"
          key="customerPortal.customer-portal.general.actions.close"
          tags="customer-portal, 3.1"
        ></T>
      </button>

      <button
        data-cy="edit-telephone-submit"
        *ngIf="step === 'change'"
        mat-button
        mat-flat-button
        color="secondary"
        class="revolution btn-dialog"
        (click)="changeNumber()"
        cdkFocusInitial
        [disabled]="!changeForm.valid || loading"
      >
        <mat-spinner
          class="inline-block mr-2.5"
          *ngIf="loading"
          diameter="20"
        ></mat-spinner>
        {{ 'save' | sharedTranslate }}
      </button>
    </ng-container>
  </mat-dialog-actions>
</div>
