<div
  id="hero"
  class="w-full bg-cover min-h-[420px] lg:mx-auto flex flex-col gap-6 justify-center items-center pb-[200px]"
  [ngClass]="{ 'min-h-[480px] md:min-h-[420px]': hasSlogan }"
>
  <span
    *ngIf="user && lang"
    class="p-4 md:pt-10 right-10 font-canelablack text-4xl md:text-6xl font-black flex flex-col text-center text-white"
  >
    <span>
      {{
        {
          str: 'Welcome to',
          key: welcomeTranslateKey
        } | transifexTranslate
      }}
      <span class="whitespace-nowrap">Emil Frey,</span>
    </span>
    <div>
      {{ user | username | async }}
    </div>

    <span
      *ngIf="hasSlogan"
      class="pt-4 text-lg md:text-xl font-semibold text-center text-white"
    >
      <!-- prettier-ignore -->
      {{ 'Slogan' | translate:{ _key: 'customerPortal.kc.' + sloganTranslation, _tags: 'customer-portal, notranslate'} }}
    </span>
  </span>
</div>
