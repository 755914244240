import { registerLocaleData } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import localeDe from '@angular/common/locales/de'
import localeCh from '@angular/common/locales/de-CH'
import localeFr from '@angular/common/locales/fr-CH'
import localeIt from '@angular/common/locales/it-CH'
import { Injectable } from '@angular/core'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { TranslateService } from '@ngx-translate/core'
import { type Observable, combineLatest } from 'rxjs'
import { DataService } from './data.service'

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private readonly apiUrl
  token: string
  combineLatestSub

  supportedLanguage
  constructor (
    private readonly http: HttpClient,
    private readonly translate: TranslateService,
    private readonly transifexService: TransifexService,
    private readonly dataService: DataService,
    private readonly keyCloakService: KeycloakService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
    this.supportedLanguage = this.remoteConfigService.get('b2c.supportedLanguages')
  }

  getLanguages (): Observable<string[]> {
    return this.http.get<string[]>(this.apiUrl + '/languages')
  }

  getLink (linkObj, primaryLang: string): string {
    if (
      this.checkIfExists(primaryLang) &&
      this.checkIfExists(linkObj['link_' + primaryLang.toLowerCase()])
    ) {
      return linkObj['link_' + primaryLang.toLowerCase()]
    }
    return linkObj.link != null ? linkObj.link : ''
  }

  checkIfExists (text: string): boolean {
    if (text != null && text.trim() !== '') {
      return true
    }
    return false
  }

  setupLanguage (): void {
    registerLocaleData(localeCh, 'de-CH')
    registerLocaleData(localeIt, 'it-CH')
    registerLocaleData(localeFr, 'fr-CH')
    registerLocaleData(localeDe, 'de-DE')

    const supportedLanguages = []
    this.supportedLanguage.forEach(lang => {
      supportedLanguages.push(lang.toLowerCase())
    })
    this.translate.addLangs(supportedLanguages)
    this.translate.setDefaultLang(
      this.remoteConfigService.get('b2c.defaultLanguage')
    )
    this.translate.currentLang = this.remoteConfigService.get(
      'b2c.defaultLanguage'
    )

    this.transifexService.initializeTransifex(
      this.remoteConfigService.get('transifexConfig.token')
    )
  }

  setTransifexLanguage (): void {
    // First, set default to langugae from keycloak token to fasten up language loading
    this.transifexService.onTransifexInitialized.subscribe(() => {
      const defaultLang = this.keyCloakService.getKeycloakInstance().tokenParsed
        ?.locale
        ? this.keyCloakService.getKeycloakInstance().tokenParsed.locale
        : 'de'
      this.transifexService.setLanguage(
        defaultLang,
        this.remoteConfigService.getArrayValues(
          'transifexConfig.transifexLanguages'
        ),
        this.remoteConfigService.get('country.code')
      )
    })

    // Then wait for user and change it's language if necessary
    this.combineLatestSub = combineLatest([
      this.dataService.onUserLoaded,
      this.transifexService.onTransifexInitialized
    ]).subscribe(res => {
      const user = res[0]
      const transifex = res[1]

      if (user != null && transifex) {
        const lang =
          user.language != null
            ? user.language.find(s => s.primary).language
            : 'de'
        this.transifexService.setLanguage(
          lang,
          this.remoteConfigService.get('transifexConfig.transifexLanguages'),
          this.remoteConfigService.get('country.code')
        )
      }
    })
  }
}
