<div
  class="cpt-modal ConfirmationPopupComponent-dialog relative dialog-content-p-0 mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    {{ translatedTitle }}
  </h2>

  <mat-dialog-content class="mat-typography" *ngIf="!data?.useInnerHtml">
    {{ translatedText }}
  </mat-dialog-content>
  <mat-dialog-content
    class="mat-typography"
    *ngIf="data?.useInnerHtml"
    [innerHTML]="translatedText"
  >
  </mat-dialog-content>
  <mat-divider></mat-divider>

  <button class="dialog-close-button" [mat-dialog-close]="false">
    <mat-icon svgIcon="close"></mat-icon>
  </button>

  <mat-dialog-actions>
    <button
      data-cy="confirmation-popup-button"
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      [mat-dialog-close]="true"
      cdkFocusInitial
    >
      <T
        str="Close"
        key="customerPortal.customer-portal.general.actions.close"
        tags="customer-portal, 3.1"
      ></T>
    </button>
  </mat-dialog-actions>
</div>
