import { Component, OnInit, Input } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import {
  EquipmentDTO,
  VehicleDTOExtended
} from '../../../../services/vehicles.service'

@Component({
  selector: 'customer-portal-app-revolution-vehicle-equipment',
  templateUrl: './vehicle-equipment.component.html'
})
export class VehicleEquipmentComponent implements OnInit {
  user: User
  basicEquipment: EquipmentDTO[] = []
  additionalEquipment: EquipmentDTO[] = []
  vehicleDetailed: VehicleDTOExtended

  // serviceKm: number

  sub = {
    onLanguageChangeSub: null,
    onVehicleDetailsLoadedSub: null,
    onVehicleFrequentDriverLoadedSub: null
  }

  @Input()
  public loading: boolean

  licensePlateMask
  constructor(
    public dialog: MatDialog,
    private readonly dataService: DataService,
    private readonly logger: NGXLogger
  ) {
    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
    })
  }

  @Input()
  public vehicle: VehicleDTOExtended

  ngOnInit(): void {
    this.sub.onVehicleDetailsLoadedSub =
      this.dataService.onVehicleDetailsLoaded.subscribe(vehicleDetails => {
        this.vehicleDetailed = vehicleDetails
        this.basicEquipment = []
        this.additionalEquipment = []
        if (this.vehicleDetailed?.details?.equipmentDetails?.[0] != null) {
          this.basicEquipment =
            this.vehicleDetailed?.details.equipmentDetails.filter(el => {
              return el.type === 'S'
            })
          if (this.basicEquipment == null) {
            this.basicEquipment = []
          }

          this.additionalEquipment =
            this.vehicleDetailed?.details.equipmentDetails.filter(el => {
              return el.type !== 'S'
            })
          if (this.additionalEquipment == null) {
            this.additionalEquipment = []
          }
        }

        if (
          vehicleDetails != null &&
          vehicleDetails?.vin === this.vehicle?.vin
        ) {
          // need to update main vehicle propertys
          Object.keys(vehicleDetails).forEach(key => {
            this.vehicle[key] = vehicleDetails[key]
          })
        }
      })
  }
}
