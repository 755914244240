import { Component, Inject, OnInit, OnDestroy } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'
import { SharedService } from '../../../services/shared.service'

export interface ConfirmationPopupDialogData {
  title: string
  subtitle?: string
  text: string
  useInnerHtml?: boolean
  skipTranslate?: boolean
}

@Component({
  selector: 'customer-portal-app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html'
})
export class ConfirmationPopupComponent implements OnInit, OnDestroy {
  translatedTitle = ''
  translatedText = ''

  sub = {
    onTransifexLanguageChange: null
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationPopupDialogData,
    private readonly dialogRef: MatDialogRef<ConfirmationPopupComponent>,
    private readonly sharedService: SharedService,
    private readonly transifexTranslationsService: TranslationService,
    private readonly transifexService: TransifexService
  ) {}

  ngOnInit(): void {
    this.sub.onTransifexLanguageChange =
      this.transifexService.onTransifexLanguageChange.subscribe(() => {
        this.refreshLanguage()
      })
  }

  refreshLanguage(): void {
    this.translatedTitle = this.transifexService.getTranslation({
      key: this.data.title
    })

    if (this.data.skipTranslate === true) {
      this.translatedText = this.data.text
    } else {
      this.translatedText = this.transifexService.getTranslation({
        key: this.data.text
      })
    }
  }

  ngOnDestroy(): void {
    this.sharedService.unsubscribe(this.sub)
  }
}
