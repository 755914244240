<div class="content">
  <div class="content-container">
    <ng-container *ngIf="view === 'favorites'">
      <div class="flex flex-col lg:flex-row items-center gap-2 md:gap-6">
        <button
          (click)="openMarketplace()"
          class="revolution w-full lg:w-fit"
          mat-button
          mat-stroked-button
          color="secondary"
        >
          <T
            str="To the car market"
            key="customerPortal.customer-portal.marketplace.toMarketplace"
            tags="customer-portal, 3.1"
          ></T>
          <mat-icon svgIcon="arrow_right"></mat-icon>
        </button>
        <button
          class="compare"
          (click)="compareVehicles()"
          class="revolution w-full lg:w-fit"
          mat-button
          mat-flat-button
          color="secondary"
          [disabled]="comparableFavorites.length < 2"
        >
          <T
            str="Compare"
            key="customerPortal.customer-portal.marketplace.compare"
            tags="customer-portal, 3.1"
          ></T>
          <span>&nbsp;({{ comparableFavorites.length }})</span>
        </button>
      </div>

      <ng-container *ngIf="!hasFavorites()">
        <div class="have-no-card mt-4">
          <div class="text-mat-ef-revolution-supergrey-900">
            <mat-icon svgIcon="no_sim"></mat-icon>
          </div>
          <div class="text-base font-efSans-primary p-6 text-center">
            <T
              str="You have no favourites. Please go to My Marketplace and mark at least one vehicle as a favourite to be shown here."
              key="customerPortal.customer-portal.marketplace.favorites.none"
              tags="customer-portal, 3.9"
            ></T>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="view === 'compare'">
      <div class="flex flex-col lg:flex-row items-center gap-6">
        <button
          (click)="openMarketplace()"
          class="revolution w-full lg:w-fit"
          mat-button
          mat-stroked-button
          color="secondary"
        >
          <T
            str="To the car market"
            key="customerPortal.customer-portal.marketplace.toMarketplace"
            tags="customer-portal, 3.1"
          ></T>
          <mat-icon svgIcon="arrow_right"></mat-icon>
        </button>
        <button
          class="compare"
          (click)="toFavorites()"
          class="revolution w-full lg:w-fit"
          mat-button
          mat-flat-button
          color="secondary"
        >
          {{ 'back' | sharedTranslate }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="view === 'favorites'">
      <div
        class="RevolutionMarketplaceFavoritesComponent w-full relative bg-white"
      >
        <div class="container px-4">
          <div class="w-full pt-10 px-0">
            <div class="w-full flex gap-6 relative justify-center">
              <div *ngIf="hasFavorites()" class="w-full md:w-9/12">
                <div class="h-full w-full" *ngIf="favorites?.length">
                  <ngx-glide
                    #RevolutionMarketplaceFavoritesComponent_ngxGlide
                    [arrowLeftTemplate]="left"
                    [arrowRightTemplate]="right"
                    [startAt]="startAt || 0"
                    [gap]="0"
                    [breakpoints]="breakpoints"
                    (ranAfter)="true || afterRan()"
                    [listenToEvents]="false"
                    [peek]="{ before: 0, after: 70 }"
                    (resized)="recreate()"
                    [showBullets]="showBullets"
                    [showArrows]="showArrows"
                    [bound]="bound"
                    [ngClass]="{
                      showBullets: showBullets,
                      fitGlider: fitGlider
                    }"
                  >
                    <div
                      class="flex flex-col px-3 py-0 mb-4 h-auto min-w-[300px] max-w-[360px]"
                      *ngFor="let favorite of favorites; let i = index"
                    >
                      <div class="w-full h-full">
                        <!-- (touchstart) is used to prevent switching tabs on mobile  -->
                        <mat-card
                          class="vehicle flex flex-col transition duration-300 ease-in-out !shadow-lg !rounded-lg h-full !p-0 cursor-pointer select-none"
                          (mouseup)="
                            sharedService.handleGliderEvents(
                              $event,
                              favorite?.vehicleData?.vin
                            )
                          "
                          (mousedown)="
                            sharedService.handleGliderEvents(
                              $event,
                              favorite?.vehicleData?.vin
                            )
                          "
                          (touchstart)="
                            sharedService.handleGliderEvents(
                              $event,
                              favorite?.vehicleData?.vin
                            )
                          "
                        >
                          <div
                            class="relative h-[192px] bg-mat-ef-revolution-grey-500 rounded-t-lg flex content-center items-center"
                          >
                            <img
                              alt="vehicle img"
                              class="rounded-t-lg max-w-[calc(100%_-_40px)] max-h-[calc(100%_-_40px)] object-contain m-auto w-auto h-auto"
                              #img
                              [src]="createImageUrl(favorite)"
                              (error)="
                                img.src =
                                  '../../../../assets/images/Placeholder.svg';
                                img.classList.add('mat-card-image-placeholder')
                              "
                            />

                            <div
                              class="flex items-center absolute bottom-2 inset-x-0 justify-end gap-3 mr-2.5"
                            >
                              <!-- prettier-ignore -->
                              <button
                              matTooltip="{{ 'Compare' | translate:{ _key: 'customerPortal.customer-portal.marketplace.compare', _tags: 'customer-portal, 3.1'} }}"
                              mat-mini-fab
                              class="revolution"
                              color="secondary"
                              (click)="addToCompare(favorite)"
                            >
                              <mat-icon svgIcon="car_compare"></mat-icon>
                            </button>
                              <!-- prettier-ignore -->
                              <button
                              matTooltip="{{ 'To the vehicle' | translate:{ _key: 'customerPortal.customer-portal.marketplace.tovehicle', _tags: 'customer-portal, 3.1'} }}"
                              mat-mini-fab
                              class="revolution"
                              color="secondary"
                              (click)="goToVehicle(favorite?.vehicleData)"
                            >
                              <mat-icon svgIcon="vehicle"></mat-icon>
                            </button>
                            </div>
                          </div>

                          <div
                            class="content flex-1 flex flex-col p-4 rounded-b-lg bg-light-bg"
                          >
                            <div class="w-full h-full flex-1">
                              <div
                                class="absolute !top-2 !left-2 text-mat-ef-revolution-green-one-500"
                                *ngIf="favorite?.selectedForCompare"
                              >
                                <mat-icon svgIcon="check_circle"></mat-icon>
                              </div>
                              <div
                                class="absolute !top-2 !right-2 cursor-pointer text-mat-ef-revolution-primary-500"
                                (click)="unsubscribeFavorite(favorite)"
                              >
                                <mat-icon svgIcon="favorite"></mat-icon>
                              </div>

                              <ng-container
                                *ngIf="
                                  favorite?.vehicleData?.energyEfficiencyClass
                                "
                              >
                                <div
                                  class="badge right-0 uppercase rounded-none absolute text-sm font-bold py-1 px-2.5 w-14 flex justify-end energy-efficiency-class arrow-bg"
                                  [ngClass]="
                                    efficiencyClassArrowColor(
                                      favorite?.vehicleData
                                        ?.energyEfficiencyClass
                                    )
                                  "
                                >
                                  <div class="text-white">
                                    {{
                                      favorite?.vehicleData
                                        ?.energyEfficiencyClass
                                    }}
                                  </div>
                                </div>
                              </ng-container>

                              <div class="flex flex-col justify-between h-full">
                                <div class="flex flex-col flex-1">
                                  <div class="min-h-[120px]">
                                    <div
                                      class="font-efSans-primary !text-xl whitespace-normal font-black"
                                    >
                                      {{
                                        favorite?.vehicleData?.manufacturerName
                                      }}
                                    </div>
                                    <div
                                      class="font-efSans-primary !text-lg whitespace-normal font-black"
                                    >
                                      {{ favorite?.vehicleData?.inseratTitle }}
                                    </div>
                                  </div>
                                  <div class="mt-0">
                                    <div
                                      class="grid grid-cols-1 my-6 gap-2 mx-0"
                                    >
                                      <div class="flex flex-col items-center">
                                        <mat-icon
                                          class="text-mat-ef-revolution-primary-500"
                                          svgIcon="kilometers"
                                        ></mat-icon>
                                        <span
                                          class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                                          >{{
                                            favorite?.vehicleData?.currentMiles
                                          }}&nbsp;km</span
                                        >
                                      </div>
                                      <div class="flex flex-col items-center">
                                        <mat-icon
                                          class="text-mat-ef-revolution-primary-500"
                                          svgIcon="calendar_today"
                                        >
                                        </mat-icon>
                                        <span
                                          class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                                          >{{
                                            formatInitialRegistrationDate(
                                              favorite?.vehicleData
                                                ?.initialRegistration
                                            )
                                          }}</span
                                        >
                                      </div>
                                      <div class="flex flex-col items-center">
                                        <mat-icon
                                          class="text-mat-ef-revolution-primary-500"
                                          svgIcon="engine"
                                        ></mat-icon>
                                        <span
                                          class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                                          >{{
                                            formatPower(favorite?.vehicleData)
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                    <div>
                                      <mat-divider
                                        class="relative flex-1"
                                      ></mat-divider>
                                    </div>
                                    <div
                                      class="grid grid-cols-1 my-6 mx-0 second-row"
                                    >
                                      <div class="flex small justify-between">
                                        <span
                                          class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                                        >
                                          <T
                                            str="Gearbox type"
                                            key="customerPortal.customer-portal.marketplace.favorites.gearboxtype"
                                            tags="customer-portal, 3.1"
                                          ></T
                                        ></span>
                                        <span
                                          class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                                          >{{
                                            favorite?.vehicleData?.gearboxType
                                          }}</span
                                        >
                                      </div>

                                      <div
                                        class="flex small justify-between"
                                        *ngIf="
                                          favorite?.vehicleData?.consumption
                                        "
                                      >
                                        <span
                                          class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                                          >{{ units.consumption }}</span
                                        >
                                        <span
                                          class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                                          >{{
                                            replacedotWithComma(
                                              favorite?.vehicleData?.consumption
                                            )
                                          }}&nbsp;</span
                                        >
                                      </div>

                                      <div class="flex small justify-between">
                                        <span
                                          class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                                          >{{ units.co2 }}</span
                                        >
                                        <span
                                          class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                                          >{{
                                            favorite?.vehicleData?.coEmission
                                          }}&nbsp;</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="flex flex-col">
                                  <div
                                    class="font-efSans text-xs font-bold text-mat-ef-revolution-grey-two-500"
                                  >
                                    {{
                                      favorite?.vehicleData?.newPrice
                                        | currency
                                          : units.currency
                                          : 'symbol'
                                          : '1.0-2'
                                          : units.currencyLocale
                                    }}
                                    UVP
                                  </div>
                                  <div
                                    class="flex flex-1 justify-between items-center font-bold text-mat-ef-revolution-primary-500"
                                  >
                                    <div
                                      class="font-efSans text-2xl flex-1 text-start"
                                    >
                                      {{
                                        favorite?.vehicleData?.priceCustomer
                                          | currency
                                            : units.currency
                                            : 'symbol'
                                            : '1.0-2'
                                            : units.currencyLocale
                                      }}
                                    </div>
                                    <div
                                      class="font-efSans text-xl flex-1 text-end"
                                    >
                                      {{
                                        favorite?.vehicleData?.rate
                                          | currency
                                            : units.currency
                                            : 'symbol'
                                            : '1.2-2'
                                            : units.currencyLocale
                                      }}/m.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </mat-card>
                      </div>
                    </div>
                  </ngx-glide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-template #left>
        <div
          class="hidden leftArrow arrowTemplate sideArrows !left-0 md:flex justify-center items-center"
          (click)="
            sendContentModulesSliderData(
              'Left',
              'shared.my-marketplace',
              'Marketplace'
            )
          "
        >
          <mat-icon svgIcon="arrow_left"></mat-icon>
        </div>
      </ng-template>

      <ng-template #right>
        <div
          class="hidden rightArrow arrowTemplate sideArrows !right-0 md:flex justify-center items-center"
          (click)="
            sendContentModulesSliderData(
              'Right',
              'shared.my-marketplace',
              'Marketplace'
            )
          "
        >
          <mat-icon svgIcon="arrow_right"></mat-icon>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="view === 'compare'">
      <customer-portal-app-revolution-marketplace-compare
        [compareData]="comparedVehicles"
      ></customer-portal-app-revolution-marketplace-compare>
    </ng-container>
  </div>
</div>
