// copied from HubMatomoIntegrationService with some adjustments

/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable, inject } from '@angular/core'
import { MatomoTracker, MatomoInitializerService } from '@ngx-matomo/tracker'
import { NGXLogger } from 'ngx-logger'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { SharedService } from './shared.service'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { type VehicleDTOExtended } from '../interfaces/shared'
import { HttpClient } from '@angular/common/http'
import { PwaService } from './pwa.service'
import { type User } from '@inside-hub-app/customer-portal-b2c-client'

@Injectable({
  providedIn: 'root'
})
export class MatomoIntegrationService {
  private readonly matomoInitializerService = inject(MatomoInitializerService)
  private readonly logger = inject(NGXLogger)
  private readonly matomo = inject(MatomoTracker)
  private readonly keyCloakService = inject(KeycloakService)
  private readonly sharedService = inject(SharedService)
  private readonly http = inject(HttpClient)
  private readonly pwaService = inject(PwaService)
  private readonly remoteConfigService = inject(EfRemoteConfigurationService<CustomerPortalConfig>)

  public hasMatomo = this.remoteConfigService.get('matomo.enabled')
  private readonly b2cApiUrl = this.remoteConfigService.get('b2c.baseUrl')
  matomoIsInitialized = false

  public shouldOpenConsentSnackbar (user: User): boolean {
    if (this.hasMatomo !== true) {
      this.logger.warn('matomo', 'is not enabled')
      return false
    }

    if (user?.contactId == null) {
      this.logger.warn('matomo', 'user not logged in')
      return false
    }

    // if its ios dont show consent popup
    const iosNative = this.pwaService.checkIosNative()
    if (iosNative === true) {
      return false
    }

    // if the user never clicked accept or decline open snackbar
    if (user.trackingConsent !== true && user.trackingConsent !== false) {
      return true
    }

    return false
  }

  public initialize (cpId?): void {
    if (this.hasMatomo !== true) {
      this.logger.warn('matomo', 'is not enabled')
      return
    }

    // is matomo already initialized
    if (this.matomoIsInitialized === true) {
      return
    }

    const siteId = this.remoteConfigService.get('matomo.site-id')
    const trackerUrl = this.remoteConfigService.get('matomo.tracker-url')

    if (!this.sharedService.stringExists(siteId) || !this.sharedService.stringExists(trackerUrl)) {
      this.logger.warn('matomo', 'missing site-id or tracker-url in the configuration, matomo disabled')
      return
    }

    this.matomo.enableJSErrorTracking() // the errors will show on console and dashboard

    // opt out by default
    this.matomo.optUserOut()

    // initialize tracker
    this.matomoInitializerService.initializeTracker({
      siteId,
      trackerUrl
    })
    this.matomoIsInitialized = true

    if (cpId != null) {
      this.startTracking(cpId)
    }
  }

  public startTracking (cpId): void {
    if (this.checkMatomo()) {
      this.logger.log('matomo', 'started tracking')
      this.matomo.forgetUserOptOut()
      // track the user's id
      this.matomo.setUserId(cpId)
      // track the first page
      this.matomo.trackPageView()
    }
  }

  public stopTracking (): void {
    if (this.checkMatomo()) {
      this.logger.log('matomo', 'stopped tracking')
      this.matomo.resetUserId()
      this.matomo.optUserOut()
      this.matomo.deleteCookies()
    }
  }

  // For example, to track a click event on a link:
  // Category could be “Links”
  // the Action could be “Click”
  // and the Name could be the text of the link.
  // The Value argument is optional and can be used to specify a numerical value associated with the event.
  public trackEvent (category: string, action: string, name?: string, value?: number): void {
    if (this.checkMatomo()) {
      this.matomo.trackEvent(category, action, name, value)
    }
  }

  // Tracking a New Page View
  // A single-page application is different from a usual website
  // as there is no regular new page load and Matomo cannot detect
  // automatically when a new page is viewed.
  // This means you need to let Matomo know whenever the URL and
  // the page title changes. You can do this using the methods
  // setCustomUrl and setDocumentTitle
  public trackPageView (url): void {
    if (this.checkMatomo()) {
      this.matomo.setCustomUrl(url)
      this.matomo.trackPageView()
    }
  }

  formatVehicleData (v: VehicleDTOExtended): string {
    if (v != null) {
      const brand = this.sharedService.stringExists(v.brand) ? v.brand : undefined
      const model = this.sharedService.stringExists(v.model) ? v.model : undefined

      if (brand != null || model != null) {
        return (brand != null ? (brand + ' ') : '') + (model != null ? model : '')
      }
    }
    return undefined
  }

  formatDocumentData (doc?): string {
    const type = this.sharedService.stringExists(doc?.type) ? doc.type : undefined
    const name = this.sharedService.stringExists(doc?.name) ? doc.name : undefined

    if (type != null || name != null) {
      return (name != null ? (name + ' ') : '') + (type != null ? '(' + type + ')' : '')
    }
    return undefined
  }

  checkMatomo (): boolean {
    if (this.hasMatomo !== true) {
      return false
    }
    if (this.matomoIsInitialized !== true) {
      return false
    }
    return true
  }
}
