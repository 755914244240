import { Component, HostListener } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import {
  CptGoogleTagmanagerService,
  LoginService
} from '@inside-hub-app/customer-portal-shared'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { SharedService } from '../../../../services/shared.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'
import { Router } from '@angular/router'
@Component({
  selector: 'customer-portal-app-bottom-navbar',
  templateUrl: './bottom-navbar.component.html',
  animations: [
    trigger('slideDown', [
      state('shown', style({ transform: 'translateY(0)', opacity: 1 })),
      state('hidden', style({ transform: 'translateY(100%)', opacity: 0 })),
      transition('hidden => shown', animate('200ms ease-out')),
      transition('shown => hidden', animate('200ms ease-in'))
    ])
  ]
})
export class BottomNavbarComponent {
  homepage
  lastScrollPosition = 0
  showNavbar = true

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    const currentScrollPosition = window.pageYOffset

    if (
      currentScrollPosition > this.lastScrollPosition &&
      currentScrollPosition > 50
    ) {
      this.showNavbar = false
    } else {
      this.showNavbar = true
    }

    this.lastScrollPosition = currentScrollPosition
  }

  user: User
  loggedIn: boolean
  vehicles: VehicleDTOExtended[] = []
  vin: string
  constructor(
    private readonly dataService: DataService,
    private readonly keycloak: KeycloakService,
    private readonly logger: NGXLogger,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public sharedService: SharedService,
    public router: Router,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>,
    private readonly loginService: LoginService
  ) {
    this.homepage = this.remoteConfigService.get('homepage')
  }

  logout(): void {
    this.loginService.logout(this.user)
  }

  sendGTMNavigationData(label?: string, linkType?: string): void {
    this.cptGtmService.sendNavigationLinkData(
      null,
      'Main Navigation',
      this.sharedService.translateLink(label),
      linkType
    )
  }

  ngOnInit(): void {
    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
      if (user != null) {
        this.loggedIn = true
      }
    })

    this.dataService.onUserVehiclesLoaded.subscribe(
      data => {
        this.vehicles = data
        if (this.vin == null && data?.[0] != null) {
          this.vin = data[0].vin
        }
      },
      err => {
        if (err.status === 404) {
          this.vehicles = []
        }
      }
    )
  }

  chatboxIsMinimized(): boolean {
    return this.sharedService.chatBotState?.state === 'minimized'
  }
}
