import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { ExcludedUrlRegex } from '../environments'
import { AuthService } from '../services/auth.service'
import { PpAuthInterceptorConfigurationService } from '../services/pp-auth-interceptor-configuration.service'

@Injectable()
export class PpAuthInterceptor implements HttpInterceptor {
  constructor (
    protected configurationService: PpAuthInterceptorConfigurationService,
    protected authService: AuthService
  ) {}

  private isUrlExcluded (
    { method, url }: HttpRequest<any>,
    { pattern, methods }: ExcludedUrlRegex
  ): boolean {
    const methodTest =
      (methods == null) ||
      methods.length === 0 ||
      methods.join().includes(method.toUpperCase())

    const urlTest = new RegExp(pattern).test(url)

    return methodTest && urlTest
  }

  intercept (
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isExcluded =
      !(this.configurationService.authorizationExcludedUrls.find(
        excludedUrl => {
          return this.isUrlExcluded(request, excludedUrl)
        }
      ) == null)

    if (isExcluded) {
      return next.handle(request)
    }

    return this.authService.getToken().pipe(
      mergeMap(token => {
        // exit if no token
        if (!token) {
          return next.handle(request)
        }

        // exit if auth header is present
        if (
          request.headers &&
          (request.headers.has('X-Auth-Token') ||
            request.headers.has('x-auth-token'))
        ) {
          return next.handle(request)
        }

        const cloneRequest = request.clone({
          headers: (request.headers || new HttpHeaders()).set(
            'x-auth-token',
            token
          )
        })
        return next.handle(cloneRequest)
      })
    )
  }
}
