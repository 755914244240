import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  UpdateUserConsentsRequest,
  User,
  UserBrandConsent,
  UserConsentsResponse,
  UserDealerConsent,
  UserGroupConsent,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import { MatomoIntegrationService, TransifexService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { LanguageService } from '../../../../services/language.service'
import { LocalStorageService } from '../../../../services/local-storage.service'
import { SharedService } from '../../../../services/shared.service'

@Component({
  selector: 'customer-portal-app-revolution-gdpr',
  templateUrl: './gdpr.component.html'
})
export class gdprComponent implements OnInit, OnDestroy {
  user: User
  country

  consentsResponse: any // UserConsentsResponse
  disabled: boolean
  updates: UserBrandConsent[] | UserDealerConsent[] | UserGroupConsent[] = []
  update: boolean

  public editDisabled = false
  headingChannels
  locale

  sub = {
    onUserConsentsLoaded: null,
    onTransifexLanguageChange: null,
    loading: null
  }

  loading

  constructor (
    private readonly userService: UserService,
    public dialog: MatDialog,
    private readonly logger: NGXLogger,
    private readonly transifexTranslationsService: TranslationService,
    public transifexService: TransifexService,
    private readonly sharedService: SharedService,
    private readonly data: DataService,
    private readonly languageService: LanguageService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly localStorageService: LocalStorageService,
    private readonly matomoIntegrationService: MatomoIntegrationService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.country = this.remoteConfigService.get('country.code')
  }

  public getStyles (): {
    'grid-template-columns': string
  } {
    const length: number =
      this.headingChannels?.length != null ? this.headingChannels.length : 0
    return {
      'grid-template-columns': `repeat(${length + 3}, 1fr)`
    }
  }

  ngOnInit (): void {
    this.disabled = false
    this.update = false
    this.editDisabled = false

    this.sub.onTransifexLanguageChange =
      this.transifexService.onTransifexLanguageChange.subscribe(locale => {
        if (this.locale !== locale) {
          this.locale = locale
          this.data.getConsents()
        }
      })
    this.sub.onUserConsentsLoaded = this.data.onUserConsentsLoaded.subscribe(
      consents => {
        this.consentsResponse = this.sharedService.deepCopy(consents)
        this.setupConsents(consents)
      }
    )
    this.sub.loading = this.data.loading.consents.subscribe(loading => {
      this.loading = loading
    })
  }

  setupConsents (consents): void {
    let consentSize = 0
    if (consents.group != null) {
      consentSize = consents.group.consents.channels.length
      this.headingChannels = consents.group.consents.channels
    }
    if (consents.brands != null) {
      for (const b of consents.brands) {
        if (b.consents.channels.length > consentSize) {
          consentSize = b.consents.channels.length
          this.headingChannels = b.consents.channels
        }
      }
    }
    if (consents.dealers != null) {
      for (const b of consents.dealers) {
        if (b.consents.channels.length > consentSize) {
          consentSize = b.consents.channels.length
          this.headingChannels = b.consents.channels
        }
      }
    }
  }

  removeLegalTextLogo (obj): void {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        this.removeLegalTextLogo(obj[key])
      } else {
        if (key === 'legalText' || key === 'logo') {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete obj[key]
        }
      }
    })
  }

  updateConsents (): void {
    this.matomoIntegrationService.trackEvent(
      'My account',
      'Data protection change'
    )

    this.disabled = true
    const newConsents: UserConsentsResponse = {
      group: this.consentsResponse.group,
      dealers: this.consentsResponse.dealers,
      brands: this.consentsResponse.brands
    }

    const copy = this.sharedService.deepCopy(newConsents)
    this.removeLegalTextLogo(copy)

    const updateConsents: UpdateUserConsentsRequest = {
      url: window.location.href,
      userConsents: copy
    }

    this.userService.putConsents(updateConsents).subscribe(
      text => {
        this.logger.debug(text)
        this.disabled = false
        this.updates = []
        this.sharedService.openConfirmationPopup(
          'shared.success',
          'customerPortal.customer-portal.details.change.consents-changed'
        )
        this.data.getConsents()
      },
      error => {
        this.sharedService.openConfirmationPopup(
          'shared.error',
          'shared.general-error-message'
        )
        this.logger.error(error)
      }
    )
  }

  getConsentValue (consent, channel): boolean {
    if (channel === 'signed') {
      return consent?.consents?.signed === true
    } else {
      const val = consent?.consents?.channels?.find(s => s.name === channel)
      if (val != null) {
        return val.allow
      } else {
        return false
      }
    }
  }

  findUserPhone (): boolean {
    return !(
      this.sharedService.findUserPhone(this.user)?.mobile != null ||
      this.sharedService.findUserPhone(this.user)?.mobileBusiness != null ||
      this.sharedService.findUserPhone(this.user)?.landlinePersonal != null ||
      this.sharedService.findUserPhone(this.user)?.landlineBusiness != null
    )
  }

  updateConsentValue (type: string, id: number, channel: string, event): void {
    if (type === 'dealer') {
      this.isValidConsents(id, this.consentsResponse.dealers, event, channel)
    } else if (type === 'brand') {
      this.isValidConsents(id, this.consentsResponse.brands, event, channel)
    } else {
      this.isValidConsents(id, this.consentsResponse.group, event, channel)
    }
  }

  errorMessage (type): void {
    if (type === 'telephone') {
      if (this.findUserPhone()) {
        this.sharedService.openConfirmationPopup(
          'shared.error',
          'customerPortal.customer-portal.no-phone'
        )
      }
    }

    if (type === 'post') {
      if (!this.addressExist()) {
        this.sharedService.openConfirmationPopup(
          'shared.error',
          'customerPortal.customer-portal.no-address'
        )
      }
    }
  }

  addressExist (): boolean {
    return this.user?.address != null
  }

  isValidConsents (id: number, element, event, channel: string): void {
    const el = Array.isArray(element)
      ? element?.find(d => d.id === id)
      : element
    let consentValue
    if (channel === 'signed') {
      consentValue = el
      consentValue.consents.signed = this.sharedService.switchBoolean(
        consentValue.consents.signed
      )
    } else {
      consentValue = el.consents.channels.find(cd => cd.name === channel)
      consentValue.allow = event.checked
      consentValue.edit = this.sharedService.switchBoolean(consentValue.edit)
    }

    if (!this.updates.includes(consentValue)) {
      this.updates.push(consentValue)
    } else {
      const index = this.updates.indexOf(consentValue)
      if (index > -1) {
        this.updates.splice(index, 1)
      }
    }
  }

  checkIfDisabled (dealer, channel: string): boolean {
    if (dealer.consents.channels.find(s => s?.name === channel) != null) {
      return false
    } else {
      return true
    }
  }

  checkIfExist (dealer, channel: string): boolean {
    if (
      this.editDisabled ||
      dealer.consents.channels.find(s => s?.name === channel) == null ||
      (this.findUserPhone() && channel === 'telephone') ||
      (!this.addressExist() && channel === 'post')
    ) {
      return false
    } else {
      return true
    }
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }
}
