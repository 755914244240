import { Location } from '@angular/common'
import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'

@Injectable()
export class NavigationService {
  public history: string[] = []
  private currentIndex = -1
  constructor(
    private readonly router: Router,
    private readonly location: Location
  ) {
    // save inital route
    if (this.router?.url != null && this.history.length === 0) {
      this.history.push(this.router.url)
      this.currentIndex = 0
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects
        if (url != null && this.history.length === 0) {
          this.history.push(url)
          this.currentIndex = 0
        } else if (url != null && url !== this.history[this.currentIndex]) {
          this.history = this.history.slice(0, this.currentIndex + 1)
          this.history.push(url)
          this.currentIndex = this.history.length - 1
        }
      }
    })
  }

  back(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--
      this.location.back()
    } else {
      void this.router.navigateByUrl('/')
    }
  }

  forward(): void {
    if (this.currentIndex < this.history.length - 1) {
      this.currentIndex++
      const url = this.history[this.currentIndex]
      void this.router.navigateByUrl(url)
    }
  }

  hasForward(): boolean {
    // Forward should be enabled if there are at least 2 values in history and last record != currentRoute
    return (
      this.history.length > 1 &&
      this.currentIndex >= 0 &&
      this.currentIndex !== this.history.length - 1
    )
  }

  hasBack(): boolean {
    // Back should be enabled if there is at least 1 record in history and  first record != currentRoute
    return this.history.length > 0 && this.currentIndex > 0
  }
}
