<div class="view-container">
  <ng-container *ngIf="showMobileView === false">
    <!-- personal-data -->
    <div
      id="personal-data"
      [hidden]="currentTabName !== 'myAccountPersonalData'"
    >
      <customer-portal-app-revolution-general-user-data></customer-portal-app-revolution-general-user-data>
    </div>
    <!-- data-protection -->
    <div
      class="content-container"
      id="data-protection"
      [hidden]="currentTabName !== 'myAccountDataProtection'"
    >
      <customer-portal-app-revolution-privacy
        *ngIf="!hasNewGDPR"
      ></customer-portal-app-revolution-privacy>
      <customer-portal-app-revolution-gdpr
        *ngIf="hasNewGDPR"
      ></customer-portal-app-revolution-gdpr>
    </div>

    <!-- user-personalization-->
    <div
      class="content-container"
      id="user-personalization"
      [hidden]="currentTabName !== 'myAccountUserPersonalization'"
    >
      <customer-portal-app-revolution-user-personalisation></customer-portal-app-revolution-user-personalisation>
    </div>

    <!-- user-notifications-->
    <div
      class="content-container"
      id="user-notifications"
      [hidden]="currentTabName !== 'myAccountUserNotifications'"
    >
      <customer-portal-app-user-notifications></customer-portal-app-user-notifications>
    </div>
  </ng-container>

  <ng-container *ngIf="showMobileView === true">
    <div #accView></div>
    <div
      (touchstart)="swipe($event, 'start')"
      (touchend)="swipe($event, 'end')"
    >
      <mat-tab-group
        [(selectedIndex)]="swipeData.currentTab"
        class="mat-tab-group_hide-header"
      >
        <mat-tab>
          <!-- personal-data -->
          <div id="personal-data">
            <customer-portal-app-revolution-general-user-data></customer-portal-app-revolution-general-user-data>
          </div>
        </mat-tab>
        <mat-tab>
          <!-- data-protection -->
          <div id="data-protection">
            <customer-portal-app-revolution-privacy
              *ngIf="!hasNewGDPR"
            ></customer-portal-app-revolution-privacy>
            <customer-portal-app-revolution-gdpr
              *ngIf="hasNewGDPR"
            ></customer-portal-app-revolution-gdpr>
          </div>
        </mat-tab>
        <mat-tab>
          <!-- user-personalization-->
          <div id="user-personalization">
            <customer-portal-app-revolution-user-personalisation></customer-portal-app-revolution-user-personalisation>
          </div>
        </mat-tab>
        <mat-tab *ngIf="hasNotifications">
          <div id="user-notifications">
            <customer-portal-app-user-notifications></customer-portal-app-user-notifications>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-container>
</div>
