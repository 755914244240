import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, type Observable } from 'rxjs'
import { B2CModuleConfig } from '../b2c.module'
import { type hr as cpt } from 'efd-b2c-backend-interfaces-ts'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'

export type ReplaceDateWithStringType<T> = {
  [P in keyof T]: T[P] extends Date ? string : T[P]
}
export type UserAddressType =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.ContactAddressTypeEnum
export type UserAddress =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.UserAddressDTO
export type UserTelephoneUsage =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.ContactTelephoneUsageEnum
export type UserTelephoneType =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.ContactTelephoneTypeEnum
export type UserTelephone =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.UserTelephoneDTO
export type UserLanguage =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.UserLanguageDTO
export type RelatedBusiness =
  cpt.emilfreydigital.b2c_auth_server.rest.common.dto.BusinessResponseDTO
export type User =
  ReplaceDateWithStringType<cpt.emilfreydigital.b2c_auth_server.rest.common.dto.UserResponseDTO>
export type UserUpdateAddressValidationErrorFieldId =
  cpt.emilfreydigital.b2c_auth_server.rest.error.dto.ValidationErrorFieldDTO.FieldIdEnum
export type UserUpdateAddressValidationErrorField =
  cpt.emilfreydigital.b2c_auth_server.rest.error.dto.ValidationErrorFieldDTO
export type UserUpdateAddressValidationError =
  cpt.emilfreydigital.b2c_auth_server.rest.error.dto.ValidationErrorResponseDTO<any>
export type ResponseUserEnums =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.UserEnumsDTO
export type UserEnums =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.UserEnumsDTO
export type UserConsentType =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.ConsentTypeEnum
export type UserConsentChannel =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.ConsentChannelDTO
export type UserConsent =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.ConsentDTO
export type UserGroupConsent =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.ConsentsDTO
export type UserBrandConsent =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.ConsentsDTO
export type UserDealerConsent =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.DealerConsentsDTO
export type UpdateUserConsentsRequest =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.UserConsentsRequestDTO
export type UserConsentsResponse =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.UserConsentsDTO
export type TelephoneVerificationRequest =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.UserTelephoneDTO
export type CompanyDataChangeRequest =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.RequestCompanyDataChangeDTO
export type RemovingAccountReason =
  cpt.emilfreydigital.b2c_auth_server.rest.user.dto.UserDeleteDTO
export type EmailChangeDetailsResponse =
  cpt.emilfreydigital.b2c_auth_server.rest.email_change.dto.EmailChangeDetailsDTO

export class UserUpdateRequest {
  address?: UserAddress[]
  language?: UserLanguage[]

  public static fromUser (user: User): UserUpdateRequest {
    const req = new UserUpdateRequest()
    req.address = [...user.address]
    req.language = [...user.language]
    return req
  }
}
export function isUserUpdateAddressValidationError (error: any): boolean {
  return (
    'reasons' in error &&
    Array.isArray(error.reasons) &&
    error.reasons.includes('invalid-address')
  )
}

export function isDuplicateUserValidationError (error: any): boolean {
  return (
    ('duplicateUsers' in error && Array.isArray(error.duplicateUsers)) ||
    ('duplicateContacts' in error && Array.isArray(error.duplicateContacts))
  )
}
@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly apiUrl
  private readonly apiKey
  private readonly fallbackApiKey

  private readonly _notEditable = new BehaviorSubject<boolean>(false)
  onNotEditableChanged = this._notEditable.asObservable()
  constructor (
    private readonly config: B2CModuleConfig,
    private readonly http: HttpClient,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('b2c.baseUrl')
    this.apiKey = this.config.apiKey
    this.fallbackApiKey = this.remoteConfigService.get('b2c.apiKey')
  }

  public getUser (apiKey: string = null): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/user`, {
      headers: {
        'api-key': apiKey || this.apiKey || this.fallbackApiKey
      }
    })
  }

  // updates the user entity
  // throws UserUpdateAddressValidationError
  public putUser (
    user: UserUpdateRequest,
    apiKey: string = null,
    force: boolean = null
  ): Observable<any> {
    return this.http.put(`${this.apiUrl}/user?force=${force}`, user, {
      headers: {
        'api-key': apiKey || this.apiKey || this.fallbackApiKey
      }
    })
  }

  public profilePicture (profilePictureUrl: string, apiKey: string = null) {
    return this.http.post(
      `${this.apiUrl}/user/profile-picture`,
      profilePictureUrl,
      {
        headers: {
          'api-key': apiKey || this.apiKey || this.fallbackApiKey
        }
      }
    )
  }

  public deleteProfilePicture (apiKey: string = null) {
    return this.http.delete(`${this.apiUrl}/user/profile-picture`, {
      headers: {
        'api-key': apiKey || this.apiKey || this.fallbackApiKey
      }
    })
  }

  public enums (apiKey: string = null): Observable<UserEnums> {
    return this.http.get<UserEnums>(`${this.apiUrl}/user/enums`, {
      headers: {
        'api-key': apiKey || this.apiKey || this.fallbackApiKey
      }
    })
  }

  public changePassword (
    currentPassword: string,
    newPassword: string,
    apiKey: string = null
  ): Observable<any> {
    return this.http.put(
      `${this.apiUrl}/user/change-password`,
      {
        currentPassword,
        password: newPassword
      },
      {
        headers: {
          'api-key': apiKey || this.apiKey || this.fallbackApiKey
        }
      }
    )
  }

  public getConsents (apiKey: string = null): Observable<UserConsentsResponse> {
    return this.http.get<UserConsentsResponse>(`${this.apiUrl}/user/consents`, {
      headers: {
        'api-key': apiKey || this.apiKey || this.fallbackApiKey
      }
    })
  }

  public putConsents (
    consents: UpdateUserConsentsRequest,
    apiKey: string = null
  ): Observable<any> {
    return this.http.put(`${this.apiUrl}/user/consents`, consents, {
      headers: {
        'api-key': apiKey || this.apiKey || this.fallbackApiKey
      }
    })
  }

  public sendTelephoneVerification (
    telephone: TelephoneVerificationRequest,
    apiKey: string = null,
    lang: string = null
  ): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/user/telephone-verification?lang=${lang}`,
      telephone,
      {
        headers: {
          'api-key': apiKey || this.apiKey || this.fallbackApiKey
        }
      }
    )
  }

  public sendCompanyChangeRequest (
    changes: CompanyDataChangeRequest,
    apiKey: string = null
  ): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/user/send-company-data-change-request`,
      changes,
      {
        headers: {
          'api-key': apiKey || this.apiKey || this.fallbackApiKey
        }
      }
    )
  }

  public verifyTelephone (
    code: string,
    apiKey: string = null,
    lang: string = null
  ): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/user/telephone-verification/${code}?lang=${lang}`,
      {
        headers: {
          'api-key': apiKey || this.apiKey || this.fallbackApiKey
        }
      }
    )
  }

  public deleteAccount (
    reason: RemovingAccountReason,
    apiKey: string = null
  ): Observable<any> {
    return this.http.post(`${this.apiUrl}/user/delete`, reason, {
      headers: {
        'api-key': apiKey || this.apiKey || this.fallbackApiKey
      }
    })
  }

  public deleteTelephone (
    type: UserTelephoneType,
    usage: UserTelephoneUsage,
    apiKey: string = null
  ) {
    return this.http.delete(
      `${this.apiUrl}/user/telephone?type=${type}&usage=${usage}`,
      {
        headers: {
          'api-key': apiKey || this.apiKey || this.fallbackApiKey
        }
      }
    )
  }

  public sendEmailChangeRequest (
    email: string,
    apiKey: string = null,
    lang: string = null
  ): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/change-email?lang=${lang}`,
      { email },
      {
        headers: {
          'api-key': apiKey || this.apiKey || this.fallbackApiKey
        }
      }
    )
  }

  public getEmailChangeDetails (
    apiKey: string = null
  ): Observable<EmailChangeDetailsResponse> {
    return this.http.get<EmailChangeDetailsResponse>(
      `${this.apiUrl}/change-email/details`,
      {
        headers: {
          'api-key': apiKey || this.apiKey || this.fallbackApiKey
        }
      }
    )
  }

  public verifyEmailChange (
    token: string,
    apiKey: string = null
  ): Observable<any> {
    return this.http.get(`${this.apiUrl}/change-email/verify/${token}`, {
      headers: {
        'api-key': apiKey || this.apiKey || this.fallbackApiKey
      }
    })
  }

  public changeUserPreference (
    body: {
      name: string
      value: string
    },
    apiKey: string = null
  ): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/user/preference`,
      body,
      {
        headers: {
          'api-key': apiKey || this.apiKey || this.fallbackApiKey
        }
      }
    )
  }

  public getUserPreferences (
    apiKey: string = null
  ): Observable<any> {
    return this.http.get(`${this.apiUrl}/user/preference`, {
      headers: {
        'api-key': apiKey || this.apiKey || this.fallbackApiKey
      }
    })
  }

  notEditableChanged (notEditable): void {
    this._notEditable.next(notEditable)
  }
}
