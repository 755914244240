import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'replace' })
export class ReplacePipe implements PipeTransform {
  transform(
    value: string,
    strToReplace: string,
    replacementStr: string
  ): string {
    if (
      value == null ||
      value === '' ||
      strToReplace == null ||
      strToReplace === '' ||
      replacementStr == null ||
      replacementStr === ''
    ) {
      return value
    }

    const regExp = new RegExp(this.regExpEscape(strToReplace), 'g')
    return value.toString().replace(regExp, replacementStr)
  }

  regExpEscape(literal): string {
    // eslint-disable-next-line no-useless-escape
    return literal.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&')
  }
}
