<div
  data-cy="edit-telephone-form"
  class="cpt-modal TelephoneChangePopupComponent-revolution-dialog relative dialog-content-p-0 mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <ng-container *ngIf="usage === 'private'">
      <T
        str="Update Mobile Number"
        key="customerPortal.customer-portal.details.change.telephone"
        tags="customer-portal, 3.1"
      ></T>
    </ng-container>
    <ng-container *ngIf="!(usage === 'private')">
      <T
        str="Change company number - mobile"
        key="customerPortal.customer-portal.details.change.telephone-busines-mobile"
        tags="customer-portal, 3.1"
      ></T>
    </ng-container>
  </h2>
  <mat-dialog-content
    class="mat-typography md:max-h-[calc(65vh_-_40px)] overflow-x-hidden"
  >
    <div [ngClass]="{ hidden: step !== 'change' }">
      <form [formGroup]="changeForm" class="gap-3 pt-2 grid grid-cols-5">
        <mat-form-field
          class="hidden md:inline-block col-span-5 md:col-span-1"
          appearance="legacy"
        >
          <mat-label>
            {{ 'prefix' | sharedTranslate }}
          </mat-label>
          <mat-select  
            data-cy="edit-telephone-prefix-select" 
            formControlName="mobilePrefix" 
            required
          >
            <mat-option
              *ngFor="let mobilePrefix of mobilePrefixes | keyvalue"
              [value]="'+' + mobilePrefix.value"
              >+{{ mobilePrefix.value }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="inline-block md:hidden col-span-5 md:col-span-1"
          appearance="legacy"
        >
          <mat-label>
            {{ 'prefix' | sharedTranslate }}
          </mat-label>
          <select matNativeControl formControlName="mobilePrefix" required>
            <option
              *ngFor="let mobilePrefix of mobilePrefixes | keyvalue"
              [value]="'+' + mobilePrefix.value"
            >
              +{{ mobilePrefix.value }}
            </option>
          </select>
        </mat-form-field>
        <mat-form-field class="col-span-5 md:col-span-4">
          <mat-label>
            {{ 'mobile-number' | sharedTranslate }}
          </mat-label>
          <input
            data-cy="edit-telephone-number-input"
            #mobileInput
            *ngIf="phoneMask"
            [mask]="phoneMask"
            class="input"
            [maxLength]="maxLength"
            matInput
            type="tel"
            required
            formControlName="mobile"
            [errorStateMatcher]="matcher"
          />
          <input
            data-cy="edit-telephone-number-input"
            *ngIf="!phoneMask"
            class="input"
            [maxLength]="maxLength"
            matInput
            type="tel"
            required
            formControlName="mobile"
            [errorStateMatcher]="matcher"
          />
          <mat-icon
            *ngIf="
              changeForm.controls.mobile.touched &&
              changeForm.controls.mobile.invalid
            "
            matSuffix
            class="error"
            svgIcon="error_outline"
          ></mat-icon>
          <mat-error
            *ngIf="
              changeForm.controls.mobile.invalid &&
              changeForm.controls.mobile.errors
            "
          >
            {{
              transifexService.getErrorTranslation(
                changeForm.controls.mobile,
                'customerPortal.customer-portal.details.personal.mobile.error.'
              )
            }}
          </mat-error>
        </mat-form-field>
      </form>
    </div>
    <div [ngClass]="{ hidden: step !== 'verify' }">
      <mat-card
        class="flex flex-row items-center gap-6 py-7 px-10 mb-5 border border-at-ef-revolution-supergrey-500 rounded-lg mat-elevation-z0"
      >
        <mat-icon
          class="min-w-[36px] min-h-[36px] text-3xl items-center justify-self-start relative b-5 text-mat-ef-revolution-primary-500 font-medium"
          svgIcon="phone_iphone"
        ></mat-icon>
        <p
          class="m-0 justify-self-start items-center font-efSans-primary"
          innerHTML="{{ getTranslation() }}"
        ></p>
      </mat-card>
      <div class="py-10 px-0 relative">
        <form
          class="grid grid-cols-3 md:grid-cols-7 gap-3 items-center justify-center justify-items-center"
          #telephoneValidationForm
          [formGroup]="verifyForm"
        >
          <input
            data-cy="verify-code-0"
            #code0
            matInput
            placeholder="0"
            type="tel"
            autocomplete="off"
            formControlName="code0"
            minlength="1"
            maxlength="1"
            tabindex="0"
            (keypress)="isNumber($event)"
            (keyup)="keyup($event, 0)"
            (focus)="focus(0)"
            class="col-span-1 w-full lg:w-[72px] h-full md:h-[80px] box-border p-4 m-0 text-6xl text-center border rounded-md border-mat-ef-revolution-supergrey-500 border-solid text-mat-ef-revolution-primary-500"
          />
          <input
            data-cy="verify-code-1"
            #code1
            matInput
            placeholder="0"
            type="tel"
            autocomplete="off"
            formControlName="code1"
            minlength="1"
            maxlength="1"
            tabindex="1"
            (keypress)="isNumber($event)"
            (keyup)="keyup($event, 1)"
            (focus)="focus(1)"
            class="col-span-1 w-full lg:w-[72px] h-full md:h-[80px] box-border p-4 m-0 text-6xl text-center border rounded-md border-mat-ef-revolution-supergrey-500 border-solid text-mat-ef-revolution-primary-500"
          />
          <input
            data-cy="verify-code-2"
            #code2
            matInput
            placeholder="0"
            type="tel"
            autocomplete="off"
            formControlName="code2"
            minlength="1"
            maxlength="1"
            tabindex="2"
            (keypress)="isNumber($event)"
            (keyup)="keyup($event, 2)"
            (focus)="focus(2)"
            class="col-span-1 w-full lg:w-[72px] h-full md:h-[80px] box-border p-4 m-0 text-6xl text-center border rounded-md border-mat-ef-revolution-supergrey-500 border-solid text-mat-ef-revolution-primary-500"
          />
          <span
            class="text-7xl text-center text-mat-ef-revolution-grey-two-500 hidden md:block"
            >-</span
          >
          <input
            data-cy="verify-code-3"
            #code3
            matInput
            placeholder="0"
            type="tel"
            autocomplete="off"
            formControlName="code3"
            minlength="1"
            maxlength="1"
            tabindex="3"
            (keypress)="isNumber($event)"
            (keyup)="keyup($event, 3)"
            (focus)="focus(3)"
            class="col-span-1 w-full lg:w-[72px] h-full md:h-[80px] box-border p-4 m-0 text-6xl text-center border rounded-md border-mat-ef-revolution-supergrey-500 border-solid text-mat-ef-revolution-primary-500"
          />
          <input
            data-cy="verify-code-4"
            #code4
            matInput
            placeholder="0"
            type="tel"
            autocomplete="off"
            formControlName="code4"
            minlength="1"
            maxlength="1"
            tabindex="4"
            (keypress)="isNumber($event)"
            (keyup)="keyup($event, 4)"
            (focus)="focus(4)"
            class="col-span-1 w-full lg:w-[72px] h-full md:h-[80px] box-border p-4 m-0 text-6xl text-center border rounded-md border-mat-ef-revolution-supergrey-500 border-solid text-mat-ef-revolution-primary-500"
          />
          <input
            data-cy="verify-code-5"
            #code5
            matInput
            placeholder="0"
            type="tel"
            autocomplete="off"
            formControlName="code5"
            minlength="1"
            maxlength="1"
            tabindex="5"
            (keypress)="isNumber($event)"
            (keyup)="keyup($event, 5)"
            (focus)="focus(5)"
            class="col-span-1 w-full lg:w-[72px] h-full md:h-[80px] box-border p-4 m-0 text-6xl text-center border rounded-md border-mat-ef-revolution-supergrey-500 border-solid text-mat-ef-revolution-primary-500"
          />
        </form>

        <div
          *ngIf="verifying"
          class="rounded-lg z-50 absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-mat-ef-revolution-grey-500 bg-opacity-70"
        >
          <mat-spinner diameter="64"></mat-spinner>
        </div>
      </div>
      <mat-error *ngIf="verifyForm.errors">
        {{
          transifexService.getErrorTranslation(
            verifyForm,
            'customerPortal.customer-portal.details.personal.verify.error'
          )
        }}
      </mat-error>
    </div>
    <div class="pb-5" [ngClass]="{ hidden: step !== 'done' || error }">
      <T
        str="Mobile number was updated successfully."
        key="customerPortal.customer-portal.details.change.telChanged"
        tags="customer-portal, 3.1"
      ></T>
    </div>
    <div class="pb-5" [ngClass]="{ hidden: !error }">
      {{ 'general-error-message' | sharedTranslate }}
    </div>
  </mat-dialog-content>
  <mat-divider *ngIf="step !== 'verify'" class="mt-0"></mat-divider>

  <mat-dialog-actions [ngClass]="{ 'max-h-0 min-h-0': step === 'verify' }">
    <button
      *ngIf="
        (step === 'change' || step === 'verify' || !verifying) &&
        step !== 'done'
      "
      class="dialog-close-button"
      [mat-dialog-close]="false"
    >
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <button
      *ngIf="step === 'done'"
      class="dialog-close-button"
      (click)="close()"
    >
      <mat-icon svgIcon="close"></mat-icon>
    </button>

    <button
      *ngIf="step === 'done'"
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      (click)="close()"
      cdkFocusInitial
    >
      <T
        str="Close"
        key="customerPortal.customer-portal.general.actions.close"
        tags="customer-portal, 3.1"
      ></T>
    </button>

    <button
      data-cy="edit-telephone-submit"
      *ngIf="step === 'change'"
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      (click)="sendVerification()"
      cdkFocusInitial
      [disabled]="!changeForm.valid || sending"
    >
      <mat-spinner
        class="inline-block mr-2.5"
        *ngIf="sending"
        diameter="16"
      ></mat-spinner>
      <T
        str="Verify"
        key="customerPortal.customer-portal.details.change-telephone.verify"
        tags="customer-portal, 3.1"
      ></T>
    </button>
  </mat-dialog-actions>
</div>
