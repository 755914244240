<section class="footer footerDE bg-mat-ef-web-dark-gray-500">
  <div
    class="footerLinksContainer border-none grid grid-cols-3 px-6 pt-[86px] pb-6 md:pb-10"
  >
    <div class="col-span-3 xl:col-span-2">
      <div class="flex items-center">
        <mat-icon
          svgIcon="ef-logo-icon"
          class="w-[65px] h-[65px] fill-mat-ef-revolution-primary-500 md:fill-white"
        ></mat-icon>
        <div class="footerEF pl-5 text-white text-lg">
          <strong>Emil Frey</strong>
          {{ countryName }}
        </div>
      </div>
      <div
        *ngIf="!isMobilePwa"
        class="mt-[44px] grid grid-cols-1 md:grid-cols-3"
      >
        <div class="footer-v2-text" *ngIf="cols && cols[0]">
          <a
            *ngFor="let c of cols[0]"
            href="{{
              c.content.link.url && c.content.link.url.startsWith('http')
                ? c.content.link.url
                : baseUrl + c.content.link.url
            }}"
            class="footer-v2-link"
            target="_blank"
            (click)="sendGTMNavigationData(c.content.text)"
          >
            {{ c.content.text }}
          </a>
        </div>
        <div class="footer-v2-text pt-6 md:pt-0" *ngIf="cols && cols[1]">
          <a
            *ngFor="let c of cols[1]"
            href="{{
              c.content.link.url && c.content.link.url.startsWith('http')
                ? c.content.link.url
                : baseUrl + c.content.link.url
            }}"
            target="_blank"
            class="footer-v2-link"
            (click)="sendGTMNavigationData(c.content.text)"
          >
            {{ c.content.text }}
          </a>
        </div>
        <div class="newsletter-col" *ngIf="cols && cols[2]">
          <a
            *ngFor="let c of cols[2]"
            href="{{
              c.content.link.url && c.content.link.url.startsWith('http')
                ? c.content.link.url
                : baseUrl + c.content.link.url
            }}"
            class="footer-v2-link"
            target="_blank"
            (click)="sendGTMNavigationData(c.content.text)"
          >
            {{ c.content.text }}
          </a>
          <a
            *ngIf="newsletter"
            href="{{ baseUrl + '/newsletter' }}"
            class="footer-v2-link"
            target="_blank"
            (click)="sendGTMNavigationData(newsletter.title)"
          >
            {{ newsletter.title }}
          </a>
        </div>
      </div>
    </div>
    <a
      href="{{ baseUrl + '/konfigurator' }}"
      class="hidden xl:flex ml-5 w-[432px] justify-self-end rounded-2xl bg-no-repeat bg-cover h-full bg-center relative text-white col-span-1"
      *ngIf="image && card && !isMobilePwa"
      target="_blank"
      [style.background-image]="'url(' + image.publicUrl + ')'"
    >
      <div class="absolute left-[30px] top-[27px] w-[calc(100%_-_60px)]">
        <span class="font-efSans font-bold text-2xl inline-block mb-1">{{
          card.content.headline
        }}</span>
        <span
          class="font-efSans font-bold text-sm inline-block tracking-wide"
          >{{ card.content.subline }}</span
        >
      </div>
      <span class="card-content-slogan">{{ card.content.slogan }}</span>
    </a>
  </div>
  <div
    *ngIf="!isMobilePwa"
    class="py-6 md:py-12 px-6 grid gap-3 items-center md:gap-0 grid-cols-1 md:grid-cols-2"
  >
    <div
      class="flex justify-center md:justify-start gap-x-7 gap-y-1 col-span-1 flex-wrap"
    >
      <div
        class="font-efSans text-3xl leading-8"
        *ngFor="let social of socialMedia"
      >
        <a
          [href]="social.content.link.url"
          (click)="sendGTMNavigationData(social.content.icon.name)"
          target="_blank"
        >
          <img
            class="social-icons h-8"
            [src]="social.content.icon.uri"
            alt="{{ social.content.icon.name }}"
          />
        </a>
      </div>
    </div>
    <div
      class="flex gap-x-7 gap-y-1 flex-wrap justify-center md:justify-end col-span-1"
    >
      <div
        class="font-efSans text-xs grid content-center font-bold hover:border-b-2 hover:border-white h-6"
        *ngFor="let legal of legals"
      >
        <a
          [href]="baseUrl + legal.content.link.url"
          class="footerLinks no-underline text-white uppercase"
          (click)="sendGTMNavigationData(legal.content.text)"
          target="_blank"
          >{{ legal.content.text }}</a
        >
      </div>
    </div>
  </div>
  <mat-divider class="bg-mat-ef-gray-one-200 mx-6"></mat-divider>
  <div class="my-0 mx-auto">
    <div
      class="footerLegalNotice py-10 px-6 md:px-2 text-sm text-mat-ef-gray-one-200"
    >
      <div class="grid grid-cols-1 md:grid-cols-3">
        <div class="col-span-1" *ngIf="legalNotice">
          <div class="px-0 md:px-4">
            <span
              class="font-efSans font-bold h-[50px] mb-4 uppercase flex items-end"
              >{{ legalNotice.col1?.header }}</span
            >
            <div [innerHTML]="legalNotice.col1.text"></div>
          </div>
        </div>
        <div cclass="col-span-1" *ngIf="legalNotice">
          <div class="px-0 md:px-4">
            <span
              class="font-efSans font-bold h-[50px] mb-4 uppercase flex items-end"
              >{{ legalNotice.col2?.header }}</span
            >
            <div [innerHTML]="legalNotice.col2.text"></div>
          </div>
        </div>
        <div class="col-span-1" *ngIf="legalNotice">
          <div class="px-0 md:px-4">
            <span
              class="font-efSans font-bold h-[50px] mb-4 uppercase flex items-end"
              >{{ legalNotice.col3?.header }}</span
            >
            <div [innerHTML]="legalNotice.col3.text"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
