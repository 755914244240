import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  CptGoogleTagmanagerService,
  EcondaService
} from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import {
  DayTimeDTO,
  DealerDTO,
  WorkingDays
} from '../../../../services/dealers.service'
import { SharedService } from '../../../../services/shared.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'
import { WorkingHoursService } from '../../../../services/working-hours.service'
import { CallRequestPopupComponent } from '../../../vehicle-details/call-request-popup/call-request-popup.component'
import { DealerDetailsPopupComponent } from '../../../vehicle-details/dealer-details-popup/dealer-details-popup.component'
declare const google
@Component({
  selector: 'customer-portal-app-revolution-dealership-summary-molecule',
  templateUrl: './revolution-dealership-summary-molecule.component.html'
})
export class RevolutionDealershipSummaryMoleculeComponent {
  public day = ''
  loading = false
  map
  constructor (
    public dialog: MatDialog,
    private readonly logger: NGXLogger,
    private readonly dataService: DataService,
    private readonly workingHoursService: WorkingHoursService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly sharedService: SharedService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly econdaService: EcondaService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() changeDealerSeller = new EventEmitter()

  @Input()
  public vehicle: VehicleDTOExtended

  public dealer: DealerDTO
  @Input()
  set setDealer (dealer: DealerDTO) {
    const previousDealer: DealerDTO = this.sharedService.deepCopy(this.dealer)
    this.dealer = dealer
    // refresh only if we changed dealer
    if (previousDealer?.googlePlaceId !== this.dealer?.googlePlaceId) {
      this.refreshMapDetails()
    }
  }

  @Input()
  public dealertype: 'sales' | 'service'

  @Input()
  public view: string

  public lat = 0
  public lng = 0
  placeId: string
  url: string

  zoom = 17
  streetViewControl = false
  zoomControl = false
  currentDate: Date
  service: DayTimeDTO[] = []
  sales: DayTimeDTO[] = []
  rating = 0

  open = {
    sales: false,
    service: false
  }

  dateToday: {
    sales: string
    service: string
  }

  public country: string
  workingDays: {
    sales: WorkingDays[]
    service: WorkingDays[]
  }

  sendContentModulesMiniCTAData (contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Mini CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'Mini CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
    this.econdaService.send({
      content: 'Vehicle action',
      Target: [
        'Change preffered dealer',
        this.sharedService.translateLink(contentModuleLabel),
        1,
        'd'
      ]
    })
  }

  sendOutboundLinkData (outboundLinkType: string, dealer: DealerDTO): void {
    this.cptGtmService.sendOutboundData(outboundLinkType, dealer)
  }

  refreshMapDetails (map?): void {
    if (map != null) {
      this.map = map
    }
    if (this.dealer != null && this.map != null) {
      this.initDealer()
    }
  }

  initDealer (): void {
    this.workingDays = {
      sales: [],
      service: []
    }
    this.dateToday = {
      sales: '',
      service: ''
    }

    this.country = this.remoteConfigService.get('country.code')

    this.lat = this.dealer.latitude
    this.lng = this.dealer.longitude
    this.cdRef.detectChanges() // to prevent console error

    this.placeId = this.dealer.googlePlaceId
    this.currentDate = new Date()
    this.service =
      this.dealer.openingHours?.service != null
        ? this.dealer.openingHours.service
        : []
    this.sales =
      this.dealer.openingHours?.sales != null
        ? this.dealer.openingHours.sales
        : []
    if (this.sales.length > 0) {
      const salesWorkingHours = this.workingHoursService.calculateIfWorking(
        this.sales,
        this.currentDate,
        'sales'
      )
      this.open.sales = salesWorkingHours.open
      this.day = salesWorkingHours.day
      this.dateToday.sales = salesWorkingHours.dateToday
    }
    if (this.service.length > 0) {
      const serviceWorkingHours = this.workingHoursService.calculateIfWorking(
        this.service,
        this.currentDate,
        'service'
      )
      this.open.service = serviceWorkingHours.open
      this.day = serviceWorkingHours.day
      this.dateToday.service = serviceWorkingHours.dateToday
    }

    if (
      this.placeId !== '' &&
      this.placeId !== null &&
      this.placeId !== undefined
    ) {
      this.loading = true
      this.cdRef.detectChanges() // to prevent console error
      const request = {
        placeId: this.placeId
      }

      const service = new google.maps.places.PlacesService(this.map)

      service.getDetails(
        request,
        (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            if (place.rating != null) {
              this.rating = place.rating.toFixed(1, 10)
              this.url = place?.url
            }
          } else {
            this.logger.debug('Google rating error')
          }
          this.loading = false
          this.cdRef.detectChanges() // to prevent console error
        },
        () => {
          this.logger.debug('Google rating error2')
        }
      )
    }
  }

  openCallRequestPopup (): void {
    this.dialog.open(CallRequestPopupComponent, {
      data: {
        vehicle: this.vehicle,
        type: 'dealer',
        dealer: this.dealer
      },
      panelClass: 'mat-dialog-cpt'
    })

    this.sendOutboundLinkData('Phone Click', this.dealer)
  }

  openDealerPopup (dealer: DealerDTO): void {
    this.dialog.open(DealerDetailsPopupComponent, {
      data: dealer,
      panelClass: ['mat-dialog-cpt', 'dealer-details']
    })
  }
}
