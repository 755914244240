<div
  class="text-lg md:text-xl font-bold mb-5 mt-14 text-mat-ef-revolution-primary-500"
  *ngIf="leasingWarrantySPLoaded"
>
  <T
    str="Leasing and warranty"
    key="customerPortal.customer-portal.vehicle.leasing-warranty"
    tags="customer-portal, 3.1"
  ></T>
</div>
<mat-divider
  class="relative w-full text-mat-ef-revolution-supergrey-500"
></mat-divider>

<customer-portal-app-skeleton-loader
  [loading]="leasingWarrantySPLoaded"
></customer-portal-app-skeleton-loader>

<mat-expansion-panel
  *ngIf="leasingWarrantySPLoaded"
  [hideToggle]="leasingIsDisabled"
  [disabled]="leasingIsDisabled"
  #leasing
  (afterExpand)="
    sendContentModulesData(
      true,
      'customerPortal.customer-portal.vehicle.leasing'
    )
  "
  (afterCollapse)="
    sendContentModulesData(
      false,
      'customerPortal.customer-portal.vehicle.leasing'
    )
  "
>
  <mat-expansion-panel-header
    class="p-0 md:px-6"
    [collapsedHeight]="'auto'"
    [expandedHeight]="'auto'"
    [ngClass]="{ 'disable-hover': !leasingWarrantySPLoaded }"
  >
    <mat-panel-title>
      <div class="mt-1 mr-1" *ngIf="leasingWarrantySPLoaded">
        <mat-icon
          class="text-mat-ef-revolution-primary-500"
          [ngClass]="leasing.expanded ? 'blue-icon' : ''"
          svgIcon="car_return"
        ></mat-icon>
      </div>

      <div class="vehicle-expansion-panel-text" *ngIf="leasingWarrantySPLoaded">
        <div
          class="w-full pt-1 overflow-hidden whitespace-nowrap text-ellipsis"
        >
          <div class="flex font-efSans text-sm sm:text-base font-semibold">
            <span>
              <T
                str="Leasing"
                key="customerPortal.customer-portal.vehicle.leasing"
                tags="customer-portal, 3.1"
              ></T
            ></span>
            <span
              *ngIf="leasingCalc"
              class="leasing-title-warning"
              [ngClass]="styleLeasingProgressBar(leasingCalc)"
            >
              <mat-icon
                [svgIcon]="iconType(styleLeasingProgressBar(leasingCalc))"
              ></mat-icon>
            </span>
          </div>
        </div>

        <!-- <div class="panel-heading" *ngIf="!leasingWarrantySPLoaded">
        <ngx-skeleton-loader appearance="line" animation="progress"></ngx-skeleton-loader>
      </div> -->

        <mat-progress-bar
          *ngIf="leasingWarrantySPLoaded && leasingResponse && leasingCalc"
          [ngClass]="styleLeasingProgressBar(leasingCalc)"
          class="h-0.5"
          mode="determinate"
          value="{{ leasingCalc?.percentageLeft.toString() }}"
          dir="rtl"
        >
        </mat-progress-bar>
        <div class="text-sm text-efSans" *ngIf="!leasingWarrantySPLoaded">
          <ngx-skeleton-loader
            appearance="line"
            animation="progress"
          ></ngx-skeleton-loader>
        </div>
        <div
          class="text-sm text-efSans"
          *ngIf="
            leasingWarrantySPLoaded &&
            leasingResponse &&
            leasingCalc &&
            leasingCalc.tillExpires
          "
        >
          <T
            str="The lease expires in {days}."
            key="customerPortal.customer-portal.leasing-expire"
            tags="customer-portal, 3.1"
            [vars]="{ days: leasingCalc.tillExpires }"
          ></T>
        </div>
        <div
          class="text-sm text-efSans"
          *ngIf="
            !leasingWarrantySPLoaded &&
            leasingResponse &&
            leasingCalc &&
            leasingCalc.tillExpires
          "
        >
          <ngx-skeleton-loader
            appearance="line"
            animation="progress"
          ></ngx-skeleton-loader>
        </div>
        <div
          class="text-sm text-efSans"
          *ngIf="
            leasingWarrantySPLoaded &&
            leasingResponse &&
            (!leasingResponse.startDate || !leasingResponse.endDate)
          "
        >
          <T
            str="Further leasing information"
            key="customerPortal.customer-portal.leasing.check-leasing-data"
            tags="customer-portal, 3.1"
          ></T>
        </div>
        <div
          class="text-sm text-efSans"
          *ngIf="
            leasingWarrantySPLoaded && !leasingResponse && !loadingDocuments
          "
        >
          <T
            str="No leasing data."
            key="customerPortal.customer-portal.leasing.no-leasing-data"
            tags="customer-portal, 3.1"
          ></T>
        </div>
        <div
          class="text-sm text-efSans"
          *ngIf="!leasingWarrantySPLoaded && !leasingResponse"
        >
          <ngx-skeleton-loader
            appearance="line"
            animation="progress"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <!-- <mat-divider class="relative w-full text-mat-ef-revolution-supergrey-500"></mat-divider> -->

  <div
    class="ml-5 md:ml-11 mt-2.5 grid grid-cols-1 sm:grid-cols-3 gap-2"
    *ngIf="leasingResponse && leasingWarrantySPLoaded"
  >
    <div class="m-1 grid" *ngIf="leasingResponse.providerName">
      <span
        class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
      >
        <T
          str="Provider name:"
          key="customerPortal.customer-portal.leasing.provider-name"
          tags="customer-portal, 3.1"
        ></T
      ></span>
      <span class="text-sm text-mat-ef-revolution-primary-500 font-normal">{{
        leasingResponse.providerName
      }}</span>
    </div>
    <div
      class="m-1 grid"
      *ngIf="leasingResponse.startDate && leasingResponse.endDate"
    >
      <span
        class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
      >
        <T
          str="Maximum duration"
          key="customerPortal.customer-portal.leasing.startEnd"
          tags="customer-portal, 3.1"
        ></T
      ></span>
      <span class="text-sm text-mat-ef-revolution-primary-500 font-normal"
        >Von {{ leasingResponse.startDate | date: dateFormat }} bis
        {{ leasingResponse.endDate | date: dateFormat }}</span
      >
    </div>
    <div class="m-1 grid" *ngIf="leasingResponse.durationMonth">
      <span
        class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
      >
        <T
          str="Number of months"
          key="customerPortal.customer-portal.leasing.months"
          tags="customer-portal, 3.1"
        ></T
      ></span>
      <span class="text-sm text-mat-ef-revolution-primary-500 font-normal">{{
        leasingResponse.durationMonth
      }}</span>
    </div>
    <div class="m-1 grid" *ngIf="leasingResponse.kmLimit">
      <span
        class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
        ><T
          str="KM Limit per year"
          key="customerPortal.customer-portal.leasing.kmLimit"
          tags="customer-portal, 3.1"
        ></T
      ></span>
      <span class="text-sm text-mat-ef-revolution-primary-500 font-normal"
        >{{ leasingResponse.kmLimit }} km</span
      >
    </div>
    <div class="m-1 grid" *ngIf="leasingResponse.costPerKm">
      <span
        class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
      >
        <T
          str="Cost per additional km"
          key="customerPortal.customer-portal.leasing.costPerKm"
          tags="customer-portal, 3.1"
        ></T
      ></span>
      <span class="text-sm text-mat-ef-revolution-primary-500 font-normal">{{
        leasingResponse.costPerKm
          | currency: currency:'symbol':'1.2-2':currencyLocale
      }}</span>
    </div>
    <div class="m-1 grid" *ngIf="leasingResponse.monthlyRate">
      <span
        class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
        ><T
          str="Monthly installment"
          key="customerPortal.customer-portal.leasing.monthlyRate"
          tags="customer-portal, 3.1"
        ></T
      ></span>
      <span class="text-sm text-mat-ef-revolution-primary-500 font-normal">{{
        leasingResponse.monthlyRate
          | currency: currency:'symbol':'1.2-2':currencyLocale
      }}</span>
    </div>
    <div class="m-1 grid" *ngIf="leasingResponse.remainingValue">
      <span
        class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
        ><T
          str="Remaining value"
          key="customerPortal.customer-portal.leasing.remainingValue"
          tags="customer-portal, 3.1"
        ></T
      ></span>
      <span class="text-sm text-mat-ef-revolution-primary-500 font-normal">{{
        leasingResponse.remainingValue
          | currency: currency:'symbol':'1.2-2':currencyLocale
      }}</span>
    </div>
    <div class="m-1 grid" *ngIf="leasingResponse.multileaseContractId">
      <span
        class="text-sm text-efSans font-semibold text-mat-ef-revolution-primary-500"
        ><T
          str="Contract number"
          key="customerPortal.customer-portal.leasing.contractNumber"
          tags="customer-portal, 3.1"
        ></T
      ></span>
      <span class="text-sm text-mat-ef-revolution-primary-500 font-normal">{{
        leasingResponse.multileaseContractId
      }}</span>
    </div>
  </div>

  <!-- <ngx-skeleton-loader *ngIf="!leasingWarrantySPLoaded" appearance="line" animation="progress">
</ngx-skeleton-loader> -->
  <div
    class="relative -r-10 w-full mt-5"
    *ngIf="leasingResponse && leasingWarrantySPLoaded"
  >
    <button
      *ngIf="leasingResponse.allowContact"
      class="w-full mt-2 md:mt-0 md:w-auto float-right ml-0 md:ml-5 revolution"
      mat-button
      mat-flat-button
      color="secondary"
      (click)="openLeasingContactPopup('MultileaseContact')"
    >
      <T
        str="Manage contact"
        key="customerPortal.customer-portal.leasing.manage.contact"
        tags="customer-portal, 3.1"
      ></T>
    </button>
    <button
      *ngIf="hasLeasingExtensionPopup"
      class="w-full md:w-auto float-right mt-2 md:mt-0 revolution"
      mat-button
      mat-flat-button
      color="secondary"
      (click)="openLeasingContactPopup('Contact')"
    >
      <T
        str="Lease extension"
        key="customerPortal.customer-portal.leasing-extension.contact"
        tags="customer-portal, 3.1"
      ></T>
    </button>
    <button
      *ngIf="hasLeasingDocuments"
      [routerLink]="['/vehicles/' + vehicle.vin + '/vehicle-history/leasing']"
      class="w-full mt-2 md:mt-0 md:w-auto float-right revolution mr-0 md:mr-5"
      mat-button
      mat-stroked-button
      color="secondary"
      (click)="onLeasingDocumentButtonClicked()"
    >
      {{ 'documents' | sharedTranslate }}
    </button>
  </div>
</mat-expansion-panel>
<mat-divider
  class="relative w-full text-mat-ef-revolution-supergrey-500"
></mat-divider>

<ng-container *ngIf="hasWarranty && featuresWarranty && enableEfitData">
  <customer-portal-app-revolution-warranty-molecule
    *ngIf="leasingWarrantySPLoaded"
    [vehicle]="vehicle"
  >
  </customer-portal-app-revolution-warranty-molecule>

  <customer-portal-app-skeleton-loader
    [loading]="leasingWarrantySPLoaded"
  ></customer-portal-app-skeleton-loader>

  <mat-divider
    class="relative w-full text-mat-ef-revolution-supergrey-500"
  ></mat-divider>
</ng-container>
