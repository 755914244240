import { Component, OnInit, HostListener } from '@angular/core'
import { PwaService } from '@inside-hub-app/customer-portal-shared'
import { LocalStorageService } from '../../../services/local-storage.service'

@Component({
  selector: 'customer-portal-pwa-detector',
  templateUrl: './pwa-detector.component.html'
})
export class PwaDetectorComponent implements OnInit {
  public isIos = false
  public isPwa = false
  public isMobile = false
  public isInstalled = false
  constructor(
    private readonly pwaService: PwaService,
    private readonly localStorage: LocalStorageService
  ) {}

  @HostListener('window:appinstalled', ['$event'])
  afterInstall(e): void {
    localStorage.setItem('pwaInstalled', 'true')
  }

  ngOnInit(): void {
    this.isIos = this.pwaService.checkIos()
    this.isPwa = this.pwaService.checkPwa()
    this.isMobile = this.pwaService.checkMobile()
    this.isInstalled = this.pwaService.checkPwaInstalled()
  }
}
