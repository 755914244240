import { Component, OnInit } from '@angular/core'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
@Component({
  selector: 'customer-portal-app-impressum',
  templateUrl: './impressum.component.html'
})
export class ImpressumComponent implements OnInit {
  constructor(
    public remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {}

  ngOnInit(): void {}
}
