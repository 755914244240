<div
  data-cy="leasing-expiry-next-service-form"
  class="cpt-modal EditLeasingExpiryComponent-revolution-dialog relative dialog-content-p-0 mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <ng-container
      *ngTemplateOutlet="
        sharedService.template('transifexTranslation');
        context: {
          str: data.property,
          prefix: 'customerPortal.customer-portal.edit-',
          suffix: '.title',
          noLowerCase: true
        }
      "
    ></ng-container>
  </h2>

  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="my-2.5 flex justify-between w-full">
        <mat-form-field class="date" class="w-full">
          <input
            data-cy="leasing-expiry-next-service-form-input"
            matInput
            (click)="picker.open()"
            readonly
            [min]="now"
            [max]="fiveYearsAfter"
            [matDatepicker]="picker"
            placeholder="{{
              data.property
                | translate
                  : {
                      _key: 'customerPortal.customer-portal.' + data.property,
                      _tags: 'customer-portal, notranslate'
                    }
            }}"
            formControlName="date"
          />
          <mat-datepicker-toggle
            class="calendar-size"
            matPrefix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker
            [calendarHeaderComponent]="customHeader"
            #picker
            startView="multi-year"
            (yearSelected)="chosenYearHandler($event)"
            (monthSelected)="chosenMonthHandler($event, picker)"
            (opened)="addScrolling(picker)"
          ></mat-datepicker>
          <span (click)="picker.open()" matSuffix>
            <mat-icon class="primary" svgIcon="create"></mat-icon>
          </span>
          <mat-error *ngIf="form.controls.date.invalid">
            {{
              transifexService.getErrorTranslation(
                form.controls.date,
                'customerPortal.customer-portal.edit-' +
                  data.property +
                  '.error.'
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <mat-divider class="mt-0"></mat-divider>

  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>

    <button
      data-cy="leasing-expiry-next-service-form-submit"
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      (click)="update()"
      cdkFocusInitial
      [disabled]="loading || !form.valid"
    >
      <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
      <T
        str="Update"
        key="customerPortal.customer-portal.general.actions.update"
        tags="customer-portal, 3.1"
      ></T>
    </button>
  </mat-dialog-actions>
</div>
