<div class="flex flex-col md:flex-row px-6 md:mb-12">
  <ng-container
    *ngFor="let trackStep of stepList; let index = index"
    [ngTemplateOutlet]="stepper"
    [ngTemplateOutletContext]="{
      data: {
        dateName: trackStep.dateName,
        step: trackStep.step,
        iconNgClass: trackStep.iconNgClass,
        icon: trackStep.icon,
        position: index
      }
    }"
  >
  </ng-container>
</div>

<ng-template let-d="data" #stepper>
  <div
    class="flex flex-col md:flex-row justify-start md:justify-center md:items-center"
    [ngClass]="{
      'track-trace-active': isActive(d),
      'flex-1': d.position !== 0
    }"
  >
    <div
      *ngIf="d.position !== 0"
      class="flex-1 min-h-[80px] md:min-h-[auto] md:px-2"
    >
      <mat-progress-bar
        [value]="getProgress(d)"
        class="rotate-90 md:rotate-0 max-w-[64px] translate-y-[38px] md:max-w-[100%] md:translate-y-0"
      >
      </mat-progress-bar>
    </div>

    <div
      class="mat-icon-wrapper max-w-[64px] min-w-[64px] max-h-[64px] min-h-[64px] rounded-full bg-mat-ef-revolution-grey-900 relative"
      [ngClass]="d.iconNgClass"
    >
      <mat-icon
        class="w-full h-full max-w-[32px] max-h-[32px] fill-mat-ef-revolution-primary-500"
        [svgIcon]="d.icon"
      >
      </mat-icon>

      <div
        class="absolute top-2.5 md:top-[70px] left-20 md:-left-4 w-24 flex flex-col text-mat-ef-revolution-primary-500"
        [ngClass]="{ 'top-[18px]': !data?.[d.dateName] }"
      >
        <div class="font-semibold md:text-center">
          <ng-container
            *ngTemplateOutlet="
              sharedService.template('transifexTranslation');
              context: {
                str: d.step,
                prefix: this.prefix
              }
            "
          ></ng-container>
        </div>
        <div class="md:text-center" *ngIf="d?.dateName">
          {{ data?.[d.dateName] | date: dateFormat }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
