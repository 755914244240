<div
  class="w-full flex justify-center justify-items-center h-fit py-0 px-4 bg-white"
>
  <div class="notification-panel-wrapper">
    <customer-portal-app-appointments
      [hidden]="loadingAppointments"
      [setUpcomingAppointments]="appointments"
      [loadingAppointments]="loadingAppointments"
      (action)="action($event)"
      class="min-h-full h-fit flex-1 md:max-w-[50%]"
    >
    </customer-portal-app-appointments>
    <div
      class="min-h-full h-fit flex-1 md:max-w-[50%]"
      [hidden]="!loadingAppointments"
    >
      <ng-container *ngTemplateOutlet="loader"> </ng-container>
    </div>
    <mat-divider class="md:hidden mx-6"></mat-divider>
    <customer-portal-app-notifications
      [hidden]="loadingNotifications"
      [setNotifications]="notifications"
      [setVehicles]="userVehicles"
      [loadingNotifications]="loadingNotifications"
      (action)="action($event)"
      class="min-h-full h-fit flex-1 md:max-w-[50%]"
    >
    </customer-portal-app-notifications>
    <div
      class="min-h-full h-fit flex-1 md:max-w-[50%]"
      [hidden]="!loadingNotifications"
    >
      <ng-container *ngTemplateOutlet="loader"> </ng-container>
    </div>
  </div>
</div>

<ng-template #loader>
  <div class="w-full h-full p-6 py-20 flex justify-center items-center">
    <div class="flex-1 flex flex-col justify-center items-center">
      <div class="p-2 text-mat-ef-revolution-grey-two-500 font-efSans">
        <T
          str="Data is loading, please be patient"
          key="customerPortal.customer-portal.loading-data.please-wait"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <customer-portal-ef-spinner
        [color]="'mat-ef-revolution-grey-two-500'"
        [opacity]="0.7"
      ></customer-portal-ef-spinner>
    </div>
  </div>
</ng-template>
