<div
  [hidden]="!hidden"
  class="cpt-modal relative dialog-content-p-0 ef-scrollbar mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="Update name and salutation"
      key="customerPortal.customer-portal.details.change.name"
      tags="customer-portal, 3.1"
    ></T>
  </h2>
  <mat-dialog-content class="mat-typography">
    <form class="form" [formGroup]="nameForm" class="grid gap-2">
      <div class="grid grid-cols-2 gap-5 mt-3">
        <mat-form-field appearance="legacy" class="name-salutation">
          <mat-label>{{ 'salutation' | sharedTranslate }}</mat-label>
          <mat-select
            formControlName="salutation"
            required
            [errorStateMatcher]="matcher"
          >
            <mat-option
              *ngFor="let salutation of salutations"
              [value]="salutation"
            >
              {{ 'salutation-' + salutation?.toLowerCase() | sharedTranslate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="name-salutation">
          <mat-label
            ><T
              str="Title"
              key="customerPortal.customer-portal.register.user-title"
              tags="customer-portal, 3.1"
            ></T
          ></mat-label>
          <mat-select formControlName="title" [errorStateMatcher]="matcher">
            <mat-option *ngFor="let title of titles" [value]="title">
              {{ 'title-' + title?.toLowerCase() | sharedTranslate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex flex-col md:flex-row gap-2 md:gap-5">
        <mat-form-field appearance="legacy" class="name-salutation flex-1">
          <mat-label>{{ 'first-name' | sharedTranslate }}</mat-label>
          <input
            matInput
            required
            formControlName="firstName"
            [errorStateMatcher]="matcher"
          />
          <mat-error *ngIf="nameForm.get('firstName').errors">
            {{
              transifexService.getErrorTranslation(
                nameForm.get('firstName'),
                'customerPortal.customer-portal.name-salutation.error.',
                {
                  errKey: {
                    maxlength: 'max-length-characters',
                    pattern: 'only-numbers-letters-allowed'
                  }
                }
              )
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="name-salutation flex-1">
          <mat-label>{{ 'last-name' | sharedTranslate }}</mat-label>
          <input
            matInput
            required
            formControlName="lastName"
            [errorStateMatcher]="matcher"
          />
          <mat-error *ngIf="nameForm.get('lastName').errors">
            {{
              transifexService.getErrorTranslation(
                nameForm.get('lastName'),
                'customerPortal.customer-portal.name-salutation.error.',
                {
                  errKey: {
                    maxlength: 'max-length-characters',
                    pattern: 'only-numbers-letters-allowed'
                  }
                }
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <mat-divider class="form-divider"></mat-divider>
    </form>
    <span class="mat-error" *ngIf="errorUpdate">{{
      'general-error-message' | sharedTranslate
    }}</span>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="dialog-close-button"
      [mat-dialog-close]="false"
      (click)="setNameFields()"
    >
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <button
      id="saveButton"
      class="revolution"
      mat-button
      mat-flat-button
      color="secondary"
      (click)="updateName()"
      [disabled]="disabled || !nameForm.valid"
    >
      <div *ngIf="!loading">
        {{ 'save' | sharedTranslate }}
      </div>
      <mat-spinner class="m-2" *ngIf="loading" diameter="20"></mat-spinner>
    </button>
  </mat-dialog-actions>
</div>

<div
  [hidden]="hidden"
  class="cpt-modal relative dialog-content-p-0 ef-scrollbar mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    {{ 'success' | sharedTranslate }}
  </h2>
  <mat-dialog-content class="mat-typography">
    <div class="name-form">
      <T
        str="Salutation and name were successfully updated."
        key="customerPortal.customer-portal.details.change.nameChanged"
        tags="customer-portal, 3.1"
      ></T>
    </div>
  </mat-dialog-content>
  <mat-divider class="mt-0"></mat-divider>
  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <button
      class="revolution"
      mat-button
      mat-flat-button
      color="secondary"
      [mat-dialog-close]="true"
      cdkFocusInitial
    >
      <T
        str="Close"
        key="customerPortal.customer-portal.general.actions.close"
        tags="customer-portal, 3.1"
      ></T>
    </button>
  </mat-dialog-actions>
</div>
