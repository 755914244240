import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { UserService } from '@inside-hub-app/customer-portal-b2c-client'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { InstantErrorStateMatcherService } from '../../../services/instant-error-state-matcher.service'
import { SharedService } from '../../../services/shared.service'
import {
  FrequentDriverDialogData,
  VehicleFrequentDriverDTO,
  VehiclesService
} from '../../../services/vehicles.service'

@Component({
  selector: 'customer-portal-app-frequent-driver-popup',
  templateUrl: './frequent-driver-popup.component.html'
})
export class FrequentDriverPopupComponent implements OnInit {
  matcher = new InstantErrorStateMatcherService()
  salutations
  salutationFirstNameMaxLength: number
  salutationLastNameMaxLength: number

  mostFrequentDriverFirstNamePattern: string
  mostFrequentDriverLastNamePattern: string
  frequentDriverForm
  saving: boolean
  country: string

  constructor(
    private readonly dialog: MatDialog,
    private readonly vehicleService: VehiclesService,
    private readonly userService: UserService,
    private readonly dialogRef: MatDialogRef<FrequentDriverPopupComponent>,
    public sharedService: SharedService,
    public transifexService: TransifexService,
    @Inject(MAT_DIALOG_DATA) public data: FrequentDriverDialogData,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.userService.enums().subscribe(value => {
      this.salutations = value.salutations
    })
    this.salutationFirstNameMaxLength = this.remoteConfigService.get(
      'regEx.mostFrequentDriverRegEx.firstName.maxLength'
    )
    this.salutationLastNameMaxLength = this.remoteConfigService.get(
      'regEx.mostFrequentDriverRegEx.lastName.maxLength'
    )
    this.mostFrequentDriverFirstNamePattern = this.remoteConfigService.get(
      'regEx.mostFrequentDriverRegEx.firstName.pattern'
    )
    this.mostFrequentDriverLastNamePattern = this.remoteConfigService.get(
      'regEx.mostFrequentDriverRegEx.lastName.pattern'
    )
  
    this.frequentDriverForm = new FormGroup({
      firstName: new FormControl<string | null>(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.mostFrequentDriverFirstNamePattern),
          Validators.maxLength(
            this.remoteConfigService.get(
              'regEx.mostFrequentDriverRegEx.firstName.maxLength'
            )
          )
        ])
      ),
      lastName: new FormControl<string | null>(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.mostFrequentDriverLastNamePattern),
          Validators.maxLength(
            this.remoteConfigService.get(
              'regEx.mostFrequentDriverRegEx.lastName.maxLength'
            )
          )
        ])
      ),
      salutation: new FormControl<string | null>(null, Validators.required)
    })
  }

  ngOnInit(): void {
    this.country = this.remoteConfigService.get('country.code')
    if (this.data.mostFrequentDriver != null) {
      this.frequentDriverForm.controls.firstName.setValue(
        this.data.mostFrequentDriver.firstName
      )
      this.frequentDriverForm.controls.lastName.setValue(
        this.data.mostFrequentDriver.lastName
      )
      this.frequentDriverForm.controls.salutation.setValue(
        this.data.mostFrequentDriver.salutation
      )
    }
  }

  saveChanges(): void {
    this.saving = true
    const data: VehicleFrequentDriverDTO = {
      firstName: this.frequentDriverForm.get('firstName').value,
      lastName: this.frequentDriverForm.get('lastName').value,
      salutation: this.frequentDriverForm.get('salutation').value
    }
    if (this.data.ownershipId != null) {
      data.ownershipId = this.data.ownershipId
    }
    this.vehicleService.updateFrequentDriver(this.data.id, data).subscribe(
      value => {
        this.saving = false
        this.dialogRef.close({ success: true, value })
      },
      () => {
        this.saving = false
        this.dialogRef.close({ success: false })
      }
    )
  }
}
