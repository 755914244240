import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import { SharedService } from '../../../../services/shared.service'

@Component({
  selector: 'customer-portal-app-faq-card',
  templateUrl: './faq-card.component.html'
})
export class FAQCardComponent {
  @Input() alwaysSmall = false

  constructor(
    public sharedService: SharedService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly router: Router
  ) {}

  goToFAQ(): void {
    void this.router.navigate(['/faq'])
  }

  sendContentModulesCTAData(contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }
}
