<div
  class="cpt-modal MultilanguagePopupComponent-revolution relative dialog-content-p-0 mat-dialog-actions-pb-20"
>
  <ng-container>
    <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
      <T
        str="Update language"
        key="customerPortal.customer-portal.details.change.language"
        tags="customer-portal, 3.1"
      ></T>
    </h2>

    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </ng-container>

  <mat-dialog-content
    class="py-0 px-2 m-0 overflow-y-auto overflow-x-hidden"
    *ngIf="user"
  >
    <ng-container>
      <div class="flex flex-col md:flex-row gap-5">
        <div class="flex-1" *ngFor="let lan of languages">
          <button
            *ngIf="lan.toString().toLowerCase() === primaryLang.toLowerCase()"
            mat-button
            mat-flat-button
            color="secondary"
            class="w-full revolution text-base h-12 py-0 px-2.5 min-w-full"
            [disabled]="saving"
            (click)="changeLanguage(lan)"
            routerLinkActive="active"
          >
            <ng-container *ngTemplateOutlet="buttonBody; context: { lan: lan }">
            </ng-container>
          </button>
          <button
            *ngIf="lan.toString().toLowerCase() !== primaryLang.toLowerCase()"
            mat-button
            mat-stroked-button
            color="secondary"
            class="w-full revolution text-base h-12 py-0 px-2.5 min-w-full"
            [disabled]="saving"
            (click)="changeLanguage(lan)"
            routerLinkActive="active"
          >
            <ng-container *ngTemplateOutlet="buttonBody; context: { lan: lan }">
            </ng-container>
          </button>
        </div>
      </div>
    </ng-container>

    <div *ngIf="error">
      {{ 'general-error-message' | sharedTranslate }}
    </div>
  </mat-dialog-content>
</div>

<ng-template #buttonBody let-lan="lan">
  <mat-spinner
    [diameter]="16"
    *ngIf="saving && lan.toString().toLowerCase() === primaryLang.toLowerCase()"
  >
  </mat-spinner>
  <span>
    {{
      {
        str: lan,
        skipShared: true,
        prefix: 'customerPortal.customer-portal.language.'
      } | transifexTranslate
    }}
  </span>
</ng-template>
