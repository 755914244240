<div
  class="block fixed md:hidden min-h-full w-full overflow-auto max-h-full z-[999]"
  *ngIf="show"
>
  <div class="bg-mat-ef-revolution-grey-500">
    <div class="pt-10 pb-6 px-4 leading-6">
      <p
        class="font-efSans flex items-center justify-between px-2 py-4 cursor-pointer"
      >
        <span class="text-mat-ef-revolution-primary-500">Auto finden</span>
        <mat-icon
          svgIcon="arrow_right"
          class="text-mat-ef-revolution-secondary-500"
        ></mat-icon>
      </p>
      <a
        class="font-efSans-primary px-2 py-4 cursor-pointer block"
        href="/de/standorte"
        target="_blank"
      >
        Standorte
      </a>
      <a
        class="font-efSans-primary px-2 py-4 cursor-pointer block"
        href="/de/unsere-serviceleistungen"
        target="_blank"
      >
        Service
      </a>
      <p
        class="font-efSans flex items-center justify-between px-2 py-4 cursor-pointer"
      >
        <span class="text-mat-ef-revolution-primary-500">Emil Frey Gruppe</span>
        <mat-icon
          svgIcon="arrow_right"
          class="text-mat-ef-revolution-secondary-500"
        ></mat-icon>
      </p>
      <!-- style="background-color: #016900;" -->
      <div class="pt-10">
        <div class="flex flex-wrap gap-4 md:gap-6">
          <div class="block w-full">
            <div class="w-full">
              <a
                href="/de/aktionen/details/land-rover-macht-autotraeume-wahr-28"
                target="_blank"
              >
                <div
                  class="flex items-center bg-mat-ef-revolution-green-one-500 justify-between h-24 px-2 py-4 rounded-lg"
                >
                  <div
                    class="flex items-center justify-between md:flex-shrink-0"
                  >
                    <h3
                      class="flex-shrink-0 font-canelablack text-white text leading-6 max-w-full text-xl text-dark-text"
                    >
                      <T
                        str="All your dreams of Land Rover come true"
                        key="customerPortal.customer-portal.land-rover.makes.car.dream"
                        tags="customer-portal, 3.1"
                      ></T>
                    </h3>
                  </div>
                  <mat-icon svgIcon="arrow_forward_ios" class="text-white">
                  </mat-icon>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 gap-4 md:gap-6 md:mt-24 mt-16">
          <div class="block w-full">
            <div class="w-full">
              <a
                href="/de/aktionen/details/ford-fahren-mit-29-leasing-und-kundenvorteil-25"
                target="_blank"
              >
                <div
                  class="flex items-center h-24 px-2 py-4 rounded-lg bg-white"
                >
                  <div
                    class="flex md:block items-center justify-between w-full md:w-auto"
                  >
                    <h4
                      class="font-bold leading-3 md:leading-2 md:mb-2 md:text-xl text-sm font-efSans"
                    >
                      <T
                        str="Drive Ford with a leasing at 2,9% and client benefits!"
                        key="customerPortal.customer-portal.ford.leasing.and.customer.benefits"
                        tags="customer-portal, 3.1"
                      ></T>
                    </h4>
                  </div>
                  <mat-icon
                    svgIcon="arrow_forward_ios"
                    class="text-mat-ef-revolution-secondary-500"
                  >
                  </mat-icon>
                </div>
              </a>
            </div>
          </div>
          <div class="block w-full">
            <div class="w-full">
              <a
                href="/de/aktionen/details/kia-faehrt-mit-boni-und-top-leasing-in-den-sommer-26"
                target="_blank"
              >
                <div
                  class="flex items-center h-24 px-2 py-4 rounded-lg bg-white"
                >
                  <div
                    class="flex md:block items-center justify-between w-full md:w-auto"
                  >
                    <h4
                      class="font-bold leading-3 md:leading-2 md:mb-2 md:text-xl text-sm font-efSans"
                    >
                      <T
                        str="Drive KIA this summer thanks to bonuses and a top leasing."
                        key="customerPortal.customer-portal.kia.bonuses.and.top"
                        tags="customer-portal, 3.1"
                      ></T>
                    </h4>
                  </div>
                  <mat-icon
                    svgIcon="arrow_forward_ios"
                    class="text-mat-ef-revolution-secondary-500"
                  >
                  </mat-icon>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- <mat-divider class="mt-6 mb-6 border-mat-ef-revolution-grey-two-900 border-t-1"></mat-divider>
            <div class="flex justify-end">
                <div class="flex items-center cursor-pointer">
                    <mat-icon (click)="openLanguageChange(user)" svgIcon="language" class="cursor-pointer">
                    </mat-icon>
                    <span class="pl-2 leading-4 font-efSans">
                        {{lang ? lang : '-'}} </span>
                </div>
            </div> -->
    </div>
  </div>
</div>
