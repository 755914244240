import { Component, Input } from '@angular/core'
import { BasicDocumentDTOExtended, VehicleDocumentsService } from '../../../services/vehicle-documents.service'
@Component({
  selector: 'customer-portal-app-document-icon',
  templateUrl: './document-icon.component.html'
})
export class DocumentIconComponent {
  document: BasicDocumentDTOExtended
  @Input() set setDocument (document: BasicDocumentDTOExtended) {
    this.document = document
  }

  constructor (
    private readonly vehicleDocumentsService: VehicleDocumentsService
  ) { }

  filterOnlyDocument (doc: BasicDocumentDTOExtended): boolean {
    return this.vehicleDocumentsService.filterOnlyDocument(doc)
  }

  filterOnlyDocumentSales (doc: BasicDocumentDTOExtended): boolean {
    return this.vehicleDocumentsService.filterOnlyDocumentSales(doc)
  }

  filterService (doc: BasicDocumentDTOExtended): boolean {
    return this.vehicleDocumentsService.filterService(doc)
  }
}
