import { Component, type OnInit, type OnDestroy } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { NGXLogger } from 'ngx-logger'
import {
  CptGoogleTagmanagerService,
  ResizeService, type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'
import { SharedService } from '../../../services/shared.service'
import { DataService } from '../../../services/data.service'
import { LocalStorageService } from '../../../services/local-storage.service'
import {
  type DocumentType,
  type BasicDocumentDTOExtended,
  VehicleDocumentsService
} from '../../../services/vehicle-documents.service'
import { combineLatest } from 'rxjs'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { AcceptDeclineOfferPopupComponent } from './accept-decline-offer-popup/accept-decline-offer-popup.component'
import { differenceInCalendarDays, parseISO } from 'date-fns'

@Component({
  selector: 'customer-portal-app-revolution-my-documents',
  templateUrl: './my-documents.component.html'
})
export class RevolutionMyDocumentsComponent implements OnInit, OnDestroy {
  public screenSize: SCREEN_SIZE

  pageSize = 10

  skipViewUpdate = false // will be set to true after file is read
  documents: BasicDocumentDTOExtended[] = []
  invoices: BasicDocumentDTOExtended[] = []
  allDocuments: BasicDocumentDTOExtended[] = []
  filteredDocuments: BasicDocumentDTOExtended[] = []

  documentTypes: DocumentType[] = []
  selectedDocumentType: string = null

  allFilteredDocumentsLength: number

  documentSelectFormGroup = new FormGroup({
    documentType: new FormControl<DocumentType | null>(null)
  })

  loadingDocuments: boolean
  downloadStarted: boolean

  documentIndex

  translationKey = {
    offer: null,
    contract: null,
    invoice: null,
    salesContract: null
  }

  unread

  sub = {
    onResize: null,
    onLocalStorageDataChanged: null,
    download: null,
    combineDocumentsInvoices: null,
    combineLoading: null,
    onMyDocumentsInvoicesLoaded: null,
    onUnreadMyDocuments: null
  }

  showPaginator = true
  showSalesInvoiceDownloadButton: boolean
  showServiceInvoiceDownloadButton: boolean
  constructor (
    private readonly vehicleDocumentsService: VehicleDocumentsService,
    public resizeService: ResizeService,
    public readonly sharedService: SharedService,
    private readonly logger: NGXLogger,
    private readonly localStorage: LocalStorageService,
    private readonly dialog: MatDialog,
    private readonly dataService: DataService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.screenSize = this.resizeService.screenSize
    this.selectedDocumentType = null
    this.showSalesInvoiceDownloadButton = this.remoteConfigService.get('documents.showSalesInvoiceDownloadButton')
    this.showServiceInvoiceDownloadButton = this.remoteConfigService.get('documents.showServiceInvoiceDownloadButton')
  }

  onPageChange ($event?): void {
    let result: BasicDocumentDTOExtended[]
    this.documentIndex = undefined
    let pageIndex = 0
    let pageSize = this.pageSize

    if ($event != null) {
      pageIndex = $event.pageIndex
      pageSize = $event.pageSize
    }

    if (this.selectedDocumentType != null) {
      result = this.allDocuments.filter(d => {
        let type = d?.type
        if (d?.type === 'SALES_CONTRACT') {
          type = 'CONTRACT'
        }
        if (d?.type === 'LEASING_CONTRACT') {
          type = 'CONTRACT'
        }
        if (d?.type === 'SALES_OFFER') {
          type = 'OFFER'
        }

        return this.selectedDocumentType.includes(type)
      })
    } else {
      result = this.allDocuments
    }
    this.allFilteredDocumentsLength = result?.length ?? 0
    this.filteredDocuments = result.slice(
      pageIndex * pageSize,
      pageIndex * pageSize + pageSize
    )

    setTimeout(() => {
      this.showPaginator = true
    }, 1000)
  }

  sendContentModulesData (expanded: boolean, contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Accordion',
      expanded ? 'Open' : 'Close',
      contentModuleLabel ?? '',
      `Accordion|${contentModuleLabel ?? ''}`,
      expanded ? 'Open' : 'Close'
    )
  }

  documentRead (document: BasicDocumentDTOExtended): void {
    if (!document.read) {
      this.vehicleDocumentsService.documentRead(document.id, document.type).subscribe(
        data => {
          document.read = true

          // find document in both arrays and emit changes
          this.documents?.forEach(doc => {
            if (document.id === doc.id && document.type === doc.type) {
              this.skipViewUpdate = true
              doc.read = true
              //  update LS
              void this.localStorage.setMyDocuments(this.documents, true)
              //  update data
              this.dataService.myDocumentsLoaded(this.documents)
            }
          })

          this.invoices?.forEach(doc => {
            if (document.id === doc.id && document.type === doc.type) {
              this.skipViewUpdate = true
              doc.read = true
              //  update LS
              void this.localStorage.setInvoices(this.invoices, true)
              //  update data
              this.dataService.invoicesLoaded(this.invoices)
            }
          })
        },
        error => {
          this.logger.log(error)
        }
      )
    }
  }

  getDocumentTranslationKey (d: BasicDocumentDTOExtended): string {
    const type = this.documentTypes.find(({ value }) =>
      value?.includes(d?.type)
    )
    return type?.key
  }

  onDocumentTypeChange (type: DocumentType): void {
    this.showPaginator = false
    this.selectedDocumentType = type.value
    this.onPageChange()
  }

  filterOnlyDocumentSales (doc: BasicDocumentDTOExtended): boolean {
    return this.vehicleDocumentsService.filterOnlyDocumentSales(doc)
  }

  setDocuments (documents): void {
    // check if the data is the same
    const ifEqual = this.sharedService.isEqual(this.allDocuments, documents)
    if (ifEqual) {
      return
    }
    this.showPaginator = false
    this.filteredDocuments = []
    this.allDocuments = documents
    // sort
    this.sharedService.orderArrayByDate(this.allDocuments, 'dateModified')
    // update unread count
    const unreadDocumentsCount = this.vehicleDocumentsService.unreadDocumentsCount(this.allDocuments)
    this.vehicleDocumentsService.unreadMyDocuments(unreadDocumentsCount)
    this.onPageChange()
  }

  showDataIsChangedToast (changed: string): void {
    // dont show notice if dialog or snackbar is open
    if (!this.dialogIsOpen() && this.sharedService.getSnackbarRef() == null) {
      if ((changed === 'myDocuments' || changed === 'invoices') && !this.loadingDocuments) {
        this.sharedService.showSnackbar(
          'customerPortal.customer-portal.data-changed',
          null
        )
      }
    }
  }

  dialogIsOpen (): boolean {
    return Boolean(this.dialog.openDialogs?.length > 0)
  }

  getDocumentTypes (): void {
    this.vehicleDocumentsService.getDocumentTypes().subscribe(types => {
      this.documentTypes = types.filter(el => {
        return (
          el.value === null ||
          el.value === 'OFFER' ||
          el.value === 'CONTRACT' ||
          el.value === 'INVOICE' ||
          el.value === 'SERVICE'
        )
      })
    })
  }

  ngOnInit (): void {
    this.sub.download = this.vehicleDocumentsService.download.subscribe(
      data => {
        this.downloadStarted = data
      }
    )

    this.getDocumentTypes()
    this.sub.onResize = this.resizeService.onResize.subscribe(size => {
      this.screenSize = size
    })

    this.sub.onLocalStorageDataChanged =
      this.localStorage.onLocalStorageDataChanged.subscribe(data => {
        this.showDataIsChangedToast(data)
      })

    if (this.selectedDocumentType == null) {
      this.documentSelectFormGroup
        .get('documentType')
        .setValue(this.documentTypes[0])
    } else {
      const docType = this.documentTypes.find(
        ({ value }) => value === this.selectedDocumentType
      )
      this.documentSelectFormGroup.get('documentType').setValue(docType)
    }

    this.documentSelectFormGroup
      .get('documentType')
      .valueChanges.subscribe((value: DocumentType) => {
        this.onDocumentTypeChange(value)
      })

    this.sub.combineDocumentsInvoices = combineLatest([
      this.dataService.onMyDocumentsLoaded,
      this.dataService.onInvoicesLoaded
    ]).subscribe(res => {
      if (this.skipViewUpdate) {
        this.skipViewUpdate = false
      } else {
        this.documents = res[0]
        this.invoices = res[1]
        const allDocuments = (this.documents ?? []).concat(this.invoices ?? [])
        this.setDocuments(allDocuments)
      }
    })

    this.sub.combineLoading = combineLatest([
      this.dataService.loading.myDocuments,
      this.dataService.loading.invoices
    ]).subscribe(res => {
      this.loadingDocuments = res[0] || res[1]
    })

    this.sub.onUnreadMyDocuments =
    this.vehicleDocumentsService.onUnreadMyDocuments.subscribe(count => {
      this.unread = count
    })
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  showOfferButtons (d: BasicDocumentDTOExtended): boolean {
    // show buttons if its offer that is pending and that was created max 5 days ago
    // disable button if user already declined/accepted
    // show information that user accepted or declined
    if (d.type?.toUpperCase() === 'SALES_OFFER' && d.contractStatus?.toUpperCase() === 'PENDING' && !this.offerAlreadyAcceptedDeclined(d)) {
      if (this.sharedService.dateExists(d.dateCreated)) {
        const dateCreated = parseISO(d.dateCreated.toString())
        const today = new Date()
        const diff = differenceInCalendarDays(today, dateCreated)
        if (diff <= 5) {
          return true
        }
      }
    }
    return false
  }

  offerAlreadyAcceptedDeclined (d: BasicDocumentDTOExtended): boolean {
    if ((d.mailOfferState === 'ACCEPTED' || d.mailOfferState === 'DECLINED') && d.contractStatus?.toUpperCase() === 'PENDING') {
      return true
    }
    return false
  }

  confirmAcceptDeclineOffer (d: BasicDocumentDTOExtended, action: 'accept' | 'decline'): void {
    const data = {
      offer: d,
      action
    }
    const dialogRef = this.dialog.open(AcceptDeclineOfferPopupComponent, {
      data,
      panelClass: ['mat-dialog-cpt']
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== false) {
        if (result.success === true) {
          // reload data - no need to reload invoices
          // same key for accept and decline
          void this.dataService.getMyDocuments()
          this.sharedService.openConfirmationPopup(
            'shared.success',
            'customerPortal.customer-portal.my-documents.decline-offer.success'
          )
        } else {
          this.sharedService.openConfirmationPopup(
            'shared.error',
            'customerPortal.customer-portal.my-documents.decline-offer.failure'
          )
        }
      }
    })
  }

  onMarkAllAsReadClicked (): void {
    this.vehicleDocumentsService.markAllDocumentsAsRead()
  }
}
