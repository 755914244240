import { Component, Inject, type OnInit } from '@angular/core'
import {
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import {
  CptGoogleTagmanagerService,

  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import {
  AppointmentsService,
  type Canton
} from '../../../services/appointments.service'
import { InstantErrorStateMatcherService } from '../../../services/instant-error-state-matcher.service'
import { SharedService } from '../../../services/shared.service'
import { VehicleDTOExtended, VehiclesService } from '../../../services/vehicles.service'

@Component({
  selector: 'customer-portal-app-registration-plate-change-popup',
  templateUrl: './registration-plate-change-popup.component.html'
})
export class RegistrationPlateChangePopupComponent implements OnInit {
  otherLicencePlateCheckbox = false
  matcher = new InstantErrorStateMatcherService()

  licensePlateNormalRegex: string
  licensePlateOfficialRegex: string
  licensePlateAnyRegex
  deLicencePlateRegex

  saving = false
  private readonly vehicleUpdated: VehicleDTOExtended
  country: string
  licencePlateForm

  cantons: Canton[] = []

  constructor (
    private readonly dialogRef: MatDialogRef<RegistrationPlateChangePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public vehicle: VehicleDTOExtended,
    private readonly vehiclesService: VehiclesService,
    private readonly appointmentsService: AppointmentsService,
    public sharedService: SharedService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public transifexService: TransifexService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.vehicleUpdated = vehicle
    this.country = this.remoteConfigService.get('country.code')
    this.licensePlateNormalRegex = this.remoteConfigService.get('regEx.licencePlateRegEx.types.normal.regex')
    this.licensePlateOfficialRegex = this.remoteConfigService.get('regEx.licencePlateRegEx.types.official.regex')
    this.licensePlateAnyRegex = this.remoteConfigService.get('regEx.licencePlateRegEx.types.any.regex')
    this.deLicencePlateRegex = new RegExp(
      this.licensePlateNormalRegex +
        '|' +
        this.licensePlateOfficialRegex,
      'i'
    )
    this.licencePlateForm = new FormGroup({
      licencePlateCanton: new FormControl<string | null>(null),
      licencePlateDE: new FormControl<string | null>(
        null,
        Validators.pattern(this.deLicencePlateRegex)
      ),
      licencePlateCH: new FormControl<string | null>(
        null,
        Validators.compose([
          Validators.pattern(
            this.licensePlateOfficialRegex
          ),
          Validators.maxLength(6)
        ])
      ),
      licencePlateCustom: new FormControl<string | null>(null, Validators.pattern(this.licensePlateAnyRegex))
    })
  }

  ngOnInit (): void {
    this.appointmentsService.getCantons().subscribe(cantons => {
      this.cantons = Object.assign([], cantons)
    })
    this.setRegistrationField()
  }

  otherLicencePlateChanged (checkbox): void {
    this.otherLicencePlateCheckbox = checkbox.checked
    setTimeout(() => {
      this.licencePlateForm.get('licencePlateCustom').setValue(null)
      this.licencePlateForm.get('licencePlateCanton').setValue(null)
      this.licencePlateForm.get('licencePlateDE').setValue(null)
      this.licencePlateForm.get('licencePlateCH').setValue(null)

      this.licencePlateForm.get('licencePlateCustom').markAsTouched()
      this.licencePlateForm.get('licencePlateCanton').markAsTouched()
      this.licencePlateForm.get('licencePlateDE').markAsTouched()
      this.licencePlateForm.get('licencePlateCH').markAsTouched()

      this.licencePlateForm.get('licencePlateCustom').updateValueAndValidity()
      this.licencePlateForm.get('licencePlateCanton').updateValueAndValidity()
      this.licencePlateForm.get('licencePlateDE').updateValueAndValidity()
      this.licencePlateForm.get('licencePlateCH').updateValueAndValidity()
    })
  }

  composeCHRegistration (): string {
    const reg = String(this.licencePlateForm.get('licencePlateCanton').value) +
    ' ' +
    String(this.licencePlateForm.get('licencePlateCH').value)
    return reg
  }

  updateRegistration (): void {
    this.saving = true
    const vehicleUpdated = this.sharedService.deepCopy(this.vehicleUpdated)
    delete vehicleUpdated.pictureType
    delete vehicleUpdated.leasingInfo
    delete vehicleUpdated.dealers
    delete vehicleUpdated.dealer
    delete vehicleUpdated.brandPicture
    delete vehicleUpdated.relations
    if (this.otherLicencePlateCheckbox === true) {
      vehicleUpdated.licensePlate =
        this.licencePlateForm.get('licencePlateCustom').value
    } else {
      if (this.country === 'de') {
        vehicleUpdated.licensePlate =
          this.licencePlateForm.get('licencePlateDE').value
      }
      if (this.country === 'ch') {
        vehicleUpdated.licensePlate =
          String(this.licencePlateForm.get('licencePlateCanton').value) +
          String(this.licencePlateForm.get('licencePlateCH').value)
      }
    }
    this.vehiclesService.updateLicencePlate(vehicleUpdated).subscribe(
      () => {
        this.cptGtmService.sendFormSuccessData('Kennzeichen Ändern', 'Senden')
        this.saving = false
        this.dialogRef.close({
          success: true,
          licensePlate: vehicleUpdated.licensePlate
        })
      },
      () => {
        this.cptGtmService.sendFormSuccessData('Kennzeichen Ändern', 'Senden')
        this.saving = false
        this.dialogRef.close({ success: false })
      }
    )
  }

  setRegistrationField (): void {
    this.licencePlateForm.controls.licencePlateCustom.setValue(this.vehicle.licensePlate)
    if (this.country === 'de') {
      this.licencePlateForm.controls.licencePlateDE.setValue(
        this.vehicle.licensePlate != null ? this.vehicle.licensePlate.trim() : ''
      )
    }
    if (this.country === 'ch') {
      const canton = this.vehicle.licensePlate.substring(0, 2)
      if (this.sharedService.stringExists(canton)) {
        // if canton does not exists in our canton list temprarily add it
        const foundCanton = this.cantons.find(el => {
          return el.id === canton
        })
        if (foundCanton == null) {
          this.cantons.push({ id: canton })
        }
      }
      const reg = this.vehicle.licensePlate
        .substring(2, this.vehicle.licensePlate.length)
        .replace(/ /g, '')
      this.licencePlateForm.get('licencePlateCanton').setValue(canton)
      this.licencePlateForm.controls.licencePlateCH.setValue(reg)
    }
  }
}
