import { Injectable } from '@angular/core'
import { TranslationService } from '@emilfreydigital/transifex-angular'
@Injectable({
  providedIn: 'root'
})
export class SharedTranslationsService {
  constructor (private readonly translationService: TranslationService) {}

  getSharedTranslation (key, params?): string {
    let translation

    switch (key) {
      // salutation
      case 'salutation-ms':
        translation = this.translationService.translate('Ms', {
          _key: 'shared.salutation-ms',
          _tags: 'shared'
        })
        break
      case 'salutation-mrs':
        translation = this.translationService.translate('Mrs', {
          _key: 'shared.salutation-mrs',
          _tags: 'shared'
        })
        break
      case 'salutation-mr':
        translation = this.translationService.translate('Mr', {
          _key: 'shared.salutation-mr',
          _tags: 'shared'
        })
        break
      case 'salutation-mr_mrs':
        translation = this.translationService.translate('Mr and Mrs', {
          _key: 'shared.salutation-mr_mrs',
          _tags: 'shared'
        })
        break
      case 'salutation-family':
        translation = this.translationService.translate('Family', {
          _key: 'shared.salutation-family',
          _tags: 'shared'
        })
        break
      case 'salutation-gentlemen':
        translation = this.translationService.translate('Gentlemen', {
          _key: 'shared.salutation-gentlemen',
          _tags: 'shared'
        })
        break
      case 'salutation-dealership':
        translation = this.translationService.translate('Dealership', {
          _key: 'shared.salutation-dealership',
          _tags: 'shared'
        })
        break
      case 'salutation-lawfirm':
        translation = this.translationService.translate('LawFirm', {
          _key: 'shared.salutation-lawfirm',
          _tags: 'shared'
        })
        break
      case 'salutation-company':
        translation = this.translationService.translate('Company', {
          _key: 'shared.salutation-company',
          _tags: 'shared'
        })
        break
      case 'salutation-undefined':
        translation = this.translationService.translate('Unknown', {
          _key: 'shared.salutation-undefined',
          _tags: 'shared'
        })
        break
      case 'salutation-unspecified':
        translation = this.translationService.translate('Unspecified', {
          _key: 'shared.salutation-unspecified',
          _tags: 'shared'
        })
        break
        // *****************

      // title
      case 'title-prof_dr':
        translation = this.translationService.translate('Prof Dr', {
          _key: 'shared.title-prof_dr',
          _tags: 'shared'
        })
        break
      case 'title-dr':
        translation = this.translationService.translate('Dr', {
          _key: 'shared.title-dr',
          _tags: 'shared'
        })
        break
      case 'title-dipl_ing':
        translation = this.translationService.translate('Dipl Ing', {
          _key: 'shared.title-dipl_ing',
          _tags: 'shared'
        })
        break
      case 'title-mba':
        translation = this.translationService.translate('MBA', {
          _key: 'shared.title-mba',
          _tags: 'shared'
        })
        break
      case 'title-architect':
        translation = this.translationService.translate('Architect', {
          _key: 'shared.title-architect',
          _tags: 'shared'
        })
        break
      case 'title-lawyer':
        translation = this.translationService.translate('Lawyer', {
          _key: 'shared.title-lawyer',
          _tags: 'shared'
        })
        break
      case 'title-reverend':
        translation = this.translationService.translate('Reverend', {
          _key: 'shared.title-reverend',
          _tags: 'shared'
        })
        break
      case 'title-prof':
        translation = this.translationService.translate('Prof', {
          _key: 'shared.title-prof',
          _tags: 'shared'
        })
        break
        // *****************

      // errors
      case 'required-field': // Pflichtfeld.
        translation = this.translationService.translate('Required field.', {
          _key: 'shared.required-field',
          _tags: 'shared'
        })
        break
      case 'only-numbers-allowed': // Es sind nur Zahlen erlaubt. ----------pattern
        translation = this.translationService.translate(
          'Only numbers are allowed.',
          { _key: 'shared.only-numbers-allowed', _tags: 'shared' }
        )
        break
      case 'only-numbers-letters-allowed': // Es sind nur Zahlen und Buchstaben erlaubt. ----------pattern
        translation = this.translationService.translate(
          'Only numbers and letters are allowed.',
          { _key: 'shared.only-numbers-letters-allowed', _tags: 'shared' }
        )
        break
      case 'enter-valid-email': // Geben Sie eine gültige E-Mail ein. ------email, pattern
        translation = this.translationService.translate(
          'Enter a valid email.',
          { _key: 'shared.enter-valid-email', _tags: 'shared' }
        )
        break
      case 'invalid-number': // Ungültige Nummer. -------------pattern
        translation = this.translationService.translate('Invalid number.', {
          _key: 'shared.invalid-number',
          _tags: 'shared'
        })
        break
      case 'current-number': // Nummer kann nicht in dieselbe Nummer geändert werden.
        translation = this.translationService.translate(
          'Number cannot be changed into the same number.',
          { _key: 'shared.current-number', _tags: 'shared' }
        )
        break
      case 'max-length-characters': // Die maximale Länge beträgt {num} Zeichen.---------maxlength
        translation = this.translationService.translate(
          'The maximum length is {num} characters.',
          {
            _key: 'shared.max-length-characters',
            _tags: 'shared',
            num: params?.num
          }
        )
        break
      case 'required-length-characters': // Die erforderliche Länge beträgt {num} Zeichen. -------------maxlength, minlength
        translation = this.translationService.translate(
          'The required length is {num} characters',
          {
            _key: 'shared.required-length-characters',
            _tags: 'shared',
            num: params?.num
          }
        )
        break
      case 'maximum-length-exceeded': // Maximale Länge überschritten -------------maxlength
        translation = this.translationService.translate('Max length exceeded', {
          _key: 'shared.maximum-length-exceeded',
          _tags: 'shared'
        })
        break
      case 'min-password-length': // Das Passwort muss mindestens {num} Zeichen umfassen. -------minlength, length
        translation = this.translationService.translate(
          'The password must contain at least {num} characters.',
          {
            _key: 'shared.min-password-length',
            _tags: 'shared',
            num: params?.num
          }
        )
        break
      case 'password-lowercase-required': // Das Passwort muss mindestens ein Kleinbuchstaben enthalten
        translation = this.translationService.translate(
          'The password must contain at least one lowercase letter',
          { _key: 'shared.password-lowercase-required', _tags: 'shared' }
        )
        break
      case 'password-uppercase-required': // Das Passwort muss mindestens ein Großbuchstabe enthalten
        translation = this.translationService.translate(
          'The password must contain at least one capital letter',
          { _key: 'shared.password-uppercase-required', _tags: 'shared' }
        )
        break
      case 'password-number-required': // Passwort muss mindestens eine Ziffer enthalten.
        translation = this.translationService.translate(
          'Password must contain at least one digit',
          { _key: 'shared.password-number-required', _tags: 'shared' }
        )
        break
      case 'password-invalid': // Invalid password
        translation = this.translationService.translate('Invalid password', {
          _key: 'shared.password-invalid',
          _tags: 'shared'
        })
        break
      case 'password-notsame': // Passwörter stimmen nicht überein.
        translation = this.translationService.translate(
          'Passwords do not match',
          { _key: 'shared.password-notsame', _tags: 'shared' }
        )
        break
      case 'invalid-characters': // Ungültige Zeichen
        translation = this.translationService.translate('Invalid characters', {
          _key: 'shared.invalid-characters',
          _tags: 'shared'
        })
        break
      case 'general-error-message': // Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.
        translation = this.translationService.translate(
          'Something went wrong. Please try again later',
          { _key: 'shared.general-error-message', _tags: 'shared' }
        )
        break
      case 'this-field-required': // Dieses Feld muss ausgefüllt werden.
        translation = this.translationService.translate(
          'This field is required.',
          { _key: 'shared.this-field-required', _tags: 'shared' }
        )
        break
      case 'error': // Fehler
        translation = this.translationService.translate('Error', {
          _key: 'shared.error',
          _tags: 'shared'
        })
        break
      case 'address-validation-failed': // Die eingegebene Adresse scheint ungültig zu sein. Wir haben Vorschläge gefunden für die Richtige.
        translation = this.translationService.translate(
          "We couldn't validate your address. Here are our suggestions.",
          { _key: 'shared.address-validation-failed', _tags: 'shared' }
        )
        break
      case 'confirmation-code-invalid': // Bestätigungscode ist nicht mehr gültig.
        translation = this.translationService.translate(
          'Confirmation code is no longer valid.',
          { _key: 'shared.confirmation-code-invalid', _tags: 'shared' }
        )
        break
      case 'enter-mobile-number': // Bitte geben Sie eine Mobilnummer ein
        translation = this.translationService.translate(
          'Please enter a mobile number',
          { _key: 'shared.enter-mobile-number', _tags: 'shared' }
        )
        break
        // *****************

      // user details
      case 'last-name': // Nachname
        translation = this.translationService.translate('Last name', {
          _key: 'shared.last-name',
          _tags: 'shared'
        })
        break
      case 'first-name': // Vorname
        translation = this.translationService.translate('First name', {
          _key: 'shared.first-name',
          _tags: 'shared'
        })
        break
      case 'e-mail':
      case 'email': // E-Mail
        translation = this.translationService.translate('E-Mail', {
          _key: 'shared.e-mail',
          _tags: 'shared'
        })
        break
      case 'postcode': // Postleitzahl
        translation = this.translationService.translate('Postcode', {
          _key: 'shared.postcode',
          _tags: 'shared'
        })
        break
      case 'city': // Stadt
        translation = this.translationService.translate('City', {
          _key: 'shared.city',
          _tags: 'shared'
        })
        break
      case 'address': // Adresse
        translation = this.translationService.translate('Address', {
          _key: 'shared.address',
          _tags: 'shared'
        })
        break
      case 'password': // Passwort
        translation = this.translationService.translate('Passwort', {
          _key: 'shared.password',
          _tags: 'shared'
        })
        break
      case 'address-suffix': // Adresszusatz
        translation = this.translationService.translate('Address suffix', {
          _key: 'shared.address-suffix',
          _tags: 'shared'
        })
        break
      case 'mobile-number': // Mobilnummer
        translation = this.translationService.translate('Mobilnummer', {
          _key: 'shared.mobile-number',
          _tags: 'shared'
        })
        break
      case 'number': // Nummer
        translation = this.translationService.translate('Number', {
          _key: 'shared.number',
          _tags: 'shared'
        })
        break
      case 'username': // Benutzername
        translation = this.translationService.translate('Username', {
          _key: 'shared.username',
          _tags: 'shared'
        })
        break
      case 'mail': // Brief
      case 'post':
        translation = this.translationService.translate('Mail', {
          _key: 'shared.mail',
          _tags: 'shared'
        })
        break
      case 'date-of-birth': // Geburtstag //Geburtsdatum
        translation = this.translationService.translate('Date of birth', {
          _key: 'shared.date-of-birth',
          _tags: 'shared'
        })
        break
      case 'care-id':
        translation = this.translationService.translate('Care ID', {
          _key: 'shared.care-id',
          _tags: 'shared'
        })
        break
      case 'prefix': // Präfix
        translation = this.translationService.translate('Prefix', {
          _key: 'shared.prefix',
          _tags: 'shared'
        })
        break
      case 'push-notification':
        translation = this.translationService.translate('Push notification', {
          _key: 'shared.push-notification',
          _tags: 'shared'
        })
        break
      case 'push':
        translation = this.translationService.translate('Push', {
          _key: 'shared.push',
          _tags: 'shared'
        })
        break
        // *****************

      // actions
      case 'next': // Weiter
        translation = this.translationService.translate('Next', {
          _key: 'shared.next',
          _tags: 'shared'
        })
        break
      case 'back': // Zurück
        translation = this.translationService.translate('Back', {
          _key: 'shared.back',
          _tags: 'shared'
        })
        break
      case 'forward': // Nach vorne
        translation = this.translationService.translate('Forward', {
          _key: 'shared.forward',
          _tags: 'shared'
        })
        break
      case 'edit': // Bearbeiten
        translation = this.translationService.translate('Edit', {
          _key: 'shared.edit',
          _tags: 'shared'
        })
        break
      case 'success': // Erfolg
        translation = this.translationService.translate('Success', {
          _key: 'shared.success',
          _tags: 'shared'
        })
        break
      case 'successful': // Erfolgreich
        translation = this.translationService.translate('Successful', {
          _key: 'shared.successful',
          _tags: 'shared'
        })
        break
      case 'clear':
      case 'delete': // Löschen
        translation = this.translationService.translate('Delete', {
          _key: 'shared.clear',
          _tags: 'shared'
        })
        break
      case 'save': // Speichern
        translation = this.translationService.translate('Save', {
          _key: 'shared.save',
          _tags: 'shared'
        })
        break
      case 'cancel': // Abbrechen
        translation = this.translationService.translate('Cancel', {
          _key: 'shared.cancel',
          _tags: 'shared'
        })
        break
      case 'update': // Ändern
        translation = this.translationService.translate('Change', {
          _key: 'shared.update',
          _tags: 'shared'
        })
        break
      case 'yes': // Ja
        translation = this.translationService.translate('Yes', {
          _key: 'shared.yes',
          _tags: 'shared'
        })
        break
      case 'skip':
        translation = this.translationService.translate('Skip', {
          _key: 'shared.skip',
          _tags: 'shared'
        })
        break
      case 'accept':
        translation = this.translationService.translate('Accept', {
          _key: 'shared.accept',
          _tags: 'shared'
        })
        break
      case 'decline':
        translation = this.translationService.translate('Decline', {
          _key: 'shared.decline',
          _tags: 'shared'
        })
        break
        // *****************

      // vehicle details
      case 'brand':
        translation = this.translationService.translate('Brand', {
          _key: 'shared.brand',
          _tags: 'shared'
        })
        break
      case 'model': // Modell
        translation = this.translationService.translate('Model', {
          _key: 'shared.model',
          _tags: 'shared'
        })
        break
        // *****************

      // ef, langs, country
      case 'emil-frey-customer-portal': // Emil Frey Kundenportal
        translation = this.translationService.translate(
          'Emil Frey Customer Portal',
          { _key: 'shared.emil-frey-customer-portal', _tags: 'shared' }
        )
        break
      case 'customer-portal': // Kundenportal
        translation = this.translationService.translate('Customer Portal', {
          _key: 'shared.customer-portal',
          _tags: 'shared'
        })
        break
      case 'emil-frey-digital-services': // Emil Frey Digital Services GmbH
        translation = this.translationService.translate(
          'Emil Frey Digital Services GmbH',
          { _key: 'shared.emil-frey-digital-services', _tags: 'shared' }
        )
        break
      case 'emil-frey-group': // Emil Frey Gruppe
        translation = this.translationService.translate('Emil Frey Germany', {
          _key: 'shared.emil-frey-group',
          _tags: 'shared'
        })
        break
      case 'my-emil-frey-customer-portal': // myEmil Frey Kundenportal
        translation = this.translationService.translate(
          'myEmil Frey Customer Portal',
          { _key: 'shared.my-emil-frey-customer-portal', _tags: 'shared' }
        )
        break
      case 'germany':
      case 'deutschland':
      case 'de': // Deutschland
        translation = this.translationService.translate('Germany', {
          _key: 'shared.germany',
          _tags: 'shared'
        })
        break
      case 'switzerland':
      case 'schweiz':
      case 'ch': // Schweiz
        translation = this.translationService.translate('Switzerland', {
          _key: 'shared.switzerland',
          _tags: 'shared'
        })
        break
      case 'german': // Deutsch
        translation = this.translationService.translate('German', {
          _key: 'shared.german',
          _tags: 'shared'
        })
        break
        // *****************

      case 'data-protection': // Datenschutz
        translation = this.translationService.translate('Data protection', {
          _key: 'shared.data-protection',
          _tags: 'shared'
        })
        break
      case 'service': // Service
        translation = this.translationService.translate('Service', {
          _key: 'shared.service',
          _tags: 'shared'
        })
        break
      case 'customer-portal-request': // Sie haben im «myEmil Frey» Kundenportal folgende Anfrage gestellt:
        translation = this.translationService.translate(
          'You have made the following request in the "myEmil Frey" Customer Portal:',
          { _key: 'shared.customer-portal-request', _tags: 'shared' }
        )
        break
      case 'warranty': // Garantie
        translation = this.translationService.translate('Warranty', {
          _key: 'shared.warranty',
          _tags: 'shared'
        })
        break
      case 'register': // Anmelden
        translation = this.translationService.translate('Register', {
          _key: 'shared.register',
          _tags: 'shared'
        })
        break
      case 'salutation': // Anrede
        translation = this.translationService.translate('Salutation', {
          _key: 'shared.salutation',
          _tags: 'shared'
        })
        break
      case 'title':
        translation = this.translationService.translate('Title', {
          _key: 'shared.title',
          _tags: 'shared'
        })
        break
      case 'dealer': // Händler
        translation = this.translationService.translate('Dealer', {
          _key: 'shared.dealer',
          _tags: 'shared'
        })
        break
      case 'communication': // Kommunikation
        translation = this.translationService.translate('Communication', {
          _key: 'shared.communication',
          _tags: 'shared'
        })
        break
      case 'country': // Land
        translation = this.translationService.translate('Country', {
          _key: 'shared.country',
          _tags: 'shared'
        })
        break
      case 'preferences': // Präferenzen
        translation = this.translationService.translate('Preferences', {
          _key: 'shared.preferences',
          _tags: 'shared'
        })
        break
      case 'contact': // Kontakt
        translation = this.translationService.translate('Contact', {
          _key: 'shared.contact',
          _tags: 'shared'
        })
        break
      case 'my-account': // Mein Konto
        translation = this.translationService.translate('My account', {
          _key: 'shared.my-account',
          _tags: 'shared'
        })
        break
      case 'sale': // Verkauf
        translation = this.translationService.translate('Sales', {
          _key: 'shared.sale',
          _tags: 'shared'
        })
        break
      case 'thank-you': // Vielen Dank!
        translation = this.translationService.translate('Thank you', {
          _key: 'shared.thank-you',
          _tags: 'shared'
        })
        break
      case 'address-suggestion': // Adressvorschlag
        translation = this.translationService.translate('Suggestions', {
          _key: 'shared.address-suggestion',
          _tags: 'shared'
        })
        break
      case 'favorites': // Favoriten
        translation = this.translationService.translate('Favourites', {
          _key: 'shared.favorites',
          _tags: 'shared'
        })
        break
      case 'customer-service-advisor': // Kundendienstberater(in)
        translation = this.translationService.translate(
          'Customer service advisor',
          { _key: 'shared.customer-service-advisor', _tags: 'shared' }
        )
        break
      case 'personal': // Privat
        translation = this.translationService.translate('Personal', {
          _key: 'shared.personal',
          _tags: 'shared'
        })
        break
      case 'type': // Typ
        translation = this.translationService.translate('Type', {
          _key: 'shared.type',
          _tags: 'shared'
        })
        break
      case 'fill': // Übernehmen
        translation = this.translationService.translate('Fill', {
          _key: 'shared.fill',
          _tags: 'shared'
        })
        break
      case 'consent-channels-message': // Um Ihnen den bestmöglichen Service zu bieten, bitten wir Sie uns mitzuteilen auf welchen Kanälen wir Sie kontaktieren dürfen:
        translation = this.translationService.translate(
          'In order to offer you the best possible service, please let us know on which channels we may contact you:',
          { _key: 'shared.consent-channels-message', _tags: 'shared' }
        )
        break
      case 'general-info': // Allgemeines
        translation = this.translationService.translate('General information', {
          _key: 'shared.general-info',
          _tags: 'shared'
        })
        break
      case 'other': // Andere
        translation = this.translationService.translate('Other', {
          _key: 'shared.other',
          _tags: 'shared'
        })
        break
      case 'documents': // Dokumente
        translation = this.translationService.translate('Documents', {
          _key: 'shared.documents',
          _tags: 'shared'
        })
        break
      case 'e-mail-confirmed': // E-Mail bestätigt
        translation = this.translationService.translate('E-Mail confirmed', {
          _key: 'shared.e-mail-confirmed',
          _tags: 'shared'
        })
        break
      case 'vehicle-attention': // Fahrzeuge erfordern Ihre Aufmerksamkeit
        translation = this.translationService.translate(
          'Vehicle needs your attention',
          { _key: 'shared.vehicle-attention', _tags: 'shared' }
        )
        break
      case 'preffered-dealer': // Ihr bevorzugter Händler
        translation = this.translationService.translate(
          'Your preferred dealer',
          { _key: 'shared.preffered-dealer', _tags: 'shared' }
        )
        break
      case 'impressum': // Impressum
        translation = this.translationService.translate('Impressum', {
          _key: 'shared.impressum',
          _tags: 'shared'
        })
        break
      case 'account-delete': // Konto löschen
        translation = this.translationService.translate('Delete account', {
          _key: 'shared.account-delete',
          _tags: 'shared'
        })
        break
      case 'customer-number': // Kundennummer
        translation = this.translationService.translate('Customer number', {
          _key: 'shared.customer-number',
          _tags: 'shared'
        })
        break
      case 'my-marketplace': // Mein Automarkt
        translation = this.translationService.translate('My marketplace', {
          _key: 'shared.my-marketplace',
          _tags: 'shared'
        })
        break
      case 'my-vehicles': // Meine Fahrzeuge
        translation = this.translationService.translate('My vehicles', {
          _key: 'shared.my-vehicles',
          _tags: 'shared'
        })
        break
      case 'newsletter-subscribe': // Newsletter bestellen
        translation = this.translationService.translate(
          'Subscribe to newsletter',
          { _key: 'shared.newsletter-subscribe', _tags: 'shared' }
        )
        break

      // footer ch
      case 'emil-frey-select': // Emil Frey Select
        translation = this.translationService.translate('Emil Frey Select', {
          _key: 'shared.emil-frey-select',
          _tags: 'shared'
        })
        break
      case 'alternative-motorisations': // Alternative Antriebe
        translation = this.translationService.translate(
          'Alternative motorisation',
          { _key: 'shared.alternative-motorisations', _tags: 'shared' }
        )
        break
      case 'appointment': // footer.appointments
        translation = this.translationService.translate('Appointment', {
          _key: 'shared.appointment',
          _tags: 'shared'
        })
        break
      case 'other-companies': // footer.other-companies
        translation = this.translationService.translate('Other companies', {
          _key: 'shared.other-companies',
          _tags: 'shared'
        })
        break
      case 'vacancies': // footer.vacancies
        translation = this.translationService.translate('Job opportunities', {
          _key: 'shared.vacancies',
          _tags: 'shared'
        })
        break
      case 'insurance': // Versicherung
        translation = this.translationService.translate('Insurance', {
          _key: 'shared.insurance',
          _tags: 'shared'
        })
        break
      case 'financing': // Finanzierung
        translation = this.translationService.translate('Financing', {
          _key: 'shared.financing',
          _tags: 'shared'
        })
        break
        // *****************

      // footer de
      case 'online-car-dealership': // footer.efd-authohaus
        translation = this.translationService.translate(
          'Emil Frey online car dealership',
          { _key: 'shared.online-car-dealership', _tags: 'shared' }
        )
        break
      case 'emil-frey-autoteilewelt': // footer.efd-autoteilewelt
        translation = this.translationService.translate(
          'Emil Frey Autoteilewelt',
          { _key: 'shared.emil-frey-autoteilewelt', _tags: 'shared' }
        )
        break
      case 'emil-frey-logistic': // footer.efd-logistic
        translation = this.translationService.translate('Emil Frey Logistics', {
          _key: 'shared.emil-frey-logistic',
          _tags: 'shared'
        })
        break
      case 'career': // footer.efd-karriere
        translation = this.translationService.translate('Career', {
          _key: 'shared.career',
          _tags: 'shared'
        })
        break
      case 'emil-frey-fleet': // footer.efd-fleet
        translation = this.translationService.translate('Emil Frey Fleet', {
          _key: 'shared.emil-frey-fleet',
          _tags: 'shared'
        })
        break
      case 'commercial-customers': // footer.commercial-customers
        translation = this.translationService.translate(
          'Commercial customers',
          { _key: 'shared.commercial-customers', _tags: 'shared' }
        )
        break
      case 'emil-frey-financial-services': // footer.efd-financial-services
        translation = this.translationService.translate(
          'Emil Frey Financial Services',
          { _key: 'shared.emil-frey-financial-services', _tags: 'shared' }
        )
        break
      case 'emil-frey-insurance-service': // footer.efd-insurance-service
        translation = this.translationService.translate(
          'Emil Frey insurance service',
          { _key: 'shared.emil-frey-insurance-service', _tags: 'shared' }
        )
        break
        // *****************

      //  mobile menu
      case 'actions': // top-bar.actions
        translation = this.translationService.translate('Actions', {
          _key: 'shared.actions',
          _tags: 'shared'
        })
        break
      case 'configurator': // top-bar.configurator
        translation = this.translationService.translate('Configurator', {
          _key: 'shared.configurator',
          _tags: 'shared'
        })
        break
      case 'car-purchase': // top-bar.autoankauf
        translation = this.translationService.translate('Car purchase', {
          _key: 'shared.car-purchase',
          _tags: 'shared'
        })
        break
      case 'electromobility': // top-bar.electromobility
        translation = this.translationService.translate('Electromobility', {
          _key: 'shared.electromobility',
          _tags: 'shared'
        })
        break
      case 'test-drive': // top-bar.test-drive
        translation = this.translationService.translate('Test drive', {
          _key: 'shared.test-drive',
          _tags: 'shared'
        })
        break
      case 'service-accessories': // top-bar.service-accessories
        translation = this.translationService.translate(
          'Service & accessories',
          { _key: 'shared.service-accessories', _tags: 'shared' }
        )
        break
      case 'locations': // Standorte
        translation = this.translationService.translate('Locations', {
          _key: 'shared.locations',
          _tags: 'shared'
        })
        break
      case 'emil-frey-about-us': // top-bar.about-us
        translation = this.translationService.translate('About us', {
          _key: 'shared.emil-frey-about-us',
          _tags: 'shared'
        })
        break
        // *****************

      case 'shop': //
        translation = this.translationService.translate('Shop', {
          _key: 'shared.shop',
          _tags: 'shared'
        })
        break
      case 'find-car': // kc.top-bar.ch.auto-finden
        translation = this.translationService.translate('Find a car', {
          _key: 'shared.find-car',
          _tags: 'shared'
        })
        break

      case 'conditions-of-sale-title': // kc.footer.conditions-of-sale.title
        translation = this.translationService.translate('Conditions of sale:', {
          _key: 'shared.conditions-of-sale-title',
          _tags: 'shared'
        })
        break
      case 'conditions-of-sale-notice': // kc.footer.conditions-of-sale.notice
        translation = this.translationService.translate(
          'All offers on this website are valid while stocks last, until canceled or until the specified date. The listed prices include 7.7% VAT, except commercial vehicles. Errors, changes in prices, models, equipment and sales campaigns are reserved. Leasing will not be granted if it leads to over-indebtedness of the consumer. Some of the vehicles shown contain options that are subject to a surcharge. Comprehensive insurance is mandatory for all leasing offers, but not included.',
          { _key: 'shared.conditions-of-sale-notice', _tags: 'shared' }
        )
        break
      case 'guarantee-service-title': // kc.footer.guarantee-service.title
        translation = this.translationService.translate('Warranty/Service:', {
          _key: 'shared.guarantee-service-title',
          _tags: 'shared'
        })
        break
      case 'guarantee-service-notice': // kc.footer.guarantee-service.notice
        translation = this.translationService.translate(
          'When specifying years or kilometers, the first achieved always applies.',
          { _key: 'shared.guarantee-service-notice', _tags: 'shared' }
        )
        break
      case 'co2-emissions-title': // kc.footer.co2-emissions-title
        translation = this.translationService.translate(
          'Note on fuel consumption and CO2 emissions:',
          { _key: 'shared.co2-emissions-title', _tags: 'shared' }
        )
        break
      case 'co2-emissions-notice': // kc.footer.co2-emissions-notice
        translation = this.translationService.translate(
          'The specified values ​​were determined according to the prescribed measurement method [VO (EG) 715/2007 and VO (EG) 692/2008 in the currently valid version]. The information does not refer to an individual vehicle and is not part of the offer, but is for comparison purposes only between the different vehicle types.',
          { _key: 'shared.co2-emissions-notice', _tags: 'shared' }
        )
        break
      case 'gasoline-equivalent-title': // kc.footer.gasoline-equivalent.title
        translation = this.translationService.translate(
          'Gasoline Equivalent (Bä):',
          { _key: 'shared.gasoline-equivalent-title', _tags: 'shared' }
        )
        break
      case 'gasoline-equivalent-notice': // kc.footer.gasoline-equivalent.notice
        translation = this.translationService.translate(
          'For diesel engines, the petrol equivalent is also listed. The value is obtained by multiplying the diesel consumption/100 km by a factor of 1.13. For example, a diesel consumption of 4.8 l/100 km results in a consumption of 5.42 l/100 km. Notation on this website: Mix consumption 4.8 l/100 km (petrol equivalent or Bä 5.42 l/100 km).',
          { _key: 'shared.gasoline-equivalent-notice', _tags: 'shared' }
        )
        break
    }

    return translation
  }

  getErrorSharedKey (error: string, params?): string {
    let key
    switch (error) {
      case 'error':
      case 'unable-to-update':
        key = 'general-error-message'
        break
      case 'required':
        key = 'required-field'
        break
      case 'errormaxTries':
      case 'errorexpired':
        key = 'confirmation-code-invalid'
        break
      case 'notMobile':
        key = 'enter-mobile-number'
        break
      case 'nonGermanCharacters':
        key = 'invalid-characters'
        break
      case 'invalidNumber':
        key = 'invalid-number'
        break
      case 'currentNumber':
        key = 'current-number'
        break
      case 'notsame':
        key = 'password-notsame'
        break
    }
    return key
  }
}
