<div
  class="cpt-modal relative dialog-content-p-0 mat-dialog-actions-pb-20 ef-scrollbar"
>
  <h2 class="font-canelablack-primary text-4xl">
    <ng-container
      *ngTemplateOutlet="
        sharedService.template('transifexTranslation');
        context: { key: title, str: 'Title' }
      "
    ></ng-container>
  </h2>

  <mat-dialog-content class="leasing-warranty-contact-dialog">
    <div class="font-efSans text-xl text-mat-ef-revolution-primary-500">
      <T
        str="Your data"
        key="customerPortal.customer-portal.leasing.manage.contact.yourData"
        tags="customer-portal, 3.1"
      ></T>
    </div>

    <ngx-skeleton-loader
      *ngIf="loadingContactData"
      appearance="line"
      animation="progress"
    ></ngx-skeleton-loader>

    <div
      class="flex flex-col gap-3 border-2 rounded-lg px-3.5 py-2.5"
      *ngIf="user && !loadingContactData"
    >
      <div class="flex flex-col md:flex-row md:justify-between">
        <div class="text-base text-efSans text-mat-ef-revolution-grey-two-500">
          <T
            str="Customer"
            key="customerPortal.customer-portal.leasing.manage.contact.yourData.kunde"
            tags="customer-portal, 3.1"
          ></T>
        </div>
        <div
          class="text-base text-efSans text-mat-ef-revolution-primary-500 md:text-end"
        >
          {{ user | username | async }}
        </div>
      </div>
      <div class="flex flex-col md:flex-row md:justify-between">
        <div class="text-base text-efSans text-mat-ef-revolution-grey-two-500">
          {{ 'customer-number' | sharedTranslate }}
        </div>
        <div
          class="text-base text-efSans text-mat-ef-revolution-primary-500 md:text-end"
        >
          {{ user.careId }}
        </div>
      </div>
      <div class="flex flex-col md:flex-row md:justify-between">
        <div class="text-base text-efSans text-mat-ef-revolution-grey-two-500">
          <T
            str="VIN (chassis number)"
            key="customerPortal.customer-portal.vehicle.details.vin"
            tags="customer-portal, 3.1"
          ></T>
        </div>
        <div
          class="text-base text-efSans text-mat-ef-revolution-primary-500 md:text-end"
        >
          {{ data.vehicle.vin }}
        </div>
      </div>
      <div class="flex flex-col md:flex-row md:justify-between">
        <div class="text-base text-efSans text-mat-ef-revolution-grey-two-500">
          <T
            str="Provider name:"
            key="customerPortal.customer-portal.leasing.provider-name"
            tags="customer-portal, 3.1"
          ></T>
        </div>
        <div
          class="text-base text-efSans text-mat-ef-revolution-primary-500 md:text-end"
        >
          {{ data.leasingResponse.providerName }}
        </div>
      </div>
      <div
        class="flex flex-col md:flex-row md:justify-between"
        *ngIf="
          data.leasingResponse.multileaseContractId &&
          mode === 'MultileaseContact'
        "
      >
        <div class="text-base text-efSans text-mat-ef-revolution-grey-two-500">
          <T
            str="Contract number"
            key="customerPortal.customer-portal.leasing.contractNumber"
            tags="customer-portal, 3.1"
          ></T>
        </div>
        <div
          class="text-base text-efSans text-mat-ef-revolution-primary-500 md:text-end"
        >
          {{ data.leasingResponse.multileaseContractId }}
        </div>
      </div>
    </div>

    <form
      [formGroup]="loanContactForm"
      *ngIf="mode === 'Contact' || loanContactData"
      class="mt-8"
    >
      <div class="w-full mb-2.5 grid grid-cols-2 gap-2">
        <mat-form-field
          *ngIf="mode === 'MultileaseContact'"
          class="col-span-2"
          appearance="legacy"
        >
          <mat-label>
            <T
              str="Subject"
              key="customerPortal.customer-portal.leasing.manage.contact.yourMessage.subject"
              tags="customer-portal, 3.1"
            ></T>
          </mat-label>
          <mat-select formControlName="reason" required>
            <mat-option
              *ngFor="let reason of loanContactData.reasons"
              [value]="reason.id"
            >
              {{
                {
                  str: reason.name,
                  prefix:
                    'customerPortal.customer-portal.leasing.contact.reason.'
                } | transifexTranslate
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-span-2" appearance="legacy">
          <mat-label>
            <T
              str="Your message"
              key="customerPortal.customer-portal.leasing.manage.contact.yourMessage"
              tags="customer-portal, 3.1"
            ></T
          ></mat-label>
          <textarea
            rows="4"
            maxlength="512"
            formControlName="message"
            required
            matInput
          ></textarea>
          <mat-error *ngIf="loanContactForm.get('message').errors">
            {{
              transifexService.getErrorTranslation(
                loanContactForm.get('message'),
                'customerPortal.customer-portal.leasing.manage.contact.yourMessage.error.message.'
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="mode === 'MultileaseContact'" class="grid grid-cols-2 gap-5">
        <mat-form-field
          class="col-span-1"
          appearance="legacy"
          [ngClass]="{ hidden: loanContactData?.affiliates?.length === 1 }"
        >
          <mat-label>
            <T
              str="Affiliate"
              key="customerPortal.customer-portal.leasing.manage.contact.yourMessage.affiliate"
              tags="customer-portal, 3.1"
            ></T>
          </mat-label>
          <mat-select formControlName="affiliate" [errorStateMatcher]="matcher">
            <mat-option
              *ngFor="let affiliate of loanContactData.affiliates"
              [value]="affiliate.id"
            >
              <ng-container
                *ngTemplateOutlet="
                  sharedService.template('transifexTranslation');
                  context: {
                    str: affiliate.name,
                    prefix:
                      'customerPortal.customer-portal.leasing.contact.affiliate.'
                  }
                "
              ></ng-container>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-span-1" appearance="legacy">
          <mat-label>
            <T
              str="Kilometers"
              key="customerPortal.customer-portal.leasing.manage.contact.yourMessage.km"
              tags="customer-portal, 3.1"
            ></T>
          </mat-label>
          <img
            alt="mileage"
            matPrefix
            class="image"
            src="../../../../assets/images/mileage_24px.svg"
          />
          <input
            type="number"
            min="0"
            max="9999999"
            maxlength="7"
            formControlName="mileage"
            matInput
            required
            [errorStateMatcher]="matcher"
          />
          <mat-error *ngIf="loanContactForm.get('mileage').errors">
            {{
              transifexService.getErrorTranslation(
                loanContactForm.get('mileage'),
                'customerPortal.customer-portal.leasing.manage.contact.yourMessage.error.mileage.'
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <mat-divider></mat-divider>

  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>

    <button
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      cdkFocusInitial
      [disabled]="saving || !loanContactForm.valid"
      (click)="onSubmit()"
    >
      <mat-spinner *ngIf="saving" diameter="20"></mat-spinner>
      <T
        str="Send"
        key="customerPortal.customer-portal.leasing.contact.send"
        tags="customer-portal, 3.1"
      ></T>
    </button>
  </mat-dialog-actions>
</div>
