import { Component, Inject, OnInit } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import {
  User,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { SharedService } from '../../../../services/shared.service'
import { ConfirmationPopupComponent } from '../../../basic/confirmation-popup/confirmation-popup.component'
import { ConfirmationQuestionPopupComponent } from '../../../basic/confirmation-question-popup/confirmation-question-popup.component'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
@Component({
  selector: 'customer-portal-app-revolution-user-img-details',
  templateUrl: './user-img-details.component.html'
})
export class RevolutionUserImgDetailsPopupComponent implements OnInit {
  nameInitials = ''
  originalFile
  propperSize = true
  propperFileNameLength = true
  disabled = true
  preview = null
  savingInProgress = false
  fileUploaded = false

  constructor (
    @Inject(MAT_DIALOG_DATA) public user: User,
    private readonly userService: UserService,
    private readonly dialogRef: MatDialogRef<RevolutionUserImgDetailsPopupComponent>,
    private readonly dialog: MatDialog,
    public sharedService: SharedService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly transifexTranslationsService: TranslationService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {}

  ngOnInit (): void {
    if (this.user.firstName != null && this.user.lastName != null) {
      this.nameInitials = this.user.firstName[0] + this.user.lastName[0]
    }
    if (
      this.user.profilePictureUrl !== null &&
      this.user.profilePictureUrl !== '' &&
      this.user.profilePictureUrl !== undefined
    ) {
      this.disabled = false
    }
  }

  sendFileUpload (step: string): void {
    this.cptGtmService.sendFileUpload('img', step)
  }

  checkFilenameLength (name: string): boolean {
    return name.length < 40
  }

  loadImage (e: Event): void {
    const reader: FileReader = new FileReader()
    const file: File = (e.target as HTMLInputElement).files[0]

    if (
      file.size < this.remoteConfigService.get('regEx.imageFile.maxSize10Mb')
    ) {
      reader.onload = e => {
        this.preview = e.target.result // for immediate display
        this.disabled = false
        this.originalFile = file
        this.propperFileNameLength = this.checkFilenameLength(file.name)
      }
      reader.readAsDataURL(file)
      this.propperSize = true
      this.fileUploaded = true
      this.sendFileUpload('Step 1')
    } else {
      this.propperSize = false
    }
  }

  deleteImage (): void {
    const dialogRef = this.dialog.open(ConfirmationQuestionPopupComponent, {
      data: {
        title: 'shared.clear',
        text: 'customerPortal.customer-portal.details.update-avatar-popup.delete-image.confirmation',
        cancel: 'shared.cancel',
        save: 'shared.clear',
        saveButtonColor: 'warn',
        preventCloseBeforeComplete: true
      },
      panelClass: 'mat-dialog-cpt'
    })
    const sub = dialogRef.componentInstance.confirm.subscribe(() => {
      this.userService.deleteProfilePicture().subscribe(
        () => {
          dialogRef.close()
          this.user.profilePictureUrl = ''
          this.dialogRef.close()
          this.savingInProgress = false

          this.dialog.open(ConfirmationPopupComponent, {
            data: {
              title: 'shared.successful',
              text: 'customerPortal.customer-portal.details.update-avatar-popup.delete-image.success'
            },
            panelClass: 'mat-dialog-cpt'
          })
        },
        () => {
          dialogRef.close()
          this.dialog.open(ConfirmationPopupComponent, {
            data: {
              title: 'shared.error',
              text: 'customerPortal.customer-portal.details.update-avatar-popup.delete-image.failure'
            },
            panelClass: 'mat-dialog-cpt'
          })
        }
      )
    })
    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe()
    })
  }

  save (): void {
    this.savingInProgress = true
    this.userService.profilePicture(this.originalFile).subscribe(
      response => {
        const res = {
          changed: true,
          url: response
        }
        this.dialogRef.close(res)
        this.savingInProgress = false
        this.dialog.open(ConfirmationPopupComponent, {
          data: {
            title: 'shared.successful',
            text: 'customerPortal.customer-portal.details.update-avatar.upload-image.success'
          },
          panelClass: 'mat-dialog-cpt'
        })
        this.sendFileUpload('Step 2')
      },
      () => {
        this.dialogRef.close(true)
        this.savingInProgress = false
        this.dialog.open(ConfirmationPopupComponent, {
          data: {
            title: 'shared.error',
            text: 'customerPortal.customer-portal.details.update-avatar.upload-image.failure'
          },
          panelClass: 'mat-dialog-cpt'
        })
      }
    )
  }

  getTranslation (): string {
    return this.transifexTranslationsService.translate(
      'Insert image here or search image',
      {
        _key: 'customerPortal.customer-portal.post-picture-here-or-after',
        _tags: 'customer-portal, 3.1'
      }
    )
  }
}
