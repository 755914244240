import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { Observable, of } from 'rxjs'
import { DataService } from './data.service'

export interface MetaPartial {
  title: string
  subtitle: string
  abstract: string
  description: string
  keywords: string
  canonical: string
}

export interface HeaderSocialMediaPartial {
  ogTitle: string
  ogDescription: string
  ogImage
  twitterTitle: string
  twitterDescription: string
  twitterCard: string
  twitterImage
}

export interface ResourcesPartial {
  media
}

export interface EditorialPartial {
  author: string
  authorEmail: string
}

export interface HeaderNavigationPartial {
  slug: string
  navTitle: string
  target: string
  noSearch: string
}

export interface HeaderCachePartial {
  timeout: string
  tags: string
}

export interface HeaderAppearancePartial {
  layout: string
  backendLayout: string
  backendLayoutNextLevel: string
  newUntil: string
}

export interface HeaderL10nPartial {
  languageId: number
}

export interface HeaderSystemPartial {
  tstamp: number
  crdate: number
  starttime: number
  endtime: number
  feGroup: string
  sorting: number
}

export interface HeaderPagePartial {
  id: number
  pid: number
  title: string
  type: string
  meta: MetaPartial
  socialMedia: HeaderSocialMediaPartial
  resources: ResourcesPartial
  categories: string
  editorial: EditorialPartial
  navigation: HeaderNavigationPartial
  cache: HeaderCachePartial
  appearance: HeaderAppearancePartial
  l10n: HeaderL10nPartial
  system: HeaderSystemPartial
  page_related_content
}

export interface HeaderResponse {
  page: HeaderPagePartial
  content
  languages: Languages[]
  structure: Structure
}

export interface Languages {
  languageId: number
  locale: string
  title: string
  navigationTitle: string
  twoLetterIsoCode: string
  hreflang: string
  direction: string
  flag: string
  link: string
  active: number
  current: number
  available: number
}

export interface Structure {
  navigation: StructureHeaderPartial
  footer: StructureFooterPartial
}

export interface Context {
  ctype: string
  cid: string
  task: string
  uid: number
  pid: number
}

export interface StructureHeaderPartial {
  context: Context
  content: HeaderContent
}

export interface StructureFooterPartial {
  context: Context
  content: FooterContent
}

export interface SettingsPartial {
  theme: string
}

export interface HeaderPartial {
  text: string
  logo: LogoPartial[]
}

export interface PropertiesPartial {
  title: string
  alternative: string
  description: string
  mimeType: string
  type: string
  filename: string
  originalUrl: string
  uidLocal: number
  fileReferenceUid: number
  size: string
  link: string
  dimensions: DimensionsPartial
  cropDimensions: DimensionsPartial
  crop
  autoplay: number
  extension: string
}

export interface DimensionsPartial {
  height: number
  width: number
}

export interface LogoPartial {
  publicUrl: string
  properties: PropertiesPartial
  breakpoint?: string
}

export interface NavigationMainChildrenPartial {
  title: string
  link: string
  target: string
  active: number
  current: number
  spacer: number
}

export interface Usp {
  icon: LogoPartial[]
  headline: string
  subline: string
  hoverHeadline: string
  hoverText: string
  uid: number
  _localizedUid: number
  _languageUid: number
  _versionedUid: number
  pid: number
}

export interface ActionLinkPartial {
  type: string
  url: string
  target: string
  aTagParams: string
  link: string
}

export interface ActionIconPartial {
  name: string
  filename: string
  uri: string
  position?
  variant?
}

export interface ActionContentPartial {
  text: string
  link: ActionLinkPartial
  layout: string
  variant?: string
  icon?: ActionIconPartial
}

export interface ActionPartial {
  id: number
  pid: number
  type: string
  content: ActionContentPartial
}

export interface OfferBrandPartial {
  localId: number
  apiBrandId: number
  name: string
  alias: string
  logo: LogoPartial[]
  logocustomsized: LogoPartial[]
  landingpage: string
}

export interface OfferContentPartial {
  variant: string
  title: string
  availability: string
  availabilityShort: string
  headline: string
  headlineShort: string
  subline: string
  sublineShort: string
  imageSmall
  imageMedium
  imageLarge
  imageXl
  ctaboxText: string
  ctaboxTextShort: string
  ctaboxPrice: string
  ctaboxTime: string
  slogan: string
  boxcolor?: string
  usp: Usp[]
  actions: ActionPartial[]
  lists?
  offerbrand
  uid?: number
  _localizedUid?: number
  _languageUid?: number
  _versionedUid?: number
  pid?: number
  displaymode?: string
}

export interface OfferPartial {
  content: OfferContentPartial
  id: number
  pid: number
  type: string
}

export interface TopicsPartial {
  headline: string
  action
  cards
}

export interface NavigationMainDropdownPartial {
  offer: OfferPartial
  topics: TopicsPartial
}

export interface NavigationMainPartial {
  title: string
  link: string
  target: string
  active: number
  current: number
  spacer: number
  children?: NavigationMainChildrenPartial[]
  dropdown?: NavigationMainDropdownPartial
}

export interface NavigationSubContentPartial {
  text: string
  link
  layout: string
  variant?: string
  icon?: ActionIconPartial
}

export interface PagesPartial {
  id: number
  pid: number
  type: string
  content: NavigationSubContentPartial
}

export interface NavigationSubPartial {
  message: string[]
  context: string
  pages: PagesPartial[]
}

export interface CardsPartial {
  variant: string
  text: string
  image: LogoPartial
  action: ActionLinkPartial
}

export interface BreadcrumbsPartial {
  title: string
  link: string
  target: string
  active: number
  current: number
  spacer: number
}

// main header interface
export interface HeaderContent {
  settings: SettingsPartial
  header: HeaderPartial
  navigationMain: NavigationMainPartial[]
  navigationSub: NavigationSubPartial
  cards: CardsPartial[]
  breadcrumbs: BreadcrumbsPartial[]
}

export interface NewsletterPartial {
  title: string
  teaser: string
  placeholder: string
}

export interface SocialMediaPartial {
  id: number
  pid: number
  type: string
  content: ActionContentPartial
}

export interface FooterCardPartial {
  content: OfferContentPartial
  id: number
  pid: number
  type: string
}

export interface LegalNoticeColPartial {
  header: string
  text: string
}

export interface LegalNoticePartial {
  col1: LegalNoticeColPartial
  col2: LegalNoticeColPartial
  col3: LegalNoticeColPartial
}

export interface FooterContent {
  settings: SettingsPartial
  header: HeaderPartial
  pages: PagesPartial[]
  newsletter: NewsletterPartial
  socialmedia: SocialMediaPartial[]
  legal: ActionPartial[]
  card: FooterCardPartial
  legalNotice: LegalNoticePartial
}

export interface IHeaderFooterService {
  getHeaderFooterData: () => Observable<HeaderResponse>
}

@Injectable({
  providedIn: 'root'
})
export class HeaderFooterTestingService implements IHeaderFooterService {
  getHeaderFooterData(): Observable<HeaderResponse> {
    return of({
      page: {
        id: 1,
        pid: 0,
        title: 'Emil Frey. Das Digitale Autohaus in Ihrer N\u00e4he.',
        type: 'Standard',
        meta: {
          title:
            'Emil Frey Deutschland: Das Online-Autohaus in Ihrer N\u00e4he.',
          subtitle: '',
          abstract: '',
          description: '',
          keywords: '',
          canonical: ''
        },
        socialMedia: {
          ogTitle: '',
          ogDescription: '',
          ogImage: [],
          twitterTitle: '',
          twitterDescription: '',
          twitterCard: 'summary',
          twitterImage: []
        },
        resources: {
          media: []
        },
        categories: '',
        editorial: {
          author: '',
          authorEmail: ''
        },
        navigation: {
          slug: '/',
          navTitle: 'Emil Frey Deutschland',
          target: '',
          noSearch: '0'
        },
        cache: {
          timeout: '',
          tags: ''
        },
        appearance: {
          layout: 'layout-0',
          backendLayout: '1',
          backendLayoutNextLevel: '1',
          newUntil: '0'
        },
        l10n: {
          languageId: 0
        },
        system: {
          tstamp: 1630321265,
          crdate: 1610652860,
          starttime: 0,
          endtime: 0,
          feGroup: '',
          sorting: 256
        },
        page_related_content: []
      },
      content: [],
      languages: [
        {
          languageId: 0,
          locale: 'de_DE.UTF-8',
          title: 'German',
          navigationTitle: 'Deutsch',
          twoLetterIsoCode: 'de',
          hreflang: 'de-DE',
          direction: 'ltr',
          flag: 'flags-de',
          link: '/',
          active: 1,
          current: 0,
          available: 1
        }
      ],
      structure: {
        navigation: {
          context: {
            ctype: 'o_01_01__main_navigation',
            cid: 'O 01-01 Main Navigation / O 01-02 Sub Navigation',
            task: '14074799',
            uid: 41,
            pid: 1
          },
          content: {
            settings: {
              theme: 'dark'
            },
            header: {
              text: '<p><strong>Emil Frey</strong>&nbsp;Deutschland</p>',
              logo: [
                {
                  publicUrl:
                    'https://production.corporate.cms.emf.exu.dev/library/brand/emilfrey.svg',
                  properties: {
                    title: null,
                    alternative: null,
                    description: null,
                    mimeType: 'image/svg+xml',
                    type: 'image',
                    filename: 'emilfrey.svg',
                    originalUrl: 'library/brand/emilfrey.svg',
                    uidLocal: 9,
                    fileReferenceUid: 25,
                    size: '7 KB',
                    link: '/',
                    dimensions: {
                      width: 281,
                      height: 283
                    },
                    cropDimensions: {
                      width: 281,
                      height: 281
                    },
                    crop: {
                      o_01_01__main_navigation: {
                        cropArea: {
                          x: 0,
                          y: 0.0035335689045936395,
                          width: 1,
                          height: 0.9929328621908127
                        },
                        selectedRatio: '1:1',
                        focusArea: null
                      }
                    },
                    autoplay: 0,
                    extension: 'svg'
                  },
                  breakpoint: 'default'
                }
              ]
            },
            navigationMain: [
              {
                title: 'Auto kaufen',
                link: '/markenportfolio',
                target: '',
                active: 0,
                current: 0,
                spacer: 0,
                children: [
                  {
                    title: 'Markenportfolio',
                    link: '/markenportfolio',
                    target: '',
                    active: 0,
                    current: 0,
                    spacer: 0
                  },
                  {
                    title: 'Konfigurator',
                    link: '/konfigurator',
                    target: '',
                    active: 0,
                    current: 0,
                    spacer: 0
                  },
                  {
                    title: 'Auto-Abo',
                    link: '/auto-abo',
                    target: '',
                    active: 0,
                    current: 0,
                    spacer: 0
                  },
                  {
                    title: 'Elektromobilit\u00e4t',
                    link: '/elektromobilitaet',
                    target: '',
                    active: 0,
                    current: 0,
                    spacer: 0
                  },
                  {
                    title: 'Angebote',
                    link: '/angebote',
                    target: '',
                    active: 0,
                    current: 0,
                    spacer: 0
                  },
                  {
                    title: 'Probefahrt',
                    link: '/probefahrt',
                    target: '',
                    active: 0,
                    current: 0,
                    spacer: 0
                  }
                ],
                dropdown: {
                  offer: {
                    content: {
                      variant: 'leasing-bonus',
                      title: 'Mazda Jahresauftaktbonus',
                      availability: 'Leasing Angebot',
                      availabilityShort: 'Leasing Angebot',
                      headline: 'Mazda Jahresauftaktbonus',
                      headlineShort: 'Mazda Jahresauftaktbonus',
                      subline:
                        'Sichern Sie sich jetzt Ihren Traumwagen zum absoluten Traumpreis.',
                      sublineShort:
                        'Sichern Sie sich jetzt Ihren Traumwagen zum absoluten Traumpreis.',
                      imageSmall: [
                        {
                          // tslint:disable-next-line:max-line-length
                          publicUrl:
                            'https://production.corporate.cms.emf.exu.dev/fileadmin/user_upload/testing/Testing_Philipp/Emil-Frey_Service_Reparatur_Werkstatt_Gutachten_Leasinguebergabe_Leasing_Abnahme_Wertermittlung_Autoankauf_1200x800_05.jpg',
                          properties: {
                            title: null,
                            alternative: null,
                            description: null,
                            mimeType: 'image/jpeg',
                            type: 'image',
                            // tslint:disable-next-line:max-line-length
                            filename:
                              'Emil-Frey_Service_Reparatur_Werkstatt_Gutachten_Leasinguebergabe_Leasing_Abnahme_Wertermittlung_Autoankauf_1200x800_05.jpg',
                            // tslint:disable-next-line:max-line-length
                            originalUrl:
                              'fileadmin/user_upload/testing/Testing_Philipp/Emil-Frey_Service_Reparatur_Werkstatt_Gutachten_Leasinguebergabe_Leasing_Abnahme_Wertermittlung_Autoankauf_1200x800_05.jpg',
                            uidLocal: 222,
                            fileReferenceUid: 144,
                            size: '131 KB',
                            link: null,
                            dimensions: {
                              width: 1200,
                              height: 800
                            },
                            cropDimensions: {
                              width: 1200,
                              height: 800
                            },
                            crop: {
                              image_small: {
                                cropArea: {
                                  x: 0.12551666666666667,
                                  y: 0,
                                  width: 0.5020666666666667,
                                  height: 1
                                },
                                selectedRatio: '1_33:1',
                                focusArea: null
                              }
                            },
                            autoplay: null,
                            extension: 'jpg'
                          }
                        }
                      ],
                      imageMedium: [
                        {
                          // tslint:disable-next-line:max-line-length
                          publicUrl:
                            'https://production.corporate.cms.emf.exu.dev/fileadmin/user_upload/testing/Testing_Philipp/Emil-Frey_Service_Reparatur_Werkstatt_Gutachten_Leasinguebergabe_Leasing_Abnahme_Wertermittlung_Autoankauf_1200x800_05.jpg',
                          properties: {
                            title: null,
                            alternative: null,
                            description: null,
                            mimeType: 'image/jpeg',
                            type: 'image',
                            // tslint:disable-next-line:max-line-length
                            filename:
                              'Emil-Frey_Service_Reparatur_Werkstatt_Gutachten_Leasinguebergabe_Leasing_Abnahme_Wertermittlung_Autoankauf_1200x800_05.jpg',
                            // tslint:disable-next-line:max-line-length
                            originalUrl:
                              'fileadmin/user_upload/testing/Testing_Philipp/Emil-Frey_Service_Reparatur_Werkstatt_Gutachten_Leasinguebergabe_Leasing_Abnahme_Wertermittlung_Autoankauf_1200x800_05.jpg',
                            uidLocal: 222,
                            fileReferenceUid: 145,
                            size: '131 KB',
                            link: null,
                            dimensions: {
                              width: 1200,
                              height: 800
                            },
                            cropDimensions: {
                              width: 1200,
                              height: 800
                            },
                            crop: {
                              image_medium: {
                                cropArea: {
                                  x: 0.2780166666666666,
                                  y: 0,
                                  width: 1.1120666666666668,
                                  height: 1
                                },
                                selectedRatio: '1_66:1',
                                focusArea: null
                              }
                            },
                            autoplay: null,
                            extension: 'jpg'
                          }
                        }
                      ],
                      imageLarge: [
                        {
                          // tslint:disable-next-line:max-line-length
                          publicUrl:
                            'https://production.corporate.cms.emf.exu.dev/fileadmin/user_upload/testing/Testing_Philipp/Emil-Frey_Service_Reparatur_Werkstatt_Gutachten_Leasinguebergabe_Leasing_Abnahme_Wertermittlung_Autoankauf_1200x800_05.jpg',
                          properties: {
                            title: null,
                            alternative: null,
                            description: null,
                            mimeType: 'image/jpeg',
                            type: 'image',
                            // tslint:disable-next-line:max-line-length
                            filename:
                              'Emil-Frey_Service_Reparatur_Werkstatt_Gutachten_Leasinguebergabe_Leasing_Abnahme_Wertermittlung_Autoankauf_1200x800_05.jpg',
                            // tslint:disable-next-line:max-line-length
                            originalUrl:
                              'fileadmin/user_upload/testing/Testing_Philipp/Emil-Frey_Service_Reparatur_Werkstatt_Gutachten_Leasinguebergabe_Leasing_Abnahme_Wertermittlung_Autoankauf_1200x800_05.jpg',
                            uidLocal: 222,
                            fileReferenceUid: 146,
                            size: '131 KB',
                            link: null,
                            dimensions: {
                              width: 1200,
                              height: 800
                            },
                            cropDimensions: {
                              width: 1200,
                              height: 800
                            },
                            crop: {
                              image_large: {
                                cropArea: {
                                  x: 0.11394999999999995,
                                  y: 0,
                                  width: 0.45580000000000004,
                                  height: 1
                                },
                                selectedRatio: '1:1_5',
                                focusArea: null
                              }
                            },
                            autoplay: null,
                            extension: 'jpg'
                          }
                        }
                      ],
                      imageXl: [
                        {
                          // tslint:disable-next-line:max-line-length
                          publicUrl:
                            'https://production.corporate.cms.emf.exu.dev/fileadmin/user_upload/testing/Testing_Philipp/Emil-Frey_Service_Reparatur_Werkstatt_Gutachten_Leasinguebergabe_Leasing_Abnahme_Wertermittlung_Autoankauf_1200x800_05.jpg',
                          properties: {
                            title: null,
                            alternative: null,
                            description: null,
                            mimeType: 'image/jpeg',
                            type: 'image',
                            // tslint:disable-next-line:max-line-length
                            filename:
                              'Emil-Frey_Service_Reparatur_Werkstatt_Gutachten_Leasinguebergabe_Leasing_Abnahme_Wertermittlung_Autoankauf_1200x800_05.jpg',
                            // tslint:disable-next-line:max-line-length
                            originalUrl:
                              'fileadmin/user_upload/testing/Testing_Philipp/Emil-Frey_Service_Reparatur_Werkstatt_Gutachten_Leasinguebergabe_Leasing_Abnahme_Wertermittlung_Autoankauf_1200x800_05.jpg',
                            uidLocal: 222,
                            fileReferenceUid: 147,
                            size: '131 KB',
                            link: null,
                            dimensions: {
                              width: 1200,
                              height: 800
                            },
                            cropDimensions: {
                              width: 1200,
                              height: 800
                            },
                            crop: {
                              image_xl: {
                                cropArea: {
                                  x: 0.3333333333333333,
                                  y: 0,
                                  width: 1.3333333333333333,
                                  height: 1
                                },
                                selectedRatio: '2:1',
                                focusArea: null
                              }
                            },
                            autoplay: null,
                            extension: 'jpg'
                          }
                        }
                      ],
                      ctaboxText: 'Mazda Jahresauftaktbonus',
                      ctaboxTextShort: 'Mazda Modelle',
                      ctaboxPrice: '1234',
                      ctaboxTime: 'Monat',
                      slogan:
                        'Der gro\u00dfe Emil Frey Mazda Jahresauftaktbonus',
                      boxcolor: 'emf_pry_blue',
                      usp: [
                        {
                          icon: [
                            {
                              publicUrl:
                                'https://production.corporate.cms.emf.exu.dev/fileadmin/user_upload/demo/demo_001.svg',
                              properties: {
                                title: null,
                                alternative: null,
                                description: null,
                                mimeType: 'image/svg+xml',
                                type: 'image',
                                filename: 'demo_001.svg',
                                originalUrl:
                                  'fileadmin/user_upload/demo/demo_001.svg',
                                uidLocal: null,
                                fileReferenceUid: 12,
                                size: '5 KB',
                                link: null,
                                dimensions: {
                                  width: 55,
                                  height: 55
                                },
                                cropDimensions: {
                                  width: 55,
                                  height: 55
                                },
                                crop: null,
                                autoplay: null,
                                extension: 'svg'
                              },
                              breakpoint: 'default'
                            }
                          ],
                          headline: 'USP Test1',
                          subline: 'USP Test1 Subline',
                          hoverHeadline: 'USP Test1Hover headline',
                          hoverText: 'USP Test1Hover text',
                          uid: 8,
                          _localizedUid: 8,
                          _languageUid: 0,
                          _versionedUid: 8,
                          pid: 42
                        },
                        {
                          icon: null,
                          headline: 'USP Test2',
                          subline: 'USP Test2 Subline',
                          hoverHeadline: 'USP Test2 Hover headline',
                          hoverText: 'USP Test2 Hover text',
                          uid: 9,
                          _localizedUid: 9,
                          _languageUid: 0,
                          _versionedUid: 9,
                          pid: 42
                        }
                      ],
                      actions: [
                        {
                          id: 353,
                          pid: 42,
                          type: 'a_01_01__action',
                          content: {
                            text: 'Jetzt Angebot sichern',
                            link: {
                              type: 'page',
                              url: '/services/leistungen',
                              target: '',
                              aTagParams: ' class="button-secondary-default"',
                              link: 'Jetzt Angebot sichern'
                            },
                            layout: 'button-secondary-default',
                            variant: 'small',
                            icon: {
                              name: null,
                              filename: null,
                              uri: null,
                              position: null,
                              variant: null
                            }
                          }
                        }
                      ],
                      lists: [],
                      offerbrand: {
                        localId: 41,
                        apiBrandId: 41,
                        name: 'MAZDA',
                        alias: '',
                        logo: [
                          {
                            publicUrl:
                              'https://production.corporate.cms.emf.exu.dev/library/brand/mazda_unisize.svg',
                            properties: {
                              title: null,
                              alternative: null,
                              description: null,
                              mimeType: 'image/svg+xml',
                              type: 'image',
                              filename: 'mazda_unisize.svg',
                              originalUrl: 'library/brand/mazda_unisize.svg',
                              uidLocal: 71,
                              fileReferenceUid: 107,
                              size: '4 KB',
                              link: null,
                              dimensions: {
                                width: 68,
                                height: 36
                              },
                              cropDimensions: {
                                width: 68,
                                height: 36
                              },
                              crop: {
                                brand_logo: {
                                  cropArea: {
                                    x: 3.999999999973529,
                                    y: 0,
                                    width: 9.00000000005294,
                                    height: 9
                                  },
                                  selectedRatio: '17:9',
                                  focusArea: null
                                }
                              },
                              autoplay: 0,
                              extension: 'svg'
                            },
                            breakpoint: 'default'
                          }
                        ],
                        logocustomsized: [
                          {
                            publicUrl:
                              'https://production.corporate.cms.emf.exu.dev/library/brand/mazda_customsize.svg',
                            properties: {
                              title: null,
                              alternative: null,
                              description: null,
                              mimeType: 'image/svg+xml',
                              type: 'image',
                              filename: 'mazda_customsize.svg',
                              originalUrl: 'library/brand/mazda_customsize.svg',
                              uidLocal: 72,
                              fileReferenceUid: 108,
                              size: '4 KB',
                              link: null,
                              dimensions: {
                                width: 30,
                                height: 31
                              },
                              cropDimensions: {
                                width: 30,
                                height: 31
                              },
                              crop: {
                                default: {
                                  cropArea: {
                                    x: 0,
                                    y: 0,
                                    width: 1,
                                    height: 1
                                  },
                                  selectedRatio: 'NaN',
                                  focusArea: null
                                }
                              },
                              autoplay: 0,
                              extension: 'svg'
                            },
                            breakpoint: 'default'
                          }
                        ],
                        landingpage:
                          'https://production.corporate.cms.emf.exu.dev/markenportfolio'
                      },
                      uid: 6,
                      _localizedUid: 6,
                      _languageUid: 0,
                      _versionedUid: 6,
                      pid: 42
                    },
                    id: 6,
                    pid: 42,
                    type: 'tx_emfoffers_domain_model_offer'
                  },
                  topics: {
                    headline: 'Die Ford Auswahl Finanzierung',
                    action: [],
                    cards: [
                      {
                        text: 'Topics Card I Text',
                        image: {
                          // tslint:disable-next-line:max-line-length
                          publicUrl:
                            'https://production.corporate.cms.emf.exu.dev/fileadmin/_processed_/d/2/csm_Emil-Frey_Elektro_Mobilitaet_Ford_Mustang_Mach_E_333x500_01_6663ec9e45.jpg',
                          properties: {
                            title: null,
                            alternative: null,
                            description: null,
                            mimeType: 'image/jpeg',
                            type: 'image',
                            filename:
                              'csm_Emil-Frey_Elektro_Mobilitaet_Ford_Mustang_Mach_E_333x500_01_6663ec9e45.jpg',
                            // tslint:disable-next-line:max-line-length
                            originalUrl:
                              'fileadmin/Mediapool/01_Fotos_Eigene-Aufnahmen/Fahrzeugbilder/Web_333x500/Emil-Frey_Elektro_Mobilitaet_Ford_Mustang_Mach_E_333x500_01.jpg',
                            uidLocal: 336,
                            fileReferenceUid: 252,
                            size: '29 KB',
                            link: null,
                            dimensions: {
                              width: 200,
                              height: 200
                            },
                            cropDimensions: {
                              width: 200,
                              height: 200
                            },
                            crop: {
                              topics_card_image: {
                                cropArea: {
                                  x: 0,
                                  y: 0.167,
                                  width: 1,
                                  height: 0.666
                                },
                                selectedRatio: '1:1',
                                focusArea: null
                              }
                            },
                            autoplay: null,
                            extension: 'jpg'
                          },
                          breakpoint: 'default'
                        },
                        action: null,
                        variant: 'text'
                      },
                      {
                        variant: 'text',
                        text: 'Topics Card II Text',
                        action: null,
                        image: {
                          // tslint:disable-next-line:max-line-length
                          publicUrl:
                            'https://production.corporate.cms.emf.exu.dev/fileadmin/_processed_/9/d/csm_Emil-Frey_Elektro_Mobilitaet_Ladestation_VW_Volkswagen_ID4_ID_4_333x500_04_4dd1f3991f.jpg',
                          properties: {
                            title: null,
                            alternative: null,
                            description: null,
                            mimeType: 'image/jpeg',
                            type: 'image',
                            filename:
                              'csm_Emil-Frey_Elektro_Mobilitaet_Ladestation_VW_Volkswagen_ID4_ID_4_333x500_04_4dd1f3991f.jpg',
                            // tslint:disable-next-line:max-line-length
                            originalUrl:
                              'fileadmin/Mediapool/01_Fotos_Eigene-Aufnahmen/Fahrzeugbilder/Web_333x500/Emil-Frey_Elektro_Mobilitaet_Ladestation_VW_Volkswagen_ID4_ID_4_333x500_04.jpg',
                            uidLocal: 344,
                            fileReferenceUid: 253,
                            size: '18 KB',
                            link: null,
                            dimensions: {
                              width: 200,
                              height: 200
                            },
                            cropDimensions: {
                              width: 200,
                              height: 200
                            },
                            crop: {
                              topics_card_image: {
                                cropArea: {
                                  x: 0,
                                  y: 0.167,
                                  width: 1,
                                  height: 0.666
                                },
                                selectedRatio: '1:1',
                                focusArea: null
                              }
                            },
                            autoplay: null,
                            extension: 'jpg'
                          },
                          breakpoint: 'default'
                        }
                      }
                    ]
                  }
                }
              },
              {
                title: 'Auto verkaufen',
                link: '/autoankauf',
                target: '',
                active: 0,
                current: 0,
                spacer: 0,
                dropdown: {
                  offer: null,
                  topics: {
                    headline: '',
                    action: [],
                    cards: [
                      {
                        text: '',
                        image: '',
                        action: ''
                      }
                    ]
                  }
                },
                children: []
              },
              {
                title: 'Service',
                link: '/services',
                target: '',
                active: 0,
                current: 0,
                spacer: 0,
                children: [
                  {
                    title: 'Terminvereinbarung',
                    link: '/services/terminvereinbarung',
                    target: '',
                    active: 0,
                    current: 0,
                    spacer: 0
                  }
                ],
                dropdown: {
                  offer: null,
                  topics: {
                    headline: '',
                    action: [],
                    cards: [
                      {
                        text: '',
                        image: '',
                        action: ''
                      }
                    ]
                  }
                }
              },
              {
                title: 'Teile und Zubeh\u00f6r',
                link: '/teile-und-zubehoer',
                target: '',
                active: 0,
                current: 0,
                spacer: 0,
                dropdown: {
                  offer: null,
                  topics: {
                    headline: '',
                    action: [],
                    cards: [
                      {
                        text: '',
                        image: '',
                        action: ''
                      }
                    ]
                  }
                },
                children: []
              }
            ],
            navigationSub: {
              message: [],
              context:
                'Verkauf: 0711 2528 09 13 46 &nbsp;&nbsp;&nbsp; Service: 0711 2528 09 13 45',
              pages: [
                {
                  id: 416,
                  pid: 1,
                  type: 'a_01_01__action',
                  content: {
                    text: 'Emil Frey Schwabengarage',
                    link: {
                      type: 'page',
                      url: '/schwabengarage',
                      target: '',
                      aTagParams: ' class="nav-item"',
                      link: 'Emil Frey Schwabengarage'
                    },
                    layout: 'nav-item'
                  }
                }
              ]
            },
            cards: [
              {
                variant: 'textlink',
                text: 'Wir kaufen Ihren Gebrauchten!',
                image: {
                  // tslint:disable-next-line:max-line-length
                  publicUrl:
                    'https://production.corporate.cms.emf.exu.dev/fileadmin/_processed_/c/1/csm_car-slider-2_6d4ba92c32.png',
                  properties: {
                    title: null,
                    alternative: null,
                    description: null,
                    mimeType: 'image/png',
                    type: 'image',
                    filename: 'csm_car-slider-2_6d4ba92c32.png',
                    originalUrl: 'fileadmin/user_upload/demo/car-slider-2.png',
                    uidLocal: 14,
                    fileReferenceUid: 139,
                    size: '67 KB',
                    link: null,
                    dimensions: {
                      width: 260,
                      height: 174
                    },
                    cropDimensions: {
                      width: 260,
                      height: 174
                    },
                    crop: {
                      image260: {
                        cropArea: {
                          height: 1,
                          width: 0.9375,
                          x: 0.0625,
                          y: 0
                        },
                        selectedRatio: '3:2',
                        focusArea: null
                      },
                      image100: {
                        cropArea: {
                          height: 1,
                          width: 0.625,
                          x: 0.2125,
                          y: 0
                        },
                        selectedRatio: '1:1',
                        focusArea: null
                      }
                    },
                    autoplay: null,
                    extension: 'png'
                  },
                  breakpoint: 'default'
                },
                action: {
                  type: 'page',
                  url: '/',
                  target: '',
                  aTagParams: '',
                  link: 'Emil Frey. Das Digitale Autohaus in Ihrer N\u00e4he.'
                }
              },
              {
                variant: 'textlink',
                text: 'Jaguar F-Type Cabriolet',
                image: {
                  // tslint:disable-next-line:max-line-length
                  publicUrl:
                    'https://production.corporate.cms.emf.exu.dev/fileadmin/_processed_/7/f/csm_emf-demo-003_7f8a0e15a1.jpg',
                  properties: {
                    title: null,
                    alternative: null,
                    description: null,
                    mimeType: 'image/jpeg',
                    type: 'image',
                    filename: 'csm_emf-demo-003_7f8a0e15a1.jpg',
                    originalUrl: 'fileadmin/user_upload/demo/emf-demo-003.jpg',
                    uidLocal: 230,
                    fileReferenceUid: 239,
                    size: '25 KB',
                    link: null,
                    dimensions: {
                      width: 260,
                      height: 174
                    },
                    cropDimensions: {
                      width: 260,
                      height: 174
                    },
                    crop: {
                      image260: {
                        cropArea: {
                          height: 0.7995198079231692,
                          width: 0.999,
                          x: 0,
                          y: 0.14285714285714285
                        },
                        selectedRatio: '3:2',
                        focusArea: null
                      },
                      image100: {
                        cropArea: {
                          height: 1,
                          width: 0.833,
                          x: 0.062,
                          y: 0
                        },
                        selectedRatio: '1:1',
                        focusArea: null
                      }
                    },
                    autoplay: null,
                    extension: 'jpg'
                  },
                  breakpoint: 'default'
                },
                action: {
                  type: 'page',
                  url: '/',
                  target: '',
                  aTagParams: '',
                  link: 'Emil Frey. Das Digitale Autohaus in Ihrer N\u00e4he.'
                }
              }
            ],
            breadcrumbs: [
              {
                title: 'Emil Frey Deutschland',
                link: '/',
                target: '',
                active: 1,
                current: 1,
                spacer: 0
              }
            ]
          }
        },
        footer: {
          context: {
            ctype: 'o_01_03__footer',
            cid: 'O 01-03 Footer',
            task: '14074801',
            uid: 20,
            pid: 1
          },
          content: {
            settings: {
              theme: 'dark'
            },
            header: {
              text: '<p><strong>Emil Frey</strong>&nbsp;Deutschland</p>',
              logo: [
                {
                  publicUrl:
                    'https://production.corporate.cms.emf.exu.dev/library/brand/emilfrey.svg',
                  properties: {
                    title: null,
                    alternative: null,
                    description: null,
                    mimeType: 'image/svg+xml',
                    type: 'image',
                    filename: 'emilfrey.svg',
                    originalUrl: 'library/brand/emilfrey.svg',
                    uidLocal: 9,
                    fileReferenceUid: 9,
                    size: '7 KB',
                    link: null,
                    dimensions: {
                      width: 281,
                      height: 283
                    },
                    cropDimensions: {
                      width: 279,
                      height: 279
                    },
                    crop: {
                      o_01_03__footer_logo: {
                        cropArea: {
                          x: 0,
                          y: 0.006865558375032689,
                          width: 0.9932885906040267,
                          height: 0.9862688832499347
                        },
                        selectedRatio: '1:1',
                        focusArea: null
                      }
                    },
                    autoplay: 0,
                    extension: 'svg'
                  },
                  breakpoint: 'default'
                }
              ]
            },
            pages: [
              {
                id: 458,
                pid: 41,
                type: 'a_01_01__action',
                content: {
                  text: 'Digitales Autohaus',
                  link: {
                    type: 'page',
                    url: '/digitales-autohaus',
                    target: '',
                    aTagParams: ' class="footer-link"',
                    link: 'Digitales Autohaus'
                  },
                  layout: 'footer-link'
                }
              },
              {
                id: 2,
                pid: 1,
                type: 'a_01_01__action',
                content: {
                  text: 'Auto kaufen',
                  link: {
                    type: 'page',
                    url: '/automarkt',
                    target: '',
                    aTagParams: ' class="footer-link"',
                    link: 'Auto kaufen'
                  },
                  layout: 'footer-link'
                }
              },
              {
                id: 69,
                pid: 41,
                type: 'a_01_01__action',
                content: {
                  text: 'Auto verkaufen',
                  link: {
                    type: 'page',
                    url: '/autoankauf',
                    target: '',
                    aTagParams: ' class="footer-link"',
                    link: 'Auto verkaufen'
                  },
                  layout: 'footer-link'
                }
              },
              {
                id: 94,
                pid: 41,
                type: 'a_01_01__action',
                content: {
                  text: 'Auto Konfigurieren',
                  link: 'Auto Konfigurieren',
                  layout: 'footer-link'
                }
              },
              {
                id: 72,
                pid: 41,
                type: 'a_01_01__action',
                content: {
                  text: 'Elektromobilit\u00e4t',
                  link: {
                    type: 'page',
                    url: '/elektromobilitaet',
                    target: '',
                    aTagParams: ' class="footer-link"',
                    link: 'Elektromobilit\u00e4t'
                  },
                  layout: 'footer-link'
                }
              },
              {
                id: 148,
                pid: 41,
                type: 'a_01_01__action',
                content: {
                  text: 'Teile und Zubeh\u00f6r',
                  link: {
                    type: 'page',
                    url: '/teile-und-zubehoer',
                    target: '',
                    aTagParams: ' class="footer-link"',
                    link: 'Teile und Zubeh\u00f6r'
                  },
                  layout: 'footer-link'
                }
              },
              {
                id: 180,
                pid: 41,
                type: 'a_01_01__action',
                content: {
                  text: 'Online Terminvereinbarung',
                  link: {
                    type: 'page',
                    url: '/services/terminvereinbarung',
                    target: '',
                    aTagParams: ' class="footer-link"',
                    link: 'Online Terminvereinbarung'
                  },
                  layout: 'footer-link'
                }
              },
              {
                id: 73,
                pid: 41,
                type: 'a_01_01__action',
                content: {
                  text: 'Markenwelt',
                  link: {
                    type: 'page',
                    url: '/markenportfolio',
                    target: '',
                    aTagParams: ' class="footer-link"',
                    link: 'Markenwelt'
                  },
                  layout: 'footer-link'
                }
              },
              {
                id: 459,
                pid: 41,
                type: 'a_01_01__action',
                content: {
                  text: 'Standorte',
                  link: {
                    type: 'page',
                    url: '/standorte',
                    target: '',
                    aTagParams: ' class="footer-link"',
                    link: 'Standorte'
                  },
                  layout: 'footer-link'
                }
              },
              {
                id: 11,
                pid: 1,
                type: 'a_01_01__action',
                content: {
                  text: 'Karriere',
                  link: {
                    type: 'page',
                    url: '/karriere',
                    target: '',
                    aTagParams: ' class="footer-link"',
                    link: 'Karriere'
                  },
                  layout: 'footer-link'
                }
              },
              {
                id: 74,
                pid: 41,
                type: 'a_01_01__action',
                content: {
                  text: 'Weitere Gesch\u00e4ftsfelder',
                  link: {
                    type: 'page',
                    url: '/weitere-informationen',
                    target: '',
                    aTagParams: ' class="footer-link"',
                    link: 'Weitere Gesch\u00e4ftsfelder'
                  },
                  layout: 'footer-link'
                }
              },
              {
                id: 80,
                pid: 41,
                type: 'a_01_01__action',
                content: {
                  text: '\u00dcber Emil Frey',
                  link: {
                    type: 'page',
                    url: '/ueber-emil-frey',
                    target: '',
                    aTagParams: ' class="footer-link"',
                    link: '\u00dcber Emil Frey'
                  },
                  layout: 'footer-link'
                }
              },
              {
                id: 460,
                pid: 1,
                type: 'a_01_01__action',
                content: {
                  text: 'Kontakt',
                  link: {
                    type: 'page',
                    url: '/standorte',
                    target: '',
                    aTagParams: ' class="footer-link"',
                    link: 'Kontakt'
                  },
                  layout: 'footer-link'
                }
              }
            ],
            newsletter: {
              title: 'Newsletter',
              teaser:
                'Registrieren Sie sich jetzt f\u00fcr den Emil Frey Deutschland Newsletter und verpassen Sie keine Neuigkeiten mehr.',
              placeholder: 'E-Mail Adresse'
            },
            socialmedia: [
              {
                id: 15,
                pid: 1,
                type: 'a_01_01__action',
                content: {
                  text: 'Facebook',
                  link: {
                    type: 'url',
                    url: 'https://facebook.com',
                    target: ' target="_blank"',
                    aTagParams: ' class="icon-secondary-default"',
                    link: 'Facebook'
                  },
                  layout: 'icon-secondary-default',
                  variant: '',
                  icon: {
                    name: 'facebook',
                    filename: 'facebook.svg',
                    // tslint:disable-next-line:max-line-length
                    uri: 'https://production.corporate.cms.emf.exu.dev/typo3conf/ext/contentprovider/Resources/Public/Images/socialmedia/icons/facebook.svg'
                  }
                }
              },
              {
                id: 16,
                pid: 1,
                type: 'a_01_01__action',
                content: {
                  text: 'Instagram',
                  link: {
                    type: 'url',
                    url: 'https://www.instagram.com/',
                    target: ' target="_blank"',
                    aTagParams: ' class="icon-secondary-default"',
                    link: 'Instagram'
                  },
                  layout: 'icon-secondary-default',
                  variant: '',
                  icon: {
                    name: 'instagram',
                    filename: 'instagram.svg',
                    // tslint:disable-next-line:max-line-length
                    uri: 'https://production.corporate.cms.emf.exu.dev/typo3conf/ext/contentprovider/Resources/Public/Images/socialmedia/icons/instagram.svg'
                  }
                }
              },
              {
                id: 198,
                pid: 41,
                type: 'a_01_01__action',
                content: {
                  text: 'LinkedIn',
                  link: {
                    type: 'url',
                    url: 'https://www.linkedin.com/',
                    target: ' target="_blank"',
                    aTagParams: ' class="icon-secondary-default"',
                    link: 'LinkedIn'
                  },
                  layout: 'icon-secondary-default',
                  variant: '',
                  icon: {
                    name: 'linkedin',
                    filename: 'linkedin.svg',
                    // tslint:disable-next-line:max-line-length
                    uri: 'https://production.corporate.cms.emf.exu.dev/typo3conf/ext/contentprovider/Resources/Public/Images/socialmedia/icons/linkedin.svg'
                  }
                }
              },
              {
                id: 203,
                pid: 41,
                type: 'a_01_01__action',
                content: {
                  text: 'YouTube',
                  link: {
                    type: 'url',
                    url: 'https://www.youtube.com/',
                    target: ' target="_blank"',
                    aTagParams: ' class="icon-secondary-default"',
                    link: 'YouTube'
                  },
                  layout: 'icon-secondary-default',
                  variant: '',
                  icon: {
                    name: 'youtube',
                    filename: 'youtube.svg',
                    // tslint:disable-next-line:max-line-length
                    uri: 'https://production.corporate.cms.emf.exu.dev/typo3conf/ext/contentprovider/Resources/Public/Images/socialmedia/icons/youtube.svg'
                  }
                }
              }
            ],
            legal: [
              {
                id: 19,
                pid: 1,
                type: 'a_01_01__action',
                content: {
                  text: 'Impressum',
                  link: {
                    type: 'page',
                    url: '/impressum',
                    target: '',
                    aTagParams: ' class="footer-link-small"',
                    link: 'Impressum'
                  },
                  layout: 'footer-link-small'
                }
              },
              {
                id: 20,
                pid: 1,
                type: 'a_01_01__action',
                content: {
                  text: 'AGB',
                  link: {
                    type: 'page',
                    url: '/allgemeine-geschaeftsbedingungen',
                    target: '',
                    aTagParams: ' class="footer-link-small"',
                    link: 'AGB'
                  },
                  layout: 'footer-link-small'
                }
              },
              {
                id: 214,
                pid: 41,
                type: 'a_01_01__action',
                content: {
                  text: 'Datenschutz',
                  link: {
                    type: 'page',
                    url: '/datenschutz',
                    target: '',
                    aTagParams: ' class="footer-link-small"',
                    link: 'Datenschutz'
                  },
                  layout: 'footer-link-small'
                }
              },
              {
                id: 219,
                pid: 41,
                type: 'a_01_01__action',
                content: {
                  text: 'Cookies',
                  link: {
                    type: 'page',
                    url: '/cookies',
                    target: '',
                    aTagParams: ' class="footer-link-small"',
                    link: 'Cookies'
                  },
                  layout: 'footer-link-small'
                }
              }
            ],
            card: {
              content: {
                variant: 'leasing-bonus',
                title: 'Entdecken Sie unsere einzigartige Markenvielfalt',
                availability: '',
                availabilityShort: '',
                headline:
                  'Entdecken Sie unsere einzigartige Markenvielfalt mit \u00fcber 24 Marken',
                headlineShort:
                  'Entdecken Sie unsere einzigartige Markenvielfalt mit \u00fcber 24 Marken',
                subline: '24 Marken. Mehr Auswahl. Mehr M\u00f6glichkeiten.',
                sublineShort:
                  '24 Marken. Mehr Auswahl. Mehr M\u00f6glichkeiten.',
                imageSmall: [
                  {
                    // tslint:disable-next-line:max-line-length
                    publicUrl:
                      'https://production.corporate.cms.emf.exu.dev/fileadmin/user_upload/testing/Testing_Philipp/Emil-Frey_Panorama_VolumenMarken_02-dark.jpg',
                    properties: {
                      title: null,
                      alternative: null,
                      description: null,
                      mimeType: 'image/jpeg',
                      type: 'image',
                      filename: 'Emil-Frey_Panorama_VolumenMarken_02-dark.jpg',
                      originalUrl:
                        'fileadmin/user_upload/testing/Testing_Philipp/Emil-Frey_Panorama_VolumenMarken_02-dark.jpg',
                      uidLocal: 1000,
                      fileReferenceUid: 624,
                      size: '301 KB',
                      link: null,
                      dimensions: {
                        width: 2000,
                        height: 1333
                      },
                      cropDimensions: {
                        width: 2000,
                        height: 1333
                      },
                      crop: {
                        image_small: {
                          cropArea: {
                            x: 0.12557942500000002,
                            y: 0,
                            width: 0.50194115,
                            height: 1
                          },
                          selectedRatio: '1_33:1',
                          focusArea: null
                        }
                      },
                      autoplay: null,
                      extension: 'jpg'
                    }
                  }
                ],
                imageMedium: [
                  {
                    // tslint:disable-next-line:max-line-length
                    publicUrl:
                      'https://production.corporate.cms.emf.exu.dev/fileadmin/user_upload/testing/Testing_Philipp/Emil-Frey_Panorama_VolumenMarken_02-dark.jpg',
                    properties: {
                      title: null,
                      alternative: null,
                      description: null,
                      mimeType: 'image/jpeg',
                      type: 'image',
                      filename: 'Emil-Frey_Panorama_VolumenMarken_02-dark.jpg',
                      originalUrl:
                        'fileadmin/user_upload/testing/Testing_Philipp/Emil-Frey_Panorama_VolumenMarken_02-dark.jpg',
                      uidLocal: 1000,
                      fileReferenceUid: 625,
                      size: '301 KB',
                      link: null,
                      dimensions: {
                        width: 2000,
                        height: 1333
                      },
                      cropDimensions: {
                        width: 2000,
                        height: 1333
                      },
                      crop: {
                        image_medium: {
                          cropArea: {
                            x: 0.278155675,
                            y: 0,
                            width: 1.11178865,
                            height: 1
                          },
                          selectedRatio: '1_66:1',
                          focusArea: null
                        }
                      },
                      autoplay: null,
                      extension: 'jpg'
                    }
                  }
                ],
                imageLarge: [
                  {
                    // tslint:disable-next-line:max-line-length
                    publicUrl:
                      'https://production.corporate.cms.emf.exu.dev/fileadmin/user_upload/testing/Testing_Philipp/Emil-Frey_Panorama_VolumenMarken_02-dark.jpg',
                    properties: {
                      title: null,
                      alternative: null,
                      description: null,
                      mimeType: 'image/jpeg',
                      type: 'image',
                      filename: 'Emil-Frey_Panorama_VolumenMarken_02-dark.jpg',
                      originalUrl:
                        'fileadmin/user_upload/testing/Testing_Philipp/Emil-Frey_Panorama_VolumenMarken_02-dark.jpg',
                      uidLocal: 1000,
                      fileReferenceUid: 626,
                      size: '301 KB',
                      link: null,
                      dimensions: {
                        width: 2000,
                        height: 1333
                      },
                      cropDimensions: {
                        width: 2000,
                        height: 1333
                      },
                      crop: {
                        image_large: {
                          cropArea: {
                            x: 0.11400697499999998,
                            y: 0,
                            width: 0.45568604999999995,
                            height: 1
                          },
                          selectedRatio: '1:1_5',
                          focusArea: null
                        }
                      },
                      autoplay: null,
                      extension: 'jpg'
                    }
                  }
                ],
                imageXl: [
                  {
                    // tslint:disable-next-line:max-line-length
                    publicUrl:
                      'https://production.corporate.cms.emf.exu.dev/fileadmin/user_upload/testing/Testing_Philipp/Emil-Frey_Panorama_VolumenMarken_02-dark.jpg',
                    properties: {
                      title: null,
                      alternative: null,
                      description: null,
                      mimeType: 'image/jpeg',
                      type: 'image',
                      filename: 'Emil-Frey_Panorama_VolumenMarken_02-dark.jpg',
                      originalUrl:
                        'fileadmin/user_upload/testing/Testing_Philipp/Emil-Frey_Panorama_VolumenMarken_02-dark.jpg',
                      uidLocal: 1000,
                      fileReferenceUid: 627,
                      size: '301 KB',
                      link: null,
                      dimensions: {
                        width: 2000,
                        height: 1333
                      },
                      cropDimensions: {
                        width: 2000,
                        height: 1333
                      },
                      crop: {
                        image_xl: {
                          cropArea: {
                            x: 0.3335,
                            y: 0,
                            width: 1.333,
                            height: 1
                          },
                          selectedRatio: '2:1',
                          focusArea: null
                        }
                      },
                      autoplay: null,
                      extension: 'jpg'
                    }
                  }
                ],
                ctaboxText: 'Jetzt Traumauto finden',
                ctaboxTextShort: 'Cta Box text m',
                ctaboxPrice: '123',
                ctaboxTime: '123',
                slogan: 'Jetzt Traumauto finden',
                usp: [
                  {
                    icon: [
                      {
                        publicUrl:
                          'https://production.corporate.cms.emf.exu.dev/fileadmin/user_upload/testing/label.svg',
                        properties: {
                          title: null,
                          alternative: null,
                          description: null,
                          mimeType: 'image/svg+xml',
                          type: 'image',
                          filename: 'label.svg',
                          originalUrl:
                            'fileadmin/user_upload/testing/label.svg',
                          uidLocal: null,
                          fileReferenceUid: 103,
                          size: '1 KB',
                          link: null,
                          dimensions: {
                            width: 24,
                            height: 24
                          },
                          cropDimensions: {
                            width: 24,
                            height: 24
                          },
                          crop: null,
                          autoplay: null,
                          extension: 'svg'
                        },
                        breakpoint: 'default'
                      }
                    ],
                    headline: 'Headline',
                    subline: 'Subline',
                    hoverHeadline: 'Hover headline',
                    hoverText: 'Hover text',
                    uid: 12,
                    _localizedUid: 12,
                    _languageUid: 0,
                    _versionedUid: 12,
                    pid: 42
                  },
                  {
                    icon: null,
                    headline: 'Headline 2',
                    subline: 'Subline 2',
                    hoverHeadline: 'Hover Headline',
                    hoverText: 'Hover Text',
                    uid: 13,
                    _localizedUid: 13,
                    _languageUid: 0,
                    _versionedUid: 13,
                    pid: 42
                  }
                ],
                actions: [
                  {
                    id: 467,
                    pid: 42,
                    type: 'a_01_01__action',
                    content: {
                      text: 'Jetzt Traumauto finden',
                      link: {
                        type: 'page',
                        url: '/konfigurator',
                        target: '',
                        aTagParams: ' class="button-secondary-default"',
                        link: 'Jetzt Traumauto finden'
                      },
                      layout: 'button-secondary-default',
                      variant: 'small',
                      icon: {
                        name: null,
                        filename: null,
                        uri: null,
                        position: null,
                        variant: null
                      }
                    }
                  }
                ],
                offerbrand: [],
                displaymode: 'universal'
              },
              id: 1,
              pid: 1,
              type: 'm_01_09__offercard'
            },
            legalNotice: {
              col1: {
                header: 'Hinweis zu Kraftstoffverbrauch und CO2-Emissionen',
                // tslint:disable-next-line:max-line-length
                text: '<p>Die angegebenen Werte wurden nach dem vorgeschriebenen Messverfahren [VO (EG) 715/2007 und VO (EG) 692/2008 in der jeweils geltenden Fassung] ermittelt. Die Angaben beziehen sich nicht auf ein einzelnes Fahrzeug und sind nicht Bestandteil des Angebotes, sondern dienen allein Vergleichszwecken zwischen den verschiedenen Fahrzeugtypen.</p>'
              },
              col2: {
                header: 'Hinweis nach Richtlinie 1999/94/EG',
                // tslint:disable-next-line:max-line-length
                text: '<p>Der Kraftstoffverbrauch und die CO2-Emissionen eines Fahrzeugs h\u00e4ngen nicht nur von der effizienten Ausnutzung des Kraftstoffs durch das Fahrzeug ab, sondern werden auch vom Fahrverhalten und anderen nichttechnischen Faktoren beeinflusst. CO2 ist das f\u00fcr die Erderw\u00e4rmung haupts\u00e4chlich verantwortliche Treibhausgas.</p>'
              },
              col3: {
                header: '',
                // tslint:disable-next-line:max-line-length
                text: '<p>Weitere Informationen zum offiziellen&nbsp;Kraftstoffverbrauch und den offiziellen spezifischen CO2-Emissionen neuer Personenkraftwagen k\u00f6nnen dem&nbsp;<a href="https://www.dat.de/co2/" target="_blank" rel="noreferrer">\'Leitfaden \u00fcber den Kraftstoffverbrauch</a>, die CO2-Emissionen und den Stromverbrauch neuer Personenkraftwagen\' entnommen werden, der an allen Verkaufsstellen und bei&nbsp;<a href="http://www.dat.de/" target="_blank" rel="noreferrer">http://www.dat.de</a>&nbsp;unentgeltlich erh\u00e4ltlich ist. F\u00fcr weitere Informationen siehe&nbsp;<a href="http://www.gesetze-im-internet.de/pkw-envkv/index.html" target="_blank" rel="noreferrer">Pkw - Energieverbrauchskennzeichnungsverordnung \u2013 Pkw-EnVKV.</a></p>'
              }
            }
          }
        }
      }
    })
  }
}

@Injectable({
  providedIn: 'root'
})
export class HeaderFooterService implements IHeaderFooterService {
  private readonly apiUrl: string

  constructor(
    private readonly http: HttpClient,
    private readonly dataService: DataService,
    private readonly logger: NGXLogger,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl =
    this.remoteConfigService.get('headerV2.baseUrl') ??
    'https://production.corporate.cms.emf.exu.dev/'
  }

  getHeaderFooterData(): Observable<HeaderResponse> {
    return this.http.get<HeaderResponse>(this.apiUrl)
  }

  getHeaderFooter(): void {
    // get header Footer data
    this.getHeaderFooterData().subscribe(
      value => {
        this.dataService.headerFooterDataLoaded(value)
      },
      error => {
        this.logger.debug(error)
        this.dataService.headerFooterDataLoaded(null)
      }
    )
  }
}
