<div id="RevolutionTyreMoleculeComponent">
  <customer-portal-app-skeleton-loader [loading]="maintenanceLoaded"></customer-portal-app-skeleton-loader>

  <ng-container *ngIf="maintenanceLoaded">
    <mat-divider class="relative w-full text-mat-ef-revolution-supergrey-500"></mat-divider>

    <mat-expansion-panel hideToggle="true" disabled="true" #tires *ngIf="!set || set?.tires?.length === 0">
      <mat-expansion-panel-header class="last-service p-0 md:px-6" [collapsedHeight]="'auto'" [expandedHeight]="'auto'"
        [ngClass]="{ 'disable-hover': !maintenanceLoaded }">
        <mat-panel-title>
          <div class="mt-1 mr-1">
            <mat-icon class="text-mat-ef-revolution-primary-500" [ngClass]="tires?.expanded ? 'blue-icon' : ''"
              svgIcon="car_tire">
            </mat-icon>
          </div>

          <div class="vehicle-expansion-panel-text">
            <div class="w-full pt-1 overflow-hidden whitespace-nowrap text-ellipsis">
              <div class="flex text-sm sm:text-base font-bold">
                <T str="Tyres" key="customerPortal.customer-portal.vehicle.tires" tags="customer-portal, 3.1"></T>
              </div>

              <div class="text-sm font-efSans whitespace-normal">
                <T str="No tyres in storage." key="customerPortal.customer-portal.tyre-storage-empty"
                  tags="customer-portal, 3.1"></T>
              </div>
            </div>
          </div>
        </mat-panel-title>
        <mat-panel-description class="justify-start sm:justify-end text-mat-ef-revolution-primary-500">
          <div data-cy="tyre-molecule-newAppointment-button"
            class="w-full md:w-fit text-center whitespace-nowrap select-none text-base font-efSans cursor-pointer bg-mat-ef-revolution-supergrey-500 px-4 py-1 rounded-2xl font-semibold"
            (click)="link && hasServiceManager ? decideNextStep() : newAppointment(vehicle)">
            <T str="New appointment" key="customerPortal.customer-portal.top-bar.appointment"
              tags="customer-portal, 3.1"></T>
          </div>

          <div class="button-skeleton-div" *ngIf="!maintenanceLoaded">
            <ngx-skeleton-loader class="button-skeleton" appearance="line" animation="progress">
            </ngx-skeleton-loader>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <!-- (set && set?.tires?.length !== 0) -->

    <!--  Tires-->
    <mat-expansion-panel #tires (afterExpand)="
        sendContentModulesData(
          true,
          'customerPortal.customer-portal.vehicle.tires'
        )
      " (afterCollapse)="
        sendContentModulesData(
          false,
          'customerPortal.customer-portal.vehicle.tires'
        )
      " *ngIf="set && set?.tires?.length !== 0">
      <mat-expansion-panel-header class="p-0 md:px-6" [collapsedHeight]="'auto'" [expandedHeight]="'auto'"
        [ngClass]="{ 'disable-hover': !maintenanceLoaded }">
        <mat-panel-title>
          <div class="mt-1 mr-1 text-mat-ef-revolution-primary-500">
            <mat-icon [ngClass]="tires?.expanded ? 'blue-icon' : ''" svgIcon="car_tire"></mat-icon>
          </div>

          <div class="vehicle-expansion-panel-text">
            <div class="flex text-sm sm:text-base font-bold">
              <T str="Tyres" key="customerPortal.customer-portal.vehicle.tires" tags="customer-portal, 3.1"></T>
            </div>
            <div class="text-sm font-efSans whitespace-normal" *ngIf="
                VR &&
                VL &&
                HL &&
                HR &&
                set &&
                !loadingTires &&
                numberOfWarnings === 0 &&
                maintenanceLoaded
              ">
              <ng-container *ngTemplateOutlet="
                  sharedService.template('transifexTranslation');
                  context: {
                    key: 'customerPortal.customer-portal.tires-storage',
                    params: {
                      type: getTypeTranslation(set?.tyreType)
                    }
                  }
                "></ng-container>
            </div>
            <div class="text-sm font-efSans font-light whitespace-normal" *ngIf="
                VR &&
                VL &&
                HL &&
                HR &&
                set &&
                !loadingTires &&
                numberOfWarnings > 1
              ">
              <ng-container *ngTemplateOutlet="
                  sharedService.template('transifexTranslation');
                  context: {
                    key: 'customerPortal.customer-portal.tires-storage-warning',
                    params: {
                      type: getTypeTranslation(set?.tyreType),
                      number: numberOfWarnings
                    }
                  }
                "></ng-container>
            </div>
            <div class="text-sm font-efSans font-light whitespace-normal" *ngIf="
                VR &&
                VL &&
                HL &&
                HR &&
                set &&
                !loadingTires &&
                numberOfWarnings === 1
              ">
              <ng-container *ngTemplateOutlet="
                  sharedService.template('transifexTranslation');
                  context: {
                    key: 'customerPortal.customer-portal.tire-storage-warning',
                    params: {
                      type: getTypeTranslation(set?.tyreType),
                      number: numberOfWarnings
                    }
                  }
                "></ng-container>
            </div>
          </div>
          <div class="vehicle-expansion-panel-text" *ngIf="!maintenanceLoaded">
            <div class="w-full pt-1 overflow-hidden whitespace-nowrap text-ellipsis">
              <div class="text-sm font-efSans font-light whitespace-normal" *ngIf="
                  VR &&
                  VL &&
                  HL &&
                  HR &&
                  set &&
                  !loadingTires &&
                  numberOfWarnings === 0 &&
                  !maintenanceLoaded
                ">
                <ngx-skeleton-loader appearance="line" animation="progress"></ngx-skeleton-loader>
              </div>
              <div class="text-sm font-efSans font-light whitespace-normal" *ngIf="
                  VR &&
                  VL &&
                  HL &&
                  HR &&
                  set &&
                  !loadingTires &&
                  numberOfWarnings > 1 &&
                  !maintenanceLoaded
                ">
                <ngx-skeleton-loader appearance="line" animation="progress"></ngx-skeleton-loader>
              </div>
              <div class="text-sm font-efSans font-light whitespace-normal" *ngIf="
                  VR &&
                  VL &&
                  HL &&
                  HR &&
                  set &&
                  !loadingTires &&
                  numberOfWarnings === 1 &&
                  !maintenanceLoaded
                ">
                <ngx-skeleton-loader appearance="line" animation="progress"></ngx-skeleton-loader>
              </div>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-divider class="relative w-full"></mat-divider>
      <div class="-mx-6 md:m-0">
        <div class="w-full">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 1366 925" style="enable-background: new 0 0 1366 925" xml:space="preserve"
            class="h-60 p-5 w-full box-border">
            <path class="st0" d="M1350.48,314.58h-24.06c-0.83,0-1.5-0.67-1.5-1.5v-15.04c0-0.83,0.67-1.5,1.5-1.5h24.06
        c0.83,0,1.5,0.67,1.5,1.5v15.04C1351.98,313.9,1351.31,314.58,1350.48,314.58z" />
            <path class="st0" d="M1359.5,346.15h-24.06c-0.83,0-1.5-0.67-1.5-1.5v-15.04c0-0.83,0.67-1.5,1.5-1.5h24.06c0.83,0,1.5,0.67,1.5,1.5
        v15.04C1361,345.47,1360.33,346.15,1359.5,346.15z" />
            <g>
              <g>
                <path class="st1" d="M480.43,209.62l-10.15,0.1c0,0,2.13-3.46,5.22-8.7" />
                <path class="st2"
                  d="M484.98,184.48c8.76-15.88,17.3-33.39,12.37-33.39c-22.92,0-40.38,23.66-50.07,40.92" />
                <path class="st1" d="M442.85,200.46c-2.71,5.55-4.14,9.26-4.14,9.26s-3.91,0.39-10.11,0.83" />
                <path class="st3" d="M408.68,211.55c-13.82,0.41-29.83,0.22-42.12-1.83c-27.06-4.51-94.71-9.02-94.71-9.02
            s-144.32-4.51-202.95,54.12C18.92,304.8,5.76,421.69,5.96,457.77c-0.2,36.08,12.96,152.97,62.94,202.95
            c58.63,58.63,202.95,54.12,202.95,54.12s67.65-4.51,94.71-9.02c15.4-2.57,36.64-2.21,52.09-1.43" />
                <path class="st1" d="M428.6,704.99c6.2,0.44,10.11,0.83,10.11,0.83s1.43,3.71,4.14,9.26" />
                <path class="st2"
                  d="M452.19,731.69c10.17,15.71,25.69,32.76,45.15,32.76c5.82,0-7.12-24.38-17.04-41.69" />
                <path class="st1" d="M475.51,714.51c-3.09-5.24-5.22-8.7-5.22-8.7l10.15,0.1" />
                <path class="st4"
                  d="M500.56,706.11l425.22,4.21c0,0,49.61,4.51,103.73,4.51c72.16,0,90.2-18.04,130.79-18.04
            c40.59,0,96.82-13.01,117.26-22.55c67.65-31.57,67.65-216.48,67.65-216.48s0-184.91-67.65-216.48
            c-20.43-9.54-76.67-22.55-117.26-22.55c-40.59,0-58.63-18.04-130.79-18.04c-54.12,0-103.73,4.51-103.73,4.51l-435.29,4.31" />
              </g>
            </g>
            <path [attr.fill]="getColor(VR)" d="M370.09,308.95H187.14c-5.52,0-10-4.48-10-10v-61.18c0-5.52,4.48-10,10-10h182.95c5.52,0,10,4.48,10,10v61.18
        C380.09,304.47,375.61,308.95,370.09,308.95z" />
            <rect x="267.34" y="322.48" class="st6" width="27.06" height="275.11" />
            <line class="st7" x1="177.14" y1="268.36" x2="380.09" y2="268.36" />
            <polyline class="st7" points="177.14,244.67 186.36,253.69 195.59,244.67 204.81,253.69 214.04,244.67 223.26,253.69 232.49,244.67
        241.71,253.69 250.94,244.67 260.16,253.69 269.39,244.67 278.61,253.69 287.84,244.67 297.06,253.69 306.29,244.67 315.51,253.69
        324.74,244.67 333.96,253.69 343.19,244.67 352.41,253.69 361.64,244.67 370.86,253.69 380.09,244.67 " />
            <polyline class="st7" points="177.14,285.26 186.36,294.28 195.59,285.26 204.81,294.28 214.04,285.26 223.26,294.28 232.49,285.26
        241.71,294.28 250.94,285.26 260.16,294.28 269.39,285.26 278.61,294.28 287.84,285.26 297.06,294.28 306.29,285.26 315.51,294.28
        324.74,285.26 333.96,294.28 343.19,285.26 352.41,294.28 361.64,285.26 370.86,294.28 380.09,285.26 " />
            <path [attr.fill]="getColor(VL)" d="M370.09,692.3H187.14c-5.52,0-10-4.48-10-10v-61.18c0-5.52,4.48-10,10-10h182.95c5.52,0,10,4.48,10,10v61.18
        C380.09,687.82,375.61,692.3,370.09,692.3z" />
            <line class="st7" x1="177.14" y1="651.71" x2="380.09" y2="651.71" />
            <polyline class="st7" points="177.14,628.02 186.36,637.04 195.59,628.02 204.81,637.04 214.04,628.02 223.26,637.04 232.49,628.02
        241.71,637.04 250.94,628.02 260.16,637.04 269.39,628.02 278.61,637.04 287.84,628.02 297.06,637.04 306.29,628.02 315.51,637.04
        324.74,628.02 333.96,637.04 343.19,628.02 352.41,637.04 361.64,628.02 370.86,637.04 380.09,628.02 " />
            <polyline class="st7" points="177.14,668.61 186.36,677.63 195.59,668.61 204.81,677.63 214.04,668.61 223.26,677.63 232.49,668.61
        241.71,677.63 250.94,668.61 260.16,677.63 269.39,668.61 278.61,677.63 287.84,668.61 297.06,677.63 306.29,668.61 315.51,677.63
        324.74,668.61 333.96,677.63 343.19,668.61 352.41,677.63 361.64,668.61 370.86,677.63 380.09,668.61 " />
            <path [attr.fill]="getColor(HR)" d="M1118.74,308.95H935.79c-5.52,0-10-4.48-10-10v-61.18c0-5.52,4.48-10,10-10h182.95c5.52,0,10,4.48,10,10v61.18
        C1128.74,304.47,1124.26,308.95,1118.74,308.95z" />
            <rect x="1015.99" y="320.78" class="st6" width="27.06" height="275.11" />
            <line class="st7" x1="925.79" y1="268.36" x2="1128.74" y2="268.36" />
            <polyline class="st7"
              points="925.79,244.67 935.01,253.69 944.24,244.67 953.46,253.69 962.69,244.67 971.91,253.69 981.14,244.67
        990.36,253.69 999.59,244.67 1008.81,253.69 1018.04,244.67 1027.26,253.69 1036.49,244.67 1045.71,253.69 1054.94,244.67
        1064.16,253.69 1073.39,244.67 1082.61,253.69 1091.84,244.67 1101.06,253.69 1110.29,244.67 1119.51,253.69 1128.74,244.67 " />
            <polyline class="st7"
              points="925.79,285.26 935.01,294.28 944.24,285.26 953.46,294.28 962.69,285.26 971.91,294.28 981.14,285.26
        990.36,294.28 999.59,285.26 1008.81,294.28 1018.04,285.26 1027.26,294.28 1036.49,285.26 1045.71,294.28 1054.94,285.26
        1064.16,294.28 1073.39,285.26 1082.61,294.28 1091.84,285.26 1101.06,294.28 1110.29,285.26 1119.51,294.28 1128.74,285.26 " />
            <path [attr.fill]="getColor(HL)" d="M1118.74,692.3H935.79c-5.52,0-10-4.48-10-10v-61.18c0-5.52,4.48-10,10-10h182.95c5.52,0,10,4.48,10,10v61.18
        C1128.74,687.82,1124.26,692.3,1118.74,692.3z" />
            <line class="st7" x1="925.79" y1="651.71" x2="1128.74" y2="651.71" />
            <polyline class="st7"
              points="925.79,628.02 935.01,637.04 944.24,628.02 953.46,637.04 962.69,628.02 971.91,637.04 981.14,628.02
        990.36,637.04 999.59,628.02 1008.81,637.04 1018.04,628.02 1027.26,637.04 1036.49,628.02 1045.71,637.04 1054.94,628.02
        1064.16,637.04 1073.39,628.02 1082.61,637.04 1091.84,628.02 1101.06,637.04 1110.29,628.02 1119.51,637.04 1128.74,628.02 " />
            <polyline class="st7"
              points="925.79,668.61 935.01,677.63 944.24,668.61 953.46,677.63 962.69,668.61 971.91,677.63 981.14,668.61
        990.36,677.63 999.59,668.61 1008.81,677.63 1018.04,668.61 1027.26,677.63 1036.49,668.61 1045.71,677.63 1054.94,668.61
        1064.16,677.63 1073.39,668.61 1082.61,677.63 1091.84,668.61 1101.06,677.63 1110.29,668.61 1119.51,677.63 1128.74,668.61 " />
            <circle class="st6" cx="1029.52" cy="458.34" r="38.27" />
            <g>
              <path class="st10" d="M30.56,358.55c0,0,22.55-67.65,49.61-94.71c27.06-27.06,81.18-31.57,81.18-31.57s-49.61,22.55-67.65,49.61
          S71.15,322.47,62.13,336S30.56,358.55,30.56,358.55z" />
              <path class="st10" d="M30.56,556.99c0,0,22.55,67.65,49.61,94.71c27.06,27.06,81.18,31.57,81.18,31.57s-49.61-22.55-67.65-49.61
          c-18.04-27.06-22.55-40.59-31.57-54.12S30.56,556.99,30.56,556.99z" />
            </g>
            <line class="st11" x1="1030.13" y1="227.13" x2="1030.13" y2="57.91" />
            <line class="st11" x1="281.13" y1="227.13" x2="281.13" y2="57.91" />
            <line class="st11" x1="1030.13" y1="862.13" x2="1030.13" y2="692.91" />
            <line class="st11" x1="281.13" y1="862.13" x2="281.13" y2="692.91" />
            <circle [attr.fill]="getColor(VR)" cx="280.83" cy="33.01" r="30" />
            <polyline *ngIf="VR?.profileState === 'OK'" class="st12" points="298.25,20.13 272.75,45.63 261.25,34.13 " />
            <g *ngIf="!(VR?.profileState === 'OK')">
              <line class="st12" x1="294.34" y1="46.52" x2="267.32" y2="19.5" />
              <line class="st12" x1="267.32" y1="46.52" x2="294.34" y2="19.5" />
            </g>
            <circle [attr.fill]="getColor(HR)" cx="1029.75" cy="32.88" r="30" />
            <polyline *ngIf="HR?.profileState === 'OK'" class="st12"
              points="1048.25,20.13 1022.75,45.63 1011.25,34.13 " />
            <g *ngIf="!(HR?.profileState === 'OK')">
              <line class="st12" x1="1044.34" y1="46.52" x2="1017.32" y2="19.5" />
              <line class="st12" x1="1017.32" y1="46.52" x2="1044.34" y2="19.5" />
            </g>
            <circle [attr.fill]="getColor(HL)" cx="1031" cy="893" r="30" />
            <polyline *ngIf="HL?.profileState === 'OK'" class="st12"
              points="1048.25,880.13 1022.75,905.63 1011.25,894.13 " />
            <g *ngIf="!(HL?.profileState === 'OK')">
              <line class="st12" x1="1044.34" y1="880.13" x2="1017.32" y2="907.15" />
              <line class="st12" x1="1017.32" y1="880.13" x2="1044.34" y2="907.15" />
            </g>

            <circle [attr.fill]="getColor(VL)" cx="280.66" cy="893" r="30" />
            <polyline *ngIf="VL?.profileState === 'OK'" class="st12"
              points="298.25,880.13 272.75,905.63 261.25,894.13 " />
            <g *ngIf="!(VL?.profileState === 'OK')">
              <line class="st12" x1="294.34" y1="880.13" x2="267.32" y2="907.15" />
              <line class="st12" x1="267.32" y1="880.13" x2="294.34" y2="907.15" />
            </g>
            <text x="40" y="50" class="font-efSans text-fill" font-size="3.5em">
              {{ VR?.profile ? VR?.profile + ' mm' : '' }}
            </text>
            <text x="40" y="907" class="font-efSans text-fill" font-size="3.5em">
              {{ VL?.profile ? VL?.profile + ' mm' : '' }}
            </text>
            <text x="1120" y="50" class="font-efSans text-fill" font-size="3.5em">
              {{ HR?.profile ? HR?.profile + ' mm' : '' }}
            </text>
            <text x="1120" y="907" class="font-efSans text-fill" font-size="3.5em">
              {{ HL?.profile ? HL?.profile + ' mm' : '' }}
            </text>
          </svg>
        </div>
        <!-- <mat-divider class="relative w-full"></mat-divider> -->
        <div class="bg-mat-ef-revolution-grey-200 text-mat-ef-revolution-primary-500 p-6 rounded-md font-normal">
          <div class="m-0 md:ml-10 grid grid-cols-2 gap-5" *ngIf="VR && VL && HL && HR && set">
            <div>
              <h2 class="font-efSans text-base mb-1 font-bold">
                <T str="Front left" key="customerPortal.customer-portal.vehicle.tire-front-left"
                  tags="customer-portal, 3.1"></T>
              </h2>
              <div class="font-efSans text-sm">
                <span>
                  <div class="float-left w-2.5 h-2.5 rounded-full m-1 bg-mat-ef-green-secondary-500"
                    *ngIf="VL?.profileState === 'OK'"></div>
                  <div class="float-left w-2.5 h-2.5 rounded-full m-1 bg-mat-ef-yellow-secondary-500"
                    *ngIf="VL?.profileState === 'WARN'"></div>
                  <div class="float-left w-2.5 h-2.5 rounded-full m-1 bg-mat-ef-red-secondary-500"
                    *ngIf="VL?.profileState === 'ERR'"></div>
                  {{ VL?.profile ? VL?.profile : ' - ' }}
                  <ng-container *ngTemplateOutlet="threadDepth"></ng-container>
                </span>
                <p class="ml-5">
                  {{ VL?.brand ? VL?.brand + ' • ' : '' }}
                  {{ VL?.tyreDimension ? VL?.tyreDimension + ' • ' : '' }}
                  <ng-container *ngTemplateOutlet="tyreRim; context: { set: set }"></ng-container>
                </p>
              </div>
            </div>
            <div>
              <h2 class="font-efSans text-base mb-1 font-bold">
                <T str="Rear left" key="customerPortal.customer-portal.vehicle.tire-back-left"
                  tags="customer-portal, 3.1"></T>
              </h2>
              <div class="font-efSans text-sm">
                <span>
                  <div class="float-left w-2.5 h-2.5 rounded-full m-1 bg-mat-ef-green-secondary-500"
                    *ngIf="HL?.profileState === 'OK'"></div>
                  <div class="float-left w-2.5 h-2.5 rounded-full m-1 bg-mat-ef-yellow-secondary-500"
                    *ngIf="HL?.profileState === 'WARN'"></div>
                  <div class="float-left w-2.5 h-2.5 rounded-full m-1 bg-mat-ef-red-secondary-500"
                    *ngIf="HL?.profileState === 'ERR'"></div>
                  {{ HL?.profile ? HL?.profile : ' - ' }}
                  <ng-container *ngTemplateOutlet="threadDepth"></ng-container>
                </span>
                <p class="ml-5">
                  {{ HL?.brand ? HL?.brand + ' • ' : '' }}
                  {{ HL?.tyreDimension ? HL?.tyreDimension + ' • ' : '' }}
                  <ng-container *ngTemplateOutlet="tyreRim; context: { set: set }"></ng-container>
                </p>
              </div>
            </div>
          </div>
          <mat-divider class="relative w-full my-2"></mat-divider>
          <div class="m-0 md:ml-10 grid grid-cols-2 gap-5">
            <div>
              <h2 class="font-efSans text-base mb-1 font-bold">
                <T str="Front right" key="customerPortal.customer-portal.vehicle.tire-front-right"
                  tags="customer-portal, 3.1"></T>
              </h2>
              <div class="font-efSans text-sm">
                <span>
                  <div class="float-left w-2.5 h-2.5 rounded-full m-1 bg-mat-ef-green-secondary-500"
                    *ngIf="VR?.profileState === 'OK'"></div>
                  <div class="float-left w-2.5 h-2.5 rounded-full m-1 bg-mat-ef-yellow-secondary-500"
                    *ngIf="VR?.profileState === 'WARN'"></div>
                  <div class="float-left w-2.5 h-2.5 rounded-full m-1 bg-mat-ef-red-secondary-500"
                    *ngIf="VR?.profileState === 'ERR'"></div>
                  {{ VR?.profile ? VR?.profile : ' - ' }}
                  <ng-container *ngTemplateOutlet="threadDepth"></ng-container>
                </span>
                <p class="ml-5">
                  {{ VR?.brand ? VR?.brand + ' • ' : '' }}
                  {{ VR?.tyreDimension ? VR?.tyreDimension + ' • ' : '' }}
                  <ng-container *ngTemplateOutlet="tyreRim; context: { set: set }"></ng-container>
                </p>
              </div>
            </div>
            <div>
              <h2 class="font-efSans text-base mb-1 font-bold">
                <T str="Rear right" key="customerPortal.customer-portal.vehicle.tire-back-right"
                  tags="customer-portal, 3.1"></T>
              </h2>
              <div class="font-efSans text-sm">
                <span>
                  <div class="float-left w-2.5 h-2.5 rounded-full m-1 bg-mat-ef-green-secondary-500"
                    *ngIf="HR?.profileState === 'OK'"></div>
                  <div class="float-left w-2.5 h-2.5 rounded-full m-1 bg-mat-ef-yellow-secondary-500"
                    *ngIf="HR?.profileState === 'WARN'"></div>
                  <div class="float-left w-2.5 h-2.5 rounded-full m-1 bg-mat-ef-red-secondary-500"
                    *ngIf="HR?.profileState === 'ERR'"></div>
                  {{ HR?.profile ? HR?.profile : ' - ' }}
                  <ng-container *ngTemplateOutlet="threadDepth"></ng-container>
                </span>
                <p class="ml-5">
                  {{ HR?.brand ? HR?.brand + ' • ' : '' }}
                  {{ HR?.tyreDimension ? HR?.tyreDimension + ' • ' : '' }}
                  <ng-container *ngTemplateOutlet="tyreRim; context: { set: set }"></ng-container>
                </p>
              </div>
            </div>
          </div>
          <mat-divider class="relative w-full my-2" *ngIf="SP?.profile"></mat-divider>
          <div class="m-0 md:ml-10 grid grid-cols-2 gap-5" *ngIf="SP?.profile">
            <div>
              <h2 class="font-efSans text-base mb-1 font-bold">
                <T str="Spare tyre" key="customerPortal.customer-portal.vehicle.tire-spare" tags="customer-portal, 3.1">
                </T>
              </h2>
              <div class="font-efSans text-sm">
                <span>
                  <div class="float-left w-2.5 h-2.5 rounded-full m-1 bg-mat-ef-green-secondary-500"
                    *ngIf="SP?.profileState === 'OK'"></div>
                  <div class="float-left w-2.5 h-2.5 rounded-full m-1 bg-mat-ef-yellow-secondary-500"
                    *ngIf="SP?.profileState === 'WARN'"></div>
                  <div class="float-left w-2.5 h-2.5 rounded-full m-1 bg-mat-ef-red-secondary-500"
                    *ngIf="SP?.profileState === 'ERR'"></div>
                  {{ SP?.profile ? SP?.profile : ' - ' }}
                  <ng-container *ngTemplateOutlet="threadDepth"></ng-container>
                </span>
                <p class="ml-5">
                  {{ SP?.brand ? SP?.brand + ' • ' : '' }}
                  {{ SP?.tyreDimension ? SP?.tyreDimension + ' • ' : '' }}
                  <ng-container *ngTemplateOutlet="tyreRim; context: { set: set }"></ng-container>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="relative -r-10 w-full mt-5">
          <button data-cy="tyre-molecule-newAppointment-button" class="w-full md:w-auto float-right md:ml-5 revolution"
            mat-button mat-stroked-button color="secondary" (click)="
              link && hasServiceManager ? decideNextStep() : newAppointment(vehicle);
              sendContentModulesCTAData(
                'customerPortal.customer-portal.book-appointment'
              )
            ">
            <mat-icon class="mr-2" svgIcon="calendar_today"></mat-icon>
            <T str="New appointment" key="customerPortal.customer-portal.book-appointment" tags="customer-portal, 3.1">
            </T>
          </button>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-divider class="relative w-full"></mat-divider>
    <!-- End tyres -->
  </ng-container>
</div>

<ng-template #threadDepth>
  <T str="mm tread depth" key="customerPortal.customer-portal.vehicle.threadDepth" tags="customer-portal, 3.1"></T>
</ng-template>

<ng-template #tyreRim let-set="set">
  <ng-container *ngIf="set">
    <ng-container *ngIf="set?.tyreType">
      <ng-container *ngTemplateOutlet="
          sharedService.template('transifexTranslation');
          context: {
            str: set?.tyreType.toLowerCase(),
            prefix: 'customerPortal.customer-portal.'
          }
        "></ng-container>{{ ' • ' }}
    </ng-container>
    <ng-container *ngIf="set?.rimType">
      <ng-container *ngTemplateOutlet="
          sharedService.template('transifexTranslation');
          context: {
            str: set?.rimType.toLowerCase(),
            prefix: 'customerPortal.customer-portal.'
          }
        "></ng-container>
    </ng-container>
  </ng-container>
</ng-template>