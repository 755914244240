<nav
  class="fixed md:hidden bottom-0 left-0 z-[999] w-full bg-mat-ef-revolution-supergrey-500 px-7 py-2"
  [ngClass]="{
    'z-[999]': chatboxIsMinimized(),
    'z-[899]': !chatboxIsMinimized()
  }"
  [@slideDown]="showNavbar ? 'shown' : 'hidden'"
>
  <ul class="flex justify-between">
    <li
      class="cursor-pointer"
      (click)="
        sendGTMNavigationData(
          'customerPortal.customer-portal.summary.title',
          'White Box Link'
        )
      "
      [routerLink]="['/']"
    >
      <mat-icon
        class="w-9 h-9"
        svgIcon="home"
        [ngClass]="{
          'text-mat-ef-revolution-secondary-500': router.url === homepage,
          'text-mat-ef-revolution-primary-500': router.url !== homepage
        }"
      ></mat-icon>
    </li>
    <li
      class="cursor-pointer"
      (click)="sendGTMNavigationData('shared.my-vehicles', 'White Box Link')"
      *ngIf="vehicles?.length > 0"
      [routerLink]="['/vehicles', vehicles?.[0] ? vehicles[0].vin : '']"
    >
      <mat-icon
        class="w-9 h-9"
        svgIcon="drive_eta"
        [ngClass]="{
          'text-mat-ef-revolution-secondary-500':
            router.url.includes('/vehicles'),
          'text-mat-ef-revolution-primary-500':
            !router.url.includes('/vehicles')
        }"
      ></mat-icon>
    </li>
    <li
      class="cursor-pointer"
      (click)="sendGTMNavigationData('shared.my-vehicles', 'White Box Link')"
      *ngIf="vehicles?.length === 0"
      routerLink="/vehicles-not"
    >
      <mat-icon
        class="w-9 h-9"
        svgIcon="car_off"
        [ngClass]="{
          'text-mat-ef-revolution-secondary-500':
            router.url === '/vehicles-not',
          'text-mat-ef-revolution-primary-500': router.url !== '/vehicles-not'
        }"
      ></mat-icon>
    </li>
    <li
      class="cursor-pointer"
      (click)="
        sendGTMNavigationData(
          'customerPortal.customer-portal.top-bar.appointments',
          'White Box Link'
        )
      "
      [routerLink]="['/appointments']"
    >
      <mat-icon
        class="w-9 h-9"
        svgIcon="event"
        [ngClass]="{
        'text-mat-ef-revolution-secondary-500': router.url === '/appointments',
        'text-mat-ef-revolution-primary-500': router.url !== '/appointments',
      }"
      ></mat-icon>
    </li>
    <li
      class="cursor-pointer"
      (click)="
        sendGTMNavigationData(
          'customerPortal.customer-portal.my-profile',
          'White Box Link'
        )
      "
      [routerLink]="['/user', 'personal-data']"
    >
      <mat-icon
        class="w-9 h-9"
        svgIcon="account_circle"
        [ngClass]="{
          'text-mat-ef-revolution-secondary-500': router.url.includes('/user'),
          'text-mat-ef-revolution-primary-500': !router.url.includes('/user')
        }"
      ></mat-icon>
    </li>
  </ul>
</nav>
