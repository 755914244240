<div
  id="impressum"
  class="footerImpressum text-mat-ef-revolution-supergrey-500"
>
  <div class="p-6 md:p-0 grid grid-cols-1 md:grid-cols-4 gap-4 text-xxs">
    <!-- First impressum column -->
    <div class="col1">
      <span class="font-bold">
        {{
          {
            key: remoteConfigService.get(
              'revolution.links.footer2.column1.title'
            )
          } | transifexTranslate
        }}
      </span>
      <span>
        {{
          {
            key: remoteConfigService.get(
              'revolution.links.footer2.column1.notice'
            )
          } | transifexTranslate
        }}
      </span>
    </div>

    <!-- Second impressum column -->
    <div class="col2">
      <span class="font-bold">
        {{
          {
            key: remoteConfigService.get(
              'revolution.links.footer2.column2.title'
            )
          } | transifexTranslate
        }}
      </span>
      <span>
        {{
          {
            key: remoteConfigService.get(
              'revolution.links.footer2.column2.notice'
            )
          } | transifexTranslate
        }}
      </span>
    </div>

    <!-- Third impressum column -->
    <div class="col3">
      <span class="font-bold">
        {{
          {
            key: remoteConfigService.get(
              'revolution.links.footer2.column3.title'
            )
          } | transifexTranslate
        }}
      </span>
      <span>
        {{
          {
            key: remoteConfigService.get(
              'revolution.links.footer2.column3.notice'
            )
          } | transifexTranslate
        }}
      </span>
    </div>

    <!-- Forth impressum column -->
    <div class="col4">
      <span class="font-bold">
        {{
          {
            key: remoteConfigService.get(
              'revolution.links.footer2.column4.title'
            )
          } | transifexTranslate
        }}
      </span>
      <span>
        {{
          {
            key: remoteConfigService.get(
              'revolution.links.footer2.column4.notice'
            )
          } | transifexTranslate
        }}
      </span>
    </div>
  </div>
</div>
