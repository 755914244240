import { Component, Input } from '@angular/core'
import { BasicDocumentDTOExtended, VehicleDocumentsService } from '../../../services/vehicle-documents.service'
import { SharedService } from '../../../services/shared.service'
import { RevolutionVehicleDocumentDeleteConfirmationPopupComponent } from '../../revolution/vehicle-documents/vehicle-document-delete-confirmation-popup/vehicle-document-delete-confirmation-popup.component'
import { MatDialog } from '@angular/material/dialog'
import { DataService } from '../../../services/data.service'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'

@Component({
  selector: 'customer-portal-app-document-buttons',
  templateUrl: './document-buttons.component.html'
})
export class DocumentButtonsComponent {
  document: BasicDocumentDTOExtended
  @Input() set setDocument (document: BasicDocumentDTOExtended) {
    this.document = document
  }

  @Input() downloadStarted: boolean

  showSalesInvoiceDownloadButton: boolean
  showServiceInvoiceDownloadButton: boolean
  constructor (
    private readonly dialog: MatDialog,
    private readonly dataService: DataService,
    public readonly sharedService: SharedService,
    private readonly vehicleDocumentsService: VehicleDocumentsService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.showSalesInvoiceDownloadButton = this.remoteConfigService.get('documents.showSalesInvoiceDownloadButton')
    this.showServiceInvoiceDownloadButton = this.remoteConfigService.get('documents.showServiceInvoiceDownloadButton')
  }

  filterOnlyDocument (doc: BasicDocumentDTOExtended): boolean {
    return this.vehicleDocumentsService.filterOnlyDocument(doc)
  }

  filterOnlyDocumentSales (doc: BasicDocumentDTOExtended): boolean {
    return this.vehicleDocumentsService.filterOnlyDocumentSales(doc)
  }

  filterService (doc: BasicDocumentDTOExtended): boolean {
    return this.vehicleDocumentsService.filterService(doc)
  }

  getInvoicePDF (
    h: BasicDocumentDTOExtended,
    mode: 'download' | 'open',
    event?: Event
  ): void {
    this.vehicleDocumentsService.getInvoicePDF(
      h.id,
      h.invNumber,
      h.dateModified,
      mode,
      event
    )
  }

  downloadOpenSalesFile (
    file: BasicDocumentDTOExtended,
    mode: 'download' | 'open',
    ev?: Event
  ): void {
    this.downloadStarted = true
    if (ev != null) {
      this.sharedService.preventEventPropagation(ev)
    }

    if (file.preview == null) {
      if (
        file.type === 'OFFER' ||
        file.type === 'LEASING_CONTRACT' ||
        file.type === 'CONTRACT'
      ) {
        this.vehicleDocumentsService.getDocumentsPDF(
          file.id,
          file.invNumber,
          mode
        )
      } else {
        this.sharedService.showSnackbar(
          'customerPortal.customer-portal.please-wait-loading-data',
          null,
          0
        )
        this.vehicleDocumentsService
          .getDocumentSalesPDF(file.publicUrl)
          .subscribe(
            response => {
              this.sharedService.closeSnackbar()
              file.preview = URL.createObjectURL(response)
              this.downloadOpenFileInner(file, mode)
              this.downloadStarted = false
            },
            () => {
              this.sharedService.showSnackbar(
                'customerPortal.customer-portal.loading-data.error',
                null
              )
              this.downloadStarted = false
            }
          )
      }
    } else {
      this.downloadOpenFileInner(file, mode)
    }
  }

  downloadOpenFileInner (
    file: BasicDocumentDTOExtended,
    mode: 'download' | 'open'
  ): void {
    switch (mode) {
      case 'download':
        this.downloadFile(file)
        break
      case 'open':
        this.sharedService.openPreviewPopup(file.preview, 'pdf')
        this.downloadStarted = false
        break
    }
  }

  downloadFile (file: BasicDocumentDTOExtended): void {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = file.preview
    a.download =
      file?.number != null ? file.number.trim() + '.pdf' : file?.name?.trim()
    a.target = '_blank'
    a.click()
    this.downloadStarted = false
  }

  onDeleteDocumentClicked (doc: BasicDocumentDTOExtended): void {
    this.downloadStarted = true
    const dialogRef = this.dialog.open(
      RevolutionVehicleDocumentDeleteConfirmationPopupComponent,
      {
        data: {
          document: doc
        },
        panelClass: 'mat-dialog-cpt'
      }
    )
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== false) {
        void this.dataService.getMyDocuments()
      }
      this.downloadStarted = false
    })
  }

  showPDFButtons (h: BasicDocumentDTOExtended): boolean {
    return this.vehicleDocumentsService.showServicePDFButtons(h)
  }
}
