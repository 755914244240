<div
  class="font-efSans container p-3 min-h-[50px] w-full flex justify-between bg-mat-ef-revolution-primary-400 text-mat-ef-revolution-primary-50"
>
  <button class="flex-none" (click)="dismissBanner($event)">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
  <div class="grow text-center">
    <div class="text-sm font-bold">
      <T
        str="PWA install promo title"
        key="customerPortal.customer-portal.pwa.promo-banner-title"
        tags="customer-portal, 3.3"
      ></T>
    </div>
    <div class="text-sm">
      <T
        str="Install our app for better experience"
        key="customerPortal.customer-portal.pwa.promo-banner-text"
        tags="customer-portal, 3.3"
      ></T>
    </div>
  </div>
  <button
    class="px-3 flex-none rounded-lg bg-white text-mat-ef-revolution-primary-500"
    (click)="promptInstall()"
  >
    <T
      str="Install"
      key="customerPortal.customer-portal.pwa.promo-banner-install-button"
      tags="customer-portal, 3.3"
    ></T>
  </button>
</div>
