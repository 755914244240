import {
  Component,
  EventEmitter,
  Input,
  type OnInit,
  Output,
  ViewChild
} from '@angular/core'
import {
  CptGoogleTagmanagerService,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { NgxGlideComponent } from 'ngx-glide'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from '../../../../../services/shared.service'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import {
  AppointmentsService,
  type UpcomingAppointmentDTOExtended
} from '../../../../../services/appointments.service'

@Component({
  selector: 'customer-portal-app-appointments',
  templateUrl: './appointments.component.html'
})
export class AppointmentsComponent implements OnInit {
  hasLiveStatusAppointments = false

  @Input()
  set setUpcomingAppointments (
    upcomingAppointments: UpcomingAppointmentDTOExtended[]
  ) {
    this.upcomingAppointments = upcomingAppointments
    this.setTranslateKey()
    this.updateItems()
  }

  upcomingAppointments: UpcomingAppointmentDTOExtended[] = []

  @Input() loadingAppointments = true

  @Output() action = new EventEmitter()

  licensePlateMask

  @ViewChild('AppointmentsComponent_ngxGlide')
    AppointmentsComponent_ngxGlide!: NgxGlideComponent

  today = new Date()

  anyAppointmentHasLiveStatus = false

  constructor (
    public appointmentsService: AppointmentsService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly transifexTranslationsService: TranslationService,
    private readonly transifexService: TransifexService,
    public sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>,
    private readonly logger: NGXLogger
  ) {}

  upcomingAppointmentsKey
  ngOnInit (): void {
    this.licensePlateMask = this.sharedService.setLicensePlateMask()
    this.hasLiveStatusAppointments = this.remoteConfigService.get(
      'hasLiveStatusAppointments'
    )
    this.updateItems()
    this.anyAppointmentHasLiveStatus = this.checkIfAnyAppointmentHasLiveStatus(this.upcomingAppointments)
  }

  checkIfAnyAppointmentHasLiveStatus (appointments: UpcomingAppointmentDTOExtended[]): boolean {
    let hasLiveStatus = false
    appointments?.forEach((a) => {
      if (this.checkAppointment(a)) {
        hasLiveStatus = true
      }
    })
    return (hasLiveStatus)
  }

  updateItems (): void {
    setTimeout(() => {
      if (this.AppointmentsComponent_ngxGlide != null) {
        try {
          this.AppointmentsComponent_ngxGlide.recreate()
        } catch (error) {
          this.logger.log(error)
        }
      }
    })
  }

  startDate (date: string): Date {
    return new Date(date)
  }

  setTranslateKey (): void {
    const length =
      this.upcomingAppointments?.length != null
        ? this.upcomingAppointments.length
        : 0
    switch (length) {
      case 0:
        this.upcomingAppointmentsKey =
          'customerPortal.customer-portal.vehicle.appointments.0'
        break
      case 1:
        this.upcomingAppointmentsKey =
          'customerPortal.customer-portal.vehicle.appointments.1'
        break
      default:
        this.upcomingAppointmentsKey =
          'customerPortal.customer-portal.vehicle.appointments'
        break
    }
  }

  sendContentModulesCTAData (contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }

  checkAppointment (appointment: UpcomingAppointmentDTOExtended): boolean {
    return this.sharedService.checkAppointment(appointment)
  }

  sendContentModulesSliderData (
    side: string,
    contentModuleLabel: string,
    galleryName: string
  ): void {
    this.cptGtmService.sendContentModulesData(
      'Gallery',
      side,
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Gallery|(${galleryName})`,
      side
    )
  }
}
