import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core'
import { GoogleMap } from '@angular/google-maps'
import { Observable } from 'rxjs'
import {
  CptGoogleMapsService,
  Marker
} from '../../services/google-maps.service'

@Component({
  selector: 'customer-portal-agm-map',
  templateUrl: './agm-map.component.html',
  styleUrls: ['./agm-map.component.scss']
})
export class AgmMapComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() width: string
  @Input() height: string
  @Input() view: string
  @Input() latitude: number
  @Input() longitude: number
  @Input() zoom: number
  @Input() markDraggable: boolean
  @Input() zoomControl: boolean
  @Input() fitBounds: boolean
  @Input() usePanning: boolean
  @Input() markersSelected: Marker[] = []
  @Input() markers: Marker[] = []
  @Output() mapReady = new EventEmitter<GoogleMap>()
  @Output() markerClicked = new EventEmitter<Marker>()
  @ViewChild('mapMarker') mapMarker

  constructor(
    private readonly googleMapService: CptGoogleMapsService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  center: { lng: number; lat: number }
  options = {
    mapTypeId: 'roadmap',
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: false,
    scrollwheel: false,
    fitBounds: false,
    fullscreenControl: false,
    disableDoubleClickZoom: false,
    usePanning: false
  }

  apiLoaded: Observable<boolean>

  ngAfterViewInit(): void {
    this.apiLoaded = this.googleMapService.onMapsLoaded
    this.cdr.detectChanges()
  }

  ngOnInit(): void {
    if (this.latitude != null && this.longitude != null) {
      this.center = {
        lat: this.latitude,
        lng: this.longitude
      }

      if (this.view !== 'seller') {
        this.markers.push({
          position: {
            lat: this.center.lat,
            lng: this.center.lng
          },
          options: { draggable: this.markDraggable }
        })
      } else {
        this.options.zoomControl = this.zoomControl
        this.options.fitBounds = this.fitBounds
        this.options.usePanning = this.usePanning
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.view !== 'seller') {
      if (!changes?.latitude?.firstChange && !changes?.longitude?.firstChange) {
        this.center = {
          lat: changes.latitude.currentValue,
          lng: changes.longitude.currentValue
        }
        this.markers = []
        this.markers.push({
          position: {
            lat: changes.latitude.currentValue,
            lng: changes.longitude.currentValue
          },
          options: { draggable: this.markDraggable }
        })
      }
    } else {
      if (!changes?.markersSelected?.firstChange) {
        const lat =
          changes?.markersSelected?.currentValue?.[0]?.dealer?.latitude
        const lng =
          changes?.markersSelected?.currentValue?.[0]?.dealer?.longitude

        if (lat != null && lng != null) {
          this.center = {
            lat,
            lng
          }
        }
      }
    }
  }

  onMapReady(event): void {
    this.mapReady.emit(event)
  }

  onMarkerClicked(event): void {
    this.markerClicked.emit(event)
  }
}
