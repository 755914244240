import {
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  type OnInit,
  Output
} from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from '../../../services/shared.service'
import {
  BasicDocumentDTOExtended,
  type ContactDocumentFormatEnum,
  VehicleDocumentsService,
  VehiclePromotionDocumentDTOExtended
} from '../../../services/vehicle-documents.service'
import { SharedService as CPTSharedService } from '@inside-hub-app/customer-portal-shared'

@Component({
  selector: 'customer-portal-app-document-preview',
  templateUrl: './document-preview.component.html'
})
export class DocumentPreviewComponent implements OnInit, OnDestroy {
  @Input()
    promotion: VehiclePromotionDocumentDTOExtended

  @Input()
    documentSales: BasicDocumentDTOExtended

  @Input()
    showPreviewButton = true

  @Output() closePreview = new EventEmitter()

  preview: VehiclePromotionDocumentDTOExtended | BasicDocumentDTOExtended
  hideSpinner = false
  image
  showPreview = true
  sub = {
    onFilePreviewOpened: null
  }

  constructor (
    private readonly vehicleDocumentsService: VehicleDocumentsService,
    private readonly logger: NGXLogger,
    private readonly sharedService: SharedService,
    private readonly cptSharedService: CPTSharedService,
    private readonly sanitizer: DomSanitizer
  ) {}

  ngOnInit (): void {
    if (this.promotion != null) {
      this.preview = Object.assign({}, this.promotion)
      this.preview.preview = this.promotion.url
      this.preview.format = 'pdf'
      this.hideLoadingSpinner()
    }
    const sales = this.documentSales

    if (sales != null) {
      if (!this.sharedService.stringExists(sales.preview)) {
        if (
          sales.type === 'OFFER' ||
          sales.type === 'LEASING_CONTRACT' ||
          sales.type === 'CONTRACT'
        ) {
          this.vehicleDocumentsService
            .getDocumentsPDF_request(sales.id)
            .subscribe(
              response => {
                sales.preview = URL.createObjectURL(response)
                this.preview = Object.assign({}, sales)
                this.image = this.sanitizer.bypassSecurityTrustUrl(
                  this.preview.preview
                )
                this.hideLoadingSpinner()
              },
              error => {
                this.handleError(error)
              }
            )
        } else {
          this.vehicleDocumentsService
            .getDocumentSalesPDF(sales.publicUrl)
            .subscribe(
              response => {
                sales.format = response?.type.split(
                  '/'
                )[1] as ContactDocumentFormatEnum
                sales.preview = URL.createObjectURL(response)
                this.preview = Object.assign({}, sales)
                this.image = this.sanitizer.bypassSecurityTrustUrl(
                  this.preview.preview
                )
                this.hideLoadingSpinner()
              },
              error => {
                this.handleError(error)
              }
            )
        }
      } else {
        this.preview = Object.assign({}, sales)
        this.image = this.sanitizer.bypassSecurityTrustUrl(this.preview.preview)
        this.hideLoadingSpinner()
      }
    }

    // hide small preview when the preview popup is opened
    // can only have 1 instance of ngx-extended-pdf-viewer or the preview doesnt work
    this.sub.onFilePreviewOpened =
      this.cptSharedService.onFilePreviewOpened.subscribe(
        (popupFilePreviewOpened: boolean) => {
          this.showPreview = !popupFilePreviewOpened
        }
      )
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  PDF_openNewTab (
    d: VehiclePromotionDocumentDTOExtended | BasicDocumentDTOExtended
  ): void {
    window.open(d.preview, '_blank')
  }

  PDF_openPreviewPopup (
    d: VehiclePromotionDocumentDTOExtended | BasicDocumentDTOExtended
  ): void {
    this.sharedService.openPreviewPopup(d.preview, 'pdf')
  }

  hideLoadingSpinner (): void {
    setTimeout(() => {
      this.hideSpinner = true
    }, 1000)
  }

  handleError (error): void {
    this.logger.debug(error)
    this.hideLoadingSpinner()
    this.showErrorNotification()
    this.closePreview.emit()
  }

  showErrorNotification (): void {
    this.sharedService.showSnackbar('shared.general-error-message', null)
  }
}
