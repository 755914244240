import {
  Component,
  type OnDestroy,
  type OnInit,
  Renderer2
} from '@angular/core'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { type hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { MatDialog, type MatDialogRef } from '@angular/material/dialog'
import { type MatSlideToggle } from '@angular/material/slide-toggle'
import { MatSnackBar } from '@angular/material/snack-bar'
import { NGXLogger } from 'ngx-logger'
import { Subscription, forkJoin } from 'rxjs'
import {
  type NewsletterDTO,
  NotificationsService,
  type PreferenceDTO
} from '../../../services/notifications.service'
import { SharedService } from '../../../services/shared.service'
import { ConfirmationQuestionPopupComponent } from '../../basic/confirmation-question-popup/confirmation-question-popup.component'
import { MatomoIntegrationService, PwaService } from '@inside-hub-app/customer-portal-shared'
import {
  type User,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import { DataService } from '../../../services/data.service'

export type PreferenceType =
  cpt.emilfreydigital.customer_portal_backend.rest.preferences.dto.PreferenceType
export interface UserNotifications {
  priceChange: boolean
  searchResultsChange: boolean
  appointmentChange?: boolean
  newDocumentChange?: boolean
  leasingChange?: boolean
  warrantyChange?: boolean
}

// https://emilfreygroup.atlassian.net/browse/CPEFG-1408
// https://emilfreygroup.atlassian.net/browse/CPEFG-1628

@Component({
  selector: 'customer-portal-app-user-notifications',
  templateUrl: './user-notifications.component.html'
})
export class UserNotificationsComponent implements OnInit, OnDestroy {
  loading = true
  hasMarketPlace
  hasNewsletter

  userNotificationsEmail: UserNotifications = {
    priceChange: false,
    searchResultsChange: false
  }

  userNotificationsPush: UserNotifications = {
    priceChange: false,
    searchResultsChange: false,
    appointmentChange: false,
    newDocumentChange: false,
    leasingChange: false,
    warrantyChange: false
  }

  // if backend scope name is different
  alias = {
    WATCHLIST_CHANGE: 'priceChange',
    SEARCH_RESULTS_CHANGE: 'searchResultsChange',
    UPCOMING_APPOINTMENT: 'appointmentChange',
    DOCUMENT_REMINDER: 'newDocumentChange',
    WARRANTY_REMINDER: 'warrantyChange',
    LOAN_REMINDER: 'leasingChange'
  }

  newsletterData: NewsletterDTO

  public country
  public hasMatomo
  public isIosNative = false // dont show matomo if its ios

  trackingConsent
  user: User
  dateFormat: string

  private readonly subscriptions = new Subscription()
  constructor (
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly renderer: Renderer2,
    private readonly logger: NGXLogger,
    private readonly notificationsService: NotificationsService,
    private readonly sharedService: SharedService,
    private readonly matomoIntegrationService: MatomoIntegrationService,
    private readonly data: DataService,
    private readonly userService: UserService,
    private readonly pwaService: PwaService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.dateFormat = this.remoteConfigService.get('dateFormat.long')
    this.hasMarketPlace = this.remoteConfigService.get('hasMarketPlace')
    this.hasNewsletter = this.remoteConfigService.get('hasNewsletter')
    this.country = this.remoteConfigService.get('country.code')
    this.hasMatomo = this.remoteConfigService.get('matomo.enabled')
  }

  ngOnInit (): void {
    const onUserLoaded = this.data.onUserLoaded.subscribe(user => {
      this.user = user
      this.setValues()
      this.load()
    })
    this.subscriptions.add(onUserLoaded)

    this.isIosNative = this.pwaService.checkIosNative()
  }

  ngOnDestroy (): void {
    this.subscriptions.unsubscribe()
  }

  showMatomoSection (): boolean {
    return this.hasMatomo && this.isIosNative !== true
  }

  load (): void {
    this.loading = true
    forkJoin([
      this.getNotifications(),
      this.getNewsletter(),
      this.getMatomoConsent()
    ]).subscribe((value: [PreferenceDTO[], NewsletterDTO, any]) => {
      // UserPreferenceDTO
      this.loading = false
      if (value[0] != null) {
        this.setValues(value[0])
      }
      this.newsletterData = value[1] ?? { subscribed: false }

      const preferences = value[2] ?? []
      this.trackingConsent =
        preferences.find(el => {
          return el.name === 'trackingConsent'
        })?.value === 'true'
    })
  }

  setValues (prefrences?: PreferenceDTO[]): void {
    for (const prefrence in this.userNotificationsEmail) {
      this.userNotificationsEmail[prefrence] = false
    }
    for (const prefrence in this.userNotificationsPush) {
      this.userNotificationsPush[prefrence] = false
    }
    if (prefrences != null) {
      prefrences.forEach(prefrence => {
        const key: string =
          this.alias[prefrence.scope] != null
            ? this.alias[prefrence.scope]
            : prefrence
        if (prefrence.type === 'email') {
          this.userNotificationsEmail[key] = prefrence.enabled
        } else {
          this.userNotificationsPush[key] = prefrence.enabled
        }
      })
    }
  }

  notificationsToggleClicked (
    ref: MatSlideToggle,
    property: string,
    type?: PreferenceType
  ): void {
    let currentValue
    switch (property) {
      case 'newsletter':
        currentValue = this.newsletterData.subscribed
        break
      default:
        if (type === 'email') {
          currentValue = this.userNotificationsEmail[property]
        } else {
          currentValue = this.userNotificationsPush[property]
        }
        break
    }
    // only show confirmation when we turn off notification https://emilfreygroup.atlassian.net/browse/CPEFG-3083
    if (currentValue === true) {
      // reset to the previous value so that the user could not see
      // that the mat-slide-toggle has really changed
      this.renderer.setProperty(ref, 'checked', currentValue)
      const dialogRef = this.dialog.open(ConfirmationQuestionPopupComponent, {
        data: {
          title:
            'customerPortal.customer-portal.userNotifications.disable_title',
          text: 'customerPortal.customer-portal.userNotifications.disable_text',
          cancel: 'shared.cancel',
          save: 'shared.yes',
          preventCloseBeforeComplete: true
        },
        panelClass: 'mat-dialog-cpt'
      })
      const sub = dialogRef.componentInstance.confirm.subscribe(() => {
        this.notificationsToggleClickedConfirmed(property, type, dialogRef)
      })
      dialogRef.afterClosed().subscribe(() => {
        sub.unsubscribe()
      })
    } else {
      this.notificationsToggleClickedConfirmed(property, type)
    }
  }

  notificationsToggleClickedConfirmed (property, type, dialogRef?): void {
    this.matomoIntegrationService.trackEvent(
      'My account',
      'Notification settings change',
      property + ' ' + type
    )
    switch (property) {
      case 'newsletter':
        this.newsletterData.subscribed = !this.newsletterData.subscribed
        if (this.newsletterData.subscribed) {
          this.saveNewsletter(property, dialogRef)
        } else {
          this.deleteNewsletter(property, dialogRef)
        }
        break
      default:
        if (type === 'email') {
          this.userNotificationsEmail[property] =
            this.sharedService.switchBoolean(
              this.userNotificationsEmail[property]
            )
        } else {
          this.userNotificationsPush[property] =
            this.sharedService.switchBoolean(
              this.userNotificationsPush[property]
            )
        }
        this.saveNotification(property, type, dialogRef)
        break
    }
  }

  notificationsToggleChanged (property: string, type?: string): void {
    let translationKey
    switch (property) {
      case 'newsletter':
        translationKey =
          'customerPortal.customer-portal.userNotifications' +
          (this.newsletterData.subscribed ? '.activated' : '.disabled')
        break
      default:
        let value
        if (type === 'email') {
          value = this.userNotificationsEmail[property]
        } else {
          value = this.userNotificationsPush[property]
        }
        translationKey =
          'customerPortal.customer-portal.userNotifications' +
          (value === true ? '.activated' : '.disabled')
        break
    }
    this.sharedService.showSnackbar(translationKey, null)
  }

  saveNotification (
    property: string,
    type: string,
    dialogRef?: MatDialogRef<ConfirmationQuestionPopupComponent>
  ): void {
    this.loading = true
    const params = []
    let data
    if (type === 'email') {
      data = this.userNotificationsEmail
    } else {
      data = this.userNotificationsPush
    }
    for (const prefrence in data) {
      let scope = prefrence
      // if backend scope name is different
      for (const prop in this.alias) {
        if (this.alias[prop] === prefrence) {
          scope = prop
        }
      }
      params.push({
        scope,
        enabled: data[prefrence],
        type
      })
    }
    this.notificationsService.putNotifications(params).subscribe(
      () => {
        this.successCallback(property, type, dialogRef)
      },
      error => {
        this.errorCallback(property, error, type, dialogRef)
      }
    )
  }

  saveNewsletter (
    property: string,
    dialogRef?: MatDialogRef<ConfirmationQuestionPopupComponent>
  ): void {
    this.loading = true
    const lang = this.sharedService.currentLanguage()
    this.notificationsService.postNewsletter(lang).subscribe(
      () => {
        this.successCallback(property, null, dialogRef)
      },
      error => {
        this.errorCallback(property, error, null, dialogRef)
      }
    )
  }

  deleteNewsletter (
    property: string,
    dialogRef?: MatDialogRef<ConfirmationQuestionPopupComponent>
  ): void {
    this.loading = true
    this.notificationsService.deleteNewsletter().subscribe(
      () => {
        this.successCallback(property, null, dialogRef)
      },
      error => {
        this.errorCallback(property, error, null, dialogRef)
      }
    )
  }

  successCallback (
    property: string,
    type?: string,
    dialogRef?: MatDialogRef<ConfirmationQuestionPopupComponent>
  ): void {
    dialogRef?.close()
    this.notificationsToggleChanged(property, type)
    this.load()
  }

  errorCallback (
    property: string,
    error,
    type?: string,
    dialogRef?: MatDialogRef<ConfirmationQuestionPopupComponent>
  ): void {
    switch (property) {
      case 'newsletter':
        this.newsletterData.subscribed = !this.newsletterData.subscribed
        break
      default:
        if (type === 'email') {
          this.userNotificationsEmail[property] =
            this.sharedService.switchBoolean(
              this.userNotificationsEmail[property]
            )
        } else {
          this.userNotificationsPush[property] =
            this.sharedService.switchBoolean(
              this.userNotificationsPush[property]
            )
        }
        break
    }
    dialogRef?.close()
    this.logger.error(error)
    this.sharedService.openConfirmationPopup(
      'customerPortal.customer-portal.userNotifications.save.failure_title',
      'customerPortal.customer-portal.userNotifications.save.failure_text'
    )
    this.load()
  }

  matomoConsentChanged (ref: MatSlideToggle): void {
    const currentValue = this.trackingConsent
    const preference = {
      name: 'trackingConsent',
      value: currentValue === true ? 'false' : 'true' // has to be string
    }
    // only show confirmation when we turn off notification https://emilfreygroup.atlassian.net/browse/CPEFG-3083
    if (currentValue === true) {
      // reset to the previous value so that the user could not see
      // that the mat-slide-toggle has really changed
      this.renderer.setProperty(ref, 'checked', currentValue)
      const dialogRef = this.dialog.open(ConfirmationQuestionPopupComponent, {
        data: {
          title:
            'customerPortal.customer-portal.userNotifications.analytics.disable_title',
          text: 'customerPortal.customer-portal.userNotifications.analytics.disable_text',
          cancel: 'shared.cancel',
          save: 'shared.yes',
          preventCloseBeforeComplete: true
        },
        panelClass: 'mat-dialog-cpt'
      })
      const sub = dialogRef.componentInstance.confirm.subscribe(() => {
        this.changeUserPreference(preference, ref, dialogRef)
      })
      dialogRef.afterClosed().subscribe(() => {
        sub.unsubscribe()
      })
    } else {
      this.changeUserPreference(preference, ref)
    }
  }

  // only matomo atm - will have to change if more are added
  changeUserPreference (preference, ref: MatSlideToggle, dialogRef?): void {
    this.userService.changeUserPreference(preference).subscribe({
      next: res => {
        // matomo
        if (preference.value === 'true') {
          if (this.matomoIntegrationService.matomoIsInitialized) {
            this.matomoIntegrationService.startTracking(this.user.contactId)
          } else {
            this.matomoIntegrationService.initialize(this.user.contactId)
          }
        } else {
          this.matomoIntegrationService.stopTracking()
        }
        // update variable
        this.trackingConsent = this.sharedService.switchBoolean(
          this.trackingConsent
        )
        dialogRef?.close()
        const translationKey =
          'customerPortal.customer-portal.userNotifications.analytics' +
          (this.trackingConsent === true ? '.activated' : '.disabled')
        this.sharedService.showSnackbar(translationKey, null)
      },
      error: error => {
        this.logger.debug(error)
        // reset mat-slide-toggle
        this.renderer.setProperty(ref, 'checked', this.trackingConsent)
        dialogRef?.close()
        this.sharedService.openConfirmationPopup(
          'shared.error',
          'shared.general-error-message'
        )
      }
    })
  }

  private async getNotifications (): Promise<unknown> {
    return await new Promise(resolve => {
      this.notificationsService.getNotificationValues().subscribe(
        value => {
          resolve(value)
        },
        error => {
          this.logger.error(error)
          resolve(null)
        }
      )
    })
  }

  private async getNewsletter (): Promise<unknown> {
    return await new Promise(resolve => {
      if (this.hasNewsletter) {
        this.notificationsService.getNewsletter().subscribe(
          value => {
            resolve(value)
          },
          error => {
            this.logger.error(error)
            resolve(null)
          }
        )
      } else {
        resolve(null)
      }
    })
  }

  private async getMatomoConsent (): Promise<unknown> {
    return await new Promise(resolve => {
      if (this.hasMatomo) {
        this.userService.getUserPreferences().subscribe(
          value => {
            resolve(value)
          },
          error => {
            this.logger.error(error)
            resolve(null)
          }
        )
      } else {
        resolve(null)
      }
    })
  }
}
