import { ChangeDetectorRef, Component, type OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  CptGoogleTagmanagerService,
  EcondaService,
  ResizeService, type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'
import {
  type CustomerPortalConfig,
  type Navigation
} from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import {
  CarmatoService,
  type CarmatoWatchlistDTOExtended
} from '../../../../services/carmato.service'
import { DataService } from '../../../../services/data.service'
import { SharedService } from '../../../../services/shared.service'
import { type VehicleDTOExtended } from '../../../../services/vehicles.service'

@Component({
  selector: 'customer-portal-app-revolution-my-account-navigation',
  templateUrl: './my-account-navigation.component.html'
})
export class RevolutionMyVehicleNavigationComponent implements OnInit {
  navLinks
  navigationItems

  currentParent
  hideNavigation
  vin: string
  favorites: CarmatoWatchlistDTOExtended[] = []

  public screenSize: SCREEN_SIZE
  sub = {
    onVehicleVinChange: null,
    activatedRoute: null,
    activatedRouteURL: null,
    onUserVehiclesLoaded: null,
    onResize: null,
    onWatchListLoaded: null
  }

  constructor (
    public dataService: DataService,
    private readonly activatedRoute: ActivatedRoute,
    public router: Router,
    private readonly sharedService: SharedService,
    public resizeService: ResizeService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly carmatoService: CarmatoService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly econdaService: EcondaService,
    private readonly logger: NGXLogger,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.screenSize = this.resizeService.screenSize
    this.navigationItems =
    this.remoteConfigService.getArrayValues<Navigation[]>('navigationAccount')
  }

  setPath (): void {
    this.navigationItems.forEach((item: Navigation) => {
      if (item != null) {
        item.pathWithParams = this.sharedService.getRoute(item, this.vin)
      }
    })

    // set findCurrentRoute if not exists
    if (this.currentParent == null) {
      this.findCurrentRoute()
    }
  }

  findCurrentRoute (): void {
    // iterate thru navigation and find path that matches current route
    this.navigationItems.forEach((item: Navigation) => {
      if (item.pathWithParams === this.router.url) {
        this.setCurrentRoute(item)
      }
    })
  }

  setCurrentRoute (n: Navigation): void {
    this.currentParent = n
    this.cdRef.detectChanges()
  }

  sendContentModulesTabSwitchData (contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Tab Switch',
      null,
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'Tab Switch',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
    this.econdaService.send({
      content: 'My account',
      Target: [
        'My account tab switched',
        this.sharedService.translateLink(contentModuleLabel),
        1,
        'd'
      ]
    })
  }

  ngOnInit (): void {
    this.setPath()

    this.sub.onVehicleVinChange = this.dataService.onVehicleVinChange.subscribe(
      vin => {
        this.vin = vin
        this.setPath()
      }
    )
    this.sub.activatedRoute = this.activatedRoute.params.subscribe(params => {
      if (params.vin != null) {
        this.vin = params.vin
        this.dataService.vehicleVinChanged(params.vin)
      }
    })
    this.sub.activatedRouteURL = this.activatedRoute.url.subscribe(() => {
      // expand children if url changes
      this.findCurrentRoute()
    })
    this.sub.onUserVehiclesLoaded =
      this.dataService.onUserVehiclesLoaded.subscribe(
        (allVehicles: VehicleDTOExtended[]) => {
          if (allVehicles.length > 0) {
            if (this.vin == null) {
              this.vin = allVehicles[0].vin
              this.setPath()
            }
          }
        }
      )
    this.sub.onResize = this.resizeService.onResize.subscribe(size => {
      this.screenSize = size
    })
    this.sub.onWatchListLoaded =
      this.carmatoService.onWatchListLoaded.subscribe(favorites => {
        this.favorites = favorites
      })
  }
}
