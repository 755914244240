import { Injectable } from '@angular/core'
import { NGXLogger } from 'ngx-logger'

declare global {
  interface Window {
    webkit?: any
  }
}

interface iosNavigator extends Navigator {
  standalone: boolean
  fullscreen: boolean
  webkit?: any
}

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  public isPwa = false
  public isIos = false
  public isMobile = false
  public isPwaInstalled = false
  public installprompt: any = {}

  constructor(private readonly logger: NGXLogger) {}

  checkIos(): boolean {
    this.isIos =
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    this.logger.info('Is this ios? ' + this.isIos)
    return this.isIos
  }

  checkIosNative(): boolean {
    const webKit = Boolean(window.webkit as iosNavigator)
    // We added custom user-agent, 'CPTiOSApp' to native app
    const cptApp = Boolean(/CPTiOSApp/i.test(window.navigator.userAgent))
    return webKit && cptApp
  }

  checkPwa(): boolean {
    this.isPwa = false
    // For iOS
    const nav = window.navigator as iosNavigator
    if (nav.standalone || nav.fullscreen) {
      this.isPwa = true
    }

    // For Android - replace standalone if you change in manifest
    // eslint-disable-next-line @typescript-eslint/dot-notation, @typescript-eslint/strict-boolean-expressions
    if (
      window.matchMedia('(display-mode: fullscreen)').matches ||
      window.navigator['fullscreen'] ||
      window.matchMedia('(display-mode: standalone)').matches ||
      window.navigator['standalone'] ||
      document.referrer.includes('android-app://')
    ) {
      this.isPwa = true
    }

    this.logger.info('Is this pwa? ' + this.isPwa)
    return this.isPwa
  }

  checkMobile(): boolean {
    this.isMobile = window.matchMedia('(any-pointer:coarse)').matches
    return this.isMobile
  }

  checkPwaInstalled(): boolean {
    this.isPwaInstalled = Boolean(localStorage.getItem('pwaInstalled'))
    this.logger.info('Is pwa installed? ' + this.isPwaInstalled)
    return this.isPwaInstalled
  }

  setInstallPrompt(e: any): void {
    this.installprompt = e
  }
}
