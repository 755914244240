import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
export type UpcomingAppointmentsCountDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.appointment.dto.UpcomingAppointmentsCountDTO
export type RecallDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.recalls.dto.RecallDTO

@Injectable({
  providedIn: 'root'
})
export class RecallsService {
  private readonly apiUrl

  constructor(
    private readonly http: HttpClient,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  getRecallsForVehicle(vin: string, language: string): Observable<RecallDTO> {
    let params = new HttpParams()
    params = params.append('lang', language)
    return this.http.get<RecallDTO>(this.apiUrl + '/recalls/' + vin, {
      params
    })
  }
}
