import { Component } from '@angular/core'
import { NavigationService } from '../../../services/navigation.service'

@Component({
  selector: 'customer-portal-app-navbar-back',
  templateUrl: './navbar-back.component.html'
})
export class NavbarBackComponent {
  backClicked = false
  constructor (
    public readonly navigationService: NavigationService
  ) {}

  backIsDisabled (): boolean {
    return !this.navigationService.hasBack() || this.backClicked === true
  }

  onClickB (): void {
    if (!this.backClicked) {
      this.backClicked = true
      setTimeout(() => {
        this.backClicked = false
      }, 200)
      this.navigationService.back()
    }
  }
}
