<h2 class="appointment-form-title">
  <T
    str="Summary"
    key="customerPortal.customer-portal.appointments.summary"
    tags="customer-portal, 3.1"
  ></T>
</h2>
<form
  *ngIf="appointmentsPopupService.car && appointmentsPopupService.chosenDealer"
  class="summary appointment-form"
>
  <p class="font-efSans-primary font-bold uppercase">
    <T
      str="Vehicle"
      key="customerPortal.customer-portal.appointments.vehicle"
      tags="customer-portal, 3.1"
    ></T>
  </p>
  <h2
    class="text-mat-ef-revolution-secondary-500 font-efSans text-3xl mb-4 font-extrabold"
  >
    {{
      appointmentsPopupService.car.brand
        ? appointmentsPopupService.car.brand
        : ''
    }}
    {{
      appointmentsPopupService.car.model
        ? appointmentsPopupService.car.model
        : ''
    }}
    ({{
      appointmentsPopupService.currentMileage
        ? appointmentsPopupService.currentMileage
        : appointmentsPopupService.car.currentMileage
    }}
    km)
  </h2>
  <div class="mb-2.5">
    <p class="font-efSans-primary font-bold uppercase">
      <T
        str="Location"
        key="customerPortal.customer-portal.appointments.location"
        tags="customer-portal, 3.1"
      ></T>
    </p>
    <p class="mb-4 font-efSans-primary font-light">
      {{
        appointmentsPopupService.chosenDealer.name &&
        appointmentsPopupService.chosenDealer.city
          ? appointmentsPopupService.chosenDealer.name +
            ', ' +
            appointmentsPopupService.chosenDealer.city +
            ', ' +
            appointmentsPopupService.chosenDealer.street
          : appointmentsPopupService.chosenDealer.name || ''
      }}
    </p>
  </div>
  <div class="mb-2.5">
    <p class="font-efSans-primary font-bold uppercase">
      <T
        str="Preferred date and time"
        key="customerPortal.customer-portal.appointments.preferred-date-time"
        tags="customer-portal, 3.1"
      ></T>
    </p>
    <div class="font-efSans-primary font-light">
      <div class="flex">
        {{ appointmentsPopupService.date | date: 'mediumDate':'':dateLocale }} •
        <ng-container
          *ngTemplateOutlet="
            sharedService.template('transifexTranslation');
            context: { key: appointmentsPopupService.time }
          "
        ></ng-container>
      </div>
    </div>
  </div>
  <div
    class="mb-2.5"
    *ngIf="appointmentsPopupService.selectedServices.length !== 0"
  >
    <p class="font-efSans-primary font-bold uppercase">
      <T
        str="Services"
        key="customerPortal.customer-portal.appointments.services"
        tags="customer-portal, 3.1"
      ></T>
    </p>
    <div class="font-efSans-primary font-light">
      <div
        class="flex"
        *ngFor="let service of appointmentsPopupService.selectedServices"
      >
        {{
          {
            str: service.toLowerCase(),
            prefix: 'customerPortal.customer-portal.appointments.services.'
          } | transifexTranslate
        }}
      </div>
    </div>
    <div
      class="font-efSans-primary font-bold uppercase mt-5"
      *ngIf="appointmentsPopupService.replacement"
    >
      <T
        str="Replacement car."
        key="customerPortal.customer-portal.appointments.replacement-needed"
        tags="customer-portal, 3.1"
      ></T>
    </div>
    <div
      class="font-efSans-primary font-bold uppercase mt-5"
      *ngIf="!appointmentsPopupService.replacement"
    >
      <T
        str="No replacement car."
        key="customerPortal.customer-portal.appointments.no-replacement"
        tags="customer-portal, 3.1"
      ></T>
    </div>
  </div>
  <div class="mb-2.5" *ngIf="appointmentsPopupService.notes">
    <p class="font-efSans-primary font-bold uppercase">
      <T
        str="Additional"
        key="customerPortal.customer-portal.appointments.additional"
        tags="customer-portal, 3.1"
      ></T>
    </p>
    <div class="font-efSans-primary font-light">
      <div class="flex word-break_break-word">
        {{ appointmentsPopupService.notes }}
      </div>
    </div>
  </div>
  <div class="flex flex-col gap-[10px] md:hidden">
    <mat-divider class="my-4"></mat-divider>
    <button
      class="back revolution w-full md:w-auto"
      mat-button
      mat-stroked-button
      color="secondary"
      matStepperPrevious
    >
      {{ 'back' | sharedTranslate }}
    </button>
    <button
      class="next revolution w-full md:w-auto"
      mat-flat-button
      color="secondary"
      mat-button
      [disabled]="appointmentsPopupService.disabledSave"
      (click)="appointmentsPopupService.createAppointment(dialogRef)"
    >
      <mat-spinner
        class="inline-block mr-2.5"
        [diameter]="16"
        *ngIf="appointmentsPopupService.disabledSave"
      ></mat-spinner
      ><span>{{ 'save' | sharedTranslate }}</span>
    </button>
  </div>
</form>
