import { Component, Input } from '@angular/core'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { SharedService } from '../../../services/shared.service'
import { BasicDocumentDTOExtended } from '../../../services/vehicle-documents.service'
@Component({
  selector: 'customer-portal-app-offer-contract-info',
  templateUrl: './offer-contract-info.component.html'
})
export class OfferContractInfoComponent {
  showPreview = false

  document: BasicDocumentDTOExtended
  @Input() set setDocument (document: BasicDocumentDTOExtended) {
    this.document = document
    if (document != null) {
      if (this.document.contractType === 'invoice') {
        this.showPreview = false
      } else {
        this.showPreview = true
      }
      // theres a bug in currency pipe if we have negative number
      // https://github.com/angular/angular/issues/46038
      if (
        document.price != null &&
        document.currencyIso != null &&
        document.price < 0
      ) {
        document.currencyIso = document.currencyIso + ' '
      }
    }
  }

  currencySymbol: string
  currency: string
  currencyLocale: string
  dateFormat: string
  hasNewFooter
  units

  constructor (
    public sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.currencySymbol= this.remoteConfigService.get('currencySymbol')
    this.currency = this.remoteConfigService.get('currency')
    this.currencyLocale = this.remoteConfigService.get('currencyLocale')
    this.hasNewFooter = this.remoteConfigService.get('hasNewFooter')
    this.dateFormat = this.remoteConfigService.get('dateFormat.long')

    this.units = {
      currencySymbol: this.currencySymbol,
      currency: this.currency,
      currencyLocale: this.currencyLocale
    }
  }

  hasServices (document: BasicDocumentDTOExtended): boolean {
    return document?.services?.length > 0
  }
}
