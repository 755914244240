<h2 class="appointment-form-title">
  <T
    str="location and date"
    key="customerPortal.customer-portal.appointments.location-and-time"
    tags="customer-portal, 3.1"
  ></T>
</h2>
<form
  [formGroup]="appointmentsPopupService.thirdFormGroup"
  class="appointment-form"
>

  <div
    *ngIf="
      appointmentsPopupService.showDealerSection &&
      !appointmentsPopupService.loading
    "
  >
    <div class="flex-1 flex flex-col md:gap-6 md:flex-row md:justify-between">
      <div
        class="flex-1 text-base text-mat-ef-revolution-primary-500 font-bold font-efSans"
      >
        {{ 'service' | sharedTranslate }}
        -
        <ng-container
          *ngIf="checkbox_carService?.checked; else officialDealersText"
        >
          <ng-container *ngTemplateOutlet="allDealersText"> </ng-container>
        </ng-container>
      </div>
      <div class="mt-2">
        <mat-checkbox
          class="h-6 w-full text-sm"
          color="primary"
          #checkbox_carService
          (change)="addSelectedDealerToOfficialDealers()"
        >
          <T
            str="Show all dealers"
            key="customerPortal.customer-portal.change-dealer-seller.show-all-dealers"
            tags="customer-portal, 3.1"
          ></T>
        </mat-checkbox>
      </div>
    </div>
    <!-- header -->
    <div
      class="py-0 flex-1 flex flex-col md:gap-6 md:flex-row md:justify-between"
    >
      <mat-form-field
        *ngIf="hasDealers(checkbox_carService?.checked)"
        class="w-full relative"
        [ngClass]="{
          'md:w-3/4': appointmentsPopupService.showDealerChange
        }"
        cdkScrollable
      >
        <div *ngIf="isMobile===true" class="absolute top-0 right-0 bottom-0 left-0 cursor-pointer"
        (click)="openSelectOptionsPopup($event,checkbox_carService)">
      </div>
        <mat-label
          ><T
            str="Location"
            key="customerPortal.customer-portal.appointments.location"
            tags="customer-portal, 3.1"
          ></T
        ></mat-label>
        <input
          data-cy="add-new-appointment-dealer-select"
          required
          formControlName="carDealer"
          name="carDealer"
          type="text"
          aria-label="Number"
          matInput
          [matAutocomplete]="auto"
          
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          
          [displayWith]="sharedService.formatDealerName"
        >
          <mat-option
            *ngIf="appointmentsPopupService.dealer"
            [value]="appointmentsPopupService.dealer"
          >
            <ng-container *ngTemplateOutlet="preferred"></ng-container>
          </mat-option>
          <ng-container
            *ngFor="let d of getDealersForDropdown(checkbox_carService.checked)"
          >
            <ng-container *ngIf="d?.openingHours?.service?.length > 0">
              <mat-option [value]="d">
                {{ sharedService.formatDealerName(d) }}
              </mat-option>
            </ng-container>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <div
        class="flex items-center content-center"
        *ngIf="
          !hasDealers(checkbox_carService?.checked) &&
          !appointmentsPopupService.loadingDealersAndBrands
        "
      >
        <ng-container *ngIf="!checkbox_carService?.checked">
          <T
            str="This car brand has no dealers."
            key="customerPortal.customer-portal.appointments.no-dealers"
            tags="customer-portal, 3.1"
          ></T>
        </ng-container>
        <ng-container *ngIf="checkbox_carService?.checked"> - </ng-container>
      </div>
      <div
        class="flex items-center content-center"
        *ngIf="
          !hasDealers(checkbox_carService?.checked) &&
          appointmentsPopupService.loadingDealersAndBrands
        "
      >
        <mat-spinner class="inline-block mr-2.5" [diameter]="16"></mat-spinner>
        <T
          str="Loading data"
          key="customerPortal.customer-portal.loading-data"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <button
        *ngIf="appointmentsPopupService.showDealerChange"
        class="button-cancel revolution w-full md:w-1/4"
        mat-button
        mat-stroked-button
        color="secondary"
        (click)="onDealerChangeClick()"
      >
        <mat-icon class="profile-edit-icon" svgIcon="create"></mat-icon>
        <T
          str="Search"
          key="customerPortal.customer-portal.appointments.search.find"
          tags="customer-portal, 3.1"
        ></T>
      </button>
    </div>
    <!-- header -->
  </div>
  <mat-divider></mat-divider>
  <div class="flex flex-col md:flex-row gap-0 md:gap-5">
    <mat-form-field class="w-full md:w-1/2">
      <!-- prettier-ignore -->
      <input
      data-cy="add-new-appointment-date-input"
        matInput
        required
        (click)="picker.open()"
        readonly
        [(ngModel)]="appointmentsPopupService.defaultDate"
        [matDatepickerFilter]="myDateFilter"
        [matDatepicker]="picker"
        [min]="appointmentsPopupService.minDate"
        datePickerFormat={{dateFormat}}
        placeholder="{{ 'Preferred date' | translate:{ _key: 'customerPortal.customer-portal.appointments.preferred-date', _tags: 'customer-portal, 3.1'} }}"
        formControlName="date"
      />
      <mat-datepicker-toggle
        class="calendar-size"
        matPrefix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker
        [calendarHeaderComponent]="customHeader"
        #picker
        (opened)="addScroll(picker)"
      >
      </mat-datepicker>
      <span (click)="picker.open()" matSuffix>
        <mat-icon class="primary" svgIcon="create"></mat-icon>
      </span>
    </mat-form-field>
    <mat-form-field class="inline-block md:hidden w-full">
      <mat-label
        ><T
          str="Preferred time of day"
          key="customerPortal.customer-portal.appointments.preferred-time"
          tags="customer-portal, 3.1"
        ></T
      ></mat-label>
      <select required matNativeControl formControlName="time">
        <option *ngFor="let time of times" [value]="time">
          <ng-container
            *ngTemplateOutlet="
              sharedService.template('transifexTranslation');
              context: { key: time }
            "
          ></ng-container>
        </option>
      </select>
    </mat-form-field>
    <mat-form-field class="hidden md:inline-block w-1/2">
      <mat-label
        ><T
          str="Preferred time of day"
          key="customerPortal.customer-portal.appointments.preferred-time"
          tags="customer-portal, 3.1"
        ></T
      ></mat-label>
      <mat-select
        formControlName="time"
        data-cy="add-new-appointment-time-select"
      >
        <mat-option *ngFor="let time of times" [value]="time">
          <ng-container
            *ngTemplateOutlet="
              sharedService.template('transifexTranslation');
              context: { key: time }
            "
          ></ng-container>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="flex gap-5 mb-5 mt-0 items-center md:mt-2.5">
    <div>
      <mat-icon
        class="primary"
        class="min-w-[48px] min-h-[48px]"
        svgIcon="report"
      ></mat-icon>
    </div>
    <div class="font-efSans-primary font-light text-sm md:text-base">
      <p>
        <T
          str="Note:"
          key="customerPortal.customer-portal.appointments.note"
          tags="customer-portal, 3.1"
        ></T>
      </p>
      <p>
        <T
          str="We will confirm the desired date or contact you to arrange an alternative date with you."
          key="customerPortal.customer-portal.appointments.notice"
          tags="customer-portal, 3.1"
        ></T>
      </p>
    </div>
  </div>

  <div class="flex flex-col gap-[10px] md:hidden">
    <mat-divider class="my-4"></mat-divider>
    <button
      class="back revolution w-full md:w-auto"
      mat-button
      mat-stroked-button
      color="secondary"
      matStepperPrevious
    >
      {{ 'back' | sharedTranslate }}
    </button>
    <button
      class="next revolution w-full md:w-auto"
      mat-button
      mat-flat-button
      color="secondary"
      matStepperNext
      type="button"
      (click)="appointmentsPopupService.setAllData()"
      [disabled]="
        appointmentsPopupService.thirdFormGroup?.invalid ||
        appointmentsPopupService.loadingCarData
      "
    >
      {{ 'next' | sharedTranslate }}
    </button>
  </div>
</form>

<ng-template #officialDealersText>
  <T
    str="Official brand representatives"
    key="customerPortal.customer-portal.change-dealer-seller.official-dealers"
    tags="customer-portal, 3.1"
  ></T>
</ng-template>
<ng-template #allDealersText>
  <T
    str="All brand representatives"
    key="customerPortal.customer-portal.change-dealer-seller.all-dealers"
    tags="customer-portal, 3.1"
  ></T>
</ng-template>
<ng-template #preferred>
  <!-- prettier-ignore -->
  {{
    appointmentsPopupService.dealer.name && appointmentsPopupService.dealer.city
      ? sharedService.formatDealerName(appointmentsPopupService.dealer) +
        ' (' +
        ('Your preferred dealer'
          | translate
            : {
                _key: 'shared.preffered-dealer',
                _tags: 'customer-portal, 3.1'
              }) +
        ')'
      : ''
  }}
</ng-template>
