<div
  data-cy="vehicle-upload-document-form"
  class="cpt-modal ef-scrollbar relative dialog-content-p-0 mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <ng-container *ngIf="data?.type === 'document'">
      <T
        str="Add document"
        key="customerPortal.customer-portal.vehicle.documents-upload.title"
        tags="customer-portal, 3.1"
      ></T>
    </ng-container>
    <ng-container *ngIf="!(data?.type === 'document')">
      <T
        str="Add image"
        key="customerPortal.customer-portal.add-images"
        tags="customer-portal, 3.1"
      ></T>
    </ng-container>
  </h2>

  <mat-dialog-content class="mat-typography">
    <form [formGroup]="documentUploadFormGroup">
      <div>
        <mat-form-field
          class="hidden md:block w-full mt-2.5"
          appearance="legacy"
        >
          <mat-label>{{ 'type' | sharedTranslate }}</mat-label>
          <mat-select
            formControlName="documentType"
            required
            data-cy="vehicle-upload-document-select"
          >
            <mat-option *ngFor="let d of uploadableDocumentTypes" [value]="d">
              <ng-container
                *ngTemplateOutlet="
                  sharedService.template('transifexTranslation');
                  context: {
                    str: d?.value?.toLowerCase(),
                    key: d?.key
                  }
                "
              ></ng-container>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="block md:hidden w-full mt-2.5"
          appearance="legacy"
        >
          <mat-label>{{ 'type' | sharedTranslate }}</mat-label>
          <select matNativeControl formControlName="documentType" required>
            <option *ngFor="let d of uploadableDocumentTypes" [ngValue]="d">
              <ng-container
                *ngTemplateOutlet="
                  sharedService.template('transifexTranslation');
                  context: {
                    str: d?.value?.toLowerCase(),
                    key: d?.key
                  }
                "
              ></ng-container>
            </option>
          </select>
        </mat-form-field>
      </div>

      <mat-divider class="mt-0 mb-6"></mat-divider>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-12">
        <!-- Image preview -->
        <ng-container *ngIf="selectedFileType !== 'pdf'">
          <div
            class="image-preview col-span-1 box-border bg-cover bg-center w-full md:w-[300px] h-[200px] flex justify-center bg-mat-ef-revolution-grey-two-50"
          >
            <img alt="image preview" *ngIf="fileUploaded" [src]="preview" />
            <div
              class="w-full h-full flex items-center justify-center"
              *ngIf="!fileUploaded"
            >
              <mat-icon
                class="w-12 h-12"
                svgIcon="add_photo_alternate"
              ></mat-icon>
            </div>
          </div>
        </ng-container>

        <!-- Pdf preview -->
        <ng-container *ngIf="selectedFileType === 'pdf'">
          <div
            class="box-border bg-cover bg-center w-full md:w-[300px] h-[200px] flex justify-center bg-mat-ef-grey-dark-50"
          >
            <div class="w-full h-full">
              <ngx-extended-pdf-viewer
                [src]="preview"
                [logLevel]="0"
                [showToolbar]="false"
                [showDownloadButton]="false"
              ></ngx-extended-pdf-viewer>
            </div>
          </div>
        </ng-container>

        <div
          class="flex flex-col col-span-1 gap-5"
          data-cy="vehicle-upload-document-accepted"
        >
          <p
            class="text-sm leading-5 mt-0 md:mt-5 text-mat-ef-revolution-grey-two-200"
          >
            <!-- File requirements, based on format-->
            <span *ngIf="acceptedTypes === 'image'">
              <T
                str="JPG, PNG (max. 10MB)"
                key="customerPortal.customer-portal.vehicle.documents-upload.requirements.image"
                tags="customer-portal, 3.1"
              ></T>
            </span>
            <span *ngIf="acceptedTypes === 'pdf'">
              <T
                str="PDF (max. 10MB)"
                key="customerPortal.customer-portal.vehicle.documents-upload.requirements.pdf"
                tags="customer-portal, 3.1"
              ></T>
            </span>
            <span *ngIf="acceptedTypes === 'image,pdf'">
              <T
                str="JPG, PNG, PDF (max. 10MB)"
                key="customerPortal.customer-portal.vehicle.documents-upload.requirements.imageorpdf"
                tags="customer-portal, 3.1"
              ></T>
            </span>
            <br />
            <!-- Incorrect size notifier-->
            <span class="text-mat-ef-red-secondary-500" *ngIf="!propperSize">
              <T
                str="Incorrect file size"
                key="customerPortal.customer-portal.vehicle.documents-upload.requirements.incorrect-size"
                tags="customer-portal, 3.1"
              ></T>
            </span>
            <span
              class="text-mat-ef-red-secondary-500"
              *ngIf="fileUploaded && !isAllowedImageType"
            >
              <T
                str="Incorrect file type."
                key="customerPortal.customer-portal.vehicle.documents-upload.requirements.incorrect-format"
                tags="customer-portal, 3.1"
              ></T>
            </span>
          </p>

          <div class="relative">
            <input
              data-cy="vehicle-upload-document-input"
              *ngIf="acceptedTypes === 'image' && vehicleDocument.type"
              class="absolute top-0 opacity-0 z-50 h-full w-full cursor-pointer"
              type="file"
              accept=".jpg, .png"
              (change)="onFileSelected($event)"
            />
            <input
              data-cy="vehicle-upload-document-input"
              *ngIf="acceptedTypes === 'image,pdf' && vehicleDocument.type"
              class="absolute top-0 opacity-0 z-50 h-full w-full cursor-pointer"
              type="file"
              accept=".jpg, .png, .pdf"
              (change)="onFileSelected($event)"
            />
            <input
              data-cy="vehicle-upload-document-input"
              *ngIf="acceptedTypes === 'pdf' && vehicleDocument.type"
              class="absolute top-0 opacity-0 z-50 h-full w-full cursor-pointer"
              type="file"
              accept=".pdf"
              (change)="onFileSelected($event)"
            />

            <button
              class="revolution button-upload w-full md:w-fit"
              mat-button
              mat-stroked-button
              color="secondary"
              [disabled]="!vehicleDocument.type"
            >
              <mat-icon class="mr-1" svgIcon="add_photo_alternate"></mat-icon>
              <span *ngIf="!fileUploaded">
                <T
                  str="Select file"
                  key="customerPortal.customer-portal.vehicle.documents-upload.select-file"
                  tags="customer-portal, 3.1"
                ></T>
              </span>
              <span *ngIf="fileUploaded">
                <T
                  str="Replace file"
                  key="customerPortal.customer-portal.vehicle.documents-upload.replace-file"
                  tags="customer-portal, 3.1"
                ></T>
              </span>
            </button>
          </div>

          <div>
            <mat-form-field
              class="prefix-large w-full mt-2.5"
              appearance="legacy"
            >
              <mat-label for="name"
                ><T
                  str="Name"
                  key="customerPortal.customer-portal.vehicle.documents-upload.name"
                  tags="customer-portal, 3.1"
                ></T
              ></mat-label>
              <input
                matInput
                type="text"
                formControlName="name"
                [errorStateMatcher]="matcher"
              />
              <mat-error
                *ngIf="documentUploadFormGroup.get('name').errors?.maxlength"
              >
                <T
                  str="Maximum filename length excceeded"
                  key="customerPortal.customer-portal.vehicle.documents-upload.error.maxlength"
                  tags="customer-portal, 3.1"
                ></T>
              </mat-error>
              <mat-error
                *ngIf="documentUploadFormGroup.get('name').errors?.required"
              >
                <T
                  str="The document is required"
                  key="customerPortal.customer-portal.vehicle.documents-upload.error.required"
                  tags="customer-portal, 3.1"
                ></T>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-divider class="mt-0 mb-4"></mat-divider>

  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>

    <button
      data-cy="vehicle-upload-document-submit"
      cdkFocusInitial
      class="revolution w-full md:w-auto"
      color="secondary"
      mat-button
      mat-flat-button
      (click)="onUploadDocumentFormSubmit()"
      cdkFocusInitial
      [disabled]="
        !documentUploadFormGroup.valid || saving || !isAllowedImageType
      "
    >
      <mat-spinner
        class="inline-block mr-2.5"
        [diameter]="16"
        *ngIf="saving"
      ></mat-spinner>
      <span>{{ 'save' | sharedTranslate }}</span>
    </button>
  </mat-dialog-actions>
</div>
