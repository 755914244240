import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGoogleTagmanagerService,
  GTMEventData
} from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { ServicePackageDTO } from '../../../../services/service-package.service'
import { SharedService } from '../../../../services/shared.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'
import {
  Expiration,
  WarrantyDetailsDTO,
  WarrantyService
} from '../../../../services/warranty.service'
import { CustomCalendarHeaderComponent } from '../../../basic/custom-calendar-header/custom-calendar-header.component'
import {
  RevolutionWarrantyContactPopupComponent,
  WarrantyContactPopupComponentDialogData,
  WarrantyContactPopupComponentDialogTitle
} from '../warranty-contact-popup/warranty-contact-popup.component'
import { parseISO } from 'date-fns'

@Component({
  selector: 'customer-portal-app-revolution-service-molecule',
  templateUrl: './service-molecule.component.html'
})
export class RevolutionServiceMoleculeComponent implements OnInit {
  percentageTillExpires: number
  tillExpires: number
  customHeader = CustomCalendarHeaderComponent
  user: User
  servicePackages: ServicePackageDTO[] = []
  unpurchasedServicePackages: ServicePackageDTO[]
  purchasedServicePackages: ServicePackageDTO[]
  servicePackage: ServicePackageDTO = null
  servicePackageIsDisabled = true
  showWarning = false
  dateFormat: string

  hasLinks = false
  constructor(
    private readonly warrantyService: WarrantyService,
    private readonly dialog: MatDialog,
    private readonly dataService: DataService,
    private readonly logger: NGXLogger,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly sharedService: SharedService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.dateFormat = this.remoteConfigService.get('dateFormat.long')
    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
    })
  }

  @Input()
  public vehicle: VehicleDTOExtended

  @Input()
  loading: boolean

  sendContentModulesData(expanded: boolean, contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Accordion',
      expanded ? 'Open' : 'Close',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Accordion|${this.sharedService.translateLink(contentModuleLabel) ?? ''}`,
      expanded ? 'Open' : 'Close'
    )
  }

  styleServicePackageProgressBar(w: ServicePackageDTO): string {
    return this.warrantyService.styleProgressBar(w, 'servicePackage')
  }

  openWarrantyPackageContactPopup(
    contactType: 'contact' | 'offerRequest',
    title: WarrantyContactPopupComponentDialogTitle,
    w?: WarrantyDetailsDTO | ServicePackageDTO
  ): void {
    const data: WarrantyContactPopupComponentDialogData = {
      vehicle: this.vehicle,
      warrantyServicePackage: w,
      requestType: contactType,
      title
    }
    this.dialog.open(RevolutionWarrantyContactPopupComponent, {
      data,
      panelClass: 'mat-dialog-cpt'
    })

    /** GTM 2.7.3 */
    const gtmData: GTMEventData = {
      event: 'gaEvent2',
      event_name: 'service',
      service: {
        button_action: title,
        service_type:
          contactType === 'contact' ? 'Get in touch' : 'Offer request',
        event_action:
          contactType === 'contact' ? 'Get in touch' : 'Offer request',
        event_category: 'Service',
        event_label: title
      }
    }
    this.cptGtmService.send(gtmData)
  }

  onDocumentButtonClicked(): void {
    /** GTM 2.7.6 */
    const gtmData: GTMEventData = {
      event: 'gaEvent2',
      event_name: 'service',
      service: {
        button_action: 'Warranty',
        service_type: 'Documents',
        event_action: 'Documents',
        event_category: 'Service',
        event_label: 'Warranty'
      }
    }
    this.cptGtmService.send(gtmData)
  }

  sortPurchasedServicePackagesByDateDesc(): void {
    this.purchasedServicePackages.sort(
      (a: ServicePackageDTO, b: ServicePackageDTO) => {
        const one = parseISO(a.endDate).getTime()
        const two = parseISO(b.endDate).getTime()
        return two - one
      }
    )
  }

  styleLeasingProgressBar(w: ServicePackageDTO): string {
    return this.warrantyService.styleProgressBar(w, 'servicePackage')
  }

  styleLeasingWarningIcon(w: ServicePackageDTO[]): string {
    let style = 'ok-green'
    w.forEach(w => {
      if (this.tillExpiresWarn(w)) {
        style = 'warn-orange'
      }
      if (this.tillExpiresCritical(w)) {
        style = 'warn-red'
      }
    })
    return style
  }

  tillExpiration(w: ServicePackageDTO): Expiration {
    return this.warrantyService.calculateTillExpires(w.startDate, w.endDate)
  }

  tillExpiresWarn(w: ServicePackageDTO): boolean {
    const warn: number = this.remoteConfigService.get(
      'servicePackage.expirationDays.warn'
    )
    return (
      this.tillExpiration(w).days <= warn && this.tillExpiration(w).days > 0
    )
  }

  tillExpiresCritical(w: ServicePackageDTO): boolean {
    const critical: number = this.remoteConfigService.get(
      'servicePackage.expirationDays.critical'
    )
    return (
      this.tillExpiration(w).days < critical && this.tillExpiration(w).days > 0
    )
  }

  /**
   * Warning is shown if there is purchased, non expired SP near expiration, and there is
   * possibility to buy additional SP
   */
  setWarning(w: ServicePackageDTO[]): boolean {
    let isWarnOrCritical = false
    let purchasable = false
    w.forEach(w => {
      if (this.tillExpiresCritical(w) || this.tillExpiresWarn(w)) {
        isWarnOrCritical = true
      }
      if (!w.purchased) {
        purchasable = true
      }
    })
    this.showWarning = isWarnOrCritical && purchasable
    return this.showWarning
  }

  ngOnInit(): void {
    this.dataService.onVehicleServicePackageLoaded.subscribe(
      servicePackages => {
        this.servicePackages = servicePackages
        this.servicePackage = null

        if (servicePackages != null) {
          this.logger.debug(this.servicePackage, 'SP')

          if (servicePackages.length > 0) {
            this.servicePackageIsDisabled = false
            this.cdRef.detectChanges()
            this.servicePackage = servicePackages[servicePackages.length - 1]
            this.tillExpires = this.warrantyService.calculateTillExpires(
              this.servicePackage.startDate,
              this.servicePackage.endDate
            ).days
            this.percentageTillExpires =
              this.warrantyService.calculateTillExpires(
                this.servicePackage.startDate,
                this.servicePackage.endDate
              ).percentage
            this.unpurchasedServicePackages = this.servicePackages.filter(
              w => !w.purchased
            )
            this.purchasedServicePackages = this.servicePackages.filter(
              w => w.purchased
            )
            this.sortPurchasedServicePackagesByDateDesc()
            this.setWarning(servicePackages)
          }
        }

        if (this.hasLinks) {
          this.servicePackageIsDisabled = false
          this.cdRef.detectChanges()
        }
      }
    )
  }

  hasLinksChanged(hasLinks): void {
    this.hasLinks = hasLinks

    if (
      (this.servicePackages != null && this.servicePackages?.length > 0) ||
      this.hasLinks
    ) {
      this.servicePackageIsDisabled = false
    } else {
      this.servicePackageIsDisabled = true
    }
    this.cdRef.detectChanges()
  }
}
