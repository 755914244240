import { Component, Inject } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { LegacyErrorStateMatcher as ErrorStateMatcher } from '@angular/material/legacy-core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import {
  User,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from '../../../../services/shared.service'
import { ConfirmationPopupComponent } from '../../../basic/confirmation-popup/confirmation-popup.component'
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    return (
      !!(control?.invalid === true && control.dirty && control.parent.dirty) ||
      control?.hasError('notsame') === true ||
      control?.parent.hasError('notsame')
    )
  }
}

@Component({
  selector: 'customer-portal-app-revolution-email-change-popup',
  templateUrl: './email-change-popup.component.html'
})
export class RevolutionEmailChangePopupComponent {
  emailForm: FormGroup
  matcher = new MyErrorStateMatcher()

  saving = false
  onSuccessCallback = 'emailChanged'
  emailRegExPattern: string
  constructor(
    private readonly dialogRef: MatDialogRef<RevolutionEmailChangePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public user: User,
    private readonly userService: UserService,
    private readonly logger: NGXLogger,
    public dialog: MatDialog,
    public sharedService: SharedService,
    private readonly formBuilder: FormBuilder,
    public transifexService: TransifexService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.emailRegExPattern = this.remoteConfigService.get(
      'regEx.emailRegEx.pattern'
    )
    this.emailForm = this.formBuilder.group(
      {
        email: new FormControl<string | null>(null, [
          Validators.required,
          Validators.email,
          Validators.pattern(this.emailRegExPattern)
        ]),
        repeatEmail: new FormControl<string | null>(null, [
          Validators.required,
          Validators.email,
          Validators.pattern(this.emailRegExPattern)
        ])
      },
      { validator: this.checkEmails }
    )
  }

  updateEmail(): void {
    this.saving = true
    const email = this.emailForm.get('email').value
    const lang =
      this.user.language != null
        ? this.user.language.find(s => s.primary).language
        : 'de'
    this.userService.sendEmailChangeRequest(email, null, lang).subscribe(
      () => {
        this.saving = false
        this.dialogRef.close(true)
      },
      error => {
        this.saving = false
        this.logger.debug(error)
        this.dialog.open(ConfirmationPopupComponent, {
          data: {
            title: 'shared.error',
            text: 'customerPortal.customer-portal.details.change.email.failure.text'
          },
          panelClass: 'mat-dialog-cpt'
        })
        this.dialogRef.close()
      }
    )
  }

  checkEmails(group: FormGroup): {
    notsame: boolean
  } {
    const email = group.controls.email.value
    const repeatEmail = group.controls.repeatEmail.value

    let hasError = false
    if (
      group.controls.email.dirty &&
      group.controls.repeatEmail.dirty &&
      email !== repeatEmail &&
      repeatEmail.length > 0
    ) {
      hasError = true
    }

    return !hasError ? null : { notsame: true }
  }

  saveDisabled(): boolean {
    return (
      this.saving ||
      !this.emailForm.valid ||
      this.emailForm.get('email').value == null
    )
  }
}
