<customer-portal-app-navbar></customer-portal-app-navbar>
<customer-portal-app-navbar-second></customer-portal-app-navbar-second>
<ng-container *ngIf="hasTestView">
  <customer-portal-app-test></customer-portal-app-test>
</ng-container>
<ng-container *ngIf="!hasTestView">
  <div class="w-full h-[500px] flex justify-center items-center">
    <div class="text-red-700 font-black text-7xl">403</div>
  </div>
</ng-container>
<customer-portal-footer-container></customer-portal-footer-container>
