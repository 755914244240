import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGoogleTagmanagerService,
  Marker
} from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { debounceTime, distinctUntilChanged } from 'rxjs'
import { DataService } from '../../../../services/data.service'
import { DealerDTO, DealersService } from '../../../../services/dealers.service'
import { PersonalizationService } from '../../../../services/personalization.service'
import { SharedService } from '../../../../services/shared.service'
import {
  BrandDTO,
  VehiclesService
} from '../../../../services/vehicles.service'
import { WorkingHoursService } from '../../../../services/working-hours.service'

import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
interface DialogData {
  brand: string
  dealers: DealerDTO[]
  selectedDealer: DealerDTO
  serviceHoursCondition?: boolean
}

@Component({
  selector: 'customer-portal-app-revolution-search-dealer-seller-popup',
  templateUrl: './revolution-search-dealer-seller-popup.component.html'
})
export class RevolutionSearchDealerSellerPopupComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  searchDealerForm = new FormGroup({
    dealer: new FormControl<DealerDTO | null>(null),
    brand: new FormControl<BrandDTO | null>(null),
    filter: new FormControl<string | null>(null)
  })

  showDealerList = true
  initialLat = 51.165691
  initialLng = 10.451526
  initialZoom = 6
  streetViewControl = false
  zoomControl = true
  markers: Marker[] = []
  markersSelected: Marker[] = []
  selectedDealer: DealerDTO
  filteredOptions: DealerDTO[]
  filteredOptions_filter: DealerDTO[]
  filter: string
  brands: BrandDTO[]
  selectedBrand: BrandDTO
  previousSelectedBrand: BrandDTO
  lat: number
  lng: number
  public origin
  public destination
  zoom: number

  googleMapsUrl: string
  open = {
    sales: true,
    service: true
  }

  day = ''
  dateToday = {
    sales: '',
    service: ''
  }

  public user: User

  @ViewChild('map') public ngMap
  @ViewChildren('mapDealerTemplateList')
  mapDealerTemplateList: QueryList<ElementRef>

  sub = {
    onUserLoaded: null
  }

  loadingDealers = true

  constructor(
    private readonly dealersService: DealersService,
    private readonly vehicleService: VehiclesService,
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<RevolutionSearchDealerSellerPopupComponent>,
    private readonly personalizationService: PersonalizationService,
    private readonly workingHoursService: WorkingHoursService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly sharedService: SharedService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly dataService: DataService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.initialZoom = this.remoteConfigService.get('mapOptions.initalMapZoom')
    this.initialLat = this.remoteConfigService.get('mapOptions.coordinates.lat')
    this.initialLng = this.remoteConfigService.get('mapOptions.coordinates.lng')
    this.googleMapsUrl = this.remoteConfigService.get(
      'googleMaps.googleMapsUrl'
    )
    if (data.selectedDealer != null) {
      this.lat = data.selectedDealer.latitude
      this.lng = data.selectedDealer.longitude
    } else {
      this.lat = this.initialLat
      this.lng = this.initialLng
    }
    this.filteredOptions = this.data.dealers
    this.setFilteredData()
    this.zoom = this.initialZoom
  }

  drawMarkers(dealers: DealerDTO[]): void {
    dealers.forEach(d => {
      const m: Marker = {
        position: {
          lat: d.latitude,
          lng: d.longitude
        },
        options: {
          icon: 'assets/images/map-marker-default.svg',
          draggable: false
        },
        draggable: false,
        label: d.name,
        dealer: d
      }
      this.markers.push(m)
    })
    if (this.selectedDealer != null) {
      const selectedDealerMarker: Marker = {
        dealer: this.selectedDealer,
        position: {
          lat: this.selectedDealer.latitude,
          lng: this.selectedDealer.longitude
        },
        options: {
          icon: 'assets/images/map-marker-selected.svg',
          draggable: false
        },
        draggable: false
      }
      this.markersSelected.push(selectedDealerMarker)
      this.centerToDealer(this.selectedDealer)
    }
  }

  getBrandByName(brandName: string, brands: BrandDTO[]): BrandDTO {
    let brand = null
    brands.forEach(b => {
      if (b.name === brandName) {
        brand = b
      }
    })
    return brand
  }

  loadBrands(): void {
    this.personalizationService.getPersonalizationEnums().subscribe(val => {
      this.brands = val.brands.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
      this.selectedBrand = this.getBrandByName(this.data.brand, val.brands)
      this.previousSelectedBrand = { ...this.selectedBrand }
      this.searchDealerForm
        .get('brand')
        .setValue(this.selectedBrand != null ? this.selectedBrand : null)
    })
  }

  disableBrandDropdown(): void {
    if (
      this.data.brand != null &&
      this.selectedBrand != null &&
      this.data.brand === this.selectedBrand.name &&
      this.data.dealers != null &&
      this.data.dealers[0] != null
    ) {
      this.searchDealerForm.controls.brand.enable()
      return
    }
    this.searchDealerForm.controls.brand.disable()
  }

  dealerSelected(dealer): void {
    this.selectedDealer = dealer
    this.markersSelected = []
    const newMarker: Marker = {
      dealer: this.selectedDealer,
      options: {
        icon: 'assets/images/map-marker-selected.svg',
        draggable: false
      },
      draggable: false,
      position: {
        lat: this.selectedDealer.latitude,
        lng: this.selectedDealer.longitude
      }
    }
    this.markersSelected.push(newMarker)

    this.zoomToDealer(this.selectedDealer)
    this.setFilteredData(this.filteredOptions_filter)
  }

  markerClicked(marker: Marker): void {
    this.selectedDealer = marker.dealer
    this.searchDealerForm.get('dealer').setValue(marker.dealer)

    this.markersSelected = []
    const newMarker: Marker = {
      dealer: marker.dealer,
      draggable: false,
      options: {
        icon: 'assets/images/map-marker-selected.svg',
        draggable: false
      },
      position: {
        lat: marker.dealer.latitude,
        lng: marker.dealer.longitude
      }
    }
    this.markersSelected.push(newMarker)

    this.zoomToDealer(this.selectedDealer)
    this.scrollToDealer(this.selectedDealer)

    this.showDealerList = true
  }

  scrollToDealer(dealer: DealerDTO): void {
    if (this.mapDealerTemplateList != null) {
      this.mapDealerTemplateList.forEach(el => {
        try {
          if (String(el.nativeElement.id) === String(dealer.id)) {
            el.nativeElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            })
          }
        } catch (error) {}
      })
    }
  }

  centerToDealer(dealer: DealerDTO): void {
    this.zoom = this.initialZoom
    setTimeout(() => {
      this.lat = dealer.latitude
      this.lng = dealer.longitude
      // this.zoom = this.zoom + 2
    }, 500)
  }

  zoomToDealer(dealer: DealerDTO): void {
    setTimeout(() => {
      this.lat = dealer.latitude
      this.lng = dealer.longitude
      if (this.zoom < 15) {
        this.zoom = 15
      } else {
        this.zoom = this.zoom + 0.1
      }
    }, 1000)
  }

  loadDealers(brand?: string): void {
    this.loadingDealers = true
    this.filteredOptions = []
    this.filteredOptions_filter = []
    this.dealersService.getBrandDealers(brand).subscribe(
      value => {
        this.data.dealers = this.sharedService.sortDealers(value)
        this.filteredOptions = this.data.dealers
        this.setFilteredData()
        this.updateMap()
      },
      () => {
        this.setFilteredData()
      }
    )
  }

  updateMap(): void {
    this.markers = []
    this.markersSelected = []
    this.lat = this.initialLat
    this.lng = this.initialLng
    this.zoom = this.initialZoom + 0.1
    this.drawMarkers(this.filteredOptions_filter)
  }

  setFilteredData(filteredOptions?): void {
    this.loadingDealers = false
    this.filteredOptions_filter = this.sharedService.deepCopy(
      filteredOptions != null ? filteredOptions : this.filteredOptions
    )
  }

  clearFilter(): void {
    this.searchDealerForm.get('filter').setValue(null)
  }

  ngOnInit(): void {
    this.sub.onUserLoaded = this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
    })

    this.loadBrands()

    this.selectedDealer = this.data.selectedDealer
    if (this.selectedDealer != null) {
      this.searchDealerForm.get('dealer').setValue(this.selectedDealer)
    }

    this.searchDealerForm
      .get('brand')
      .valueChanges.subscribe((brand: BrandDTO) => {
        if (brand == null) {
          this.loadDealers()
        } else {
          if (brand.id !== this.previousSelectedBrand.id) {
            this.selectedBrand = this.getBrandByName(brand.name, this.brands)
            this.previousSelectedBrand = { ...this.selectedBrand }
            this.selectedDealer = null
          }
          if (brand != null && brand.name !== 'any') {
            this.loadDealers(brand.name)
          } else {
            this.loadDealers()
          }
        }
      })

    this.searchDealerForm
      .get('filter')
      .valueChanges.pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value: string) => {
        if (typeof value !== 'string') {
          this.setFilteredData()
        } else {
          const searchTerm = value.toLowerCase()
          this.filteredOptions_filter = this.filteredOptions.filter(
            dealer =>
              dealer.name?.toLowerCase().includes(searchTerm) ||
              dealer.subname?.toLowerCase().includes(searchTerm) ||
              dealer.zip?.toLowerCase().includes(searchTerm) ||
              dealer.city?.toLowerCase().includes(searchTerm)
          )
        }
        this.setFilteredData(this.filteredOptions_filter)
        this.updateMap()
      })
  }

  ngOnDestroy(): void {
    this.sharedService.unsubscribe(this.sub)
  }

  ngAfterViewInit(): void {
    this.ngMap.mapReady.subscribe(() => {
      if (this.data.selectedDealer != null) {
        this.centerToDealer(this.data.selectedDealer)
      }
    })
  }

  onSubmit(): void {
    this.dialogRef.close({
      selectedDealer: this.selectedDealer,
      allDealers: this.data.dealers
    })
  }

  sendOutboundLinkData(outboundLinkType: string, dealer: DealerDTO): void {
    this.cptGtmService.sendOutboundData(outboundLinkType, dealer)
  }

  showDirection(dealer): void {
    this.destination = { lat: dealer.latitude, lng: dealer.longitude }

    const latLonO =
      (this.user.address[0].street != null ? this.user.address[0].street : '') +
      (this.user.address[0].zip != null ? ',' + this.user.address[0].zip : '') +
      (this.user.address[0].city != null ? ',' + this.user.address[0].city : '')
    const latLonD =
      String(this.destination.lat) + ',' + String(this.destination.lng)
    window.open(
      this.googleMapsUrl +
        latLonO +
        '&destination=' +
        latLonD +
        '&travelmode=driving',
      '_blank'
    )
  }

  toggleDealerList(): void {
    this.showDealerList = !this.showDealerList
  }

  openPhoneClient(event: Event, dealer: DealerDTO): void {
    event.stopPropagation()
    const phone = dealer.telephone.toLowerCase()
    const link = 'tel:' + phone
    window.open(link, '_blank')
  }

  openEmailClient(event: Event, dealer: DealerDTO): void {
    event.stopPropagation()
    const mail = dealer.email.toLowerCase()
    const link = 'mailto:' + mail
    window.open(link, '_blank')
  }
}
