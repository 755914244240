<div id="links" class="footerLinks text-mat-ef-revolution-supergrey-500">
  <div class="grid grid-cols-1 md:grid-cols-4 md:gap-4">
    <!-- First links column -->
    <div class="col1 p-6 md:p-0 flex flex-col gap-2 md:gap-6">
      <div class="link-item flex gap-6" *ngFor="let l of col1">
        <a
          [href]="getLink(l)"
          (click)="sendGTMNavigationData(translateLink(l?.translation))"
          target="_blank"
        >
          {{
            {
              key: l?.translation
            } | transifexTranslate
          }}
        </a>
        <mat-icon svgIcon="chevron_right"></mat-icon>
      </div>
    </div>

    <customer-portal-app-divider
      class="footerDivider md:hidden"
    ></customer-portal-app-divider>

    <!-- Second links column -->
    <div class="col2 p-6 md:p-0 flex flex-col gap-2 md:gap-6">
      <div class="link-item flex gap-6" *ngFor="let l of col2">
        <a
          [href]="getLink(l)"
          (click)="sendGTMNavigationData(translateLink(l?.translation))"
          target="_blank"
        >
          {{
            {
              key: l?.translation
            } | transifexTranslate
          }}
        </a>
      </div>
    </div>
  </div>
</div>
