<div
  data-cy="registration-plate-form"
  class="cpt-modal relative dialog-content-p-0 mat-dialog-actions-pb-20"
>
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="Updating the licence plate"
      key="customerPortal.customer-portal.vehicle.registration-plate-update"
      tags="customer-portal, 3.1"
    ></T>
  </h2>

  <mat-dialog-content class="pt-5">
    <form class="form relative" [formGroup]="licencePlateForm">
      <mat-checkbox
      class="h-6 text-sm absolute top-[-24px] right-0 font-normal"
      color="primary"
      #checkbox_otherLicencePlate
      (change)="otherLicencePlateChanged(checkbox_otherLicencePlate)"
    >
      <T
        str="Other license plate"
        key="customerPortal.customer-portal.add-new-vehicle.otherlicencePlate"
        tags="customer-portal, 3.8"
      ></T>
    </mat-checkbox>

    <ng-container *ngIf="otherLicencePlateCheckbox">
      <mat-form-field appearance="legacy" class="w-full">
        <mat-label>
          <T
            str="License plate"
            key="customerPortal.customer-portal.add-new-vehicle.licencePlate"
            tags="customer-portal, 3.1"
          ></T
        ></mat-label>
        <input
          oninput="this.value = this.value.toUpperCase()"
          matInput
          required
          formControlName="licencePlateCustom"
          [errorStateMatcher]="matcher"
        />
        <mat-error
          *ngIf="licencePlateForm.get('licencePlateCustom').errors"
          data-cy="add-new-vehicle-form-licence-plate-error"
        >
          {{
            transifexService.getErrorTranslation(
              licencePlateForm.get('licencePlateCustom'),
              'customerPortal.customer-portal.add-new-vehicle.error.'
            )
          }}
        </mat-error>
      </mat-form-field>
    </ng-container>


    <ng-container *ngIf="!otherLicencePlateCheckbox">
      <mat-form-field
        appearance="legacy"
        *ngIf="country === 'de'"
        class="w-full"
      >
        <mat-label>
          <T
            str="Add a new license plate"
            key="customerPortal.customer-portal.add-new-vehicle.licencePlate"
            tags="customer-portal, 3.1"
          ></T
        ></mat-label>
        <input
          data-cy="registration-plate-form-input"
          oninput="this.value = this.value.toUpperCase()"
          matInput
          required
          formControlName="licencePlateDE"
          [errorStateMatcher]="matcher"
        />
        <mat-error *ngIf="licencePlateForm.get('licencePlateDE').errors">
          {{
            transifexService.getErrorTranslation(
              licencePlateForm.get('licencePlateDE'),
              'customerPortal.customer-portal.add-new-vehicle.error.'
            )
          }}
        </mat-error>
      </mat-form-field>

      <div
        class="flex gap-1 md:gap-5 flex-col md:flex-row"
        *ngIf="country === 'ch'"
      >
        <mat-form-field
          class="md:max-w-[150px] w-full md:w-fit desktop-only"
          appearance="legacy"
        >
          <mat-label
            ><T
              str="Canton"
              key="customerPortal.customer-portal.vehicle.details.registration-plate-canton"
              tags="customer-portal, 3.1"
            ></T
          ></mat-label>
          <mat-select
            data-cy="registration-plate-form-canton-select"
            formControlName="licencePlateCanton"
            required
            [errorStateMatcher]="matcher"
          >
            <mat-option *ngFor="let k of cantons" [value]="k.id">
              {{ k.id }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="md:max-w-[150px] w-full md:w-fit mobile-only select-small"
          appearance="legacy"
        >
          <mat-label>
            <T
              str="Canton"
              key="customerPortal.customer-portal.vehicle.details.registration-plate-canton"
              tags="customer-portal, 3.1"
            ></T
          ></mat-label>
          <select
            matNativeControl
            formControlName="licencePlateCanton"
            required
            [errorStateMatcher]="matcher"
          >
            <option *ngFor="let k of cantons" [value]="k.id">
              {{ k.id }}
            </option>
          </select>
        </mat-form-field>
        <mat-form-field class="flex-1" appearance="legacy">
          <mat-label>
            <T
              str="Add a new license plate"
              key="customerPortal.customer-portal.add-new-vehicle.licencePlate"
              tags="customer-portal, 3.1"
            ></T
          ></mat-label>
          <input
            data-cy="registration-plate-form-input"
            oninput="this.value = this.value.toUpperCase()"
            matInput
            required
            formControlName="licencePlateCH"
            [errorStateMatcher]="matcher"
          />
          <mat-error *ngIf="licencePlateForm.get('licencePlateCH').errors">
            {{
              transifexService.getErrorTranslation(
                licencePlateForm.get('licencePlateCH'),
                'customerPortal.customer-portal.add-new-vehicle.error.'
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>
    </form>
  </mat-dialog-content>

  <mat-divider class="mt-0"></mat-divider>

  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <button
      data-cy="registration-plate-form-submit"
      [disabled]="!licencePlateForm.valid || saving"
      id="saveButton"
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      (click)="updateRegistration()"
      cdkFocusInitial
    >
      <mat-spinner [diameter]="16" *ngIf="saving"></mat-spinner>
      {{ 'save' | sharedTranslate }}
    </button>
  </mat-dialog-actions>
</div>
