import { Component, EventEmitter, OnInit, Output } from '@angular/core'

@Component({
  selector: 'customer-portal-pwa-promo-banner',
  templateUrl: './pwa-promo-banner.component.html'
})
export class PwaPromoBannerComponent implements OnInit {
  @Output() public bannerDismiss = new EventEmitter<MouseEvent>()

  constructor() {}

  ngOnInit(): void {}
  dismissBanner(event: MouseEvent): void {
    this.bannerDismiss.emit(event)
  }
  promptInstall(): void {
    const installPrompt = Boolean(window['installPrompt'])
    if (installPrompt) {
      window['installPrompt'].prompt()
    }
  }
}
