<div class="cpt-modal">
  <div class="relative">
    <button
      class="absolute top-0 right-0 md:-top-5 md:-right-5 scale-150 w-6 h-6 outline-0"
      (click)="close()"
    >
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>

  <div class="image-preview w-full h-full m-auto">
    <button
      class="image-slider-btn left-0 md:left-5"
      (click)="back()"
      *ngIf="imageArr.length > 1"
    >
      <mat-icon
        svgIcon="chevron_left"
        class="text-white text-4xl flex justify-center items-center w-10 md:w-14 h-10 md:h-14"
      >
      </mat-icon>
    </button>
    <button
      class="image-slider-btn right-0 md:right-5"
      (click)="forward()"
      *ngIf="imageArr.length > 1"
    >
      <mat-icon
        svgIcon="chevron_right"
        class="text-white text-4xl flex justify-center items-center w-10 md:w-14 h-10 md:h-14"
      >
      </mat-icon>
    </button>

    <div
      class="hidden lg:flex justify-center absolute bottom-7 inset-x-5 gap-6 cursor-pointer"
    >
      <div
        *ngFor="let img of imageArr; let i = index"
        class="image-preview w-40 max-w-40 h-20 max-h-20 relative"
        (click)="setImage(i)"
      >
        <div class="absolute top-0 right-0 z-10 p-1 w-8 h-8">
          <customer-portal-app-vehicle-profile-picture
            [vehicle]="vehicle"
            [setPicture]="img"
          ></customer-portal-app-vehicle-profile-picture>
        </div>

        <img
          alt="vehicle img"
          class="vehicle-img rounded-lg w-full max-w-full bg-mat-ef-revolution-grey-500 h-20 max-h-20"
          src="{{ img?.publicUrl }}"
        />
        <div class="relative" *ngIf="index === i">
          <div
            class="duration-600 ease-in-out h-0.5 mt-2 mx-auto transition-colors w-2/3 bg-mat-ef-revolution-secondary-500"
          ></div>
        </div>
      </div>
    </div>

    <div class="w-full h-full relative">
      <div class="absolute -top-7 right-3 z-10 p-1 w-10 h-10">
        <customer-portal-app-vehicle-profile-picture
          [vehicle]="vehicle"
          [setPicture]="imageArr[index]"
          [showAlways]="true"
        ></customer-portal-app-vehicle-profile-picture>
      </div>
      <img
        class="w-full h-full"
        [src]="imageArr[index]?.publicUrl"
        alt=""
        [@animImageSlider]="index"
      />
    </div>

    <div class="flex lg:hidden gap-2 justify-center pt-6">
      <div
        *ngFor="let img of imageArr; let i = index"
        [ngClass]="{
          'bg-mat-ef-revolution-secondary-500': index === i,
          'bg-mat-ef-revolution-grey-700': index !== i
        }"
        class="duration-600 ease-in transition-colors w-2 h-2 rounded-full cursor-pointer"
        (click)="setImage(i)"
      ></div>
    </div>
  </div>
</div>
