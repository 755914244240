<div class="container user-info pt-16">
  <div class="text-center">
    <div
      class="flex flex-col md:flex-row gap-6 justify-center items-center font-canelablack-primary text-4xl md:text-6xl"
      *ngIf="!loading && user && lang"
    >
      <div
        class="h-32 w-32 cursor-pointer flex justify-center items-center rounded-full relative bg-mat-ef-revolution-primary-500 shadow-md"
        [ngClass]="{
          'p-[2px] ': !showAltIcon && user?.profilePictureUrl,
          'p-4': showAltIcon || !user?.profilePictureUrl
        }"
        (click)="openUserImgDetails()"
      >
        <img
          *ngIf="!showAltIcon && user?.profilePictureUrl"
          class="h-[124px] w-[124px] object-cover rounded-full shadow-md"
          [src]="user?.profilePictureUrl"
          (error)="showAltIcon = true"
          alt=""
        />

        <div
          *ngIf="showAltIcon || !user?.profilePictureUrl"
          class="w-32 h-32 text-white flex items-center object-cover uppercase font-canelablack"
        >
          <span class="m-auto">{{ user | userinitials | async }}</span>
        </div>
      </div>
      <div>
        {{ user | username | async }}
      </div>
    </div>
    <div *ngIf="loading && !user?.contactId" class="id-skeleton">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>
  </div>
</div>
