<div class="content">
  <div class="content-container">
    <div class="valuations">
      <div *ngIf="loading">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>

      <ng-container *ngIf="!loading && vehiclesValuations?.length === 0">
        <div class="have-no-card">
          <div class="text-mat-ef-revolution-supergrey-900">
            <mat-icon svgIcon="no_sim"></mat-icon>
          </div>
          <div class="text-base font-efSans-primary">
            <T
              str="No valuations"
              key="customerPortal.customer-portal.marketplace.valuations.none"
              tags="customer-portal, 3.1"
            ></T>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!loading && vehiclesValuations?.length > 0">
        <div *ngFor="let v of vehiclesValuations">
          <mat-divider class="relative w-full"></mat-divider>
          <mat-expansion-panel
            (afterExpand)="
              sendContentModulesData(
                true,
                'customerPortal.customer-portal.top-bar.valuations'
              )
            "
            (afterCollapse)="
              sendContentModulesData(
                false,
                'customerPortal.customer-portal.top-bar.valuations'
              )
            "
          >
            <mat-expansion-panel-header>
              <mat-panel-title
                class="font-efSans text-base font-bold md:text-lg text-mat-ef-revolution-primary-500"
              >
                {{ v?.vehicleData?.manufacturerName }}&nbsp;{{
                  v?.vehicleData?.inseratTitle
                }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div>
              <table
                mat-table
                [dataSource]="v?.valuations"
                class="customer-portal_responsive-table"
                aria-describedby="valuations"
              >
                <ng-container matColumnDef="offerFrom">
                  <th id="Bid from" mat-header-cell *matHeaderCellDef>
                    <T
                      str="Bid from"
                      key="customerPortal.customer-portal.marketplace.valuations.bidfrom"
                      tags="customer-portal, 3.1"
                    ></T>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div
                      class="mobile-label text-sm text-mat-ef-revolution-grey-two-500 w-[100px]"
                    >
                      <T
                        str="Bid from"
                        key="customerPortal.customer-portal.marketplace.valuations.bidfrom"
                        tags="customer-portal, 3.1"
                      ></T>
                    </div>
                    {{ element.company }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="created">
                  <th id="Created at" mat-header-cell *matHeaderCellDef>
                    <T
                      str="Created at"
                      key="customerPortal.customer-portal.marketplace.valuations.createdAt"
                      tags="customer-portal, 3.1"
                    ></T>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div
                      class="mobile-label text-sm text-mat-ef-revolution-grey-two-500 w-[100px]"
                    >
                      <T
                        str="Created at"
                        key="customerPortal.customer-portal.marketplace.valuations.createdAt"
                        tags="customer-portal, 3.1"
                      ></T>
                    </div>
                    {{
                      element.dateCreated | date: 'mediumDate':'':currencyLocale
                    }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="bid">
                  <th id="Bid" mat-header-cell *matHeaderCellDef>
                    <T
                      str="Bid"
                      key="customerPortal.customer-portal.marketplace.valuations.bid"
                      tags="customer-portal, 3.1"
                    ></T>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div
                      class="mobile-label text-sm text-mat-ef-revolution-grey-two-500 w-[100px]"
                    >
                      <T
                        str="Bid"
                        key="customerPortal.customer-portal.marketplace.valuations.bid"
                        tags="customer-portal, 3.1"
                      ></T>
                    </div>
                    {{
                      element.bid
                        | currency: currency:'symbol':'1.2-2':currencyLocale
                    }}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; let i = index; columns: displayedColumns"
                  [ngClass]="i % 2 === 0 ? 'odd' : 'even'"
                ></tr>
              </table>

              <div class="mt-5">
                <button
                  mat-button
                  mat-raised-button
                  class="revolution float-right"
                  color="secondary"
                >
                  <a
                    [href]="marketPlaceUrl + v.vehicleData.uid"
                    target="_blank"
                    class="flex items-center"
                  >
                    <T
                      str="To the vehicle"
                      key="customerPortal.customer-portal.marketplace.tovehicle"
                      tags="customer-portal, 3.1"
                    ></T>
                    <mat-icon svgIcon="arrow_right"></mat-icon>
                  </a>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
        <mat-divider class="relative w-full"></mat-divider>
      </ng-container>
    </div>
  </div>
</div>
