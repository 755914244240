import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { LocalStorageService } from './local-storage.service'
import { EfOneSignalService } from '@inside-hub-app/ef-onesignal'

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {
  isSubscribed = false
  appId: string
  oneSignalDebug: boolean
  forceSubscription

  constructor (
    private readonly logger: NGXLogger,
    private readonly router: Router,
    private readonly localStorage: LocalStorageService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>,
    private readonly efOneSignalService: EfOneSignalService
  ) {
    this.appId = this.remoteConfigService.get('oneSignal.appId')
    this.oneSignalDebug = this.remoteConfigService.get('oneSignal.debug')
    this.forceSubscription = this.remoteConfigService.get(
      'oneSignal.forceSubscription'
    )
  }

  init (): void {
    // this is only set to true during cypress test, will not prevent notifications
    const hideOnesignalNotificationPrompt =
        this.localStorage.getHideOnesignalNotificationPrompt()
    if (hideOnesignalNotificationPrompt === 'true') {
      this.logger.info('cypress run detected')
      return
    }
    this.efOneSignalService.init({
      appId: this.appId,
      notificationClickHandlerMatch: 'exact',
      notificationClickHandlerAction: 'navigation',
      autoResubscribe: true,
      allowLocalhostAsSecureOrigin: true,
      path: '../assets/onesignal/OneSignalSDKWorker.js',
      serviceWorkerParam: {
        scope: '/assets/onesignal'
      },
      serviceWorkerUpdaterPath:
            '../assets/onesignal/OneSignalSDKUpdaterWorker.js',
      debug: this.oneSignalDebug
    }).then((success) => {
      this.checkSubscription()
    }).catch((err) => {
      this.logger.error(err)
    })
  }

  navigateRoute (data): void {
    if (data != null) {
      this.logger.info(data)
      const link = data != null ? `/${String(data.link)}` : ''
      void this.router.navigate([link])
    }
  }

  checkSubscription (): void {
    this.efOneSignalService
      .checkSubscription()
      .then(enabled => {
        if (enabled) {
          this.logger.info('Push notifications are enabled!')
          this.efOneSignalService.optIn().catch((err) => {
            this.logger.error(err)
          })
          this.isSubscribed = true
          this.efOneSignalService.onIncomingPushNotification.subscribe((notification) => {
            const data = notification.additionalData
            this.navigateRoute(data)
          })
        } else {
          this.logger.info('Push notifications are not enabled yet.')
          if (this.forceSubscription) {
            this.efOneSignalService.showNotificationPrompt({
              force: true
            }).catch((err) => {
              this.logger.error(err)
            })
          }
        }
      })
      .catch(err => {
        this.logger.info(err)
      })
  }
}
