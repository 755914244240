import { CommonModule } from '@angular/common'
import { NgModule, Optional } from '@angular/core'
import { HubConfigurationService } from '@inside-hub-app/hub-configuration'
import { NGXLogger } from 'ngx-logger'

@NgModule({
  imports: [CommonModule]
})
export class HubStorageModule {
  constructor(
    private readonly logger: NGXLogger,
    @Optional() private readonly hubConfigurationService?: HubConfigurationService
  ) {
    this.hubConfigurationService?.setLoggerLevel(this.logger)
  }
}
