import { Component, Inject, type OnInit } from '@angular/core'
import { type MatCheckboxChange } from '@angular/material/checkbox'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from '../../../../services/shared.service'
import { DataService } from '../../../../services/data.service'
import {
  type DealerDTO,
  DealersService,
  type SearchDealerSellerResponse
} from '../../../../services/dealers.service'
import {
  type VehicleDealersDTO,
  type VehicleDTOExtended,
  VehiclesService
} from '../../../../services/vehicles.service'
import { ConfirmationPopupComponent } from '../../../basic/confirmation-popup/confirmation-popup.component'
import { RevolutionSearchDealerSellerPopupComponent } from '../revolution-search-dealer-seller-popup/revolution-search-dealer-seller-popup.component'
import {
  EcondaService,
  MatomoIntegrationService,
  TransifexService,
  PwaService
} from '@inside-hub-app/customer-portal-shared'
import { InstantErrorStateMatcherService } from '../../../../services/instant-error-state-matcher.service'
import { SelectOptionsPopupComponent } from '../../../basic/select-options-popup/select-options-popup.component'

interface DialogData {
  vehicle: VehicleDTOExtended
  vehicleDealers: VehicleDealersDTO
  type: 'sales' | 'service'
}

@Component({
  selector: 'customer-portal-app-revolution-change-dealer-seller-popup',
  templateUrl: './revolution-change-dealer-seller-popup.component.html'
})
export class RevolutionChangeDealerSellerPopupComponent implements OnInit {
  vehicleDealers: VehicleDealersDTO
  dealers: DealerDTO[] = []
  dealersHOLD: DealerDTO[] = []
  salesDealer: DealerDTO
  serviceDealer: DealerDTO
  vehicle: VehicleDTOExtended
  sameDealerAndService = true
  saving = false
  dealersLoading = true
  dealersLoadingError = false
  dealerSellerForm = new FormGroup({
    carDealer: new FormControl<DealerDTO | null>(null, Validators.required),
    carService: new FormControl<DealerDTO | null>(null, Validators.required),
    sameDealerAndService: new FormControl<boolean | null>(null)
  })

  // all dealers
  allDealersLoading = true
  allDealersLoadingError = false
  allDealers: DealerDTO[] = []

  // autocomplete
  filteredAllDealers: DealerDTO[] = []
  filteredDealers: DealerDTO[] = []
  matcher = new InstantErrorStateMatcherService()

  isMobile
  constructor (
    private readonly dealersService: DealersService,
    private readonly vehicleService: VehiclesService,
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<RevolutionChangeDealerSellerPopupComponent>,
    private readonly logger: NGXLogger,
    private readonly dataService: DataService,
    public readonly sharedService: SharedService,
    public transifexService: TransifexService,
    private readonly econdaService: EcondaService,
    private readonly matomoIntegrationService: MatomoIntegrationService,
    private readonly pwaService: PwaService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.isMobile = this.pwaService.checkMobile()
  }

  ngOnInit (): void {
    this.vehicleDealers = this.data.vehicleDealers
    this.vehicle = this.data.vehicle
    this.dealerSellerForm
      .get('sameDealerAndService')
      .setValue(this.sameDealerAndService)

    this.salesDealer = this.vehicleDealers.salesDealer

    // No dealer case
    if (
      this.vehicleDealers.serviceDealer == null &&
      this.vehicleDealers.salesDealer == null
    ) {
      this.sameDealerAndService = true
    } else {
      // Has service dealer
      if (this.vehicleDealers.serviceDealer != null) {
        this.serviceDealer = this.vehicleDealers.serviceDealer
        this.dealerSellerForm.get('carDealer').setValue(this.salesDealer)
        this.dealerSellerForm.get('carService').setValue(this.serviceDealer)
        // same service and sales dealer
        if (this.salesDealer.id === this.serviceDealer.id) {
          this.sameDealerAndService = true
          this.dealerSellerForm.get('carService').setValue(this.serviceDealer)
          this.dealerSellerForm
            .get('sameDealerAndService')
            .setValue(this.sameDealerAndService)
        }
        // Does not have service
      } else {
        this.sameDealerAndService = true
        this.dealerSellerForm.get('carDealer').setValue(this.salesDealer)
        this.dealerSellerForm
          .get('sameDealerAndService')
          .setValue(this.sameDealerAndService)
      }
    }

    this.loadDealers()
    this.loadAllDealers()

    this.dealerSellerForm
      .get('carDealer')
      .valueChanges.subscribe((value: any) => {
        this.filteredAllDealers = this.sharedService.filterDealers(
          value,
          this.allDealers
        )
        this.filteredDealers = this.sharedService.filterDealers(
          value,
          this.dealers
        )
      })
    this.dealerSellerForm
      .get('carService')
      .valueChanges.subscribe((value: any) => {
        this.filteredAllDealers = this.sharedService.filterDealers(
          value,
          this.allDealers
        )
        this.filteredDealers = this.sharedService.filterDealers(
          value,
          this.dealers
        )
      })

    this.matomoIntegrationService.trackEvent(
      'Vehicle action',
      'Change preffered dealer click',
      this.matomoIntegrationService.formatVehicleData(this.vehicle)
    )
  }

  addToOfficialDealer (): void {
    this.dealers = this.sharedService.deepCopy(this.dealersHOLD)
    const sales = this.dealerSellerForm.controls.carDealer.value
    const service = this.dealerSellerForm.controls.carService.value

    if (
      sales?.id != null &&
      this.dealers.find(el => {
        return el != null
          ? el.id === sales.id ||
              (el.efitId != null && el.efitId === sales.efitId)
          : false
      }) == null
    ) {
      if (this.dealers == null) {
        this.dealers = []
      }
      this.dealersLoadingError = false
      this.dealers.push(sales)
    }

    if (
      service?.id != null &&
      this.dealers.find(el => {
        return el != null
          ? el.id === service.id ||
              (el.efitId != null && el.efitId === service.efitId)
          : false
      }) == null
    ) {
      if (this.dealers == null) {
        this.dealers = []
      }
      this.dealersLoadingError = false
      this.dealers.push(service)
    }

    this.dealers = this.sharedService.sortDealers(this.dealers)
    this.filteredDealers = this.sharedService.deepCopy(this.dealers)
  }

  saveIsDisabled (): boolean {
    let formInvalid = false
    if (this.sameDealerAndService) {
      formInvalid = this.dealerSellerForm.controls.carDealer.invalid
    } else {
      formInvalid =
        this.dealerSellerForm.controls.carDealer.invalid ||
        this.dealerSellerForm.controls.carService.invalid
    }
    return this.saving || this.dealersLoading || formInvalid
  }

  onDealerChangeClick (
    type: 'sales' | 'service',
    selectedDealer: DealerDTO
  ): void {
    const dialog = this.dialog.open(
      RevolutionSearchDealerSellerPopupComponent,
      {
        data: {
          brand: this.vehicle.brand,
          dealers: this.dealers,
          selectedDealer
        },
        panelClass: ['mat-dialog-cpt', 'find-dealer', 'no-lr-padding']
      }
    )
    dialog
      .afterClosed()
      .subscribe((searchDealerSellerResponse: SearchDealerSellerResponse) => {
        const selectedDealer = searchDealerSellerResponse?.selectedDealer
        if (selectedDealer != null) {
          this.dealersLoadingError = false
          if (type === 'sales') {
            this.dealerSellerForm.get('carDealer').setValue(selectedDealer)
          }
          if (type === 'service') {
            this.dealerSellerForm.get('carService').setValue(selectedDealer)
          }
          this.addToOfficialDealer()
        }
      })
  }

  loadDealers (): void {
    this.dealersLoading = true
    this.dealersService.getBrandDealers(this.vehicle.brand, true).subscribe(
      value => {
        this.dealersLoading = false
        this.dealersHOLD = this.sharedService.sortDealers(value)
        // add to array currently selected dealer
        this.addToOfficialDealer()
      },
      () => {
        this.dealersLoading = false
        this.dealersLoadingError = true
        this.dealersHOLD = []
        this.addToOfficialDealer()
      }
    )
  }

  loadAllDealers (): void {
    this.allDealersLoading = true
    this.dealersService.getBrandDealers(null, true).subscribe(
      value => {
        this.allDealersLoading = false
        this.allDealers = this.sharedService.sortDealers(value)
        this.filteredAllDealers = this.sharedService.deepCopy(this.allDealers)
      },
      () => {
        this.allDealersLoading = false
        this.allDealersLoadingError = true
      }
    )
  }

  compareDealer (dealer1: DealerDTO, dealer2: DealerDTO): boolean {
    return dealer1 != null && dealer2 != null && dealer1?.id === dealer2?.id
  }

  setSameDealer (event: MatCheckboxChange): void {
    this.sameDealerAndService = event.checked
    if (this.sameDealerAndService) {
      // clear service dealer
      this.dealerSellerForm.get('carService').setValue(undefined)
    }
    this.addToOfficialDealer()
  }

  saveChanges (): void {
    this.saving = true
    const vin = this.vehicle.vin
    const salesDealer = this.dealerSellerForm.get('carDealer').value
    let serviceDealer = this.dealerSellerForm.get('carService').value
    if (this.sameDealerAndService || serviceDealer.id == null) {
      serviceDealer = salesDealer
    }
    this.vehicleService
      .updatePrefferedVehicleDealers(vin, salesDealer.id, serviceDealer.id)
      .subscribe(
        response => {
          this.dialog.open(ConfirmationPopupComponent, {
            data: {
              title: 'shared.successful',
              text: 'customerPortal.customer-portal.change-dealer-seller.success'
            },
            panelClass: 'mat-dialog-cpt'
          })
          this.dialogRef.close(response)
          const v = this.vehicle
          void this.dataService.loadVehicleData(v)
        },
        error => {
          this.dialog.open(ConfirmationPopupComponent, {
            data: {
              title: 'shared.successful',
              text: 'customerPortal.customer-portal.change-dealer-seller.failure'
            },
            panelClass: 'mat-dialog-cpt'
          })
          this.dialogRef.close(false)
          this.logger.debug(error)
        }
      )
  }

  openSelectOptionsPopup (ev, checkbox, type: 'sales' | 'service'): void {
    this.sharedService.preventEventPropagation(ev)
    const dialog = this.dialog.open(
      SelectOptionsPopupComponent,
      {
        data: {
          selectedDealer: type === 'sales' ? this.dealerSellerForm.get('carDealer').value : this.dealerSellerForm.get('carService').value,
          dealers: checkbox?.checked === true ? this.filteredAllDealers : this.filteredDealers,
          salesDealer: this.salesDealer,
          serviceDealer: this.serviceDealer,
          type: 'dealer',
          dealerType: type
        },
        panelClass: ['mat-dialog-cpt', 'select-options']
      }
    )
    dialog
      .afterClosed()
      .subscribe((dialogData: any) => {
        const selectedDealer = dialogData?.selected
        if (selectedDealer != null) {
          if (type === 'sales') {
            this.dealerSellerForm.get('carDealer').setValue(selectedDealer)
          }
          if (type === 'service') {
            this.dealerSellerForm.get('carService').setValue(selectedDealer)
          }
          this.addToOfficialDealer()
        }
      })
  }
}
