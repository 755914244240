import { Component } from '@angular/core'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import {
  CustomerPortalConfig,
  B2CConfig
} from '@inside-hub-app/customer-portal-config'
import { CptGoogleTagmanagerService } from '../../../../services/cpt-google-tagmanager.service'
@Component({
  selector: 'customer-portal-app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent {
  links

  constructor(
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly remoteConfigService: EfRemoteConfigurationService<
      CustomerPortalConfig | B2CConfig
    >
  ) {
    this.links = {
      youtube: this.remoteConfigService.get('revolution.links.social.youtube'),
      insta: this.remoteConfigService.get('revolution.links.social.insta'),
      facebook: this.remoteConfigService.get('revolution.links.social.facebook'),
      linkedin: this.remoteConfigService.get('revolution.links.social.linkedin')
    }
  }

  sendGTMNavigationData(label?: string): void {
    this.cptGtmService.sendNavigationLinkData(null, 'Footer Navigation', label)
  }
}
