import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { SharedService } from '../../../../services/shared.service'
import {
  ReminderChannelDTO,
  VehicleReminderDTO,
  VehicleRemindersService
} from '../../../../services/vehicle-reminders.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'

@Component({
  selector: 'customer-portal-app-revolution-vehicle-reminders',
  templateUrl: './vehicle-reminders.component.html'
})
export class VehicleRemindersComponent implements OnInit, OnDestroy {
  @Input()
  public vehicle: VehicleDTOExtended

  loading = true
  vehicleRemindersOld: VehicleReminderDTO[] = []
  vehicleReminders: VehicleReminderDTO[] = []
  channels: ReminderChannelDTO[] = []

  selectedReminder: VehicleReminderDTO

  sub = {
    vehicleReminders: null,
    updatingVehicleReminders: null,
    onVehicleRemindersLoaded: null,
    channels: null,
    onChannelsLoaded: null,
    onVehicleReminderChanged: null
  }

  loadingVehicleReminders: boolean
  updatingVehicleReminders: boolean
  loadingChannels: boolean

  reminderTypesSVG = {
    'insurance-end-reminder': 'car_return',
    'warranty-end-reminder': 'car_insurance',
    'road-tax-reminder': 'monetization_on',
    'tüv-reminder': 'clipboard_check'
  }

  vehicleReminderTypes: string[]
  constructor (
    private readonly vehicleRemindersService: VehicleRemindersService,
    private readonly sharedService: SharedService
  ) {}

  ngOnInit (): void {
    this.sub.vehicleReminders =
      this.vehicleRemindersService.loading.vehicleReminders.subscribe(
        loading => {
          this.loadingVehicleReminders = loading
          this.setLoading()
        }
      )
    this.sub.updatingVehicleReminders =
      this.vehicleRemindersService.loading.updatingVehicleReminders.subscribe(
        loading => {
          this.updatingVehicleReminders = loading
          this.setLoading()
        }
      )
    this.sub.channels = this.vehicleRemindersService.loading.channels.subscribe(
      loading => {
        this.loadingChannels = loading
        this.setLoading()
      }
    )
    this.sub.onVehicleRemindersLoaded =
      this.vehicleRemindersService.onVehicleRemindersLoaded.subscribe(data => {
        this.vehicleReminders = data
        this.setLoading()
      })
    this.sub.onChannelsLoaded =
      this.vehicleRemindersService.onChannelsLoaded.subscribe(data => {
        this.channels = data
        this.setLoading()
      })
  }

  setLoading (): void {
    this.loading = this.loadingVehicleReminders || this.loadingChannels
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }
}
