/* eslint-disable no-async-promise-executor */
import { Injectable } from '@angular/core'
import { NGXLogger } from 'ngx-logger'
import { forkJoin } from 'rxjs'
import { DealersService } from './dealers.service'
import { GalleryService, VehicleGalleryDTO } from './gallery.service'
import { LocalStorageService } from './local-storage.service'
import { RecallDTO, RecallsService } from './recalls.service'
import { ServicePackageService } from './service-package.service'
import {
  TiresAppointmentDTO,
  TiresService,
  TiresStockDTO
} from './tires.service'
import {
  BasicDocumentDTOExtended,
  VehicleDocumentsService,
  VehiclePromotionDocumentDTOExtended
} from './vehicle-documents.service'

import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { LeasingService } from './leasing.service'
import { SharedService } from './shared.service'
import {
  VehicleReminderDTO,
  VehicleRemindersService
} from './vehicle-reminders.service'
import {
  VehicleDealersDTO,
  VehicleDTOExtended,
  VehicleFrequentDriverDTO,
  VehiclesService
} from './vehicles.service'
import { WarrantyService, WarrantySP } from './warranty.service'
import {

  VehicleInsuranceDTO,
  InsuranceService
} from '@inside-hub-app/customer-portal-shared'
@Injectable({
  providedIn: 'root'
})
export class VehiclesInitialLoadService {
  private readonly hasPromotions

  constructor (
    private readonly vehiclesService: VehiclesService,
    private readonly vehicleDocumentsService: VehicleDocumentsService,
    private readonly galleryService: GalleryService,
    private readonly leasingService: LeasingService,
    private readonly tiresService: TiresService,
    private readonly servicePackageService: ServicePackageService,
    private readonly warrantyService: WarrantyService,
    private readonly recallsService: RecallsService,
    private readonly localStorage: LocalStorageService,
    private readonly dealersService: DealersService,
    private readonly vehicleRemindersService: VehicleRemindersService,
    private readonly logger: NGXLogger,
    private readonly sharedService: SharedService,
    private readonly insuranceService: InsuranceService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasPromotions = this.remoteConfigService.get('hasPromotions')
  }

  public async loadVehiclesData (vehicles: VehicleDTOExtended[]): Promise<void> {
    await this.loadAllBrandsDealers()

    if (vehicles?.[0] != null) {
      for (const vehicle of vehicles) {
        await this.loadVehicleData(vehicle)
      }
    }
  }

  private async loadVehicleData (vehicle: VehicleDTOExtended): Promise<boolean> {
    return await new Promise(resolve => {
      (async () => {
        let vehicleData
        try {
          vehicleData = await this.localStorage.getVehicleByVin(vehicle.vin)
        } catch (error) {
          this.logger.debug(error)
        }
        const lang = this.sharedService.currentLanguage()
        // if the vehicle doesnt have anything in relations - the data was not previousley loaded
        if (
          !(
            vehicleData != null && Object.keys(vehicleData.relations).length > 0
          )
        ) {
          forkJoin([
            this.getFrequentDriver(vehicle), // Frequent driver
            this.getVehicleDetails(vehicle, lang), // Vehicle details
            this.getVehicleDealers(vehicle), // Dealers
            this.getVehiclePromotions(vehicle, lang), // Vehicle promotions
            this.getWarrantySP(vehicle, lang), // Warranty SP
            this.getDocuments(vehicle), // Vehicle documents
            this.getGalleries(vehicle), // Gallery documents
            this.tireAppointmentLink(vehicle), // Vehicle tire appointments
            this.getTires(vehicle), // Vehicle tires
            this.getRecallsForVehicle(vehicle, lang), // Recalls
            this.getVehicleReminders(vehicle), // Reminders
            this.getInsurance(vehicle) // insurance
          ]).subscribe(
            (
              value: [
                VehicleFrequentDriverDTO,
                VehicleDTOExtended,
                VehicleDealersDTO,
                VehiclePromotionDocumentDTOExtended[],
                WarrantySP,
                BasicDocumentDTOExtended[],
                VehicleGalleryDTO[],
                TiresAppointmentDTO,
                TiresStockDTO,
                RecallDTO,
                VehicleReminderDTO[],
                VehicleInsuranceDTO
              ]
            ) => {
              const vin = vehicle.vin

              void this.localStorage.setFrequentDriver(vin, value[0]) // Frequent driver
              void this.localStorage.setVehicleDetails(vin, value[1]) // Vehicle details
              void this.localStorage.setVehicleDealers(vin, value[2]) // Dealers
              void this.localStorage.setPromotions(vin, value[3]) // Vehicle promotions
              void this.localStorage.setServicePackage(
                vin,
                value[4]?.servicePackages != null
                  ? value[4]?.servicePackages
                  : null
              ) // Service Packages
              void this.localStorage.setWarranty(
                vin,
                value[4]?.warranty != null ? value[4]?.warranty : null
              ) // Warranty
              void this.localStorage.setVehicleDocuments(vin, value[5]) // Vehicle documents
              void this.localStorage.setGalleries(vin, value[6]) // Vehicle documents
              void this.localStorage.setTiresAppintment(vin, value[7]) // Vehicle tire appointments
              void this.localStorage.setTires(vin, value[8]) // Vehicle tires
              void this.localStorage.setRecalls(vin, value[9]) // Recalls
              void this.localStorage.setReminders(vin, value[10]) // Reminders
              void this.localStorage.setInsurance(vin, value[11]) // Insurance
              resolve(true)
            }
          )
        } else {
          resolve(false)
        }
      })().catch(() => resolve(false))
    })
  }

  private async getFrequentDriver (
    vehicle: VehicleDTOExtended
  ): Promise<VehicleFrequentDriverDTO> {
    return await new Promise(resolve => {
      this.vehiclesService
        .getFrequentDriver(
          vehicle.id,
          vehicle.business === true ? vehicle.ownershipId : null
        )
        .subscribe(
          value => {
            let fqd = null
            if (this.sharedService.objectHasKeys(value)) {
              fqd = value
            }
            resolve(fqd)
          },
          () => {
            resolve(null)
          }
        )
    })
  }

  private async getVehicleDetails (vehicle, lang): Promise<VehicleDTOExtended> {
    return await new Promise(resolve => {
      this.vehiclesService
        .getVehicleDetails(vehicle.vin, false, lang)
        .subscribe(
          value => {
            resolve(value)
          },
          () => {
            resolve(null)
          }
        )
    })
  }

  private async getVehicleDealers (
    vehicle: VehicleDTOExtended
  ): Promise<VehicleDealersDTO> {
    return await new Promise(resolve => {
      this.vehiclesService
        .getVehicleDealers(vehicle.vin, true, true, true)
        .subscribe(
          value => {
            resolve(value)
          },
          () => {
            resolve(null)
          }
        )
    })
  }

  private async getVehiclePromotions (
    vehicle: VehicleDTOExtended,
    lang
  ): Promise<VehiclePromotionDocumentDTOExtended[]> {
    return await new Promise(resolve => {
      if (this.hasPromotions) {
        this.vehicleDocumentsService
          .getVehiclePromotions(vehicle.vin, lang)
          .subscribe(
            value => {
              resolve(value)
            },
            () => {
              resolve(null)
            }
          )
      } else {
        resolve(null)
      }
    })
  }

  private async getWarrantySP (
    vehicle: VehicleDTOExtended,
    lang
  ): Promise<WarrantySP> {
    return await new Promise(resolve => {
      if (
        vehicle.createdByDataSource === 'customer_portal' ||
        !(
          this.remoteConfigService.get('features.warranty') ||
          this.remoteConfigService.get('features.servicePackage')
        )
      ) {
        resolve(null)
      } else {
        this.warrantyService.getWarrantySP(vehicle.vin, lang).subscribe(
          value => {
            resolve(value)
          },
          () => {
            resolve(null)
          }
        )
      }
    })
  }

  private async getVehicleReminders (
    vehicle: VehicleDTOExtended
  ): Promise<VehicleReminderDTO[]> {
    return await new Promise(resolve => {
      if (!this.sharedService.showRemindersForVehicle(vehicle)) {
        resolve(null)
      } else {
        this.vehicleRemindersService.getVehicleReminders(vehicle.vin).subscribe(
          value => {
            resolve(value)
          },
          () => {
            resolve(null)
          }
        )
      }
    })
  }

  private async getDocuments (
    vehicle: VehicleDTOExtended
  ): Promise<BasicDocumentDTOExtended[]> {
    return await new Promise(resolve => {
      this.vehicleDocumentsService.getDocuments(vehicle.vin).subscribe(
        value => {
          resolve(value)
        },
        () => {
          resolve(null)
        }
      )
    })
  }

  private async getGalleries (
    vehicle: VehicleDTOExtended
  ): Promise<VehicleGalleryDTO[]> {
    return await new Promise(resolve => {
      this.galleryService.getGalleries(vehicle.vin).subscribe(
        value => {
          resolve(value)
        },
        () => {
          resolve(null)
        }
      )
    })
  }

  /**
   * 
   * now it covers all services, not only tires
   */
  private async tireAppointmentLink (
    vehicle: VehicleDTOExtended
  ): Promise<TiresAppointmentDTO> {
    return await new Promise(resolve => {
      if (vehicle.createdByDataSource === 'customer_portal'
        || this.remoteConfigService.get('hasServiceManager') !== true) {
        resolve(null)
      } else {
        this.tiresService.tireAppointmentLink(vehicle.vin).subscribe(
          value => {
            let tire = null
            if (this.sharedService.objectHasKeys(value)) {
              tire = value
            }
            resolve(tire)
          },
          () => {
            resolve(null)
          }
        )
      }
    })
  }

  private async getTires (vehicle: VehicleDTOExtended): Promise<TiresStockDTO> {
    return await new Promise(resolve => {
      if (vehicle.createdByDataSource === 'customer_portal') {
        resolve(null)
      } else {
        this.tiresService.getTires(vehicle.vin).subscribe(
          value => {
            resolve(value)
          },
          () => {
            resolve(null)
          }
        )
      }
    })
  }

  private async getRecallsForVehicle (
    vehicle: VehicleDTOExtended,
    lang
  ): Promise<RecallDTO> {
    return await new Promise(resolve => {
      if (
        vehicle.createdByDataSource === 'customer_portal' ||
        !this.remoteConfigService.get('features.recalls')
      ) {
        resolve(null)
      } else {
        this.recallsService.getRecallsForVehicle(vehicle.vin, lang).subscribe(
          value => {
            resolve(value)
          },
          () => {
            resolve(null)
          }
        )
      }
    })
  }

  private async loadAllBrandsDealers (): Promise<boolean> {
    return await new Promise(resolve => {
      (async () => {
        // get data from local storage
        let brands, dealers
        try {
          brands = await this.localStorage.getAllBrands()
        } catch (error) {
          this.logger.debug(error)
        }
        try {
          dealers = await this.localStorage.getAllDealers()
        } catch (error) {
          this.logger.debug(error)
        }

        if (!(brands != null && dealers != null)) {
          forkJoin([
            this.vehiclesService.getBrands(),
            this.dealersService.getDealers()
          ]).subscribe(
            value => {
              if (value?.[0] != null) {
                void this.localStorage.setAllBrands(value[0])
              }
              if (value?.[1] != null) {
                void this.localStorage.setAllDealers(value[1])
              }
              resolve(true)
            },
            () => {
              resolve(false)
            }
          )
        } else {
          resolve(false)
        }
      })().catch(() => resolve(false))
    })
  }

  private async getInsurance (
    vehicle: VehicleDTOExtended
  ): Promise<VehicleInsuranceDTO> {
    return await new Promise(resolve => {
      if (
        this.remoteConfigService.get('hasInsurance') !== true
      ) {
        resolve(null)
      } else {
        this.insuranceService
          .getInsurance(vehicle.vin).subscribe(
            value => {
              resolve(value)
            },
            () => {
              resolve(null)
            }
          )
      }
    })
  }
}
