<div class="w-full h-full DealerInfoComponent-revolution ef-scrollbar">
  <span class="heading-small block" *ngIf="!showWorkingHours">
    <T
      str="Your dealer"
      key="customerPortal.customer-portal.top-bar.appointments.upcoming.yourdealer"
      tags="customer-portal, 3.1"
    ></T>
  </span>
  <div class="relative">
    <customer-portal-agm-map
      *ngIf="showMap && lat && lng"
      #map
      [zoom]="zoom"
      [longitude]="lng"
      [latitude]="lat"
      [markDraggable]="false"
      [height]="'240px'"
      class="h-[240px]"
    >
    </customer-portal-agm-map>
  </div>
  <div
    class="pt-5 grid grid-cols-1 md:grid-cols-2 font-normal text-sm overflow-auto max-h-[calc(100%_-_240px)] gap-0"
  >
    <div *ngIf="showWorkingHours">
      <div class="uppercase font-bold">
        <T
          str="Place"
          key="customerPortal.customer-portal.dealer-details.location"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div class="inline-flex">
        <mat-icon class="align-middle mr-2.5" svgIcon="location_on"></mat-icon>
        <p class="mb-4 text-mat-ef-primary-500">
          {{ dealer.street ? dealer.street : '' }},
          {{ dealer.zip ? dealer.zip : '' }}
          {{ dealer.city ? dealer.city : '' }}
        </p>
      </div>

      <ng-container>
        <button
          mat-button
          mat-flat-button
          color="secondary"
          class="revolution w-full md:w-fit text-base h-12 px-8 mt-6"
          (click)="showDirection()"
        >
          <mat-icon
            svgIcon="directions"
            class="align-middle mr-2.5 text-xl"
          ></mat-icon>
          <ng-container *ngTemplateOutlet="directions"></ng-container>
        </button>
      </ng-container>
    </div>
    <div *ngIf="!showWorkingHours">
      <div class="location">
        <mat-icon class="primary" svgIcon="location_on"></mat-icon>
        <p>
          {{ dealer.name }}{{ dealer.subname ? ', ' + dealer.subname : '' }}
        </p>
      </div>
      <div class="text-black">
        <span
          >{{ dealer.street ? dealer.street : '' }},
          {{ dealer.zip ? dealer.zip : '' }}
          {{ dealer.city ? dealer.city : '' }}
        </span>
      </div>
      <button
        class="mt"
        mat-button
        mat-flat-button
        color="primary"
        (click)="showDirection(); sendOutboundLinkData('Routefinder', dealer)"
      >
        <mat-icon svgIcon="directions"></mat-icon>
        <ng-container *ngTemplateOutlet="directions"></ng-container>
      </button>
    </div>
    <div class="data-contact">
      <div class="uppercase font-bold" *ngIf="showWorkingHours">
        {{ 'contact' | sharedTranslate }}
      </div>
      <p
        class="mb-2 text-mat-ef-primary-500"
        (click)="sendOutboundLinkData('Phone click', dealer)"
      >
        <mat-icon class="align-middle mr-2.5" svgIcon="phone"></mat-icon
        ><a href="tel:{{ dealer.telephone }}" data-rel="external">{{
          dealer.telephone
        }}</a>
      </p>
      <p
        class="mb-2 text-mat-ef-primary-500"
        (click)="sendOutboundLinkData('Email click', dealer)"
      >
        <mat-icon class="align-middle mr-2.5" svgIcon="email"></mat-icon>
        <a href="mailto:{{ dealer.email }}" data-rel="external">{{
          dealer.email
        }}</a>
      </p>
      <div class="data-hours" *ngIf="showOpeningTimeInContact">
        <div
          class="flex my-[14px]"
          *ngIf="
            dealer &&
            dealer.openingHours &&
            dealer.openingHours.service.length > 0
          "
        >
          <div class="ml-1" *ngIf="openServices">
            <div
              class="float-left w-2.5 h-2.5 m-[5px] rounded-md bg-mat-ef-green-secondary-500 p-0.5"
            ></div>
            <span
              ><ng-container *ngTemplateOutlet="nowOpen"></ng-container>
              <ng-container *ngIf="serviceWorkingHours.day">
                (
                <ng-container
                  *ngTemplateOutlet="
                    dayTranslation;
                    context: { day: serviceWorkingHours.day }
                  "
                ></ng-container>
              </ng-container>
              {{ serviceWorkingHours.dateToday + ' )' }}</span
            >
          </div>

          <div class="ml-1" *ngIf="!openServices">
            <div
              class="float-left w-2.5 h-2.5 m-[5px] rounded-md bg-red-500 p-0.5"
            ></div>
            <span>
              <ng-container *ngTemplateOutlet="nowClosed"></ng-container>
              <ng-container *ngIf="day">
                (
                <ng-container
                  *ngTemplateOutlet="dayTranslation; context: { day: day }"
                ></ng-container>
              </ng-container>
              {{
                serviceWorkingHours.dateToday
                  ? serviceWorkingHours.dateToday + ' )'
                  : '
                            -)'
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="data-hours" *ngIf="showWorkingHours">
      <div
        class="flex my-[14px]"
        *ngIf="
          dealer && dealer.openingHours && dealer.openingHours.sales.length > 0
        "
      >
        <div class="uppercase font-bold">
          {{ 'sale' | sharedTranslate }}
        </div>
        <div class="ml-1" *ngIf="openSales">
          <div
            class="float-left w-2.5 h-2.5 m-[5px] rounded-md bg-mat-ef-green-secondary-500 p-0.5"
          ></div>
          <span>
            <ng-container *ngTemplateOutlet="nowOpen"></ng-container
          ></span>
        </div>
        <div class="ml-1" *ngIf="!openSales">
          <div
            class="float-left w-2.5 h-2.5 m-[5px] rounded-md bg-red-500 p-0.5"
          ></div>
          <span
            ><ng-container *ngTemplateOutlet="nowClosed"></ng-container
          ></span>
        </div>
      </div>
      <div
        class="inline-flex"
        *ngIf="
          dealer && dealer.openingHours && dealer.openingHours.sales.length > 0
        "
      >
        <mat-icon class="w-[32px]" svgIcon="watch_later"></mat-icon>
        <div class="w-auto">
          <div
            class="grid grid-cols-hours-grid"
            *ngFor="let day of workingDaysSales"
            [ngClass]="{ hidden: day.hours.length === 0 }"
          >
            <p
              class="p-0"
              *ngIf="day.hours.length === 1 && day.hours[0] !== ''"
            >
              <ng-container
                *ngTemplateOutlet="dayTranslation; context: { day: day.day }"
              ></ng-container
              >:
            </p>
            <div *ngIf="day.hours.length === 1 && day.hours[0] !== ''">
              {{ day.hours[0] }}
              <ng-container *ngTemplateOutlet="time"></ng-container>
            </div>
            <p
              class="p-0"
              *ngIf="
                day.hours.length > 1 &&
                day.hours[0] !== '' &&
                day.hours[1] !== ''
              "
            >
              <ng-container
                *ngTemplateOutlet="dayTranslation; context: { day: day.day }"
              ></ng-container>
              :
            </p>
            <div
              *ngIf="
                day.hours.length > 1 &&
                day.hours[0] !== '' &&
                day.hours[1] !== ''
              "
            >
              {{ day.hours[0] }}
              <ng-container *ngTemplateOutlet="time"></ng-container>
            </div>
            <div></div>
            <div
              *ngIf="
                day.hours.length > 1 &&
                day.hours[0] !== '' &&
                day.hours[1] !== ''
              "
            >
              {{ day.hours[1] }}
              <ng-container *ngTemplateOutlet="time"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="data-hours" *ngIf="showWorkingHours">
      <div
        class="flex my-[14px]"
        *ngIf="
          dealer &&
          dealer.openingHours &&
          dealer.openingHours.service.length > 0
        "
      >
        <div class="uppercase font-bold">
          {{ 'service' | sharedTranslate }}
        </div>
        <div class="ml-1" *ngIf="openServices">
          <div
            class="float-left w-2.5 h-2.5 m-[5px] rounded-md bg-mat-ef-green-secondary-500 p-0.5"
          ></div>
          <span>
            <ng-container *ngTemplateOutlet="nowOpen"></ng-container
          ></span>
        </div>
        <div class="ml-1" *ngIf="!openServices">
          <div
            class="float-left w-2.5 h-2.5 m-[5px] rounded-md bg-red-500 p-0.5"
          ></div>
          <span>
            <ng-container *ngTemplateOutlet="nowClosed"></ng-container
          ></span>
        </div>
      </div>
      <div
        class="inline-flex"
        *ngIf="
          dealer &&
          dealer.openingHours &&
          dealer.openingHours.service.length > 0
        "
      >
        <mat-icon class="w-[32px]" svgIcon="watch_later"></mat-icon>
        <div class="w-auto">
          <div
            class="grid grid-cols-hours-grid"
            *ngFor="let day of workingDaysService"
            [ngClass]="{ hidden: day.hours.length === 0 }"
          >
            <p *ngIf="day.hours.length === 1 && day.hours[0] !== ''">
              <ng-container
                *ngTemplateOutlet="dayTranslation; context: { day: day.day }"
              ></ng-container>
              :
            </p>
            <div *ngIf="day.hours.length === 1 && day.hours[0] !== ''">
              {{ day.hours[0] }}
              <ng-container *ngTemplateOutlet="time"></ng-container>
            </div>
            <p *ngIf="day.hours.length > 1">
              <ng-container
                *ngTemplateOutlet="dayTranslation; context: { day: day.day }"
              ></ng-container
              >:
            </p>
            <div *ngIf="day.hours.length > 1">
              {{ day.hours[0] }}
              <ng-container *ngTemplateOutlet="time"></ng-container>
            </div>
            <div></div>
            <div *ngIf="day.hours.length > 1">
              {{ day.hours[1] }}
              <ng-container *ngTemplateOutlet="time"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #time>
  <T
    str="Clock"
    key="customerPortal.customer-portal.dealers.time"
    tags="customer-portal, 3.1"
  ></T>
</ng-template>

<ng-template #nowClosed>
  <T
    str="Now closed"
    key="customerPortal.customer-portal.vehicle.nowClosed"
    tags="customer-portal, 3.1"
  ></T>
</ng-template>

<ng-template #nowOpen>
  <T
    str="Now open"
    key="customerPortal.customer-portal.vehicle.nowOpen"
    tags="customer-portal, 3.1"
  ></T>
</ng-template>

<ng-template #directions>
  <T
    str="Directions"
    key="customerPortal.customer-portal.dealer-details.directions"
    tags="customer-portal, 3.1"
  ></T>
</ng-template>

<ng-template #dayTranslation let-day="day">
  <ng-container
    *ngTemplateOutlet="
      sharedService.template('transifexTranslation');
      context: {
        str: day,
        prefix: 'customerPortal.customer-portal.dealer-details.days.'
      }
    "
  ></ng-container>
</ng-template>
