<div
  *ngIf="menuType === 'editDeleteMenu'"
  class="flex justify-center items-center"
>
  <button
    data-cy="edit-delete-menu-button"
    class="text-mat-ef-primary-500 text-sm font-bold border-none bg-transparent cursor-pointer"
    [matMenuTriggerFor]="editDeleteMenu"
  >
    <mat-icon
      class="!text-mat-ef-revolution-secondary-500"
      svgIcon="more_vert"
    ></mat-icon>
  </button>
  <mat-menu #editDeleteMenu="matMenu">
    <button
      data-cy="edit-delete-menu-edit-button"
      class="h-[44px] hover:bg-mat-ef-revolution-grey-two-100"
      mat-menu-item
      (click)="onActionClicked('edit')"
    >
      <mat-icon svgIcon="create" class="align-middle"></mat-icon>
      {{ 'edit' | sharedTranslate }}
    </button>
    <mat-divider></mat-divider>
    <button
      data-cy="edit-delete-menu-delete-button"
      mat-menu-item
      class="mat-error delete h-[44px] hover:bg-mat-ef-revolution-grey-two-100"
      (click)="onActionClicked('delete')"
    >
      <mat-icon
        class="error !text-mat-ef-revolution-red-500 align-middle"
        svgIcon="delete"
      ></mat-icon>
      {{ 'clear' | sharedTranslate }}
    </button>
  </mat-menu>
</div>

<div *ngIf="menuType === 'fileMenu'" class="flex justify-center items-center">
  <button
    data-cy="file-menu-button"
    class="text-mat-ef-primary-500 text-sm font-bold border-none bg-transparent cursor-pointer"
    [matMenuTriggerFor]="fileMenu"
  >
    <mat-icon svgIcon="more_vert"></mat-icon>
  </button>
  <mat-menu #fileMenu="matMenu">
    <button
      data-cy="file-menu-download-button"
      class="h-[44px] hover:bg-mat-ef-revolution-grey-two-100"
      mat-menu-item
      (click)="onActionClicked('download')"
    >
      <mat-icon svgIcon="download"></mat-icon>
      <T
        str="Download"
        key="customerPortal.customer-portal.general.actions.download"
        tags="customer-portal, 3.1"
      ></T>
    </button>
    <button
      data-cy="file-menu-edit-button"
      class="h-[44px] hover:bg-mat-ef-revolution-grey-two-100"
      mat-menu-item
      (click)="onActionClicked('edit')"
    >
      <mat-icon svgIcon="create"></mat-icon>
      {{ 'edit' | sharedTranslate }}
    </button>
    <mat-divider></mat-divider>
    <button
      data-cy="file-menu-delete-button"
      mat-menu-item
      class="mat-error delete h-[44px] hover:bg-mat-ef-revolution-grey-two-100"
      (click)="onActionClicked('delete')"
    >
      <mat-icon
        class="error !text-mat-ef-revolution-red-500"
        svgIcon="delete"
      ></mat-icon>
      {{ 'clear' | sharedTranslate }}
    </button>
  </mat-menu>
</div>
