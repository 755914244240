<section
  class="block relative w-full z-[899]"
  #headerNav
  (mouseleave)="openCloseMenu()"
>
  <div
    class="hidden relative py-1 pr-[70px] pl-[55px] xl:flex justify-end bg-mat-ef-revolution-supergrey-100 text-[13px] font-efSans"
    *ngIf="navigationSub"
  >
    <div class="flex text-[13px] text-mat-ef-gray-one-200">
      <div class="contact-phones" [innerHTML]="navigationSub.context"></div>
      <a
        class="ml-6 text-mat-ef-gray-one-200 no-underline"
        *ngFor="let page of navigationSub.pages"
        [href]="getHref(page)"
      >
        {{ page.content.text }}
      </a>
    </div>
  </div>
  <div
    class="bg-white flex justify-between py-2.5 px-5 xl:px-[55px] items-center"
  >
    <customer-portal-app-navbar-back
      *ngIf="screenSize === 'mobile'"
    ></customer-portal-app-navbar-back>

    <a
      class="flex items-center logo cursor-pointer min-w-fit"
      *ngIf="header && header.header.logo"
      [href]="efWebsiteUrl"
      target="_blank"
    >
      <mat-icon
        svgIcon="ef-logo-icon"
        class="w-10 h-10 md:w-[52px] md:h-[52px] text-mat-ef-primary-500"
      ></mat-icon>
      <div class="text-sm pl-3 lg:text-base flex gap-1">
        <span class="font-efSans text-mat-ef-primary-500 font-bold">
          Emil Frey
        </span>
        <span class="font-efSans text-mat-ef-primary-500">
          <!-- prettier-ignore -->
          {{ country | translate:{ _key: 'customerPortal.customer-portal.country.' + country , _tags: 'customer-portal, notranslate'} }}
        </span>
      </div>
    </a>
    <div
      class="hidden xl:flex -translate-x-1/2 max-w-[1050px] absolute left-1/2 min-w-[620px] w-[620px] items-center"
      *ngIf="header"
    >
      <div class="flex items-center w-full max-w-[620px]">
        <div
          class="flex wrap justify-evenly w-full cursor-pointer"
          *ngFor="let item of header.navigationMain; index as i"
        >
          <a
            *ngIf="item.children && item.children.length > 0"
            class="flex uppercase font-efSans font-bold text-sm text-mat-ef-gray-one-300 items-center no-underline hover:underline"
            (mouseenter)="openCloseMenu(i)"
            (click)="
              openCloseMenu(i); sendGTMNavigationData(item.title, 'Textlink')
            "
            [ngClass]="activeStyle(i)"
            target="_blank"
          >
            <div class="w-full relative">
              <div class="w-full pr-[25px] whitespace-nowrap z-20">
                {{ item.title }}
              </div>
              <div class="absolute right-0 top-0 -z-10">
                <mat-icon
                  [svgIcon]="
                    (toggleMenu0 && i === 0) ||
                    (toggleMenu2 && i === 2) ||
                    (toggleMenu3 && i === 3)
                      ? 'expand_less'
                      : 'expand_more'
                  "
                >
                </mat-icon>
              </div>
            </div>
          </a>
          <a
            *ngIf="item.children && item.children.length < 1"
            class="flex uppercase font-efSans font-bold text-sm text-mat-ef-gray-one-300 items-center no-underline hover:underline"
            [href]="baseUrl + item.link"
            (click)="
              openCloseMenu(i); sendGTMNavigationData(item.title, 'Textlink')
            "
            target="_blank"
          >
            {{ item.title }}
          </a>
        </div>
      </div>
    </div>
    <div
      id="menu0"
      class="absolute min-w-full top-[100px] left-0 bg-white z-50 shadow-none"
      *ngIf="toggleMenu0"
      [@inOutAnimation]
    >
      <div class="grid px-[207px] pt-[132px] pb-[76px] mb-[76px] grid-cols-3">
        <div
          class="font-efSans text-lg font-semibold leading-6 text-left tracking-normal col-span-1"
          *ngIf="header && header.navigationMain"
        >
          <p
            class="font-canelablack-primary text-4xl font-bold tracking-normal text-left p-0 mb-9 leading-normal"
          >
            {{ header.navigationMain[0].title }}
          </p>
          <div *ngFor="let link of header.navigationMain[0].children">
            <a
              class="links no-underline text-mat-ef-primary-500 inline-flex items-center mb-6"
              *ngIf="link.link !== '/'"
              [href]="
                !link.link.startsWith('http') ? baseUrl + link.link : link.link
              "
              (click)="sendGTMNavigationData(link.title, 'Textlink')"
              target="_blank"
            >
              <div class="h-6 pr-4">
                <mat-icon
                  class="bg-mat-ef-primary-500 text-white rounded-full"
                  svgIcon="arrow_right"
                ></mat-icon>
              </div>
              {{ link.title }}
            </a>
          </div>
        </div>
        <!-- right -->
        <div class="col-span-2">
          <a
            *ngIf="
              offerFirstMenu &&
              offerFirstMenu.content.actions &&
              offerFirstMenu.content.actions[0]
            "
            [href]="
              baseUrl + offerFirstMenu.content.actions[0].content.link.url
            "
            target="_blank"
          >
            <div
              class="max-h-[288px] bg-no-repeat bg-cover h-full bg-center rounded-lg flex relative"
              *ngIf="offerFirstMenu"
              [style.background-image]="
                'url(' + offerFirstMenu.content.imageXl[0][0].publicUrl + ')'
              "
            >
              <div class="offer-brands"></div>
              <div class="absolute bottom-8 left-8">
                <p class="text-white font-bold text-sm mb-0 tracking-widest">
                  {{ offerFirstMenu.content.availability }}
                </p>
                <h4 class="text-white my-0 text-2xl">
                  {{ offerFirstMenu.content.headline }}
                </h4>
                <p
                  class="text-white text-sm my-0 uppercase tracking-widest font-efSans font-bold leading-5"
                >
                  {{ offerFirstMenu.content.subline }}
                </p>
              </div>
              <div
                class="absolute right-8 bottom-8 rounded-lg h-[160px] w-[160px] bg-mat-ef-red-secondary-500"
                *ngIf="offerFirstMenu.content.ctaboxPrice"
              >
                <div
                  class="flex flex-col justify-between min-w-[130px] h-[130px] overflow-hidden p-4 w-min"
                >
                  <p
                    class="flex flex-col justify-between text-base font-efSans m-0 h-full pb-1 text-white"
                  >
                    <span class="hidden xl:block">{{
                      offerFirstMenu.content.availability
                    }}</span>
                    <span class="block xl:hidden"></span>
                    <span class="offer-text-ab">
                      <T
                        str="from"
                        key="customerPortal.customer-portal.header.offer-text.ab"
                        tags="customer-portal, 3.1"
                      ></T
                    ></span>
                  </p>
                  <h5 class="text-white m-0 leading-none font-bold text-3xl">
                    {{
                      offerFirstMenu.content.ctaboxPrice
                        | currency: currency:true:'1.0-0':currencyLocale
                    }}
                    <span class="text-base font-efSans"
                      >/{{ offerFirstMenu.content.ctaboxTime }}</span
                    >
                  </h5>
                </div>
              </div>
              <div
                class="header-brands h-[26px] min-w-[26px] absolute right-8 top-8"
                *ngIf="
                  offerFirstMenu.content.offerbrand &&
                  offerFirstMenu.content.offerbrand.logo
                "
              >
                <img
                  alt="logo"
                  [src]="offerFirstMenu.content.offerbrand.logo[0].publicUrl"
                />
              </div>
            </div>
          </a>
          <div class="pt-6" *ngIf="topicsFirstMenu">
            <div class="header">
              <h6
                class="pt-6 text-2xl font-bold my-0 text-mat-ef-revolution-grey-two-500"
              >
                {{ topicsFirstMenu.headline }}
              </h6>
            </div>
            <div class="cards">
              <div class="flex justify-between w-full">
                <div
                  class="flex items-center w-[437px] max-h-[120px] pt-5"
                  *ngFor="let card of topicsFirstMenu.cards"
                >
                  <a
                    class="text-mat-ef-revolution-grey-two-500 no-underline bg-inerhit font-efSans text-sm font-semibold"
                    *ngIf="card && card.action"
                    [href]="baseUrl + card.action.url"
                    target="_blank"
                  >
                    <img
                      alt="image"
                      class="max-w-[100px] max-h-[100px] rounded-lg my-0 ml-0 mr-6"
                      [src]="card.image.publicUrl"
                  /></a>
                  <div
                    class="font-efSans font-semibold text-mat-ef-revolution-grey-two-500"
                  >
                    <a
                      *ngIf="card && card.action"
                      [href]="baseUrl + card.action.url"
                      target="_blank"
                    >
                      <span>{{ card.text }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="menu2"
      class="absolute min-w-full top-[100px] left-0 bg-white z-50 shadow-none"
      *ngIf="toggleMenu2"
      [@inOutAnimation]
    >
      <div class="grid px-[207px] pt-[132px] pb-[76px] mb-[76px] grid-cols-3">
        <div
          class="font-efSans text-lg font-semibold leading-6 text-left tracking-normal col-span-1"
          *ngIf="header && header.navigationMain && header.navigationMain[2]"
        >
          <p
            class="font-canelablack-primary text-4xl font-bold tracking-normal text-left p-0 mb-9 leading-normal"
          >
            {{ header.navigationMain[2].title }}
          </p>
          <div *ngFor="let link of header.navigationMain[2].children">
            <a
              class="links text-mat-ef-primary-500 inline-flex items-center mb-6"
              *ngIf="link.link !== '/'"
              [href]="
                !link.link.startsWith('http') ? baseUrl + link.link : link.link
              "
              target="_blank"
            >
              <div class="h-6 pr-4">
                <mat-icon
                  class="bg-mat-ef-primary-500 text-white rounded-full"
                  svgIcon="arrow_right"
                ></mat-icon>
              </div>
              {{ link.title }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      id="menu3"
      class="absolute min-w-full top-[100px] left-0 bg-white z-50 shadow-none"
      *ngIf="toggleMenu3"
      [@inOutAnimation]
    >
      <div class="grid px-[207px] pt-[132px] pb-[76px] mb-[76px] grid-cols-3">
        <div
          class="font-efSans text-lg font-semibold leading-6 text-left tracking-normal col-span-1"
          *ngIf="header && header.navigationMain && header.navigationMain[2]"
        >
          <p
            class="font-canelablack-primary text-4xl font-bold tracking-normal text-left p-0 mb-9 leading-normal"
          >
            {{ header.navigationMain[3].title }}
          </p>
          <div *ngFor="let link of header.navigationMain[3].children">
            <a
              class="links text-mat-ef-primary-500 inline-flex items-center mb-6"
              *ngIf="link.link !== '/'"
              [href]="
                !link.link.startsWith('http') ? baseUrl + link.link : link.link
              "
              target="_blank"
            >
              <div class="h-6 pr-4">
                <mat-icon
                  class="bg-mat-ef-primary-500 text-white rounded-full"
                  svgIcon="arrow_right"
                ></mat-icon>
              </div>
              {{ link.title }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="relative flex gap-4 items-center">
      <customer-portal-app-navbar-right
        [header]="header"
      ></customer-portal-app-navbar-right>
    </div>
  </div>
</section>
