import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { CptGoogleTagmanagerService } from '../../../../services/cpt-google-tagmanager.service'
import {
  RevolutionLink,
  CustomerPortalConfig,
  B2CConfig
} from '@inside-hub-app/customer-portal-config'
import { TransifexService } from '../../../../services/transifex.service'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { SharedService } from '../../../../services/shared.service'
@Component({
  selector: 'customer-portal-app-links',
  templateUrl: './links.component.html'
})
export class LinksComponent implements OnInit {
  col1
  col2

  currentLanguage: string

  constructor(
    private readonly translation: TranslateService,
    public transifexService: TransifexService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly remoteConfigService: EfRemoteConfigurationService<
      CustomerPortalConfig | B2CConfig
    >,
    private readonly sharedService: SharedService
  ) {
    this.currentLanguage = this.sharedService.currentLanguage()
    this.col1 = this.remoteConfigService.getArrayValues<RevolutionLink[]>(
      'revolution.links.footer.column1'
    )
    this.col2 = this.remoteConfigService.getArrayValues<RevolutionLink[]>(
      'revolution.links.footer.column2'
    )
  
  }

  ngOnInit(): void {
    this.translation.onLangChange.subscribe(val => {
      this.currentLanguage = val.lang
    })
  }

  sendGTMNavigationData(label?: string): void {
    this.cptGtmService.sendNavigationLinkData(null, 'Footer Navigation', label)
  }

  translateLink(link: string) {
    return this.transifexService.getTranslation({ key: link })
  }

  getLink(link: RevolutionLink): string {
    return this.sharedService.getLink(link)
  }
}
