import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router, ActivatedRoute } from '@angular/router'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from '../../../../services/shared.service'
import { DataService } from '../../../../services/data.service'
import {
  CptGoogleTagmanagerService,
  EcondaService,
  MatomoIntegrationService,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { ConfirmationQuestionPopupComponent } from '../../../basic/confirmation-question-popup/confirmation-question-popup.component'
import { RemoveVehiclePopupComponent } from '../../../vehicle-details/remove-vehicle-popup/remove-vehicle-popup.component'
import {
  VehicleDTOExtended,
  VehiclesService
} from '../../../../services/vehicles.service'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { LocalStorageService } from '../../../../services/local-storage.service'

@Component({
  selector: 'customer-portal-app-delete-vehicle',
  templateUrl: './delete-vehicle.component.html'
})
export class DeleteVehicleComponent implements OnInit {
  allVehicles: VehicleDTOExtended[] = []
  public vehicle: VehicleDTOExtended = null
  deactivateRequestDateString: string
  homepage
  public loading = true
  public vin: string
  i = 0

  vinIsValid = false

  sub = {
    onVehicleVinChange: null,
    activatedRoute: null,
    activatedRouteURL: null,
    onUserVehiclesLoaded: null,
    onResize: null,
    onWatchListLoaded: null,
    onVehicleDetailsLoadedSub: null
  }

  constructor (
    private readonly dataService: DataService,
    private readonly logger: NGXLogger,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly transifexTranslationsService: TranslationService,
    private readonly transifexService: TransifexService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly vehiclesService: VehiclesService,
    public sharedService: SharedService,
    private readonly econdaService: EcondaService,
    private readonly localStorage: LocalStorageService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>,
    private readonly matomoIntegrationService: MatomoIntegrationService
  ) {
    this.homepage = this.remoteConfigService.get('homepage')
  }

  // TODO: change dialog with newest
  openVehicleRemovalPopup (): void {
    const dialogRef = this.dialog.open(RemoveVehiclePopupComponent, {
      data: { vehicle: this.vehicle },
      panelClass: 'mat-dialog-cpt'
    })

    this.econdaService.send({
      content: 'Vehicle action',
      Target: [
        'Delete vehicle click',
        this.econdaService.formatVehicleData(this.vehicle),
        1,
        'd'
      ]
    })

    this.matomoIntegrationService.trackEvent(
      'Vehicle action',
      'Delete vehicle click',
      this.matomoIntegrationService.formatVehicleData(this.vehicle)
    )

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== false) {
        if (result.success === true) {
          if (this.vehicle.createdByDataSource === 'customer_portal') {
            // move to home
            this.router
              .navigate([this.homepage])
              .then(() => {
                this.dataService.removeVehicle(this.vehicle.vin)
                this.econdaService.send({
                  content: 'Vehicle action',
                  Target: [
                    'Delete vehicle click - confirm',
                    this.econdaService.formatVehicleData(this.vehicle),
                    1,
                    'd'
                  ]
                })
                this.matomoIntegrationService.trackEvent(
                  'Vehicle action',
                  'Delete vehicle click - confirm',
                  this.matomoIntegrationService.formatVehicleData(this.vehicle)
                )
              })
              .catch(() => {
                //
              })
          } else {
            this.vehicle.deactivateRequestDate = result.value
            this.updateLocalStorage(this.vehicle)
            this.dataService.refreshVehicleData(this.vehicle)
          }
        }
      }
    })
  }

  updateLocalStorage (vehicle): void {
    const copy = this.sharedService.deepCopy(vehicle)
    delete copy.relations
    void this.localStorage.setVehicleDetails(copy.vin, copy)
  }

  sendContentModulesMiniTeaserData (contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Mini Teaser',
      null,
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'Mini Teaser',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }

  openCompanyVehicleRemovalPopup (): void {
    const dialogRef = this.dialog.open(ConfirmationQuestionPopupComponent, {
      data: {
        title: 'customerPortal.customer-portal.company-vehicle.remove',
        text: 'customerPortal.customer-portal.company-vehicle.remove.confirmation-text',
        cancel: 'shared.cancel',
        save: 'customerPortal.customer-portal.company-vehicle.remove.button',
        saveButtonColor: 'warn',
        preventCloseBeforeComplete: true
      },
      panelClass: 'mat-dialog-cpt'
    })
    const sub = dialogRef.componentInstance.confirm.subscribe(() => {
      this.removeCompanyVehicle(this.vehicle, dialogRef)
    })
    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe()
    })
  }

  removeCompanyVehicle (vehicle: VehicleDTOExtended, dialogRef): void {
    this.vehiclesService.removeCompanyVehicle(vehicle.ownershipId).subscribe(
      () => {
        dialogRef.close()
        this.sharedService.openConfirmationPopup(
          'shared.successful',
          'customerPortal.customer-portal.company-vehicle.remove.success'
        )
        this.router
          .navigate([this.homepage])
          .then(() => {
            this.dataService.removeVehicle(this.vehicle.vin)
          })
          .catch(() => {
            //
          })
      },
      error => {
        this.logger.error(error)
        dialogRef.close()
        this.sharedService.openConfirmationPopup(
          'shared.error',
          'customerPortal.customer-portal.company-vehicle.remove.failure'
        )
      }
    )
  }

  ngOnInit (): void {
    this.sub.onVehicleVinChange = this.dataService.onVehicleVinChange.subscribe(
      vin => {
        this.vin = vin
        this.checkVin()
      }
    )
    this.sub.activatedRoute = this.activatedRoute.params.subscribe(params => {
      if (this.sharedService.stringExists(params.vin)) {
        this.vin = params.vin
      }
      this.checkVin()
    })
    this.sub.onUserVehiclesLoaded =
      this.dataService.onUserVehiclesLoaded.subscribe(
        (allVehicles: VehicleDTOExtended[]) => {
          this.loading = false
          if (allVehicles.length > 0) {
            this.allVehicles = allVehicles
          }
          this.checkVin()
        },
        error => {
          this.loading = false
          this.logger.debug(error)
        }
      )

    this.sub.onVehicleDetailsLoadedSub =
      this.dataService.onVehicleDetailsLoaded.subscribe(vehicleDetails => {
        if (vehicleDetails != null && vehicleDetails?.vin === this.vin) {
          this.deactivateRequestDateString = this.sharedService.formatDate(
            vehicleDetails.deactivateRequestDate
          )
        } else {
          this.deactivateRequestDateString = undefined
        }
      })
  }

  loadVehicle (vin): void {
    this.i = this.allVehicles.findIndex(k => k.vin === vin)
    this.vehicle = this.allVehicles.find(k => k.vin === vin)
  }

  checkVin (): void {
    // check if user has vehicle with current vin
    if (this.sharedService.stringExists(this.vin)) {
      const v = this.allVehicles?.find(({ vin }) => vin === this.vin)
      if (v == null) {
        this.vinIsValid = false
      } else {
        this.vinIsValid = true
      }
    } else {
      this.vinIsValid = false
    }

    if (this.vinIsValid) {
      this.loadVehicle(this.vin)
    }
  }
}
