import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { DataService } from '../../../../services/data.service'
import { SharedService } from '../../../../services/shared.service'
@Component({
  selector: 'customer-portal-app-hero',
  styleUrls: ['./hero.component.scss'],
  templateUrl: './hero.component.html'
})
export class HeroComponent implements OnInit, OnDestroy {
  user: User
  lang
  public hasSlogan
  public sloganTranslation

  sub = {
    onUserLoaded: null,
    onLanguageChange: null
  }

  welcomeTranslateKey
  constructor(
    private readonly data: DataService,
    public readonly sharedService: SharedService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasSlogan = this.remoteConfigService.get('b2c.slogan.hasSlogan')
    this.sloganTranslation = this.remoteConfigService.get(
      'b2c.slogan.translation'
    )
  }

  ngOnInit(): void {
    this.sub.onUserLoaded = this.data.onUserLoaded.subscribe(user => {
      this.lang = undefined
      this.cdRef.detectChanges()
      this.user = user
      this.lang =
        this.user != null && user.language != null && user.language.length !== 0
          ? user.language.find(s => s.primary).language
          : ''

      try {
        switch (this.user.salute) {
          case 'mr':
          case 'gentlemen':
            this.welcomeTranslateKey =
              'customerPortal.customer-portal.menu-bar.welcome-new.male'
            break
          case 'ms':
          case 'mrs':
            this.welcomeTranslateKey =
              'customerPortal.customer-portal.menu-bar.welcome-new.female'
            break
          default:
            this.welcomeTranslateKey =
              'customerPortal.customer-portal.menu-bar.welcome-new'
            break
        }
      } catch (error) {
        this.welcomeTranslateKey =
          'customerPortal.customer-portal.menu-bar.welcome-new'
      }
    })
    this.sub.onLanguageChange = this.data.onLanguageChange.subscribe(
      newLanguage => {
        this.lang = undefined
        this.cdRef.detectChanges()
        this.lang = newLanguage.toUpperCase()
      }
    )
  }

  ngOnDestroy(): void {
    this.sharedService.unsubscribe(this.sub)
  }
}
