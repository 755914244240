export interface DateParse {
  dateInput?: string
}
export type DateDisplay = DateParse & {
  monthYearLabel?: string
  dateA11yLabel?: string
  monthYearA11yLabel?: string
  default?: string
}

export class CustomDateFormat {
  private _parse: DateParse = {
    dateInput: 'MM/YYYY'
  }

  private _display: DateDisplay = {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    default: 'MMMM YYYY'
  }

  set parse(parse: DateParse) {
    this._parse = Object.assign({}, this._parse, parse)
  }

  get parse(): DateParse {
    return this._parse
  }

  set display(display: DateDisplay) {
    this._display = Object.assign({}, this._display, display)
  }

  get display(): DateDisplay {
    return this._display
  }

  updateDateFormat(parse: DateParse, display?: DateDisplay): void {
    this.parse = parse

    this.display = display
  }
}
