import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import {
  CustomerPortalConfig,
  DigitalManuals
} from '@inside-hub-app/customer-portal-config'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from '../../../../services/shared.service'
import { DataService } from '../../../../services/data.service'
import { PersonalizationService } from '../../../../services/personalization.service'
import {
  EquipmentDTO,
  VehicleDTOExtended,
  VehiclesService
} from '../../../../services/vehicles.service'

import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
@Component({
  selector: 'customer-portal-app-revolution-vehicle-extended-details',
  templateUrl: './vehicle-extended-details.component.html'
})
export class VehicleExtendedDetailsComponent implements OnInit {
  user: User
  basicEquipment: EquipmentDTO[] = []
  additionalEquipment: EquipmentDTO[] = []
  vehicleDetailed: VehicleDTOExtended

  // serviceKm: number

  sub = {
    onLanguageChangeSub: null,
    onVehicleDetailsLoadedSub: null,
    onVehicleFrequentDriverLoadedSub: null
  }

  public loading: boolean

  digitalManuals
  licensePlateMask
  constructor(
    public dialog: MatDialog,
    private readonly logger: NGXLogger,
    private readonly vehicleService: VehiclesService,
    private readonly personalizationService: PersonalizationService,
    private readonly dataService: DataService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.digitalManuals =
    this.remoteConfigService.getArrayValues<DigitalManuals[]>('digitalManuals')

    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
    })
    this.dataService.loading.vehicleDetails.subscribe(loading => {
      this.loading = loading
    })
  }

  @Input()
  public vehicle: VehicleDTOExtended

  sendContentModulesCTAData(contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }

  ngOnInit(): void {
    this.sub.onVehicleDetailsLoadedSub =
      this.dataService.onVehicleDetailsLoaded.subscribe(vehicleDetails => {
        this.vehicleDetailed = vehicleDetails
        this.basicEquipment = []
        this.additionalEquipment = []
        if (this.vehicleDetailed?.details?.equipmentDetails?.[0] != null) {
          this.basicEquipment =
            this.vehicleDetailed?.details.equipmentDetails.filter(el => {
              return el.type === 'S'
            })
          if (this.basicEquipment == null) {
            this.basicEquipment = []
          }

          this.additionalEquipment =
            this.vehicleDetailed?.details.equipmentDetails.filter(el => {
              return el.type !== 'S'
            })
          if (this.additionalEquipment == null) {
            this.additionalEquipment = []
          }
        }

        if (
          vehicleDetails != null &&
          vehicleDetails?.vin === this.vehicle?.vin
        ) {
          // need to update main vehicle propertys
          Object.keys(vehicleDetails).forEach(key => {
            this.vehicle[key] = vehicleDetails[key]
          })
        }
      })
  }
}
