<div class="text-white flex items-center">
  <div class="svg-logo p-8 lg:p-0">
    <mat-icon svgIcon="ef-logo-icon" class="w-14 h-14"></mat-icon>
  </div>
  <div class="brand-title hidden lg:flex gap-1 p-4">
    <span class="ef-paragraph font-bold"> Emil Frey </span>
    <span class="ef-paragraph">
      {{
        { key: 'customerPortal.customer-portal.country.' + countryCode } | transifexTranslate
      }}
    </span>
  </div>
</div>
