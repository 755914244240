<p
  class="my-5"
  *ngIf="
    reifenVehicleBrand && appointmentsPopupService.reifenData.serviceAction
  "
>
  <mat-checkbox [disabled]="true" [checked]="true" color="primary"
    >{{ reifenVehicleBrand + ' ' }}
    <T
      str="Service action (free)"
      key="customerPortal.customer-portal.appointments.services.service-action"
      tags="customer-portal, 3.1"
    ></T>
  </mat-checkbox>
</p>
<h2 class="appointment-form-title">
  <T
    str="Choose your service"
    key="customerPortal.customer-portal.appointments.choose-services"
    tags="customer-portal, 3.1"
  ></T>
</h2>
<form
  class="second-form-group appointment-form"
  [formGroup]="appointmentsPopupService.secondFormGroup"
>
  <div
    class="grid grid-cols-1 md:grid-cols-2 mt-2.5 font-efSans font-normal text-base gap-2.5"
  >
    <div *ngFor="let service of services">
      <mat-checkbox
        attr.data-cy="add-new-appointment-service-checkbox-{{ service.text }}"
        [checked]="isChecked(service.text)"
        (change)="addService(service.text)"
        color="primary"
        value="{{ service.text }}"
      >
        {{
          {
            str: service.text,
            prefix: 'customerPortal.customer-portal.appointments.services.'
          } | transifexTranslate
        }}
      </mat-checkbox>
    </div>
  </div>
  <div class="additional">
    <h2
      class="text-mat-ef-revolution-secondary-500 font-efSans py-4 font-medium"
    >
      <T
        str="Additional information"
        key="customerPortal.customer-portal.appointments.additional-info"
        tags="customer-portal, 3.1"
      ></T>
    </h2>
    <mat-form-field appearance="legacy" class="w-full">
      <mat-label
        ><T
          str="Enter current mileage in km"
          key="customerPortal.customer-portal.appointments.mileage"
          tags="customer-portal, 3.1"
        ></T
      ></mat-label>
      <input
        data-cy="add-new-appointment-mileage-input"
        type="number"
        matInput
        formControlName="mileage"
        required
      />
      <mat-error *ngFor="let validation of validationMessages.mileage">
        <mat-error
          class="error-message"
          *ngIf="
            appointmentsPopupService.secondFormGroup
              .get('mileage')
              .hasError(validation.type) &&
            (appointmentsPopupService.secondFormGroup.get('mileage').dirty ||
              appointmentsPopupService.secondFormGroup.get('mileage').touched)
          "
        >
          <ng-container
            *ngTemplateOutlet="
              sharedService.template('transifexTranslation');
              context: {
                str: validation.type,
                key: validation.message
              }
            "
          ></ng-container>
        </mat-error>
      </mat-error>
      <mat-hint
        ><T
          str="Last service mileage"
          key="customerPortal.customer-portal.vehicle.details.mileage"
          tags="customer-portal, 3.1"
        ></T>
        {{
          appointmentsPopupService.car?.currentMileage
            ? appointmentsPopupService.car.currentMileage + ' km'
            : ''
        }}</mat-hint
      >
    </mat-form-field>
    <mat-form-field class="my-5 w-full" appearance="legacy">
      <mat-label
        ><T
          str="Your message (optional)"
          key="customerPortal.customer-portal.appointments.notes"
          tags="customer-portal, 3.1"
        ></T
      ></mat-label>
      <textarea
        #notes
        cdkTextareaAutosize
        matInput
        cdkAutosizeMinRows="4"
        [maxlength]="maxLength"
        formControlName="notes"
      ></textarea>
      <mat-hint>{{ notes.value.length }} / {{ maxLength }}</mat-hint>
    </mat-form-field>
  </div>
  <div class="grid gap-4 text-base">
    <h2
      class="text-mat-ef-revolution-secondary-500 font-efSans text-xl font-medium"
    >
      <T
        str="Replacement car"
        key="customerPortal.customer-portal.appointments.replacement-car"
        tags="customer-portal, 3.1"
      ></T>
    </h2>
    <mat-checkbox
      data-cy="add-new-appointment-replacement-checkbox"
      color="primary"
      labelPosition="after"
      formControlName="replacement"
      class="text-mat-ef-revolution-primary-500 font-efSans"
      ><T
        str="I need a replacement vehicle"
        key="customerPortal.customer-portal.appointments.request-replacement"
        tags="customer-portal, 3.1"
      ></T
    ></mat-checkbox>
  </div>
  <div class="flex flex-col gap-[10px] md:hidden">
    <mat-divider class="my-4"></mat-divider>
    <button
      [disabled]="
        appointmentsPopupService.reifenData &&
        appointmentsPopupService.reifenData.serviceAction
      "
      class="next revolution w-full md:w-auto"
      mat-button
      mat-stroked-button
      color="secondary"
      matStepperPrevious
    >
      {{ 'back' | sharedTranslate }}
    </button>
    <button
      class="next revolution w-full md:w-auto"
      mat-button
      mat-flat-button
      color="secondary"
      (click)="appointmentsPopupService.setAllData()"
      matStepperNext
      type="button"
      [disabled]="
        appointmentsPopupService.selectedServices.length === 0 ||
        appointmentsPopupService.secondFormGroup.invalid ||
        appointmentsPopupService.loadingCarData
      "
    >
      <mat-spinner
        class="inline-block mr-2.5"
        [diameter]="16"
        *ngIf="appointmentsPopupService.loadingCarData"
      ></mat-spinner>
      {{ 'next' | sharedTranslate }}
    </button>
  </div>
</form>
