import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import { NGXLogger } from 'ngx-logger'
import { SharedService, PhoneMask } from '../../../../services/shared.service'
import { DataService } from '../../../../services/data.service'
import { DealerDTO, DealerUserDTO } from '../../../../services/dealers.service'
import { LocalStorageService } from '../../../../services/local-storage.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'
import { CallRequestPopupComponent } from '../../../vehicle-details/call-request-popup/call-request-popup.component'
import { ChangeSalesmanPopupComponent } from '../../../vehicle-details/change-salesman-popup/change-salesman-popup.component'

import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'

export type DealerUserFunctionEnum =
  cpt.emilfreydigital.customer_portal_backend.rest.dealer.dto.DealerUserFunctionEnum

interface PrefferedDealerResponse {
  dealers: {
    salesDealer: DealerDTO
    serviceDealer: DealerDTO
  }
  employeeType: string
}

@Component({
  selector: 'customer-portal-app-revolution-dealership-contact-molecule',
  templateUrl: './revolution-dealership-contact-molecule.component.html'
})
export class RevolutionDealershipContactMoleculeComponent
  implements OnInit, OnDestroy
{
  employeeType: DealerUserFunctionEnum
  showAltIcon = false
  phoneNumber: PhoneMask

  sub = {
    onDealerChange: null
  }

  constructor(
    private readonly dialog: MatDialog,
    private readonly dataService: DataService,
    private readonly logger: NGXLogger,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public readonly sharedService: SharedService,
    private readonly localStorage: LocalStorageService
  ) {}

  @Input()
  public vehicle: VehicleDTOExtended

  @Input()
  public dealer: DealerDTO

  @Input()
  public salesman: DealerUserDTO

  @Input()
  public salesAdvisor: DealerUserDTO

  @Input()
  public position: string

  @Input() public disableEdit = false

  @Input()
  public view: string

  public profilePictureUrl: string
  employee: DealerUserDTO

  editPrefferedemployee(): void {
    const dialog = this.dialog.open(ChangeSalesmanPopupComponent, {
      data: {
        vehicle: this.vehicle,
        dealer: this.dealer,
        employee: this.employee,
        employeeType: this.employeeType
      },
      panelClass: 'mat-dialog-cpt'
    })

    dialog.afterClosed().subscribe((result: PrefferedDealerResponse) => {
      if (result?.employeeType?.toUpperCase() === 'SALESMAN') {
        this.employee = result.dealers.salesDealer.salesman
      }
      if (result?.employeeType?.toUpperCase() === 'SALES_ADVISOR') {
        this.employee = result.dealers.serviceDealer.salesAdvisor
      }
      if (this.employee.profilePicture != null) {
        this.profilePictureUrl = this.sharedService.setUpProfilePicture(
          this.employee?.profilePicture
        )
      } else {
        this.profilePictureUrl = null
      }
      if (result?.dealers != null) {
        void this.localStorage.setVehicleDealers(
          this.vehicle.vin,
          result.dealers
        )
      }
    })
  }

  getSalesAdvisor(
    salesDealer: DealerDTO,
    serviceDealer: DealerDTO
  ): DealerUserDTO {
    let salesAdvisor = null

    if (salesDealer == null && serviceDealer == null) {
      return salesAdvisor
    }
    // If there is no salesAdvisor in serviceDealer, try to find it in "salesDealer"
    if (serviceDealer != null) {
      if (serviceDealer.salesAdvisor != null) {
        salesAdvisor = serviceDealer.salesAdvisor
      } else if (salesDealer.salesAdvisor != null) {
        salesAdvisor = salesDealer.salesAdvisor
      }
    }
    // If there is no serviceDealer, try to find salesAdvisor in "salesDealer"
    if (serviceDealer == null) {
      if (salesDealer.salesAdvisor != null) {
        salesAdvisor = salesDealer.salesAdvisor
      }
    }
    return salesAdvisor
  }

  sendOutboundLinkData(outboundLinkType: string, dealer: DealerDTO): void {
    this.cptGtmService.sendOutboundData(outboundLinkType, dealer)
  }

  openCallRequestPopup(): void {
    this.dialog.open(CallRequestPopupComponent, {
      data: {
        vehicle: this.vehicle,
        type: 'employee',
        employee: this.employee,
        dealer: this.dealer
      },
      panelClass: 'mat-dialog-cpt'
    })
  }

  openPhoneCLient(event: Event): void {
    event.stopPropagation()
    const phone = this.employee.phone.toLowerCase()
    const link = 'tel:' + phone
    window.open(link, '_blank')
  }

  openEmailClient(event: Event): void {
    event.stopPropagation()
    const mail = this.employee.email.toLowerCase()
    const link = 'mailto:' + mail
    window.open(link, '_blank')
  }

  setTelephoneMask(employee: DealerUserDTO): void {
    if (employee?.phone != null) {
      // if number starts with +41 add mask
      this.phoneNumber = this.sharedService.getTelephoneMask(
        employee.phone,
        3,
        '+41'
      )
    }
  }

  ngOnInit(): void {
    if (this.salesman != null) {
      this.employee = this.salesman
      this.employeeType = 'SALESMAN'
    }
    if (this.salesAdvisor != null) {
      this.employee = this.salesAdvisor
      this.employeeType = 'SALES_ADVISOR'
    }

    if (this.employee.profilePicture != null) {
      this.profilePictureUrl = this.employee.profilePicture
    }

    this.setTelephoneMask(this.employee)

    if (this.view !== 'tracking') {
      this.sub.onDealerChange = this.dataService.onDealerChange.subscribe(
        dealers => {
          if (dealers != null) {
            if (this.employeeType?.toUpperCase() === 'SALESMAN') {
              if (dealers.salesDealer != null) {
                this.employee = dealers.salesDealer.salesman
              }
            }
            if (this.employeeType?.toUpperCase() === 'SALES_ADVISOR') {
              this.employee = this.getSalesAdvisor(
                dealers.salesDealer,
                dealers.serviceDealer
              )
            }
            if (this.employee !== null) {
              this.profilePictureUrl = this.sharedService.setUpProfilePicture(
                this.employee?.profilePicture
              )
            }

            this.setTelephoneMask(this.employee)
          }
        }
      )
    }
  }

  ngOnDestroy(): void {
    this.sharedService.unsubscribe(this.sub)
  }
}
