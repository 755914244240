import { Pipe, PipeTransform } from '@angular/core'
import { DealerDTO } from '../services/dealers.service'

@Pipe({ name: 'dealername' })
export class DealerNamePipe implements PipeTransform {
  transform(dealer: DealerDTO): string {
    let dealerName = ''
    dealerName += dealer.name
    dealerName +=
      dealer.subname != null && dealer.subname.trim() !== ''
        ? ', ' + dealer.subname
        : ''
    return dealerName
  }
}
