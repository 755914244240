import { HttpClient } from '@angular/common/http'
import { Component, Input, type OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import {
  CptGoogleTagmanagerService,
  EcondaService,
  MatomoIntegrationService,
  ResizeService, type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NgxGlideComponent } from 'ngx-glide'
import { NGXLogger } from 'ngx-logger'
import {
  type CarmatoCompareVehicleDTO,
  CarmatoService,
  type CarmatoVehicleDataDTO,
  type CarmatoWatchlistDTOExtended
} from '../../../../services/carmato.service'
import { SharedService } from '../../../../services/shared.service'
import { ConfirmationPopupComponent } from '../../../basic/confirmation-popup/confirmation-popup.component'
import { ConfirmationQuestionPopupComponent } from '../../../basic/confirmation-question-popup/confirmation-question-popup.component'
import { getMonth, getYear } from 'date-fns'
// import { FAVORITES } from '../../../../mock/favorites'
@Component({
  selector: 'customer-portal-app-revolution-marketplace-favorites',
  templateUrl: './marketplace-favorites.component.html'
})
export class RevolutionMarketplaceFavoritesComponent implements OnInit {
  @ViewChild('RevolutionMarketplaceFavoritesComponent_ngxGlide')
    ngxGlide!: NgxGlideComponent

  comparedVehicles: CarmatoCompareVehicleDTO[]
  perView
  startAt
  focusAt
  showArrows = true
  showBullets = true
  bound = false
  fitGlider = false
  public screenSize: SCREEN_SIZE
  public screenSizePx: number
  breakpoints
  marketPlaceImageUrl: string
  constructor (
    public sharedService: SharedService,
    private readonly router: Router,
    private readonly carmatoService: CarmatoService,
    private readonly dialog: MatDialog,
    private readonly http: HttpClient,
    public resizeService: ResizeService,
    private readonly logger: NGXLogger,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly econdaService: EcondaService,
    private readonly matomoIntegrationService: MatomoIntegrationService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.screenSizePx = this.resizeService.screenSizePx
    this.marketPlaceImageUrl = this.remoteConfigService.get(
      'marketPlace.imageUrl'
    )
    this.currencySymbol = this.remoteConfigService.get('currencySymbol')
    this.currency = this.remoteConfigService.get('currency')
    this.currencyLocale = this.remoteConfigService.get('currencyLocale')
    this.co2 = this.remoteConfigService.get('marketPlace.favorites.units.co2')
    this.consumption = this.remoteConfigService.get(
      'marketPlace.favorites.units.consumption'
    )
    this.powerHp = this.remoteConfigService.get(
      'marketPlace.favorites.units.power.hp'
    )
    this.powerKw = this.remoteConfigService.get(
      'marketPlace.favorites.units.power.kw'
    )
    this.hasNewFooter = this.remoteConfigService.get('hasNewFooter')
    this.units = {
      co2: this.co2,
      consumption: this.consumption,
      powerHp: this.powerHp,
      powerKw: this.powerKw,
      currencySymbol: this.currencySymbol,
      currency: this.currency,
      currencyLocale: this.currencyLocale
    }
  }

  @Input() favorites: CarmatoWatchlistDTOExtended[]

  currencySymbol: string
  currency: string
  currencyLocale: string
  co2: string
  consumption: string
  powerHp: string
  powerKw: string
  hasNewFooter
  units

  comparableFavorites: CarmatoWatchlistDTOExtended[] = []
  view = 'favorites'

  sub = {
    onResize: null,
    onResizePx: null
  }

  hasFavorites (): boolean {
    return this.favorites?.length > 0
  }

  efficiencyClassArrowColor (efficiencyClass: string): string {
    let cssClass = ''
    const country: string = this.remoteConfigService.get('country.code')
    if (efficiencyClass === 'A+') {
      efficiencyClass = 'a-plus'
    }
    cssClass = country + ' ' + efficiencyClass.toLowerCase()
    return cssClass
  }

  replacedotWithComma (num: number): string {
    return num.toString().replace('.', ',')
  }

  formatInitialRegistrationDate (registrationDate: string | Date): string {
    const regDate = typeof registrationDate === 'string' ? new Date(registrationDate) : registrationDate
    const monthNumber = getMonth(regDate) + 1
    const year = getYear(regDate).toString()

    const monthString = monthNumber >= 10 ? monthNumber.toString() : '0' + monthNumber.toString()

    const monthYearString = monthString + '/' + year

    return monthYearString
  }

  formatPower (vehicleData: CarmatoVehicleDataDTO): string {
    const power =
      String(vehicleData.powerKw) +
      ' ' +
      String(this.units.powerKw) +
      ' / ' +
      String(vehicleData.powerHp) +
      ' ' +
      String(this.units.powerHp)
    return power
  }

  unsubscribeFavorite (f: CarmatoWatchlistDTOExtended): void {
    const vehicleId = f.vehicle
    this.dialog
      .open(ConfirmationQuestionPopupComponent, {
        data: {
          title:
            'customerPortal.customer-portal.marketplace.favorites.unsubscribe',
          text: 'customerPortal.customer-portal.marketplace.favorites.unsubscribe.question',
          cancel: 'shared.cancel',
          save: 'customerPortal.customer-portal.marketplace.favorites.unsubscribe',
          saveButtonColor: 'warn'
        },
        panelClass: 'mat-dialog-cpt'
      })
      .afterClosed()
      .subscribe(result => {
        if (result != null && result !== false) {
          this.carmatoService.unsubscribeFromWatchList(vehicleId).subscribe(
            () => {
              this.dialog.open(ConfirmationPopupComponent, {
                data: {
                  title: 'shared.successful',
                  text: 'customerPortal.customer-portal.marketplace.favorites.unsubscribe.success.text'
                },
                panelClass: 'mat-dialog-cpt'
              })
              this.getWatchList()
            },
            () => {
              this.dialog.open(ConfirmationPopupComponent, {
                data: {
                  title: 'shared.error',
                  text: 'customerPortal.customer-portal.marketplace.favorites.unsubscribe.failure.text'
                },
                panelClass: 'mat-dialog-cpt'
              })
            }
          )
        }
      })
  }

  addToCompare (f: CarmatoWatchlistDTOExtended): void {
    f.selectedForCompare = !f.selectedForCompare
    if (f.selectedForCompare) {
      if (this.comparableFavorites.length > 2) {
        const v1 = this.comparableFavorites[2].vehicle
        const v2 = this.favorites.find(({ vehicle }) => vehicle === v1)
        v2.selectedForCompare = false
        this.comparableFavorites.pop()
        this.comparableFavorites.unshift(f)
      } else {
        this.comparableFavorites.unshift(f)
      }
    } else {
      // Remove item from selected from compare
      f.selectedForCompare = false
      this.comparableFavorites.splice(
        this.comparableFavorites.findIndex(function (i) {
          return i.uid === f.uid
        }),
        1
      )
    }
  }

  goToVehicle (v: CarmatoVehicleDataDTO): void {
    const url = this.sharedService.carmatoVehicleLink(v)
    window.open(url, '_blank')
  }

  openMarketplace (): void {
    const url: string = this.remoteConfigService.get('marketPlace.url')
    this.econdaService.send({
      content: 'Marketplace',
      Target: ['Link to marketplace click', url, 1, 'd']
    })
    this.matomoIntegrationService.trackEvent(
      'Marketplace',
      'Link to marketplace click',
      url
    )
    window.open(url, '_blank')
  }

  compareVehicles (): void {
    const vehicles = []
    this.comparableFavorites.forEach(f => {
      vehicles.push(f.vehicle)
    })
    this.carmatoService.compareVehicles(vehicles).subscribe(compareResponse => {
      this.comparedVehicles = compareResponse
      this.view = 'compare'
    })
  }

  toFavorites (): void {
    this.view = 'favorites'
  }

  createImageUrl (d: CarmatoWatchlistDTOExtended): string {
    const url =
      this.marketPlaceImageUrl + d.vehicleData.mobileInseratNumber + '_01.jpg'
    return url
  }

  defaultBreakpoints (): void {
    this.breakpoints = {
      1024: { perView: 1, focusAt: 'center' },
      1280: { perView: 2, focusAt: 0 },
      3840: { perView: 3, focusAt: 0 }
    }
  }

  recreate (): void {
    setTimeout(() => {
      if (this.ngxGlide != null) {
        try {
          this.ngxGlide.recreate()
        } catch (error) {
          // no need to log
        }
      }
    })
  }

  calculateStartAt (): void {
    if (this.favorites?.[0] != null) {
      this.startAt = this.favorites.findIndex(favorites => {
        return favorites.vehicle
      })
    }
  }

  afterRan (): void {
    const index = this.ngxGlide.getIndex()
    if (index >= 0) {
      const favorites = this.favorites[index]
      if (favorites.vehicle != null) {
        this.goToVehicle(favorites?.vehicleData)
      }
    }
  }

  setBreakpoints (): void {
    this.calculateStartAt()

    const favoritesLength =
      this.favorites?.length != null ? this.favorites.length : 0
    if (favoritesLength >= 0) {
      switch (favoritesLength) {
        case 0:
        case 1:
          this.showBullets = false
          this.showArrows = false
          this.bound = !(this.screenSizePx > 1024)
          this.fitGlider = !(this.screenSizePx > 1024)

          this.breakpoints = {
            1024: { perView: 1, focusAt: 'center' },
            1280: { perView: 1, focusAt: 'center' },
            3840: { perView: 1, focusAt: 'center' }
          }
          break
        default:
          if (this.screenSizePx < 1024) {
            // small screen
            this.defaultBreakpoints()
            this.showBullets = true
            this.showArrows = true
            this.bound = false
            this.fitGlider = false
          } else if (this.screenSizePx > 1024) {
            // medium screen
            this.defaultBreakpoints()
            this.showBullets = favoritesLength > 2
            this.showArrows = favoritesLength > 2
            this.bound = !(favoritesLength > 2)
            this.fitGlider = !(favoritesLength > 2)
          } else {
            // large screen
            this.defaultBreakpoints()
            this.showBullets = false
            this.showArrows = false
            this.bound = true
            this.fitGlider = true
          }
          break
      }
    }
  }

  getWatchList (): void {
    this.carmatoService.getWatchlist().subscribe(favorites => {
      this.carmatoService.watchListLoaded(favorites)
      this.favorites = favorites
      // this.favorites = FAVORITES as any // mock
      this.setBreakpoints()
      this.recreate()
    })
  }

  sendContentModulesSliderData (
    side: string,
    contentModuleLabel: string,
    galleryName: string
  ): void {
    this.cptGtmService.sendContentModulesData(
      'Gallery',
      side,
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Gallery|(${galleryName})`,
      side
    )
  }

  ngOnInit (): void {
    this.getWatchList()

    this.sub.onResizePx = this.resizeService.onResizePx.subscribe(size => {
      this.screenSizePx = size
      this.setBreakpoints()
      this.recreate()
    })

    this.setBreakpoints()
    this.recreate()
  }
}
