import { Component } from '@angular/core'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import {
  type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'
@Component({
  selector: 'customer-portal-app-revolution-test-view',
  templateUrl: './test-view.component.html'
})
export class TestViewComponent {
  public screenSize: SCREEN_SIZE
  public hasNewFooter
  hasTestView

  constructor (
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasNewFooter = this.remoteConfigService.get('hasNewFooter')
    this.hasTestView = this.remoteConfigService.get('hasTestView')
  }
}
