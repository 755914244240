<div class="cpt-modal relative dialog-content-p-0 mat-dialog-actions-pb-20">
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <ng-container *ngIf="data.action === 'accept'">
      <T
        str="Accept offer"
        key="customerPortal.customer-portal.my-documents.accept-offer"
        tags="customer-portal, 3.11"
      ></T>
    </ng-container>
    <ng-container *ngIf="data.action === 'decline'">
      <T
        str="Decline offer"
        key="customerPortal.customer-portal.my-documents.decline-offer"
        tags="customer-portal, 3.11"
      ></T>
    </ng-container>
  </h2>

  <mat-dialog-content class="mat-typography">
    <ng-container *ngIf="data.action === 'accept'">
      <T
        str="Are you sure you want to accept the offer?"
        key="customerPortal.customer-portal.my-documents.accept-offer.confirmation"
        tags="customer-portal, 3.11"
      ></T>
    </ng-container>
    <ng-container *ngIf="data.action === 'decline'">
      <T
        str="Are you sure you want to decline the offer?"
        key="customerPortal.customer-portal.my-documents.decline-offer.confirmation"
        tags="customer-portal, 3.11"
      ></T>
    </ng-container>
  </mat-dialog-content>

  <form class="mt-2" [formGroup]="form">
    <div class="w-full">
      <mat-form-field class="w-full" appearance="legacy">
        <mat-label>
          {{
            {
              str: 'Your message',
              key: 'customerPortal.customer-portal.support.callrequest.yourmessage'
            } | transifexTranslate
          }}
        </mat-label>
        <textarea
          #comment
          cdkTextareaAutosize
          cdkAutosizeMinRows="4"
          [maxlength]="maxLength"
          formControlName="comment"
          matInput
        ></textarea>
        <mat-hint>{{ comment.value.length }} / {{ maxLength }}</mat-hint>
      </mat-form-field>
    </div>
  </form>

  <mat-divider></mat-divider>

  <button class="dialog-close-button" [mat-dialog-close]="false">
    <mat-icon svgIcon="close"></mat-icon>
  </button>

  <mat-dialog-actions>
    <button
      class="back revolution btn-dialog"
      mat-button
      mat-stroked-button
      color="secondary"
      [mat-dialog-close]="false"
    >
      {{ 'cancel' | sharedTranslate }}
    </button>

    <button
      mat-button
      mat-flat-button
      [color]="saveButtonColor"
      class="revolution btn-dialog"
      cdkFocusInitial
      (click)="onActionConfirm()"
      [disabled]="saving || !form.valid"
    >
      <mat-spinner [diameter]="16" *ngIf="saving"></mat-spinner>
      <mat-icon
        class="mr-1"
        *ngIf="!saving"
        [svgIcon]="data.action === 'decline' ? 'close' : 'check_circle_outline'"
      ></mat-icon>
      {{ data.action | sharedTranslate }}
    </button>
  </mat-dialog-actions>
</div>
