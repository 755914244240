import { Component, OnInit, Inject } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { DateAdapter } from '@angular/material/core'
import {
  MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS,
  LegacyErrorStateMatcher as ErrorStateMatcher
} from '@angular/material/legacy-core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import {
  User,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import { NGXLogger } from 'ngx-logger'
import {
  CptDateFormats,
  CptDateAdapter,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { DatepickerScrollService } from '../../../../services/datepickerScroll.service'
import { CustomCalendarHeaderComponent } from '../../../basic/custom-calendar-header/custom-calendar-header.component'
import { SharedService } from '../../../../services/shared.service'
import { format } from 'date-fns'

export const APP_DATE_FORMATS: CptDateFormats = {
  parse: {
    dateInput: 'MM/YYYY'
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    default: 'DD.MM.YYYY.'
  }
}

@Component({
  selector: 'customer-portal-app-revolution-birthday-popup',
  templateUrl: './birthday-popup.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: CptDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS
    }
  ]
})
export class RevolutionBirthdayPopupComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RevolutionBirthdayPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public user: User,
    private readonly userService: UserService,
    private readonly logger: NGXLogger,
    private readonly datepickerScrollService: DatepickerScrollService,
    public sharedService: SharedService,
    public transifexService: TransifexService
  ) {}

  birthdateForm = new FormGroup({
    birthdate: new FormControl<string | null>(null, [Validators.required])
  })

  hidden: boolean
  errorShow = false
  disabled = false
  date: ''
  defaultDate
  public loading: boolean
  todayDate
  disabledSave = false
  public maxDate: Date
  customHeader = CustomCalendarHeaderComponent

  parentErrorStateMatcher: ErrorStateMatcher

  ngOnInit(): void {
    this.hidden = true
    this.loading = false
    const today = new Date()
    this.maxDate = new Date()
    this.maxDate.setUTCFullYear(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    )
    this.maxDate.setDate(this.maxDate.getDate())
    if (this.user.birthDate != null) {
      this.defaultDate = this.user.birthDate
    }
  }

  updateBirthdate(): void {
    this.disabled = true
    this.loading = true
    const user: User = {
      contactId: this.user.contactId,
      birthDate: format(new Date(this.birthdateForm.controls.birthdate.value), 'yyyy-MM-dd')
    }

    this.userService.putUser(user).subscribe(
      data => {
        this.logger.debug('Request is successful ', data)
        this.loading = false
        this.hidden = false
        this.errorShow = false
      },
      error => {
        this.logger.error('Error', error)
        this.showError()
        this.disabled = false
        this.loading = false
      }
    )
  }

  showError(): boolean {
    this.birthdateForm.controls.birthdate.setErrors({
      error: true
    })
    return (this.errorShow = true)
  }

  addScrolling(picker): void {
    this.datepickerScrollService.addScrolling(picker)
  }
}
