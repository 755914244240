import { Pipe, PipeTransform } from '@angular/core'
import { TransifexService } from '../services/transifex.service'
import { GenericObject } from '../services/shared.service'

@Pipe({
  name: 'transifexTranslate',
  pure: false // it will execute on every cycle
})
export class TransifexTranslatePipe implements PipeTransform {
  constructor(public transifexService: TransifexService) {}

  transform(translationObj: {
    prefix?: string
    str?: string
    key?: string
    params?: GenericObject
    skipShared?: boolean
  }): string {
    return this.transifexService.getTranslation(translationObj)
  }
}
