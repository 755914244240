import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'

@Component({
  selector: 'customer-portal-app-navbar-expand',
  templateUrl: './navbar-expand.component.html'
})
export class NavbarExpandComponent implements OnInit {
  firstVehicleVin = ''
  user: User
  lang: string

  @Input()
  show: boolean

  constructor(
    public dataService: DataService,
    private readonly logger: NGXLogger,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.dataService.onUserVehiclesLoaded.subscribe(vehicles => {
      if (vehicles?.length > 0) {
        this.firstVehicleVin = vehicles[0].vin
      }
    })
    this.dataService.onLanguageChange.subscribe(newLanguage => {
      this.lang = newLanguage.toUpperCase()
    })
    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
    })
  }
}
