import { Component, OnDestroy, OnInit } from '@angular/core'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import { CptGoogleTagmanagerService, MatomoIntegrationService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { DataService } from '../../../../services/data.service'
import { SharedService } from '../../../../services/shared.service'

@Component({
  selector: 'customer-portal-app-support-card',
  templateUrl: './support-card.component.html'
})
export class SupportCardComponent implements OnInit, OnDestroy {
  sub = {
    onUserLoaded: null
  }

  user: User

  hasFAQ
  supportSubject: string
  constructor (
    public sharedService: SharedService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly dataService: DataService,
    private readonly transifexTranslationsService: TranslationService,
    private readonly matomoIntegrationService: MatomoIntegrationService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasFAQ = this.remoteConfigService.get('hasFAQ')
    this.supportSubject = this.remoteConfigService.get('support.subject')
  }

  ngOnInit (): void {
    this.sub.onUserLoaded = this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
    })
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  openMailClient (): void {
    const email = this.remoteConfigService.get('support.email')
    const subject = this.supportSubject + ' - ' + String(this.user.contactId)
    const url = 'mailto:' + email + '?subject=' + subject
    window.open(url)
  }

  sendContentModulesCTAData (contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )

    this.matomoIntegrationService.trackEvent(
      'CTA',
      'Customer portal support click'
    )
  }

  getTranslation (): string {
    return this.transifexTranslationsService.translate(
      'Is your data not displayed correctly? Do you have questions?<br> We are there for you.',
      {
        _key: 'customerPortal.customer-portal.support.contact.title',
        _tags: 'customer-portal, 3.1'
      }
    )
  }
}
