<div *ngIf="!error" class="cpt-modal relative mat-dialog-actions-pb-20">
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="Delete account"
      key="customerPortal.customer-portal.details.delete-account.confirmation.title"
      tags="customer-portal, 3.1"
    ></T>
  </h2>

  <mat-dialog-content class="py-2 px-0">
    <div class="rounded-xl bg-mat-ef-revolution-grey-200 my-4">
      <div class="p-4">
        <span
          class="font-efSans text-lg font-bold text-mat-ef-revolution-primary-500 flex gap-2 items-center"
        >
          <mat-icon svgIcon="error_outline"></mat-icon>
          <T
            str="Note:"
            key="customerPortal.customer-portal.appointments.note"
            tags="customer-portal, 3.1"
          ></T>
        </span>
        <span class="font-efSansRegular text-sm">
          <T
            str="Why do you want to delete your account?"
            key="customerPortal.customer-portal.details.delete-account.confirmation.questions"
            tags="customer-portal, 3.1"
          ></T>
        </span>
      </div>
    </div>
    <form [formGroup]="deleteAccountForm" class="grid">
      <div class="input-element" *ngFor="let option of options; let i = index">
        <mat-radio-group
          class="radios white-space-normal"
          formControlName="reasonId"
          aria-label="Select an option"
        >
          <mat-radio-button
            attr.data-cy="user-account-delete-reason-{{i}}"
            [checked]="deleteAccountForm.value.reasonId === option.id"
            class="font-efSansRegular"
            [value]="option.id"
          >
            <ng-container
              *ngTemplateOutlet="
                sharedService.template('transifexTranslation');
                context: {
                  key: option.question
                }
              "
            ></ng-container>
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-form-field class="w-full mt-6" appearance="legacy">
        <mat-label
          ><T
            str="Add notes"
            key="customerPortal.customer-portal.details.delete-account.confirmation.notes"
            tags="customer-portal, 3.1"
          ></T
        ></mat-label>
        <textarea
          cdkTextareaAutosize
          matInput
          cdkAutosizeMinRows="4"
          maxlength="300"
          formControlName="note"
        ></textarea>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-divider></mat-divider>

  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>

    <button
      data-cy="user-account-delete-submit"
      color="warn"
      id="saveButton"
      class="revolution flex justify-center items-center button-spinner w-full md:w-fit"
      mat-button
      mat-stroked-button
      (click)="deleteAccount()"
      cdkFocusInitial
      [disabled]="!deleteAccountForm.value.reasonId || loading"
    >
      <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
      {{ 'account-delete' | sharedTranslate }}
    </button>
    <button
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      mat-dialog-close
    >
      {{ 'cancel' | sharedTranslate }}
    </button>
  </mat-dialog-actions>
</div>

<div *ngIf="error" class="cpt-modal">
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="Delete account"
      key="customerPortal.customer-portal.details.delete-account.confirmation.title"
      tags="customer-portal, 3.1"
    ></T>
  </h2>
  <mat-dialog-content class="py-2 px-0">
    <h4>
      {{ 'general-error-message' | sharedTranslate }}
    </h4>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions>
    <button
      class="revolution"
      mat-button
      mat-stroked-button
      color="secondary"
      [mat-dialog-close]="false"
    >
      {{ 'cancel' | sharedTranslate }}
    </button>
  </mat-dialog-actions>
</div>
