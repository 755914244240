import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import {
  User,
  UserLanguage,
  UserService,
  UserUpdateRequest
} from '@inside-hub-app/customer-portal-b2c-client'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { TranslateService } from '@ngx-translate/core'
import { NGXLogger } from 'ngx-logger'
import { LanguageService } from '../../../services/language.service'
import { DataService } from '../../../services/data.service'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { HttpClient } from '@angular/common/http'
import { DomSanitizer } from '@angular/platform-browser'
import { SharedService } from '../../../services/shared.service'

@Component({
  selector: 'customer-portal-app-multilanguage-popup',
  templateUrl: './multilanguage-popup.component.html'
})
export class MultilanguagePopupComponent implements OnInit, OnDestroy {
  sub = {
    onUserLoaded: null,
    onLanguageChange: null
  }

  constructor(
    private readonly dialogRef: MatDialogRef<MultilanguagePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public user: User,
    private readonly translateService: TranslateService,
    private readonly userService: UserService,
    public logger: NGXLogger,
    private readonly languageService: LanguageService,
    private readonly dataService: DataService,
    private readonly keyCloakService: KeycloakService,
    private readonly http: HttpClient,
    public sanitizer: DomSanitizer,
    public sharedService: SharedService
  ) {}

  primaryLang = ''
  languages = []

  error = null
  saving = false
  kcUpdate = false

  ngOnInit(): void {
    this.languageService.getLanguages().subscribe(value => {
      this.languages = value
    })

    this.sub.onLanguageChange = this.dataService.onLanguageChange.subscribe(
      newLanguage => {
        this.primaryLang = newLanguage.toUpperCase()
      }
    )

    this.sub.onUserLoaded = this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
      if (user != null) {
        this.primaryLang = this.user.language.find(s => s.primary).language
      }
    })
  }

  ngOnDestroy(): void {
    this.sharedService.unsubscribe(this.sub)
  }

  changeLanguage(lan: string): void {
    this.saving = true
    const us: User = this.user
    const lang: UserLanguage = {
      language: lan.toUpperCase(),
      primary: true
    }
    const langs = this.user.language
    langs.push(lang)
    const userUpdate: UserUpdateRequest = {
      language: langs
    }
    us.language.splice(0, us.language.length)
    us.language.push(lang)
    this.primaryLang = this.user.language.find(s => s.primary).language
    this.translateService.use(lan.toLowerCase())
    this.userService.putUser(userUpdate, '', true).subscribe(
      data => {
        this.logger.debug('Request is successful ', data)
        this.dialogRef.close()
        this.dataService.languageChanged(lan.toLowerCase())
        this.saving = false
      },
      error => {
        this.logger.error(error)
        this.error = error
        this.saving = false
      }
    )
  }
}
