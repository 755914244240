<div class="hidden xl:grid mt-0 md:mt-10" [ngStyle]="getStyles()">
  <div *ngFor="let d of compareData" class="p-5 min-w-[260px] max-w-[360px]">
    <ng-container
      [ngTemplateOutlet]="compareItemTemplate"
      [ngTemplateOutletContext]="{ carData: d }"
    >
    </ng-container>
  </div>
</div>

<div class="grid xl:hidden mt-10 lg:mt-0">
  <mat-divider class="relative w-full"></mat-divider>

  <div *ngFor="let d of compareData">
    <mat-expansion-panel
      (afterExpand)="
        sendContentModulesData(
          true,
          'customerPortal.customer-portal.marketplace.compare'
        )
      "
      (afterCollapse)="
        sendContentModulesData(
          false,
          'customerPortal.customer-portal.marketplace.compare'
        )
      "
      class="vehicle-valuation"
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          class="font-efSans text-lg font-bold text-mat-ef-revolution-primary-500"
        >
          {{ d?.manufacturerName }}&nbsp;{{ d?.adTitle }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mat-expansion-content p-5">
        <ng-container
          [ngTemplateOutlet]="compareItemTemplate"
          [ngTemplateOutletContext]="{ carData: d }"
        >
        </ng-container>
      </div>
    </mat-expansion-panel>
    <mat-divider class="relative w-full"></mat-divider>
  </div>
</div>

<ng-template let-d="carData" #compareItemTemplate>
  <mat-card
    class="vehicle flex flex-col transition duration-300 ease-in-out !shadow-lg !rounded-lg !p-0 cursor-pointer select-none mb-10"
  >
    <div
      class="image-wrapper relative h-[192px] bg-mat-ef-revolution-grey-500 rounded-t-lg flex content-center items-center"
    >
      <img
        alt="vehicle img"
        class="rounded-t-lg object-contain m-auto w-full h-full"
        #img
        mat-card-image
        [src]="createImageUrl(d)"
        (error)="
          img.src = '../../../../assets/images/Placeholder.svg';
          img.classList.add('mat-card-image-placeholder')
        "
      />
    </div>

    <div class="content flex-1 flex flex-col p-4 pb-9 rounded-b-lg bg-light-bg">
      <div class="min-h-[120px]">
        <div class="font-efSans-primary !text-xl whitespace-normal font-black">
          {{ d?.manufacturerName }}
        </div>
        <div class="font-efSans-primary !text-lg whitespace-normal font-black">
          {{ d?.adTitle }}
        </div>
      </div>

      <div class="flex flex-col mt-5">
        <div
          class="font-efSans text-xs font-bold text-mat-ef-revolution-grey-two-500"
        >
          {{
            d?.newPrice
              | currency: units.currency:'symbol':'1.0-2':units.currencyLocale
          }}
          UVP
        </div>
        <div
          class="flex flex-1 justify-between items-center font-bold text-mat-ef-revolution-primary-500"
        >
          <span class="font-efSans text-2xl">
            {{
              d?.priceCustomer
                | currency: units.currency:'symbol':'1.0-2':units.currencyLocale
            }}
          </span>
          <span class="font-efSans text-xl">
            {{
              d?.rate
                | currency
                  : units.currency
                  : 'symbol'
                  : '1.2-2'
                  : units.currencyLocale
            }}/m.
          </span>
        </div>
      </div>
    </div>
  </mat-card>

  <div class="font-efSans text-xl text-mat-ef-revolution-primary-500 font-bold">
    <T
      str="Quick overview"
      key="customerPortal.customer-portal.marketplace.compare.overview"
      tags="customer-portal, 3.1"
    ></T>
  </div>
  <mat-divider></mat-divider>
  <div
    class="flex flex-col gap-2.5 p-4 items-center text-mat-ef-revolution-primary-500"
  >
    <mat-icon svgIcon="calendar_today"></mat-icon>
    <span class="font-efSans text-base">{{ d?.initialRegistrationYear }}</span>
    <span class="font-efSans text-base">
      <T
        str="First registration year"
        key="customerPortal.customer-portal.marketplace.searchagent.searchparam.initialRegistrationYear"
        tags="customer-portal, 3.1"
      ></T
    ></span>
  </div>
  <div
    class="flex flex-col gap-2.5 p-4 items-center text-mat-ef-revolution-primary-500"
  >
    <mat-icon svgIcon="kilometers"></mat-icon>
    <span class="font-efSans text-base">{{ d?.currentMiles }}</span>
    <span class="font-efSans text-base">
      <T
        str="Number of kilometers"
        key="customerPortal.customer-portal.marketplace.searchagent.searchparam.km"
        tags="customer-portal, 3.1"
      ></T
    ></span>
  </div>
  <div
    class="flex flex-col gap-2.5 p-4 items-center text-mat-ef-revolution-primary-500"
  >
    <mat-icon svgIcon="engine"></mat-icon>
    <span class="font-efSans text-base"
      >{{ d?.powerKw }} kw / {{ d?.powerHp }} PS</span
    >
    <span class="font-efSans text-base"
      ><T
        str="Power"
        key="customerPortal.customer-portal.marketplace.compare.params.power"
        tags="customer-portal, 3.1"
      ></T
    ></span>
  </div>
  <div class="font-efSans text-xl text-mat-ef-revolution-primary-500 font-bold">
    <T
      str="Vehicle details"
      key="customerPortal.customer-portal.marketplace.compare.params.vehicleDetails"
      tags="customer-portal, 3.1"
    ></T>
  </div>
  <mat-divider></mat-divider>
  <div
    class="flex flex-col py-2 min-h-[40px] font-bold text-mat-ef-revolution-primary-500"
  >
    <span class="text-base"
      ><T
        str="Category"
        key="customerPortal.customer-portal.marketplace.compare.params.category"
        tags="customer-portal, 3.1"
      ></T
    ></span>
    <span class="text-xs">{{ d?.vehicleTypeName }}</span>
  </div>
  <div
    class="flex flex-col py-2 min-h-[40px] font-bold text-mat-ef-revolution-primary-500"
  >
    <span class="text-base"
      ><T
        str="Gearbox type"
        key="customerPortal.customer-portal.marketplace.favorites.gearboxtype"
        tags="customer-portal, 3.1"
      ></T
    ></span>
    <span class="text-xs">{{ d?.gearboxType }}</span>
  </div>
  <div
    class="flex flex-col py-2 min-h-[40px] font-bold text-mat-ef-revolution-primary-500"
  >
    <span class="text-base">
      <T
        str="Exterior colors"
        key="customerPortal.customer-portal.marketplace.searchagent.searchparam.exteriorColors"
        tags="customer-portal, 3.1"
      ></T
    ></span>
    <span class="text-xs">{{ d?.exteriorColor }}</span>
  </div>
  <div
    class="flex flex-col py-2 min-h-[40px] font-bold text-mat-ef-revolution-primary-500"
  >
    <span class="text-base">
      <T
        str="Drive type"
        key="customerPortal.customer-portal.marketplace.searchagent.searchparam.drive"
        tags="customer-portal, 3.1"
      ></T
    ></span>
    <span class="text-xs">{{ d?.drive }}</span>
  </div>
  <div class="font-efSans text-xl text-mat-ef-revolution-primary-500 font-bold">
    <T
      str="Weight and other data"
      key="customerPortal.customer-portal.marketplace.compare.params.weight"
      tags="customer-portal, 3.1"
    ></T>
  </div>
  <mat-divider></mat-divider>
  <div
    class="flex flex-col py-2 min-h-[40px] font-bold text-mat-ef-revolution-primary-500"
  >
    <span class="text-xs">
      <T
        str="Max. speed"
        key="customerPortal.customer-portal.marketplace.compare.params.topspeed"
        tags="customer-portal, 3.1"
      ></T
    ></span>
    <span class="text-base">{{ d?.maxSpeed }}</span>
  </div>
  <div
    class="flex flex-col py-2 min-h-[40px] font-bold text-mat-ef-revolution-primary-500"
  >
    <span class="text-xs">
      <T
        str="Number of gears"
        key="customerPortal.customer-portal.marketplace.compare.params.gearsNumber"
        tags="customer-portal, 3.1"
      ></T
    ></span>
    <span class="text-base">{{ d?.numberOfGears }}</span>
  </div>
  <div class="font-efSans text-xl text-mat-ef-revolution-primary-500 font-bold">
    <T
      str="Performance"
      key="customerPortal.customer-portal.marketplace.compare.params.performance"
      tags="customer-portal, 3.1"
    ></T>
  </div>
  <mat-divider></mat-divider>
  <div
    class="flex flex-col py-2 min-h-[40px] font-bold text-mat-ef-revolution-primary-500"
  >
    <span class="text-xs">
      <T
        str="Fuel type"
        key="customerPortal.customer-portal.marketplace.searchagent.searchparam.fuelMethods"
        tags="customer-portal, 3.1"
      ></T
    ></span>
    <span class="text-base">{{ d?.fuelMethod }}</span>
  </div>
  <div
    class="flex flex-col py-2 min-h-[40px] font-bold text-mat-ef-revolution-primary-500"
  >
    <span class="text-xs">
      <T
        str="Power"
        key="customerPortal.customer-portal.marketplace.compare.params.power"
        tags="customer-portal, 3.1"
      ></T
    ></span>
    <span class="text-base">{{ d?.powerKw }} kw / {{ d?.powerHp }} PS</span>
  </div>
  <div
    class="flex flex-col py-2 min-h-[40px] font-bold text-mat-ef-revolution-primary-500"
  >
    <span class="text-xs">
      <T
        str="Energy efficiency class"
        key="customerPortal.customer-portal.marketplace.compare.params.energyEfficiencyClass"
        tags="customer-portal, 3.1"
      ></T
    ></span>
    <span class="text-base">{{ d?.energyEfficiencyClass }}</span>
  </div>
  <div
    class="flex flex-col py-2 min-h-[40px] font-bold text-mat-ef-revolution-primary-500"
  >
    <span class="text-xs">
      <T
        str="CO2 Emission"
        key="customerPortal.customer-portal.marketplace.searchagent.searchparam.emission"
        tags="customer-portal, 3.1"
      ></T
    ></span>
    <span class="text-base">{{ d?.coEmission | replace: '.':',' }}</span>
  </div>
  <div
    class="flex flex-col py-2 min-h-[40px] font-bold text-mat-ef-revolution-primary-500"
  >
    <span class="text-xs">
      <T
        str="Fuel consumption"
        key="customerPortal.customer-portal.marketplace.compare.params.consumption"
        tags="customer-portal, 3.1"
      ></T
    ></span>
    <span class="text-base">{{ d?.consumption | replace: '.':',' }}</span>
  </div>
  <div class="scale-y-0 overflow-hidden hidden md:block">
    <button
      mat-button
      mat-raised-button
      class="revolution m-0"
      color="secondary"
      (click)="goToVehicle(d)"
    >
      <T
        str="To the vehicle"
        key="customerPortal.customer-portal.marketplace.tovehicle"
        tags="customer-portal, 3.1"
      ></T>
    </button>
  </div>
</ng-template>
