import { Component, Input } from '@angular/core'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CarmatoCompareVehicleDTO } from '../../../../services/carmato.service'
import { SharedService } from '../../../../services/shared.service'

@Component({
  selector: 'customer-portal-app-revolution-marketplace-compare',
  templateUrl: './marketplace-compare.component.html'
})
export class RevolutionMarketplaceCompareComponent {
  @Input() compareData: CarmatoCompareVehicleDTO[]

  constructor(
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly sharedService: SharedService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.currencySymbol = this.remoteConfigService.get('currencySymbol')
    this.currency = this.remoteConfigService.get('currency')
    this.currencyLocale = this.remoteConfigService.get('currencyLocale')
    this.co2 = this.remoteConfigService.get('marketPlace.favorites.units.co2')
    this.consumption = this.remoteConfigService.get(
      'marketPlace.favorites.units.consumption'
    )
    this.powerHp = this.remoteConfigService.get(
      'marketPlace.favorites.units.power.hp'
    )
    this.powerKw = this.remoteConfigService.get(
      'marketPlace.favorites.units.power.kw'
    )
    this.hasNewFooter = this.remoteConfigService.get('hasNewFooter')
    this.units = {
      co2: this.co2,
      consumption: this.consumption,
      powerHp: this.powerHp,
      powerKw: this.powerKw,
      currencySymbol: this.currencySymbol,
      currency: this.currency,
      currencyLocale: this.currencyLocale
    }
  }

  currencySymbol: string
  currency: string
  currencyLocale: string
  co2: string
  consumption: string
  powerHp: string
  powerKw: string
  hasNewFooter
  units

  createImageUrl(d: CarmatoCompareVehicleDTO): string {
    return (
      this.remoteConfigService.get('marketPlace.imageUrl') +
      d.mobileAdNumber +
      '_01.jpg'
    )
  }

  goToVehicle (d: CarmatoCompareVehicleDTO): void {
    const url = this.sharedService.carmatoVehicleLink(d)
    window.open(url, '_blank')
  }

  sendContentModulesData(expanded: boolean, contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Accordion',
      expanded ? 'Open' : 'Close',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Accordion|${this.sharedService.translateLink(contentModuleLabel) ?? ''}`,
      expanded ? 'Open' : 'Close'
    )
  }

  public getStyles(): {
    'grid-template-columns': string
    'justify-items': string
  } {
    return {
      'grid-template-columns': `repeat(${this.compareData?.length}, 1fr)`,
      'justify-items': 'center'
    }
  }
}
