import { Component, type OnInit } from '@angular/core'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
@Component({
  selector: 'customer-portal-app-brand',
  templateUrl: './brand.component.html'
})
export class BrandComponent implements OnInit {
  countryCode: string
  constructor (
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {}

  ngOnInit (): void {
    this.countryCode = this.remoteConfigService.get('country.code')
  }
}
